import { useEffect } from 'react';
import { postMessage } from '@stonlyCommons/helpers/widgetHelpers';

const sendMessage = ({ value }) =>
  postMessage({
    type: 'setIsImageFullscreen',
    value,
  });

export default function useSetIsImageFullscreen({ isImageFullscreen }) {
  useEffect(() => {
    if (isImageFullscreen) {
      sendMessage({ value: true });
    }

    return () => {
      if (isImageFullscreen) {
        sendMessage({ value: false });
      }
    };
  }, [isImageFullscreen]);
}
