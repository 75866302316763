import React, { Component } from 'react';
import callApi from 'helpers/apiHelpers';
import queryString from 'query-string';
import { getGlobal } from 'global/windowUtils';
import ExplanationHelmet from '@playerCommon/CustomElements/ExplanationHelmet';

const pathnameFixer = pathname => (pathname || '').replaceAll(/\/+/gi, '/');

function withRequestData(WrappedComponent) {
  return class extends Component {
    static async getInitialProps({ req, match }) {
      const languages = getGlobal('languages');
      const userAgent = getGlobal('userAgent');

      let user;

      const { mode } = match.params;

      const res = await callApi('v1/auth/status', 'get');
      const { csrfToken } = res.data;
      if (res.data.logged) {
        user = res.data.user;
      }

      if (req && !mode) {
        const urlParams = queryString.parse(req._parsedUrl.search);

        if (user && user.id) {
          if (urlParams.signedIn === undefined) {
            urlParams.signedIn = null;
            return {
              redirectTo: `${pathnameFixer(req._parsedUrl.pathname)}?${queryString.stringify(urlParams)}`,
              statusCode: 302,
            };
          }
        } else if (urlParams.signedIn !== undefined) {
          urlParams.signedIn = undefined;
          return {
            redirectTo: `${pathnameFixer(req._parsedUrl.pathname)}?${queryString.stringify(urlParams)}`,
            statusCode: 302,
          };
        }
      }

      return { languages, userAgent, user, csrfToken };
    }

    render() {
      return (
        <>
          <ExplanationHelmet />
          <WrappedComponent {...this.props} />
        </>
      );
    }
  };
}

export default withRequestData;
