import React from 'react';
import styled, { useTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { assetsUrl } from 'global/env';
import Icon from '@playerCommon/StandardElements/Icon';

const Canvas = styled.div`
  font-size: 14px;
`;

const DropdownTitle = styled.div`
  display: flex;
  transition: 0.2s;
  font-size: 12px;
  color: ${props => props.theme.steel};
  font-weight: 500;
  padding-left: 0;
`;

export const Tip = styled(Icon)`
  margin-left: 8px;
  vertical-align: sub;
`;

const RequiredLabel = styled.span`
  color: ${({ theme }) => theme.pink};
  margin-left: 2px;
`;

const DropdownWrap = React.forwardRef(
  ({ triggerId, className, title, tooltip, children, stonlyTrigger, stonlyTriggerCanvas, required }, ref) => {
    const theme = useTheme();
    return (
      <Canvas data-cy="dropdownCanvas" className={className} ref={ref} data-stonly-trigger={stonlyTriggerCanvas}>
        {(title || required) && (
          <DropdownTitle data-cy="dropdownTitle" data-stonly-trigger={stonlyTrigger} htmlFor={triggerId}>
            {title}
            {required && <RequiredLabel className="label-asterisk">*</RequiredLabel>}
            {tooltip && (
              <Tip
                positionTooltip="up"
                color={theme.lightGrey}
                colorHover={theme.seaweed}
                src={`${assetsUrl}resources/icons/tip.svg`}
                {...tooltip}
              />
            )}
          </DropdownTitle>
        )}
        {children}
      </Canvas>
    );
  }
);

DropdownWrap.propTypes = {
  triggerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
  tooltip: PropTypes.object,
  stonlyTrigger: PropTypes.string,
  stonlyTriggerCanvas: PropTypes.string,
  children: PropTypes.node,
  required: PropTypes.bool,
};

export default DropdownWrap;
