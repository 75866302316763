import styled, { css } from 'styled-components';
import CopySVG from 'icons/copy.svg';
import TickSVG from 'icons/tickMedium.svg';
import ThumbsUpSVG from 'icons/thumbsUp-12.svg';
import { FEEDBACK_TYPE } from '../useSmartSearchResults';

export const ThumbsUpIcon = styled(ThumbsUpSVG)`
  path {
    fill: currentColor !important;
  }

  ${({ reversed }) =>
    reversed &&
    css`
      transform: rotate(180deg);
    `}
`;

export const CopyIcon = styled(CopySVG)`
  path {
    fill: currentColor !important;
  }
`;

export const CopiedIcon = styled(TickSVG)`
  path {
    fill: currentColor !important;
  }
`;

export const SmartSearchSingleAnswerActions = styled.div`
  position: absolute;
  left: 100%;
  padding-left: 4px;
  height: 100%;
  top: 4px;
  opacity: 0;
  transform: translate3d(-16px, 0, 0);
  transition: opacity 0.2s, transform 0.2s;

  *:hover > &,
  &:hover,
  &:focus-within {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  button {
    color: ${props => props.theme.steel};
    background: transparent;
    height: 32px;
    width: 32px;
    border-radius: 8px;
    border: 0px;
  }

  .ai-answer-copied {
    color: ${props => props.theme.seaweed};
    cursor: auto;
  }
`;

export const SmartSearchResultToolbar = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  padding: 12px 12px 32px 0px;
  font-weight: 300;
  z-index: 0;
  position: relative;

  button {
    color: ${props => props.theme.steel};
    background: transparent;
    height: 32px;
    width: 32px;
    border-radius: 8px;
    border: 0px;
  }

  .ai-answer-copied {
    color: ${props => props.theme.seaweed};
    cursor: auto;
  }

  ${({ feedbackValue }) =>
    feedbackValue &&
    css`
      button.feedback {
        transition: background-color 0.2s, color 0.2s;
        border-color: transparent;
        ${feedbackValue === FEEDBACK_TYPE.POSITIVE &&
        css`
          &.feedback-positive {
            background-color: ${props => props.theme.washedGreen};
            color: ${props => props.theme.seaweed};
          }
        `}

        ${feedbackValue === FEEDBACK_TYPE.NEGATIVE &&
        css`
          &.feedback-negative {
            background-color: ${props => props.theme.washedRed};
            color: ${props => props.theme.amaranth};
          }
        `}
      }
    `}

  ${({ searchLoading }) =>
    searchLoading &&
    css`
      display: none;
    `}

  ${({ feedbackHidden }) =>
    feedbackHidden &&
    css`
      transition: gap 0.2s;
      gap: 0;

      button.feedback {
        transition: width 0.2s, height 0.2s, opacity 0.2s, visibility 0.2s;
        height: 0 !important;
        width: 0 !important;
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
      }
    `}
`;
