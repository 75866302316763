import React, { Component } from 'react';
import styled, { css, withTheme, ThemeProvider, createGlobalStyle } from 'styled-components';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { RIGHT, STEP_NEXT_VISIBILITY } from 'global';
import queryString from 'query-string';
import i18n, { getLanguageShorthand } from 'helpers/i18n';
import { setLanguage } from 'helpers/i18n.helpers';
import { createPathFromParam, loadExplanation, getNewLanguageUrl } from 'helpers/explanationManagement.js';
import Loader, { FullscreenLoaderWrap } from '@playerCommon/CustomElements/Loader/Loader';
import { validateHexaDecimal } from 'helpers/validationHelpers.js';
import NoViewAccess from '@playerCommon/Commons/NoViewAccess';
import fullscreenContext from '@playerCommon/Contexts/fullscreenContext';
import ExplanationPlayer from '@playerCommon/ComplexElements/ExplanationPlayer';
import withUser from '@playerCommon/HOC/withUser/withUser';
import withExplanationData from 'HOC/withExplanationData';
import withContextProviders from 'HOC/withContextProviders';
import { postMessage, getWidgetIframeParams } from 'helpers/widgetHelpers';
import { getStepsGlobalScrollbarStyles } from '@playerCommon/CommonStyledComponents/CustomScrollbar';
import { parseGuideData } from 'helpers/guidePlayerHelpers';
import { parseBoolean } from '@playerCommon/helpers/booleanHelpers';
import { getIds } from 'helpers/statIdsManagement';
import { getIsMobileIntegration } from '@stonlyCommons/helpers/userAgentHelpers';

const RootScrollStyles = createGlobalStyle`
  ${getStepsGlobalScrollbarStyles({ marginTop: '64px', marginBottom: '68px' })}

  @media screen and (max-width: 899px) {
    ${getStepsGlobalScrollbarStyles({ marginTop: '64px', marginBottom: '68px' })}
  }

  @media screen and (max-width: 480px) {
    ${getStepsGlobalScrollbarStyles({ marginTop: '64px', marginBottom: '60px' })}
  }

  html {
    overflow-x: hidden;

    &::-webkit-scrollbar-track {
      ${({ compact }) =>
        compact &&
        css`
          margin-top: 0;
          margin-bottom: 0;
        `}
    }
    ${({ compact }) =>
      compact &&
      css`
        scroll-padding-top: 0;
        scroll-padding-bottom: 0;
      `}

    /**
      For Widgets with dynamic height we don't want scrollbar to affect the width of the content.
      Otherwise, in some cases on the steps with custom images (and aspect ratio) the Widget would shake infinitely.
    */
    ${({ compact }) =>
      compact &&
      css`
        @media screen and (max-width: 480px) {
          body {
            width: 100vw;
          }

          &::-webkit-scrollbar {
            width: 10px;
            height: 10px;
          }
        }
      `}
  }

  ${({ hideScrollbar }) =>
    hideScrollbar &&
    css`
      html {
        &::-webkit-scrollbar {
          display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;
      }
    `}


  // This is a Safari hack, so that in the compact widget the scrollbar gets the default width and height, because
  // Safari subtracts the scrollbar width from the width of the html, and so the @media attributes are improperly
  // calculated. Like WTF Safari.
  // Widget runs the first iframe in the size of 64pxs, so I figured max-height of 80px is a safe margin for
  // this idiotic browser.
  ${({ compact }) =>
    compact &&
    css`
      @media screen and (max-width: 899px) and (max-height: 80px) {
        @supports (-webkit-hyphens: none) {
          html {
            &::-webkit-scrollbar,
            &::-webkit-scrollbar-thumb {
              width: 0px !important;
              height: 0px !important;
            }
          }
        }
      }
    `}
`;

const Canvas = styled.div`
  background-color: ${props => props.theme.white};
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}px`};
  display: flex;
  flex-direction: column;
  padding-top: 64px;
  overflow: auto;
  width: 1px;
  min-width: 100%;
  position: relative;
  ${({ embedFontFamily }) => embedFontFamily && `font-family: '${embedFontFamily}'`};
  /* height: ${({ view }) => (view === 'flowchart' ? '800px' : 'initial')}; */
  height: 100%;
  min-height: 100%;
  @supports (height: unset) {
    height: unset;
  }
  overflow: visible;
  ${({ compact }) =>
    compact &&
    css`
      padding-top: 0;
    `}
`;

// based on COMPACT_WIDGET_WIDTH from stonly-widget-preact/src/consts.js
const WIDGET_WIDTH = {
  nextToTrigger: 320,
  bottomCorner: 400,
  modal: 520,
};

const PlayerContentWrap = styled.div`
  @media screen and (min-width: 900px) {
    height: calc(100vh - 64px);
  }

  ${({ autoResizable }) =>
    !autoResizable &&
    css`
      @media screen and (max-width: 899px) {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }
    `};

  ${({ compact }) =>
    compact &&
    css`
      @media screen and (min-width: 900px) {
        height: 100vh;
      }
    `}

  ${({ compact, isMobileIntegration }) =>
    compact &&
    !isMobileIntegration &&
    css`
      /*
        Yes, this is awful, yes, this means that if we have precisely one of WIDGET_WIDTH widths,
        it's still going to be ugly, but realistically, not many devices have such a width (thankfully).
        It's an exception for the compact widget's desktop widths, so that it can compute height properly.
        Also - it uses a min-height hack with 1px height, so that the min-height can be inherited. Browsers <3
        Currently the compact widget has three widths: 320, 400 and 520 and all three have to be handled.

        It doesn't apply to resolutions below 320px, as there were widgets show in sidebars that had below 320px and
        there are no real devices using that resolution.

        TODO: find a nicer solution, that doesn't feel as hacky as this one one day
      */
      @media screen and (min-width: ${WIDGET_WIDTH.nextToTrigger + 1}px) and (max-width: ${WIDGET_WIDTH.bottomCorner -
        1}px),
        screen and (min-width: ${WIDGET_WIDTH.bottomCorner + 1}px) and (max-width: ${WIDGET_WIDTH.modal - 1}px),
        screen and (min-width: ${WIDGET_WIDTH.modal + 1}px) and (max-width: 899px) {
        min-height: 100vh;
        height: 1px;
      }
    `}
`;

const WidgetHeader = styled.div`
  position: fixed;
  font-size: 20px;
  line-height: 24px;
  padding-left: 24px;
  padding-top: 20px;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
  border-bottom: 1px solid ${props => props.theme.silver};
  z-index: 11;
  background: white;

  ${({ titleLength }) =>
    titleLength > 32 &&
    css`
      font-size: 16px;
    `}

  ${({ compact }) =>
    compact &&
    css`
      height: 0px;
      padding: 0;
      border-bottom: 0;
    `}

  @media screen and (max-width: 479px) {
    font-size: 16px;
  }
`;

const WidgetHeaderTitle = styled.div`
  max-width: calc(100% - 94px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ALLOWED_RIGHTS = new Set([RIGHT.FULL_RIGHTS, RIGHT.VIEW, RIGHT.VIEW_EDIT, RIGHT.VIEW_EDIT_PUBLISH]);

const getProcessedState = (match, location, loadedGuide, statusCode) => {
  if (statusCode === 403) {
    return { explanationLoaded: true, accessToExplanation: false, guide: {} };
  }
  if (!loadedGuide) return null;

  const accessToExplanation = ALLOWED_RIGHTS.has(loadedGuide.guideInfo.access);
  if (!accessToExplanation) {
    return { explanationLoaded: true, accessToExplanation: false, guide: {} };
  }

  let themeHighlightColor;
  let placement;
  let widgetFormat;
  let widgetSizeType;
  let compactInitialValue = false;

  const { buttonColor } = loadedGuide.guideOptions;
  const urlParams = queryString.parse(location.search);

  if (buttonColor && validateHexaDecimal(buttonColor)) {
    themeHighlightColor = buttonColor;
  }
  if (urlParams.accent && validateHexaDecimal(urlParams.accent)) {
    themeHighlightColor = urlParams.accent;
  }
  if (urlParams.compact && JSON.parse(urlParams.compact)) {
    compactInitialValue = true;
  }
  if (urlParams.placement) {
    placement = urlParams.placement;
  }
  if (urlParams.widgetFormat) {
    widgetFormat = urlParams.widgetFormat;
  }
  if (urlParams.widgetSizeType) {
    widgetSizeType = urlParams.widgetSizeType;
  }

  return {
    guide: loadedGuide,
    explanationLoaded: true,
    accessToExplanation,
    //
    themeHighlightColor,
    iframeParams: {
      autoResizable: true,
      compact: compactInitialValue,
      widgetFormat,
      placement,
      widgetSizeType,
    },
    isFullScreen: false,
    windowResizing: true,
  };
};

class Widget extends Component {
  constructor(props) {
    super(props);
    const ssrInitialState = getProcessedState(props.match, props.location, props.loadedGuide, props.statusCode);
    const stepsPath = createPathFromParam(props.match.params.pathToLoad);
    this.state = ssrInitialState
      ? { ...ssrInitialState, stepsPath }
      : {
          guide: null,
          explanationLoaded: false,
          accessToExplanation: true,
          //
          themeHighlightColor: undefined,
          iframeParams: { autoResizable: true },
          isFullScreen: false,
          windowResizing: true,
          stepsPath,
        };
  }

  canvasRef = React.createRef();

  resizeTimeoutId = undefined;

  componentDidMount() {
    const { explanationLoaded } = this.state;
    if (!explanationLoaded) this.initiateExplanation();

    getIds().then(({ sessionId }) => {
      postMessage({
        type: 'params',
        name: 'stonlySessionId',
        value: sessionId,
      });
    });
    postMessage({ type: 'initPlugin' });
    postMessage({ type: 'guideLanguage', language: getLanguageShorthand() });

    this.updateIframeHeight();
    this.setState({ iframeParams: { ...getWidgetIframeParams() }, windowResizing: false });
    window.addEventListener('message', this.onMessage);
    window.addEventListener('resize', this.onWindowResize);
  }

  componentDidUpdate(prevProps) {
    const { userManagement } = this.props;
    if (userManagement.user.id && !prevProps.userManagement.user.id) {
      this.initiateExplanation();
    }
    const { match } = this.props;
    const { match: oldMatch } = prevProps;
    if (match.params.pathToLoad !== oldMatch.params.pathToLoad) {
      this.onWindowResize();
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ stepsPath: createPathFromParam(match.params.pathToLoad) });
    }

    this.updateIframeHeight();
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.onMessage);
    window.removeEventListener('resize', this.onWindowResize);
    clearTimeout(this.resizeTimeoutId);
  }

  onMessage = message => {
    if (message.data.type === 'iFrameParams') {
      this.setState({ iframeParams: message.data.params });
    }
  };

  onWindowResize = () => {
    this.setState({ windowResizing: true });
    clearTimeout(this.resizeTimeoutId);
    this.resizeTimeoutId = setTimeout(() => {
      this.setState({ windowResizing: false });
    }, 50);
  };

  openFullscreen = () => {
    postMessage({ type: 'stonlyWidgetFullscreenOpen' });
    this.setState({ isFullScreen: true });
  };

  closeFullscreen = () => {
    const { isFullScreen } = this.state;
    if (isFullScreen) {
      postMessage({ type: 'stonlyWidgetFullscreenClose' });
    }
    this.setState({ isFullScreen: false });
  };

  getParams = (nextprops = {}) => {
    const { match } = this.props;
    let nextParams;

    const currentParams = { id: match.params.id, pathToLoad: match.params.pathToLoad };

    if (Object.keys(nextprops).length !== 0 || nextprops.constructor !== Object) {
      if (nextprops.match) {
        nextParams = {
          id: nextprops.match.params.id,
          pathToLoad: nextprops.match.params.pathToLoad,
        };
      } else {
        nextParams = { id: nextprops.id, pathToLoad: nextprops.pathToLoad };
      }
    } else {
      nextParams = { id: null, pathToLoad: '' };
    }
    return {
      id: currentParams.id,
      pathToLoad: currentParams.pathToLoad,
      nextId: nextParams.id,
      nextPathToLoad: nextParams.pathToLoad,
    };
  };

  updateIframeHeight = () => {
    const { id } = this.getParams();
    const { updateParentContainer } = this.props;

    const explanationId = id;

    if (this.canvasRef.current && Object.keys(this.canvasRef.current).length !== 0) {
      postMessage({ FrameHeight: `${this.canvasRef.current.offsetHeight}`, iFrameId: `ston-${explanationId}` });
    }
    if (updateParentContainer) {
      updateParentContainer();
    }
  };

  initiateExplanation = async () => {
    const { match, location } = this.props;

    const { themeHighlightColor: currentThemeHighlightColor } = this.state;
    const { id } = this.getParams();

    try {
      const language = getLanguageShorthand();
      const {
        widgetId,
        segmentAnonymousId,
        segmentUserId,
        stonlyAnonymousId,
        customerUserId,
        isSnoozingEnabled,
        widgetRuleId,
      } = queryString.parse(location.search);
      const loadedGuide = await loadExplanation(id, {
        widgetId,
        segmentAnonymousId,
        segmentUserId,
        stonlyAnonymousId,
        customerUserId,
        isSnoozingEnabled: isSnoozingEnabled === '1',
        widgetRuleId,
      });
      const computedState = getProcessedState(match, location, parseGuideData(loadedGuide, language));
      if (!computedState) return;

      if (currentThemeHighlightColor) {
        const { themeHighlightColor, ...newState } = computedState;
        this.setState(newState);
      } else {
        this.setState(computedState);
      }
    } catch {
      this.setState({ explanationLoaded: true, accessToExplanation: false });
    }
  };

  setLanguage = async language => {
    const { forceUpdateAtRoot, match, history } = this.props;
    setLanguage(language);
    postMessage({ type: 'guideLanguageChanged', language });
    forceUpdateAtRoot();
    await this.initiateExplanation();
    const { guide } = this.state;
    history.replace(getNewLanguageUrl(language, match, guide.guideInfo.title));
  };

  render() {
    const {
      guide,
      isFullScreen,
      themeHighlightColor,
      accessToExplanation,
      iframeParams = {},
      windowResizing,
      explanationLoaded,
      stepsPath,
    } = this.state;

    const { theme, match } = this.props;

    const isMobileIntegration = getIsMobileIntegration();

    if (!accessToExplanation) {
      return <NoViewAccess showLink={false} showBranding={false} />;
    }

    if (!explanationLoaded) {
      return (
        <FullscreenLoaderWrap>
          <Loader monochrome text={i18n('Global.Loading')} />
        </FullscreenLoaderWrap>
      );
    }
    const { maxHeight } = this.props;

    const { title } = guide.guideInfo;

    const hasConditionalConnection = guide.allGuides
      .flatMap(({ stepNext }) => stepNext)
      .some(({ visibility }) => visibility === STEP_NEXT_VISIBILITY.externalCondition);

    let displayProgressBar = accessToExplanation ? parseBoolean(guide.guideOptions.displayProgressBar, true) : true;
    displayProgressBar = displayProgressBar && !hasConditionalConnection;

    let firstStep = null;
    const firstStepObj = guide.allGuides.find(info => info.guideId === match.params.id);
    if (firstStepObj) firstStep = firstStepObj.startId;

    let overridingTheme;
    if (themeHighlightColor && validateHexaDecimal(themeHighlightColor)) {
      overridingTheme = { ...theme, highlightColor: themeHighlightColor };
    }

    return (
      <ThemeProvider theme={overridingTheme || theme}>
        <Canvas
          ref={this.canvasRef}
          maxHeight={maxHeight}
          isFullScreen={isFullScreen}
          embedFontFamily={guide.guideOptions.embedFontFamily}
          compact={iframeParams.compact}
          className="widget-guide"
          onClickCapture={() => {
            postMessage({
              type: 'widgetClick',
            });
          }}
        >
          <fullscreenContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
              parentIsFullscreen: isFullScreen,
              openParentFullscreen: this.openFullscreen,
              closeParentFullscreen: this.closeFullscreen,
            }}
          >
            <RootScrollStyles compact={iframeParams.compact} hideScrollbar={windowResizing} />
            <WidgetHeader titleLength={title.length} compact={iframeParams.compact}>
              {iframeParams.compact ? null : <WidgetHeaderTitle>{title}</WidgetHeaderTitle>}
            </WidgetHeader>
            <PlayerContentWrap
              autoResizable={iframeParams.autoResizable}
              compact={iframeParams.compact}
              isMobileIntegration={isMobileIntegration}
            >
              <ExplanationPlayer
                mode="widget"
                guide={guide}
                firstStep={firstStep}
                stepsPath={stepsPath}
                displayProgressBar={displayProgressBar}
                setLanguage={this.setLanguage}
                compact={iframeParams.compact}
                widgetPlacement={iframeParams.placement}
                widgetSizeType={iframeParams.widgetSizeType}
              />
            </PlayerContentWrap>
          </fullscreenContext.Provider>
        </Canvas>
      </ThemeProvider>
    );
  }
}

Widget.propTypes = {
  match: PropTypes.object,
  userManagement: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  updateParentContainer: PropTypes.func,
  maxHeight: PropTypes.number,
  loadedGuide: PropTypes.object,
  guide: PropTypes.object,
  theme: PropTypes.object,
  statusCode: PropTypes.number,
  forceUpdateAtRoot: PropTypes.func,
};

export default withExplanationData(withContextProviders(withRouter(withUser(withTheme(Widget)))));
