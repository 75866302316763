import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import i18n from 'helpers/i18n';
import TextLink from '@playerCommon/StandardElements/TextLink';
import { appUrl, assetsUrl } from 'global/env';

import RibbonIconSVG from 'resources/illustrations/ribbon.svg';
import BackButton from '@playerCommon/CustomElements/BackButton';

const Canvas = styled.div`
  position: relative;
  width: 560px;
  transform: translateX(-50%);
  left: 50%;

  &:before {
    content: '';
    position: absolute;
    width: 384px;
    height: 412px;
    background-image: url('${assetsUrl}resources/illustrations/ribbonHuge.svg');
    background-size: contain;
    background-position: center center;
    transform: translate(-180px, -130px);
    z-index: -1;
  }

  @media screen and (max-width: 900px) {
    width: auto;
    transform: none;
    left: 0;
    &:before {
      display: none;
    }
  }
`;

const CongratsMessage = styled.div`
  color: ${props => props.theme.darkGrey};
  font-size: 24px;
  line-height: 1.33;
  font-weight: normal;
  margin-bottom: 16px;
`;

const NextMessage = styled.div`
  color: ${props => props.theme.darkGrey};
  font-size: 16px;
  margin-bottom: 32px;
`;

const StyledLink = styled(TextLink)`
  display: inline-block !important;
  margin-left: 24px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
`;

const RibbonIcon = styled(RibbonIconSVG)`
  width: 155px;
  height: 157px;
`;

const RibbonIconWrap = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  height: 320px;
  margin-top: -24px;

  @media screen and (max-width: 900px) {
    display: flex;
  }

  @media screen and (max-width: 480px) {
    margin-top: -56px;
  }
`;

const StyledBackButton = styled(BackButton)`
  vertical-align: middle;
`;

function ClassicFinalStep({ onBackLinkClick, previousStepLink }) {
  return (
    <Canvas>
      <RibbonIconWrap>
        <RibbonIcon />
      </RibbonIconWrap>
      <CongratsMessage data-cy="congratsMessage">{i18n('FinalStep.MainMessage')}</CongratsMessage>
      <NextMessage data-cy="nextMessage">{i18n('FinalStep.MainMessageSub')}</NextMessage>
      <StyledBackButton onClick={onBackLinkClick} link={previousStepLink} />
      <StyledLink type="slateGrey" href={appUrl} newWindow dataCy="finalButtonOpenHomepage">
        {i18n('FinalStep.LearnMore')}
      </StyledLink>
    </Canvas>
  );
}

ClassicFinalStep.propTypes = {
  onBackLinkClick: PropTypes.func,
  previousStepLink: PropTypes.string,
};

export default ClassicFinalStep;
