import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DropdownBack from '@playerCommon/CustomElements/DropdownBack/DropdownBack.js';
import { useTranslation } from 'react-i18next';
import {
  Canvas,
  StepsContainer,
  TopBar,
  AllConversationsWrapper,
  AllConversationsLink,
  ChatBubbleIcon,
} from './ConversationView.styles.js';
import ConversationInteraction from '../components/ConversationInteraction/ConversationInteraction.js';

const ConversationView = ({ aiSearchInteractionList, allAiSearchInteractionList, setView }) => {
  const [shouldShowAll, setShouldShowAll] = useState(false);
  const { t } = useTranslation();

  return (
    <Canvas>
      <TopBar>
        <DropdownBack backFunction={setView} location="mainView" />
        {!!allAiSearchInteractionList && !shouldShowAll && (
          <AllConversationsWrapper>
            <ChatBubbleIcon />
            <AllConversationsLink onClick={() => setShouldShowAll(true)}>
              {t('SessionDetail.ViewAllAiConversations')}
            </AllConversationsLink>
          </AllConversationsWrapper>
        )}
      </TopBar>
      <StepsContainer>
        {(shouldShowAll ? allAiSearchInteractionList : aiSearchInteractionList).map(aiInteraction => (
          <ConversationInteraction
            key={`${aiInteraction.questionAnswerId}-${aiInteraction.actionType}`}
            aiInteraction={aiInteraction}
          />
        ))}
      </StepsContainer>
    </Canvas>
  );
};

ConversationView.propTypes = {
  aiSearchInteractionList: PropTypes.array,
  allAiSearchInteractionList: PropTypes.array,
  setView: PropTypes.func,
};

export default ConversationView;
