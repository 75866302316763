import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { generatePath, withRouter } from 'react-router-dom';
import Loader from '@playerCommon/CustomElements/Loader/Loader';
import i18n, { getLanguageShorthand } from 'helpers/i18n';
import { setTempStorage, deleteTempStorage } from 'helpers/storage';
import TextLink from '@playerCommon/StandardElements/TextLink';
import IconDropdown from '@playerCommon/CustomElements/IconDropdown/IconDropdown.js';
import Icon from '@playerCommon/StandardElements/Icon';
import BackSVG from 'icons/leftThinChevron.svg';
import LinkSVG from 'icons/link.svg';
import DuplicateSVG from 'icons/duplicate.svg';
import { postMessage } from 'helpers/widgetHelpers';
import { trackSegment } from '@stonlyCommons/components/Analytics/recorder';
import { copyHTMLToClipboard } from '@stonlyCommons/helpers/textManagement';
import { loadExplanation } from 'helpers/explanationManagement';
import {
  getStepContent,
  getStepMedia,
  getStepTitle,
  mergeGuideStates,
  parseGuideData,
} from 'helpers/guidePlayerHelpers';
import { getImageUrl } from 'helpers/fileHelpers';
import { imagesUrl } from 'global/env';
import { useAdditionalLoadedGuidesData } from '@playerCommon/Contexts/additionalGuideLoaderContext';
import { STEP_TYPE } from 'global/index';
import { getGlobal } from 'global/windowUtils';
import Embed from '../../../../Explanation/Embed';

const Canvas = styled.div``;

const Head = styled.div`
  display: flex;
  margin-bottom: 8px;
  align-items: center;
`;

const Title = styled.div`
  font-size: 18px;
  ${({ type }) => type === 'small' && 'font-size: 14px;'}
  padding: 0 8px;
`;

const BackButtonIcon = styled(BackSVG)`
  path {
    fill: ${props => props.theme.highlightColor};
  }

  /* @noflip */
  [dir='rtl'] & {
    transform: scaleX(-1);
  }
`;

const BackTo = styled(TextLink)`
  margin-left: 8px;
  font-size: 14px;
  font-weight: 500;
  margin-top: 4px;
`;

const Actions = styled.div`
  border: 1px solid;
  border-radius: 16px;
  border-color: ${props => props.theme.lightGrey};
  display: flex;
  height: 32px;
  margin-left: auto;
  margin-right: 8px;
`;

const Sep = styled.div`
  border-right: 1px solid;
  border-color: ${props => props.theme.lightGrey};
`;

const UrlCopyIconWrap = styled.div`
  margin: 6px 12px 0 16px;
`;

const ContentCopyIcon = styled(Icon)`
  margin: 2px 12px 0 8px;
`;

const getGuideURLWithFirstStep = (match, firstStepId) => {
  return generatePath(match.path, { ...match.params, pathToLoad: `${firstStepId}` });
};

function ExplanationView({
  match,
  history,
  backTolist,
  userManagement,
  updateParentContainer,
  type,
  agentAppGuideData,
}) {
  const { mode, pathToLoad, id: explanationId } = match.params;

  const theme = useTheme();
  const [explanation, setExplanation] = useState({});
  const [explanationLoaded, setExplanationLoaded] = useState(false);

  const { additionalLoadedGuides, loadAdditionalGuide } = useAdditionalLoadedGuidesData();

  // this is to make sure that we won't fire a rerender on Embed with a new explanation state here when the guide
  // language changes for example. We only want this component to rerender, not the main one.
  const loadedGuideForEmbed = useMemo(() => {
    if (explanationLoaded) return mergeGuideStates(explanation, additionalLoadedGuides);
    return {};
  }, [explanationLoaded, additionalLoadedGuides]);

  const loadedGuideCustomCSS = useMemo(() => {
    return loadedGuideForEmbed?.guideOptions?.designSettings?.customHtml || '';
  }, [loadedGuideForEmbed]);

  useEffect(() => {
    async function onMount() {
      const urlParams = queryString.parse(window.location.search);
      const agentAppAdditionalGuidesToLoadList = (urlParams.additionalGuidesToLoad || '').split(',') || [];

      if (agentAppAdditionalGuidesToLoadList.length > 0) {
        await Promise.all(agentAppAdditionalGuidesToLoadList.map(guideId => loadAdditionalGuide(guideId)));
      }
      const loadedGuide = await loadExplanation(explanationId);

      const language = getLanguageShorthand();
      const newState = parseGuideData(loadedGuide, language);

      setExplanation(newState);
      setExplanationLoaded(true);

      setTempStorage('agentAppGuideState', newState);

      if (!pathToLoad && newState && newState.guideInfo.firstStepId) {
        history.push(getGuideURLWithFirstStep(match, newState.guideInfo.firstStepId));
      }

      trackSegment({
        url: document.location.href,
        referrer: document.referrer,
        type: 'track',
        params: {},
        event: 'agent.stonly-view',
      });
      trackSegment({
        url: document.location.href,
        referrer: document.referrer,
        type: 'trackOrg',
        params: {},
        event: 'org.agent.stonly-view',
      });
    }
    onMount();

    return () => deleteTempStorage('agentAppGuideState');
  }, []);

  const copyURL = useCallback(
    (type = 'guide') => {
      const language = getLanguageShorthand();
      const { defaultLanguage } = explanation.guideOptions;

      let { title } = explanation.guideInfo;

      let url = `https://stonly.com/guide/${explanationId}/Steps/`;

      if (type === 'step') {
        const currentStepId = pathToLoad.slice(pathToLoad.lastIndexOf(',') + 1);
        const currentStep = explanation.steps.find(s => s.stepId === +currentStepId);
        const currentStepTitle = getStepTitle(currentStep, language, defaultLanguage);
        title = `${title} > ${currentStepTitle}`;

        url = `${url}${pathToLoad}`;
      }

      postMessage({
        type: 'copyUrl',
        url,
        title,
        step: type === 'step' ? pathToLoad : '',
      });

      if (mode === 'agentAppFresh') {
        copyHTMLToClipboard(url);
      }
    },
    [explanation, explanationId, mode, pathToLoad]
  );

  const updateLoadedGuideState = useCallback(newGuideState => {
    setExplanation(newGuideState);
  }, []);

  const copyContent = useCallback(() => {
    const language = getLanguageShorthand();
    const currentStepId = pathToLoad.slice(pathToLoad.lastIndexOf(',') + 1);
    const currentStep = explanation.steps.find(s => s.stepId === +currentStepId);

    const { defaultLanguage } = explanation.guideOptions;
    const stepContent = getStepContent(currentStep, language, defaultLanguage);
    const stepMedia = getStepMedia(currentStep, language, defaultLanguage);

    const isAiStep = currentStep.stepType === STEP_TYPE.aiAnswer;
    const lastAiAnswer = getGlobal('lastAiAnswer');
    const content = isAiStep && lastAiAnswer ? lastAiAnswer : stepContent;

    const illustrations = [];
    if (stepMedia.type !== 'none' && isAiStep && lastAiAnswer) {
      stepMedia.media.forEach(media => {
        if (stepMedia.type === 'image') {
          illustrations.push({
            type: 'image',
            src: `${imagesUrl.url}/${getImageUrl(media)}`,
          });
        }
        if (stepMedia.type === 'code') {
          illustrations.push({
            type: 'code',
            content: media.content.replaceAll('<', '&lt;').replaceAll('>', '&gt;').replaceAll('\n', '<br />'),
          });
        }
        if (stepMedia.type === 'video') {
          illustrations.push({ type: 'video', url: media.url });
        }
      });
    }

    postMessage({
      type: 'copyContent',
      content,
      illustrations,
    });

    if (mode === 'agentAppFresh') {
      copyHTMLToClipboard(content);
    }
  }, [explanation, mode, pathToLoad]);

  return (
    <Canvas>
      <Head>
        <BackTo type="grey" onClick={() => backTolist('mainView')}>
          <BackButtonIcon data-cy="backButtonIcon" />
        </BackTo>
        <Title type={type}>{explanation.guideInfo ? explanation.guideInfo.title : ''}</Title>
        {['agentAppZendeskModal', 'agentAppZendeskPanel', 'agentAppFront', 'agentAppFresh', 'agentApp'].includes(
          mode
        ) && (
          <Actions>
            <UrlCopyIconWrap>
              <IconDropdown
                src={LinkSVG}
                size={16}
                tooltip="Copy URL"
                positionTooltip="down"
                background="dark"
                color={theme.slateGrey}
                colorHover={theme.freshGreen}
                options={[
                  {
                    id: 1,
                    label: 'Copy guide URL',
                    value: '',
                  },
                  {
                    id: 2,
                    label: 'Copy Step URL',
                    value: 'step',
                  },
                ]}
                globalAction={copyURL}
              />
            </UrlCopyIconWrap>
            <Sep />
            <ContentCopyIcon
              iconNode={DuplicateSVG}
              src={undefined}
              size={24}
              tooltip="Copy Content"
              positionTooltip="downRight"
              background="dark"
              color={theme.slateGrey}
              colorHover={theme.freshGreen}
              onClick={copyContent}
            />
          </Actions>
        )}
      </Head>
      {(!explanationLoaded || !pathToLoad) && <Loader text={i18n('Global.Loading')} />}
      {explanationLoaded && pathToLoad && Object.keys(explanation).length > 0 && (
        <>
          {!!loadedGuideCustomCSS && <style>{loadedGuideCustomCSS}</style>}
          <Embed
            userManagement={userManagement}
            loadedGuide={loadedGuideForEmbed}
            updateParentContainer={updateParentContainer}
            updateParentState={updateLoadedGuideState}
            maxHeight={20_000}
            match={{ params: { mode: 'embed' } }}
            source="modal"
            desktopMaxHeight="calc(80vh)"
            agentAppGuideData={agentAppGuideData}
          />
        </>
      )}
    </Canvas>
  );
}

ExplanationView.propTypes = {
  backTolist: PropTypes.func,
  userManagement: PropTypes.object,
  updateParentContainer: PropTypes.func,
  match: PropTypes.object,
  history: PropTypes.object,
  type: PropTypes.string,
};

export default withRouter(ExplanationView);
