import type { theme as DSTheme } from '@playerCommon/ui/theme';
import type { Status } from '@playerCommon/ui/models';
import { renderStatusIconForStatus } from '@playerCommon/ui/utils/status.helpers';

export const resolveIconColorForInputStatus = (theme: typeof DSTheme, status?: Status | false) => {
  if (status === 'error') {
    return theme.errorColor;
  }
  if (status === 'success') {
    return theme.seaweed;
  }
  if (status === 'warning') {
    return theme.mango;
  }

  return undefined;
};

export const resolveMessageColorForInputStatus = (theme: typeof DSTheme, status?: Status | false) => {
  if (status === 'error') {
    return theme.errorColor;
  }
  if (status === 'success') {
    return theme.seaweed;
  }
  if (status === 'warning') {
    return theme.mango;
  }
  if (status === 'info') {
    return theme.darkGray;
  }

  return undefined;
};

export const resolveBorderColorForInputStatus = (theme: typeof DSTheme, status?: Status | false) => {
  if (status === 'error') {
    return theme.errorColor;
  }
  if (status === 'success') {
    return theme.seaweed;
  }
  if (status === 'warning') {
    return theme.mango;
  }

  return undefined;
};

export const renderStatusIconForInputStatus = (status?: Status | false, iconSizeName?: '16' | '24') => {
  if (status === 'success') {
    return renderStatusIconForStatus(status, iconSizeName);
  }
  return null;
};

export const renderMessageStatusIconForInputStatus = (status?: Status | false, iconSizeName?: '16' | '24') => {
  if (status === 'success') {
    return null;
  }
  if (status === 'info') {
    return null;
  }
  return renderStatusIconForStatus(status, iconSizeName);
};
