import React from 'react';
import styled, { useTheme } from 'styled-components';
import PropTypes from 'prop-types';
import TextLink from '@playerCommon/StandardElements/TextLink';
import { unique } from 'helpers/arrayManagement.js';
import { createPathForURL } from 'helpers/explanationManagement.js';
import { secondsToMinutesAndSeconds } from 'helpers/dateManagement.js';
import Icon from '@playerCommon/StandardElements/Icon';
import { assetsUrl } from 'global/env.js';
import { useTranslation } from 'react-i18next';
import Step from '../components/Step/Step.js';

const Canvas = styled.div``;

const Title = styled.div`
  text-transform: capitalize;
  font-size: 20px;
`;

const Info = styled.div`
  margin-top: 8px;
  display: flex;
  gap: 16px;
  color: ${props => props.theme.lightGrey};
`;

const SubInfo = styled.div`
  display: flex;
`;

const Text = styled.div`
  font-size: 12px;
  font-weight: 500;
  margin-left: 4px;
`;

const SubTitle = styled.div`
  margin-top: 32px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  color: ${props => props.theme.steel};
  height: 22px;
  border-bottom: 1px solid ${props => props.theme.grey};
`;

const StepCanvas = styled.div`
  margin-top: 22px;
`;

const PathCanvas = styled.div`
  display: flex;
`;

const LinkToPath = styled(TextLink)`
  text-decoration: underline;
  margin-left: 8px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-top: -4px;
  color: ${props => props.theme.slateGrey};
`;

const Circle = styled.div`
  height: 12px;
  width: 12px;
  background-color: ${props => props.theme.lightGrey};
  border-radius: 50%;
`;

const MainView = ({ guide, setView, goToPlayer, view }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const getTotalTime = exp => {
    let totalTime = null;

    exp.steps.forEach(step => {
      totalTime += step.timing;
    });

    return secondsToMinutesAndSeconds(totalTime);
  };

  const getTotalNumberOfSteps = exp => {
    const stepsId = exp.steps.map(step => step.stepId);

    return `${unique(stepsId).length} ${t('SessionDetail.CompletedSteps')}`;
  };

  const { stepId, title, timing, aiSearchInteractionList } = guide.steps[guide.steps.length - 1];

  return (
    <Canvas>
      <Title data-cy="mainViewTitle">{guide.explanationTitle}</Title>
      <Info>
        <SubInfo>
          <Icon src={`${assetsUrl}resources/icons/timerSmall.svg`} size={16} color={theme.defaultGreen} />
          <Text>{getTotalTime(guide)}</Text>
        </SubInfo>
        <SubInfo>
          <Icon src={`${assetsUrl}resources/icons/checkSmall.svg`} size={16} color={theme.defaultGreen} />
          <Text>{getTotalNumberOfSteps(guide)}</Text>
        </SubInfo>
      </Info>

      <SubTitle>{t('SessionDetail.MainTitle')}</SubTitle>
      <StepCanvas>
        <Step
          guideId={guide.sourceId}
          stepPathForUrl={createPathForURL(guide.stepPath)}
          stepId={stepId}
          title={title}
          content={t('SessionDetail.LastVisitedStep')}
          timing={timing}
          order="first"
          goToPlayer={goToPlayer}
          view={view}
          aiSearchInteractionList={aiSearchInteractionList}
        />
        <PathCanvas>
          <Circle />
          <LinkToPath type="black" onClick={() => setView(`stepView-${guide.sourceId}`)}>
            {t('SessionDetail.ShowFullPath')}
          </LinkToPath>
        </PathCanvas>
      </StepCanvas>
    </Canvas>
  );
};

MainView.propTypes = {
  guide: PropTypes.object,
  setView: PropTypes.func,
  goToPlayer: PropTypes.func,
  view: PropTypes.string,
};

export default MainView;
