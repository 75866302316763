import { useRef, useEffect, useCallback } from 'react';
import { trackSearch as recorderTrack } from 'components/Analytics/recorder';
import { getIds } from 'helpers/statIdsManagement';

export default function useSearchTrack(results) {
  const searchTrackAction = useRef(null);
  const trackingIds = useRef({});

  const hasSearchTrackAction = () => !!searchTrackAction.current;
  const getSearchTrackAction = () => searchTrackAction.current;
  const clearSearchTrackAction = () => (searchTrackAction.current = null);
  const setSearchTrackActionData = data => {
    searchTrackAction.current = {
      ...searchTrackAction.current,
      ...data,
    };
  };
  const trackSearchAction = () => {
    if (!hasSearchTrackAction()) {
      return;
    }
    const {
      term,
      searchStartDate,
      results: searchTrackResults = [],
      resultType,
      resultId,
      id,
    } = getSearchTrackAction();

    recorderTrack({
      actionType: 'search',
      actionDetail: {
        term,
        searchStartDate,
        searchEndDate: Date.now(),
        results: searchTrackResults.map(({ guideId = null, stepId = null, folderId = null, type }) => ({
          guideId,
          stepId,
          folderId,
          type,
        })),
        resultsCount: searchTrackResults.length,
        isSuccessful: !!resultId,
        resultType,
        resultId,
        id,
      },
      ...trackingIds.current,
    });
    clearSearchTrackAction();
  };

  useEffect(() => {
    const onUnload = () => {
      if (hasSearchTrackAction()) trackSearchAction();
    };
    window.addEventListener('unload', onUnload);
    return () => {
      window.removeEventListener('unload', onUnload);
    };
  }, []);

  // We're using beacon for tracking search, that's why it is necessary to grab information about tracking ids
  // before the search request is sent, as this is the asynchronous operation that can't be done when page is unloading
  useEffect(() => {
    getIds().then(({ userId, deviceId, sessionId, stonlyAnonymousId, customerUserId }) => {
      trackingIds.current = { userId, deviceId, sessionId, stonlyAnonymousId, customerUserId };
    });
  }, []);

  const onSearchResultClick = useCallback(
    result => {
      const { type, id, folderId, guideId, stepId, name } = result;
      const idByType = {
        folder: folderId,
        guide: guideId,
        step: stepId,
        ai: name,
      };
      const resultId = idByType[type] || null;

      if (hasSearchTrackAction()) {
        setSearchTrackActionData({
          results,
          resultType: type,
          resultId,
          id,
          semanticSearch: type === 'ai',
        });
      }
      setTimeout(trackSearchAction, 0);
    },
    [results]
  );

  return {
    hasSearchTrackAction,
    setSearchTrackActionData,
    trackSearchAction,
    onSearchResultClick,
  };
}
