import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { isMobileDevice } from 'helpers/userAgentHelpers';
import { imagesUrl } from 'global/env';
import memoize from 'fast-memoize';
import { convertVideoMediaUrl } from 'helpers/fileHelpers';

function clientOnlyMemoize(func) {
  if (typeof window === 'undefined') {
    return func;
  }
  return memoize(func);
}

const Canvas = styled.div`
  position: fixed;
  height: 112px;
  width: 100%;
  z-index: 256;
  left: 0;
  bottom: 0;
  overflow-x: hidden;
  transition: opacity 0.2s, visibility 0.2s, transform 0.2s;
  will-change: visibility, opacity, transform;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0;
  background: transparent;
  border: none;

  ${isMobileDevice() &&
  css`
    height: 84px;
  `}
`;

const thumbStyle = css`
  position: relative;

  width: 88px;
  height: 88px;
  z-index: 1;
  margin-right: 12px;
  border: 1px solid ${props => props.theme.silver};
  border-radius: 1px;
  cursor: pointer;
  outline: none;
  transition: opacity 0.2s, visibility 0.2s, transform 0.2s;
  will-change: visibility, opacity, transform;

  &:last-child {
    margin-right: 0;
  }

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 0 3px ${props => props.theme.secondaryColor};
    border-radius: 1px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s, visibility 0.2s;
  }

  ${({ isCurrent, anyAddedOrRemoved }) =>
    isCurrent &&
    !anyAddedOrRemoved &&
    css`
      &:after {
        opacity: 1;
        visibility: visible;
      }
    `}

  ${isMobileDevice() &&
  css`
    width: 60px;
    height: 60px;
  `}

  body.navigating-with-keyboard &:focus {
    box-shadow: 0px 0px 0px 3px ${props => props.theme.darkBlue};
  }
`;

const ImageThumb = styled.div`
  background-image: url(${({ src, loadImagesFromRoot }) =>
    `${loadImagesFromRoot ? '' : imagesUrl.url}/${src}&w=176&h=176&fit=crop`});
  background-size: cover;
  background-position: center;
  ${thumbStyle}
`;

const VideoThumb = styled.video`
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;

  &::-internal-media-controls-overlay-cast-button {
    display: none;
  }
`;

const VideoWrap = styled.div`
  ${thumbStyle}
`;

const getIllustationsFormatted = clientOnlyMemoize(illustrations => {
  return illustrations.map(image => ({
    key: image.imageId,
    content: `${image.path}/${image.imageId}.${image.ext}?s=${image.imageIdSecureHash}`,
    isVideo: ['mp4', 'gif'].includes(image.ext),
  }));
});

const ImageGallery = ({ stepIllustrations, currentImage, changeCurrentImage, loadImagesFromRoot }) => {
  const onKeyDown = (e, imageIndex) => {
    if ([13, 32].includes(e.keyCode)) {
      e.preventDefault();
      changeCurrentImage(imageIndex);
    }
  };

  const illustrations = getIllustationsFormatted(stepIllustrations);

  return (
    <Canvas>
      {illustrations.map((illustration, index) => (
        <React.Fragment key={illustration.key}>
          {illustration.isVideo ? (
            <VideoWrap
              onClick={() => changeCurrentImage(index)}
              onKeyDown={e => onKeyDown(e, index)}
              isCurrent={currentImage === index}
              tabIndex="0"
              added={illustration.added}
              removed={illustration.removed}
            >
              <VideoThumb
                src={convertVideoMediaUrl(`${imagesUrl.url}/${illustration.content}`)}
                controls={false}
                playsInline
                autoPlay
                muted
                loop
              />
            </VideoWrap>
          ) : (
            <ImageThumb
              key={illustration.content}
              index={index}
              onClick={() => changeCurrentImage(index)}
              onKeyDown={e => onKeyDown(e, index)}
              src={illustration.content}
              isCurrent={currentImage === index}
              tabIndex="0"
              loadImagesFromRoot={loadImagesFromRoot}
            />
          )}
        </React.Fragment>
      ))}
    </Canvas>
  );
};

ImageGallery.propTypes = {
  stepIllustrations: PropTypes.array,
  currentImage: PropTypes.number,
  changeCurrentImage: PropTypes.func,
  loadImagesFromRoot: PropTypes.bool,
};

export default memo(ImageGallery);
