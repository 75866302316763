import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const circleProgress = keyframes`
  0% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: calc(3.14 * 100%);
  }
`;

const Wrapper = styled.svg`
  width: 100%;
  height: 100%;

  circle {
    fill: transparent;
    stroke: ${({ iconColor }) => iconColor};
    opacity: 0.2;
    stroke-width: 2px;
    stroke-linecap: round;
    transform-origin: 50% 50% 0;
  }

  .progress {
    opacity: 1;
    stroke-dasharray: calc(3.14 * 100%);
    animation: ${circleProgress} linear reverse forwards;
    transform: rotate(-90deg);
    animation-iteration-count: ${({ isPreview }) => (isPreview ? 'infinite' : 1)};
    animation-duration: ${({ animationDuration }) => `${animationDuration}s`};
  }

  .accelerated {
    animation-duration: 100ms;
  }
`;

const ProgressCircle = ({ iconColor, isPreview, animationDuration, shouldAccelerateAnimation }) => (
  <Wrapper iconColor={iconColor} isPreview={isPreview} animationDuration={animationDuration}>
    <circle cx="50%" cy="50%" r="48%" />
    <circle className="progress" cx="50%" cy="50%" r="48%" />
    {shouldAccelerateAnimation && <circle className="progress accelerated" cx="50%" cy="50%" r="48%" />}
  </Wrapper>
);

ProgressCircle.propTypes = {
  iconColor: PropTypes.string,
  isPreview: PropTypes.bool,
  animationDuration: PropTypes.number,
  shouldAccelerateAnimation: PropTypes.bool,
};

export default ProgressCircle;
