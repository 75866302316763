import React, { memo, useState, useEffect, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import useInput from '@playerCommon/hooks/useInput';
import { useSentData } from '@playerCommon/Contexts/sentDataContext';
import { useUserData } from '@playerCommon/Contexts/userDataContext';
import { useServerCallData } from '@playerCommon/Contexts/serverCallDataContext';
import { extractBaseData, trackNPSStat, track } from 'components/Analytics/recorder';
import { postMessage } from 'helpers/widgetHelpers';
import { getIds } from 'helpers/statIdsManagement';
import { replaceVarNamesWithValues } from '@stonlyCommons/helpers/guideVariableHelpers.js';
import BackButton from '@playerCommon/CustomElements/BackButton';
import { useTranslation } from 'react-i18next';
import InternalNote from '@playerCommon/StandardElements/ContentEditable/InternalNote/InternalNote.player';
import { NPS_COLORS_MAP } from '@stonlyCommons/constants/Nps.consts';
import { NPS_STEPS, ratings } from './Nps.consts';
import {
  TitleContent,
  Title,
  Content,
  RatingsWrap,
  RatingNumber,
  Rating,
  LabelsWrap,
  Label,
  StyledTextarea,
  ButtonsWrap,
  SubmitButton,
  ThankYou,
  SuccessIcon,
  StyledBackButton,
  Canvas,
  SkipButton,
} from './Nps.styles';

const { NPS, QUESTION, THANK_YOU, THANK_YOU_SKIPPED } = NPS_STEPS;

const Nps = ({
  npsStepInfo,
  isFirstStep,
  previousUrlToLoad,
  isPreview,
  view,
  guide = {},
  location,
  stepId,
  onBackLinkClick,
  onStepNextClick,
  internalNotes,
}) => {
  const [step, setStep] = useState(NPS);
  const [ratingPicked, setRatingPicked] = useState(queryString.parse(location.search).score);
  const [answer, setAnswer] = useInput('');

  const { t } = useTranslation();

  const {
    content: { ratingText, followupQuestion, lowestValueLabel, highestValueLabel },
    properties: { askFollowupQuestion, allowSkip },
    npsModuleId,
    npsModuleGuideId: originalGuideId,
  } = npsStepInfo;

  const { localData } = useSentData();
  const userData = useUserData();
  const { serverCallVariables } = useServerCallData();

  const { ratingTextToDisplay, followupQuestionToDisplay } = useMemo(() => {
    return {
      ratingTextToDisplay: replaceVarNamesWithValues({
        content: ratingText,
        userData,
        localData,
        serverCallVariables,
      }),
      followupQuestionToDisplay: replaceVarNamesWithValues({
        content: followupQuestion,
        userData,
        localData,
        serverCallVariables,
      }),
    };
  }, [ratingText, followupQuestion, localData, userData, serverCallVariables]);

  const ratingNextSteps = (guide.stepConnectionList || [])
    .filter(el => el.conditionList && el.toStepId && el.fromStepId === stepId)
    .map(el => ({ ...el, stepConnectionId: el.id }));
  const hasSkipStep = ratingNextSteps.some(el => el.conditionList[0] === -1);

  async function onRatingClick(rating) {
    if (!isPreview) {
      setRatingPicked(rating);

      const baseData = await extractBaseData();

      postMessage({
        type: 'npsScoreClicked',
        score: rating,
        sessionId: baseData.sessionId,
        customerUserId: baseData.customerUserId,
        guideId: originalGuideId,
        stepId,
      });

      if (view !== 'PreviewSteps') {
        trackNPSStat({
          ...baseData,
          actionType: 'nps',
          originalGuideId,
          stepModuleId: npsModuleId,
          score: rating,
          response: '',
        });
      }

      const option = ratingNextSteps.find(el => el.conditionList.includes(rating)) || {};

      if (askFollowupQuestion) {
        setStep(QUESTION);
      } else if (option.toStepId) {
        onStepNextClick(option);
      } else {
        setStep(THANK_YOU);
      }
    }
  }

  const handleKeyDown = (e, rating) => {
    if (e.key === ' ' || e.key === 'Enter') {
      onRatingClick(rating);
    }
  };

  useEffect(() => {
    if (ratingPicked !== undefined) {
      onRatingClick(ratingPicked);
    }
  }, []);

  async function onSubmitClick() {
    const baseData = await extractBaseData();

    postMessage({
      type: 'npsScoreSubmitted',
      score: ratingPicked,
      response: answer,
      sessionId: baseData.sessionId,
      customerUserId: baseData.customerUserId,
      guideId: originalGuideId,
      stepId,
    });

    if (view !== 'PreviewSteps') {
      trackNPSStat({
        ...baseData,
        actionType: 'nps',
        originalGuideId,
        stepModuleId: npsModuleId,
        score: ratingPicked,
        response: answer,
      });
    }

    const option = ratingNextSteps.find(el => el.conditionList.includes(ratingPicked)) || {};

    if (option.toStepId) {
      onStepNextClick(option);
    } else {
      setStep(THANK_YOU);
    }
  }

  async function onSkipClick() {
    const { sessionId, customerUserId } = await getIds();
    postMessage({ type: 'npsScoreSkipped', sessionId, customerUserId, guideId: originalGuideId, stepId });
    track({
      actionType: 'npsSkipped',
      originalGuideId,
      actionDetail: {
        stepModuleStaticId: npsModuleId,
      },
    });

    const option = ratingNextSteps.find(el => el.conditionList.includes(-1)) || {};

    if (option.toStepId) {
      onStepNextClick(option);
    } else {
      setStep(THANK_YOU_SKIPPED);
    }
  }

  const shouldShowLabels = !!lowestValueLabel || !!highestValueLabel;
  const shouldShowBackButton = !isFirstStep;
  const shouldShowSkipButton = !!allowSkip && !!hasSkipStep;

  return (
    <Canvas>
      {step === NPS && (
        <>
          <TitleContent className="nps-description" data-cy="titleContent" text={ratingTextToDisplay} />
          <Content className="nps-rating-scale">
            <RatingsWrap>
              {ratings.map(rating => {
                return (
                  <Rating
                    data-cy="rating"
                    key={rating}
                    color={NPS_COLORS_MAP[rating]}
                    onClick={() => onRatingClick(rating)}
                    tabIndex={isPreview ? -1 : 0}
                    role="button"
                    onKeyDown={e => handleKeyDown(e, rating)}
                    isPreview={isPreview}
                  >
                    <RatingNumber>{rating}</RatingNumber>
                  </Rating>
                );
              })}
            </RatingsWrap>
            {shouldShowLabels && (
              <LabelsWrap>
                <Label className="nps-label">{lowestValueLabel}</Label>
                <Label className="nps-label">{highestValueLabel}</Label>
              </LabelsWrap>
            )}
          </Content>
          {internalNotes.map(internalNote => (
            <InternalNote
              key={internalNote.id}
              added={internalNote.added}
              removed={internalNote.removed}
              content={internalNote.content}
            />
          ))}
          {(shouldShowBackButton || shouldShowSkipButton) && (
            <ButtonsWrap>
              {shouldShowBackButton && (
                <BackButton
                  className="nps-back-button"
                  link={previousUrlToLoad}
                  onClick={onBackLinkClick}
                  disabled={isPreview}
                />
              )}
              {shouldShowSkipButton && (
                <SkipButton
                  className="nps-skip-button"
                  dataCy="skipButton"
                  isFirstStep={isFirstStep}
                  background="filledGreyBorder"
                  onClick={onSkipClick}
                  content={t('Nps.Skip')}
                  disabled={isPreview}
                />
              )}
            </ButtonsWrap>
          )}
        </>
      )}
      {step === QUESTION && (
        <>
          <TitleContent
            className="nps-followup-question"
            data-cy="followUpTitleContent"
            text={followupQuestionToDisplay}
          />
          <StyledTextarea
            className="nps-followup-answer"
            dataCy="followUpAnswer"
            value={answer}
            onChange={setAnswer}
            placeholder={t('Nps.FollowupQuestionPlaceholder')}
            maxLength={1000}
          />
          <ButtonsWrap>
            {shouldShowBackButton && (
              <BackButton className="nps-back-button" link={previousUrlToLoad} onClick={onBackLinkClick} />
            )}
            <SubmitButton
              className="nps-submit-button"
              dataCy="npsSubmitButton"
              background="highlight"
              onClick={onSubmitClick}
              content={t('Nps.Submit')}
            />
          </ButtonsWrap>
        </>
      )}
      {step === THANK_YOU && (
        <ThankYou>
          <SuccessIcon />
          <Title className="nps-thank-you" data-cy="npsThankYouTitle">
            {t('Nps.ThankYouForFeedback')}
          </Title>
          {shouldShowBackButton && (
            <StyledBackButton className="nps-back-button" link={previousUrlToLoad} onClick={onBackLinkClick} />
          )}
        </ThankYou>
      )}
      {step === THANK_YOU_SKIPPED && (
        <ThankYou>
          <SuccessIcon />
          <Title className="nps-thank-you-skip">{t('Nps.ThankYou')}</Title>
          {shouldShowBackButton && (
            <StyledBackButton className="nps-back-button" link={previousUrlToLoad} onClick={onBackLinkClick} />
          )}
        </ThankYou>
      )}
    </Canvas>
  );
};

Nps.propTypes = {
  npsStepInfo: PropTypes.object,
  isFirstStep: PropTypes.bool,
  previousUrlToLoad: PropTypes.string,
  isPreview: PropTypes.bool,
  view: PropTypes.string,
  guide: PropTypes.object,
  location: PropTypes.object,
  stepId: PropTypes.number,
  onStepNextClick: PropTypes.func,
  internalNotes: PropTypes.array,
};

export default memo(withRouter(Nps));
