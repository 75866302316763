import styled, { css } from 'styled-components';
import UnsafeHtml from '@playerCommon/StandardElements/UnsafeHtml/UnsafeHtml';
import {
  playerContentTextStyles,
  mobilePlayerContentTextStyle,
  variableStyles,
} from '@playerCommon/CommonStyledComponents/Typography';

export const Title = styled.div`
  font-size: 24px;
  line-height: 40px;
  margin-bottom: 16px;
`;

export const Subtitle = styled.div`
  font-size: 16px;
  line-height: 24px;
`;

export const ContactFormError = styled.div`
  margin-top: 4px;
  margin-bottom: -15px;
  font-size: 11px;
  font-weight: 500;
  line-height: 1;
  color: ${props => props.theme.errorColor};
`;

export const ContactFormBody = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  flex-grow: 1;

  ${({ locked }) =>
    locked &&
    css`
      overflow: hidden;
    `}
`;

export const ButtonsWrap = styled.div`
  display: flex;
  margin-top: 24px;
`;

export const Canvas = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  border-radius: 8px;
  background: white;
  color: ${props => props.theme.darkGrey};

  @media screen and (max-width: 899px) {
    max-height: calc(100% - 108px);
    padding: 0 ${({ theme }) => theme.stepsContentPaddings.contentPadding}px;
  }

  @media screen and (max-width: 560px) {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    right: 0;
    bottom: 0;
  }

  &:has(+ .snooze-button) {
    margin-bottom: 16px;
  }
`;

export const MainContent = styled(UnsafeHtml)`
  color: ${props => props.theme.darkGrey};
  margin-bottom: 32px;
  ${playerContentTextStyles};
  ${variableStyles}

  @media screen and (max-width: 899px) {
    ${mobilePlayerContentTextStyle};
  }
`;
