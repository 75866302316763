import React from 'react';
import styled from 'styled-components';
import { ListItemStyles } from './_shared/styles';

const Text = styled.div`
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  text-transform: uppercase;
  color: ${props => props.theme.steel};
`;

const RightIconContainer = styled.div`
  margin-left: 6px;
`;

export interface ListSectionHeadingProps extends React.HTMLAttributes<HTMLDivElement> {
  iconRight?: React.ReactNode;
}

export const ListSectionHeading = ({ iconRight, children, ...restDivAttributes }: ListSectionHeadingProps) => (
  <ListItemStyles.Container {...restDivAttributes}>
    <Text>{children}</Text>
    {iconRight && <RightIconContainer>{iconRight}</RightIconContainer>}
  </ListItemStyles.Container>
);
