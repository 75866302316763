import React, { Component } from 'react';
import { view as recorderView } from 'components/Analytics/recorder';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { setCookie, getParsedCookie } from 'global/windowUtils';
import userContext from '@playerCommon/Contexts/userContext';

class StonlyAnalytics extends Component {
  // eslint-disable-next-line react/static-property-placement
  static contextType = userContext;

  componentDidMount() {
    const { location, match } = this.props;
    const { user } = this.context;

    try {
      if (
        ['widget', 'kb-widget'].includes(match.params.mode) &&
        window.sessionStorage &&
        !window.sessionStorage.getItem('referrer')
      ) {
        window.sessionStorage.setItem('referrer', document.referrer);
      }
    } catch {
      window.s_referrer = document.referrer;
    }

    this.timeoutHandler = null;

    if (match.params.mode && match.params.id) {
      const page = `${location.pathname}${location.search}`;
      // Initial page load - only fired once
      const userLoggedIn = Object.keys(user).length > 0; // required for the initial load unfortunately
      recorderView({ uri: page, initial: true, logged: userLoggedIn });
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    // extend tracking cookie life
    const parsedCookie = getParsedCookie();
    if (parsedCookie.signUpPageLanding) {
      const landingPageURL = parsedCookie.signUpPageLanding;
      setCookie('signUpPageLanding', encodeURIComponent(landingPageURL), 30 * 60); // extend to 30 minutes again
    }

    // When props change, check if the URL has changed or not
    const { location, match } = this.props;
    if (location.pathname !== nextProps.location.pathname || location.search !== nextProps.location.search) {
      clearTimeout(this.timeoutHandler);

      if (match.params.mode && match.params.id) {
        const page = `${nextProps.location.pathname}${nextProps.location.search}`;

        this.timeoutHandler = setTimeout(() => {
          recorderView({ uri: page });
        }, 300);
      }
    }
  }

  render() {
    return null;
  }
}

StonlyAnalytics.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
};

const RouteTracker = () => <Route component={StonlyAnalytics} />;

export default {
  StonlyAnalytics,
  RouteTracker,
};
