const addSalesforceChatListeners = () => {
  try {
    window.embedded_svc.addEventHandler('afterMinimize', () => {
      if (this.salesforceTriggeredByStonly) {
        window.embedded_svc.hideHelpButton();
      }
    });
    window.embedded_svc.addEventHandler('afterDestroy', () => {
      if (this.salesforceTriggeredByStonly) {
        window.embedded_svc.hideHelpButton();
      }
    });
  } catch (e) {
    // we don't really care
  }
};

export const initializeSalesforceChat = isWidget => {
  try {
    window.embedded_svc.hideHelpButton();
  } catch (e) {
    // we don't really care
  }
  if (!isWidget) addSalesforceChatListeners();
};
