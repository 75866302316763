import React from 'react';
import PropTypes from 'prop-types';
import { StyledToggleArrow, ToggleArrowLeftBar, ToggleArrowRightBar } from './ToggleArrow.styles';

const ToggleArrow = ({ isActive, color }) => {
  return (
    <StyledToggleArrow>
      <ToggleArrowLeftBar isArrowOpen={isActive} color={color} />
      <ToggleArrowRightBar isArrowOpen={isActive} color={color} />
    </StyledToggleArrow>
  );
};

ToggleArrow.propTypes = {
  isActive: PropTypes.bool,
  color: PropTypes.string,
};
export default ToggleArrow;
