import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Markup } from 'interweave';
import Icon from '@playerCommon/StandardElements/Icon';
import CheckboxTickSVG from 'icons/checkboxTick.svg';

const Canvas = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
    `}
`;

export const IconTick = styled(Icon)`
  position: absolute;

  ${({ iconSize }) => {
    if (iconSize === 'normal') {
      return css`
        svg {
          top: 0px;
          left: -1px;
          width: 12px;
          height: 12px;
        }
      `;
    }

    return css`
      top: -2px;
      left: -2px;
    `;
  }}
`;

const CheckBoxLabel = styled.div`
  font-size: 14px;
  line-height: 1;
  margin-left: 8px;
  user-select: none;

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${props => props.theme.grey};
    `}

  .tip-wrapper {
    cursor: default;
    display: inline;
    position: absolute;
  }

  p {
    margin: 0;
    display: inline;
  }

  a {
    color: ${props => props.theme.mainColor};
    text-decoration: underline;
  }
`;

const StyledCheckBox = styled.div`
  display: inline-block;
  position: relative;
  flex-shrink: 0;
  ${({ size }) => {
    if (size === 'normal') {
      return css`
        width: 16px;
        height: 16px;
        border-width: 2px;
      `;
    }

    if (size === 'small') {
      return css`
        width: 12px;
        height: 12px;
        border-width: 2px;
      `;
    }

    return css`
      width: 9px;
      height: 9px;
      border-width: 3px;
    `;
  }}
  border-style: solid;
  border-color: ${({ checked, theme }) => (checked ? theme.highlightColor : theme.grey)};
  background: ${({ checked, theme }) => (checked ? theme.highlightColor : theme.white)};
  border-radius: 3px;
  transition: all 0.2s;

  @media not all and (pointer: coarse), (min--moz-device-pixel-ratio: 0) {
    &:hover {
      border-color: ${({ theme }) => theme.highlightColor};
    }
  }

  @media not all and (pointer: coarse), (min--moz-device-pixel-ratio: 0) {
    ${Canvas}:hover & {
      border-color: ${({ theme }) => theme.highlightColor};
    }
  }

  ${IconTick} {
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  }

  ${({ disabled, theme, checked }) =>
    disabled &&
    css`
      background-color: ${checked ? theme.grey : theme.paleGrey};
      border-color: ${theme.silver} !important;
    `}
`;

const RequiredLabel = styled.span`
  color: ${({ theme }) => theme.pink};
  margin-left: 2px;
`;

const CheckInput = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  top: 0;

  body.navigating-with-keyboard &:focus + ${StyledCheckBox} {
    box-shadow: 0px 0px 0px 2px ${props => props.theme.white}, 0px 0px 0px 4px ${props => props.theme.darkBlue};
  }
`;

const Checkbox = ({
  id,
  checked,
  onClick,
  label,
  size = 'small',
  dataCy,
  disabled,
  className,
  tip,
  customTickIconSrc,
  required,
  invalid,
  stonlyTrigger,
}) => {
  const inputId = id && `checkbox-${id}`;

  const handleKeyDown = e => {
    if (e.keyCode === 13 && onClick) {
      onClick();
    }
  };

  return (
    <Canvas
      onClick={disabled ? () => {} : onClick}
      className={className}
      data-cy={dataCy ? `${dataCy}-input` : undefined}
      data-stonly-trigger={stonlyTrigger}
      disabled={disabled}
    >
      <CheckInput type="checkbox" onKeyDown={handleKeyDown} tabIndex={disabled ? -1 : 0} />
      <StyledCheckBox
        id={inputId}
        disabled={disabled}
        checked={checked}
        size={size}
        data-cy={dataCy}
        className="checkbox-input"
        aria-invalid={invalid}
        aria-errormessage={invalid ? `status-message-${id}` : undefined}
      >
        <IconTick iconSize={size} src={customTickIconSrc} iconNode={customTickIconSrc ? null : CheckboxTickSVG} />
      </StyledCheckBox>
      <CheckBoxLabel htmlFor={inputId} className="checkbox-label" disabled={disabled}>
        {typeof label === 'string' ? <Markup content={label} /> : label}
        {required && <RequiredLabel className="checkbox-label-asterisk">*</RequiredLabel>}
        {tip && (
          <div className="tip-wrapper" role="presentation" onClick={e => e.stopPropagation()}>
            {tip}
          </div>
        )}
      </CheckBoxLabel>
    </Canvas>
  );
};

Checkbox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  size: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  dataCy: PropTypes.string,
  customTickIconSrc: PropTypes.string,
  tip: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  invalid: PropTypes.bool,
  stonlyTrigger: PropTypes.string,
};

export default Checkbox;
