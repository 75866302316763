import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { BaseInput } from './Input.styles';

const IconWrap = styled(BaseInput.IconWrap)`
  margin: 8px 12px 0 0;
  align-items: flex-start;
`;

const ElementWrap = styled(BaseInput.ElementWrap)`
  min-height: 80px;
  height: auto;
  align-items: flex-start;
`;

const TextareaElement = styled(TextareaAutosize)`
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  font-family: inherit;
  color: ${({ theme }) => theme.darkGrey};
  width: 100%;
  min-width: 0;
  border: 0;
  outline: 0;
  padding: 0;
  margin: 0;
  background: transparent;
  appearance: none;
  resize: none;
  padding: 8px 12px;

  &::placeholder {
    color: ${({ theme }) => theme.lightGrey};
  }
`;

export const Textarea = {
  IconWrap,
  ElementWrap,
  TextareaElement,
};
