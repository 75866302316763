import styled, { css } from 'styled-components';

export const StyledToggleArrow = styled.div`
  height: 16px;
  width: 16px;
  display: block;
  position: relative;
  cursor: pointer;
`;

export const ToggleArrowLeftBar = styled.span`
  position: absolute;
  background-color: transparent;
  top: 7px;
  left: 1px;
  display: block;
  transform: rotate(45deg);
  float: right;
  border-radius: 2px;
  &:after {
    content: '';
    background-color: ${props => props.color};
    width: 8px;
    height: 1.5px;
    display: block;
    float: right;
    border-radius: 6px 10px 10px 6px;
    transition: all 0.5s cubic-bezier(0.25, 1.7, 0.35, 0.8);
    z-index: -1;
  }

  ${({ isArrowOpen }) =>
    isArrowOpen &&
    css`
      &:after {
        transform-origin: center center;
        transform: rotate(-90deg);
      }
    `}
`;
export const ToggleArrowRightBar = styled.span`
  position: absolute;
  background-color: transparent;
  top: 7px;
  left: 6px;
  display: block;
  transform: rotate(-45deg);
  float: right;
  border-radius: 2px;
  &:after {
    content: '';
    background-color: ${props => props.color};
    width: 8px;
    height: 1.5px;
    display: block;
    float: right;
    border-radius: 10px 6px 6px 10px;
    transition: all 0.5s cubic-bezier(0.25, 1.7, 0.35, 0.8);
    z-index: -1;
  }

  ${({ isArrowOpen }) =>
    isArrowOpen &&
    css`
      &:after {
        transform-origin: center center;
        transform: rotate(90deg);
      }
    `}
`;
