import React from 'react';
import SmileyAngrySVG from 'icons/smiley-angry.svg';
import SmileyAngry48SVG from 'icons/smiley-angry-48.svg';
import SmileySadSVG from 'icons/smiley-sad.svg';
import SmileySad48SVG from 'icons/smiley-sad-48.svg';
import SmileyNeutralSVG from 'icons/smiley-neutral.svg';
import SmileyNeutral48SVG from 'icons/smiley-neutral-48.svg';
import SmileyHappySVG from 'icons/smiley-happy.svg';
import SmileyHappy48SVG from 'icons/smiley-happy-48.svg';
import SmileyStarEyesSVG from 'icons/smiley-star-eyes.svg';
import SmileyStarEyes48SVG from 'icons/smiley-star-eyes-48.svg';
import ThumbsDownSVG from 'icons/thumbsDown-16.svg';
import ThumbsDown48SVG from 'icons/thumbsDown-48.svg';
import ThumbsUpSVG from 'icons/thumbsUp-16.svg';
import ThumbsUp48SVG from 'icons/thumbsUp-48.svg';

export const RATING_STYLES = {
  STARS: 'STARS',
  SMILEYS: 'SMILEYS',
  THUMBS: 'THUMBS',
  NUMBERS: 'NUMBERS',
};

export const ICON_STYLES = {
  ICONS: 'ICONS',
  EMOJIS: 'EMOJIS',
};

export const smileyIconMap = {
  1: <SmileyAngrySVG />,
  2: <SmileySadSVG />,
  3: <SmileyNeutralSVG />,
  4: <SmileyHappySVG />,
  5: <SmileyStarEyesSVG />,
};

export const largeSmileyIconMap = {
  1: <SmileyAngry48SVG />,
  2: <SmileySad48SVG />,
  3: <SmileyNeutral48SVG />,
  4: <SmileyHappy48SVG />,
  5: <SmileyStarEyes48SVG />,
};

export const smileyEmojiMap = {
  1: '😡',
  2: '😞',
  3: '😐',
  4: '😊',
  5: '😍',
};

export const thumbsIconMap = {
  1: <ThumbsDownSVG />,
  2: <ThumbsUpSVG />,
};

export const largeThumbsIconMap = {
  1: <ThumbsDown48SVG />,
  2: <ThumbsUp48SVG />,
};

export const thumbsEmojiMap = {
  1: '👎',
  2: '👍',
};

export const numberEmojiMap = {
  0: '0️⃣',
  1: '1️⃣',
  2: '2️⃣',
  3: '3️⃣',
  4: '4️⃣',
  5: '5️⃣',
  6: '6️⃣',
  7: '7️⃣',
  8: '8️⃣',
  9: '9️⃣',
  10: '🔟',
};

export const getAdjustedOptionIndex = (ratingStyle, ratingScale, optionIndex) => {
  if (ratingStyle === RATING_STYLES.SMILEYS) {
    if (ratingScale === 2) {
      // 2, 4 -> 1, 2
      return optionIndex / 2;
    }
    if (ratingScale < 5) {
      // 2, 3, 4    -> 1, 2, 3
      // 2, 3, 4, 5 -> 1, 2, 3, 4
      return optionIndex - 1;
    }
  }
  if ((ratingStyle === RATING_STYLES.NUMBERS || ratingStyle === RATING_STYLES.THUMBS) && ratingScale === 2) {
    // 1, 2 -> 0, 1
    return optionIndex - 1;
  }
  return optionIndex;
};

export const ratingOptionIndexListByRatingStyleAndScale = {
  [RATING_STYLES.STARS]: {
    2: [2, 1],
    3: [3, 2, 1],
    4: [4, 3, 2, 1],
    5: [5, 4, 3, 2, 1],
    6: [6, 5, 4, 3, 2, 1],
    7: [7, 6, 5, 4, 3, 2, 1],
    8: [8, 7, 6, 5, 4, 3, 2, 1],
    9: [9, 8, 7, 6, 5, 4, 3, 2, 1],
    10: [10, 9, 8, 7, 6, 5, 4, 3, 2, 1],
  },
  [RATING_STYLES.SMILEYS]: {
    2: [4, 2],
    3: [4, 3, 2],
    4: [5, 4, 3, 2],
    5: [5, 4, 3, 2, 1],
  },
  [RATING_STYLES.THUMBS]: {
    2: [2, 1],
  },
  [RATING_STYLES.NUMBERS]: {
    2: [2, 1],
    3: [3, 2, 1],
    4: [4, 3, 2, 1],
    5: [5, 4, 3, 2, 1],
    6: [6, 5, 4, 3, 2, 1],
    7: [7, 6, 5, 4, 3, 2, 1],
    8: [8, 7, 6, 5, 4, 3, 2, 1],
    9: [9, 8, 7, 6, 5, 4, 3, 2, 1],
    10: [10, 9, 8, 7, 6, 5, 4, 3, 2, 1],
  },
};
