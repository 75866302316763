import { useParams } from 'react-router-dom';
import { getParsedCookie, setCookie } from 'global/windowUtils';
import { playerUrl, appUrl } from 'global/env';
import useUserManagement from '@playerCommon/hooks/useUserManagement';
import useAuthenticationPopup from './useAuthenticationPopup';
import { SIGN_IN_HASH, AUTHENTICATION_VIEWS } from './Authentication.consts';

const emptyObj = {};

const setReturnUrl = returnUrl => {
  if (returnUrl && typeof returnUrl === 'string') {
    setCookie('returnUrl', encodeURIComponent(returnUrl));
  } else {
    setCookie('returnUrl', encodeURIComponent(`${appUrl}/app/general`));
  }
};

const useAuthenticationFlow = () => {
  const { openAuthenticationPopup } = useAuthenticationPopup();
  const userManagement = useUserManagement();
  const isUserSignIn = userManagement.user && Object.keys(userManagement.user).length !== 0;
  const { mode } = useParams();
  const isWidget = mode === 'widget';
  const isKb = mode === 'kb';

  const onOpenSignUp = ({ returnUrl, postAuthenticationAction } = emptyObj) => {
    if (isUserSignIn) {
      window.location.href = `${appUrl}/app/general`;
      return;
    }

    setReturnUrl(returnUrl);

    if (postAuthenticationAction && typeof postAuthenticationAction === 'string') {
      setCookie('postAuthenticationAction', postAuthenticationAction);
    }

    window.location.href = `${playerUrl}/getting-started`;
  };

  const onOpenSignIn = ({ returnUrl } = emptyObj) => {
    setReturnUrl(returnUrl);

    const parsedCookie = getParsedCookie();
    if (parsedCookie.sso) {
      if (isWidget) {
        window.open(`${playerUrl}/authentication/signInWithSSO`, '_blank');
      } else {
        window.location.href = `${playerUrl}/authentication/signInWithSSO`;
      }
    } else {
      if (!isKb) window.history.pushState({}, '', SIGN_IN_HASH);
      openAuthenticationPopup(AUTHENTICATION_VIEWS.SIGN_IN);
    }
  };

  return {
    onOpenSignIn,
    onOpenSignUp,
  };
};

export default useAuthenticationFlow;
