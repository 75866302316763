/* eslint-disable react/jsx-no-constructed-context-values */
import React, { Component } from 'react';
import styled, { withTheme, ThemeProvider, css } from 'styled-components';
import PropTypes from 'prop-types';
import i18n, { getLanguageShorthand } from 'helpers/i18n';
import { setLanguage } from 'helpers/i18n.helpers';
import { RIGHT, STEP_NEXT_VISIBILITY } from 'global';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import {
  openFullscreen,
  closeFullscreen,
  fullscreenEnabled,
  isFullscreen,
  addOnFullscreenChangeListener,
  removeOnFullscreenChangeListener,
} from '@playerCommon/helpers/windowManagement';
import { createPathFromParam, loadExplanation, getNewLanguageUrl } from 'helpers/explanationManagement.js';
import withUser from '@playerCommon/HOC/withUser/withUser';
import Loader, { FullscreenLoaderWrap } from '@playerCommon/CustomElements/Loader/Loader';
import { validateHexaDecimal } from 'helpers/validationHelpers.js';
import NoViewAccess from '@playerCommon/Commons/NoViewAccess';
import fullscreenContext from '@playerCommon/Contexts/fullscreenContext';
import ExplanationPlayer from '@playerCommon/ComplexElements/ExplanationPlayer';
import withExplanationData from 'HOC/withExplanationData';
import withContextProviders from 'HOC/withContextProviders';
import { postMessage } from 'helpers/widgetHelpers';
import { parseGuideData } from 'helpers/guidePlayerHelpers';
import { parseBoolean } from '@playerCommon/helpers/booleanHelpers';
import { getIds } from 'helpers/statIdsManagement';

const Canvas = styled.div`
  background-color: ${props => props.theme.white};
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}px`};
  display: flex;
  flex-direction: column;

  width: 1px;
  min-width: 100%;
  position: relative;
  height: ${({ view }) => (view === 'flowchart' ? '800px' : 'initial')};
  ${({ embedFontFamily }) => embedFontFamily && `font-family: '${embedFontFamily}'`};
`;

const EmbedScrollableParent = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-y: overlay;
`;

const StyledExplanationPlayer = styled(ExplanationPlayer)`
  margin-bottom: 24px;
`;

const EmbedWrap = styled.div`
  padding: 8px;

  ${({ desktopMaxHeight }) =>
    desktopMaxHeight &&
    css`
      @media screen and (min-width: 900px) {
        & > * {
          max-height: ${desktopMaxHeight};
        }
      }
    `}
`;

const ALLOWED_RIGHTS = new Set([RIGHT.FULL_RIGHTS, RIGHT.VIEW, RIGHT.VIEW_EDIT, RIGHT.VIEW_EDIT_PUBLISH]);

const getProcessedState = (match, location, loadedGuide, statusCode) => {
  if (statusCode === 403 || !loadedGuide) {
    return { explanationLoaded: true, accessToExplanation: false, guide: {} };
  }
  if (!loadedGuide) return null;

  const accessToExplanation = ALLOWED_RIGHTS.has(loadedGuide.guideInfo.access);
  if (!accessToExplanation) {
    return { explanationLoaded: true, accessToExplanation: false, guide: {} };
  }

  let themeHighlightColor;

  const { embedHighlightColor, buttonColor } = loadedGuide.guideOptions;
  const urlParams = queryString.parse(location.search);

  if (buttonColor && validateHexaDecimal(buttonColor)) {
    themeHighlightColor = buttonColor;
  }
  if (embedHighlightColor && validateHexaDecimal(embedHighlightColor)) {
    themeHighlightColor = embedHighlightColor;
  }
  if (urlParams.accent && validateHexaDecimal(urlParams.accent)) {
    themeHighlightColor = urlParams.accent;
  }

  return {
    guide: loadedGuide,
    accessToExplanation,
    explanationLoaded: true,
    //
    themeHighlightColor,
  };
};

class Embed extends Component {
  constructor(props) {
    super(props);
    const ssrInitialState = getProcessedState(props.match, props.location, props.loadedGuide, props.statusCode);
    const stepsPath = createPathFromParam(props.match.params.pathToLoad);
    this.state = ssrInitialState
      ? { ...ssrInitialState, stepsPath }
      : {
          guide: null,
          explanationLoaded: false,
          accessToExplanation: true,
          //
          themeHighlightColor: undefined,
          isFullScreen: false,
          stepsPath,
        };
  }

  canvasRef = React.createRef();

  fullscreenCanvasRef = React.createRef();

  embedScrollableParentRef = React.createRef();

  componentDidMount() {
    const { explanationLoaded } = this.state;
    if (!explanationLoaded) this.initiateExplanation();

    getIds().then(({ sessionId }) => {
      postMessage({
        type: 'params',
        name: 'stonlySessionId',
        value: sessionId,
      });
    });
    postMessage({ type: 'initPlugin' });

    addOnFullscreenChangeListener(this.onFullscreenChange);

    this.updateIframeHeight();

    window.addEventListener('message', this.onPostMessage);
  }

  componentDidUpdate(prevProps) {
    const { userManagement, match } = this.props;
    const { match: oldMatch } = prevProps;

    this.updateIframeHeight();
    if (userManagement.user.id && !prevProps.userManagement.user.id) {
      this.initiateExplanation();
    }
    if (match.params.pathToLoad !== oldMatch.params.pathToLoad) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ stepsPath: createPathFromParam(match.params.pathToLoad) });
    }
  }

  openFullscreen = () => {
    if (fullscreenEnabled()) {
      openFullscreen(this.fullscreenCanvasRef.current);
      this.setState({ isFullScreen: true });
    } else {
      const { id, pathToLoad } = this.getParams();
      window.open(`/guide/${id}/Steps/${pathToLoad}`, '_blank');
    }
  };

  closeFullscreen = () => {
    const { isFullScreen } = this.state;
    if (isFullScreen && isFullscreen()) {
      closeFullscreen();
    }
    this.setState({ isFullScreen: false });
  };

  componentWillUnmount() {
    removeOnFullscreenChangeListener(this.onFullscreenChange);
    window.removeEventListener('message', this.onPostMessage);
  }

  onPostMessage = message => {
    if (message.data.type === 'stonlyEmbedScrollToTop') {
      const { id } = this.getParams();
      if (id === message.data.guideId && this.embedScrollableParentRef.current) {
        this.embedScrollableParentRef.current.scrollTop = 0;
      }
    }
  };

  onFullscreenChange = () => {
    if (isFullscreen()) {
      this.setState({ isFullScreen: true });
    } else {
      this.setState({ isFullScreen: false });
    }
  };

  getParams = (nextprops = {}) => {
    const { match } = this.props;

    let nextParams;
    const currentParams = { id: match.params.id, pathToLoad: match.params.pathToLoad };

    if (Object.keys(nextprops).length !== 0 || nextprops.constructor !== Object) {
      if (nextprops.match) {
        nextParams = {
          id: nextprops.match.params.id,
          pathToLoad: nextprops.match.params.pathToLoad,
        };
      } else {
        nextParams = { id: nextprops.id, pathToLoad: nextprops.pathToLoad };
      }
    } else {
      nextParams = { id: null, pathToLoad: '' };
    }
    return {
      id: currentParams.id,
      pathToLoad: currentParams.pathToLoad,
      nextId: nextParams.id,
      nextPathToLoad: nextParams.pathToLoad,
    };
  };

  updateIframeHeight = () => {
    const { id } = this.getParams();
    const { updateParentContainer } = this.props;

    const explanationId = id;

    if (typeof updateParentContainer === 'function') {
      updateParentContainer();
    } else if (this.canvasRef.current && Object.keys(this.canvasRef.current).length !== 0) {
      postMessage({ FrameHeight: `${this.canvasRef.current.offsetHeight + 15}`, iFrameId: `ston-${explanationId}` });
      postMessage(
        JSON.stringify({
          src: window.location.toString(),
          context: 'iframe.resize',
          height: this.canvasRef.current.offsetHeight + 15,
        })
      );
    }
  };

  initiateExplanation = async (force = false) => {
    const { loadedGuide, match, location, updateParentState } = this.props;

    const { themeHighlightColor: currentThemeHighlightColor } = this.state;
    const { id } = this.getParams();

    const language = getLanguageShorthand();
    let newLoadedGuide = {};

    if (!loadedGuide || Object.keys(loadedGuide).length === 0 || force) {
      const loadedGuideToParse = await loadExplanation(id);
      newLoadedGuide = getProcessedState(match, location, parseGuideData(loadedGuideToParse, language));
      if (updateParentState) updateParentState(newLoadedGuide.guide);
    } else {
      newLoadedGuide = loadedGuide;
    }

    if (currentThemeHighlightColor) {
      const { themeHighlightColor, ...newState } = newLoadedGuide;
      this.setState(newState);
    } else {
      this.setState(newLoadedGuide);
    }
  };

  setLanguage = async language => {
    const { forceUpdateAtRoot, match, history } = this.props;
    setLanguage(language);
    forceUpdateAtRoot();
    await this.initiateExplanation(true);
    const { guide } = this.state;
    history.replace(getNewLanguageUrl(language, match, guide.guideInfo.title));
  };

  render() {
    const { guide, isFullScreen, themeHighlightColor, accessToExplanation, explanationLoaded, stepsPath } = this.state;
    const { match } = this.props;

    if (!accessToExplanation) {
      return <NoViewAccess showLink={false} showBranding={false} />;
    }

    if (!explanationLoaded) {
      return (
        <FullscreenLoaderWrap>
          <Loader monochrome text={i18n('Global.Loading')} />
        </FullscreenLoaderWrap>
      );
    }

    const { maxHeight, source, theme, desktopMaxHeight } = this.props;

    const mode = source === 'modal' ? 'embedModal' : 'embed';

    const hasConditionalConnection = guide.allGuides
      .flatMap(({ stepNext }) => stepNext)
      .some(({ visibility }) => visibility === STEP_NEXT_VISIBILITY.externalCondition);

    let displayProgressBar = accessToExplanation ? parseBoolean(guide.guideOptions.displayProgressBar, true) : true;
    displayProgressBar = displayProgressBar && !hasConditionalConnection;

    let firstStep = null;
    const firstStepObj = guide.allGuides.find(info => info.guideId === match.params.id);
    if (firstStepObj) firstStep = firstStepObj.startId;

    let overridingTheme;
    if (themeHighlightColor && validateHexaDecimal(themeHighlightColor)) {
      overridingTheme = { ...theme, highlightColor: themeHighlightColor };
    }

    return (
      <ThemeProvider theme={overridingTheme || theme}>
        <EmbedScrollableParent ref={this.embedScrollableParentRef}>
          <Canvas
            ref={this.canvasRef}
            maxHeight={maxHeight}
            isFullScreen={isFullScreen}
            embedFontFamily={guide.guideOptions.embedFontFamily}
            className="embed-guide"
          >
            <fullscreenContext.Provider
              value={{
                parentIsFullscreen: this.state.isFullScreen,
                openParentFullscreen: this.openFullscreen,
                closeParentFullscreen: this.closeFullscreen,
                parentCanvasRef: this.fullscreenCanvasRef,
              }}
            >
              <EmbedWrap desktopMaxHeight={desktopMaxHeight}>
                {accessToExplanation ? (
                  <StyledExplanationPlayer
                    guide={guide}
                    firstStep={firstStep}
                    stepsPath={stepsPath}
                    displayProgressBar={displayProgressBar}
                    mode={mode}
                    setFullScreen={this.setFullScreen}
                    isFullScreen={isFullScreen}
                    setLanguage={this.setLanguage}
                    agentAppGuideData={this.props.agentAppGuideData}
                  />
                ) : (
                  <NoViewAccess />
                )}
              </EmbedWrap>
            </fullscreenContext.Provider>
          </Canvas>
        </EmbedScrollableParent>
      </ThemeProvider>
    );
  }
}

Embed.propTypes = {
  match: PropTypes.object,
  userManagement: PropTypes.object,
  history: PropTypes.object,
  id: PropTypes.string,
  stepsToLoad: PropTypes.string,
  updateParentContainer: PropTypes.func,
  maxHeight: PropTypes.number,
  loadedGuide: PropTypes.object,
  source: PropTypes.string,
  theme: PropTypes.object,
  location: PropTypes.object,
  statusCode: PropTypes.number,
  forceUpdateAtRoot: PropTypes.func,
  desktopMaxHeight: PropTypes.string,
  // only used for and by AgentApp wrapper
  updateParentState: PropTypes.func,
};

export default withExplanationData(withContextProviders(withRouter(withUser(withTheme(Embed)))));
