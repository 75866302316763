import React, { useEffect, useCallback } from 'react';
import usePopup from '@playerCommon/hooks/usePopup';
import useUserManagement from '@playerCommon/hooks/useUserManagement';
import Popup from './Popup/Popup';
import { AUTHENTICATION_VIEWS, SIGN_IN_HASH } from './Authentication.consts';

const useAuthenticationPopup = () => {
  const { openPopup, closePopup, popupOpen } = usePopup();
  const userManagement = useUserManagement();
  const isUserSignIn = userManagement.user && Object.keys(userManagement.user).length !== 0;

  const openAuthenticationPopup = authenticationView => {
    if (!popupOpen) {
      openPopup({
        customContent: <Popup authenticationView={authenticationView} hideClose />,
        hideDismiss: true,
        hideClose: authenticationView !== AUTHENTICATION_VIEWS.SIGN_IN,
        confirmButtonText: null,
        dismissButtonText: null,
        closeButtonFunction: () => {
          closePopup();
          window.location.hash = '';
        },
      });
    }
  };

  const closePopupProxy = useCallback(() => {
    if (popupOpen) {
      closePopup();
    }
  }, [popupOpen]);

  useEffect(() => {
    const onBackButtonEvent = () => {
      closePopupProxy();
    };

    window.addEventListener('popstate', onBackButtonEvent);

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);

  useEffect(() => {
    const handleOnHashChange = () => {
      if (window.location.hash === SIGN_IN_HASH && !isUserSignIn) {
        openAuthenticationPopup(AUTHENTICATION_VIEWS.SIGN_IN);
      } else {
        closePopup();
      }
    };

    window.addEventListener('hashchange', handleOnHashChange);
    handleOnHashChange();

    return () => window.removeEventListener('hashchange', handleOnHashChange);
  }, []);

  return {
    openAuthenticationPopup,
  };
};

export default useAuthenticationPopup;
