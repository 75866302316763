import React, { useMemo } from 'react';
import { RIGHT } from 'global';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import ActionsDropdown from '@playerCommon/CustomElements/ActionsDropdown';
import i18n from 'helpers/i18n';
import EditIconSVG from 'icons/editNoPadding.svg';
import KeyIconSVG from 'icons/key.svg';
import MoveToIconSVG from 'icons/moveToNoPadding.svg';
import DeleteIconSVG from 'icons/deleteNoPadding.svg';

const FolderRowDropdown = ({
  folder,
  folderName,
  teamId,
  onEdit,
  onEditRights,
  onMoveTo,
  onDelete,
  trigger,
  theme,
  isRoot,
}) => {
  const options = useMemo(() => {
    const optionsForExplanation = [];

    if ([RIGHT.FULL_RIGHTS, RIGHT.VIEW_EDIT_PUBLISH, RIGHT.VIEW_EDIT].includes(folder.accessRights)) {
      optionsForExplanation.push({
        id: 'editFolder',
        dataCy: 'editFolder',
        label: i18n('KnowledgeBaseManagement.ContentRowDropdownRename'),
        icon: EditIconSVG,
        onClick: () => onEdit(folder.folderId),
      });
    }

    if (folder.accessRights === RIGHT.FULL_RIGHTS && folder.teamId !== 0) {
      optionsForExplanation.push({
        id: 'editFolderRights',
        dataCy: 'editFolderRights',
        label: i18n('KnowledgeBaseManagement.ContentRowDropdownEditRights'),
        icon: KeyIconSVG,
        onClick: () => onEditRights(folder.folderId),
      });
    }

    if (!isRoot && folder.accessRights === RIGHT.FULL_RIGHTS) {
      optionsForExplanation.push({
        id: 'moveFolder',
        dataCy: 'moveFolder',
        label: i18n('KnowledgeBaseManagement.ContentRowDropdownMove'),
        icon: MoveToIconSVG,
        onClick: () => onMoveTo({ type: 'folder', id: folder.folderId, teamId, name: folderName }),
      });
    }

    if (!isRoot && folder.accessRights === RIGHT.FULL_RIGHTS) {
      optionsForExplanation.push({
        id: 'deleteFolder',
        dataCy: 'deleteFolder',
        label: i18n('KnowledgeBaseManagement.ContentRowDropdownDelete'),
        icon: DeleteIconSVG,
        onClick: () => onDelete(folder.folderId),
        separation: true,
        iconHoverColor: theme.deleteColor,
        textHoverColor: theme.deleteColor,
        backgroundHoverColor: theme.deleteColorBg,
      });
    }

    return optionsForExplanation;
  }, [folder]);

  return <ActionsDropdown width={160} trigger={trigger} options={options} animateIconHover />;
};

FolderRowDropdown.propTypes = {
  folder: PropTypes.object,
  folderId: PropTypes.number,
  folderName: PropTypes.string,
  teamId: PropTypes.number,
  onEdit: PropTypes.func,
  onEditRights: PropTypes.func,
  onMoveTo: PropTypes.func,
  onDelete: PropTypes.func,
  trigger: PropTypes.object,
  theme: PropTypes.object,
  isRoot: PropTypes.bool,
};

export default withTheme(FolderRowDropdown);
