import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import i18n, { getLanguageShorthand } from 'helpers/i18n';
import { supportedLanguages } from 'helpers/i18n.helpers';
import PropTypes from 'prop-types';
import { navigationWithKeyboardStyle } from '@playerCommon/CommonStyledComponents/NavigationWithKeyboard';
import PanelCloseButton from '../PanelCloseButton';

const Canvas = styled.div`
  margin-bottom: 100px;
`;

const Wrap = styled.div`
  padding: 78px 32px 0 32px;

  @media screen and (max-width: 768px) {
    padding: 18px 24px 0 24px;
  }

  ${({ mode }) =>
    ['embed', 'borderlessembed'].includes(mode) &&
    css`
      padding: 18px 24px 0 24px;
    `}
`;

const StepTitle = styled.h4`
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 40px;
  margin-top: 0;
`;

const LanguageRow = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 16px;
  margin: 0 -8px;
  cursor: pointer;
  transition: background-color 0.2s, box-shadow 0.2s;
  border-radius: 4px;

  ${navigationWithKeyboardStyle}

  &:hover {
    background: ${props => props.theme.paleGrey};
  }
`;

const Info = styled.div`
  margin: 16px 0;
  font-size: 16px;
  color: ${props => props.theme.darkGrey};
`;

const Name = styled.div`
  display: inline-block;
  margin-right: 6px;
  font-size: 16px;
  color: ${props => props.theme.darkGrey};
  font-weight: normal;
  margin-top: -2px;
`;

const Current = styled.div`
  display: inline-block;
  color: ${props => props.theme.steel};
`;

export const LANGUAGES_TITLE_ID = 'ston-languages-title';

function ExplanationLanguages({ languageList, toggleComponent, setLanguage, mode }) {
  const languages = languageList.split(',');
  const languagesWithNames = supportedLanguages.filter(lang => languages.includes(lang.value));

  const currentLanguage = getLanguageShorthand();

  const handleKeyDown = (e, lang) => {
    if (e.key === ' ' || e.key === 'Enter') {
      setLanguage(lang);
      toggleComponent();
    }
  };

  return (
    <Wrap mode={mode}>
      <StepTitle data-cy="commentsTitle" id={LANGUAGES_TITLE_ID}>
        <PanelCloseButton mode={mode} onClick={toggleComponent} />
        {i18n('ExplanationLanguage.ChangeLanguage')}
      </StepTitle>
      <Canvas role="listbox" aria-labelledby={LANGUAGES_TITLE_ID}>
        {languagesWithNames.map(lang => (
          <LanguageRow
            key={lang.value}
            data-cy="languageRow"
            tabIndex={0}
            onClick={() => {
              setLanguage(lang.value);
              toggleComponent();
            }}
            onKeyDown={e => {
              handleKeyDown(e, lang.value);
            }}
            aria-selected={lang.value === currentLanguage}
            role="option"
          >
            <Info data-cy="languageInfo">
              <Name>{lang.name}</Name>
              {lang.value === currentLanguage && <Current>({i18n('ExplanationLanguage.Current')})</Current>}
            </Info>
          </LanguageRow>
        ))}
      </Canvas>
    </Wrap>
  );
}

ExplanationLanguages.propTypes = {
  toggleComponent: PropTypes.func,
  languageList: PropTypes.string,
  setLanguage: PropTypes.func,
  mode: PropTypes.string,
};

export default memo(ExplanationLanguages);
