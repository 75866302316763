import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ClassicFinalStep from './ClassicFinalStep/ClassicFinalStep.js';
import ZendeskFinalStep from './ZendeskFinalStep/ZendeskFinalStep.js';

const Canvas = styled.div`
  padding: 0;
`;

const FinalStepContent = ({ explanationId, toggleComponent, integration, onBackLinkClick, previousStepLink }) => (
  <Canvas>
    {integration !== 'zendesk' && (
      <ClassicFinalStep onBackLinkClick={onBackLinkClick} previousStepLink={previousStepLink} />
    )}
    {integration === 'zendesk' && <ZendeskFinalStep toggleComponent={toggleComponent} explanationId={explanationId} />}
  </Canvas>
);

FinalStepContent.propTypes = {
  explanationId: PropTypes.string,
  toggleComponent: PropTypes.func,
  integration: PropTypes.string,
  onBackLinkClick: PropTypes.func,
  previousStepLink: PropTypes.string,
};

export default FinalStepContent;
