import { variableStyles } from '@playerCommon/CommonStyledComponents/Typography';
import { playerUrl, resourcesUrl } from '@stonlyCommons/global/env';
import { getGlobal } from '@stonlyCommons/global/windowUtils';
import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { getTempStorage, setTempStorage } from '@stonlyCommons/helpers/storage';

import { SmartSearchSingleAnswerActions } from './AiAnswerResultActions.styles';
import { AiAnswerCopyButton } from './AiAnswerResultActions';

const playerUrlWithoutProtocol = playerUrl.replace(/^https?:\/\//, '');

export const AiAnswerSingleMessageWrap = styled(motion.div)`
  width: fit-content;
  position: relative;
  max-width: 80%;
  font-size: ${props => props.theme.aiAnswerAnswerFontSize};
  line-height: ${props => props.theme.aiAnswerAnswerLineHeight};
  font-weight: 400;
  text-align: left;
  border-radius: 10px;
  padding: 16px 16px;
  background: ${props => props.theme.defaultAnswer};
  margin-top: 12px;
  scroll-margin-top: 12px;
  word-break: break-word;

  ${variableStyles};

  @media screen and (max-width: 899px) {
    font-size: ${props => props.theme.playerMobileAiAnswerAnswerFontSize};
    line-height: ${props => props.theme.playerMobileAiAnswerAnswerLineHeight};
  }

  p {
    margin: 0;
  }

  ul,
  ol {
    margin: 0;
    padding-left: 24px;
  }

  a {
    color: ${props => props.theme.answerLinkColor};
    text-decoration: none;
    font-weight: 500;

    &:after {
      content: '';
      mask-image: url(${resourcesUrl.url}resources/icons/externalLinkTiny.svg);
      background: ${props => props.theme.answerLinkColor};
      display: inline-block;
      margin-bottom: 1px;
      margin-left: 4px;
      width: 16px;
      height: 16px;
      vertical-align: middle;
    }

    &[href*='${playerUrlWithoutProtocol}']:after,
    &[href*='${props => props.hostname}']:after,
    &[href^='/']:after {
      display: none;
    }

    &:hover {
      color: ${props => props.theme.answerLinkColorHover};
      &:after {
        background: ${props => props.theme.answerLinkColorHover};
      }
    }
  }

  ${({ isFallback }) =>
    isFallback &&
    css`
      margin-bottom: 24px;

      span *:first-child {
        margin-top: 0;
      }
      span *:last-child {
        margin-bottom: 0;
      }
      a {
        &:before {
          display: none;
        }
      }
    `}
`;

const MINIMUM_PIXELS_IN_VIEWPORT = 240;

function isInOrAboveViewportWithMargin(element, margin = 0) {
  const rect = element.getBoundingClientRect();
  const htmlElement = document.documentElement;
  return rect.top <= (window.innerHeight || htmlElement.clientHeight) - margin;
}

const AiAnswerSingleMessage = ({
  text,
  questionAnswerId,
  onLinkClickProxy,
  isFallback,
  isCopyable,
  isAnimated,
  messageId,
}) => {
  const hostname = getGlobal('host');
  const ref = useRef(null);
  const [isInViewport, setIsInViewport] = useState(!!getTempStorage(`${messageId}_read`));

  useEffect(() => {
    if (isFallback) {
      setTimeout(() => {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 0);
    }
  }, []);

  useEffect(() => {
    function checkIfInViewport() {
      if (isInOrAboveViewportWithMargin(ref.current, MINIMUM_PIXELS_IN_VIEWPORT)) {
        setIsInViewport(true);
        setTempStorage(`${messageId}_read`, true);
        window.dispatchEvent(new Event('message-seen'));
      }
    }
    if (!isInViewport) {
      const scrollableStepCanvas = document.querySelector('.scrollable-step-canvas');
      checkIfInViewport();
      window.addEventListener('scroll', checkIfInViewport);
      scrollableStepCanvas.addEventListener('scroll', checkIfInViewport);
      return () => {
        window.removeEventListener('scroll', checkIfInViewport);
        scrollableStepCanvas.removeEventListener('scroll', checkIfInViewport);
      };
    }
  }, [isInViewport]);

  return (
    <AiAnswerSingleMessageWrap
      ref={ref}
      // eslint-disable-next-line react/no-array-index-key
      data-ai-answer-id={questionAnswerId}
      className={`ai-step-result-text ${isFallback ? 'ai-step-result-text-fallback' : ''} ${
        isInViewport ? '' : 'ai-step-result-text-not-in-viewport'
      }`}
      onClick={onLinkClickProxy}
      hostname={hostname}
      isFallback={isFallback}
      initial={isAnimated ? { y: 16, opacity: 0 } : {}}
      animate={isAnimated ? { y: 0, opacity: 1 } : {}}
      transition={isAnimated ? { type: 'spring', bounce: 0 } : {}}
    >
      <span dangerouslySetInnerHTML={{ __html: text }} aria-live="polite" role="log" />
      {isCopyable && (
        <SmartSearchSingleAnswerActions>
          <AiAnswerCopyButton textToCopy={text} />
        </SmartSearchSingleAnswerActions>
      )}
    </AiAnswerSingleMessageWrap>
  );
};

export default AiAnswerSingleMessage;
