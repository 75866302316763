import React, { Fragment, memo, useMemo } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { navigationWithKeyboardStyle } from '@playerCommon/CommonStyledComponents/NavigationWithKeyboard';
import {
  ICON_STYLES,
  RATING_STYLES,
  ratingOptionIndexListByRatingStyleAndScale,
} from '@stonlyCommons/constants/CustomSurvey.consts';
import {
  SMILEY_COLOR_MAP,
  SMILEY_LIGHT_COLOR_MAP,
  THUMB_COLOR_MAP,
  THUMB_LIGHT_COLOR_MAP,
  getRatingLabelForPlayer,
  getMobileSize,
  getMobileSpacing,
} from './CustomSurvey.consts';

const RatingsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  border: none;
  margin: 0;
  padding: 0;

  ${({ ratingStyle, iconStyle, ratingScale }) => {
    if (ratingStyle === RATING_STYLES.NUMBERS && iconStyle === ICON_STYLES.ICONS) {
      if (ratingScale <= 8) {
        return css`
          gap: 16px;

          @media screen and (max-width: 600px) {
            gap: ${({ mobileSpacing }) => mobileSpacing}px;
          }
        `;
        // eslint-disable-next-line no-else-return
      } else {
        return css`
          gap: 12px 16px;
        `;
      }
    }
  }};

  ${({ ratingStyle, ratingScale }) => {
    if (ratingStyle === RATING_STYLES.NUMBERS && ratingScale > 8) {
      return css`
        @media screen and (max-width: 600px) {
          justify-content: center;
          flex-wrap: wrap;
        }
      `;
    }
  }};
`;

const RatingLabel = styled.label`
  cursor: ${({ isPreview }) => (isPreview ? 'default' : 'pointer')};
  box-sizing: content-box;
  padding: 0 6px;
  transition: opacity 0.2s, background-color 0.2s, border-color 0.2s, color 0.2s;
  ${navigationWithKeyboardStyle};

  &:first-of-type {
    padding-left: 0;
  }
  &:last-of-type {
    padding-right: 0;
  }

  @media screen and (max-width: 600px) {
    padding: ${({ mobileSpacing }) => mobileSpacing / 2}px;
  }

  ${({ iconStyle }) => {
    if (iconStyle === ICON_STYLES.ICONS) {
      return css`
        width: 48px;
        height: 48px;

        svg {
          width: 100%;
          height: 100%;

          path {
            stroke: ${props => props.theme.lightGrey};
            transition: stroke 0.2s, fill 0.2s;
          }
          path:first-child {
            fill: ${props => props.theme.white};
          }
          circle {
            fill: ${props => props.theme.lightGrey};
            transition: fill 0.2s;
          }
        }

        @media screen and (max-width: 600px) {
          width: ${({ mobileSize }) => mobileSize}px;
          height: ${({ mobileSize }) => mobileSize}px;
        }
      `;
    }
    return css`
      // emoji size
      font-size: 44px;
      line-height: 44px;

      font-family: 'Apple Color Emoji', 'Noto Color Emoji', 'Segoe UI Emoji', 'Android Emoji', 'EmojiSymbols',
        'EmojiOne Mozilla', 'Twemoji Mozilla', 'Segoe UI Symbol', 'Noto Color Emoji Compat', emoji;

      @media screen and (max-width: 600px) {
        font-size: ${({ mobileSize }) => mobileSize - 4}px;
        line-height: ${({ mobileSize }) => mobileSize}px;
      }
    `;
  }}

  ${({ ratingStyle, iconStyle, ratingScale }) => {
    if (ratingStyle === RATING_STYLES.NUMBERS && iconStyle === ICON_STYLES.EMOJIS && ratingScale >= 9) {
      return css`
        @media screen and (max-width: 600px) {
          font-size: 44px;
          line-height: 44px;
          padding: 6px 8px !important;
        }
      `;
    }
  }}

  ${({ rating, iconStyle, ratingStyle }) => {
    if (iconStyle === ICON_STYLES.ICONS) {
      if (ratingStyle === RATING_STYLES.STARS) {
        return css`
          svg path {
            stroke: ${props => props.theme.mango};
          }
        `;
      }
      if (ratingStyle === RATING_STYLES.SMILEYS) {
        return css`
          svg path {
            stroke: ${SMILEY_COLOR_MAP[rating]};
          }
          svg circle {
            fill: ${SMILEY_COLOR_MAP[rating]};
          }
        `;
      }
      if (ratingStyle === RATING_STYLES.THUMBS) {
        return css`
          svg path {
            stroke: ${THUMB_COLOR_MAP[rating]};
          }
        `;
      }
      if (ratingStyle === RATING_STYLES.NUMBERS) {
        return css`
          display: flex;
          align-items: center;
          justify-content: center;
          width: 44px;
          height: 44px;
          padding: 0 !important;
          text-align: center;
          font-size: 18px;
          font-weight: 500;
          border: 1px solid ${props => props.theme.amaranth};
          border-radius: 6px;
          color: ${props => props.theme.amaranth};

          @media screen and (max-width: 600px) {
            width: ${({ mobileSize }) => mobileSize - 4}px;
            height: ${({ mobileSize }) => mobileSize - 4}px;
            ${({ ratingScale }) => {
              if (ratingScale >= 6 && ratingScale <= 8) {
                return css`
                  font-size: 16px;
                `;
              }
            }}
          }
        `;
      }
    }
  }}

  ${({ iconStyle, ratingStyle }) => {
    if (iconStyle === ICON_STYLES.ICONS && ratingStyle === RATING_STYLES.STARS) {
      return css`
        // previous siblings of the hovered rating
        &:has(~ &:hover) {
          svg path {
            stroke: ${props => props.theme.mango};
            fill: ${props => props.theme.mango}40;
          }
        }
      `;
    }
    if (iconStyle === ICON_STYLES.EMOJIS && ratingStyle === RATING_STYLES.STARS) {
      return css`
        opacity: 0.4;

        // previous siblings of the hovered rating
        &:has(~ &:hover) {
          opacity: 1;
        }
      `;
    }
    if (iconStyle === ICON_STYLES.EMOJIS) {
      return css`
        opacity: 0.4;
      `;
    }
  }}

  &:hover {
    ${({ rating, iconStyle, ratingStyle }) => {
      if (iconStyle === ICON_STYLES.ICONS) {
        if (ratingStyle === RATING_STYLES.STARS) {
          return css`
            svg path {
              stroke: ${props => props.theme.mango};
              fill: ${props => props.theme.mango}40;
            }
          `;
        }
        if (ratingStyle === RATING_STYLES.SMILEYS) {
          return css`
            svg path:first-child {
              fill: ${SMILEY_LIGHT_COLOR_MAP[rating]};
            }
            svg path {
              stroke: ${SMILEY_COLOR_MAP[rating]};
            }
            svg circle {
              fill: ${SMILEY_COLOR_MAP[rating]};
            }
          `;
        }
        if (ratingStyle === RATING_STYLES.THUMBS) {
          return css`
            svg path {
              stroke: ${THUMB_COLOR_MAP[rating]};
              fill: ${THUMB_LIGHT_COLOR_MAP[rating]};
            }
          `;
        }
        if (ratingStyle === RATING_STYLES.NUMBERS) {
          return css`
            border-color: ${props => props.theme.amaranth};
            background-color: ${props => props.theme.amaranth};
            color: ${props => props.theme.white} !important;
          `;
        }
      } else {
        return css`
          opacity: 1;
        `;
      }
    }}
  }
`;

const HiddenInput = styled.input`
  position: absolute;
  visibility: hidden;
  width: 0;
  overflow: hidden;
`;

const HiddenSpan = styled.span`
  position: absolute;
  visibility: hidden;
  width: 0;
  overflow: hidden;
`;

const RatingList = ({ ratingStyle, iconStyle, ratingScale, onRatingClick, shouldReverseOptions, isPreview }) => {
  const handleKeyDown = (e, rating) => {
    if (!isPreview && (e.key === ' ' || e.key === 'Enter')) {
      onRatingClick(rating);
    }
  };

  const ratingOptions = useMemo(() => {
    const options = [...ratingOptionIndexListByRatingStyleAndScale[ratingStyle][ratingScale]].reverse();

    // if ratingStyle is THUMBS we need to reverse them back (yes, that's a thing)
    if (shouldReverseOptions) {
      options.reverse();
    }

    return options;
  }, [ratingStyle, ratingScale, shouldReverseOptions]);

  return (
    <RatingsWrap
      ratingStyle={ratingStyle}
      iconStyle={iconStyle}
      ratingScale={ratingScale}
      mobileSpacing={getMobileSpacing(ratingScale)}
      className={`custom-survey-rating-list ratingStyle-${ratingStyle.toLowerCase()} iconStyle-${iconStyle.toLowerCase()} ratingScale-${ratingScale}`}
    >
      {ratingOptions.map(rating => {
        return (
          <Fragment key={rating}>
            <HiddenInput type="radio" id={rating} value={rating} name="rating" />
            <RatingLabel
              tabIndex={isPreview ? -1 : 0}
              className={`custom-survey-rating rating-${rating} ratingStyle-${ratingStyle.toLowerCase()} iconStyle-${iconStyle.toLowerCase()} ratingScale-${ratingScale}`}
              onClick={() => onRatingClick(rating)}
              onKeyDown={e => handleKeyDown(e, rating)}
              rating={rating}
              ratingStyle={ratingStyle}
              iconStyle={iconStyle}
              ratingScale={ratingScale}
              mobileSize={getMobileSize(ratingStyle, ratingScale)}
              mobileSpacing={getMobileSpacing(ratingScale)}
              isPreview={isPreview}
              role="button"
              aria-labelledby={`${rating}-hidden-span`}
            >
              <HiddenSpan id={`${rating}-hidden-span`} htmlFor={rating}>
                {`${rating} ${ratingStyle.toLowerCase()}`}
              </HiddenSpan>
              {getRatingLabelForPlayer({ ratingStyle, ratingScale, iconStyle, optionIndex: rating })}
            </RatingLabel>
          </Fragment>
        );
      })}
    </RatingsWrap>
  );
};

RatingList.propTypes = {
  ratingStyle: PropTypes.string.isRequired,
  iconStyle: PropTypes.string.isRequired,
  ratingScale: PropTypes.number.isRequired,
  onRatingClick: PropTypes.func.isRequired,
  shouldReverseOptions: PropTypes.bool,
  isPreview: PropTypes.bool,
};

export default memo(RatingList);
