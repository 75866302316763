import React from 'react';
import PropTypes from 'prop-types';
import useGuideData from '@playerCommon/hooks/playerHooks/useGuideData';
import TTSIconSVG from 'icons/tts-24.svg';
import TTSStopIconSVG from 'icons/stopIcon-24.svg';
import i18n from 'helpers/i18n';
import { STEP_TYPE, TTS_SUPPORTED_LANGUAGE_CODES } from 'global/index';
import StepButtons from '../../components/StepButtons/StepButtons';

import {
  WideProgressBar,
  CompactStepButtonsCanvas,
  StepButtonsCanvas,
  FooterPoweredByStonly,
  FooterWrapper,
  CompactTTSButton,
} from './StepsFooter.styles';

import { StyledLoaderSmall } from '../Steps.styles';

function StepsFooter({
  dataCy,
  type,
  compact,
  displayProgressBar,
  mode,
  matchMode,
  completion,
  parentIsFullscreen,
  borderlessEmbed,
  stepType,
  isFirstStep,
  toggleComponent,
  toggleFullscreen,
  editExplanation,
  language,
  widgetSizeType,
  displayTableOfContentsTree,
  displayTableOfContentsFlowchart,
  isMobile,
  isTableOfContentsEnabled,
  isTableOfContentsExpanded,
  setIsTableOfContentsExpanded,
  isTTSRunning,
  isTTSAvailableOnStep,
  isLoadingTTSData,
  startTTS,
  stopTTS,
}) {
  const { guideInfo, contributors, guideOptions = {}, ttsEnabled } = useGuideData();
  const utmSource = guideInfo.teamId || contributors[0]?.userId || '-1';
  const shouldShowPoweredByStonly = !guideOptions.whiteLabel && mode !== 'hc';

  return (
    <FooterWrapper type={type} data-cy={dataCy}>
      {compact && ttsEnabled && TTS_SUPPORTED_LANGUAGE_CODES.includes(language) && (
        <CompactTTSButton
          onClick={isTTSRunning || isLoadingTTSData ? stopTTS : startTTS}
          dataCy="ttsCompactButton"
          ariaLabel={isTTSRunning ? i18n('StepButtons.TTSStop') : i18n('StepButtons.TTSStart')}
          tooltip={isTTSRunning ? i18n('StepButtons.TTSStop') : i18n('StepButtons.TTSStart')}
          disabled={!isTTSAvailableOnStep}
          tooltipPosition="top-end"
        >
          {isLoadingTTSData && <StyledLoaderSmall />}
          {!isLoadingTTSData && (isTTSRunning ? <TTSStopIconSVG /> : <TTSIconSVG />)}
        </CompactTTSButton>
      )}
      {compact ? (
        <CompactStepButtonsCanvas className="steps-footer">
          {displayProgressBar && <WideProgressBar mode={mode} height={3} completion={completion} />}
          {shouldShowPoweredByStonly && <FooterPoweredByStonly utmSource={utmSource} />}
        </CompactStepButtonsCanvas>
      ) : (
        <StepButtonsCanvas
          mode={mode}
          matchMode={matchMode}
          isFullscreen={parentIsFullscreen}
          borderlessEmbed={borderlessEmbed}
          displayProgressBar={displayProgressBar}
          className="steps-footer"
        >
          {shouldShowPoweredByStonly && <FooterPoweredByStonly utmSource={utmSource} />}
          {stepType !== 'introduction' && (
            <>
              {displayProgressBar && <WideProgressBar mode={mode} height={3} completion={completion} />}
              <StepButtons
                toggleComponent={toggleComponent}
                isFirstStep={isFirstStep}
                mode={mode}
                matchMode={matchMode}
                toggleFullscreen={toggleFullscreen}
                isFullscreen={parentIsFullscreen}
                editExplanation={editExplanation}
                isSurvey={stepType === STEP_TYPE.nps || stepType === STEP_TYPE.customSurvey}
                widgetSizeType={widgetSizeType}
                language={language}
                displayTableOfContentsTree={displayTableOfContentsTree}
                displayTableOfContentsFlowchart={displayTableOfContentsFlowchart}
                isMobile={isMobile}
                isTableOfContentsEnabled={isTableOfContentsEnabled}
                isTableOfContentsExpanded={isTableOfContentsExpanded}
                setIsTableOfContentsExpanded={setIsTableOfContentsExpanded}
                isTTSRunning={isTTSRunning}
                isTTSAvailableOnStep={isTTSAvailableOnStep}
                isLoadingTTSData={isLoadingTTSData}
                startTTS={startTTS}
                stopTTS={stopTTS}
              />
            </>
          )}
        </StepButtonsCanvas>
      )}
    </FooterWrapper>
  );
}

StepsFooter.propTypes = {
  type: PropTypes.oneOf(['desktop', 'mobile']),
  dataCy: PropTypes.string,
  compact: PropTypes.bool,
  displayProgressBar: PropTypes.bool,
  mode: PropTypes.string,
  matchMode: PropTypes.string,
  completion: PropTypes.number,
  parentIsFullscreen: PropTypes.bool,
  borderlessEmbed: PropTypes.bool,
  stepType: PropTypes.string,
  isFirstStep: PropTypes.bool,
  toggleComponent: PropTypes.func,
  toggleFullscreen: PropTypes.func,
  editExplanation: PropTypes.func,
  language: PropTypes.string,
  widgetSizeType: PropTypes.string,
  displayTableOfContentsTree: PropTypes.bool,
  displayTableOfContentsFlowchart: PropTypes.bool,
  isMobile: PropTypes.bool,
  isTableOfContentsEnabled: PropTypes.bool,
  isTableOfContentsExpanded: PropTypes.bool,
  setIsTableOfContentsExpanded: PropTypes.func,
  isTTSRunning: PropTypes.bool,
  isLoadingTTSData: PropTypes.bool,
  startTTS: PropTypes.func,
  stopTTS: PropTypes.func,
};

export default StepsFooter;
