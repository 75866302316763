export const LOGIN_TYPES = Object.freeze({
  LOCAL: 'local',
  SSO: 'sso',
  HYBRID: 'hybrid',
  GOOGLE: 'google',
});

export const POSTHOG_EVENT_NAME = Object.freeze({
  DEMO_SIGNUP_FUNNEL: 'demo_signup_funnel',
  SIGN_IN_FUNNEL: 'sign_in_funnel',
  USER_CREATED: 'user.created',
  ACCOUNT_CONFIRMATION: 'google_signin_account_confirmation',
});

export const FUNNEL_STEP = Object.freeze({
  ACCOUNT_CREATION_FORM_SHOWN: 'account_creation_form_shown',
  CLICK_BACK_FAIL: 'click_back_fail',
  CLICK_BACK_TO_SIGN_IN: 'click_back_to_sign_in',
  CLICK_CONFIRM_PASSWORD: 'click_confirm_password',
  CLICK_LOGIN: 'click_login',
  CLICK_SIGN_UP: 'click_sign_up',
  CLICK_SIGN_IN: 'click_sign_in',
  CLICK_SIGN_IN_SSO: 'click_sign_in_sso',
  CLICK_SIGNUP_WITH_GOOGLE: 'click_signup_with_google',
  CLICK_RESET_PASSWORD: 'click_reset_password',
  DATE_AND_TIME_SELECTED: 'date_and_time_selected',
  EVENT_SCHEDULED: 'event_scheduled',
  INTENT_GUIDE_BEGIN: 'intent_guide_first_step',
  OPEN_CALENDLY: 'open_calendly',
  OPEN_LIVESTORM: 'open_livestorm',
  POPUP_WORK_EMAIL_ADDED: 'popup_work_email_added',
  PERSONAL_EMAIL_POPUP: 'personal_email_popup',
  REACH_APP: 'reached_stonly_app',
  REDIRECTED_TO_SSO: 'redirected_to_SSO',
  REGISTRATION_FORM_SHOWN: 'registration_form_shown',
  REGISTRATION_FORM_SUBMITTED: 'registration_form_submitted',
  RESET_LINK_SENT: 'reset_link_sent',
  RESET_FAILURE: 'reset_failure',
  SIGNIN_SUCCESS: 'signin_success',
  SIGNUP_SUCCESS: 'signup_success',
  START: 'start',
  USE_CASE_FROM_SHOWN: 'use_case_form_shown',
  USE_CASE_FORM_SUBMITTED: 'use_case_form_submitted',
  USE_CASE_FORM_OTHER_SHOWN: 'use_case_form_other_shown',
  USE_CASE_FORM_OTHER_SUBMITTED: 'use_case_form_other_submitted',
  WORK_EMAIL_SKIP: 'work_email_skip',
});

export const REGISTRATION_FLOW = Object.freeze({
  DEMO: 'demo',
  TRIAL: 'trial',
});
