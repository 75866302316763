import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import i18n from 'helpers/i18n';
import Button from '@playerCommon/StandardElements/Button';
import RestrictedAccessIcon from 'icons/restrictedAccess.svg';
import ErrorMessage from '@playerCommon/CustomElements/ErrorMessage';

const Canvas = styled.div`
  display: flex;
  height: 100vh;
  padding: 48px;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 480px) {
    padding: 24px;
    padding-top: 68px;
  }
`;

const SubCanvas = styled.div`
  text-align: center;
`;

const GoToAuthenticationButton = styled(Button)`
  margin-top: 24px;
`;

const Title = styled.h1`
  font-size: 20px;
  margin-top: 24px;
  font-weight: 400;
`;

const SubTitle = styled.div`
  font-size: 14px;
  line-height: 24px;
  margin-top: 8px;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  && {
    margin-top: 24px;
  }
`;

function NoAccess({ title, subtitle, onButtonClick, buttonText = i18n('Global.Upgrade'), errorMessage, className }) {
  return (
    <Canvas className={className}>
      <SubCanvas>
        <RestrictedAccessIcon />
        <Title data-cy="noAccessTitle">{title}</Title>
        <SubTitle data-cy="noAccessSubTitle">{subtitle}</SubTitle>
        {onButtonClick && (
          <GoToAuthenticationButton
            onClick={onButtonClick}
            content={buttonText}
            value="Submit"
            dataCy="noAccessButton"
          />
        )}
        {errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
      </SubCanvas>
    </Canvas>
  );
}

NoAccess.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string,
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  onButtonClick: PropTypes.func,
};

export default NoAccess;
