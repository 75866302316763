import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import useDidUpdate from '@playerCommon/hooks/useDidUpdate';
import useTargetingChangedMessage from '@playerCommon/hooks/useTargetingChangedMessage';
import { getSegmentIds } from 'helpers/widgetHelpers';
import { extractBaseData } from 'components/Analytics/recorder';
import callApi from 'helpers/apiHelpers';

const fetchValuesByPropertyNames = async (properties = []) => {
  if (!properties?.length) {
    return;
  }

  try {
    const { guideId, stonlyAnonymousId, customerUserId } = await extractBaseData();
    const { segmentAnonymousId, segmentUserId } = getSegmentIds();
    const res = await callApi('v1/widget/properties', 'post', {
      stonlyAnonymousId,
      customerUserId,
      segmentAnonymousId,
      segmentUserId,
      guideId,
      properties,
    });
    return Object.fromEntries(
      properties.map(propertyName => [propertyName, res.data.properties[propertyName] || null])
    );
  } catch {
    return false;
  }
};

const UserDataContext = React.createContext();

function UserDataProvider({ value = {}, propertyNameList = [], fetchValuesOnMount = false, children }) {
  const [userDataValue, setUserDataValue] = useState(value);

  useDidUpdate(() => setUserDataValue(value), [value]);

  const onTargetingChange = useCallback(
    async ({ properties }) => {
      if (!properties) {
        return;
      }
      const updatedUserData = Object.fromEntries(
        propertyNameList.filter(name => properties.hasOwnProperty(name)).map(name => [name, properties[name]])
      );
      if (Object.keys(updatedUserData).length !== 0) {
        setUserDataValue(prevUserData => ({
          ...prevUserData,
          ...updatedUserData,
        }));
      }
    },
    [propertyNameList]
  );

  useTargetingChangedMessage({ onTargetingChange });

  useEffect(() => {
    if (fetchValuesOnMount && propertyNameList.length) {
      fetchValuesByPropertyNames(propertyNameList).then(data => {
        if (data) {
          setUserDataValue(prevUserData => ({
            ...prevUserData,
            ...data,
          }));
        }
      });
    }
  }, [fetchValuesOnMount, propertyNameList]);

  return <UserDataContext.Provider value={userDataValue}>{children}</UserDataContext.Provider>;
}

function useUserData() {
  const context = React.useContext(UserDataContext);
  if (context === undefined) {
    throw new Error('useUserData must be used within a UserDataProvider');
  }
  return context;
}

UserDataProvider.propTypes = {
  value: PropTypes.object,
  propertyNameList: PropTypes.array,
  children: PropTypes.object,
  fetchValuesOnMount: PropTypes.bool,
};

export { UserDataProvider, useUserData };
