/**
 * Not to be used for styling. Just for querying.
 * WARNING: these classes are used "hardcoded" (in tests).
 */
export const STATIC_CLASS_NAME = {
  field: 'ston-field-main',
  fieldGroup: 'ston-fieldgroup-main',
  fieldCheck: 'ston-field-check',
  fieldCheckTick: 'ston-field-check-tick',
  fieldWrap: 'ston-field-wrap',
  fieldCheckWrap: 'ston-field-check-wrap',
  inputWrap: 'ston-input-wrap',
  inputRequiredSuffix: 'ston-input-required-suffix',
  inputLabel: 'ston-input-label',
  inputMessage: 'ston-input-message',
  inputTooltip: 'ston-input-tooltip',
  statusIcon: 'ston-status-icon',
  statusWarning: 'ston-status-warning',
  statusInfo: 'ston-status-info',
  statusError: 'ston-status-error',
  statusSuccess: 'ston-status-success',
  dialog: 'ston-dialog-main',
  dialogContent: 'ston-dialog-content',
  dropdownTrigger: 'ston-dropdown-trigger',
  modalBackdrop: 'ston-modal-backdrop',
  modal: 'ston-modal-main',
  modalClose: 'ston-modal-close',
  modalFullscreen: 'ston-modal-fullscreen',
  portal: 'ston-portal',
  focusLocked: 'ston-focus-locked',
  tooltip: 'ston-tooltip-main',
  tooltipTrigger: 'ston-tooltipTrigger',
};

export const FILE_SIZES = {
  oneKilobyte: 1024,
  oneMegabyte: 1_048_576,
  sixAndHalfMegabytes: 6_815_744,
  tenMegabytes: 10_485_760,
  fiftyMegaBytes: 52_428_800,
};
