import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useRefWidth from '@playerCommon/hooks/useRefWidth';
import Dropdown from '@playerCommon/CustomElements/Dropdown';
import ActionList from '@playerCommon/CustomElements/ActionList/ActionList';
import { removeDiacriticsAndAccents } from 'helpers/textManagement';
import { ArrowsNavigationArea } from '@playerCommon/ComplexElements/ArrowsNavigationArea';
import DropdownButton from './DropdownButton';
import DropdownWrap from './DropdownWrap';
import { DropdownFilteringInput } from './DropdownFilteringInput';

const MIN_ITEMS_TO_SHOW_FILTERING = 5;

const DropdownInput = ({
  triggerId,
  className,
  onChange,
  value,
  valueFormatter = () => {},
  options = [],
  title,
  tooltip,
  dataCy,
  stonlyTrigger,
  stonlyTriggerCanvas,
  name,
  placeholder,
  background,
  leftIcon,
  leftIconSize,
  dropdownPlacement,
  dropdownWidth,
  disabled,
  maxHeight,
  filteringEnabled = false,
  sortOptions,
  triggerComponent,
  onClose,
  type,
  invalid = false,
  isValid,
  floatingPlacementStrategy,
  required,
}) => {
  const dropdownRef = useRef();
  const dropdownWrapRef = useRef();
  const [dropdownWrapWidth] = useRefWidth(dropdownWrapRef);
  const [filterValue, setFilterValue] = useState('');

  const showFilteringInput = filteringEnabled && options.length > MIN_ITEMS_TO_SHOW_FILTERING;

  const filterRegexp =
    showFilteringInput && !!filterValue ? new RegExp(removeDiacriticsAndAccents(filterValue), 'i') : undefined;

  const closeDropdown = () => {
    dropdownRef.current.closeDropdown();
  };

  const onDropdownChange = onChange ? val => onChange({ target: { name, value: val } }) : null;

  const onDropdownOpen = () => {
    setFilterValue('');
  };

  const nameFound = options.find(el => el.value === value);

  let filteredOptions = options.filter(
    filterRegexp ? opt => filterRegexp.test(removeDiacriticsAndAccents(opt.label)) : Boolean
  );
  if (sortOptions === 'ascending') {
    filteredOptions = [...filteredOptions].sort((a, b) => (a.label || '').localeCompare(b.label));
  }

  const DropdownTrigger = triggerComponent || (
    <DropdownButton
      isPlaceholder={!!(!value && placeholder)}
      name={(nameFound && nameFound.label) || valueFormatter(value) || value || placeholder}
      background={background}
      leftIcon={leftIcon}
      leftIconSize={leftIconSize}
      disabled={disabled}
      invalid={invalid}
      isValid={isValid}
    />
  );

  return (
    <DropdownWrap
      ref={dropdownWrapRef}
      triggerId={triggerId}
      className={className}
      title={title}
      tooltip={tooltip}
      stonlyTrigger={stonlyTrigger}
      stonlyTriggerCanvas={stonlyTriggerCanvas}
      required={required}
    >
      <Dropdown
        triggerId={triggerId}
        placement={dropdownPlacement}
        offsetValue={16}
        width={dropdownWidth || dropdownWrapWidth}
        ref={dropdownRef}
        dataCy={dataCy}
        trigger={DropdownTrigger}
        disabled={disabled}
        onOpen={onDropdownOpen}
        onClose={onClose}
        invalid={invalid}
        isFlipDisabled={showFilteringInput}
        floatingPlacementStrategy={floatingPlacementStrategy}
      >
        <ArrowsNavigationArea disabled={showFilteringInput} autoFocus restoreFocusOnUnmount>
          {showFilteringInput && <DropdownFilteringInput onChange={setFilterValue} />}
          <ActionList
            selectedOption={value}
            globalAction={onDropdownChange}
            options={filteredOptions}
            onPostSelect={closeDropdown}
            maxHeight={maxHeight}
            type={type}
          />
        </ArrowsNavigationArea>
      </Dropdown>
    </DropdownWrap>
  );
};

DropdownInput.propTypes = {
  triggerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  valueFormatter: PropTypes.func,
  options: PropTypes.array,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
  tooltip: PropTypes.object,
  dropdownPlacement: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  dataCy: PropTypes.string,
  stonlyTrigger: PropTypes.string,
  stonlyTriggerCanvas: PropTypes.string,
  background: PropTypes.string,
  leftIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  leftIconSize: PropTypes.number,
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
  filteringEnabled: PropTypes.bool,
  triggerComponent: PropTypes.object,
  sortOptions: PropTypes.oneOf(['ascending']),
  type: PropTypes.string,
  dropdownWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  invalid: PropTypes.bool,
  isValid: PropTypes.bool,
  floatingPlacementStrategy: PropTypes.string,
  required: PropTypes.bool,
};

export default DropdownInput;
