import React from 'react';

const guideDataContext = React.createContext({
  guide: {},
  steps: [],
  stepConnectionList: [],
  mode: '',
  guideInfo: {},
  access: 0, // not sure
  firstStep: null,
  isWidget: false,
});

// firstStep,
// hasIntroduction,
// displayStepTitle,
// displayProgressBar,
// displayBackButton,
// // explanationContents: contents,
// // illustrations,
// // stepNext,
// // stepInput,
// // communications,
// allowComments,
// allowFeedback,
// displayAds,
// mode,
// stepConnectionList,
// consolidatedStepNext: stepConnectionList, // temp to keep it working
// access: mainExplanationInfo.access,
// mainExplanationInfo,
// setLanguage,
// guideOrientation,

export default guideDataContext;
