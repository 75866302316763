import styled, { css } from 'styled-components';

const InputLabel = styled.span`
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.steel};
  margin-bottom: 4px;
  display: block;
  cursor: default;
`;

const InputContainer = styled.div<{ withMessageBelow?: boolean }>`
  min-width: 0;
  width: 100%;
  position: relative; // needed only in InputMessageWrap for ellipsis. Should not be needed by any InputField

  &:has([aria-expanded='true']), // for dropdown buttons
  &:focus-within {
    ${InputLabel} {
      color: ${({ theme }) => theme.slateGrey};
    }
  }

  &:has(input:disabled) {
    // future, todo - we will have contenteditable divs AND we don't want it to work with ButtonAdditionalAction disabled, and for selects with disabled options
    ${InputLabel} {
      color: ${props => props.theme.lightGrey};
    }
  }
`;

const InputRequiredLabelSuffix = styled.span`
  color: ${({ theme }) => theme.pink};
  margin-left: 2px;
`;

const InputMessageWrap = styled.div<{ $textColor?: string | false }>`
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  width: 100%;
  color: ${({ theme, $textColor }) => $textColor || theme.darkGrey};
  margin-top: 4px;
  display: flex;
  align-items: flex-start;
`;

const InputMessageIconWrap = styled.div`
  display: flex;
  align-items: center;
  height: 12px;
  width: 12px;
  margin-top: 2px;
  margin-right: 4px;
  & svg path {
    fill: currentColor;
  }
`;

export default { InputContainer, InputLabel, InputRequiredLabelSuffix, InputMessageWrap, InputMessageIconWrap };
