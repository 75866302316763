import React, { useEffect, useState, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { useListBodyRef } from './List';

const BASE_PADDING_PX = 8; // should be the same as in ListBody

/*
  width of the styled scrollbar.
  Will be used to extend padding-right of the header when TableBody has scrollbar (to make right alignment the same)
*/
const SCROLLBAR_GAP_PX = 12;

const Container = styled.div<{ $forceScrollbarGap: boolean; $showBottomDivider: boolean }>`
  position: sticky;
  top: 0;
  outline: none;
  padding: ${BASE_PADDING_PX}px;
  padding-right: ${({ $forceScrollbarGap }) =>
    $forceScrollbarGap ? BASE_PADDING_PX + SCROLLBAR_GAP_PX : BASE_PADDING_PX}px;
  border-bottom: 1px solid ${props => props.theme.white};

  ${({ $showBottomDivider }) =>
    $showBottomDivider &&
    css`
      border-bottom-color: ${props => props.theme.silver};
    `}
  background: ${props => props.theme.white};
`;

export const ListHeader = forwardRef<HTMLDivElement, { children?: React.ReactNode; showBottomDivider?: boolean }>(
  ({ children, showBottomDivider, ...rest }, forwardedRef) => {
    const [isSeparatorVisible, setIsSeparatorVisible] = useState(false);
    const [isScrollbarVisible, setIsScrollbarVisible] = useState(false);

    const bodyRef = useListBodyRef();

    /* detect if scrollable element has scrollbar (if so, then header will be given gap to uncover it ) */
    /* WAAARNING - there should be some dependency here but if we add bodyRef, then it's not refreshing on filtering  */
    useEffect(() => {
      setIsScrollbarVisible(Number(bodyRef?.current?.scrollHeight) > Number(bodyRef?.current?.clientHeight));
    });

    /* detect if showing a separator (line above sticky header) is needed - inly if we have content scrolled */
    useEffect(() => {
      const scrollableElement = bodyRef?.current;

      if (scrollableElement) {
        const onScroll = () => {
          setIsSeparatorVisible(scrollableElement.scrollTop > 1);
        };

        scrollableElement.addEventListener('scroll', onScroll);
        return () => {
          scrollableElement.removeEventListener('scroll', onScroll);
        };
      }
      return undefined;
    }, [bodyRef]);

    const isBottomDividerVisible = typeof showBottomDivider === 'boolean' ? showBottomDivider : isSeparatorVisible;

    return (
      <Container
        {...rest}
        ref={forwardedRef}
        $showBottomDivider={isBottomDividerVisible}
        $forceScrollbarGap={isScrollbarVisible}
      >
        {children}
      </Container>
    );
  }
);
