/* eslint-disable no-param-reassign */
/* eslint-disable no-continue */
import {
  STEP_MODULE_TYPE,
  STEP_NEXT_TYPE,
  NON_STANDARD_STEP_NEXTS,
  STEP_SEPARATOR,
  STEP_TYPE,
  EMBEDDED_GUIDE_START_TYPES,
} from 'global/index';
import { getEmbeddedGuideInfo, getStepInput } from 'helpers/guidePlayerHelpers';
import { getLanguageShorthand } from 'helpers/i18n';
import { clientOnlyMemoize } from '@stonlyCommons/helpers/memoizationHelpers';

const SPECIAL_STEP_TYPES = new Set([STEP_TYPE.contactForm, STEP_TYPE.nps, STEP_TYPE.automation]);

/**
 * Returns an array with connections that do not contain broken links to removed guides
 */
export const getSafeConnectionList = guide => {
  const { steps, stepConnectionList, allGuides } = guide;
  const stepsIds = new Set([...steps.map(s => s.stepId), ...NON_STANDARD_STEP_NEXTS]);
  const stepNextCopy = [...stepConnectionList].filter(sn => {
    // ADR#015
    // basically filter out stepConnection if it has no content
    if (!sn.label && !sn.linkUrl && !sn.toStepId && !sn.action) return false;

    // ADR#018
    // filter out stepConnection if it doesn't point to any specific step
    if (sn.toStepId && !stepsIds.has(sn.toStepId)) return false;
    return true;
  });

  steps.forEach(step => {
    const embeddedGuideStepModule = step.stepModule.find(sm => sm.type === STEP_MODULE_TYPE.embeddedGuide);
    const stepIsEmbeddedGuide = step.stepType === STEP_TYPE.guide;

    // if guide does not exist (it was removed or some other shit) we need to remove all references to it and it's linking step
    if (stepIsEmbeddedGuide && !embeddedGuideStepModule) {
      const stepNextToRemoveIndex = stepNextCopy.findIndex(sn => sn.toStepId === step.stepId);
      if (stepNextToRemoveIndex > -1) {
        stepNextCopy.splice(stepNextToRemoveIndex, 1);
      }
    }
    // if guide does not exist (it was removed or some other shit) we need to remove all references to it and it's linking step
    if (embeddedGuideStepModule) {
      const { guideId } = embeddedGuideStepModule.properties;
      const linkedGuide = allGuides.find(g => g.guideId === guideId);
      if (!linkedGuide) {
        const stepNextToRemoveIndex = stepNextCopy.findIndex(sn => sn.toStepId === step.stepId);
        if (stepNextToRemoveIndex > -1) {
          stepNextCopy.splice(stepNextToRemoveIndex, 1);
        }
      }
    }
    // if step does not exist we need to remove all steps that are linking to it
    if (embeddedGuideStepModule) {
      const { guideId, startFromStepId, stepStartType } = embeddedGuideStepModule.properties;
      const linkedGuide = allGuides.find(g => g.guideId === guideId);
      const linkedStep = linkedGuide.steps.find(s => s.stepId === startFromStepId);
      if (stepStartType === EMBEDDED_GUIDE_START_TYPES.SPECIFIC_STEP && !linkedStep) {
        const stepNextToRemoveIndex = stepNextCopy.findIndex(sn => sn.toStepId === step.stepId);
        if (stepNextToRemoveIndex > -1) {
          stepNextCopy.splice(stepNextToRemoveIndex, 1);
        }
      }
    }
  });

  return stepNextCopy;
};

export const findEmbeddedNextStepBasedOnPath = (currentStepNext, stepsPath = [], guide) => {
  const { stepConnectionList, steps, guideInfo } = guide;
  const startingStepId = stepsPath[stepsPath.length - 1];
  const rootGuideId = guideInfo.guideId;

  const startingStep = steps.find(el => el.stepId === startingStepId);

  // if current step is in the root guide
  if (startingStep?.guideId === rootGuideId) {
    // that means we cannot go any higher and we can bail already
    return currentStepNext;
  }

  const stepsWithEmbeddedGuideModuleMap = Object.fromEntries(
    steps
      .filter(step => !!step.stepModule.find(sm => sm.type === STEP_MODULE_TYPE.embeddedGuide))
      .map(step => [step.stepId, step])
  );

  const stepIdsWithEmbeddedGuideInPath = stepsPath.filter(stepId => !!stepsWithEmbeddedGuideModuleMap[stepId]);

  if (!stepIdsWithEmbeddedGuideInPath.length) {
    return currentStepNext;
  }

  let stepNextToUse = currentStepNext;

  for (let i = stepIdsWithEmbeddedGuideInPath.length; i > 0; i--) {
    const currentStepId = stepIdsWithEmbeddedGuideInPath[i - 1];
    const stepConnection = stepConnectionList.find(el => el.fromStepId === currentStepId);

    if (!stepConnection || !stepConnection.toStepId || stepConnection.toStepId === STEP_NEXT_TYPE.END_OF_GUIDE) {
      continue;
    }

    const currentStepLastIndex = stepsPath.lastIndexOf(currentStepId);
    const nextStepLastIndex = stepsPath.lastIndexOf(stepConnection.toStepId);
    const isNextStepAfterCurrentStep = currentStepLastIndex < nextStepLastIndex;

    if (isNextStepAfterCurrentStep) {
      // case when next step is already present in the path and is placed after current step
      // need to ignore it otherwise there will be endless loop
      continue;
    } else {
      stepNextToUse = stepConnection;
      break;
    }
  }
  return stepNextToUse;
};

export const findEmbeddedFirstStepId = (toStepId, guide) => {
  const { steps, allGuides } = guide;
  const foundEmbeddedGuideStep = steps.find(s => s.stepType === STEP_TYPE.guide && s.stepId === toStepId);
  if (foundEmbeddedGuideStep) {
    const embeddedGuideInfo = getEmbeddedGuideInfo(foundEmbeddedGuideStep);
    if (embeddedGuideInfo) {
      if (embeddedGuideInfo.startFromStepId) {
        return embeddedGuideInfo.startFromStepId;
      }
      const foundEmbeddedGuideInfo = allGuides.find(mi => mi.guideId === embeddedGuideInfo.guideId);
      if (foundEmbeddedGuideInfo) {
        return foundEmbeddedGuideInfo.firstStepId;
      }
    }
  }
  return toStepId;
};

/**
 * return `stepId,targetStepId` if toStepId step is an embedding-guide-step OR just `stepId` if not
 */
export const findEmbeddedFirstStep = (toStepId, guide) => {
  const embeddedFirstStepId = findEmbeddedFirstStepId(toStepId, guide);

  return embeddedFirstStepId === toStepId ? toStepId : `${toStepId}${STEP_SEPARATOR}${embeddedFirstStepId}`;
};

// ADR#026
export const getSchemePreparedForMaxLengthComputation = guide => {
  const { stepConnectionList, steps } = guide;
  const embeddingSteps = steps.filter(s => s.stepType === STEP_TYPE.guide);

  const resScheme = {};

  function addToResScheme(fromStepId, toStepId) {
    if (resScheme[fromStepId]) {
      resScheme[fromStepId].push(toStepId);
    } else {
      resScheme[fromStepId] = [toStepId];
    }
  }

  // if there are no embedded guides, we can just skip this whole thing
  if (embeddingSteps.length) {
    const hasEmbeddingStepWithNextSteps = embeddingSteps.find(es => {
      const postEmbeddedGuideStepsNexts = stepConnectionList.filter(sc => sc.fromStepId === es.stepId);
      return !!postEmbeddedGuideStepsNexts.length;
    });

    // if the embedded guides have no "post-embedded guide" steps we can also skip this computation (which is often a case)
    if (hasEmbeddingStepWithNextSteps) {
      const stepsWithNoStepNexts = steps.filter(step => {
        const stepConnection = stepConnectionList.find(sc => sc.fromStepId === step.stepId);
        return !stepConnection || !stepConnection.toStepId || stepConnection.toStepId === STEP_NEXT_TYPE.END_OF_GUIDE;
      });

      stepsWithNoStepNexts.forEach(stepWithNoStepNext => {
        const stepIdToAttachExitTo = stepWithNoStepNext.stepId;

        const usedSteps = {};
        function findPotentialExit(step) {
          if (usedSteps[step.stepId]) return;
          usedSteps[step.stepId] = true;

          const embeddingStepsLinkingToStep = embeddingSteps.filter(es => {
            const embeddedGuideInfo = getEmbeddedGuideInfo(es);
            return step.guideId === embeddedGuideInfo?.guideId;
          });

          embeddingStepsLinkingToStep.forEach(es => {
            const postEmbeddedGuideStepsNexts = stepConnectionList.filter(sc => sc.fromStepId === es.stepId);

            if (postEmbeddedGuideStepsNexts.length) {
              postEmbeddedGuideStepsNexts.forEach(pegs => {
                const isConnectionToEmbeddingStep = embeddingSteps.some(({ stepId }) => stepId === pegs.toStepId);
                let { toStepId } = pegs;
                if (isConnectionToEmbeddingStep) {
                  toStepId = findEmbeddedFirstStepId(toStepId, guide);
                }
                addToResScheme(stepIdToAttachExitTo, toStepId);
              });
            } else {
              findPotentialExit(es);
            }
          });
        }
        findPotentialExit(stepWithNoStepNext);
      });
    }
  }

  const computedStepConnectionList = stepConnectionList
    .map(sn => ({
      fromStepId: sn.fromStepId,
      toStepId: findEmbeddedFirstStepId(sn.toStepId, guide),
    }))
    .filter(sn => ![STEP_NEXT_TYPE.SPECIAL, STEP_NEXT_TYPE.EXTERNAL_LINK].includes(sn.toStepId));

  computedStepConnectionList.forEach(sc => addToResScheme(sc.fromStepId, sc.toStepId));

  const allStepIdsArray = Object.entries(resScheme).flat(Number.POSITIVE_INFINITY).map(Number);
  const allUniqueStepIds = [...new Set(allStepIdsArray)];

  return { stepsLinks: resScheme, stepsList: allUniqueStepIds };
};

const computeLongestPathForEachStep = ({ stepsLinks, stepsList }) => {
  const longestRes = {};
  const stepsCount = stepsList.length;

  // this is a regular graph's depth first search algorithm adapted to our thing
  const depthFirstSearch = (stepId, visited, memo) => {
    const toStepIds = stepsLinks[stepId] || [];

    if (visited[stepId] || !toStepIds.length) return memo[stepId] || 1;

    visited[stepId] = true;

    // eslint-disable-next-line unicorn/no-new-array
    const locMax = new Array(toStepIds.length).fill(0);
    toStepIds.forEach(toStepId => locMax.push(depthFirstSearch(toStepId, visited, memo)));

    const max = 1 + Math.max(...locMax);
    memo[stepId] = max;

    return max;
  };

  stepsList.forEach(stepId => {
    // eslint-disable-next-line unicorn/no-new-array
    const vis = new Array(stepsCount + 1).fill(false);
    longestRes[stepId] = depthFirstSearch(stepId, vis, {});
  });

  return longestRes;
};

const findLongestPathsPerStep = clientOnlyMemoize(guide => {
  const schemeForComputing = getSchemePreparedForMaxLengthComputation(guide);
  return computeLongestPathForEachStep(schemeForComputing);
});

export const findMaxSteps = (guide, currentStepId) => {
  const longestPathsPerStep = findLongestPathsPerStep(guide);

  return longestPathsPerStep[currentStepId];
};

export const getCompletionRate = (stepsPath, guide) => {
  if (!stepsPath) return 0;
  const { steps } = guide;

  const embeddedGuidesLinkingSteps = new Set(steps.filter(s => s.stepType === STEP_TYPE.guide).map(s => s.stepId));

  const maxSteps = findMaxSteps(guide, stepsPath[stepsPath.length - 1]);
  // we subtract 1 to not take into account step no. 1
  const remainingStepsNumber = Math.max(0, maxSteps - 1);

  // we remove "steps linking between embedded guides"
  const completedSteps = stepsPath.filter(stepId => !embeddedGuidesLinkingSteps.has(stepId));

  const introductionStepIndex = completedSteps.indexOf(-2);
  if (introductionStepIndex > -1) {
    completedSteps.splice(introductionStepIndex, 1);
  }

  const completedStepsNumber = completedSteps.length;

  return Math.floor(((completedStepsNumber - 1) / (remainingStepsNumber + completedStepsNumber - 1 || 1)) * 100);
};

function getSubsequentLinearStepsPath(startId, guide, previousPath = []) {
  const { steps, stepConnectionList, guideInfo } = guide;
  const language = getLanguageShorthand();
  const { defaultLanguage } = guideInfo.options;

  function iterateTree(stepId, path = []) {
    if (!stepId || NON_STANDARD_STEP_NEXTS.includes(stepId)) return path;
    const currentStep = steps.find(s => s.stepId === stepId);

    const currentStepIsSpecial = SPECIAL_STEP_TYPES.has(currentStep.stepType);

    const stepInfo = steps.find(step => step.stepId === stepId);

    if (path.includes(stepId) || previousPath.includes(stepId)) {
      return path;
    }

    if (!stepInfo || (stepInfo && stepInfo.stepType === STEP_TYPE.guide)) {
      return path;
    }

    if (currentStepIsSpecial) return path;

    const stepNexts = stepConnectionList.filter(sn => sn.fromStepId === stepId);
    const stepInputs = getStepInput(currentStep, language, defaultLanguage);

    if (stepNexts.length !== 1 || stepInputs.length > 0) {
      return [...path, stepId];
    }

    if (stepNexts[0].buttonEnabled) {
      return [...path, stepId];
    }

    return iterateTree(stepNexts[0].toStepId, [...path, stepId]);
  }

  return iterateTree(startId);
}

function getPreviousLinearStepsPath(previousStepsPath, guide) {
  const { steps, stepConnectionList, guideInfo } = guide;
  const language = getLanguageShorthand();
  const { defaultLanguage } = guideInfo.options;

  function iterateTreeUpwards(stepsPath, path = []) {
    const stepId = stepsPath[stepsPath.length - 1];
    const previousStepId = stepsPath[stepsPath.length - 2];
    const previousStepStepNexts = stepConnectionList.filter(sn => sn.fromStepId === previousStepId);
    const previousPath = stepsPath.slice(0, -1);

    // handle all specials here
    const currentStep = steps.find(s => s.stepId === stepId);
    const currentStepIsSpecial = SPECIAL_STEP_TYPES.has(currentStep.stepType);

    if (stepId === STEP_NEXT_TYPE.AD) {
      return iterateTreeUpwards(previousPath, [...path, previousStepId]);
    }

    if (currentStepIsSpecial) {
      return [...path, previousStepId, stepId];
    }

    if (previousStepStepNexts.length !== 1) {
      return [...path, stepId];
    }

    const previousStep = steps.find(step => step.stepId === previousStepId);
    const previousStepIsSpecial = SPECIAL_STEP_TYPES.has(currentStep.stepType);

    if (!previousStep || (previousStep && previousStep.type === STEP_TYPE.guide)) {
      return [...path, stepId];
    }

    const previousStepNexts = stepConnectionList.find(sn => sn.toStepId === stepId && sn.fromStepId === previousStepId);
    if (!previousStepNexts) return [...path, stepId];

    if (previousStepNexts.buttonEnabled) return [...path, stepId];

    if (previousStepIsSpecial) return path;

    const previousStepStepInputs = getStepInput(previousStep, language, defaultLanguage);
    if (previousStepStepInputs.length > 0) {
      return [...path, stepId];
    }

    return iterateTreeUpwards(previousPath, [...path, stepId]);
  }

  const result = iterateTreeUpwards(previousStepsPath);

  return result.slice(1).reverse();
}

export function getFullScrollableStepsPath({ stepsPath, guide }) {
  const currentStepId = stepsPath[stepsPath.length - 1];

  let res = [];
  if (currentStepId === STEP_NEXT_TYPE.INTRODUCTION || currentStepId === 'introduction') {
    res = [STEP_NEXT_TYPE.INTRODUCTION];
  } else if (currentStepId === STEP_NEXT_TYPE.AD) {
    res = [STEP_NEXT_TYPE.AD];
  } else if (currentStepId === STEP_NEXT_TYPE.END_OF_GUIDE) {
    res = [currentStepId];
  } else {
    const previous = getPreviousLinearStepsPath(stepsPath, guide);
    const subsequent = getSubsequentLinearStepsPath(currentStepId, guide, previous);
    res = [...previous, ...subsequent];
  }

  return res;
}

export const getAllTreeSteps = clientOnlyMemoize((stepNext, firstStep) => {
  let computedPaths = new Set();

  function traverseTree(stepId, computedPath) {
    const stepNexts = stepNext.filter(sn => sn.fromStepId === stepId);

    if (!stepNexts.length || computedPath.includes(stepId)) {
      computedPaths = new Set([...computedPaths, ...computedPath, stepId]);
      return;
    }

    stepNexts.forEach(sn => {
      if (!sn.toStepId || sn.toStepId === STEP_NEXT_TYPE.END_OF_GUIDE) {
        computedPaths = new Set([...computedPaths, ...computedPath, stepId]);
      } else {
        traverseTree(sn.toStepId, [...computedPath, stepId]);
      }
    });
  }

  traverseTree(firstStep, []);

  return [...computedPaths];
});

export const newGetAllTreeSteps = clientOnlyMemoize((guideConnectionList, firstStepId) => {
  const metadata = {
    toStepIdListByStepId: {},
    linkedStepIds: {},
  };

  // collect toStepId list starting from each stepId
  guideConnectionList.forEach(connection => {
    const { fromStepId, toStepId } = connection;

    if (Array.isArray(metadata.toStepIdListByStepId[fromStepId])) {
      metadata.toStepIdListByStepId[fromStepId].push(toStepId);
    } else {
      metadata.toStepIdListByStepId[fromStepId] = [toStepId];
    }
  });

  (function collectLinkedStepIdsForStepId(stepId) {
    if (metadata.linkedStepIds[stepId]) {
      return;
    }

    metadata.linkedStepIds[stepId] = true;
    (metadata.toStepIdListByStepId[stepId] || []).forEach(collectLinkedStepIdsForStepId);
  })(firstStepId);

  return Object.keys(metadata.linkedStepIds)
    .map(Number)
    .filter(toStepId => toStepId > 0);
});
