import styled, { css } from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';

const FieldWrap = styled.div<{
  $borderColor?: string;
  $disabled?: boolean;
}>`
  display: flex;
  width: 100%;
  border: 1px solid ${({ theme, $borderColor }) => $borderColor || theme.grey};
  background: ${({ theme }) => theme.white};
  border-radius: 4px;
  background: ${({ theme }) => theme.white};

  height: auto;
  align-items: flex-start;

  &:focus-within {
    border-color: ${({ theme }) => theme.darkBlue};
    border-width: 2px;
  }

  ${({ theme, $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
      background: ${theme.paleGrey};
      & > input {
        color: ${theme.lightGrey};
      }
      svg path {
        fill: ${theme.lightGrey};
      }
    `}
`;

const TextareaAutosizeElement = styled(TextareaAutosize)`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-family: inherit;
  color: ${({ theme }) => theme.darkGrey};
  width: 100%;
  min-width: 0;
  border: 0;
  outline: 0;
  padding: 0;
  margin: 0;
  background: transparent;
  appearance: none;
  resize: none;
  padding: 8px 12px;

  &::placeholder {
    color: ${({ theme }) => theme.lightGrey};
  }
`;

export const FieldTextAreaStyles = { FieldWrap, TextareaAutosizeElement };
