import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import callApi from 'helpers/apiHelpers.js';
import { useTranslation } from 'react-i18next';
import { validateEmail, validateEmptyString } from 'helpers/validationHelpers.js';
import { posthogCapture } from 'components/Analytics/recorder';
import useForm from '@playerCommon/StandardElements/BaseInputs/useForm';
import { POSTHOG_EVENT_NAME, FUNNEL_STEP } from '@stonlyCommons/constants/Authentication.consts';
import { InputText } from '@playerCommon/ui/components/inputs/InputText';
import { ColumnFlex } from '@playerCommon/ui/components/Flex';
import { AUTHENTICATION_VIEWS, FUNNEL_FLOW_TYPE } from '../Authentication.consts';
import { AuthenticationButton, ForgotPassword, StyledAlert } from '../Authentication.styles.js';

function SignInBasic({ setAuthenticationView, handlePostAuthentication, loginEmail, funnelId }) {
  const { t } = useTranslation();
  const [loginError, setLoginError] = useState('');
  const [antiRageClickEnabled, setAntiRageClickEnabled] = useState(false);

  const initialFormState = {
    emailForLogin: loginEmail || '',
    passwordForLogin: '',
  };

  const inputRefs = {
    emailForLogin: useRef(null),
    passwordForLogin: useRef(null),
  };

  const validations = [
    ({ emailForLogin }) =>
      validateEmail(emailForLogin.replaceAll(/\s+/g, '').toLowerCase()) || {
        emailForLogin: t('Registration.EmailFormatError'),
      },
    ({ emailForLogin }) =>
      validateEmptyString(emailForLogin) || { emailForLogin: t('Registration.RequiredFieldError') },
    ({ passwordForLogin }) =>
      validateEmptyString(passwordForLogin) || { passwordForLogin: t('Registration.RequiredFieldError') },
  ];
  const { formValues, handleFormChange, handleWillSubmit, handleFormBlur, handleFormFocus, isFormValid, formStatus } =
    useForm({ initialFormState, validations, inputRefs });

  async function onSubmit(e) {
    e.preventDefault();
    setLoginError('');
    posthogCapture(POSTHOG_EVENT_NAME.SIGN_IN_FUNNEL, {
      funnelId,
      flow: FUNNEL_FLOW_TYPE.CLASSIC,
      step: FUNNEL_STEP.CLICK_LOGIN,
    });
    handleWillSubmit();
    if (!isFormValid) return;

    // to prevent locking user out due to rage clicks
    if (antiRageClickEnabled) return;
    setAntiRageClickEnabled(true);

    const formattedEmail = formValues.emailForLogin.replaceAll(/\s+/g, '').toLowerCase();

    try {
      const res = await callApi('v1/auth/login', 'post', {
        email: formattedEmail,
        password: formValues.passwordForLogin,
      });
      if (res.data.user) {
        posthogCapture(POSTHOG_EVENT_NAME.SIGN_IN_FUNNEL, {
          funnelId,
          step: FUNNEL_STEP.SIGNIN_SUCCESS,
        });
        handlePostAuthentication({ res });
      }
    } catch (error) {
      setAntiRageClickEnabled(false);
      if (error.status === 429 || (error.headers && error.headers['x-ratelimit-remaining'] === '0')) {
        setLoginError(t('SignIn.AuthenticationTooManyAttempts'));
      } else if (error.status === 401 && error.headers && error.headers['x-ratelimit-remaining'] === '1') {
        setLoginError(t('SignIn.AuthenticationAlmostTooManyAttempts'));
      } else {
        setLoginError(t('SignIn.AuthenticationError'));
      }
    }
  }

  return (
    <form id="loginForm" onSubmit={onSubmit}>
      {loginError && (
        <StyledAlert dataCy="signInError" severity="error">
          {loginError}
        </StyledAlert>
      )}
      <ColumnFlex gap={2} marginTop={2}>
        <InputText
          name="emailForLogin"
          value={formValues.emailForLogin}
          status={formStatus.emailForLogin.type}
          message={formStatus.emailForLogin.message}
          onChange={handleFormChange}
          onBlur={handleFormBlur}
          onFocus={handleFormFocus}
          placeholder="bill.crisper@yourcompany.com"
          label={t('SignIn.EmailFieldTitle')}
          data-cy="emailField"
          autoComplete="email"
          ref={inputRefs.emailForLogin}
        />
        <InputText
          type="password"
          name="passwordForLogin"
          value={formValues.passwordForLogin}
          status={formStatus.passwordForLogin.type}
          message={formStatus.passwordForLogin.message}
          onChange={handleFormChange}
          onBlur={handleFormBlur}
          onFocus={handleFormFocus}
          placeholder=""
          label={t('SignIn.PasswordFieldTitle')}
          data-cy="passwordField"
          autoComplete="current-password"
          ref={inputRefs.passwordForLogin}
        />
        <ForgotPassword
          type="button"
          data-cy="forgotPassword"
          onClick={() => setAuthenticationView(AUTHENTICATION_VIEWS.PASSWORD_RECOVERY)}
        >
          {t('SignIn.ForgotPassword')}
        </ForgotPassword>
      </ColumnFlex>
      <AuthenticationButton
        form="loginForm"
        usage="submit"
        type="bigText"
        fullWidth
        content={t('SignIn.LoginButton')}
        value="Submit"
        dataCy="login"
        disabled={antiRageClickEnabled}
      />
    </form>
  );
}

SignInBasic.propTypes = {
  setAuthenticationView: PropTypes.func,
  handlePostAuthentication: PropTypes.func,
  loginEmail: PropTypes.string,
  funnelId: PropTypes.string,
};

export default SignInBasic;
