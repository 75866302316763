import styled, { css } from 'styled-components';
import Button from '@playerCommon/StandardElements/Button';
import { Textarea } from '@playerCommon/StandardElements/BaseInputs';
import BackButton from '@playerCommon/CustomElements/BackButton';
import SuccessSVG from 'icons/successLarge.svg';
import UnsafeHtml from '@playerCommon/StandardElements/UnsafeHtml/UnsafeHtml';
import { navigationWithKeyboardStyle } from '@playerCommon/CommonStyledComponents/NavigationWithKeyboard';
import {
  playerContentTextStyles,
  mobilePlayerContentTextStyle,
  variableStyles,
} from '@playerCommon/CommonStyledComponents/Typography';

export const TitleContent = styled(UnsafeHtml)`
  margin-bottom: 40px;

  ${playerContentTextStyles};
  font-size: 28px;
  line-height: 36px;

  @media screen and (max-width: 899px) {
    ${mobilePlayerContentTextStyle};
    font-size: 28px;
    line-height: 36px;
  }

  @media screen and (max-width: 600px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const Title = styled.div`
  font-size: 28px;
  line-height: 36px;
  margin-bottom: 40px;

  @media screen and (max-width: 600px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const Content = styled.div`
  width: fit-content;
  max-width: 100%;
  margin-bottom: 32px;

  /* max-width: 560px; */

  @media screen and (max-width: 600px) {
    max-width: 312px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const RatingsWrap = styled.div`
  color: ${props => props.theme.lightGrey};
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const RatingNumber = styled.div`
  font-size: 28px;
  line-height: 40px;
  font-weight: 500;
  padding-bottom: 6px;
  transition: color 0.2s, transform 0.2s;
  transform-origin: 50% 65%; // wtf?

  @media screen and (max-width: 600px) {
    font-size: 18px;
    line-height: 26px;
    padding-bottom: 0;
  }
`;

export const Rating = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 44px;
  margin: 4px;
  border-radius: 4px;
  transition: color 0.2s, transform 0.2s, background-color 0.2s, border 0.2s, box-shadow 0.2s;
  cursor: ${({ isPreview }) => (isPreview ? 'default' : 'pointer')};
  ${navigationWithKeyboardStyle}

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    ${RatingNumber} {
      color: ${({ color }) => color};
      transform: scale(1.4);
    }

    &:after {
      background-color: ${({ color }) => color};
    }
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: ${props => props.theme.silver};
    width: 100%;
    height: 3px;
    transition: background-color 0.2s;
  }

  @media screen and (max-width: 600px) {
    width: 44px;
    height: 44px;
    border: 1px solid ${props => props.theme.silver};

    &:hover {
      background-color: ${({ color }) => color};
      border: 1px solid ${({ color }) => color};

      ${RatingNumber} {
        color: ${props => props.theme.white};
        transform: none;
      }
    }

    &:after {
      display: none;
    }
  }
`;

export const LabelsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${props => props.theme.steel};
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin-top: 12px;
  gap: 16px;

  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
`;

export const Label = styled.div`
  display: block;

  &:last-child {
    text-align: right;
  }
`;

export const StyledTextarea = styled(Textarea)`
  max-width: 560px;
  margin-bottom: 10px;
`;

export const ButtonsWrap = styled.div`
  display: flex;
  max-width: 560px;
`;

export const SkipButton = styled(Button)`
  ${({ isFirstStep }) =>
    isFirstStep &&
    css`
      button {
        border: none;
        font-size: 14px;
        line-height: 16px;
        padding: 0;

        &:hover {
          background-color: transparent;
          color: ${props => props.theme.darkGrey};
        }
      }
    `}
`;

export const SubmitButton = styled(Button)`
  margin-left: auto;
`;

export const ThankYou = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SuccessIcon = styled(SuccessSVG)`
  margin-bottom: 24px;
`;

export const StyledBackButton = styled(BackButton)`
  margin-right: 0;
`;

export const Canvas = styled.div`
  ${variableStyles}

  @media screen and (max-width: 899px) {
    padding: 0 ${({ theme }) => theme.stepsContentPaddings.contentPadding}px;
  }
`;
