import React, { useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import T from 'prop-types';
import { Virtuoso } from 'react-virtuoso';

import styled from 'styled-components';
import { getCustomBgScrollStyles } from '@playerCommon/CommonStyledComponents/CustomScrollbar';
import { retryWithInterval } from 'helpers/retryWithInterval';
import { useTreeRefs } from './hooks/useTreeRefs';
import { useTreeVisibleRowUuidList } from './hooks/useTreeVisibleRowUuidList';
import { useTreeInitializationKey } from './hooks/useTreeInitializationKey';
import { BLINKING_CLASS_NAME, BLINKING_CLASS_TIMEOUT_MILLISECONDS } from './parameters';

const COMPONENTS = {
  Scroller: styled.div`
    ${props => getCustomBgScrollStyles(props.theme.paleGrey)}
  `,
  List: styled.div``,
};

const MAX_VISIBLE_ROW_COUNT_FOR_SMOOTH_SCROLLING = 200; // virtuoso doc says "Warning: Using smooth scrolling over many items can kill performance and potentially clash with loading strategies."
const GO_TO_OFFSET_TOP_PX = -50;

export const FlatTree = React.memo(({ RowBlockComponent, rowStaticProps = {}, className }) => {
  const executeBlinkRowIntervalRef = useRef(undefined);
  const removeBlinkClassNameTimeoutRef = useRef(undefined);
  const virtuosoRef = useRef();
  const { flatTreeRef, rowRefByUuidRef } = useTreeRefs();

  const visibleRowUuidList = useTreeVisibleRowUuidList();
  const treeInitializationKey = useTreeInitializationKey(); // #docForTreeInitializationKey

  /**
   * Blink row for a while (native DOM classList.add/remove).
   * (e.g) used when scrolling to search result
   */
  const blinkRow = useCallback(({ uuid }) => {
    clearInterval(executeBlinkRowIntervalRef.current);

    executeBlinkRowIntervalRef.current = retryWithInterval(
      () => {
        if (rowRefByUuidRef.current?.[uuid]?.current) {
          const domElement = rowRefByUuidRef.current[uuid].current;
          if (domElement.classList.contains(BLINKING_CLASS_NAME)) {
            clearTimeout(removeBlinkClassNameTimeoutRef.current);
            domElement.classList.remove(BLINKING_CLASS_NAME);
          }

          domElement.classList.add(BLINKING_CLASS_NAME);
          removeBlinkClassNameTimeoutRef.current = setTimeout(() => {
            if (rowRefByUuidRef.current?.[uuid]?.current) {
              rowRefByUuidRef.current[uuid].current.classList.remove(BLINKING_CLASS_NAME);
            }
          }, BLINKING_CLASS_TIMEOUT_MILLISECONDS);

          return true; // will end up retrying
        }
        return false; // keep trying
      },
      { interval: 500, limit: 4 }
    );
  }, []);

  /**
   * Scroll to specific row with optional blinking of that row
   */
  const scrollToRow = useCallback(
    ({ uuid, behavior: behaviorFromParams = 'smooth', blink }) => {
      const behavior =
        visibleRowUuidList.length > MAX_VISIBLE_ROW_COUNT_FOR_SMOOTH_SCROLLING ? null : behaviorFromParams;

      if (virtuosoRef.current?.scrollToIndex) {
        virtuosoRef.current.scrollToIndex({
          index: visibleRowUuidList.indexOf(uuid),
          align: 'start',
          behavior,
          offset: GO_TO_OFFSET_TOP_PX,
        });
        if (blink) {
          setTimeout(() => blinkRow({ uuid }));
        }
      } else {
        console.log('STON: error scrollToRow uuid:', uuid);
      }
    },
    [visibleRowUuidList, blinkRow]
  );

  const renderItemContent = useCallback(
    (index, uuid) => {
      return <RowBlockComponent key={uuid} uuid={uuid} {...rowStaticProps} />;
    },
    [...Object.values(rowStaticProps), RowBlockComponent]
  );

  const computeItemKey = useCallback((index, uuid) => uuid, []);

  useImperativeHandle(
    flatTreeRef,
    () => {
      return {
        scrollToRow,
      };
    },
    [scrollToRow]
  );

  useEffect(() => {
    return () => {
      clearInterval(executeBlinkRowIntervalRef.current);
      clearTimeout(removeBlinkClassNameTimeoutRef.current);
    };
  }, []);

  // /* DISABLES VIRTUAL LIST (scrolling won't work) */
  // return visibleRowUuidList.map(uuid => <RowBlockComponent uuid={uuid} key={uuid} {...rowStaticProps} />);

  return (
    <Virtuoso
      key={treeInitializationKey}
      className={className}
      ref={virtuosoRef}
      data={visibleRowUuidList}
      computeItemKey={computeItemKey}
      itemContent={renderItemContent}
      components={COMPONENTS /* if it was inline, then remounting on each render */}
      useWindowScroll={false}
    />
  );
});

FlatTree.propTypes = {
  RowBlockComponent: T.func,
  rowStaticProps: T.object,
  className: T.string,
};
