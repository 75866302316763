import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FILE_SIZES } from 'global';
import FormFileInput from '@playerCommon/ComplexElements/ContactForm/Form/FormFileInput/FormFileInput';
import { useStepAttachments, FILE_UPLOAD_STATUS } from '@playerCommon/Contexts/stepAttachmentsProvider';

const ErrorMessage = styled.div`
  margin-top: 2px;
  color: ${({ theme }) => theme.errorColor};
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  min-height: 20px;
`;

function parseFileSize(number) {
  if (number < FILE_SIZES.ONE_KILOBYTE) {
    return `${number}bytes`;
  }
  if (number >= FILE_SIZES.ONE_KILOBYTE && number < FILE_SIZES.ONE_MEGABYTE) {
    return `${(number / FILE_SIZES.ONE_KILOBYTE).toFixed(1)}KB`;
  }
  if (number >= FILE_SIZES.ONE_MEGABYTE) {
    return `${(number / FILE_SIZES.ONE_MEGABYTE).toFixed(1)}MB`;
  }
  return 'unknown size';
}

const StepInputSingleAttachment = ({
  id,
  onChangeValue,
  fileList,
  status,
  message,
  name,
  placeholder,
  label,
  required,
  isPreview,
  totalAttachmentSizePerStep,
  stepId,
  onFileUploadSuccess,
  onFileRemove,
}) => {
  const { t } = useTranslation();
  const [fileUploadError, setFileUploadError] = useState(false);
  const errorMessageId = id && `error-message-${id}`;
  const inputId = id && `input-${id}`;
  const { attachmentDataByInputId, uploadAttachment, cancelAttachment } = useStepAttachments();

  const handleFileDrop = fileArray => {
    const [file] = fileArray;
    if (file.size > FILE_SIZES.FIVE_MEGABYTES) {
      setFileUploadError(t('Validation.FileSizeTooBigPerFile'));
      return;
    }

    if (totalAttachmentSizePerStep && totalAttachmentSizePerStep + file.size > FILE_SIZES.TEN_MEGABYTES) {
      setFileUploadError(t('Validation.FileSizeTooBig'));
      return;
    }

    setFileUploadError(false);

    onChangeValue([file]);
    uploadAttachment({ inputId: name, fileToUpload: file, stepId }).then(result => {
      if (!result) {
        setFileUploadError(t('Validation.FileUploadError'));
        onChangeValue([]);
        return;
      }

      if (typeof onFileUploadSuccess === 'function') {
        onFileUploadSuccess({ name, result });
      }
    });
  };

  const handleFileRemove = () => {
    if (typeof onFileRemove === 'function') {
      onFileRemove(name);
    }
    onChangeValue([]);
    cancelAttachment(name);
  };

  return (
    <>
      <FormFileInput
        id={inputId}
        onFile={handleFileDrop}
        disabled={isPreview || !!fileList?.length}
        placeholder={placeholder}
        label={label}
        required={required}
        aria-invalid={status === 'error'}
        aria-errormessage={status === 'error' ? errorMessageId : undefined}
        onRemoveFile={handleFileRemove}
        isUploading={attachmentDataByInputId[name]?.status === FILE_UPLOAD_STATUS.PENDING}
        fileName={fileList?.length ? `${fileList[0].name}, ${parseFileSize(fileList[0].size)}` : undefined}
      />
      <ErrorMessage data-cy="contactFormAttachmentsError">
        {(status === 'error' || fileUploadError) && (
          <span id={errorMessageId} aria-live="assertive">
            {message || fileUploadError}
          </span>
        )}
      </ErrorMessage>
    </>
  );
};

StepInputSingleAttachment.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChangeValue: PropTypes.func,
  fileList: PropTypes.array,
  error: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  isPreview: PropTypes.bool,
  totalAttachmentSizePerStep: PropTypes.number,
  stepId: PropTypes.number,
  onFileUploadSuccess: PropTypes.func,
  onFileRemove: PropTypes.func,
};

export default StepInputSingleAttachment;
