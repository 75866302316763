import React, { useCallback, useMemo, useEffect, useReducer, useRef } from 'react';

import awhilePersistor from 'helpers/awhilePersistor';
import { ACTION, initializeFlatTreeState, reducer } from './_core/state';
import { resolveStaticOpenedPath, resolveRowList } from './resolvers';
import { GoToFirstActiveRowInTreeCustomEvent, GoToRowUuidInTreeCustomEvent } from './events';

const OPEN_PATH_STORAGE_PREFIX = '__treePath:';
const EMPTY_SELECTED_UUID_LIST = [];
const EMPTY_ACTIVE_UUID_LIST = [];
const EMPTY_COLOR_FOR_UUID = {};

export const FlatTreeContext = React.createContext({});

/**
 * Data provider for FlatTree
 * @param {Object} props - component's properties
 * @param {React.ReactNode} children
 * @param {Array<{
 *  uuid: string;
 *  path:string;
 *  isFolder: boolean;
 *  isSpecial: boolean;
 *  payload: Object;
 * }>} props.rowList - list of all the rows (even hidden)
 *
 * @param {string} props.mainPathName - a path that is allways opened (usually root path). It is up to you if row is in this path
 * @param {{ [path:string]: bool}} props.defaultOpenPath - a paths initially (and only initially) opened
 * @param {string} props.openPathPersistKey - string as a prefix to save tree open/close state in sessionStorage. No saving if not provided
 * @param {(rowProps) => bool} props.memoizedGetCanDropInside - executed during dnd only on folders as additional condition to prevent/allow droping inside
 * @param {(rowProps) => bool} props.memoizedGetCanDropBelow - executed during dnd on rows as additional condition to prevent/allow droping below
 * @param {(rowProps) => bool} props.memoizedGetIsRowActive - executed on rerender on every row. Element is active so it's ancestors are active too (item active, so its folders too)
 * @param {(rowProps) => string} props.memoizedGetRowHighlightColor - executed on rerender on every row to highlight color. E.g. steps tree compare (green red yellow)
 * @param {({ rowList, mainPathName}) => string} props.generateShouldComponentUpdateToken - executed on props.rowList changed from outside. Custom logic, to check if provided data has really changed and need to reinitialize
 * @param {string} props.treeName - a name of the tree that can be accessed from context consumers
 * @param {(param) => void} props.onAfterMove - will be called after move event with the following parameters: {
 *      sourceUuid:string
 *      targetUuid:string,
 *      placement: 'inside' | 'below',
 *      state: object,
 *    }
 * @returns {JSX.Element}
 */
export const FlatTreeProvider = ({
  children,
  rowList,
  mainPathName,
  defaultOpenPath,
  openPathPersistKey = '',
  onAfterMove = Function.prototype,
  memoizedGetCanDropInside = row => true,
  memoizedGetCanDropBelow = row => true,
  memoizedGetIsRowActive,
  memoizedGetRowHighlightColor,
  generateShouldComponentUpdateToken,
  treeName = '',
}) => {
  // handle onAfterMove callbac/effect
  const onAfterMoveCallbackPayloadRef = useRef(undefined);

  // create row refs, for "goTo" function
  const rowRefByUuidRef = useRef({});
  const flatTreeRef = useRef({});

  /*

    Initialization section. Try to read openPath from storage

  */

  const [state, dispatchRaw] = useReducer(reducer, undefined, () => {
    const persistedOpenPath = openPathPersistKey
      ? awhilePersistor.readObject(openPathPersistKey, OPEN_PATH_STORAGE_PREFIX)
      : undefined;

    const mergedOpenPath = { ...persistedOpenPath, ...defaultOpenPath };

    return initializeFlatTreeState({ rowList, openPath: mergedOpenPath, mainPathName });
  });

  /*
    Performance optimization
    Compute a data token, to prevent executing "initialize" action
    every time props.rowList or props.mainPathName has changed.
    Bear in mind that prop.defaultOpenPath change does not trigger initialization
  */
  const shouldComponentUpdateToken =
    typeof generateShouldComponentUpdateToken === 'function'
      ? generateShouldComponentUpdateToken({ rowList, mainPathName })
      : JSON.stringify({ rowList, mainPathName });

  /* Collect uuidList to mark as active - to be dispatched in useEffect below */
  const activeUuidList =
    typeof memoizedGetIsRowActive === 'function'
      ? resolveRowList(state)
          .filter(memoizedGetIsRowActive)
          .map(row => row.uuid)
      : EMPTY_ACTIVE_UUID_LIST;
  const activeUuidListChangedToken = JSON.stringify(activeUuidList);

  /* prepare {uuid:color} object to highlight rows -  to be dispatched in useEffect below */
  const colorByRowUuid =
    typeof memoizedGetRowHighlightColor === 'function'
      ? resolveRowList(state).reduce((acc, row) => {
          const rowHighlightColor = memoizedGetRowHighlightColor(row);
          if (rowHighlightColor) {
            acc[row.uuid] = rowHighlightColor;
          }
          return acc;
        }, {})
      : EMPTY_COLOR_FOR_UUID;
  const colorByRowUuidChangedToken = JSON.stringify(colorByRowUuid);

  /*
    Proxy dispatch to:
    - execute "onMove" effect
    - log actions if debugging
    @mateusz will have to change the logic behind this
  */
  const dispatch = useCallback(
    (action = {}) => {
      if (action.type === ACTION.move) {
        onAfterMoveCallbackPayloadRef.current = action.payload;
      }
      // console.log(`%cFLAT_TREE dispatch:: ${action.type}`, 'color: #bb0000', { payload: action.payload }); // #DEBUG
      dispatchRaw(action);
    },
    [dispatchRaw]
  );

  /* initialize row data. Or reinitialize */
  const initialize = useCallback(({ rowList: initRowList = [], openPath = {}, mainPathName: initMainPathName }) => {
    dispatch({
      type: ACTION.initialize,
      payload: { rowList: initRowList, openPath, mainPathName: initMainPathName },
    });
  }, []);

  /*

    dispatchers section (TODO @mateusz move tree related to flatTree. leave data related here)

  */

  const expandAll = useCallback(() => {
    dispatch({ type: ACTION.expandAll });
  }, []);

  const collapseAll = useCallback(() => {
    dispatch({ type: ACTION.collapseAll });
  }, []);

  const openFolder = useCallback(({ uuid }) => {
    dispatch({ type: ACTION.openFolder, payload: { uuid } });
  }, []);

  const closeFolder = useCallback(({ uuid }) => {
    dispatch({ type: ACTION.closeFolder, payload: { uuid } });
  }, []);

  /** open path to row for a while (e.g. when jumping between search results. It won't be saved to storage) */
  const openToRowTemporary = useCallback(({ uuid }) => {
    dispatch({ type: ACTION.openToRowTemporary, payload: { uuid } });
  }, []);

  const closeTemporaryOpened = useCallback(() => {
    dispatch({ type: ACTION.closeTemporaryOpened });
  }, []);

  /** put different row in place of existing. (e.g. replace "show hidden steps" placeholder. Or for future ajax) */
  const replaceRow = useCallback(({ uuid, insertRowList = [] }) => {
    dispatch({ type: ACTION.replaceRow, payload: { uuid, insertRowList } });
  }, []);

  /** move row below other element or into it (if it's a folder) */
  const moveItem = useCallback(({ sourceUuid, targetUuid, placement }) => {
    dispatch({ type: ACTION.move, payload: { sourceUuid, targetUuid, placement } });
  }, []);

  /** mark element as being dragged */
  const setDragging = useCallback(({ uuid }) => {
    dispatch({ type: ACTION.setDragging, payload: { uuid } });
  }, []);

  /** Set row list active (e.g. one guide step can be represented by multiple rows and all rows are marked active) */
  const setActive = useCallback(({ uuidList }) => {
    dispatch({ type: ACTION.setActive, payload: { uuidList } });
  }, []);

  /** Scroll to specific row */
  const scrollToRow = useCallback(({ uuid, behavior = 'smooth', blink }) => {
    flatTreeRef.current?.scrollToRow({ uuid, behavior, blink });
  }, []);

  /** Set row colors by provided map of type { [rowUuid]: rowColor } */
  const setHighlightColor = useCallback(({ colorByUuid }) => {
    dispatch({
      type: ACTION.setHighlightColor,
      payload: { colorByUuid },
    });
  }, []);

  /** A resolve-row-color function executed on every row */
  const setHighlightColorByRule = useCallback(
    getColorForRow => {
      const colorByUuid = resolveRowList(state).reduce((acc, rowDef) => {
        const color = getColorForRow(rowDef);
        if (color) {
          acc[rowDef.uuid] = color; // #perf mutation
        }
        return acc;
      }, {});

      setHighlightColor({ colorByUuid });
      return colorByUuid;
    },
    [state.rowByUuid, setHighlightColor] // TODO @mateusz - use selector for raw state part
  );

  /** Set selected row list. Analogy to setActive but styled differently (e.g. mark all search result). */
  const setSelected = useCallback(({ uuidList: selectUuidList }) => {
    const uuidList = Array.isArray(selectUuidList) ? selectUuidList : EMPTY_SELECTED_UUID_LIST;
    dispatch({
      type: ACTION.setSelected,
      payload: { uuidList },
    });
  }, []);

  /** A resolve-is-row-selected function executed on every row */
  const setSelectedByRule = useCallback(
    getIsSelectedForRow => {
      const rowListToSelect = resolveRowList(state).filter(getIsSelectedForRow);
      const uuidList = rowListToSelect.map(row => row.uuid);

      setSelected({ uuidList });
      return rowListToSelect;
    },
    [state.rowByUuid, setSelected]
  ); // TODO @mateusz - use selector for raw state part

  /** A composition of actions to go to (and blink) specific row */
  const goToRow = useCallback(
    goToParams => {
      // 1. open path to row
      // 2. highlight it
      // 3. scroll to it
      // 4. remove higlighting indicator
      if (!Reflect.has(goToParams, 'uuid')) {
        throw new Error(
          `STON:ERROR FlatTree/goToRow method requires parameter in the following format: {uuid, behaviour} received: ${JSON.stringify(
            goToParams
          )}`
        );
      }
      const { uuid, behavior = 'smooth', blink = true } = goToParams;
      Promise.resolve()
        .then(() => openToRowTemporary({ uuid }))
        .then(() => scrollToRow({ uuid, behavior, blink }))
        .catch(e => {
          console.log('STON:Error cant go to uuid', { e, uuid, rowRefByUuidRef: rowRefByUuidRef.current });
        });
    },
    [openToRowTemporary, scrollToRow]
  );

  /*

    effects section

  */

  /** Reinitialize tree if some Provider props has changed - e.g step title modified outside */
  useEffect(() => {
    initialize({ rowList, mainPathName /* , no openPath change on reinit */ });
  }, [initialize, shouldComponentUpdateToken]);

  /** Mark active rows provided by FlatTree props */
  useEffect(() => {
    setActive({ uuidList: activeUuidList });
  }, [setActive, activeUuidListChangedToken, shouldComponentUpdateToken]);

  /* TODO move it to FlatTree */
  /** Mark highlighted rows provided by FlatTree color maping prop */
  useEffect(() => {
    setHighlightColor({ colorByUuid: colorByRowUuid });
  }, [setHighlightColor, colorByRowUuidChangedToken, shouldComponentUpdateToken]);

  /** Trigger after onMove effect */
  useEffect(() => {
    if (onAfterMoveCallbackPayloadRef.current) {
      const { sourceUuid, targetUuid, placement } = onAfterMoveCallbackPayloadRef.current;

      onAfterMove({
        sourceUuid,
        targetUuid,
        placement,
        state,
      });

      onAfterMoveCallbackPayloadRef.current = undefined;
    }
    // console.log(`%cFLAT_TREE state::`, 'color: #bb0000', state); // #DEBUG
  }, [state]);

  /** Save opening state in storage (if persist key prop provided) */
  useEffect(() => {
    if (openPathPersistKey) {
      try {
        const staticOpenedPath = resolveStaticOpenedPath(state);
        awhilePersistor.save(openPathPersistKey, JSON.stringify(staticOpenedPath), OPEN_PATH_STORAGE_PREFIX);
      } catch {
        // do nothing
      }
    }
  }, [state, openPathPersistKey]);

  /*

    custom event listeners section

  */
  const shouldRegisterEventListeners = !!treeName;
  const [firstActiveUuid] = activeUuidList;

  /** GoTo first active row (usefull places where no access to active row uuid. E.g on url's activeStepId changed) */
  useEffect(() => {
    const handleGoToFirstActiveRowInTreeCustomEvent = customEvent => {
      const { treeName: eventTreeName, behavior, blink } = customEvent.detail;
      if (eventTreeName === treeName && firstActiveUuid) {
        goToRow({ uuid: firstActiveUuid, behavior, blink });
      }
    };

    if (shouldRegisterEventListeners) {
      window.addEventListener(GoToFirstActiveRowInTreeCustomEvent, handleGoToFirstActiveRowInTreeCustomEvent);
      return () => {
        window.removeEventListener(GoToFirstActiveRowInTreeCustomEvent, handleGoToFirstActiveRowInTreeCustomEvent);
      };
    }
  }, [shouldRegisterEventListeners, firstActiveUuid, goToRow]);

  /** GoTo uuid (e.g. to sync in version comparison tool) */
  useEffect(() => {
    const handleGoToRowUuidInTreeCustomEvent = customEvent => {
      const { treeName: eventTreeName, uuid, behavior } = customEvent.detail;
      if (eventTreeName === treeName && uuid) {
        goToRow({ uuid, behavior });
      }
    };

    if (shouldRegisterEventListeners) {
      window.addEventListener(GoToRowUuidInTreeCustomEvent, handleGoToRowUuidInTreeCustomEvent);
      return () => {
        window.removeEventListener(GoToRowUuidInTreeCustomEvent, handleGoToRowUuidInTreeCustomEvent);
      };
    }
  }, [shouldRegisterEventListeners, goToRow]);

  return (
    <FlatTreeContext.Provider
      value={useMemo(
        () => ({
          state,
          dispatch,
          openFolder,
          closeFolder,
          replaceRow,
          expandAll,
          collapseAll,
          moveItem,
          setDragging,
          setActive,
          initialize,
          memoizedGetCanDropInside,
          memoizedGetCanDropBelow,
          scrollToRow,
          setHighlightColor,
          setHighlightColorByRule,
          setSelected,
          setSelectedByRule,
          openToRowTemporary,
          closeTemporaryOpened,
          goToRow,
          rowRefByUuidRef,
          flatTreeRef,
          treeName,
        }),
        [state]
      )}
    >
      {children}
    </FlatTreeContext.Provider>
  );
};
