import callApi from 'helpers/apiHelpers.js';
import { STEP_SEPARATOR, STEP_TYPE } from 'global';
import { getLanguageShorthand } from 'helpers/i18n';
import { generatePath } from 'react-router-dom';
import slugify from 'slugifyjs';

export const getFolderBranchIds = (folders = [], firstSubFolderId = '') => {
  if (!firstSubFolderId) {
    return [];
  }

  const idList = [firstSubFolderId];
  let currentIndex = 0;

  while (idList.length > currentIndex) {
    const currentParentId = idList[currentIndex];

    folders.forEach(item => {
      if (item.parentId === currentParentId) {
        idList.push(item.folderId);
      }
    });

    currentIndex += 1;
  }

  return idList;
};

export function getAllSubfoldersIds(folders, parentId) {
  const folderIdList = getFolderBranchIds(folders, parentId);
  // return folder id list apart from parentId
  return folderIdList.length > 1 ? folderIdList.slice(1) : [];
}

export const createPathFromParam = param => {
  if (param) {
    if (param === 'introduction') {
      return [-2];
    }
    return param.split(STEP_SEPARATOR).map(Number);
  }
  return [];
};

export const createPathForURL = path => path.join(STEP_SEPARATOR);

export const loadExplanation = async (
  explanationId,
  {
    isPreview = false,
    isShortlink = false,
    withKnowledgeBase = false,
    widgetId,
    segmentAnonymousId,
    segmentUserId,
    stonlyAnonymousId,
    customerUserId,
    isSnoozingEnabled,
    widgetRuleId,
  } = {}
) => {
  const baseRoute = isShortlink ? 'v1/explanation/link' : `v1/guide${isPreview ? `/preview` : ''}`;
  const { data } = await callApi(baseRoute, 'get', {
    [isShortlink ? 'code' : 'guideId']: explanationId,
    context: 'view',
    language: getLanguageShorthand() || 'en',
    withKnowledgeBase: +withKnowledgeBase,
    widgetId,
    segmentAnonymousId,
    segmentUserId,
    stonlyAnonymousId,
    customerUserId,
  });

  if (isSnoozingEnabled && +widgetRuleId) {
    try {
      const { data: snoozeSettings } = await callApi('v1/widget/trigger/snoozing/settings', 'get', {
        triggerId: +widgetRuleId,
      });
      data.triggerSettings = {
        snoozeSettings,
      };
    } catch {
      // ignore error
    }
  }

  return data;
};

export const loadGuideVersion = async ({ guideId, language, version }) => {
  const { data } = await callApi('v1/guide/history/version', 'get', { guideId, language, version });
  return data;
};

export const getChoicesPrettyPrint = (stepConnections, toStepId) => {
  let stepRowContent = '';
  const choices = stepConnections.filter(nextStep => nextStep.toStepId === toStepId);

  if (choices.length === 0) {
    return '';
  }

  let i = 1;
  choices.forEach(choice => {
    stepRowContent += choice.label;
    if (i < choices.length) {
      stepRowContent += i === choices.length - 1 ? ' or ' : ', ';
    }
    i += 1;
  });
  return stepRowContent;
};

export const findPreviousLinkToGo = (stepPath, guide, currentGuideId) => {
  // const stepPathWithContainer = explanation.steps.filter(s => stepPath.includes(s.stepId));
  const stepsPathForStepExplanation = stepPath.slice().reverse();
  let stepToGo = null;

  stepsPathForStepExplanation.forEach(sc => {
    const stepWithContainer = guide.steps.find(s => s.stepId === sc);
    if (stepWithContainer.guideId !== currentGuideId) {
      stepToGo = stepWithContainer.stepId;
    }
  });

  const updatedStepPath = stepPath.slice();

  updatedStepPath.length = updatedStepPath.indexOf(stepToGo) + 1;

  return updatedStepPath;
};

export const getSubExplanationNavigationInfo = (nextPathToLoad, pathToLoad, explanation, currentExplanationId) => {
  /*
   * This function is used to redirect users to the right explanation/subexplanation
   * and right step based on the URL.
   */

  if (currentExplanationId) {
    const formerStepPath = createPathFromParam(pathToLoad);
    const stepsPath = createPathFromParam(nextPathToLoad);
    const pathFromUrl = nextPathToLoad;

    const currentStepId = stepsPath[stepsPath.length - 1];

    const currentStep = explanation.steps.find(step => step.stepId === currentStepId);
    let newExplanationInfo = explanation.steps.find(step => step.stepId === stepsPath[stepsPath.length - 1]);

    /*
     * if the step has a type "explanation" it means that it should either load
     * the sub explanation of go back to the parent one (if the user clicks
     * on previous for instance).
     */

    if (currentStep && currentStep.type === STEP_TYPE.guide) {
      if (stepsPath.length < formerStepPath.length) {
        // making sure that the new loaded step has the right explanationId.
        if (currentExplanationId === newExplanationInfo.guideId) {
          // eslint-disable-next-line prefer-destructuring
          newExplanationInfo = explanation.steps.find(step => step.stepId === stepsPath[stepsPath.length - 2]);
        }

        return {
          change: true,
          newExplanationId: newExplanationInfo.guideId,
          newUrl: `${pathFromUrl.slice(0, Math.max(0, pathFromUrl.lastIndexOf(STEP_SEPARATOR)))}`,
        };
      }
      const newSubExplanationId = explanation.linkedExplanations.find(
        linkedExplanation => linkedExplanation.stepId === currentStepId
      ).guideId;

      if (newSubExplanationId !== currentExplanationId) {
        const { startId } = explanation.mainInfo.find(expInfo => expInfo.guideId === newSubExplanationId);

        return {
          change: true,
          newExplanationId: newSubExplanationId,
          newUrl: `${nextPathToLoad}${STEP_SEPARATOR}${startId}`,
        };
      }

      if (
        !currentStep &&
        currentStepId !== -2 &&
        newExplanationInfo &&
        newExplanationInfo.guideId !== currentExplanationId
      ) {
        return {
          change: true,
          newExplanationId: newExplanationInfo.guideId,
          newUrl: '',
        };
      }
    } else if (newExplanationInfo && newExplanationInfo.guideId !== currentExplanationId) {
      /*
       * for any regular step chosen, just making sure that the right
       * sub explanation is selected.
       */
      return {
        change: true,
        newExplanationId: newExplanationInfo.guideId,
        newUrl: '',
      };
    }
  }
  return {
    change: false,
  };
};

export const getNewLanguageUrl = (language, match, title = '') => {
  const Slugify = slugify.fromLocale('en');
  return generatePath(match.path, { ...match.params, language, slug: `${Slugify.parse(title)}-` });
};

export const forceEditPanelUpdate = () => {
  window.dispatchEvent(new Event('force-update-edit-panel'));
};
