interface Finder {
  getIsItemIdMatchingPhrase: (itemId?: string, phrase?: string) => boolean;
  findItemIdListByPhrase: (phrase?: string) => string[];
}

interface SearchableItemData {
  [key: string]: string;
}

/**
 * A "db" like structure to be filled with itemList - that will expose methods to search items by string.
 * @mateusz - add worker if needed.
 * @mateusz - check memory management on larger structures
 */
export class StringFinder<ItemType> implements Finder {
  private cachedItemSearchableDataById: { [key: string]: SearchableItemData };

  private cachedCurrentPhrase = '';

  private cachedRegexpForCurrentPhrase: RegExp;

  private cachedResultsForCurrentPhrase: Map<string, boolean>;

  constructor({
    itemList,
    resolveItemId,
    resolveItemSearchableData,
  }: {
    itemList: ItemType[];
    resolveItemId: (item: ItemType) => string;
    resolveItemSearchableData: (item: ItemType) => { [key: string]: string };
  }) {
    this.cachedItemSearchableDataById = Object.fromEntries(
      itemList.map(item => [resolveItemId(item), resolveItemSearchableData(item)])
    );

    this.cachedResultsForCurrentPhrase = new Map();
    this.cachedRegexpForCurrentPhrase = new RegExp('.');
  }

  /** Converts search phrase for the matching regexp with the assumptions:
   * - if the phrase is e.g. `a+` we want to search for `a+` and not one or more `a` char. So escape chars are added
   * - we cannot put e.g. `test\` in new RegExp() because it would blow it up.
   */
  private createRegExpForPhrase = (phrase: string) => {
    const processedPhrase = phrase.replaceAll(/[$()*+.?[\\\]^{|}]/g, '\\$&'); // $& means whole matched string

    return new RegExp(processedPhrase, 'i'); // eslint-disable-line security/detect-non-literal-regexp
  };

  /** check if any field in data object is matching regexp */
  private isDataItemMatchingRegExp = (regExp: RegExp, itemSearchableData?: SearchableItemData) => {
    if (!itemSearchableData) {
      return false;
    }
    return Object.values(itemSearchableData).some(dataField => regExp.test(dataField));
  };

  private ensureCacheConfigSetup(phrase: string) {
    if (this.cachedCurrentPhrase !== phrase) {
      this.cachedCurrentPhrase = phrase;
      this.cachedRegexpForCurrentPhrase = this.createRegExpForPhrase(phrase);
      this.cachedResultsForCurrentPhrase = new Map();
    }
    // do nothing otherwise
  }

  /**
   * Check presence of the phrase in item by id
   */
  public getIsItemIdMatchingPhrase = (id?: string, phrase?: string) => {
    if (!id || !phrase) {
      return false;
    }

    this.ensureCacheConfigSetup(phrase);

    // read result from cache if item id was already checked for the phrase
    if (this.cachedResultsForCurrentPhrase.has(id)) {
      return this.cachedResultsForCurrentPhrase.get(id) as boolean; // typescript was saying that it may be undefined
    }

    const itemSearchableData = this.cachedItemSearchableDataById[id];
    const isMatching = this.isDataItemMatchingRegExp(this.cachedRegexpForCurrentPhrase, itemSearchableData);
    this.cachedResultsForCurrentPhrase.set(id, isMatching);

    return isMatching;
  };

  /**
   * Returns list (array) of ids of elements that contains the string
   */
  public findItemIdListByPhrase = (phrase?: string) => {
    if (!phrase) {
      return [];
    }
    const searchingRegexp = this.createRegExpForPhrase(phrase);

    return Object.entries(this.cachedItemSearchableDataById)
      .filter(([_, element]) => this.isDataItemMatchingRegExp(searchingRegexp, element))
      .map(([key]) => key);
  };
}
