import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import IconIllustration from '@playerCommon/CustomElements/IconIllustration';
import VideoPlayer from '@playerCommon/CustomElements/VideoPlayer';

import Code from '../../components/Code/Code.jsx';
import Image from '../../components/IllustrationImage';
import None from '../../components/None/None.jsx';

const Canvas = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-right: none;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity 0.2s, transform 0.4s, visibility 0.4s;

  ${({ illustrationType }) =>
    illustrationType === 'image' &&
    css`
      &:before {
        content: '';
        display: block;
        padding-top: initial;
      }
    `}

  ${({ positionDiff }) =>
    positionDiff < 0 &&
    css`
      transform: translate3d(0, -75%, 0);
    `}
  ${({ positionDiff }) =>
    positionDiff > 0 &&
    css`
      transform: translate3d(0, 75%, 0);
    `}
  ${({ positionDiff }) =>
    positionDiff === 0 &&
    css`
      opacity: 1;
      visibility: visible;
      pointer-events: initial;
      transform: translate3d(0, 0%, 0);
    `}
  ${({ positionDiff }) =>
    Math.abs(positionDiff) > 1 &&
    css`
      display: none;
    `}
`;

const IconIllustrationPositioned = styled(IconIllustration)`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  .illustration-icon-wrap {
    background: transparent !important;
  }
`;

const VideoPlayerWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.paleGrey};
  width: 100%;
  height: 100%;
  padding: 40px;
`;

const VideoPlayerStyled = styled(VideoPlayer)`
  @media screen and (max-width: 899px) {
    padding: 0;
    height: 100%;
  }

  ${({ mode }) =>
    (mode === 'embed' || mode === 'borderlessembed' || mode === 'widget' || mode === 'hc-widget') &&
    css`
      @media screen and (min-width: 480px) and (max-width: 899px) {
        width: 480px;
        margin: 0 auto;
      }
    `}
  ${({ mode }) =>
    mode === 'normal' &&
    css`
      @media screen and (min-width: 565px) and (max-width: 899px) {
        width: 565px;
        margin: 0 auto;
      }
    `}
`;

function Illustration({
  stepIllustrations,
  illustrationType,
  mode,
  encrypted,
  currentStepId,
  scrollable,
  stepId,
  scrollableSteps,
  loadImagesFromRoot,
}) {
  const isImage = illustrationType === 'image';
  const isCode = illustrationType === 'code';
  const isIcon = illustrationType === 'icon';
  const isVideo = illustrationType === 'video';
  const isNone = illustrationType === 'none';

  const isAdded = stepIllustrations.length === 1 && stepIllustrations[0].added; // @Michał I don't get this logic
  const isRemoved = stepIllustrations.length === 1 && stepIllustrations[0].removed;
  const isModified = stepIllustrations.length === 1 && stepIllustrations[0].modified;

  const imageIndex = scrollableSteps.indexOf(stepId);
  const currentStepIndex = scrollableSteps.indexOf(currentStepId);
  const positionDiff = imageIndex - currentStepIndex;

  return (
    <Canvas mode={mode} illustrationType={illustrationType} scrollable={scrollable} positionDiff={positionDiff}>
      {isNone && <None />}
      {isImage && stepIllustrations.length > 0 && (
        <Image
          stepIllustrations={stepIllustrations}
          added={isAdded}
          removed={isRemoved}
          modified={isModified}
          encrypted={encrypted}
          loadImagesFromRoot={loadImagesFromRoot}
          mode={mode}
        />
      )}
      {isCode && Math.abs(positionDiff) < 2 && (
        <Code
          stepIllustration={stepIllustrations[0]}
          added={isAdded}
          removed={isRemoved}
          modified={isModified}
          showGutterBg={currentStepId === stepId}
        />
      )}
      {isIcon && (
        <IconIllustrationPositioned
          {...stepIllustrations[0]}
          added={isAdded}
          removed={isRemoved}
          modified={isModified}
        />
      )}
      {isVideo && (
        <VideoPlayerWrap>
          <VideoPlayerStyled
            mode={mode}
            {...stepIllustrations[0]}
            autoplay={stepIllustrations[0]?.autoplay && !scrollable}
            added={isAdded}
            removed={isRemoved}
            modified={isModified}
          />
        </VideoPlayerWrap>
      )}
    </Canvas>
  );
}

Illustration.propTypes = {
  stepIllustrations: PropTypes.array,
  illustrationType: PropTypes.string,
  currentStepId: PropTypes.number,
  stepId: PropTypes.number,
  mode: PropTypes.string,
  encrypted: PropTypes.bool,
  scrollable: PropTypes.bool,
  scrollableSteps: PropTypes.array,
  loadImagesFromRoot: PropTypes.bool,
};

export default Illustration;
