import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import CloseButton from '@playerCommon/CustomElements/CloseButton/CloseButton';
import usePopup from '@playerCommon/hooks/usePopup';
import PopupContent from './PopupContent';
import PopupContainer from './PopupContainer';

const CustomContent = styled.div`
  padding: 0;
`;

const Popup = ({ onPopupExit }) => {
  const { popup, popupOpen, closePopup } = usePopup();
  const {
    text,
    title,
    titleIcon,
    confirmFunction,
    dismissFunction,
    confirmButtonText,
    confirmButtonColor,
    dismissButtonText,
    closeButtonFunction,
    hideDismiss,
    hideClose,
    customContent,
    style,
  } = popup;

  useEffect(() => {
    const handleKeyPress = e => {
      if (e.key === 'Escape') {
        if (hideClose) {
          return;
        }
        if (closeButtonFunction) {
          closeButtonFunction();
          return;
        }
        closePopup();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <PopupContainer onExit={onPopupExit} open={popupOpen} popupBodyStyle={style}>
      {!hideClose && <CloseButton dataCy="closeButton" placement={32} onClick={closeButtonFunction || closePopup} />}
      <PopupContent>
        {(title || text) && (
          <PopupContent.Body noBottomPadding={title && !text}>
            {title && (
              <PopupContent.Title>
                {titleIcon && <PopupContent.TitleIcon>{titleIcon}</PopupContent.TitleIcon>}
                {title}
              </PopupContent.Title>
            )}
            {text && (
              <PopupContent.Text noTopMargin={hideClose} data-cy="popupContent">
                {text}
              </PopupContent.Text>
            )}
          </PopupContent.Body>
        )}
        {customContent && <CustomContent>{customContent}</CustomContent>}
        {(confirmButtonText || dismissButtonText) && (
          <PopupContent.Actions>
            {!hideDismiss && dismissButtonText && (
              <PopupContent.CancelButton dataCy="cancelButton" onClick={dismissFunction}>
                {dismissButtonText}
              </PopupContent.CancelButton>
            )}
            {confirmButtonText && (
              <PopupContent.ConfirmButton
                dataCy="confirmationButton"
                onClick={confirmFunction}
                content={confirmButtonText}
                confirmButtonColor={confirmButtonColor}
              />
            )}
          </PopupContent.Actions>
        )}
      </PopupContent>
    </PopupContainer>
  );
};

Popup.propTypes = {
  onPopupExit: PropTypes.func,
};

export default Popup;
