import { getGlobal, setCookie } from 'global/windowUtils';
import i18n, { DEFAULT_PAGE_LANGUAGE, extendedLanguagesCodesList } from './i18n';

export const supportedLanguages = [
  { name: i18n('Languages.English'), value: 'en', code: 'en-US', canAutoTranslate: true },
  { name: i18n('Languages.German'), value: 'de', code: 'de-DE', canAutoTranslate: true },
  { name: i18n('Languages.Chinese'), value: 'zh', code: 'zh-CN', canAutoTranslate: true },
  { name: i18n('Languages.Japanese'), value: 'ja', code: 'ja-JP', canAutoTranslate: true },
  { name: i18n('Languages.Korean'), value: 'ko', code: 'ko-KR', canAutoTranslate: true },
  { name: i18n('Languages.Dutch'), value: 'nl', code: 'nl-NL', canAutoTranslate: true },
  { name: i18n('Languages.French'), value: 'fr', code: 'fr-FR', canAutoTranslate: true },
  { name: i18n('Languages.Spanish'), value: 'es', code: 'es-ES', canAutoTranslate: true },
  { name: i18n('Languages.Catalan'), value: 'ca', code: 'ca-ES', canAutoTranslate: true },
  { name: i18n('Languages.Italian'), value: 'it', code: 'it-IT', canAutoTranslate: true },
  { name: i18n('Languages.Indonesian'), value: 'id', code: 'id-ID', canAutoTranslate: true },
  { name: i18n('Languages.Portuguese'), value: 'pt', code: 'pt-PT', canAutoTranslate: true },
  { name: i18n('Languages.Norwegian'), value: 'no', code: 'no', canAutoTranslate: true },
  { name: i18n('Languages.NorwegianNynorsk'), value: 'nn', code: 'nn-NO', canAutoTranslate: true },
  { name: i18n('Languages.NorwegianBokmal'), value: 'nb', code: 'nb-NO', canAutoTranslate: true },
  { name: i18n('Languages.Finnish'), value: 'fi', code: 'fi-FI', canAutoTranslate: true },
  { name: i18n('Languages.Swedish'), value: 'sv', code: 'sv-SE', canAutoTranslate: true },
  { name: i18n('Languages.Danish'), value: 'da', code: 'da-DK', canAutoTranslate: true },
  { name: i18n('Languages.Czech'), value: 'cs', code: 'cs-CZ', canAutoTranslate: true },
  { name: i18n('Languages.Hungarian'), value: 'hu', code: 'hu-HU', canAutoTranslate: true },
  { name: i18n('Languages.Romanian'), value: 'ro', code: 'ro-RO', canAutoTranslate: true },
  { name: i18n('Languages.Polish'), value: 'pl', code: 'pl-PL', canAutoTranslate: true },
  { name: i18n('Languages.Greek'), value: 'el', code: 'el-GR', canAutoTranslate: true },
  { name: i18n('Languages.Russian'), value: 'ru', code: 'ru-RU', canAutoTranslate: true },
  { name: i18n('Languages.Turkish'), value: 'tr', code: 'tr-TR', canAutoTranslate: true },
  { name: i18n('Languages.Bulgarian'), value: 'bg', code: 'bg-BG', canAutoTranslate: true },
  { name: i18n('Languages.Latvian'), value: 'lv', code: 'lv-LV', canAutoTranslate: true },
  { name: i18n('Languages.Ukrainian'), value: 'uk', code: 'uk-UA', canAutoTranslate: true },
  { name: i18n('Languages.Thai'), value: 'th', code: 'th-TH', canAutoTranslate: true },
  { name: i18n('Languages.Estonian'), value: 'et', code: 'et-EE', canAutoTranslate: true },
  { name: i18n('Languages.Croatian'), value: 'hr', code: 'hr-HR', canAutoTranslate: true },
  { name: i18n('Languages.Lithuanian'), value: 'lt', code: 'lt-LT', canAutoTranslate: true },
  { name: i18n('Languages.Slovak'), value: 'sk', code: 'sk-SK', canAutoTranslate: true },
  { name: i18n('Languages.Slovene'), value: 'sl', code: 'sl-SI', canAutoTranslate: true },
  { name: i18n('Languages.Vietnamese'), value: 'vi', code: 'vi-VN', canAutoTranslate: true },
  { name: i18n('Languages.Icelandic'), value: 'is', code: 'is-IS', canAutoTranslate: true },
  { name: i18n('Languages.Arabic'), value: 'ar', code: 'ar-AE', canAutoTranslate: true },
  { name: i18n('Languages.Farsi'), value: 'fa', code: 'fa-IR', canAutoTranslate: true },
  { name: i18n('Languages.Hebrew'), value: 'he', code: 'he-HE', canAutoTranslate: true },
  { name: i18n('Languages.Urdu'), value: 'ur', code: 'ur-PK', canAutoTranslate: true },
  { name: i18n('Languages.Armenian'), value: 'hy', code: 'hy-AM', canAutoTranslate: true },
  { name: i18n('Languages.Bosnian'), value: 'bs', code: 'bs-BA', canAutoTranslate: true },
  { name: i18n('Languages.Filipino'), value: 'tl', code: 'tl-PH', canAutoTranslate: true },
  { name: i18n('Languages.Amharic'), value: 'am', code: 'am-ET', canAutoTranslate: true },
  { name: i18n('Languages.Māori'), value: 'mi', code: 'mi-NZ', canAutoTranslate: true },
  { name: i18n('Languages.Welsh'), value: 'cy', code: 'cy-GB', canAutoTranslate: true },
  { name: i18n('Languages.Bengali'), value: 'bn', code: 'bn-BD', canAutoTranslate: true },
  { name: i18n('Languages.Swahili'), value: 'sw', code: 'sw-TZ', canAutoTranslate: true },
  { name: i18n('Languages.Montenegrin'), value: 'cn', code: 'cn-ME', canAutoTranslate: false },
  { name: i18n('Languages.Serbian'), value: 'sr', code: 'sr-RS', canAutoTranslate: true },
  { name: i18n('Languages.Albanian'), value: 'sq', code: 'sq-AL', canAutoTranslate: true },
  { name: i18n('Languages.Irish'), value: 'ga', code: 'ga-IE', canAutoTranslate: true },
  { name: i18n('Languages.ScottishGaelic'), value: 'gd', code: 'gd-GB', canAutoTranslate: true },
  { name: i18n('Languages.Macedonian'), value: 'mk', code: 'mk-MK', canAutoTranslate: true },
  { name: i18n('Languages.FrenchBE'), value: 'fr-BE', code: 'fr-BE', canAutoTranslate: true },
  { name: i18n('Languages.FrenchCA'), value: 'fr-CA', code: 'fr-CA', canAutoTranslate: true },
  { name: i18n('Languages.ChineseTraditional'), value: 'zh-Hant', code: 'zh-Hant', canAutoTranslate: true },
  { name: i18n('Languages.EnglishGB'), value: 'en-GB', code: 'en-GB', canAutoTranslate: true },
  { name: i18n('Languages.EnglishUS'), value: 'en-US', code: 'en-US', canAutoTranslate: true },
  { name: i18n('Languages.Basque'), value: 'eu', code: 'eu-ES', canAutoTranslate: true },
  { name: i18n('Languages.PortugueseBR'), value: 'pt-BR', code: 'pt-BR', canAutoTranslate: true },
  { name: i18n('Languages.GermanAT'), value: 'de-AT', code: 'de-AT', canAutoTranslate: true },
  { name: i18n('Languages.Hindi'), value: 'hi', code: 'hi-IN', canAutoTranslate: true },
  { name: i18n('Languages.Kannada'), value: 'kn', code: 'kn-IN', canAutoTranslate: true },
];

export const supportedLanguagesOptions = supportedLanguages
  .sort((a, b) => a.name.localeCompare(b.name))
  .map(el => ({
    id: el.value,
    label: el.name,
    value: el.value,
  }));

export function setLanguage(language, save = false) {
  const foundLang = supportedLanguages.find(el => el.value === language);
  const i18next = getGlobal('i18n');
  if (save) {
    setCookie('language', language);
    setCookie('languageList', foundLang?.code || DEFAULT_PAGE_LANGUAGE);
    i18next.changeLanguage(foundLang?.code || DEFAULT_PAGE_LANGUAGE);
    window.siteLanguage = undefined;
  } else {
    i18next.changeLanguage(foundLang?.code || DEFAULT_PAGE_LANGUAGE);
    window.siteLanguage = language;
  }
}

export const getLanguageFlagName = language => {
  if (!language) {
    return '';
  }
  if (extendedLanguagesCodesList.has(language)) {
    return language;
  }
  return language.toUpperCase();
};

export const getLanguageShorthandName = language => {
  if (extendedLanguagesCodesList.has(language)) {
    return language.split('-')[1];
  }
  return language;
};
