import i18n from 'helpers/i18n';

export function getUserTeamFromId(user, teamId) {
  let teamToShow;
  if (Number(teamId) === -1 || ((Number(teamId) === null || !teamId) && teamId !== 0)) {
    teamToShow = { name: i18n('Console.AllExplanations'), teamId: -1 };
  } else if (Number(teamId) === 0) {
    teamToShow = { name: i18n('Console.PersonalTeamLabel'), teamId: 0 };
  } else {
    teamToShow = user.teams.filter(team => team.teamId === Number(teamId))[0];
  }
  return teamToShow;
}

export function isCorrectTeamId(teamId) {
  return Boolean(teamId && !Number.isNaN(+teamId) && ![-1, 0].includes(+teamId));
}

export default getUserTeamFromId;
