import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import CloseButton from '@playerCommon/CustomElements/CloseButton/CloseButton';
import { isMobileSafari } from 'helpers/userAgentHelpers';
import { getImageUrl } from 'helpers/fileHelpers';
import { useSwipeable } from 'react-swipeable';
import useSetIsImageFullscreen from '@playerCommon/hooks/useSetIsImageFullscreen';
import ImageGallery from '../ImageGallery/ImageGallery.jsx';
import SingleImage from './SingleImage';

const Canvas = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 225;
  left: 0;
  top: 0;
  overflow-x: hidden;
  transition: opacity 0.2s, visibility 0.2s;
  will-change: visibility, opacity;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  ${({ show }) =>
    show &&
    css`
      visibility: visible;
      opacity: 1;
    `};

  ${isMobileSafari() &&
  css`
    align-items: flex-start;
    padding-top: 85px;
  `}
`;

const FullScreenLayer = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: ${props => props.theme.paleGrey};
`;

const StyledCloseButton = styled(CloseButton)`
  div[role='button'] {
    z-index: 10;
  }
`;

const FullscreenImage = ({
  stepIllustrations = [],
  currentImage,
  show,
  closeFullscreen,
  encrypted,
  loadImagesFromRoot,
  showPreviousImage,
  showNextImage,
  setCurrentImage,
  portalContainerEl,
}) => {
  const isGallery = () => stepIllustrations.length > 1;

  const swipeHandles = useSwipeable({ onSwipedLeft: showNextImage, onSwipedRight: showPreviousImage });

  function keydownEscapeEventListener(event) {
    if (event.keyCode === 27 && show) {
      closeFullscreen();
    }
  }

  function keydownGalleryEventListener(event) {
    const hasGallery = isGallery();
    if (!show || !hasGallery) return;

    if (event.keyCode === 37) {
      showPreviousImage();
    } else if (event.keyCode === 39) {
      showNextImage();
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', keydownGalleryEventListener);
    document.addEventListener('keydown', keydownEscapeEventListener);

    return () => {
      document.removeEventListener('keydown', keydownGalleryEventListener);
      document.removeEventListener('keydown', keydownEscapeEventListener);
    };
  }, [show]);

  useSetIsImageFullscreen({ isImageFullscreen: show });

  if (typeof document === 'undefined') {
    return null;
  }

  return createPortal(
    <Canvas show={show} {...swipeHandles}>
      <FullScreenLayer onClick={closeFullscreen} />
      {show && (
        <>
          {isGallery ? (
            <>
              {stepIllustrations.map((si, index) => (
                <SingleImage
                  key={getImageUrl(si)}
                  url={getImageUrl(si)}
                  onClick={closeFullscreen}
                  encrypted={encrypted}
                  positionDiff={index - currentImage}
                  loadImagesFromRoot={loadImagesFromRoot}
                />
              ))}
            </>
          ) : (
            <SingleImage
              url={getImageUrl(stepIllustrations[0])}
              onClick={closeFullscreen}
              encrypted={encrypted}
              positionDiff={0}
              loadImagesFromRoot={loadImagesFromRoot}
            />
          )}
        </>
      )}
      <StyledCloseButton onClick={closeFullscreen} size="big" />
      {isGallery() && (
        <ImageGallery
          show
          stepIllustrations={stepIllustrations}
          currentImage={currentImage}
          changeCurrentImage={setCurrentImage}
          fullscreenMode
          encrypted={encrypted}
          loadImagesFromRoot={loadImagesFromRoot}
        />
      )}
    </Canvas>,
    portalContainerEl || document.body
  );
};

FullscreenImage.propTypes = {
  show: PropTypes.bool,
  closeFullscreen: PropTypes.func,
  encrypted: PropTypes.bool,
  stepIllustrations: PropTypes.array,
  currentImage: PropTypes.number,
  loadImagesFromRoot: PropTypes.bool,
  setCurrentImage: PropTypes.func,
  showNextImage: PropTypes.func,
  showPreviousImage: PropTypes.func,
  portalContainerEl: PropTypes.object,
};

export default FullscreenImage;
