import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import styled, { css } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import useReducedMotion from '@playerCommon/hooks/useReducedMotion';
import withUser from '@playerCommon/HOC/withUser/withUser.js';
import Avatar from '@playerCommon/CustomElements/Avatar/Avatar.jsx';
import useAuthenticationFlow from '@playerCommon/Authentication/useAuthenticationFlow';
import { useTranslation } from 'react-i18next';
import { THEME_COLORS } from 'global';
import ToggleArrow from './components/ToggleArrow/ToggleArrow.jsx';
import UserOptions from './UserOptions/UserOptions.js';
import MobileUserOptions from './MobileUserOptions/MobileUserOptions.js';

const DropdownCanvas = styled(motion.div)`
  position: absolute;
  width: 320px;
  right: 0;
  top: 24px;
  background: ${props => props.theme.white};
  z-index: 100;
  color: #5e5e5e;
  border-radius: 1px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08), 0 6px 24px 0 rgba(0, 0, 0, 0.16);

  ${({ mode }) =>
    mode === 'home' &&
    css`
      @media screen and (max-width: 1023px) {
        display: none;
      }
    `}
`;

const Canvas = styled.div`
  display: flex;
  align-items: center;
`;

const OptionsWrapper = styled.div`
  position: relative;
`;

const FirstNLastName = styled.span`
  margin-right: 4px;
  margin-left: 16px;

  ${({ mode }) =>
    mode === 'guide' &&
    css`
      @media screen and (max-width: 640px) {
        display: none;
      }
    `}
`;

const Username = styled.div`
  align-items: center;
  font-weight: 500;
  font-size: 1em;
  color: inherit;
  cursor: pointer;
  display: flex;

  ${({ mode }) =>
    mode === 'home' &&
    css`
      @media screen and (max-width: 1023px) {
        display: none;
      }
    `}

  ${({ mode }) =>
    mode === 'guide' &&
    css`
      @media screen and (max-width: 640px) {
        display: none;
      }
    `}
`;

const UserClickable = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 400;

  ${({ mode }) =>
    mode === 'home' &&
    css`
      @media screen and (min-width: 1024px) {
        display: none;
      }
    `}

  ${({ mode }) =>
    mode === 'guide' &&
    css`
      @media screen and (min-width: 641px) {
        display: none;
      }
    `}
`;

const LoginButton = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 1em;
  color: inherit;
  cursor: pointer;
`;

function LoginOrOutPlayer({ userManagement, mode = 'guide' }) {
  const { t } = useTranslation();
  const { onOpenSignIn } = useAuthenticationFlow();
  const [isDropdownOpen, setDropdownIsOpen] = useState(false);
  const reduceMotion = useReducedMotion();
  LoginOrOutPlayer.handleClickOutside = () => setDropdownIsOpen(false);

  const toggleDropdown = useCallback(() => setDropdownIsOpen(isOpen => !isOpen), []);

  const { user } = userManagement;

  const isConnected = Object.keys(user).length !== 0;

  return (
    <Canvas mode={mode} className="LoginOrOut">
      {isConnected && mode === 'home' && (
        <MobileUserOptions user={user} show={isDropdownOpen} toggleElementToDisplay={setDropdownIsOpen} />
      )}

      {isConnected ? (
        <OptionsWrapper className="LoginOrOutOptionsWrapper">
          <Username
            onClick={toggleDropdown}
            data-cy="firstAndLastName"
            data-stonly-trigger="usernameDropdown"
            mode={mode}
          >
            <FirstNLastName>{`${user.firstName} ${user.lastName}`}</FirstNLastName>
            <ToggleArrow color={THEME_COLORS.darkGray} isActive={isDropdownOpen} />
          </Username>
          <UserClickable onClick={toggleDropdown} mode={mode}>
            <Avatar
              src={user.picture}
              srcSecureHash={user.pictureSecureHash}
              type="user"
              size={mode === 'home' ? 40 : 30}
              targetName={`${user.firstName} ${user.lastName}`}
              numberForBackground={user.creationDate}
            />
            <FirstNLastName mode={mode}>{`${user.firstName} ${user.lastName}`}</FirstNLastName>
          </UserClickable>
          <AnimatePresence>
            {isDropdownOpen && (
              <DropdownCanvas
                key={isDropdownOpen}
                initial={reduceMotion ? { opacity: 0 } : { opacity: 0, y: -10 }}
                animate={reduceMotion ? { opacity: 1 } : { opacity: 1, y: 0 }}
                exit={reduceMotion ? { opacity: 0 } : { opacity: 0, y: -10 }}
                transition={{ duration: 0.2 }}
                mode={mode}
              >
                <UserOptions closeDropdown={() => setDropdownIsOpen(false)} user={user} />
              </DropdownCanvas>
            )}
          </AnimatePresence>
        </OptionsWrapper>
      ) : (
        <LoginButton data-cy="loginMainPage" onClick={onOpenSignIn} mode={mode}>
          {t('Header.SignIn')}
        </LoginButton>
      )}
    </Canvas>
  );
}

LoginOrOutPlayer.propTypes = {
  userManagement: PropTypes.object,
  mode: PropTypes.string,
};

const clickOutsideConfig = {
  handleClickOutside: () => LoginOrOutPlayer.handleClickOutside,
};

export default withUser(onClickOutside(LoginOrOutPlayer, clickOutsideConfig));
