import { getIds, getWidgetIdFromServerAppState } from '@stonlyCommons/helpers/statIdsManagement';
import callApi from '@stonlyCommons/helpers/apiHelpers';

const getCommonFields = async () => {
  const { stonlyAnonymousId, customerUserId } = (await getIds()) as {
    stonlyAnonymousId: string;
    customerUserId: string | null;
  };
  return {
    context: {
      library: 'stonly-guide',
    },
    widgetId: getWidgetIdFromServerAppState() as string,
    stonlyAnonymousId,
    identifyBy: customerUserId || undefined,
  };
};

export const identify = async (properties: { [key: string]: string }) => {
  const commonFields = await getCommonFields();
  return callApi('v1/targeting/identify', 'post', {
    ...commonFields,
    properties,
  });
};

export const track = async (event: string) => {
  const commonFields = await getCommonFields();
  return callApi('v1/targeting/track', 'post', {
    ...commonFields,
    event,
    date: new Date(),
  });
};
