import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Button from '@playerCommon/StandardElements/Button';
import { blendColors } from 'helpers/colorHelpers';
import { TextButton } from '@playerCommon/StandardElements/Buttons/TextButton';
import { SecondaryButton } from '@playerCommon/StandardElements/Buttons/SecondaryButton';

const Canvas = styled.div``;

const TitleIcon = styled.div`
  margin-right: 24px;
  line-height: 0;
  flex-shrink: 0;

  svg {
    display: block;
  }
`;

const Title = styled.h4`
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 400;
  color: ${props => props.theme.darkGrey};
  margin-top: 0;
  margin-bottom: 24px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px;
  height: 80px;
  border-bottom: 1px solid ${props => props.theme.pearl};

  ${Title} {
    margin-bottom: 0;
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 24px;
  border-top: 1px solid ${props => props.theme.pearl};
  ${({ spaceBetween }) =>
    spaceBetween &&
    css`
      justify-content: space-between;
    `}
`;

const CancelButton = styled(TextButton)`
  margin-right: 32px;
  font-size: 14px;
`;

const Text = styled.div`
  /* font-size: 16px; */
  line-height: 1.5;

  ${({ noTopMargin }) =>
    !noTopMargin &&
    css`
      &:first-child {
        margin-top: 24px;
      }
    `}
`;

const Body = styled.div`
  padding: 28px 32px 32px 32px;

  ${({ noBottomPadding }) =>
    noBottomPadding &&
    css`
      padding-bottom: 0;
    `}
`;

const ConfirmButton = styled(Button)`
  ${({ confirmButtonColor, theme }) =>
    confirmButtonColor &&
    css`
      button {
        background-color: ${confirmButtonColor};

        &:hover {
          background-color: ${blendColors(confirmButtonColor, theme.white, 0.2)};
        }
      }
    `}
`;

const PopupContent = ({ children, className }) => <Canvas className={className}>{children}</Canvas>;

PopupContent.Text = Text;
PopupContent.Body = Body;
PopupContent.Header = Header;
PopupContent.Title = Title;
PopupContent.TitleIcon = TitleIcon;
PopupContent.Actions = Actions;
PopupContent.CancelButton = CancelButton;
PopupContent.ConfirmButton = ConfirmButton;
PopupContent.SecondaryButton = SecondaryButton;

PopupContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element), PropTypes.node]),
  className: PropTypes.string,
};

export default PopupContent;
