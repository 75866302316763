import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { getParsedCookie, getGlobal } from 'global/windowUtils';
import { appUrl, playerUrl } from 'global/env.js';
import { useTranslation } from 'react-i18next';
import useRefWidth from '@playerCommon/hooks/useRefWidth';
import { getNewFunnelId } from '@playerCommon/helpers/topOfFunnelManagement';
import { posthogCapture } from 'components/Analytics/recorder';
import Padlock from 'resources/icons/padlock.svg';
import GoogleIcon from 'resources/icons/google.svg';
import { POSTHOG_EVENT_NAME, FUNNEL_STEP } from '@stonlyCommons/constants/Authentication.consts';
import { AUTHENTICATION_TITLE_ID, AUTHENTICATION_VIEWS, FUNNEL_FLOW_TYPE } from '../Authentication.consts';
import GoogleSignIn from '../GoogleAuth/GoogleSignIn.jsx';
import SignInBasic from './SignInBasic.jsx';
import { Canvas, Top, Title, Separator, SeparatorText } from '../Authentication.styles.js';

const buttonStyle = css`
  width: 100%;
  background-color: white;
  border: 1px solid #dadce0;
  color: #3c4043;
  font-size: 14px;
  font-weight: 500;
  height: 38px;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(211, 213, 218, 0.1);
  }
`;

const StyledButton = styled.div`
  ${buttonStyle}
  margin-top: 32px;
`;

const StyledButtonGoogle = styled.div`
  ${buttonStyle}
`;

const StyledButtonAsLink = styled(Link)`
  ${buttonStyle}
`;

const PadlockSVG = styled(Padlock)`
  width: 18px;
  height: 18px;
  margin-right: 8px;
`;

const GoogleSVG = styled(GoogleIcon)`
  width: 18px;
  height: 18px;
  margin-right: 8px;
`;

const SignIn = ({ handlePostAuthentication, setAuthenticationView, loginEmail }) => {
  const { t } = useTranslation();
  const canvasRef = useRef(null);
  const [SSOWrapWidth] = useRefWidth(canvasRef);
  const [funnelId, setFunnelId] = useState();
  // google authorization is only available on stonly domain
  const isStonlyHost = getGlobal('host') === appUrl || playerUrl;
  const { mode } = useParams();
  const isWidget = mode === 'widget';

  const getAuthenticationUrl = () => {
    const parsedCookie = getParsedCookie();
    return parsedCookie.sso ? `${playerUrl}/authentication/signInWithSSO` : `${playerUrl}/authentication`;
  };

  const onSignInWithSSOClick = () => {
    setAuthenticationView(AUTHENTICATION_VIEWS.SIGN_IN_WITH_SSO);
    posthogCapture(POSTHOG_EVENT_NAME.SIGN_IN_FUNNEL, {
      funnelId,
      step: FUNNEL_STEP.CLICK_SIGN_IN_SSO,
    });
  };

  useEffect(() => {
    const newFunnelId = getNewFunnelId();
    setFunnelId(newFunnelId);
    posthogCapture(POSTHOG_EVENT_NAME.SIGN_IN_FUNNEL, {
      funnelId: newFunnelId,
      flow: FUNNEL_FLOW_TYPE.CLASSIC,
      step: FUNNEL_STEP.START,
    });
  }, []);

  const canvasContentWidth = useMemo(() => {
    let canvasPadding = 0;
    if (canvasRef.current) {
      const canvasStyle = window.getComputedStyle(canvasRef.current);
      canvasPadding = Number.parseFloat(canvasStyle.paddingLeft) + Number.parseFloat(canvasStyle.paddingRight);
    }
    const newValue = SSOWrapWidth - canvasPadding;
    return Math.min(400, newValue);
  }, [canvasRef, SSOWrapWidth]);

  const getGoogleButton = () =>
    isWidget ? (
      <StyledButtonGoogle onClick={() => window.open(getAuthenticationUrl(), '_blank')}>
        <GoogleSVG />
        {t('SignInSSO.GoogleLoginButton')}
      </StyledButtonGoogle>
    ) : (
      <GoogleSignIn
        containerWidth={canvasContentWidth}
        funnelId={funnelId}
        handlePostAuthentication={handlePostAuthentication}
      />
    );

  const getSSOButton = () =>
    isWidget ? (
      <StyledButton
        onClick={() => {
          window.open('/authentication/signInWithSSO', '_blank');
          onSignInWithSSOClick();
        }}
      >
        <PadlockSVG />
        {t('SignInSSO.LoginPanelButton')}
      </StyledButton>
    ) : (
      <StyledButtonAsLink to="/authentication/signInWithSSO" onClick={onSignInWithSSOClick}>
        <PadlockSVG />
        {t('SignInSSO.LoginPanelButton')}
      </StyledButtonAsLink>
    );

  return (
    <>
      <Canvas>
        <Top>
          <Title id={AUTHENTICATION_TITLE_ID}>{t('SignIn.Title')}</Title>
        </Top>
        <SignInBasic
          handlePostAuthentication={handlePostAuthentication}
          setAuthenticationView={setAuthenticationView}
          loginEmail={loginEmail}
          funnelId={funnelId}
        />
      </Canvas>
      <Separator>
        <SeparatorText>{t('SignInSSO.Or')}</SeparatorText>
      </Separator>
      <Canvas ref={canvasRef}>
        {isStonlyHost && getGoogleButton()}
        {getSSOButton()}
      </Canvas>
    </>
  );
};

SignIn.propTypes = {
  setAuthenticationView: PropTypes.func,
  handlePostAuthentication: PropTypes.func,
  loginEmail: PropTypes.string,
};

export default SignIn;
