import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CONTACT_FORM_STEPS } from 'global';
import Intro from './Intro';
import SuggestedSolutions from './SuggestedSolutions';
import Form from './Form';
import SuccessMessage from './SuccessMessage';
import { Canvas } from './ContactForm.styles';

function ContactForm({
  content,
  stepInputs,
  contactFormInfo,
  inKnowledgeBase,
  previousUrlToLoad,
  contactFormTitle,
  isPreview,
  getVisitedStepsData,
  nextSteps,
  onSingleNextStepClick,
  internalNotes,
  onBackLinkClick,
  isFirstStep,
  setIsContactFromSuccessMessageDisplayed,
}) {
  const { properties, content: contactFormContent } = contactFormInfo;
  const suggestionsEnabled = !!(properties.guidesSuggestion && inKnowledgeBase);

  const { INTRO, SUGGESTED_SOLUTIONS, CONTACT_FORM, SUCCESS_MESSAGE } = CONTACT_FORM_STEPS;
  const [step, setStep] = useState(suggestionsEnabled ? INTRO : CONTACT_FORM);
  const [question, setQuestion] = useState('');

  const handleBackClick = useCallback(
    e => {
      if (suggestionsEnabled && step === CONTACT_FORM) {
        e.preventDefault();
        setStep(INTRO);
      } else if (typeof onBackLinkClick === 'function') {
        onBackLinkClick(e);
      }
    },
    [step, suggestionsEnabled, onBackLinkClick]
  );

  useEffect(() => {
    if (step === SUCCESS_MESSAGE) {
      setIsContactFromSuccessMessageDisplayed(true);
    } else {
      setIsContactFromSuccessMessageDisplayed(false);
    }

    return () => {
      setIsContactFromSuccessMessageDisplayed(false);
    };
  }, [step]);

  return (
    <Canvas>
      {step === INTRO && (
        <Intro
          suggestionsEnabled={suggestionsEnabled}
          setStep={setStep}
          question={question}
          setSearchQuery={setQuestion}
          shouldShowBackButton={!isFirstStep}
          onBackClick={handleBackClick}
          previousUrlToLoad={previousUrlToLoad}
        />
      )}
      {step === SUGGESTED_SOLUTIONS && <SuggestedSolutions setStep={setStep} searchQuery={question} />}
      {step === CONTACT_FORM && (
        <Form
          setStep={setStep}
          question={question}
          onBackClick={handleBackClick}
          previousUrlToLoad={previousUrlToLoad}
          contactFormInfo={contactFormInfo}
          contactFormTitle={contactFormTitle}
          isPreview={isPreview}
          getVisitedStepsData={getVisitedStepsData}
          stepInputs={stepInputs}
          content={content}
          contactFormContent={contactFormContent}
          onSingleNextStepClick={onSingleNextStepClick}
          internalNotes={internalNotes}
          firstConnection={nextSteps[0]}
          shouldShowBackButton={!isFirstStep || suggestionsEnabled}
        />
      )}
      {step === SUCCESS_MESSAGE && (
        <SuccessMessage
          contactFormContent={contactFormContent}
          shouldShowBackButton={!isFirstStep}
          onBackClick={handleBackClick}
          previousUrlToLoad={previousUrlToLoad}
        />
      )}
    </Canvas>
  );
}

ContactForm.propTypes = {
  content: PropTypes.string,
  onSingleNextStepClick: PropTypes.func,
  previousUrlToLoad: PropTypes.string,
  contactFormInfo: PropTypes.object,
  inKnowledgeBase: PropTypes.bool,
  contactFormTitle: PropTypes.string,
  isPreview: PropTypes.bool,
  getVisitedStepsData: PropTypes.func,
  stepsInput: PropTypes.array,
  nextSteps: PropTypes.array,
  onBackLinkClick: PropTypes.func,
  isFirstStep: PropTypes.bool,
  setIsContactFromSuccessMessageDisplayed: PropTypes.func,
};

export default ContactForm;
