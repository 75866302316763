import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { imagesUrl } from 'global/env';
import { isMediaFileVideo, convertVideoMediaUrl } from 'helpers/fileHelpers';

const transitionCss = css`
  transition: opacity 0.4s, visibility 0.4s, transform 0.4s;
  opacity: 0;
  visibility: hidden;
`;

const PictureStep = styled.picture`
  max-width: 100%;
  max-height: 100%;
  outline: none;
  height: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImageStep = styled.img`
  max-height: 90%;
  max-width: 95%;
  align-self: center;
  z-index: 10;
  cursor: pointer;
  cursor: zoom-out;
  flex-shrink: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* @noflip */
  [dir='rtl'] & {
    transform: translate(50%, -50%);
  }

  @media screen and (max-width: 899px) {
    max-width: 100vw;
  }

  ${transitionCss};

  ${({ positionDiff }) =>
    positionDiff < 0 &&
    css`
      transform: translate3d(-150%, -50%, 0);
    `}
  ${({ positionDiff }) =>
    positionDiff > 0 &&
    css`
      transform: translate3d(50%, -50%, 0);
    `}
  ${({ positionDiff }) =>
    positionDiff === 0 &&
    css`
      opacity: 1;
      visibility: visible;
      transform: translate3d(-50%, -50%, 0);
      /* @noflip */
      [dir='rtl'] & {
        transform: translate3d(50%, -50%, 0);
      }
    `}
  ${({ positionDiff }) =>
    Math.abs(positionDiff) > 1 &&
    css`
      display: none;
    `}
`;

const StepVideo = styled.video`
  max-width: 100%;
  max-height: 100%;
  align-self: center;
  cursor: pointer;
  cursor: zoom-in;
  z-index: 10;
  flex-shrink: 0;
  position: absolute;
  top: 50%;
  left: 50%;

  &::-internal-media-controls-overlay-cast-button {
    display: none;
  }

  ${transitionCss};

  ${({ positionDiff }) =>
    positionDiff < 0 &&
    css`
      transform: translate3d(-150%, -50%, 0);
    `}
  ${({ positionDiff }) =>
    positionDiff > 0 &&
    css`
      transform: translate3d(50%, -50%, 0);
    `}
  ${({ positionDiff }) =>
    positionDiff === 0 &&
    css`
      opacity: 1;
      visibility: visible;
      transform: translate3d(-50%, -50%, 0);
      /* @noflip */
      [dir='rtl'] & {
        transform: translate3d(50%, -50%, 0);
      }
    `}
  ${({ positionDiff }) =>
    Math.abs(positionDiff) > 1 &&
    css`
      display: none;
    `}
`;

function SingleImage({ url, onClick, positionDiff, loadImagesFromRoot }) {
  const isVideo = isMediaFileVideo(url);

  const baseUrl = loadImagesFromRoot ? '' : imagesUrl.url;

  return (
    <>
      {isVideo ? (
        <StepVideo
          key={url}
          src={convertVideoMediaUrl(`${baseUrl}/${url}`)}
          tabIndex={0}
          onKeyDown={onClick}
          onClick={onClick}
          controls={false}
          playsInline
          autoPlay
          muted
          loop
          positionDiff={positionDiff}
        />
      ) : (
        <PictureStep key={url}>
          <source media="(max-width: 480px)" srcSet={`${baseUrl}/${url}&w=480&h=960&auto=format&dpr=2`} />
          <source media="(max-width: 768px)" srcSet={`${baseUrl}/${url}&w=768&h=1536&auto=format&dpr=2`} />
          <source media="(max-width: 899px)" srcSet={`${baseUrl}/${url}&w=900&h=1800&auto=format&dpr=1`} />
          <source media="(min-width: 900px)" srcSet={`${baseUrl}/${url}&w=2560&h=1440&auto=format&dpr=1`} />
          <ImageStep
            onKeyDown={onClick}
            tabIndex={0}
            onClick={onClick}
            src={`${baseUrl}/${url}&w=1920&h=1600&auto=format`}
            positionDiff={positionDiff}
          />
        </PictureStep>
      )}
    </>
  );
}

SingleImage.propTypes = {
  url: PropTypes.string,
  onClick: PropTypes.func,
  positionDiff: PropTypes.number,
  loadImagesFromRoot: PropTypes.bool,
};

export default memo(SingleImage);
