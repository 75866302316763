import { css } from 'styled-components';
import { defaultScrollStyles } from '@playerCommon/CommonStyledComponents/CustomScrollbar';
import { resourcesUrl } from 'global/env';

export const playerContentTextStyles = css`
  ${({ theme }) => css`
    font-size: ${theme.playerFontSize};
    line-height: ${theme.playerFontLineHeight};
  `};
  letter-spacing: 0.05px;

  ul,
  ol {
    ${({ theme }) => css`
      margin-top: ${theme.playerListMarginVertical};
      margin-bottom: ${theme.playerListMarginVertical};
      padding-left: ${theme.playerListPaddingLeft};
    `}

    // nested lists
    ul,
    ol {
      margin: ${({ theme }) => theme.playerListItemMarginVertical} 0;
    }

    li {
      margin: ${({ theme }) => theme.playerListItemMarginVertical} 0;

      p {
        margin: 0;
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  & > ul,
  & > ol {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  h3 {
    margin-top: ${({ theme }) => theme.playerHeadingLargeMarginTop};
    margin-bottom: ${({ theme }) => theme.playerHeadingLargeMarginBottom};
    font-weight: 400;
    font-size: ${({ theme }) => theme.playerHeadingLargeFontSize};
    line-height: ${({ theme }) => theme.playerHeadingLargeLineHeight};
  }

  h4 {
    margin-top: ${({ theme }) => theme.playerHeadingMediumMarginTop};
    margin-bottom: ${({ theme }) => theme.playerHeadingMediumMarginBottom};
    font-weight: 400;
    font-size: ${({ theme }) => theme.playerHeadingMediumFontSize};
    line-height: ${({ theme }) => theme.playerHeadingMediumLineHeight};
  }

  h5 {
    margin-top: ${({ theme }) => theme.playerHeadingSmallMarginTop};
    margin-bottom: ${({ theme }) => theme.playerHeadingSmallMarginBottom};
    font-weight: 600;
    font-size: ${({ theme }) => theme.playerHeadingSmallFontSize};
    line-height: ${({ theme }) => theme.playerHeadingSmallLineHeight};
  }

  pre,
  code {
    ${({ theme }) => css`
      color: ${theme.amaranth};
      display: inline-block;
      padding: 0 2px;
      margin: 0;
      border-radius: 2px;
      font-size: ${theme.playerInlineCodeFontSize};
      line-height: ${theme.playerInlineCodeLineHeight};
      min-height: 20px;
      min-width: 8px;
      background-color: ${theme.paleGrey};
      border: 1px solid ${theme.silver};
      white-space: break-spaces;
      text-decoration: inherit;
      vertical-align: top;
      overflow-wrap: anywhere;
    `}
  }

  blockquote {
    margin: 0 0 0 24px;
  }

  a {
    ${({ theme }) => css`
      color: ${theme.linkColor};
      text-decoration: underline;
    `}
  }

  aside {
    position: relative;
    padding: 12px 32px 12px 12px;
    min-height: 48px;
    margin-top: 16px;
    margin-bottom: 16px;
    border-radius: 4px;
    overflow-wrap: break-word;

    &.tip {
      border-left: 4px solid ${props => props.theme.lightblueOld};
      background: rgba(77, 210, 239, 0.1);
    }
    &.warning {
      border-left: 4px solid ${props => props.theme.blush};
      background: rgba(251, 92, 69, 0.1);
    }

    p {
      margin: 0;
    }
  }

  var {
    font-style: normal;
  }
`;

export const mobilePlayerContentTextStyle = css`
  ${({ theme }) => css`
    font-size: ${theme.playerMobileFontSize};
    line-height: ${theme.playerMobileFontLineHeight};
  `};

  ul,
  ol {
    padding-left: ${({ theme }) => theme.playerMobileListPaddingLeft};
  }

  h3 {
    margin-top: ${({ theme }) => theme.playerMobileHeadingLargeMarginTop};
    margin-bottom: ${({ theme }) => theme.playerMobileHeadingLargeMarginBottom};
    font-size: ${({ theme }) => theme.playerMobileHeadingLargeFontSize};
    line-height: ${({ theme }) => theme.playerMobileHeadingLargeLineHeight};
  }

  h4 {
    margin-top: ${({ theme }) => theme.playerMobileHeadingMediumMarginTop};
    margin-bottom: ${({ theme }) => theme.playerMobileHeadingMediumMarginBottom};
    font-size: ${({ theme }) => theme.playerMobileHeadingMediumFontSize};
    line-height: ${({ theme }) => theme.playerMobileHeadingMediumLineHeight};
  }

  h5 {
    margin-top: ${({ theme }) => theme.playerMobileHeadingSmallMarginTop};
    margin-bottom: ${({ theme }) => theme.playerMobileHeadingSmallMarginBottom};
    font-size: ${({ theme }) => theme.playerMobileHeadingSmallFontSize};
    line-height: ${({ theme }) => theme.playerMobileHeadingSmallLineHeight};
  }

  pre,
  code {
    ${({ theme }) => css`
      font-size: ${theme.playerMobileInlineCodeFontSize};
      line-height: ${theme.playerMobileInlineCodeLineHeight};
    `}
  }
`;

export const tableStyles = css`
  .table-container {
    position: relative;

    .table-wrapper {
      overflow: auto;
      padding-bottom: 8px;
      ${defaultScrollStyles};

      background: linear-gradient(90deg, white 30%, rgba(255, 255, 255, 0)) top left /* Shadow Cover LEFT */,
        linear-gradient(90deg, rgba(255, 255, 255, 0), white 70%) top right /* Shadow Cover RIGHT */,
        linear-gradient(90deg, rgba(0, 0, 0, 0.16) 18.75%, rgba(255, 255, 255, 0) 100%) top left /* Shadow LEFT */,
        linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.16) 81.25%) top right /* Shadow RIGHT */;
      background-repeat: no-repeat;
      background-size: 40px calc(100% - 8px), 40px calc(100% - 8px), 14px calc(100% - 20px), 14px calc(100% - 20px);
      background-attachment: local, local, scroll, scroll;
      cursor: default;

      table {
        ${({ theme }) => css`
          font-size: ${theme.playerTableFontSize};
          line-height: ${theme.playerTableLineHeight};
        `};
        table-layout: fixed;
        border-collapse: collapse;
        border: 1px solid ${props => props.theme.lightGrey};
        width: 100%;
        cursor: text;

        tbody {
          vertical-align: top;
        }

        th {
          background-color: rgba(208, 208, 215, 0.15); // paleGrey, but it needs opacity to show shadows underneath
          font-weight: 700;
        }

        th,
        td {
          border: 1px solid ${props => props.theme.lightGrey};
          padding: 8px;
          text-align: left;
          overflow-wrap: break-word;
          position: relative;
        }

        p {
          margin: 0;
          min-height: 24px;
        }

        ul,
        ol {
          padding-left: ${({ theme }) => theme.playerAdditionalInfoListPaddingLeft} !important;
        }
      }
    }
  }
`;

export const editorAdditionalInfoTextStyles = css`
  overflow-wrap: anywhere;

  ${({ theme }) => css`
    font-size: ${theme.playerAdditionalInfoFontSize};
    line-height: ${theme.playerAdditionalInfoLineHeight};
  `};

  ul,
  ol {
    margin-top: 8px;
    margin-bottom: 0;
    padding-left: ${({ theme }) => theme.playerAdditionalInfoListPaddingLeft};

    li {
      margin: ${({ theme }) => theme.playerListItemMarginVertical} 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  & > ul,
  & > ol {
    &:first-child {
      margin-top: 0;
    }
  }
`;

export const variableStyles = css`
  var {
    font-style: normal;
  }

  ${({ isPreview }) =>
    isPreview &&
    css`
      var {
        background: ${props => props.theme.white};
        border-radius: 4px;
        border: 1px solid ${props => props.theme.lightGrey};
        box-sizing: border-box;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        padding: 3px;
        padding-left: 20px;
        position: relative;
        user-select: none;
        word-break: break-all;
        &:before {
          content: url('${resourcesUrl.url}resources/icons/variableSmall.svg');
          left: 4px;
          position: absolute;
          top: 3px;
        }

        &[data-type='user-data'] {
          // GUIDE_VARIABLE_TYPES.userData
          border: 1px solid ${props => props.theme.pink};
          &:before {
            content: url('${resourcesUrl.url}resources/icons/variableSmallPink.svg');
          }
        }

        &[data-type='server'] {
          // GUIDE_VARIABLE_TYPES.server
          border: 1px solid ${props => props.theme.lightblueOld};
          &:before {
            content: url('${resourcesUrl.url}resources/icons/variableSmallBlue.svg');
          }
        }
      }
    `}
`;
