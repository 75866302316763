import React, { useEffect, useRef } from 'react';

import { SmartSearchAnswerWrap, SmartSearchQuestionWrap } from '../AiAnswer.styles';
import { FEEDBACK_TYPE } from '../useSmartSearchResults';
import AiAnswerResultActions from './AiAnswerResultActions';
import AiAnswerSingleMessage from './AiAnswerSingleMessage';
import AiAnswerActionsList from './AiAnswerActionsList';
import { replaceUglyBrTags, splitAnswerIntoParts } from '../AiAnswer.helpers';

const AiAnswerSingleAnswer = ({
  smartSearchAnswer,
  isLast,
  onFeedbackClick,
  onLinkClickProxy,
  onStepNextClick,
  stepId,
  isPreview,
  fallbackList,
  fallbackMessageToDisplay,
  smartSearchAnswerDone,
  stepConnectionList,
  shouldEveryConnectionBeEnabled,
  onShouldShowNextButtonSelectorChange,
  onPredefinedQuestionSelected,
}) => {
  const answerSplit = splitAnswerIntoParts(replaceUglyBrTags(smartSearchAnswer.answer));
  const answerRef = useRef(null);
  const questionRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      answerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 0);
  }, []);

  useEffect(() => {
    if (smartSearchAnswerDone || isLast) {
      questionRef?.current?.focus();
    }
  }, [smartSearchAnswerDone, isLast]);

  return (
    <>
      <SmartSearchQuestionWrap
        isFallback={smartSearchAnswer.isFallback}
        className="ai-step-question-wrap"
        ref={questionRef}
        tabIndex={-1}
      >
        {smartSearchAnswer.question}
      </SmartSearchQuestionWrap>
      <SmartSearchAnswerWrap key={smartSearchAnswer.question} ref={answerRef}>
        {answerSplit.map((answerPart, index) => (
          <AiAnswerSingleMessage
            // eslint-disable-next-line react/no-array-index-key
            key={`${smartSearchAnswer.questionAnswerId}-${index}`}
            messageId={`${smartSearchAnswer.questionAnswerId}-${index}`}
            text={answerPart}
            questionAnswerId={smartSearchAnswer.questionAnswerId}
            onLinkClickProxy={onLinkClickProxy}
            isFallback={smartSearchAnswer.isFallback}
            isCopyable
          />
        ))}
        <AiAnswerResultActions
          onFeedbackClick={feedbackValue => onFeedbackClick(feedbackValue, smartSearchAnswer)}
          answerVote={smartSearchAnswer.answerVote}
          isLast={isLast}
          answerText={smartSearchAnswer.answer}
        />
        {(smartSearchAnswer.isFallback || smartSearchAnswer.answerVote === FEEDBACK_TYPE.NEGATIVE) && (
          <>
            {!smartSearchAnswer.isFallback && smartSearchAnswer.answerVote === FEEDBACK_TYPE.NEGATIVE && (
              <AiAnswerSingleMessage text={fallbackMessageToDisplay} onLinkClickProxy={onLinkClickProxy} isFallback />
            )}
            {smartSearchAnswerDone && (
              <AiAnswerActionsList
                options={fallbackList}
                showActions={isLast}
                onStepNextClick={onStepNextClick}
                stepId={stepId}
                isPreview={isPreview}
                stepConnectionList={stepConnectionList}
                shouldEveryConnectionBeEnabled={shouldEveryConnectionBeEnabled}
                onShouldShowNextButtonSelectorChange={onShouldShowNextButtonSelectorChange}
                onPredefinedAiQuestionSelect={onPredefinedQuestionSelected}
              />
            )}
          </>
        )}
      </SmartSearchAnswerWrap>
    </>
  );
};

export default AiAnswerSingleAnswer;
