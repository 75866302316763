import React from 'react';
import styled, { css } from 'styled-components';
import { TILE_IMAGE_POSITION, TILE_IMAGE_SIZE } from 'global/index';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  ${({ imageSize, imagePosition, isTextHidden }) => {
    // SMALL images
    if (imageSize === TILE_IMAGE_SIZE.small) {
      if (isTextHidden) {
        return css`
          height: 64px;

          @media (max-width: 480px) {
            height: 64px;
          }
        `;
      }
      if (imagePosition === TILE_IMAGE_POSITION.top || imagePosition === TILE_IMAGE_POSITION.bottom) {
        return css`
          height: 64px;

          @media (max-width: 480px) {
            height: 48px;
          }
        `;
      }
      return css`
        width: 32px;
        height: 32px;
      `;
    }

    // MEDIUM images
    if (imageSize === TILE_IMAGE_SIZE.medium) {
      if (isTextHidden) {
        return css`
          height: 128px;

          @media (max-width: 480px) {
            height: 96px;
          }
        `;
      }
      if (imagePosition === TILE_IMAGE_POSITION.top || imagePosition === TILE_IMAGE_POSITION.bottom) {
        return css`
          height: 128px;

          @media (max-width: 480px) {
            height: 96px;
          }
        `;
      }
      return css`
        width: 64px;
        height: 64px;
      `;
    }

    // LARGE images
    if (imageSize === TILE_IMAGE_SIZE.large) {
      if (isTextHidden) {
        return css`
          height: 256px;

          @media (max-width: 480px) {
            height: 160px;
          }
        `;
      }
      if (imagePosition === TILE_IMAGE_POSITION.top || imagePosition === TILE_IMAGE_POSITION.bottom) {
        return css`
          height: 192px;

          @media (max-width: 480px) {
            height: 160px;
          }
        `;
      }
      return css`
        width: 96px;
        height: 96px;
      `;
    }
  }}
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const TileImage = ({ imageSrc, imageSize, imagePosition, isTextHidden }) => {
  return (
    <Wrapper
      imageSize={imageSize}
      imagePosition={imagePosition}
      isTextHidden={isTextHidden}
      className="tile-image-wrapper"
    >
      <Image src={imageSrc} alt="" className="tile-image" />
    </Wrapper>
  );
};
