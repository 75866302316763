import React from 'react';
import fullscreenContext from '@playerCommon/Contexts/fullscreenContext';

const withFullscreen = Component => props =>
  (
    <fullscreenContext.Consumer>
      {({ parentIsFullscreen, openParentFullscreen, closeParentFullscreen, parentCanvasRef }) => (
        <Component
          {...props}
          parentIsFullscreen={parentIsFullscreen}
          openParentFullscreen={openParentFullscreen}
          closeParentFullscreen={closeParentFullscreen}
          parentCanvasRef={parentCanvasRef}
        />
      )}
    </fullscreenContext.Consumer>
  );

export default withFullscreen;
