import { useMemo } from 'react';
import { KB_CONTACT_FORM_TYPES, KB_EXTERNAL_CHAT_TYPES, CONTACT_FORM_STAT_TYPE } from 'global';
import { WIDGET_SIZE_TYPES, WIDGET_FORMATS } from 'components/consts';
import { track as recorderTrack } from 'components/Analytics/recorder';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { SMART_SEARCH_KB_VISIBILITY } from 'global/index';
import { WIDGET_PLACEMENT_TYPES } from './const';

function checkContactFormEnabled(knowledgeBase) {
  // customGuideId was renamed to guideId, because having different field names for save and read is just fucking stupid
  const { contactFormType, guideId, contactFormDisplaySettings } = knowledgeBase;
  return (
    contactFormType === KB_CONTACT_FORM_TYPES.DEFAULT ||
    (contactFormType === KB_CONTACT_FORM_TYPES.CUSTOM_GUIDE && guideId) ||
    (contactFormType === KB_CONTACT_FORM_TYPES.EXTERNAL_CHAT &&
      contactFormDisplaySettings.externalChatType !== KB_CONTACT_FORM_TYPES.NONE) ||
    (contactFormType === KB_CONTACT_FORM_TYPES.EXTERNAL_LINK && contactFormDisplaySettings.externalUrl)
  );
}

function checkContactSuggestionsEnabled(knowledgeBase) {
  const { contactFormType, contactFormDisplaySettings } = knowledgeBase;
  return contactFormType === KB_CONTACT_FORM_TYPES.DEFAULT && !!contactFormDisplaySettings.suggestionsEnabled;
}

function checkContactFormDefault(knowledgeBase) {
  return knowledgeBase.contactFormType === KB_CONTACT_FORM_TYPES.DEFAULT;
}

function checkContactFormCustomGuide(knowledgeBase) {
  return knowledgeBase.contactFormType === KB_CONTACT_FORM_TYPES.CUSTOM_GUIDE && knowledgeBase.guideId;
}

function checkContactFormExternalChat(knowledgeBase) {
  return (
    knowledgeBase.contactFormType === KB_CONTACT_FORM_TYPES.EXTERNAL_CHAT &&
    knowledgeBase.contactFormDisplaySettings.externalChatType !== KB_CONTACT_FORM_TYPES.NONE
  );
}

function checkContactFormExternalLink(knowledgeBase) {
  const { contactFormType, contactFormDisplaySettings } = knowledgeBase;
  return contactFormType === KB_CONTACT_FORM_TYPES.EXTERNAL_LINK && contactFormDisplaySettings.externalUrl;
}

function checkContactFormInKnowledgeBaseSearchResults(knowledgeBase) {
  return knowledgeBase.contactFormDisplaySettings.formInKnowledgeBaseSearchResultsEnabled;
}

function checkIsContactFormInKnowledgeBaseHeaderEnabled(knowledgeBase) {
  return knowledgeBase.contactFormDisplaySettings.formInKnowledgeBaseHeaderEnabled;
}

function checkIsContactFormInGuidesHeaderEnabled(knowledgeBase) {
  return knowledgeBase.contactFormDisplaySettings.formInGuidesHeaderEnabled;
}

function checkIsContactFormInWidgetEnabled(knowledgeBase) {
  return knowledgeBase.contactFormDisplaySettings.formInWidgetEnabled;
}

function checkIsContactFormInKBEnabled(knowledgeBase) {
  return knowledgeBase.contactFormDisplaySettings.knowledgeBaseButtonEnabled;
}

export const useContactFormOptionsChecking = knowledgeBase => {
  return useMemo(
    () => ({
      isContactFormEnabled: !!checkContactFormEnabled(knowledgeBase),
      isContactSuggestionsEnabled: !!checkContactSuggestionsEnabled(knowledgeBase),
      isContactFormDefault: !!checkContactFormDefault(knowledgeBase),
      isContactFormCustomGuide: !!checkContactFormCustomGuide(knowledgeBase),
      isContactFormExternalChat: !!checkContactFormExternalChat(knowledgeBase),
      isContactFormExternalLink: !!checkContactFormExternalLink(knowledgeBase),
      isContactFormOnNoResultsEnabled: !!checkContactFormInKnowledgeBaseSearchResults(knowledgeBase),
      isContactFormInKnowledgeBaseHeaderEnabled: !!checkIsContactFormInKnowledgeBaseHeaderEnabled(knowledgeBase),
      isContactFormInGuidesHeaderEnabled: !!checkIsContactFormInGuidesHeaderEnabled(knowledgeBase),
      isContactFormInWidgetEnabled: !!checkIsContactFormInWidgetEnabled(knowledgeBase),
      isContactFormInKBEnabled: !!checkIsContactFormInKBEnabled(knowledgeBase),
    }),
    [knowledgeBase]
  );
};

export const openCustomGuideWidget = ({
  guideId,
  stepId,
  widgetFormat,
  widgetPlacement,
  widgetSizeType,
  adaptWidgetHeight,
  language,
} = {}) => {
  if (window && window.StonlyWidget) {
    if (language) {
      window.StonlyWidget('setWidgetLanguage', language);
    }
    window.StonlyWidget('openGuide', {
      guideId,
      stepId,
      widgetOptions: {
        widgetPlacement,
        widgetFormat: widgetFormat || WIDGET_FORMATS.LIGHT,
        widgetSizeType:
          widgetPlacement === WIDGET_PLACEMENT_TYPES.BOTTOM_CORNER ? WIDGET_SIZE_TYPES.MEDIUM : widgetSizeType,
        adaptWidgetHeight,
      },
    });
  }
};
export const openExternalChat = externalChatType => {
  recorderTrack({
    actionType: 'contactFormSubmission',
    actionDetail: {
      formType: CONTACT_FORM_STAT_TYPE.EXTERNAL_CHAT,
    },
  });

  if (externalChatType === KB_EXTERNAL_CHAT_TYPES.CRISP && window.$crisp && !window.$crisp.is('chat:opened')) {
    $crisp.push(['do', 'chat:show'], ['do', 'chat:open']);
  }
  if (externalChatType === KB_EXTERNAL_CHAT_TYPES.FRESHCHAT && window.fcWidget) {
    window.fcWidget.show();
    window.fcWidget.open();
  }
  if (externalChatType === KB_EXTERNAL_CHAT_TYPES.FRONT && window.FrontChat) {
    FrontChat('show');
  }
  if (externalChatType === KB_EXTERNAL_CHAT_TYPES.GORGIAS && window.GorgiasChat && GorgiasChat.hasOwnProperty('on')) {
    GorgiasChat.open();
  }
  if (window.Helpshift) {
    Helpshift('show');
    Helpshift('open');
  }
  if (externalChatType === KB_EXTERNAL_CHAT_TYPES.HUBSPOT && window.HubSpotConversations) {
    window.HubSpotConversations.widget.load({ widgetOpen: true });
    window.HubSpotConversations.widget.open();
  }
  if (externalChatType === KB_EXTERNAL_CHAT_TYPES.INTERCOM && window.Intercom) {
    Intercom('showNewMessage');
  }
  if (externalChatType === KB_EXTERNAL_CHAT_TYPES.LIVECHAT && window.LiveChatWidget) {
    LiveChatWidget.call('maximize');
  }
  if (externalChatType === KB_EXTERNAL_CHAT_TYPES.ZENDESK && window.zE) {
    try {
      zE('webWidget', 'show');
      zE('webWidget', 'open');

      zE('messenger', 'show');
      zE('messenger', 'open');
    } catch (e) {
      console.log(e);
    }
  }
  if (
    externalChatType === KB_EXTERNAL_CHAT_TYPES.SALESFORCE &&
    window.embedded_svc &&
    window.embedded_svc.liveAgentAPI
  ) {
    try {
      window.embedded_svc.liveAgentAPI.startChat();
    } catch (e) {
      console.log(e);
    }
  }
  if (externalChatType === KB_EXTERNAL_CHAT_TYPES.GENESYS && window.Genesys) {
    Genesys('command', 'Messenger.open');
  }
};

export const openExternalLink = externalUrl => {
  recorderTrack({
    actionType: 'contactFormSubmission',
    actionDetail: {
      formType: CONTACT_FORM_STAT_TYPE.EXTERNAL_URL,
    },
  });
  window.open(externalUrl, '_blank', 'noopener');
};

export const getExternalChatCSS = externalChatType => {
  if (externalChatType === KB_EXTERNAL_CHAT_TYPES.GORGIAS) {
    return `#gorgias-chat-container iframe#chat-button {
      display: none;
    }`;
  }

  if (externalChatType === KB_EXTERNAL_CHAT_TYPES.HUBSPOT) {
    return `div#hubspot-messages-iframe-container {
      display: none !important;
    }`;
  }

  if (externalChatType === KB_EXTERNAL_CHAT_TYPES.GENESYS) {
    return `.genesys-app {
      visibility: hidden;
    }`;
  }

  return null;
};

export function useIsSmartSearchQueryStringPresent() {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  return !!queryParams.smartSearch || !!queryParams.aiAnswers;
}

export function useIsSmartSearchEnabled(isUserTeamMemberWithEnabledSearch, smartSearchAccess) {
  const isSmartSearchQueryStringPresent = useIsSmartSearchQueryStringPresent();

  return useMemo(() => {
    switch (smartSearchAccess) {
      case SMART_SEARCH_KB_VISIBILITY.EVERYONE: {
        return true;
      }
      case SMART_SEARCH_KB_VISIBILITY.VISITOR_URL_PARAM: {
        return isSmartSearchQueryStringPresent;
      }
      case SMART_SEARCH_KB_VISIBILITY.LOGGED_MEMBER: {
        return isUserTeamMemberWithEnabledSearch;
      }
      case SMART_SEARCH_KB_VISIBILITY.LOGGED_MEMBER_URL_PARAM: {
        return isUserTeamMemberWithEnabledSearch && isSmartSearchQueryStringPresent;
      }
      case SMART_SEARCH_KB_VISIBILITY.NOBODY: {
        return false;
      }
      default: {
        return false;
      }
    }
  }, [smartSearchAccess, isUserTeamMemberWithEnabledSearch]);
}
