import React, { Component } from 'react';
import styled, { withTheme, ThemeProvider, createGlobalStyle } from 'styled-components';
import PropTypes from 'prop-types';
import i18n, { getLanguageShorthand } from 'helpers/i18n';
import { setLanguage } from 'helpers/i18n.helpers';
import { RIGHT, STEP_NEXT_VISIBILITY } from 'global';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import {
  openFullscreen,
  closeFullscreen,
  fullscreenEnabled,
  isFullscreen,
  addOnFullscreenChangeListener,
  removeOnFullscreenChangeListener,
} from '@playerCommon/helpers/windowManagement';
import { createPathFromParam, loadExplanation, getNewLanguageUrl } from 'helpers/explanationManagement.js';
import Loader, { FullscreenLoaderWrap } from '@playerCommon/CustomElements/Loader/Loader';
import { validateHexaDecimal } from 'helpers/validationHelpers.js';
import NoViewAccess from '@playerCommon/Commons/NoViewAccess';
import fullscreenContext from '@playerCommon/Contexts/fullscreenContext';
import ExplanationPlayer from '@playerCommon/ComplexElements/ExplanationPlayer';
import withUser from '@playerCommon/HOC/withUser/withUser';
import withExplanationData from 'HOC/withExplanationData';
import withContextProviders from 'HOC/withContextProviders';
import { postMessage } from 'helpers/widgetHelpers';
import { getStepsGlobalScrollbarStyles } from '@playerCommon/CommonStyledComponents/CustomScrollbar';
import { parseGuideData } from 'helpers/guidePlayerHelpers';
import { parseBoolean } from '@playerCommon/helpers/booleanHelpers';
import { getIds } from 'helpers/statIdsManagement';

const Canvas = styled.div`
  background-color: ${props => props.theme.white};
  display: flex;
  flex-direction: column;
  width: 1px;
  min-width: 100%;
  position: relative;
  ${({ embedFontFamily }) => embedFontFamily && `font-family: '${embedFontFamily}'`};
  height: 100%;
`;

const GlobalScrollStyles = createGlobalStyle`
  ${getStepsGlobalScrollbarStyles({ marginTop: '0px', marginBottom: '68px' })}

  @media screen and (max-width: 620px) {
    ${getStepsGlobalScrollbarStyles({ marginTop: '0px', marginBottom: '68px' })}
  }

  @media screen and (max-width: 480px) {
    ${getStepsGlobalScrollbarStyles({ marginTop: '0px', marginBottom: '60px' })}
  }
`;

const PositionedExplanationPlayer = styled(ExplanationPlayer)`
  padding-top: ${({ isFullScreen }) => (isFullScreen ? '48px' : '40px')};
`;

const ALLOWED_RIGHTS = new Set([RIGHT.FULL_RIGHTS, RIGHT.VIEW, RIGHT.VIEW_EDIT, RIGHT.VIEW_EDIT_PUBLISH]);

const getProcessedState = (match, location, loadedGuide, statusCode) => {
  if (statusCode === 403 || !loadedGuide) {
    return { explanationLoaded: true, accessToExplanation: false, guide: {} };
  }
  if (!loadedGuide) return null;

  const accessToExplanation = ALLOWED_RIGHTS.has(loadedGuide.guideInfo.access);
  if (!accessToExplanation) {
    return { explanationLoaded: true, accessToExplanation: false, guide: {} };
  }

  let themeHighlightColor;

  const { embedHighlightColor, buttonColor } = loadedGuide.guideOptions;
  const urlParams = queryString.parse(location.search);

  if (buttonColor && validateHexaDecimal(buttonColor)) {
    themeHighlightColor = buttonColor;
  }
  if (embedHighlightColor && validateHexaDecimal(embedHighlightColor)) {
    themeHighlightColor = embedHighlightColor;
  }
  if (urlParams.accent && validateHexaDecimal(urlParams.accent)) {
    themeHighlightColor = urlParams.accent;
  }

  return {
    guide: loadedGuide,
    accessToExplanation,
    explanationLoaded: true,
    //
    themeHighlightColor,
  };
};

class BorderlessEmbed extends Component {
  constructor(props) {
    super(props);
    const ssrInitialState = getProcessedState(props.match, props.location, props.loadedGuide, props.statusCode);
    const stepsPath = createPathFromParam(props.match.params.pathToLoad);
    this.state = ssrInitialState
      ? { ...ssrInitialState, stepsPath }
      : {
          guide: null,
          explanationLoaded: false,
          accessToExplanation: true,
          //
          themeHighlightColor: undefined,
          isFullScreen: false,
          stepsPath,
        };
  }

  canvasRef = React.createRef();

  fullscreenCanvasRef = React.createRef();

  componentDidMount() {
    const { explanationLoaded } = this.state;
    if (!explanationLoaded) this.initiateExplanation();

    getIds().then(({ sessionId }) => {
      postMessage({
        type: 'params',
        name: 'stonlySessionId',
        value: sessionId,
      });
    });
    postMessage({ type: 'initPlugin' });

    addOnFullscreenChangeListener(this.onFullscreenChange);

    this.updateIframeHeight();
  }

  componentDidUpdate(prevProps) {
    const { userManagement, match } = this.props;
    const { match: oldMatch } = prevProps;

    if (userManagement.user.id && !prevProps.userManagement.user.id) {
      this.initiateExplanation();
    }
    if (match.params.pathToLoad !== oldMatch.params.pathToLoad) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ stepsPath: createPathFromParam(match.params.pathToLoad) });
    }
    this.updateIframeHeight();
  }

  componentWillUnmount() {
    removeOnFullscreenChangeListener(this.onFullscreenChange);
  }

  onFullscreenChange = () => {
    if (isFullscreen()) {
      this.setState({ isFullScreen: true });
    } else {
      this.setState({ isFullScreen: false });
    }
  };

  openFullscreen = () => {
    if (fullscreenEnabled) {
      openFullscreen(this.fullscreenCanvasRef.current);
    }
    this.setState({ isFullScreen: true });
  };

  closeFullscreen = () => {
    const { isFullScreen } = this.state;
    if (isFullScreen && isFullscreen()) {
      closeFullscreen();
    }
    this.setState({ isFullScreen: false });
  };

  getParams = (nextprops = {}) => {
    const { match } = this.props;
    let nextParams;

    const currentParams = { id: match.params.id, pathToLoad: match.params.pathToLoad };

    if (Object.keys(nextprops).length !== 0 || nextprops.constructor !== Object) {
      if (nextprops.match) {
        nextParams = {
          id: nextprops.match.params.id,
          pathToLoad: nextprops.match.params.pathToLoad,
        };
      } else {
        nextParams = { id: nextprops.id, pathToLoad: nextprops.pathToLoad };
      }
    } else {
      nextParams = { id: null, pathToLoad: '' };
    }
    return {
      id: currentParams.id,
      pathToLoad: currentParams.pathToLoad,
      nextId: nextParams.id,
      nextPathToLoad: nextParams.pathToLoad,
    };
  };

  updateIframeHeight = () => {
    const { id } = this.getParams();
    const { updateParentContainer } = this.props;

    const explanationId = id;

    if (this.canvasRef.current && Object.keys(this.canvasRef.current).length !== 0) {
      postMessage({ FrameHeight: `${this.canvasRef.current.offsetHeight}`, iFrameId: `ston-${explanationId}` });
    }
    if (updateParentContainer) {
      updateParentContainer();
    }
  };

  initiateExplanation = async (force = false) => {
    const { loadedGuide, match, location } = this.props;

    const { themeHighlightColor: currentThemeHighlightColor } = this.state;
    const { id } = this.getParams();

    let newLoadedGuide = {};

    newLoadedGuide =
      !loadedGuide || Object.keys(loadedGuide).length === 0 || force ? await loadExplanation(id) : loadedGuide;

    const language = getLanguageShorthand();
    const computedState = getProcessedState(match, location, parseGuideData(newLoadedGuide, language));

    if (currentThemeHighlightColor) {
      const { themeHighlightColor, ...newState } = computedState;
      this.setState(newState);
    } else {
      this.setState(computedState);
    }
  };

  setLanguage = async language => {
    const { forceUpdateAtRoot, history, match } = this.props;
    setLanguage(language);
    forceUpdateAtRoot();
    await this.initiateExplanation(true);
    const { guide } = this.state;
    history.replace(getNewLanguageUrl(language, match, guide.guideInfo.title));
  };

  render() {
    const { guide, isFullScreen, themeHighlightColor, accessToExplanation, explanationLoaded, stepsPath } = this.state;
    const { theme, match } = this.props;

    if (!accessToExplanation) {
      return <NoViewAccess showBranding={false} showLink={false} />;
    }

    if (!explanationLoaded) {
      return (
        <FullscreenLoaderWrap>
          <Loader text={i18n('Global.Loading')} />
        </FullscreenLoaderWrap>
      );
    }
    const hasConditionalConnection = guide.allGuides
      .flatMap(({ stepNext }) => stepNext)
      .some(({ visibility }) => visibility === STEP_NEXT_VISIBILITY.externalCondition);

    let displayProgressBar = accessToExplanation ? parseBoolean(guide.guideOptions.displayProgressBar, true) : true;
    displayProgressBar = displayProgressBar && !hasConditionalConnection;

    let firstStep = null;
    const firstStepObj = guide.allGuides.find(info => info.guideId === match.params.id);
    if (firstStepObj) firstStep = firstStepObj.startId;

    let overridingTheme;
    if (themeHighlightColor && validateHexaDecimal(themeHighlightColor)) {
      overridingTheme = { ...theme, highlightColor: themeHighlightColor };
    }

    return (
      <ThemeProvider theme={overridingTheme || theme}>
        <GlobalScrollStyles />
        <Canvas
          ref={this.canvasRef}
          isFullScreen={isFullScreen}
          embedFontFamily={guide.guideOptions.embedFontFamily}
          className="borderlessembed-guide"
        >
          <fullscreenContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
              parentIsFullscreen: isFullScreen,
              openParentFullscreen: this.openFullscreen,
              closeParentFullscreen: this.closeFullscreen,
              parentCanvasRef: this.fullscreenCanvasRef,
            }}
          >
            <>
              {accessToExplanation && (
                <PositionedExplanationPlayer
                  mode="widget"
                  guide={guide}
                  firstStep={firstStep}
                  stepsPath={stepsPath}
                  setLanguage={this.setLanguage}
                  setFullScreen={this.setFullScreen}
                  isFullScreen={isFullScreen}
                  displayProgressBar={displayProgressBar}
                  borderlessEmbed
                />
              )}
            </>
          </fullscreenContext.Provider>
        </Canvas>
      </ThemeProvider>
    );
  }
}

BorderlessEmbed.propTypes = {
  match: PropTypes.object,
  userManagement: PropTypes.object,
  history: PropTypes.object,
  theme: PropTypes.object,
  location: PropTypes.object,
  updateParentContainer: PropTypes.func,
  loadedGuide: PropTypes.object,
  statusCode: PropTypes.number,
  forceUpdateAtRoot: PropTypes.func,
};

export default withExplanationData(withContextProviders(withRouter(withUser(withTheme(BorderlessEmbed)))));
