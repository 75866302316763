import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Dropdown from '@playerCommon/CustomElements/Dropdown';
import { defaultScrollStyles } from '@playerCommon/CommonStyledComponents/CustomScrollbar';

const TagItemsWrap = styled.div`
  display: flex;
  margin-left: 8px;
`;

const TagItem = styled.div`
  flex-shrink: 0;
  max-width: 214px;
  height: 24px;
  margin-right: 8px;
  padding: 5px 8px;
  border-radius: 6px;
  background-color: ${props => (props.color && `${props.color}33`) || props.theme.silver}; // HEX and 20% opacity
  color: ${props => props.theme.slateGrey};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 11px;
  line-height: 14px;
  font-weight: 600;

  ${({ roundedTags }) =>
    roundedTags &&
    css`
      border-radius: 28px;
      height: 24px;
      padding: 4px 8px;
      font-size: 10px;
      line-height: 16px;
      font-weight: 400;
    `}

  b {
    color: ${props => props.theme.darkGrey};
  }
`;

const MoreTagsButton = styled(TagItem)`
  transition: background-color 0.2s;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: ${props => props.theme.lightGrey};
  }
`;

const StyledDropdown = styled(Dropdown)`
  display: flex;
  flex-wrap: wrap;
  padding: 10px 6px 10px 12px;
  overflow-y: auto;
  overflow-y: overlay;
  max-height: 178px;
  ${defaultScrollStyles};
`;

const SearchTags = ({ tags, roundedTags }) => {
  const dropdownRef = useRef();
  const toggleDropdown = () => {
    dropdownRef.current.toggleDropdown();
  };
  const closeDropdown = () => {
    dropdownRef.current.closeDropdown();
  };
  return (
    <TagItemsWrap>
      <TagItem
        roundedTags={roundedTags}
        dangerouslySetInnerHTML={{
          __html: tags[0],
        }}
      />
      {tags.length > 1 && (
        <StyledDropdown
          ref={dropdownRef}
          width={240}
          trigger={
            <MoreTagsButton onMouseEnter={toggleDropdown} onMouseLeave={closeDropdown}>
              +{tags.length - 1}
            </MoreTagsButton>
          }
        >
          {tags.slice(1).map(tag => (
            <TagItem
              key={tag}
              dangerouslySetInnerHTML={{
                __html: tag,
              }}
            />
          ))}
        </StyledDropdown>
      )}
    </TagItemsWrap>
  );
};

SearchTags.propTypes = {
  tags: PropTypes.array,
  roundedTags: PropTypes.bool,
};

export default SearchTags;
