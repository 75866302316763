const AGENT_STORAGE_TEAM_PREFIX = 'STONLY_AGENT_TEAM_ID_FOR_';

export const agentStorage = {
  saveTeam: ({ userId, teamId }) => {
    try {
      if (teamId) {
        localStorage.setItem(AGENT_STORAGE_TEAM_PREFIX + userId, teamId);
      }
    } catch (error) {
      console.log('STON: error - agentTeamStorage.save', { userId, teamId, error });
    }
  },
  readTeam: ({ userId }) => {
    try {
      const teamId = Number.parseInt(localStorage.getItem(AGENT_STORAGE_TEAM_PREFIX + userId), 10);
      return Number.isNaN(teamId) ? undefined : teamId;
    } catch (error) {
      console.log('STON: error - agentTeamStorage.read', { userId, error });
      return undefined;
    }
  },
};
