const addFreshChatListeners = () => {
  window.fcWidget.on('widget:closed', function(resp) {
    window.fcWidget.hide();
  });
};

export const initializeFreshChat = isWidget => {
  setTimeout(() => {
    if (window.fcWidget) {
      window.fcWidget.hide();
      if (!isWidget) addFreshChatListeners();
    }
  }, 1000);
};
