import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import CloseButton from '@playerCommon/CustomElements/CloseButton/CloseButton';
import { isMobileSafari } from 'helpers/userAgentHelpers';
import UnsafeHtml from '@playerCommon/StandardElements/UnsafeHtml/UnsafeHtml';
import { defaultScrollStyles } from '@playerCommon/CommonStyledComponents/CustomScrollbar';
import {
  tableStyles,
  playerContentTextStyles,
  mobilePlayerContentTextStyle,
} from '@playerCommon/CommonStyledComponents/Typography';
import useSetIsImageFullscreen from '@playerCommon/hooks/useSetIsImageFullscreen';

const Canvas = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 225;
  left: 0;
  top: 0;
  overflow-x: hidden;
  transition: opacity 0.2s, visibility 0.2s;
  will-change: visibility, opacity;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;

  ${({ show }) =>
    show &&
    css`
      visibility: visible;
      opacity: 1;
    `};

  ${isMobileSafari() &&
  css`
    align-items: flex-start;
    padding-top: 85px;
  `}
`;

const FullScreenLayer = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: ${props => props.theme.paleGrey};
`;

const StyledCloseButton = styled(CloseButton)`
  div[role='button'] {
    z-index: 1;
  }
`;

const UnsafeTableHtml = styled(UnsafeHtml)`
  padding: 80px;
  width: 100%;
  max-height: 100%;
  overflow: auto;
  z-index: 1;
  ${defaultScrollStyles};

  @media screen and (max-width: 768px) {
    padding: 48px 24px;
  }

  table {
    background-color: rgba(255, 255, 255, 0.3);
  }

  ${tableStyles};
  ${playerContentTextStyles}
  ${mobilePlayerContentTextStyle};
`;

const FullscreenTable = ({ table, show, closeFullscreen }) => {
  useSetIsImageFullscreen({ isImageFullscreen: show });

  return (
    <Canvas show={show}>
      <FullScreenLayer onClick={closeFullscreen} />
      {show && table && <UnsafeTableHtml text={table} />}
      <StyledCloseButton onClick={closeFullscreen} size="big" />
    </Canvas>
  );
};

FullscreenTable.propTypes = {
  table: PropTypes.string,
  show: PropTypes.bool,
  closeFullscreen: PropTypes.func,
};

export default FullscreenTable;
