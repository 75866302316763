import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from '@playerCommon/StandardElements/Button';
import ArrowLeftSVG from 'resources/icons/arrowLeft.svg';
import useGuideData from '@playerCommon/hooks/playerHooks/useGuideData';

const StyledButton = styled(Button)`
  margin-right: ${({ theme }) => theme.backButtonMarginRight};
  vertical-align: bottom;
  a {
    border-radius: 50%;
  }
`;

const BackIcon = styled(ArrowLeftSVG)`
  /* @noflip */
  [dir='rtl'] & {
    transform: scaleX(-1);
  }
`;

const BackButton = ({ onClick, link, disabled = false, dataCy, className }) => {
  const { displayBackButton } = useGuideData();

  if (!displayBackButton) {
    return null;
  }

  return (
    <StyledButton
      dataCy={dataCy}
      background="white"
      mouseover="grey"
      type="icon"
      content={BackIcon}
      ariaLabel="go back one step"
      onClick={disabled ? undefined : onClick}
      link={disabled ? undefined : link}
      disabled={disabled}
      className={className}
    />
  );
};

BackButton.propTypes = {
  onClick: PropTypes.func,
  link: PropTypes.string,
  dataCy: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default BackButton;
