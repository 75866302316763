import styled, { css } from 'styled-components';

import CrossSVG from 'icons/crossMedium.svg';
import { FieldStyles } from '../_shared/Field.styles';

interface InputFileElementWrapProps {
  onDragLeave?: (e: React.DragEvent<HTMLDivElement>) => void;
  onDragOver?: (e: React.DragEvent<HTMLDivElement>) => void;
  onDrop: (e: React.DragEvent<HTMLDivElement>) => void;
}

interface FieldWrapFileProps {
  isFileOver?: boolean;
  disabled?: boolean;
  $borderColor?: string;
  $isActive?: boolean;
}

const Label = styled.label<{ $isActive?: boolean; disabled?: boolean }>`
  font-size: 14px;
  line-height: 24px;
  color: ${props => props.theme.lightGrey};
  margin-left: 8px;
  width: 100%;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
    `}

  ${({ $isActive }) =>
    $isActive &&
    css`
      color: ${props => props.theme.darkGrey};
    `}
`;

const FieldWrapFile = styled(FieldStyles.FieldWrap)<FieldWrapFileProps>`
  border-style: dashed;
  border-color: ${props => props.theme.grey};
  background: transparent;
  transition: opacity 0.2s, box-shadow 0.2s;
  ${({ isFileOver }) =>
    isFileOver &&
    css`
      border-style: solid;
      border-color: ${props => props.theme.seaweed};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}

  ${({ $isActive }) =>
    $isActive &&
    css`
      border-style: solid;
      opacity: 1;
    `}
`;

const InputFileElement = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;
`;

const FileLabel = styled.label`
  color: ${props => props.theme.lightGrey};
`;

const FileRemove = styled(CrossSVG)<{ $isActive?: boolean }>`
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-left: auto;
  opacity: 0;
  transition: opacity 0.2s;
  cursor: pointer;

  &:focus {
    opacity: 1;
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      opacity: 1;
    `}

  path {
    fill: ${props => props.theme.charcoal};
  }
`;

const InputFileElementWrap = styled(FieldStyles.InputElementWrap)<InputFileElementWrapProps>``;

export default {
  Label,
  FieldWrapFile,
  InputFileElement,
  FileLabel,
  FileRemove,
  InputFileElementWrap,
};
