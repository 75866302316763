import Button from '@playerCommon/StandardElements/Button';
import styled, { css } from 'styled-components';
import { Textarea } from '@playerCommon/StandardElements/BaseInputs';
import BackButton from '@playerCommon/CustomElements/BackButton';
import SuccessSVG from 'icons/successLarge.svg';
import UnsafeHtml from '@playerCommon/StandardElements/UnsafeHtml/UnsafeHtml';
import {
  mobilePlayerContentTextStyle,
  playerContentTextStyles,
  variableStyles,
} from '@playerCommon/CommonStyledComponents/Typography';

export const TitleContent = styled(UnsafeHtml)`
  margin-bottom: 40px;

  ${playerContentTextStyles};
  font-size: 28px;
  line-height: 36px;

  @media screen and (max-width: 899px) {
    ${mobilePlayerContentTextStyle};
    font-size: 28px;
    line-height: 36px;
  }

  @media screen and (max-width: 600px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const Title = styled.div`
  font-size: 28px;
  line-height: 36px;
  margin-bottom: 40px;

  @media screen and (max-width: 600px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const Content = styled.div`
  width: fit-content;
  max-width: 100%;
  margin-bottom: 32px;

  @media screen and (max-width: 600px) {
    max-width: 312px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const LabelsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${props => props.theme.steel};
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin-top: 12px;
  gap: 16px;

  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
`;

export const Label = styled.div`
  display: block;

  &:last-child {
    text-align: right;
  }
`;

export const StyledTextarea = styled(Textarea)`
  max-width: 560px;
  margin-bottom: 10px;
`;

export const ButtonsWrap = styled.div`
  display: flex;
  max-width: 560px;
`;

export const SkipButton = styled(Button)`
  ${({ isFirstStep }) =>
    isFirstStep &&
    css`
      button {
        border: none;
        font-size: 14px;
        line-height: 16px;
        padding: 0;

        &:hover {
          background-color: transparent;
          color: ${props => props.theme.darkGrey};
        }
      }
    `}
`;

export const SubmitButton = styled(Button)`
  margin-left: auto;
`;

export const ThankYou = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SuccessIcon = styled(SuccessSVG)`
  margin-bottom: 24px;
`;

export const StyledBackButton = styled(BackButton)`
  margin-right: 0;
`;

export const Canvas = styled.div`
  ${variableStyles}

  @media screen and (max-width: 899px) {
    padding: 0 ${({ theme }) => theme.stepsContentPaddings.contentPadding}px;
  }
`;
