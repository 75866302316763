import styled, { css } from 'styled-components';
import { checkColorLuma } from 'helpers/colorHelpers';
import ChecklistSkippedSVG from 'icons/checklistSkipped.svg';
import { playerContentTextStyles, mobilePlayerContentTextStyle } from '@playerCommon/CommonStyledComponents/Typography';
import { navigationWithKeyboardStyle } from '@playerCommon/CommonStyledComponents/NavigationWithKeyboard';
import ChevronSVG from 'icons/chevronThinRight.svg';
import ChecklistItemIconComponent from './ChecklistItemIcon';

export const ItemTextWrapper = styled.label``;

export const ChecklistItemIcon = styled(ChecklistItemIconComponent)``;
export const ChevronIcon = styled(ChevronSVG)``;

export const ItemTextDiv = styled.div`
  display: inline;
  overflow-wrap: anywhere;
`;

export const ItemText = styled.span`
  transition: color 0.4s;
`;

export const ItemWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  line-height: 24px;
  padding: 0px;
  width: fit-content;
  transition: box-shadow 0.2s;
  outline: none;

  &:focus-visible {
    box-shadow: 0px 0px 0px 2px ${props => props.theme.white}, 0px 0px 0px 4px ${props => props.theme.darkBlue};
  }

  @media screen and (max-width: 480px) {
    width: auto;
    flex-grow: 1;

    ${ItemTextWrapper} {
      align-items: center;
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
    }
  }

  ${ItemTextWrapper} {
    padding: 12px 8px;
    ${navigationWithKeyboardStyle}
    :hover {
      background: ${({ theme }) => theme.paleGrey};
      border-radius: 4px;
    }
  }

  ${ChecklistItemIcon} {
    margin-left: 12px;
    margin-right: 8px;
    flex-shrink: 0;
  }

  ${({ hasSingleAction, isReadOnly }) =>
    (hasSingleAction || isReadOnly) &&
    css`
      padding: 12px;

      ${ItemTextWrapper} {
        padding: 0px;
      }
      ${ChecklistItemIcon} {
        margin-left: 0px;
        margin-right: 16px;
      }
      &:hover {
        background: ${({ theme }) => theme.paleGrey};
        border-radius: 4px;

        ${({ canBeCheckedManually }) =>
          canBeCheckedManually &&
          css`
            ${ChecklistItemIcon} path[class*='circle'] {
              fill: ${({ theme }) => theme.highlightColor};
            }
          `}
      }
    `}

  ${ChecklistItemIcon} {
    path[class*='circle'] {
      fill: ${({ theme }) => theme.grey};
    }

    path[class*='tick'] {
      display: none;
    }

    ${({ isChecked }) =>
      isChecked &&
      css`
        path[class*='circle'] {
          fill: ${({ theme }) => theme.highlightColor};
          fill-rule: nonzero;
        }

        path[class*='tick'] {
          display: block;
          fill: ${({ theme }) => (checkColorLuma(theme.highlightColor) > 200 ? theme.darkGrey : theme.white)};
        }
      `}

    ${({ isDisabled, isStatusLoaded }) =>
      isDisabled &&
      isStatusLoaded &&
      css`
        background: ${({ theme }) => theme.paleGrey};
        border-radius: 50%;
        path {
          fill: ${({ theme }) => theme.lightGrey};
        }
      `}

      ${({ canBeCheckedManually, hasSingleAction }) =>
      canBeCheckedManually &&
      !hasSingleAction &&
      css`
        &:hover path[class*='circle'] {
          fill: ${({ theme }) => theme.highlightColor};
        }
      `}
  }

  ${({ isChecked, isStatusLoaded }) =>
    isChecked &&
    isStatusLoaded &&
    css`
      ${ItemText} {
        background-image: ${({ theme }) => `linear-gradient(${theme.lightGrey}, ${theme.lightGrey})`};
        background-position: 0% 50%;
        background-repeat: no-repeat;
        background-size: 100% 1px;
        color: ${({ theme }) => theme.lightGrey};
        transition: background-size 0.4s, color 0.4s;
        transform-origin: 50% 50% 0px;
      }

      &:hover ${ItemText} {
        background-size: 0% 1px;
        color: ${({ theme }) => theme.darkGrey};
      }
    `};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
      ${ItemText} {
        color: ${({ theme }) => theme.lightGrey};
      }
    `};

  ${({ isReadOnly }) =>
    isReadOnly &&
    css`
      cursor: default;
    `}

  ${ChevronIcon} {
    cursor: pointer;
    flex-shrink: 0;
    margin-left: 8px;
    vertical-align: middle;
    path {
      transition: fill 0.3s;
      fill: ${({ theme }) => theme.highlightColor};
    }

    ${({ isChecked }) =>
      isChecked &&
      css`
        path {
          fill: ${({ theme }) => theme.lightGrey};
        }
      `}

    ${({ isDisabled }) =>
      isDisabled &&
      css`
        path {
          fill: ${({ theme }) => theme.lightGrey};
        }
      `}

    ${({ isStatusLoaded }) =>
      !isStatusLoaded &&
      css`
        display: none;
      `}
  }

  &:hover ${ChevronIcon} {
    path {
      fill: ${({ theme }) => theme.highlightColor};
    }
  }
`;

export const ChecklistWrapper = styled.div`
  ${playerContentTextStyles};

  @media screen and (max-width: 899px) {
    ${mobilePlayerContentTextStyle};
  }

  ${({ isChecklistSkipped }) =>
    isChecklistSkipped &&
    css`
      text-align: center;
    `}

  @media screen and (max-width: 899px) {
    padding: 0 ${({ theme }) => theme.stepsContentPaddings.contentPadding}px;
  }
`;

export const ChecklistSkippedText = styled.div`
  font-size: 28px;
  line-height: 36px;
  margin-top: 16px;
`;

export const ChecklistSkippedIcon = styled(ChecklistSkippedSVG)`
  path {
    fill: ${({ theme }) => theme.highlightColor};
  }
`;
