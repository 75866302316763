import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import i18n from 'helpers/i18n';
import { assetsUrl } from 'global/env';
import Icon from '@playerCommon/StandardElements/Icon';
import TextLink from '@playerCommon/StandardElements/TextLink';
import displayDate from 'helpers/dateManagement.js';
import { getFolderCaption } from 'helpers/helpcenterHelpers';

import MoreIconSVG from 'icons/more.svg';
import FolderRowDropdown from './FolderRowDropdown';

const OptionsIcon = styled(MoreIconSVG)`
  margin-right: 20px;
  cursor: pointer;
  path {
    fill: ${props => props.theme.slateGrey};
  }
`;

const Canvas = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ context }) => (context === 'view' ? '0 8px;' : '0 24px;')};
  margin: ${({ context }) => (context === 'view' ? '0 -8px;' : '0 -24px;')};
  ${({ context }) =>
    context === 'smallView' &&
    css`
      margin: 0;
      padding: 0;
    `};

  &:hover {
    background: ${props => props.theme.paleGrey};
  }
`;

const FolderIcon = styled(Icon)`
  margin: 24px 24px 24px 0;
`;

const Separator = styled(Icon)`
  margin: 0 8px;
`;

const TitleCol = styled.div`
  display: flex;
  align-items: center;
  width: 75%;
  ${({ context }) => context === 'view' && 'width: 80%;'}
  ${({ context }) => context === 'smallView' && 'width: 100%;'}
`;

const Title = styled(TextLink)`
  font-size: ${({ context }) => (['view', 'smallView'].includes(context) ? '14px' : '16px')};
`;

const UpdateDate = styled.div`
  width: 20%;
  ${({ context }) => context === 'smallView' && 'display:none;'}
  font-size: ${({ context }) => (context === 'view' ? '12px' : '16px')};
`;

const SubInfo = styled.div`
  color: ${props => props.theme.lightGrey};
  font-size: 12px;
  margin-top: 7px;
  display: flex;
  align-items: center;
`;

const Options = styled.div`
  width: 5%;
  display: flex;
  justify-content: flex-end;
  position: relative;
`;

const Folder = ({
  folder,
  folders,
  currentLanguage,
  setFolderToDisplay,
  openMovePanel,
  deleteFolder,
  toggleFolderEdit,
  toggleChangeRights,
  loadedExplanations,
  context,
  isRoot,
}) => {
  const explanationNumberLabel = useMemo(() => {
    let label;

    const folderExplanations = loadedExplanations.filter(explanation => explanation.folderId === folder.folderId);
    const containerNumber = folderExplanations.length;

    if (containerNumber === 1) {
      label = i18n('Console.explanationsNumberLabelOne');
    } else if (containerNumber < 1) {
      label = i18n('Console.explanationsLabelNone');
    } else {
      label = `${containerNumber} ${i18n('Console.explanationsLabelMultiple')}`;
    }
    return label;
  }, [loadedExplanations]);

  const folderNumberLabel = useMemo(() => {
    const childrenFolders = folders.filter(teamFolder => teamFolder.parentId === folder.folderId);

    let label;

    if (childrenFolders.length === 1) {
      label = i18n('Console.foldersNumberLabelOne');
    } else if (childrenFolders.length < 1) {
      label = i18n('Console.foldersLabelNone');
    } else {
      label = `${childrenFolders.length} ${i18n('Console.foldersLabelMultiple')}`;
    }
    return label;
  }, [folders]);

  const currentFolderName = getFolderCaption(folder, currentLanguage).name;

  const thumbSize = ['view', 'smallView'].includes(context) ? 32 : 40;

  return (
    <Canvas data-cy="folderRows" context={context}>
      <TitleCol context={context}>
        <FolderIcon size={thumbSize} src={`${assetsUrl}resources/icons/folder.svg`} />
        <div>
          <Title context={context} type="black" onClick={() => setFolderToDisplay(folder.folderId, folder.teamId)}>
            {currentFolderName}
          </Title>
          <SubInfo>
            {explanationNumberLabel}
            <Separator src={`${assetsUrl}resources/icons/separatorDot.svg`} />
            {folderNumberLabel}
          </SubInfo>
        </div>
      </TitleCol>
      <UpdateDate context={context}>{displayDate(folder.updateDate)}</UpdateDate>
      {!['view', 'smallView'].includes(context) && (
        <Options data-cy="folderOptions">
          <FolderRowDropdown
            folder={folder}
            folderName={currentFolderName}
            teamId={folder.teamId}
            isRoot={isRoot}
            onEdit={toggleFolderEdit}
            onEditRights={toggleChangeRights}
            onMoveTo={openMovePanel}
            onDelete={deleteFolder}
            trigger={<OptionsIcon data-cy="optionsButton" />}
          />
        </Options>
      )}
    </Canvas>
  );
};

Folder.propTypes = {
  folder: PropTypes.object,
  folders: PropTypes.array,
  currentLanguage: PropTypes.string,
  setFolderToDisplay: PropTypes.func,
  elementToDisplay: PropTypes.object,
  toggleElementToDisplay: PropTypes.func,
  openMovePanel: PropTypes.func,
  deleteFolder: PropTypes.func,
  toggleFolderEdit: PropTypes.func,
  toggleChangeRights: PropTypes.func,
  loadedExplanations: PropTypes.array,
  context: PropTypes.string,
  isRoot: PropTypes.bool,
};

export default Folder;
