/* eslint-disable import/prefer-default-export */
import React from 'react';
import i18n from 'helpers/i18n';
import { StepIcon, GuideIcon, FolderIcon, SearchIcon, ChatIcon } from './CommonStyledComponents/SearchFilteringIcons';

// ============== Widget format constants ==============
export const WIDGET_PLACEMENTS = {
  NEXT_TO_TRIGGER: 'NEXT_TO_TRIGGER',
  MODAL: 'MODAL',
  BOTTOM_RIGHT: 'BOTTOM_RIGHT',
  BOTTOM_LEFT: 'BOTTOM_LEFT',
  BOTTOM_CORNER: 'BOTTOM_CORNER',
};

export const MOBILE_WIDGET_PLACEMENTS = {
  POPOVER: 'POPOVER',
  SHEET: 'SHEET',
  DIALOG: 'DIALOG',
  FULL_SCREEN: 'FULL_SCREEN',
  INTEGRATED: 'INTEGRATED',
};

export const WIDGET_SIZE_TYPES = {
  FULL_SCREEN: 'FULL_SCREEN',
  MEDIUM: 'MEDIUM',
  SMALL: 'SMALL',
};

export const WIDGET_FORMATS = {
  CLASSIC: 'CLASSIC',
  LIGHT: 'LIGHT',
};

export const WIDGET_PREFERRED_POSITIONS = {
  LEFT: 'LEFT',
  TOP: 'TOP',
  RIGHT: 'RIGHT',
  BOTTOM: 'BOTTOM',
};

// ============== Search constants ==============

export const SEARCH_FILTER_TYPES = {
  ALL: 'ALL',
  GUIDE: 'GUIDE',
  FOLDER: 'FOLDER',
  STEP: 'STEP',
  SMART: 'SMART',
};

const mapSearchFilterName = {
  [SEARCH_FILTER_TYPES.ALL]: i18n('HelpcenterSearchResults.All'),
  [SEARCH_FILTER_TYPES.SMART]: i18n('HelpcenterSearchResults.SmartAnswer'),
  [SEARCH_FILTER_TYPES.FOLDER]: i18n('HelpcenterSearchResults.Folders'),
  [SEARCH_FILTER_TYPES.GUIDE]: i18n('HelpcenterSearchResults.Guides'),
  [SEARCH_FILTER_TYPES.STEP]: i18n('HelpcenterSearchResults.Steps'),
};

export const mapSearchFilterForSubtitle = {
  [SEARCH_FILTER_TYPES.ALL]: i18n('HelpcenterSearchResults.Result'),
  [SEARCH_FILTER_TYPES.FOLDER]: i18n('HelpcenterSearchResults.Folder'),
  [SEARCH_FILTER_TYPES.GUIDE]: i18n('HelpcenterSearchResults.Guide'),
  [SEARCH_FILTER_TYPES.STEP]: i18n('HelpcenterSearchResults.Step'),
};

export const mapSearchFilterForSubtitlePlural = {
  [SEARCH_FILTER_TYPES.ALL]: i18n('HelpcenterSearchResults.Results'),
  [SEARCH_FILTER_TYPES.FOLDER]: i18n('HelpcenterSearchResults.Folders'),
  [SEARCH_FILTER_TYPES.GUIDE]: i18n('HelpcenterSearchResults.Guides'),
  [SEARCH_FILTER_TYPES.STEP]: i18n('HelpcenterSearchResults.Steps'),
};

export const SEARCH_FILTERING_OPTIONS = [
  {
    icon: <SearchIcon />,
    text: mapSearchFilterName[SEARCH_FILTER_TYPES.ALL],
    type: SEARCH_FILTER_TYPES.ALL,
  },
  {
    icon: <FolderIcon />,
    text: mapSearchFilterName[SEARCH_FILTER_TYPES.FOLDER],
    type: SEARCH_FILTER_TYPES.FOLDER,
  },
  {
    icon: <GuideIcon />,
    text: mapSearchFilterName[SEARCH_FILTER_TYPES.GUIDE],
    type: SEARCH_FILTER_TYPES.GUIDE,
  },
  {
    icon: <StepIcon />,
    text: mapSearchFilterName[SEARCH_FILTER_TYPES.STEP],
    type: SEARCH_FILTER_TYPES.STEP,
  },
];

export const SEARCH_FILTERING_OPTIONS_SMART = [
  {
    icon: <ChatIcon />,
    text: mapSearchFilterName[SEARCH_FILTER_TYPES.SMART],
    type: SEARCH_FILTER_TYPES.SMART,
  },
  ...SEARCH_FILTERING_OPTIONS,
];

/* On BE is Bitwise so all combination is allowed, now we are using it only exclusive */
export const mapSearchFilterToBitWise = {
  [SEARCH_FILTER_TYPES.ALL]: 7,
  [SEARCH_FILTER_TYPES.FOLDER]: 1,
  [SEARCH_FILTER_TYPES.GUIDE]: 2,
  [SEARCH_FILTER_TYPES.STEP]: 4,
};

export const SEARCH_MAX_RESULTS = 20;

export const SEARCH_RESULT_TYPES = {
  AI: 'ai',
  GUIDE: 'guide',
  FOLDER: 'folder',
  STEP: 'step',
};
