const addGorgiasChatListeners = (onOpen, onClose) => {
  GorgiasChat.on('widget:opened', function(data) {
    if (onOpen) onOpen();
  });
  GorgiasChat.on('widget:closed', function(data) {
    if (onClose) onClose();
  });
};

export const initializeGorgiasChat = (isWidget, onContactOpen, onContactClose) => {
  setTimeout(() => {
    if (window.GorgiasChat && GorgiasChat.hasOwnProperty('on')) {
      if (!isWidget) addGorgiasChatListeners(onContactOpen, onContactClose);
    }
  }, 1000);
};
