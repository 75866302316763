import type { Communication } from '../types/dataTransmission.ts';
import { COMMUNICATION_TARGET_PREFIX } from '../types/dataTransmission';
import { SALESFORCE_DATA_TRANSMISSION_TARGET_ACTION_TYPES } from '../types/salesforce';
import { getSalesforceRecordsIdsFromQuery } from './salesforce';

export const SALESFORCE_ACTION_PREFIX = {
  CREATE: `${COMMUNICATION_TARGET_PREFIX.SALESFORCE}${SALESFORCE_DATA_TRANSMISSION_TARGET_ACTION_TYPES.CREATE}`,
  UPDATE: `${COMMUNICATION_TARGET_PREFIX.SALESFORCE}${SALESFORCE_DATA_TRANSMISSION_TARGET_ACTION_TYPES.UPDATE}-`,
  UPSERT: `${COMMUNICATION_TARGET_PREFIX.SALESFORCE}${SALESFORCE_DATA_TRANSMISSION_TARGET_ACTION_TYPES.UPSERT}-`,
};

export const getCommunicationTargetAndValue = async (
  communication?: Communication
): Promise<{ target?: string; value?: unknown; type?: string }> => {
  const target = communication?.target || '';

  if (target.startsWith(COMMUNICATION_TARGET_PREFIX.ZAPIER)) {
    return { target: target.replace(COMMUNICATION_TARGET_PREFIX.ZAPIER, ''), value: communication?.value };
  }

  // ! When it's Salesforce we need to delay the request, so the session data is saved in DynamoDB.
  if (target.startsWith(COMMUNICATION_TARGET_PREFIX.SALESFORCE)) {
    await new Promise(r => setTimeout(r, 3000));
  }

  if (target.startsWith(SALESFORCE_ACTION_PREFIX.CREATE)) {
    return { target, value: communication?.type === 'allInputsValues' ? communication?.value : '' };
  }

  if (target.startsWith(SALESFORCE_ACTION_PREFIX.UPDATE)) {
    const actionUniqueKey = target.replace(SALESFORCE_ACTION_PREFIX.UPSERT, '');
    return { target, value: getSalesforceRecordsIdsFromQuery()[actionUniqueKey] || '' };
  }

  if (target.startsWith(SALESFORCE_ACTION_PREFIX.UPSERT)) {
    return { target, value: communication?.value };
  }

  return {};
};
