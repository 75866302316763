import React from 'react';
import i18n from 'helpers/i18n';
import PadlockIconSVG from 'icons/padlock.svg';
import PropTypes from 'prop-types';
import { InternalNoteWrapper, PadlockIcon, InternalNoteContent } from './InternalNote.styles';

function InternalNote({ content, added, removed }) {
  return (
    <InternalNoteWrapper className="content-internal-note" isAdded={added} isRemoved={removed}>
      <PadlockIcon
        tooltip={i18n('Editor.InternalNoteTip')}
        positionTooltip="top-start"
        color="#ffffff"
        iconNode={PadlockIconSVG}
      />
      <InternalNoteContent text={content} />
    </InternalNoteWrapper>
  );
}

InternalNote.propTypes = {
  content: PropTypes.string,
  added: PropTypes.bool,
  removed: PropTypes.bool,
};

export default InternalNote;
