import React, { useMemo } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ThemeProvider, useTheme } from 'styled-components';
import ScrollToTop from '@playerCommon/CustomElements/ScrollToTop';
import PropTypes from 'prop-types';
import guideDataContext from '@playerCommon/Contexts/guideDataContext';
import { UserDataProvider } from '@playerCommon/Contexts/userDataContext';
import { SentDataProvider } from '@playerCommon/Contexts/sentDataContext';
import { ServerCallDataProvider } from '@playerCommon/Contexts/serverCallDataContext.js';
import { getHasIntroduction, mergeGuideStates } from 'helpers/guidePlayerHelpers.js';
import { parseBoolean } from '@playerCommon/helpers/booleanHelpers.js';
import { useAdditionalLoadedGuidesData } from '@playerCommon/Contexts/additionalGuideLoaderContext.js';
import useUserManagement from '@playerCommon/hooks/useUserManagement.js';
import { getLanguageShorthand } from '@stonlyCommons/helpers/i18n.jsx';
import { StepAttachmentsProvider } from '@playerCommon/Contexts/stepAttachmentsProvider';
import { StepInputsProvider } from './Steps/provider/StepInputsProvider.js';
import Steps from './Steps/Steps.js';
import getStepTheme from './stepsTheme';
import { getCompactStepsTheme } from './compactStepsTheme.js';

const ExplanationPlayer = ({
  guide: guideData,
  firstStep,
  stepsPath,
  mode = 'normal',
  displayProgressBar,
  borderlessEmbed,
  /** is Widget with `Light` format (WIDGET_FORMATS.LIGHT) */
  compact,
  setLanguage,
  /** WIDGET_PLACEMENTS */
  widgetPlacement,
  widgetSizeType,
  /** Images privacy */
  loadImagesFromRoot,
  agentAppGuideData,
}) => {
  // const { stepInput, communications } = explanation;
  const { additionalLoadedGuides, loadAdditionalGuide } = useAdditionalLoadedGuidesData();

  const guide = useMemo(() => mergeGuideStates(guideData, additionalLoadedGuides), [guideData, additionalLoadedGuides]);

  const currentExplanationId = useMemo(() => {
    const currentStep = guide.steps.find(step => step.stepId === stepsPath[stepsPath.length - 1]);
    return currentStep ? currentStep.guideId : null;
  }, [stepsPath, guide]);

  const { steps, guideOptions, guideInfo } = guide;

  const language = getLanguageShorthand();
  const theme = useTheme();
  const userManagement = useUserManagement();

  const firstStepId = firstStep || guideInfo.firstStepId || guideInfo.startId;

  const hasIntroduction = useMemo(
    () => getHasIntroduction(steps.find(s => s.stepId === firstStepId)),
    [firstStepId, steps]
  );

  const displayStepTitle = parseBoolean(guideOptions.displayStepTitle, true);
  const displayBackButton = parseBoolean(guideOptions.displayBackButton, true);

  const displayAds = parseBoolean(guideOptions.displayAds, false);

  const guideOrientation = guideOptions.guideOrientation ? guideOptions.guideOrientation : 'horizontal';

  const guideDataContextValue = useMemo(
    () => ({
      guide,
      steps: guide.steps,
      stepConnectionList: guide.stepConnectionList,
      guideInfo,
      access: guideInfo.access,
      guideOptions: {
        ...guide.guideOptions,
        allowComments: parseBoolean(guide.guideOptions.allowComments, false),
        allowFeedback: parseBoolean(guide.guideOptions.allowFeedback, true),
      },
      firstStepId,
      mode,
      guideEncrypted: !!guideOptions.encrypted,
      guideLinksDisabled: guideInfo.guideLinksDisabled,
      ttsEnabled: !!guideInfo.ttsEnabled,
      guideTeamKnowledgeBaseId: guideInfo.guideTeamKnowledgeBaseId,
      displayBackButton,
      contributors: guide.contributors,
      loadImagesFromRoot,
      isWidget: (mode === 'widget' || mode === 'hc-widget') && !borderlessEmbed, // borderless embed has mode widget for some reason
      displayProgressBar,
      loadAdditionalGuide,
    }),
    [guide, currentExplanationId, compact, firstStepId]
  );

  const commonStepsProps = useMemo(
    () => ({
      displayStepTitle,
      displayProgressBar,
      displayAds,
      mode,
      setLanguage,
      guideOrientation,
    }),
    [guide, currentExplanationId, compact]
  );

  const userDataContextValue = useMemo(() => guide.guideVariables || {}, [guide]);

  const predefinedLocalData = useMemo(
    () => ({
      isWidget: guideDataContextValue.isWidget ? 'true' : 'false',
      guideLanguage: language || undefined,
      stonlyGroupIds: (userManagement.user?.groups || []).map(({ groupId }) => groupId).join(',') || undefined,
      stonlyGroupNames: (userManagement.user?.groups || []).map(({ name }) => name).join(',') || undefined,
      stonlyUserEmail: userManagement.user?.email || undefined,
    }),
    [guideDataContextValue.isWidget, userManagement.user, language]
  );

  return (
    <guideDataContext.Provider value={guideDataContextValue}>
      <SentDataProvider predefinedLocalData={predefinedLocalData} agentAppGuideData={agentAppGuideData}>
        <UserDataProvider
          value={userDataContextValue}
          propertyNameList={guide.guideVariableNameList}
          fetchValuesOnMount={mode === 'normal' || mode === 'hc'}
        >
          <ServerCallDataProvider serverCallVariables={guide.serverCallVariables}>
            <StepInputsProvider>
              <StepAttachmentsProvider>
                <ThemeProvider
                  theme={{
                    ...theme,
                    ...(compact ? getCompactStepsTheme({ widgetPlacement }) : getStepTheme({ mode })),
                  }}
                >
                  <ScrollToTop>
                    <Switch>
                      <Route
                        exact
                        path="/:mode(agentApp|agentAppFront|agentAppZendeskModal|agentAppZendeskPanel|agentAppFresh)/:token/:id/"
                        render={() => <Redirect to={hasIntroduction ? 'introduction' : `${firstStep}`} />}
                      />
                      <Route
                        path="/:mode(guide|sl|embed|widget|borderlessembed)/(.*-)?:id/:view"
                        render={props => (
                          <Steps
                            {...props}
                            {...commonStepsProps}
                            stepsPath={stepsPath}
                            currentExplanationId={currentExplanationId}
                            borderlessEmbed={borderlessEmbed}
                            compact={compact}
                            widgetSizeType={widgetSizeType}
                          />
                        )}
                      />
                      <Route
                        path="/:mode(kb|kb-widget)/guide/(.*-)?:id/:view"
                        render={props => (
                          <Steps
                            {...props}
                            {...commonStepsProps}
                            stepsPath={stepsPath}
                            currentExplanationId={currentExplanationId}
                            borderlessEmbed={borderlessEmbed}
                            compact={compact}
                            widgetSizeType={widgetSizeType}
                          />
                        )}
                      />
                      <Route
                        path="/guideEditorPreview/:pathToLoad?"
                        render={props => <Steps {...props} {...commonStepsProps} stepsPath={stepsPath} />}
                      />
                      <Route
                        path="/:mode(zendesk|agentApp|agentAppFront|agentAppZendeskModal|agentAppZendeskPanel|intercom|agentAppFresh)/:token/:id/:pathToLoad?"
                        render={props => (
                          <Steps
                            {...props}
                            {...commonStepsProps}
                            stepsPath={stepsPath}
                            currentExplanationId={currentExplanationId}
                            displayStepTitle
                          />
                        )}
                      />
                    </Switch>
                  </ScrollToTop>
                </ThemeProvider>
              </StepAttachmentsProvider>
            </StepInputsProvider>
          </ServerCallDataProvider>
        </UserDataProvider>
      </SentDataProvider>
    </guideDataContext.Provider>
  );
};

ExplanationPlayer.propTypes = {
  guide: PropTypes.object,
  firstStep: PropTypes.number,
  stepsPath: PropTypes.array,
  mode: PropTypes.oneOf(['widget', 'hc', 'preview', 'hc-widget', 'normal', 'embed', 'embedModal']),
  borderlessEmbed: PropTypes.bool,
  displayProgressBar: PropTypes.bool,
  setLanguage: PropTypes.func,
  compact: PropTypes.bool,
  widgetPlacement: PropTypes.string,
  widgetSizeType: PropTypes.string,
};

export default ExplanationPlayer;
