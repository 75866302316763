import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const circleSpinner = keyframes`
  0% {
    transform: rotate(0deg);
    stroke-dashoffset: calc(0.66 * 100%);
  } 50% {
    transform: rotate(720deg);
    stroke-dashoffset: calc(3.14 * 100%);
  } 100% {
    transform: rotate(1080deg);
    stroke-dashoffset: calc(0.66 * 100%);
  }
`;

const Wrapper = styled.svg`
  width: 100%;
  height: 100%;

  circle {
    fill: transparent;
    stroke: ${({ iconColor }) => iconColor};
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-dasharray: calc(3.14 * 100%);
    transform-origin: 50% 50% 0;
    animation: ${circleSpinner} 2500ms linear infinite;
  }
`;

const Spinner = ({ iconColor }) => (
  <Wrapper iconColor={iconColor}>
    <circle cx="50%" cy="50%" r="48%" />
  </Wrapper>
);

Spinner.propTypes = {
  iconColor: PropTypes.string,
};

export default Spinner;
