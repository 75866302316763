import React, { useState, useEffect, memo, useRef } from 'react';
import styled, { css } from 'styled-components';
import isEqual from 'react-fast-compare';
import PropTypes from 'prop-types';
import IconIllustration from '@playerCommon/CustomElements/IconIllustration';
import VideoPlayer from '@playerCommon/CustomElements/VideoPlayer';

import {
  isFullscreen,
  addOnFullscreenChangeListener,
  removeOnFullscreenChangeListener,
} from '@playerCommon/helpers/windowManagement';
import Code from '../components/Code/Code.jsx';
import Image from '../components/IllustrationImage';

const CompactIllustrationWrap = styled.div`
  margin-bottom: 24px;
  @media screen and (min-width: 900px) {
    ${({ forceDisplay }) =>
      !forceDisplay &&
      css`
        display: none;
      `}
  }

  ${({ forcePointerEvents }) =>
    forcePointerEvents &&
    css`
      // this is needed for full screen video to be responsive when opened from scrolled steps
      pointer-events: all;
    `}

  @media screen and (max-width: 899px) {
    margin-top: ${props => props.theme.compactIllustrationTopMargin};
  }
`;

const Canvas = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  border-right: none;
  overflow: hidden;

  @media screen and (max-width: 899px) {
    width: 100%;
  }

  ${({ isIcon, theme }) =>
    isIcon &&
    css`
      height: ${theme.compactIllustrationIconHeight};
      padding: 0;

      @media screen and (max-width: 899px) {
        width: calc(100% - ${theme.stepsContentPaddings.mediaPadding * 2}px);
        margin: ${theme.compactIllustrationIconVerticalMargin} ${theme.stepsContentPaddings.mediaPadding}px 24px
          ${theme.stepsContentPaddings.mediaPadding}px;
        border-radius: ${theme.compactIllustrationBorderRadius};
      }
    `}

  ${({ isVideo, isCode, aspectRatioX, aspectRatioY, theme }) =>
    (isVideo || isCode) &&
    css`
      padding: 0;
      aspect-ratio: ${aspectRatioX} / ${aspectRatioY};

      @media screen and (max-width: 899px) {
        width: calc(100% - ${theme.stepsContentPaddings.mediaPadding * 2}px);
        margin: ${theme.compactIllustrationVideoCodeVerticalMargin} ${theme.stepsContentPaddings.mediaPadding}px 24px
          ${theme.stepsContentPaddings.mediaPadding}px;
        border-radius: ${theme.compactIllustrationBorderRadius};
      }
    `}

  ${({ guideOrientation, theme }) =>
    guideOrientation === 'vertical' &&
    css`
      @media screen and (min-width: 900px) {
        border-radius: ${theme.compactIllustrationBorderRadius};
      }
    `}
`;

const IconIllustrationPositioned = styled(IconIllustration)`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`;

const VideoPlayerWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.paleGrey};
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const VideoPlayerStyled = styled(VideoPlayer)`
  @media screen and (max-width: 899px) {
    padding: 0;
    height: 100%;
  }
`;

function CompactIllustration({
  stepIllustrations,
  illustrationType,
  mode,
  encrypted,
  active,
  scrollable,
  guideOrientation,
  loadImagesFromRoot,
}) {
  const wrapRef = useRef();
  const isImage = illustrationType === 'image';
  const isCode = illustrationType === 'code';
  const isIcon = illustrationType === 'icon';
  const isVideo = illustrationType === 'video';
  const isNone = illustrationType === 'none';
  const isAdded = stepIllustrations.length === 1 && stepIllustrations[0]?.added;
  const isRemoved = stepIllustrations.length === 1 && stepIllustrations[0]?.removed;
  const isModified = stepIllustrations.length === 1 && stepIllustrations[0]?.modified;

  // Autoplay should be enabled only when guide orientation is vertical.
  // Otherwise, when guide orientation is horizontal, the video will be visually hidden
  // but will be playing due to enabled autoplay
  const isVideoAutoplayEnabled = stepIllustrations[0]?.autoplay && !scrollable && guideOrientation === 'vertical';

  const [isBrowserFullscreen, setIsBrowserFullscreen] = useState(false);
  useEffect(() => {
    function fullscreenChangeHandler(e) {
      // if fullscreen request was fired from within compact illustration (only video player will match)
      if (wrapRef.current?.contains(e.target)) {
        setIsBrowserFullscreen(isFullscreen());
      }
    }
    addOnFullscreenChangeListener(fullscreenChangeHandler);
    return () => removeOnFullscreenChangeListener(fullscreenChangeHandler);
  }, []);

  if (isNone) return null;

  const forceDisplay = guideOrientation === 'vertical' || isBrowserFullscreen;

  return (
    <CompactIllustrationWrap forceDisplay={forceDisplay} forcePointerEvents={isBrowserFullscreen} ref={wrapRef}>
      <Canvas
        illustrationType={illustrationType}
        active={active}
        isIcon={isIcon}
        isVideo={isVideo}
        isCode={isCode}
        aspectRatioX={(stepIllustrations[0]?.aspectRatio || '16:9').split(':')[0]}
        aspectRatioY={(stepIllustrations[0]?.aspectRatio || '16:9').split(':')[1]}
        guideOrientation={guideOrientation}
        mode={mode}
      >
        {isImage && stepIllustrations.length > 0 && (
          <Image
            stepIllustrations={stepIllustrations}
            added={isAdded}
            removed={isRemoved}
            modified={isModified}
            encrypted={encrypted}
            guideOrientation={guideOrientation}
            loadImagesFromRoot={loadImagesFromRoot}
            mode={mode}
          />
        )}
        {isCode && (
          <Code
            stepIllustration={stepIllustrations[0]}
            added={isAdded}
            removed={isRemoved}
            modified={isModified}
            showBg
          />
        )}
        {isIcon && (
          <IconIllustrationPositioned
            {...stepIllustrations[0]}
            added={isAdded}
            removed={isRemoved}
            modified={isModified}
            size="fill"
          />
        )}
        {isVideo && (
          <VideoPlayerWrap>
            <VideoPlayerStyled
              {...stepIllustrations[0]}
              autoplay={isVideoAutoplayEnabled}
              added={isAdded}
              removed={isRemoved}
              modified={isModified}
            />
          </VideoPlayerWrap>
        )}
      </Canvas>
    </CompactIllustrationWrap>
  );
}

CompactIllustration.propTypes = {
  stepIllustrations: PropTypes.array,
  illustrationType: PropTypes.string,
  mode: PropTypes.string,
  encrypted: PropTypes.bool,
  parentIsFullscreen: PropTypes.bool,
  openParentFullscreen: PropTypes.func,
  closeParentFullscreen: PropTypes.func,
  active: PropTypes.bool,
  scrollable: PropTypes.bool,
  guideOrientation: PropTypes.string,
  loadImagesFromRoot: PropTypes.bool,
};

export default memo(CompactIllustration, isEqual);
