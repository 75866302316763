import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes, css } from 'styled-components';
import ChecklistItemSVG from 'icons/checklistItem.svg';

const rotateKeyframes = keyframes`
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 360deg);
  }
`;

const CircleAnimated = styled.svg`
  animation-duration: 1s;
  animation-iteration-count: infinite;
  transform-origin: center;
  animation-name: ${rotateKeyframes};

  circle {
    stroke-dasharray: 50;
    stroke-width: 1.5px;
    stroke: ${props => props.theme.grey};
  }
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  position: relative;

  .hidden-checkbox:focus {
    outline: none;
  }

  .hidden-checkbox:focus-visible ~ .checklist-icon {
    box-shadow: 0px 0px 0px 2px ${props => props.theme.white}, 0px 0px 0px 4px ${props => props.theme.darkBlue};
  }

  .checklist-icon,
  .checklist-loading-icon {
    transition: opacity 0.4s;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }

  ${({ isLoading }) =>
    isLoading &&
    css`
      .checklist-icon {
        opacity: 0;
      }
    `}
  ${({ isLoading }) =>
    !isLoading &&
    css`
      .checklist-loading-icon {
        opacity: 0;
        animation-name: unset;
      }
      .checklist-icon {
        opacity: 1;
      }
    `};
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const ChecklistItemIcon = ({ id, isLoading, onClick, onKeyDown, tabIndex, className, isChecked, isDisabled }) => (
  <IconWrapper
    isLoading={isLoading}
    className={[className, 'checklist-icon-wrapper'].join(' ')}
    onClick={onClick}
    onKeyDown={onKeyDown}
  >
    <HiddenCheckbox
      className="hidden-checkbox"
      id={id}
      checked={isChecked}
      tabIndex={tabIndex}
      disabled={isDisabled}
      aria-checked={isChecked === undefined ? 'undefined' : isChecked}
      required
    />
    <CircleAnimated
      aria-hidden
      className="checklist-loading-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <circle strokeLinecap="round" fill="transparent" r="11" cx="12" cy="12" />
    </CircleAnimated>
    <ChecklistItemSVG aria-hidden className="checklist-icon" />
  </IconWrapper>
);

ChecklistItemIcon.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

export default ChecklistItemIcon;
