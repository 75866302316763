/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import ExplanationHelmet from '@playerCommon/CustomElements/ExplanationHelmet';
import { loadExplanation } from 'helpers/explanationManagement.js';
import slugify from 'slugifyjs';
import callApi from 'helpers/apiHelpers';
import i18n, { getLanguageShorthand, shorthandToCodeLanguageMap } from 'helpers/i18n';
import { getGlobal, setGlobal } from 'global/windowUtils';
import { RIGHT } from 'global';

import { getStepContent, getIntroductionContent, parseGuideData, getRedirectUrl } from 'helpers/guidePlayerHelpers';
import { convertHtmlToMetaTagDescripton } from 'helpers/sanitizeHelpers';

const Slugify = slugify.fromLocale('en');

function withExplanationData(WrappedComponent) {
  return class extends Component {
    static async getInitialProps({ req, match, location }) {
      const languages = getGlobal('languages');
      const userAgent = getGlobal('userAgent');

      const returnable = { languages, userAgent };

      if (req === undefined) {
        return returnable;
      }

      if (match.params.language) {
        const siteLanguage = match.params.language;
        setGlobal('siteLanguage', siteLanguage);
        req.i18n.changeLanguage(shorthandToCodeLanguageMap[siteLanguage] || siteLanguage);
      }

      const currentLanguage = getLanguageShorthand();
      const parsedLocation = req._parsedUrl;

      const statusRes = await callApi('v1/auth/status', 'get');
      returnable.csrfToken = statusRes.data.csrfToken;

      if (statusRes.data.logged) {
        returnable.user = statusRes.data.user;
      }

      let loadedGuide;
      try {
        const { id, view, mode } = match.params;
        const {
          widgetId,
          segmentAnonymousId,
          segmentUserId,
          stonlyAnonymousId,
          customerUserId,
          isSnoozingEnabled,
          widgetRuleId,
        } = queryString.parse(parsedLocation.search);
        loadedGuide = await loadExplanation(id, {
          isPreview: view === 'PreviewSteps',
          isShortlink: parsedLocation.pathname.slice(1, 3) === 'sl',
          withKnowledgeBase: mode === 'guide',
          widgetId,
          segmentAnonymousId,
          segmentUserId,
          stonlyAnonymousId,
          customerUserId,
          isSnoozingEnabled: isSnoozingEnabled === '1',
          widgetRuleId,
        });
        loadedGuide = parseGuideData(loadedGuide, currentLanguage);

        // temporary solution for lack of 403
        if (loadedGuide.guideInfo.access === RIGHT.NONE) {
          throw { message: '', status: 403 };
        }
      } catch (e) {
        console.log(e);
        if (e.status === 404 || e.status === 400) {
          return { statusCode: 404, ...returnable };
        }
        if (e.status === 403 || e.status === 401) {
          return { statusCode: 403, ...returnable };
        }
        if (e.status === 500) {
          return { statusCode: 500, ...returnable };
        }
      }

      const redirectUrl = getRedirectUrl({ urlParams: match.params, urlSearch: parsedLocation.search, loadedGuide });

      const { guideId, title, startId } = loadedGuide.guideInfo;
      const titleSlug = Slugify.parse(title);
      const host = req.headers['x-forwarded-host'] || req.headers.host;
      const ogURL = `https://${host}/guide/${match.params.language}/${titleSlug}-${guideId}/Steps/${startId}`;

      returnable.customCSS = loadedGuide.guideOptions?.designSettings?.customHtml;

      if (redirectUrl) {
        return { statusCode: 301, redirectTo: redirectUrl };
      }

      return { loadedGuide, ogURL, ...returnable };
    }

    render() {
      const { loadedGuide, ogURL, statusCode, match, customCSS } = this.props;
      if (match && match.params.language) {
        setGlobal('siteLanguage', match.params.language);
      }
      const currentLanguage = getLanguageShorthand();

      let titleToDisplay;
      let description;
      let url;
      let lang;
      let team;
      let noIndex;
      let isPrivate;

      // console.log(loadedGuide);

      if (loadedGuide && loadedGuide.guideInfo.access !== RIGHT.NONE) {
        const { title, teamId, options } = loadedGuide.guideInfo;
        const firstStep = loadedGuide.steps.find(s => s.stepId === loadedGuide.guideInfo.startId);
        const firstStepContent = getStepContent(firstStep, currentLanguage, options.defaultLanguage);
        const introductionContent = getIntroductionContent(firstStep, currentLanguage, options.defaultLanguage);

        team = teamId;
        titleToDisplay = title;
        url = ogURL;
        lang = loadedGuide.guideOptions.languageList.includes(currentLanguage)
          ? currentLanguage
          : loadedGuide.guideOptions.defaultLanguage;
        noIndex = loadedGuide.guideOptions.noIndex === undefined ? true : loadedGuide.guideOptions.noIndex;
        isPrivate = team === 2 ? false : noIndex;

        // SEO title and description
        const { titleTag, metaDescription } = options;

        titleToDisplay = titleTag[lang] || titleToDisplay;

        description = metaDescription[lang]
          ? metaDescription[lang]
          : convertHtmlToMetaTagDescripton(introductionContent || firstStepContent);
      } else {
        titleToDisplay = i18n('RestrictedAccess.RestrictedAccessShort');
        description = i18n('RestrictedAccess.ViewerRestrictedAccess');
      }
      if (statusCode === 500) {
        return <div>Server error, please try again in a few minutes</div>;
      }

      return (
        <>
          <ExplanationHelmet
            title={titleToDisplay}
            description={description}
            url={url}
            canonicalUrl={url}
            lang={lang}
            isPrivate={isPrivate}
            customCSS={customCSS}
          />
          <WrappedComponent {...this.props} />
        </>
      );
    }

    // eslint-disable-next-line react/static-property-placement
    static propTypes = {
      loadedGuide: PropTypes.object,
      ogURL: PropTypes.string,
      statusCode: PropTypes.number,
      match: PropTypes.object,
      customCSS: PropTypes.string,
    };
  };
}

export default withExplanationData;
