import DOMPurify from 'dompurify';

const isObject = obj => obj && typeof obj === 'object';

const isEmptyObject = obj => isObject(obj) && Object.keys(obj).length === 0;

const hasUnsupportedKeys = (obj, supportedKeys = []) => {
  if (!isObject(obj) || isEmptyObject(obj)) {
    return false;
  }
  const unsupportedKeys = Object.keys(obj).filter(key => !supportedKeys.includes(key));
  return !!unsupportedKeys.length;
};

const isPrimitive = val => {
  if (val === null) {
    return true;
  }

  if (typeof val === 'object' || typeof val === 'function') {
    return false;
  }

  return true;
};

export const validateGuideData = data => {
  if (!isObject(data)) {
    return {
      isValid: false,
      message: 'Stonly: Data should be passed as an object',
    };
  }

  if (isEmptyObject(data)) {
    return {
      isValid: false,
      message: "Stonly: Data object can't be empty",
    };
  }

  try {
    // check if data object contains circular dependencies
    JSON.stringify(data);
  } catch {
    return {
      isValid: false,
      message: 'Stonly: Invalid data format',
    };
  }

  if (isObject(data)) {
    const guideDataValues = Object.values(data);
    if (!guideDataValues.every(isPrimitive)) {
      return {
        isValid: false,
        message: "Stonly: Non-primitive values can't be passed to the object.",
      };
    }
  }

  return {
    isValid: true,
  };
};

export const validateContactFormData = data => {
  if (!isObject(data)) {
    return {
      isValid: false,
      message: 'Stonly: Data should be passed as an object',
    };
  }

  if (isEmptyObject(data)) {
    return {
      isValid: false,
      message: "Stonly: Data object can't be empty",
    };
  }

  try {
    // check if data object contains circular dependencies
    JSON.stringify(data);
  } catch {
    return {
      isValid: false,
      message: 'Stonly: Invalid data format',
    };
  }

  if (isObject(data)) {
    const supportedKeys = ['email', 'additionalInfo'];

    if (hasUnsupportedKeys(data, supportedKeys)) {
      const keysString = supportedKeys.join(', ');
      return {
        isValid: false,
        message: `Stonly: "contactForm" object contains unsupported keys. Supported keys are ${keysString}`,
      };
    }
  }

  return {
    isValid: true,
  };
};

const sanitizeString = (value = '') =>
  typeof value === 'string' ? DOMPurify.sanitize(value, { FORBID_TAGS: ['style', 'a'] }) : value;

const sanitizeObject = (obj = {}) => {
  return JSON.parse(
    JSON.stringify(obj, (key, val) => {
      if (typeof val === 'string') {
        return sanitizeString(val);
      }
      if (val === undefined) {
        return null;
      }
      return val;
    })
  );
};

export const sanitizeSentData = data => sanitizeObject(data);
