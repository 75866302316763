import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import scriptLoader from 'react-async-script-loader';
import AsyncScriptLoader from 'helpers/loadingScript.js';
import PropTypes from 'prop-types';
import './codeMirror.css';
import i18n from 'helpers/i18n';
import { getCustomBgScrollStyles } from '@playerCommon/CommonStyledComponents/CustomScrollbar';

const Canvas = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
  background: transparent;
  transition: background-color 0.2s;
  padding-bottom: 1px;
  ${props => getCustomBgScrollStyles(props.theme.darkGrey, props.theme.slateGrey, props.theme.steel)}

  @media screen and (min-width: 900px) {
    padding: 40px;
  }

  ${({ showBg, theme }) =>
    showBg &&
    css`
      background: ${theme.darkGrey};
      .CodeMirror-gutters {
        background: ${theme.darkGrey} !important;
      }
    `}

  ${({ added, removed, modified }) =>
    (added || removed || modified) &&
    css`
      padding-top: 24px;
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
        z-index: 10;
        border: 4px solid ${props => props.theme.changesModifiedColorSolid};
      }

      &:before {
        content: '';
        display: flex;
        position: absolute;
        left: 0px;
        top: 0px;
        height: 24px;
        padding: 0 8px;
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        align-items: center;
        color: ${props => props.theme.white};
        background-color: ${props => props.theme.changesModifiedColorSolid};
        border-bottom-right-radius: 4px;
        z-index: 11;
      }
    `}

  ${({ added, removed, modified }) => {
    if (added) {
      return css`
        &:after {
          border-color: ${props => props.theme.changesAddedColorSolid};
        }
        &:before {
          content: '${i18n('Versions.CodeAdded')}';
          background-color: ${props => props.theme.changesAddedColorSolid};
        }
      `;
    }
    if (removed) {
      return css`
        &:after {
          border-color: ${props => props.theme.changesRemovedColorSolid};
        }
        &:before {
          content: '${i18n('Versions.CodeRemoved')}';
          background-color: ${props => props.theme.changesRemovedColorSolid};
        }
      `;
    }
    if (modified) {
      return css`
        &:after {
          border-color: ${props => props.theme.changesModifiedColorSolid};
        }
        &:before {
          content: '${i18n('Versions.CodeModified')}';
          background-color: ${props => props.theme.changesModifiedColorSolid};
        }
      `;
    }
  }}

  .CodeMirror {
    height: auto;
    margin-top: auto;
    margin-bottom: auto;
  }

  .CodeMirror-lines {
    padding: 40px 0;
  }

  .CodeMirror-gutters {
    transition: background-color 0.2s;
    ${({ showGutterBg, theme }) =>
      showGutterBg &&
      css`
        background: ${theme.darkGrey} !important;
      `}
  }
`;

class Code extends Component {
  codeCanvas = {};

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed, stepIllustration } = this.props;
    const { mode } = stepIllustration;

    if (isScriptLoaded && isScriptLoadSucceed) {
      this.loadEditor(mode);
    }
  }

  async componentDidUpdate(prevProps) {
    const { isScriptLoaded, isScriptLoadSucceed, stepIllustration } = this.props;

    if (!prevProps.isScriptLoaded && isScriptLoaded) {
      // load finished
      if (isScriptLoadSucceed) {
        this.loadEditor(stepIllustration.mode);
      } else prevProps.onError();
    }

    if (isScriptLoaded && prevProps.stepIllustration.content !== stepIllustration.content) {
      const currentEditorInstance = this.codeCanvas.lastChild.CodeMirror;
      currentEditorInstance.setValue(stepIllustration.content);
    }

    if (isScriptLoaded && prevProps.stepIllustration.mode !== stepIllustration.mode) {
      await this.loadLanguage(stepIllustration.mode);
    }
  }

  async loadLanguage(mode) {
    let modeToUse;
    switch (mode) {
      case 'text/x-csrc':
      case 'text/x-c++src':
      case 'text/x-csharp':
      case 'text/x-java':
      case 'text/x-objectivec':
      case 'text/x-scala':
      case 'text/x-squirrel': {
        modeToUse = 'clike';
        break;
      }
      case 'text/x-ocaml':
      case 'text/x-fsharp': {
        modeToUse = 'cmllike';
        break;
      }
      default: {
        modeToUse = mode;
      }
    }
    const loader = new AsyncScriptLoader({
      src: `https://cdnjs.cloudflare.com/ajax/libs/codemirror/5.40.0/mode/${modeToUse}/${modeToUse}.min.js`,
      global: 'CodeMirror',
    });
    await loader.load();
  }

  loadEditor = async mode => {
    const { stepIllustration } = this.props;

    if (mode === 'htmlmixed') {
      await Promise.all([this.loadLanguage('xml'), this.loadLanguage('css'), this.loadLanguage('javascript')]);
    }

    await this.loadLanguage(mode);

    // eslint-disable-next-line new-cap
    window.CodeMirror(this.codeCanvas, {
      value: stepIllustration.content,
      theme: 'stonly',
      readOnly: true,
      viewportMargin: Number.POSITIVE_INFINITY,
      mode,
      lineNumbers: true,
      tabindex: -1,
    });
  };

  render() {
    const { added, removed, modified, showBg } = this.props;

    return (
      <Canvas
        ref={e => {
          this.codeCanvas = e;
        }}
        added={added}
        removed={removed}
        modified={modified}
        showBg={showBg}
      />
    );
  }
}
Code.propTypes = {
  stepIllustration: PropTypes.object,
  editorHeight: PropTypes.number,
  isScriptLoaded: PropTypes.bool,
  isScriptLoadSucceed: PropTypes.bool,
  onError: PropTypes.func,
  added: PropTypes.bool,
  removed: PropTypes.bool,
  modified: PropTypes.bool,
  showBg: PropTypes.bool,
};

export default scriptLoader(['https://cdnjs.cloudflare.com/ajax/libs/codemirror/5.40.0/codemirror.min.js'])(Code);
