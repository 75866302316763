import React from 'react';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { secondsToMinutesAndSeconds } from 'helpers/dateManagement.js';
import { removeTags } from 'helpers/textManagement.js';
import Icon from '@playerCommon/StandardElements/Icon';
import TextLink from '@playerCommon/StandardElements/TextLink';
import ArrowUpSVG from 'icons/arrowUp.svg';
import ArrowDownSVG from 'icons/arrowDown.svg';
import { assetsUrl } from 'global/env';
import ChatBubbleSVG from 'icons/chat-bubble-16.svg';
import { useTranslation } from 'react-i18next';

const Canvas = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const LineContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Circle = styled.div`
  height: 12px;
  width: 12px;
  background-color: ${({ circleType, theme, order }) => {
    if (order === 'first') return theme.errorColor;
    if (circleType === 'backward') return theme.steel;
    if (circleType === 'forward') return theme.seaweed;
  }};
  border-radius: 50%;
`;

const ArrowUp = styled(ArrowUpSVG)`
  pointer-events: none;

  width: 12px;
  height: 12px;
  margin-bottom: 4px;
`;

const ArrowDown = styled(ArrowDownSVG)`
  pointer-events: none;
  margin-bottom: 4px;
  width: 12px;
  height: 12px;
`;

const Main = styled.div`
  width: 100%;
  display: flex;
  color: ${props => props.theme.darkGrey};
  padding-left: 8px;
`;

const Title = styled(TextLink)`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.33;
  margin-top: -4px;
`;

const Content = styled.div`
  font-size: 12px;
  white-space: wrap;
  max-width: 100%;
  color: ${({ type, theme }) => (type === 'choice' ? theme.coral : theme.slateGrey)};
`;

const ContentInput = styled.div`
  font-size: 12px;
  white-space: wrap;
  max-width: 100%;
  color: ${props => props.theme.peach};
  margin-left: 10px;
`;

const Info = styled.div``;

const Timing = styled.div`
  margin-left: auto;
  display: flex;
  padding-top: 2px;
`;
const Time = styled.div`
  margin-left: 4px;
  font-size: 12px;
  color: ${props => props.theme.lightGrey};
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  gap: 4px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const AiLabel = styled.div`
  display: flex;
  padding: 2px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.steel};
  color: ${props => props.theme.white};
  font-size: 10px;
  font-weight: 700;
  line-height: 12px; /* 120% */
  text-transform: uppercase;
`;

const AiConversationLink = styled(TextLink)`
  text-decoration: underline;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: ${props => props.theme.slateGrey};
`;
const ChatBubbleIcon = styled(ChatBubbleSVG)`
  path {
    fill: ${props => props.theme.slateGrey};
  }
`;

const Step = ({
  title,
  timing,
  type,
  content,
  inputToShow,
  order,
  guideId,
  stepPathForUrl,
  goToPlayer,
  view,
  circleType,
  theme,
  hasAiSearchInteraction,
  aiQuestionLength = 0,
  selectStep,
}) => {
  const { t } = useTranslation();

  const getTotalNumberOfQuestions = () => {
    return `${aiQuestionLength} ${t(
      aiQuestionLength === 1 ? 'SessionDetail.SingleQuestion' : 'SessionDetail.MultipleQuestions'
    )}`;
  };

  return (
    <Canvas>
      <LineContainer>
        <Circle order={order} circleType={circleType}>
          {circleType === 'forward' && order !== 'first' && <ArrowUp />}
          {circleType === 'backward' && order !== 'first' && <ArrowDown />}
        </Circle>
      </LineContainer>
      <Main>
        <Info>
          <Title
            title={title}
            ellipsis
            onClick={() => {
              goToPlayer(guideId, stepPathForUrl, view);
            }}
          >
            <TitleWrapper>
              {hasAiSearchInteraction && <AiLabel>AI</AiLabel>}
              {title}
            </TitleWrapper>
          </Title>
          {inputToShow && (
            <ContentWrapper>
              <Icon size={14} color={theme.peach} src={`${assetsUrl}resources/icons/pencil.svg`} />
              <ContentInput>{inputToShow}</ContentInput>
            </ContentWrapper>
          )}
          {!!aiQuestionLength && (
            <ContentWrapper>
              <ChatBubbleIcon />
              {selectStep ? (
                <AiConversationLink onClick={selectStep}>{getTotalNumberOfQuestions()}</AiConversationLink>
              ) : (
                <Content>{getTotalNumberOfQuestions()}</Content>
              )}
            </ContentWrapper>
          )}
          <ContentWrapper>
            <Icon
              color={type === 'choice' ? theme.coral : theme.steel}
              src={`${assetsUrl}resources/icons/multipleChoice-16.svg`}
            />
            <Content title={removeTags(content)} type={type}>
              {removeTags(content)}
            </Content>
          </ContentWrapper>
        </Info>
        <Timing>
          <Icon src={`${assetsUrl}resources/icons/timerSmall.svg`} size={4} color={theme.lightGrey} />
          <Time>{secondsToMinutesAndSeconds(timing || 0)}</Time>
        </Timing>
      </Main>
    </Canvas>
  );
};
Step.propTypes = {
  title: PropTypes.string,
  timing: PropTypes.number,
  inputToShow: PropTypes.string,
  type: PropTypes.string,
  order: PropTypes.string,
  content: PropTypes.string,
  guideId: PropTypes.string,
  stepPathForUrl: PropTypes.string,
  goToPlayer: PropTypes.func,
  view: PropTypes.string,
  circleType: PropTypes.string,
  theme: PropTypes.object,
  selectStep: PropTypes.func,
  aiQuestionLength: PropTypes.number,
  hasAiSearchInteraction: PropTypes.bool,
};

export default withTheme(Step);
