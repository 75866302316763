/* eslint-disable no-loop-func */
/* eslint-disable no-await-in-loop */
import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import i18n, { getLanguageShorthand } from 'helpers/i18n';
import callApi from 'helpers/apiHelpers.js';
import { getChoicesPrettyPrint } from 'helpers/explanationManagement.js';
import { postMessage } from 'helpers/widgetHelpers.js';
import withUser from '@playerCommon/HOC/withUser';
import Loader from '@playerCommon/CustomElements/Loader';
import { getIntroductionContent, getStepContent, getStepTitle, parseGuideData } from 'helpers/guidePlayerHelpers.js';
import MainView from './MainView/MainView.js';
import StepView from './StepView/StepView.js';
import KbMainView from './KbMainView/KbMainView.js';
import ConversationView from './ConversationView/ConversationView.js';

const Canvas = styled.div`
  width: ${({ width }) => width};
  overflow: hidden;
`;

const SessionInstanceCanvas = styled.div`
  width: 100%;
  ${({ mode, theme }) =>
    mode !== 'standalone' &&
    css`
      box-shadow: 0 2px 4px 0 ${theme.canvasBox}, 0 0 2px 0 rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      width: 95%;
      padding: 16px;
      margin: 8px 2.5% 40px 2.5%;
    `}
  ${({ mode }) =>
    mode === 'standalone' &&
    css`
      &:not(:first-child) {
        margin-top: 40px;
      }
    `}
  display: ${({ show }) => (show ? 'block' : 'none')};

  ${({ context }) =>
    context === 'zendeskApp' &&
    css`
      box-shadow: none;
      border: 1px solid ${props => props.theme.silver};
      width: 100%;
      margin: 8px 0 16px 0;
    `}
`;

// eslint-disable-next-line max-len
const agentAppGuideMatcher =
  /\/(agentapp|agentappzendeskmodal|agentappzendeskpanel)\/(.+)\/(guides)\/(.+)\/(.+)\/(.+)\/(.+)\/.*?([^/-]+)\/([\d,-]+)*/i;

function SessionPlayer({
  sessionId,
  conversationId,
  userManagement,
  ticketId,
  context,
  width = '320px',
  mode,
  updateParentContainer,
}) {
  const [sessionDataList, setSessionDataList] = useState([]);
  const [view, setViewValue] = useState('');
  const [previousView, setPreviousView] = useState('');

  useEffect(() => {
    const language = getLanguageShorthand();
    if (Object.keys(userManagement.user).length === 0) {
      setViewValue('notLoggedIn');
      return;
    }

    async function fetchData() {
      const isUserSession = ['userSession', 'zendeskApp', 'intercom'].includes(context);

      try {
        const { data: sessionsData } = await callApi(`v1/stat/plugin/guide?sessionId=${sessionId}`, 'get');
        let dataSent = false;

        const sourceIds = Object.keys(sessionsData);

        // eslint-disable-next-line no-restricted-syntax
        for (const sourceId of sourceIds) {
          const aiConversation = sessionsData[sourceId]?.aiConversation;
          // check if it's a guide or kb
          if (aiConversation) {
            const processedAiConversation = conversationId
              ? aiConversation.filter(interaction => interaction.conversationId === conversationId)
              : aiConversation;
            if (processedAiConversation.length) {
              setSessionDataList(currentSessionDataList => [
                ...currentSessionDataList,
                {
                  sourceId,
                  aiConversation: processedAiConversation,
                  title: sessionsData[sourceId].knowledgeBaseName,
                  isKb: true,
                },
              ]);
            }
          } else {
            // find the steps that are related to this ticket && that matches the user History
            const foundSteps = ticketId
              ? sessionsData[sourceId].stepPath.filter(
                  ({ uri }) =>
                    uri.match(agentAppGuideMatcher) && String(uri.match(agentAppGuideMatcher)[5]) === String(ticketId)
                )
              : sessionsData[sourceId].stepPath;

            if (foundSteps.length > 0 || isUserSession) {
              // Load the container that is related
              try {
                const guideRes = await callApi(`v1/guide?guideId=${sourceId}&context=view`, 'get');
                const guideData = parseGuideData(guideRes.data);
                const { defaultLanguage } = guideData.guideOptions;

                const sessionDataTransmissions = [];

                foundSteps.forEach(step => {
                  if (step.dataTransmission.length > 0) {
                    if (!dataSent) {
                      sessionDataTransmissions.push({
                        source: 'stonlyDataTransmissionSession',
                        type: 'tag',
                        value: 'stonly-prepopulated',
                        target: 'setField - Tags',
                      });
                      dataSent = true;
                    }

                    step.dataTransmission.forEach(transmission => {
                      sessionDataTransmissions.push({
                        source: 'stonlyDataTransmissionSession',
                        type: transmission.type,
                        value: (() => {
                          if (transmission.type === 'inputValue') return step.stepInputValue;
                          if (transmission.type === 'allInputsValues') return step.stepInputValues;
                          if (transmission.type === 'customText') return transmission.value;
                          if (transmission.type === 'choiceLabel') return step.choiceLabel;
                          if (transmission.type === 'choiceStaticValue') return step.choiceStaticValue;
                          if (transmission.type === 'stepTitle') return transmission.title;
                          return transmission.value;
                        })(),
                        target: transmission.target,
                      });
                    });
                  }
                });

                const stepsDetails = foundSteps.map((step, index) => {
                  const stepToShow = guideData.steps.find(stt => stt.stepId === step.stepId);

                  if (!stepToShow) {
                    const firstStep = guideData.steps.find(s => s.stepId === guideData.guideInfo.startId);

                    return {
                      stepId: -2,
                      title: 'Introduction',
                      content: getIntroductionContent(firstStep, language, defaultLanguage),
                      timing: step.timing,
                      link: step.uri,
                    };
                  }
                  const aiSearchInteractionList = conversationId
                    ? step.aiSearchInteractions?.filter(interaction => interaction.conversationId === conversationId)
                    : step.aiSearchInteractions;

                  const allAiSearchInteractionList = foundSteps
                    .flatMap(foundStep => {
                      if (foundStep.stepId !== step.stepId) return null;
                      return conversationId
                        ? foundStep.aiSearchInteractions?.filter(
                            interaction => interaction.conversationId === conversationId
                          )
                        : foundStep.aiSearchInteractions;
                    })
                    .filter(Boolean);

                  const nextStep = sessionsData[sourceId].stepPath[index + 1];
                  let choiceToDisplay;
                  if (nextStep) {
                    const nextStepRows = guideData.stepConnectionList.filter(n => n.fromStepId === stepToShow.stepId);
                    choiceToDisplay =
                      nextStepRows.length > 1 ? getChoicesPrettyPrint(nextStepRows, nextStep.stepId) : '';
                  } else {
                    choiceToDisplay = '';
                  }

                  return {
                    stepId: stepToShow.stepId,
                    title: getStepTitle(stepToShow, language, defaultLanguage),
                    content: getStepContent(stepToShow, language, defaultLanguage),
                    timing: step.timing,
                    input: step.stepInputValue,
                    inputs: step.stepInputValues,
                    choiceLabel: step.choiceLabel,
                    choiceStaticValue: step.choiceStaticValue,
                    link: step.uri,
                    choice: choiceToDisplay,
                    type: stepToShow.type,
                    aiSearchInteractionList,
                    allAiSearchInteractionList:
                      !allAiSearchInteractionList.length ||
                      aiSearchInteractionList?.length === allAiSearchInteractionList.length
                        ? undefined
                        : allAiSearchInteractionList,
                  };
                });

                if (sessionDataTransmissions.length > 0) {
                  postMessage({
                    source: 'stonlyDataTransmissionSessionArray',
                    value: sessionDataTransmissions,
                  });
                }

                const stepsDetailsToShow = stepsDetails.filter(step => step.type !== 'explanation');
                const stepPathToUse = sessionsData[sourceId].stepPath.map(step => step.stepId || -2);

                if (stepsDetailsToShow[0]?.stepId === -2 && stepsDetailsToShow[1]?.stepId === -2) {
                  stepsDetailsToShow.shift();
                  stepPathToUse.shift();
                }

                setSessionDataList(currentSessionDataList => [
                  ...currentSessionDataList,
                  {
                    sourceId,
                    stepPath: stepPathToUse,
                    explanationTitle: guideData.guideInfo.title,
                    steps: stepsDetailsToShow,
                  },
                ]);
              } catch (e) {
                console.log('e', e);
              }
            } else {
              // what the actual fuck
              setViewValue(true);
            }
          }
        }
        setViewValue('mainView');
      } catch (e) {
        console.log('e', e);
        setViewValue('noData');
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    updateParentContainer();
  }, [view]);

  const setView = (newView = '', newPreviousView = '') => {
    const updatedView = previousView || newView;
    setViewValue(updatedView);
    setPreviousView(newPreviousView);
  };

  const goToPlayer = useCallback((guideId, path) => {
    window.open(`https://stonly.com/guide/${guideId}/Steps/${path}`, '_blank');
  }, []);

  if (!view) {
    return <Loader text={i18n('Global.Loading')} />;
  }

  if (view === 'notLoggedIn') {
    return i18n('SessionPlayer.LoginRequest');
  }

  if (sessionDataList.length === 0) {
    return i18n('SessionPlayer.NoData');
  }

  return (
    <Canvas width={width}>
      {sessionDataList.map(sessionData => (
        <SessionInstanceCanvas
          key={sessionData.sourceId}
          context={context}
          mode={mode}
          show={
            view.includes('mainView') ||
            view === `stepView-${sessionData.sourceId}` ||
            view === `playerView-${sessionData.sourceId}` ||
            view === `conversationView-${sessionData.sourceId}`
          }
        >
          {view.includes('mainView') &&
            (sessionData.isKb ? (
              <KbMainView sessionData={sessionData} setView={setView} />
            ) : (
              <MainView guide={sessionData} setView={setView} goToPlayer={goToPlayer} view={view} />
            ))}
          {view === `stepView-${sessionData.sourceId}` && (
            <StepView
              steps={sessionData.steps}
              stepPath={sessionData.stepPath}
              setView={setView}
              view={view}
              guideId={sessionData.sourceId}
              goToPlayer={goToPlayer}
              aiSearchInteractions={sessionData.aiSearchInteractions}
            />
          )}
          {view === `conversationView-${sessionData.sourceId}` && (
            <ConversationView aiSearchInteractionList={sessionData.aiConversation} setView={setView} />
          )}
        </SessionInstanceCanvas>
      ))}
    </Canvas>
  );
}

SessionPlayer.propTypes = {
  userManagement: PropTypes.object,
  width: PropTypes.string,
  sessionId: PropTypes.string,
  id: PropTypes.string,
  pathToLoad: PropTypes.string,
  history: PropTypes.object,
  updateParentContainer: PropTypes.func,
  ticketId: PropTypes.string,
  context: PropTypes.string,
  referrerUrl: PropTypes.string,
  mode: PropTypes.string,
};

export default withUser(SessionPlayer);
