import React, { useCallback, useMemo, useState } from 'react';
import i18n from 'helpers/i18n';
import PropTypes from 'prop-types';
import TableOfContentsSVG from 'icons/tableOfContents.svg';
import ChevronLeftSVG from 'icons/chevronLeft.svg';

import useGuideTableOfContentsData from '@playerCommon/hooks/playerHooks/useGuideTableOfContentsData';
import { GuideStepsTreeProvider } from '@playerCommon/CustomElements/GuideStepsTree/templates/player';
import { STEP_SEPARATOR } from 'global/index';
import { TableOfContentsTreeAsyncWrapper } from './TableOfContentsTree/TableOfContentsTreeAsyncWrapper';
import { TableOfContentsFlowchartAsyncWrapper } from './TableOfContentsFlowchart/TableOfContentsFlowchartAsyncWrapper';

import {
  COLLAPSED_PANE_WIDTH,
  EXPANDED_TREE_PANE_WIDTH,
  EXPANDED_FLOWCHART_PANE_WIDTH,
  MobileWrap,
  DesktopWrap,
  StyledSplitPane,
  TableOfContentsPlaceholder,
  StyledPane,
  InvisiblePane,
  OverflowWrap,
  ToggleIcon,
  Content,
  MobileHeader,
  Title,
  StyledCloseButton,
  Divider,
  ButtonsWrap,
  TabButton,
} from './TableOfContents.styles';

const TABLE_OF_CONTENTS_TABS = {
  TREE: 'TREE',
  FLOWCHART: 'FLOWCHART',
};

const TableOfContents = ({
  stepsPathString,
  displayTree,
  displayFlowchart,
  isTableOfContentsExpanded,
  setIsTableOfContentsExpanded,
  jumpToStep,
  isMobile,
}) => {
  const [tableOfContentsActiveTab, setTableOfContentsActiveTab] = useState(
    displayTree ? TABLE_OF_CONTENTS_TABS.TREE : TABLE_OF_CONTENTS_TABS.FLOWCHART
  );
  const [isResizing, setIsResizing] = useState(false);
  const [paneWidth, setPaneWidth] = useState(COLLAPSED_PANE_WIDTH);

  const isSSR = typeof window === 'undefined';
  const { stepList, connectionList, firstStepId } = useGuideTableOfContentsData({ skipComputation: isSSR });

  const expandedPaneWidth = useMemo(() => {
    return tableOfContentsActiveTab === TABLE_OF_CONTENTS_TABS.TREE
      ? EXPANDED_TREE_PANE_WIDTH
      : EXPANDED_FLOWCHART_PANE_WIDTH;
  }, [tableOfContentsActiveTab]);

  function enforceFlowchartSizeUpdated(delay) {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, delay);
  }

  function onResizeEnd(widths) {
    setIsResizing(false);
    let width = +widths[0].replace('px', '');

    if (!isTableOfContentsExpanded) return;

    if (width < expandedPaneWidth / 2) {
      width = COLLAPSED_PANE_WIDTH;
      setIsTableOfContentsExpanded(false);
    } else if (width < expandedPaneWidth) {
      width = expandedPaneWidth;
    }
    setPaneWidth(width);
    enforceFlowchartSizeUpdated();
  }

  function toggleTableOfContents() {
    if (isTableOfContentsExpanded) {
      setIsTableOfContentsExpanded(false);
      setPaneWidth(COLLAPSED_PANE_WIDTH);
    } else {
      setIsTableOfContentsExpanded(true);
      setPaneWidth(expandedPaneWidth);
      enforceFlowchartSizeUpdated();
    }
  }

  function toggleTab(tab) {
    if (tab === TABLE_OF_CONTENTS_TABS.TREE) {
      setTableOfContentsActiveTab(TABLE_OF_CONTENTS_TABS.TREE);
      setPaneWidth(EXPANDED_TREE_PANE_WIDTH);
    } else {
      setTableOfContentsActiveTab(TABLE_OF_CONTENTS_TABS.FLOWCHART);
      setPaneWidth(EXPANDED_FLOWCHART_PANE_WIDTH);
      enforceFlowchartSizeUpdated(300 /* time longer than width transition time */);
    }
  }

  const getIsRowActive = useCallback(
    row => {
      if (row.isFolder) {
        return false;
      }
      if (row.isSpecial) {
        return false;
      }
      // The following is needed until we allow for embedding inception (embed step points to another embedding step)
      return stepsPathString.endsWith(
        String(row.payload.stepId).split(STEP_SEPARATOR).slice(-1).filter(Boolean).join(STEP_SEPARATOR)
      );
    },
    [stepsPathString]
  );

  if (!displayTree && !displayFlowchart) {
    return null;
  }

  const contents = (
    <>
      {displayTree && displayFlowchart && (
        <ButtonsWrap>
          <TabButton
            active={tableOfContentsActiveTab === TABLE_OF_CONTENTS_TABS.TREE}
            onClick={() => toggleTab(TABLE_OF_CONTENTS_TABS.TREE)}
            tabIndex={isTableOfContentsExpanded ? 0 : '-1'}
          >
            {i18n('ExplanationPlayer.TreeView')}
          </TabButton>
          <TabButton
            active={tableOfContentsActiveTab === TABLE_OF_CONTENTS_TABS.FLOWCHART}
            onClick={() => toggleTab(TABLE_OF_CONTENTS_TABS.FLOWCHART)}
            tabIndex={isTableOfContentsExpanded ? 0 : '-1'}
          >
            {i18n('ExplanationPlayer.FlowchartView')}
          </TabButton>
        </ButtonsWrap>
      )}
      {isTableOfContentsExpanded && displayTree && tableOfContentsActiveTab === TABLE_OF_CONTENTS_TABS.TREE && (
        <TableOfContentsTreeAsyncWrapper {...{ onStepClick: jumpToStep }} />
      )}
      {isTableOfContentsExpanded &&
        displayFlowchart &&
        tableOfContentsActiveTab === TABLE_OF_CONTENTS_TABS.FLOWCHART && (
          <>
            <Divider />
            <TableOfContentsFlowchartAsyncWrapper
              guideStepList={stepList}
              guideConnectionList={connectionList}
              firstStepId={firstStepId}
              onStepClick={jumpToStep}
              getIsStepActive={getIsRowActive}
              widthStyle={`${paneWidth}px`}
            />
          </>
        )}
    </>
  );
  return (
    <GuideStepsTreeProvider
      guideStepList={stepList}
      guideConnectionList={connectionList}
      firstStepId={firstStepId}
      stepsPathString={stepsPathString}
      getIsRowActive={getIsRowActive}
    >
      <DesktopWrap>
        <TableOfContentsPlaceholder />
        <StyledSplitPane
          split="vertical"
          isTableOfContentsExpanded={isTableOfContentsExpanded}
          onResizeStart={() => setIsResizing(true)}
          onResizeEnd={onResizeEnd}
        >
          <StyledPane
            minSize={`${COLLAPSED_PANE_WIDTH}px`}
            maxSize="100%"
            initialSize={`${paneWidth}px`}
            paneWidth={isResizing ? undefined : paneWidth}
          >
            <OverflowWrap>
              <Content>{isMobile ? null : contents}</Content>
            </OverflowWrap>
            <ToggleIcon
              iconNode={isTableOfContentsExpanded ? ChevronLeftSVG : TableOfContentsSVG}
              tooltip={i18n('ExplanationPlayer.TableOfContents')}
              tabIndex="0"
              positionTooltip="right"
              onClick={toggleTableOfContents}
            />
          </StyledPane>
          <InvisiblePane />
        </StyledSplitPane>
      </DesktopWrap>
      <MobileWrap isTableOfContentsExpanded={isTableOfContentsExpanded}>
        <OverflowWrap>
          <Content>
            <MobileHeader>
              <Title>{i18n('ExplanationPlayer.TableOfContents')}</Title>
              <StyledCloseButton onClick={toggleTableOfContents} size="small" placement={26} />
            </MobileHeader>
            {isMobile ? contents : null}
          </Content>
        </OverflowWrap>
      </MobileWrap>
    </GuideStepsTreeProvider>
  );
};

TableOfContents.propTypes = {
  displayTree: PropTypes.bool,
  displayFlowchart: PropTypes.bool,
  isMobile: PropTypes.bool,
  isTableOfContentsExpanded: PropTypes.bool,
  setIsTableOfContentsExpanded: PropTypes.func,
  jumpToStep: PropTypes.func,
  stepsPathString: PropTypes.string,
};

export default TableOfContents;
