import { useState, useEffect, useCallback } from 'react';
import { STEP_NEXT_VISIBILITY } from 'global';
import useDidUpdate from '@playerCommon/hooks/useDidUpdate';
import usePrevious from '@playerCommon/hooks/usePrevious';
import useIsMounted from '@playerCommon/hooks/useIsMounted';
import useTargetingChangedMessage from '@playerCommon/hooks/useTargetingChangedMessage';
import callApi from 'helpers/apiHelpers';
import { postMessage } from 'helpers/widgetHelpers';
import { useSentData } from '@playerCommon/Contexts/sentDataContext';
import { useServerCallData } from '@playerCommon/Contexts/serverCallDataContext';
import { prepareStepNextList } from '@playerCommon/ComplexElements/Automation/Automation.helpers';
import { extractBaseData } from 'components/Analytics/recorder';

const cache = {
  value: {},
  set(key, value) {
    this.value[key] = JSON.stringify({ value, timestamp: Date.now() });
  },
  get(key) {
    const { value, timestamp } = this.value[key] ? JSON.parse(this.value[key]) : {};
    const ONE_MINUTE = 60 * 1000;
    const now = Date.now();
    if (timestamp + ONE_MINUTE > now) {
      return value;
    }
    this.invalidate(key);
    return undefined;
  },
  invalidate(key) {
    delete this.value[key];
  },
  clearCache() {
    this.value = {};
  },
};

const sendResolveConditionsRequest = async (stepNextList = []) => {
  if (!stepNextList.length) {
    return false;
  }
  try {
    const { stonlyAnonymousId, customerUserId, segmentAnonymousId, segmentUserId, guideId, originalGuideId } =
      await extractBaseData();
    const { data } = await callApi('v1/widget/condition/resolve', 'post', {
      stonlyAnonymousId,
      customerUserId,
      segmentAnonymousId,
      segmentUserId,
      guideId,
      originalGuideId,
      stepNextList,
    });
    return Object.fromEntries(data.conditionResults.map(({ stepNextId, result }) => [stepNextId, result]));
  } catch {
    return false;
  }
};

async function resolveExternalConditions(connectionsToResolve, localData, serverCallStatuses, serverCallVariables) {
  if (!connectionsToResolve.length) {
    return [];
  }

  const connectionsWithConditions = connectionsToResolve.filter(
    connection => connection.visibility === STEP_NEXT_VISIBILITY.externalCondition
  );

  const stepNextList = prepareStepNextList(
    connectionsWithConditions,
    localData,
    serverCallStatuses,
    serverCallVariables
  );

  let visibleConnections = connectionsToResolve.filter(({ visibility }) => visibility === STEP_NEXT_VISIBILITY.always);

  const conditionResults = await sendResolveConditionsRequest(stepNextList);

  if (conditionResults) {
    const hasResolvedExternalCondition = Object.values(conditionResults).some(Boolean);
    visibleConnections = connectionsToResolve.filter(({ visibility, id }) => {
      if (visibility === STEP_NEXT_VISIBILITY.always) {
        return true;
      }
      if (visibility === STEP_NEXT_VISIBILITY.externalCondition) {
        return conditionResults[id];
      }
      if (visibility === STEP_NEXT_VISIBILITY.fallback) {
        return !hasResolvedExternalCondition;
      }
      return true;
    });
  }
  return visibleConnections.map(({ id }) => id);
}

export default function useGuideStepConnectionsVisibility({ connections, isPreview, stepId }) {
  const isEveryConnectionVisible =
    isPreview || connections.every(({ visibility }) => visibility !== STEP_NEXT_VISIBILITY.externalCondition);

  const initiallyVisibleConnectionIdList = isEveryConnectionVisible
    ? connections.map(({ id }) => id)
    : cache.get(stepId) || [];

  const shouldResolveConditions = !isEveryConnectionVisible && !initiallyVisibleConnectionIdList.length;

  const [visibleConnectionIdList, setVisibleConnectionIdList] = useState(initiallyVisibleConnectionIdList);
  const [isLoading, setIsLoading] = useState(shouldResolveConditions);
  const { localData } = useSentData();
  const { serverCallStatuses, serverCallVariables } = useServerCallData();
  const previousLocalData = usePrevious(localData);
  const checkIsMounted = useIsMounted();

  const fetchAndSetVisibleConnectionIdList = useCallback(
    async connectionsToResolve =>
      resolveExternalConditions(connectionsToResolve, localData, serverCallStatuses, serverCallVariables).then(
        result => {
          if (checkIsMounted()) {
            setVisibleConnectionIdList(result);
          }
        }
      ),
    [localData, checkIsMounted]
  );

  useEffect(() => {
    if (shouldResolveConditions) {
      fetchAndSetVisibleConnectionIdList(connections).then(() => {
        setIsLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    if (isPreview) {
      setVisibleConnectionIdList(connections.map(({ id }) => id));
    }
  }, [isPreview, connections]);

  useEffect(() => {
    postMessage({ type: 'visibleConnectionIdList', visibleConnectionIdList });
    return () => !!visibleConnectionIdList.length && cache.set(stepId, visibleConnectionIdList);
  }, [visibleConnectionIdList]);

  useDidUpdate(() => {
    if (isPreview) {
      return;
    }

    const localPropertyUsedInConditionsList = [
      ...new Set(connections.flatMap(c => c.conditionLocalProperties).filter(property => !!property)),
    ];
    const hasLocalPropertyChanged = localPropertyUsedInConditionsList.some(p => previousLocalData[p] !== localData[p]);

    // Refetch external conditions in case the values of local properties they depend on have changed
    if (hasLocalPropertyChanged) {
      fetchAndSetVisibleConnectionIdList(connections);
    }

    // Clear cache because already saved values might be stale
    cache.clearCache();
  }, [localData]);

  const onTargetingChange = useCallback(() => {
    fetchAndSetVisibleConnectionIdList(connections);
    // Clear cache because already saved values might be stale
    cache.clearCache();
  }, [connections, fetchAndSetVisibleConnectionIdList]);

  useTargetingChangedMessage({ onTargetingChange });

  return {
    isLoading,
    visibleConnectionIdList,
  };
}
