import React from 'react';
import permissionsContext from '@playerCommon/Contexts/permissionsContext';

const withPermissions = Component => props =>
  (
    <permissionsContext.Consumer>
      {({
        permissions,
        upgradeTrial,
        isTrial,
        goToUpgrade,
        getPermission,
        upgradePlan,
        currentPlan,
        currentPlanFull,
        currentPeriod,
      }) => (
        <Component
          {...props}
          permissions={permissions}
          upgradeTrial={upgradeTrial}
          isTrial={isTrial}
          goToUpgrade={goToUpgrade}
          currentPlan={currentPlan}
          currentPlanFull={currentPlanFull}
          currentPeriod={currentPeriod}
          getPermission={getPermission}
          upgradePlan={upgradePlan}
        />
      )}
    </permissionsContext.Consumer>
  );

export default withPermissions;
