import React, { useMemo } from 'react';
import Warning from 'resources/icons/warning.svg';
import Tip from 'resources/icons/tip.svg';
import Tick from 'resources/icons/tickMedium.svg';
import { Textarea as TextareaStyle } from './Textarea.styles';
import { BaseInput } from './Input.styles';
import { STATUS_TYPES } from './Input.consts';

function Textarea({
  id,
  className,
  value,
  label,
  name,
  statusType,
  statusMessage,
  disabled,
  placeholder,
  maxLength,
  minRows = 3,
  onChange,
  onBlur,
  onFocus,
  dataCy,
  required,
  onKeyPress,
  readOnly,
}) {
  const textareaId = id && `textarea-${id}`;
  const statusMessageId = id && `status-message-${id}`;
  const MessageIcon = useMemo(() => {
    if (statusType === STATUS_TYPES.ERROR || statusType === STATUS_TYPES.WARNING) return <Warning />;
    if (statusType === STATUS_TYPES.SUCCESS) return <Tick />;
    if (statusType === STATUS_TYPES.INFO) return <Tip />;
    return null;
  }, [statusType]);

  return (
    <BaseInput.Container className={className}>
      <BaseInput.Label data-cy={dataCy && `${dataCy}Title`} htmlFor={textareaId}>
        {label}
        {required && <BaseInput.RequiredLabel className="label-asterisk">*</BaseInput.RequiredLabel>}
      </BaseInput.Label>
      <TextareaStyle.ElementWrap className="input-wrap" statusType={statusType} disabled={disabled}>
        <TextareaStyle.TextareaElement
          id={textareaId}
          required={required}
          value={value}
          name={name}
          placeholder={placeholder}
          maxLength={maxLength}
          data-cy={dataCy}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyPress={onKeyPress}
          readOnly={readOnly}
          aria-invalid={statusType === STATUS_TYPES.ERROR}
          aria-errormessage={statusType === STATUS_TYPES.ERROR ? statusMessageId : undefined}
          tabIndex="0"
          minRows={minRows}
          aria-label={label ? null : placeholder}
        />
        <TextareaStyle.IconWrap statusType={statusType} className="textarea-message-icon">
          {MessageIcon}
        </TextareaStyle.IconWrap>
      </TextareaStyle.ElementWrap>
      <BaseInput.MessageWrap statusType={statusType} data-cy={dataCy && `${dataCy}Status`}>
        {statusMessage && (
          <span id={statusMessageId} aria-live="assertive">
            {statusMessage}
          </span>
        )}
      </BaseInput.MessageWrap>
    </BaseInput.Container>
  );
}

export default Textarea;
