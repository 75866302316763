import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import CrossSVG from 'icons/crossMedium.svg';
import { AnimatePresence, motion } from 'framer-motion';
import usePrevious from '@playerCommon/hooks/usePrevious';
import { useNotifications } from '@playerCommon/hooks/useNotifications';
import SuccessSVG from 'icons/success.svg';
import WarningSVG from 'icons/warning.svg';
import ErrorSVG from 'icons/error.svg';
import Loader from '@playerCommon/CustomElements/Loader';

const NotificationsWrap = styled.ul`
  position: fixed;
  bottom: 0;
  left: 24px;
  display: flex;
  flex-direction: column;
  list-style: none;
  align-items: flex-start;
  justify-content: flex-end;
  z-index: 107;
  margin: 0;
  padding: 0;
`;

const NotificationIcon = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 12px;
  margin-top: 1px;
  align-items: flex-start;
`;

const Notification = styled(motion.li)`
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  align-items: flex-start;
  background-color: ${props => props.theme.darkGrey};
  color: ${props => props.theme.white};
  margin: 0;
  margin-bottom: 24px;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.darkGrey};
  box-shadow: 0 2px 4px 0 ${props => props.theme.canvasBox}, 0 0 2px 0 rgba(0, 0, 0, 0.12);
  z-index: 107;
  width: auto;
  max-width: 480px;
  padding: 18px;

  ${({ status }) => {
    if (status === 'success') {
      return css`
        border-left: 4px solid ${props => props.theme.seaweed};

        ${NotificationIcon} path {
          fill: ${props => props.theme.seaweed};
        }
      `;
    }
    if (status === 'warning') {
      return css`
        border-left: 4px solid ${props => props.theme.mango};

        ${NotificationIcon} path {
          fill: ${props => props.theme.mango};
        }
      `;
    }
    if (status === 'error') {
      return css`
        border-left: 4px solid ${props => props.theme.errorColor};

        ${NotificationIcon} path {
          fill: ${props => props.theme.errorColor};
        }
      `;
    }
  }}
`;

const Content = styled.div`
  font-size: 14px;

  p {
    margin: 0;
  }
`;

const LoaderSmall = styled(Loader).attrs({ type: 'circle', monochrome: true })`
  margin-right: 12px;
  width: 16px;
  height: 16px;

  & svg circle {
    stroke-width: 4px;
    stroke: ${props => props.theme.white};
  }
`;

const NotificationClose = styled.div`
  display: flex;
  margin-left: 24px;
  flex-shrink: 0;
  flex-grow: 0;
  width: 16px;
  margin-top: 1px;
  align-items: flex-start;
`;

const Cross = styled(CrossSVG)`
  width: 16px;
  height: 16px;
  transition: opacity 0.2s;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

function Notifications() {
  const { notifications, removeNotification } = useNotifications();
  const previousNotifications = usePrevious(notifications);

  useEffect(() => {
    const newNotification = notifications.find(el => !previousNotifications.includes(el));

    if (newNotification && newNotification.timeout) {
      setTimeout(() => {
        removeNotification(newNotification);
      }, newNotification.timeout);
    }
  }, [notifications]);

  return (
    <NotificationsWrap>
      <AnimatePresence initial={false}>
        {notifications.map(notification => (
          <Notification
            key={notification.key}
            style={notification.style}
            layout
            initial={{ opacity: 0, y: 50, scale: 0.5 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2, ease: 'easeInOut' } }}
            status={notification.status}
          >
            {notification.status === 'info' && !!notification.icon && (
              <NotificationIcon>{notification.icon}</NotificationIcon>
            )}
            {notification.status === 'success' && (
              <NotificationIcon>{notification.icon || <SuccessSVG />}</NotificationIcon>
            )}
            {notification.status === 'warning' && (
              <NotificationIcon>
                <WarningSVG />
              </NotificationIcon>
            )}
            {notification.status === 'error' && (
              <NotificationIcon>
                <ErrorSVG />
              </NotificationIcon>
            )}
            {notification.status === 'loading' && <LoaderSmall />}

            <Content data-cy="notificationContent">{notification.content}</Content>
            {notification.dismissable && notification.status !== 'loading' && (
              <NotificationClose>
                <Cross data-cy="crossCancel" onClick={() => removeNotification(notification)} />
              </NotificationClose>
            )}
          </Notification>
        ))}
      </AnimatePresence>
    </NotificationsWrap>
  );
}

export default Notifications;
