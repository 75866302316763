import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const Canvas = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.playerTitleMarginBottom};

    @media screen and (max-width: 480px) {
      margin-bottom: ${theme.playerMobileTitleMarginBottom};
    }
  `}
`;

const Subtitle = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.playerTitleFontSize};
    line-height: ${theme.playerTitleLineHeight};

    @media screen and (max-width: 480px) {
      font-size: ${theme.playerMobileTitleFontSize};
      line-height: ${theme.playerMobileTitleLineHeight};
    }
  `}
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
`;

const PageSubtitle = ({ subtitle }) => (
  <Canvas>
    <Subtitle className="subtitle" data-cy="stepSubtitle">
      {subtitle}
    </Subtitle>
  </Canvas>
);

PageSubtitle.propTypes = {
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
};

export default PageSubtitle;
