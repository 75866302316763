import styled, { css } from 'styled-components';
import { variableStyles } from '@playerCommon/CommonStyledComponents/Typography';
import { playerUrl, assetsUrl } from 'global/env';
import { getGlobal } from 'global/windowUtils.js';
import StepSVG from 'icons/step-16.svg';
import ExternalLinkSmallSVG from 'icons/externalLinkSmall.svg';
import ThumbsUpSVG from 'icons/thumbsUp-12.svg';
import ResetSVG from 'icons/reset.svg';

export const QuestionAnswerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const bubbleStyles = css`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

export const QuestionCanvas = styled.div`
  ${bubbleStyles}
  background: ${props => props.theme.anotherWeirdLightGrey};
`;

export const AnswerCanvas = styled.div`
  font-size: 16px;
  text-align: left;
  padding: 0px 16px 8px 16px;
  ${variableStyles};

  a {
    text-decoration: none;
    font-weight: 600;

    &:before {
      content: '';
      mask-image: url(${assetsUrl}resources/icons/externalLinkTiny.svg);
      background: ${props => props.theme.linkColor};
      display: inline-block;
      margin-bottom: 1px;
      margin-right: 2px;
      width: 16px;
      height: 16px;
      vertical-align: middle;
    }

    &[href*='${playerUrl.replace(/^https?:\/\//, '')}']:before,
    &[href*='${props => props.hostname}']:before,
    &[href^='/']:before {
      mask-image: url(${assetsUrl}resources/icons/stepIcon.svg);
      background: ${props => props.theme.amaranth};
    }
  }

  ${({ isFallback }) =>
    isFallback &&
    css`
      margin-bottom: 24px;

      span *:first-child {
        margin-top: 0;
      }
      span *:last-child {
        margin-bottom: 0;
      }
      a {
        &:before {
          display: none;
        }
      }
    `}
`;

export const LinkClickedCanvas = styled.div`
  ${bubbleStyles}
  background: ${props => props.theme.washedBlue};
`;

export const Link = styled.a`
  margin-left: 4px;
  font-weight: 600;
  color: ${props => props.theme.linkColor};
`;

export const StepIcon = styled(StepSVG)`
  margin-right: 12px;
  path {
    fill: ${props => props.theme.amaranth};
  }
`;

export const AnswerVotedCanvas = styled.div`
  ${bubbleStyles}
  background: ${({ theme, vote }) => (vote ? theme.washedGreen : theme.washedRed)};
  gap: 12px;
`;

export const ThumbsUpIcon = styled(ThumbsUpSVG)`
  width: 16px;
  height: 16px;
  path {
    fill: ${props => props.theme.seaweed};
  }

  ${({ reversed }) =>
    reversed &&
    css`
      transform: rotate(180deg);
      path {
        fill: ${props => props.theme.amaranth};
      }
    `}
`;

export const ConversationRestartedCanvas = styled.div`
  ${bubbleStyles}
  background: ${props => props.theme.washedYellow};
  gap: 12px;
`;

export const ResetIcon = styled(ResetSVG)`
  path {
    fill: ${props => props.theme.orangish};
  }
`;

export const ExternalLinkIcon = styled(ExternalLinkSmallSVG)`
  margin-right: 12px;
  path {
    fill: ${props => props.theme.linkColor};
  }
`;
