import React, { useMemo } from 'react';
import T from 'prop-types';
import { FlatTreeProvider } from '@playerCommon/ComplexElements/FlatTree/FlatTreeProvider';
import { computeStepsTreeRowDisplayParameters } from '../../shared/tools/computeStepsTreeRowDisplayParameters';
import { convertGuideStepsToFlatTreeList } from '../../shared/tools/convertGuideStepsToFlatTreeList';
import { GuideStepsFinderProvider } from '../../shared/GuideStepsFinderProvider';

const ROOT_PATH_NAME = 'root_path'; // just need something

export const GuideStepsTreeProvider = ({
  guideStepList,
  guideConnectionList,
  guideHasIntroduction,
  firstStepId,
  getIsRowActive,
  children,
}) => {
  const rowList = useMemo(() => {
    const list = convertGuideStepsToFlatTreeList({
      nodeList: guideStepList,
      connectionList: guideConnectionList,
      config: {
        firstStepId,
        rootPath: ROOT_PATH_NAME,
        shouldAddIntroduction: guideHasIntroduction,
        showUnlinked: false,
        getStepId: step => step.id,
        insertPlaceholderOnTop: false,
      },
    });

    return list.map(row => {
      return row.isSpecial
        ? row
        : {
            ...row,
            payload: computeStepsTreeRowDisplayParameters(row.payload || {}),
          };
    });
  }, [guideStepList, guideConnectionList, guideHasIntroduction, firstStepId]);

  return (
    <GuideStepsFinderProvider guideStepList={guideStepList}>
      <FlatTreeProvider rowList={rowList} mainPathName={ROOT_PATH_NAME} memoizedGetIsRowActive={getIsRowActive}>
        {children}
      </FlatTreeProvider>
    </GuideStepsFinderProvider>
  );
};

GuideStepsTreeProvider.propTypes = {
  guideStepList: T.array,
  guideConnectionList: T.array,
  guideHasIntroduction: T.bool,
  firstStepId: T.oneOfType([T.string, T.number]),
  stepsPathString: T.string,
  getIsRowActive: T.func,
};
