import styled, { css } from 'styled-components';
import ProgressBar from '@playerCommon/CustomElements/ProgressBar/ProgressBar';
import PoweredByStonly, { PoweredByStonlyCustomText } from '@playerCommon/CustomElements/PoweredByStonly';

import { SecondaryIconButton } from '@playerCommon/StandardElements/Buttons/SecondaryButton';

export const FooterWrapper = styled.div`
  ${({ type }) =>
    type === 'desktop' &&
    css`
      width: 100%;
      display: none;
      @media screen and (min-width: 900px) {
        display: block;
      }
    `};

  ${({ type }) =>
    type === 'mobile' &&
    css`
      width: 100%;
      display: block;
      @media screen and (min-width: 900px) {
        display: none;
      }
    `};
`;

export const WideProgressBar = styled(ProgressBar)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: inline-block;

  ${({ mode }) =>
    ['normal', 'hc'].includes(mode) &&
    css`
      @media screen and (min-width: 900px) {
        display: none;
      }
    `};
`;

export const FooterPoweredByStonly = styled(PoweredByStonly)``;

export const CompactStepButtonsCanvas = styled.div`
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;

  @media screen and (min-width: 900px) {
    position: absolute;
  }

  ${WideProgressBar} {
    top: unset;
    bottom: 0;
    z-index: 1;
  }

  ${FooterPoweredByStonly} {
    background: ${({ theme }) => theme.white};
    border-radius: 4px;
    bottom: 12px;
    padding: 4px;
    position: absolute;
    right: 16px;
  }

  @media screen and (max-width: 320px) {
    ${PoweredByStonlyCustomText} {
      display: none;
    }
  }
`;

export const StepButtonsCanvas = styled.div`
  position: relative;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 84px;
  width: 100%;
  padding: 0 24px;
  flex-shrink: 0;
  flex-grow: 0;
  border-top: 1px solid ${({ theme }) => theme.silver};
  background-color: white;
  backdrop-filter: blur(100px);

  @supports ((-webkit-backdrop-filter: blur(5px)) or (backdrop-filter: blur(5px))) {
    & {
      background-color: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(5px);
    }
  }

  @media screen and (max-width: 899px) {
    height: ${({ theme }) => theme.contentCanvasPaddingBottom};
    z-index: 11;

    ${({ mode }) =>
      ['widget', 'normal', 'hc', 'hc-widget'].includes(mode) &&
      css`
        position: fixed;
      `};
  }

  @media screen and (max-width: 640px) {
    ${({ mode, borderlessEmbed, displayProgressBar }) =>
      (mode !== 'widget' || borderlessEmbed) &&
      displayProgressBar &&
      css`
        padding-top: theme.progressBarHeight;
        border-top: 0px solid ${props => props.theme.silver};
      `};
  }

  @media screen and (max-width: 480px) {
    height: ${({ theme }) => theme.contentCanvasPaddingBottomMobile};
    padding: 0 12px;
    ${({ mode, theme }) =>
      mode !== 'widget' &&
      css`
        padding-top: ${theme.progressBarHeight};
      `};
    ${({ matchMode = '' }) => matchMode.includes('agentApp') && 'padding: 0px;'}
  }

  @media screen and (max-width: 899px) {
    ${({ mode }) =>
      ['embed'].includes(mode) &&
      css`
        position: absolute;
      `};

    ${({ mode, isFullscreen }) =>
      ['embed'].includes(mode) &&
      isFullscreen &&
      css`
        position: fixed;
      `};
  }

  ${FooterPoweredByStonly} {
    padding-left: 8px;
    font-size: 14px;
  }
`;

export const CompactTTSButton = styled(SecondaryIconButton)`
  position: fixed;
  bottom: 12px;
  right: 12px;
  z-index: 2;
`;
