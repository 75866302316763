const addHubSpotListeners = (onOpen, onClose) => {
  window.addEventListener('message', message => {
    if (message.origin.includes('.hubspot.com')) {
      try {
        const data = JSON.parse(message.data);
        if (data.type === 'open-change') {
          if (data.data.isOpen === true) {
            if (onOpen) onOpen();
          }
          if (data.data.isOpen === false) {
            if (onClose) onClose();
          }
        }
      } catch (e) {
        // mute, we don't care
      }
    }
  });
};

export const initializeHubspotChat = (isWidget, onContactOpen, onContactClose) => {
  if (!isWidget) addHubSpotListeners(onContactOpen, onContactClose);
};
