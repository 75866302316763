import React, { memo, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import callApi from 'helpers/apiHelpers.js';
import TextLink from '@playerCommon/StandardElements/TextLink';
import Avatar from '@playerCommon/CustomElements/Avatar/Avatar.jsx';
import displayDate from 'helpers/dateManagement.js';
import i18n from 'helpers/i18n';
import { RIGHT } from 'global';
import ResolveIconSVG from 'icons/resolve.svg';
import ReplyIconSVG from 'icons/reply.svg';
import NewComment from '../../NewComment/NewComment.js';

const Canvas = styled.div`
  margin: 0 0 32px 48px;
`;

const UserInfo = styled.div`
  display: flex;
`;

const UsernameAndHeadline = styled.div`
  padding-left: 12px;
  font-weight: 600;
  font-size: 14px;
`;

const Username = styled.div`
  ${({ headline }) => (headline ? '' : 'margin-top:8px;')}
`;

const Headline = styled.div`
  color: ${props => props.theme.lightGrey};
  margin-top: 2px;
`;

const Content = styled.div`
  margin-top: 18px;
  margin-left: 48px;
  white-space: pre-line;
  word-wrap: break-word;
`;

const Options = styled.div`
  margin-left: 48px;
  margin-top: 24px;
  color: ${props => props.theme.steel};
`;

const Line = styled.div`
  display: flex;
  height: 24px;
  align-items: center;
`;

const Reply = styled(TextLink)`
  & svg {
    margin-top: 4px;
    margin-right: -4px;
  }
`;

const Resolve = styled(TextLink)`
  margin-left: 16px;
  & svg {
    margin-top: 4px;
    margin-right: -4px;
  }
`;

const PostDate = styled.div`
  color: ${props => props.theme.lightGrey};
  ${({ canReply }) => canReply && 'margin-left: 20px;'};
`;

const NewSubComment = styled(NewComment)`
  margin-top: 32px;
`;

function Comment({ item, userManagement, updateStepComments, access }) {
  const [showReply, setShowReply] = useState(false);

  const resolveComment = async () => {
    try {
      await callApi('v1/stepReaction', 'put', {
        guideId: item.guideId,
        stepReactionId: item.stepCommentId,
        resolved: 1,
      });
      updateStepComments(item, 'resolve');
    } catch {
      console.log('there was an error while posting a comment');
    }
  };

  const commentDate = displayDate(item.commentDate);
  const authorName = [item.firstName, item.lastName].filter(Boolean).join(' ') || i18n('StepComments.AnonymousUser');

  return (
    <Canvas>
      <UserInfo>
        <Avatar
          src={item.picture}
          srcSecureHash={item.pictureSecureHash}
          type="user"
          numberForBackground={item.creationDate}
          targetName={`${item.firstName} ${item.lastName}`}
        />
        <UsernameAndHeadline>
          <Username data-cy="username" headline={item.headline}>
            {authorName}
          </Username>
          {item.headline && <Headline>{item.headline}</Headline>}
        </UsernameAndHeadline>
      </UserInfo>
      <Content data-cy="contentText">{item.comment}</Content>
      <Options>
        <Line>
          {Object.keys(userManagement.user).length !== 0 && (
            <Reply dataCy="replyButton" onClick={() => setShowReply(value => !value)} bullet={ReplyIconSVG} type="grey">
              {i18n('StepComments.Reply')}
            </Reply>
          )}
          {access === RIGHT.FULL_RIGHTS && (
            <Resolve dataCy="resolveButton" onClick={resolveComment} bullet={ResolveIconSVG} type="grey">
              {i18n('StepComments.Resolve')}
            </Resolve>
          )}
          <PostDate canReply={Object.keys(userManagement.user).length !== 0}>{commentDate}</PostDate>
        </Line>
        {showReply && (
          <NewSubComment
            userManagement={userManagement}
            guideId={item.guideId}
            stepId={item.stepId}
            parentId={item.stepCommentId}
            updateStepComments={updateStepComments}
          />
        )}
      </Options>
    </Canvas>
  );
}

Comment.propTypes = {
  updateStepComments: PropTypes.func,
  userManagement: PropTypes.object,
  item: PropTypes.object,
  access: PropTypes.number,
};

export default memo(Comment);
