import i18n from 'helpers/i18n';
import { EMBEDDED_GUIDE_START_TYPES, STEP_WIDGET_ACTION_TYPES } from './index';

export const SPECIAL_STEPS_BUTTON_NAME = {
  [STEP_WIDGET_ACTION_TYPES.CLOSE_WIDGET]: i18n('Global.Close'),
  [STEP_WIDGET_ACTION_TYPES.KNOWLEDGE_BASE]: i18n('Global.Next'),
  [STEP_WIDGET_ACTION_TYPES.GUIDED_TOUR]: i18n('Global.Next'),
};

/**
 * @typedef {import('./index.types').EmbeddedGuideStartTypeNames} EmbeddedGuideStartTypeNames
 */

/** @type {EmbeddedGuideStartTypeNames} */
export const EMBEDDED_GUIDE_START_TYPE_NAMES = {
  [EMBEDDED_GUIDE_START_TYPES.FIRST_STEP]: i18n('ExplanationEditNextSteps.EmbeddedGuideStartTypeFirstStep'),
  [EMBEDDED_GUIDE_START_TYPES.SPECIFIC_STEP]: i18n('ExplanationEditNextSteps.EmbeddedGuideStartTypeSpecificStep'),
};

export const monthsMap = [
  i18n('MonthsAbbreviations.Jan'),
  i18n('MonthsAbbreviations.Feb'),
  i18n('MonthsAbbreviations.Mar'),
  i18n('MonthsAbbreviations.Apr'),
  i18n('MonthsAbbreviations.May'),
  i18n('MonthsAbbreviations.Jun'),
  i18n('MonthsAbbreviations.Jul'),
  i18n('MonthsAbbreviations.Aug'),
  i18n('MonthsAbbreviations.Sep'),
  i18n('MonthsAbbreviations.Oct'),
  i18n('MonthsAbbreviations.Nov'),
  i18n('MonthsAbbreviations.Dec'),
];
