import { useRef, useState, useEffect } from 'react';

const useAuthenticationInitiateRedirect = () => {
  const [redirectCountdown, setRedirectCountdown] = useState(3);
  const interval = useRef(null);
  const timeout = useRef(null);

  const initiateRedirect = ({ timeoutCallback, intervalTime = 1000, timeoutTime = 3000 }) => {
    interval.current = setInterval(() => {
      setRedirectCountdown(redirectCountdown - 1);
    }, intervalTime);
    timeout.current = setTimeout(() => {
      clearInterval(interval.current);
      timeoutCallback();
    }, timeoutTime);
  };

  useEffect(() => {
    return () => {
      clearInterval(interval.current);
      clearTimeout(timeout.current);
    };
  }, []);

  return {
    redirectCountdown,
    initiateRedirect,
  };
};

export default useAuthenticationInitiateRedirect;
