import TextareaAutosize from '@playerCommon/CustomElements/TextareaAutosize/TextareaAutosize';
import styled, { css } from 'styled-components';

export const SmartSearchResultsFollowupInputWrapWrapper = styled.div`
  position: sticky;
  width: 100%;
  bottom: 0px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity 0.4s, visibility 0.4s, transform 0.4s;
  transform: translate3d(0, 100%, 0);

  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
      visibility: visible;
      pointer-events: all;
      transform: translate3d(0, 0, 0);
    `}

  @media screen and (max-width: 899px) {
    bottom: ${props => props.theme.contentCanvasPaddingBottom};

    ${({ compact, isPreview }) =>
      (compact || isPreview) &&
      css`
        bottom: 0px;
      `}
  }

  @media screen and (max-width: 480px) {
    bottom: ${props => props.theme.contentCanvasPaddingBottomMobile};

    ${({ compact, isPreview }) =>
      (compact || isPreview) &&
      css`
        bottom: 0px;
      `}
  }

  &:before {
    pointer-events: none;
    position: absolute;
    bottom: 0px;
    left: 0px;
    content: '';
    z-index: -1;
    display: block;
    height: 100%;
    width: 100%;
    background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0.6) 70%, rgba(255, 255, 255, 0) 100%);
  }
`;

export const SmartSearchResultsFollowupInput = styled(TextareaAutosize).attrs({ rows: 1 })`
  width: 100%;
  outline: 1px solid ${props => props.theme.grey};

  &:focus-within {
    outline: 1px solid ${props => props.theme.grey};
  }

  @media screen and (min-width: 900px) {
    box-shadow: 0px 12px 32px 0px rgba(40, 37, 71, 0.08), 0px 4px 12px 0px rgba(40, 37, 71, 0.04);
    outline: 0px solid ${props => props.theme.grey};
  }

  background: white;
  color: ${props => props.theme.darkGrey};
  font-size: ${props => props.theme.aiAnswerInputFontSize};
  line-height: ${props => props.theme.aiAnswerInputLineHeight};
  transition: border-color 0.2s;
  resize: none;
  border-radius: 24px;

  @media screen and (max-width: 899px) {
    font-size: ${props => props.theme.playerMobileAiAnswerInputFontSize};
    line-height: ${props => props.theme.playerMobileAiAnswerInputLineHeight};
  }

  &::after,
  textarea {
    padding: 12px 16px;
    padding-right: 76px;
    border-radius: 24px;
  }

  textarea {
    &::placeholder {
      color: ${props => props.theme.steel};
    }
  }
`;

export const SmartSearchResultsFollowupSend = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 6px;
  bottom: 6px;
  width: 36px;
  height: 36px;
  appearance: none;
  border-radius: 50%;
  border: 0;
  background: ${props => props.theme.highlightColor};
  cursor: pointer;
  transition: opacity 0.2s;

  svg path {
    stroke: white;
  }

  &:hover {
    opacity: 0.8;
  }

  svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${props => props.theme.lightGrey};

      &:hover {
        opacity: 1;
      }
    `}
`;

export const SmartSearchResultsFollowupInputWrapInner = styled.div`
  position: relative;
  display: block;
  width: 100%;
`;

export const SmartSearchResultsFollowupInputWrap = styled.form`
  position: relative;
  display: flex;
  width: 100%;
  padding: ${props => props.theme.aiAnswerInputBottomTop} 0px ${props => props.theme.aiAnswerInputBottomPadding};

  @media screen and (max-width: 899px) {
    padding: ${props => props.theme.playerMobileAiAnswerInputBottomTop} 0px
      ${props => props.theme.playerMobileAiAnswerInputBottomPadding};

    ${({ compact, progressBarVisible, theme }) =>
      compact &&
      progressBarVisible &&
      css`
        padding: ${theme.playerMobileAiAnswerInputBottomTop} 0px
          calc(${theme.playerMobileAiAnswerInputBottomPadding} + ${theme.progressBarHeight});
      `}
  }
`;

export const SmartSearchTextCounter = styled.div`
  position: absolute;
  top: 14px;
  right: 6px;
  width: 54px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: ${props => props.theme.paleGrey};
  color: ${props => props.theme.slateGrey};
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  text-transform: uppercase;
  transition: background 0.2s, color 0.2s, opacity 0.2s, visibility 0.2s;
  visibility: hidden;
  opacity: 0;
  z-index: 1;

  ${({ visible }) =>
    visible &&
    css`
      visibility: visible;
      opacity: 1;
    `};

  ${({ warning }) =>
    warning &&
    css`
      background: ${props => props.theme.washedRed};
      color: ${props => props.theme.pink};
    `};
`;
