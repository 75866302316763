import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const circleSpinner = keyframes`
  0% {
    opacity: 0.2;
  }
  90% {
    opacity: 1;
  }
`;

const Wrapper = styled.svg`
  width: 100%;
  height: 100%;

  circle {
    fill: ${({ iconColor }) => iconColor};
    opacity: 0.2;
    animation: ${circleSpinner} 1200ms ease-in-out reverse infinite;
  }

  circle:nth-child(1) {
    animation-delay: 0s;
  }

  circle:nth-child(2) {
    animation-delay: 0.15s;
  }

  circle:nth-child(3) {
    animation-delay: 0.3s;
  }

  circle:nth-child(4) {
    animation-delay: 0.45s;
  }

  circle:nth-child(5) {
    animation-delay: 0.6s;
  }

  circle:nth-child(6) {
    animation-delay: 0.75s;
  }

  circle:nth-child(7) {
    animation-delay: 0.9s;
  }

  circle:nth-child(8) {
    animation-delay: 1.05s;
  }
`;

const DotsSpinner = ({ iconColor }) => (
  <Wrapper iconColor={iconColor}>
    <circle cx="50%" cy="9.4%" r="9.4%" />
    <circle cx="78.1%" cy="21.1%" r="9.4%" />
    <circle cx="90.6%" cy="50%" r="9.4%" />
    <circle cx="78.1%" cy="78.1%" r="9.4%" />
    <circle cx="50%" cy="90.6%" r="9.4%" />
    <circle cx="21.9%" cy="78.1%" r="9.4%" />
    <circle cx="9.4%" cy="50%" r="9.4%" />
    <circle cx="21.9%" cy="21.9%" r="9.4%" />
  </Wrapper>
);

DotsSpinner.propTypes = {
  iconColor: PropTypes.string,
};

export default DotsSpinner;
