import { useMemo } from 'react';
import { findEmbeddedFirstStepId } from '@stonlyCommons/helpers/guidePathHelpers';
import { getStepContent, getStepTitle } from 'helpers/guidePlayerHelpers';

import { convertPlayerGuidesDataToGuideTreeStructure } from 'helpers/guideTreeStructure';
import { getLanguageShorthand } from 'helpers/i18n';
import useGuideData from './useGuideData';

export default function useGuideTableOfContentsData(options = {}) {
  const skipComputation = !!options.skipComputation;
  const guideData = useGuideData();
  const currentLanguage = getLanguageShorthand();
  const defaultLanguage = guideData.guideOptions?.defaultLanguage;

  const guideStepList = useMemo(
    () =>
      skipComputation
        ? []
        : guideData.steps.map(stepState => {
            return {
              id: stepState.stepId,
              type: stepState.stepType,
              title: getStepTitle(stepState, currentLanguage, defaultLanguage),
              embeddedFirstStepId: findEmbeddedFirstStepId(stepState.stepId, guideData.guide),
              content: getStepContent(stepState, currentLanguage, defaultLanguage),
            };
          }),
    [guideData.steps, currentLanguage, defaultLanguage, skipComputation]
  );

  const guideConnectionList = useMemo(
    () => (skipComputation ? [] : guideData.stepConnectionList.filter(connDef => !!connDef.toStepId)),
    [guideData.stepConnectionList, skipComputation]
  );

  return useMemo(
    () =>
      convertPlayerGuidesDataToGuideTreeStructure({
        stepList: guideStepList,
        firstStepId: guideData.firstStepId,
        connectionList: guideConnectionList,
      }),
    [guideStepList, guideData.firstStepId, guideConnectionList]
  );
}
