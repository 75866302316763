import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CONTACT_FORM_STEPS } from 'global';
import i18n from 'helpers/i18n';
import Button from '@playerCommon/StandardElements/Button';

import { Title, Subtitle, ContactFormTextarea, ContactFormFooter, ContactFormBody } from '../ContactForm.styles';

function Intro({ setStep, question = '', suggestionsEnabled, setSearchQuery }) {
  const [search, setSearch] = useState(question);

  const onSubmit = () => {
    setSearchQuery(search);
    if (search.length >= 3 && search.length < 250 && suggestionsEnabled) {
      setStep(CONTACT_FORM_STEPS.SUGGESTED_SOLUTIONS);
    } else {
      setStep(CONTACT_FORM_STEPS.CONTACT_FORM);
    }
  };

  return (
    <>
      <ContactFormBody>
        <Title>{i18n('HelpcenterContact.TypeYourQuestion')}</Title>
        <Subtitle>{i18n('HelpcenterContact.WellFindBestAnswers')}</Subtitle>
        <ContactFormTextarea
          value={search}
          row={6}
          skin="bordered"
          onChange={e => setSearch(e.target.value)}
          placeholder={i18n('HelpcenterContact.QuestionExample')}
          maxLength={20_000}
        />
      </ContactFormBody>
      <ContactFormFooter>
        <Button
          background="outlineAmaranth"
          mouseover="highlight"
          onClick={onSubmit}
          dataCy="nextStepButton"
          content={i18n('HelpcenterContact.AskQuestion')}
        />
      </ContactFormFooter>
    </>
  );
}

Intro.propTypes = {
  setStep: PropTypes.func,
  setSearchQuery: PropTypes.func,
  question: PropTypes.string,
  suggestionsEnabled: PropTypes.bool,
};

export default Intro;
