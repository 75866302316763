import { StringFinder } from 'helpers/StringFinder';
import React, { useContext, useMemo } from 'react';

const GuideStepsSearchingContext = React.createContext();

export const GuideStepsFinderProvider = ({ guideStepList, children }) => {
  const stringFinder = useMemo(
    () =>
      new StringFinder({
        itemList: guideStepList,
        resolveItemId: step => step.id,
        resolveItemSearchableData: step => ({ id: step.id, content: step.content, title: step.title }),
      }),
    [guideStepList]
  );

  return (
    <GuideStepsSearchingContext.Provider
      value={useMemo(
        () => ({
          getIsStepIdMatchingPhrase: stringFinder.getIsItemIdMatchingPhrase,
          findStepIdByPhrase: stringFinder.findItemIdListByPhrase,
        }),
        [stringFinder]
      )}
    >
      {children}
    </GuideStepsSearchingContext.Provider>
  );
};

export const useGuideStepsFinder = () => {
  const contextValue = useContext(GuideStepsSearchingContext);
  if (!contextValue) {
    throw new Error('useGuideStepsFinder must be executed from inside GuideStepsFinderProvider');
  }

  return contextValue;
};
