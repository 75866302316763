import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { playerUrl } from 'global/env';
import callApi from 'helpers/apiHelpers.js';
import { useTranslation } from 'react-i18next';
import { validateEmail } from 'helpers/validationHelpers.js';
import useInput from '@playerCommon/hooks/useInput';
import ErrorMessage from '@playerCommon/CustomElements/ErrorMessage/ErrorMessage';
import withTooltipElement from '@playerCommon/HOC/withTooltipElement';
import { LOGIN_TYPES } from '@stonlyCommons/constants/Authentication.consts';
import { ColumnFlex } from '@playerCommon/ui/components/Flex/Flex';
import { InputText } from '@playerCommon/ui/components/inputs/InputText';
import { AUTHENTICATION_TITLE_ID, AUTHENTICATION_VIEWS } from '../Authentication.consts';

import {
  Canvas,
  Top,
  Title,
  Switch,
  SSOProblemText,
  AuthenticationButtonSSO,
  TextWrap,
  StyledAlert,
  TextLink,
} from '../Authentication.styles.js';

const TextWithTooltip = withTooltipElement(SSOProblemText);

const SignInWithSSO = ({ setAuthenticationView, setLoginEmail, loginEmail }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const urlParams = queryString.parse(location.search);
  const [loginError, setLoginError] = useState('');
  const [antiRageClickEnabled, setAntiRageClickEnabled] = useState(false);
  const [emailForLogin, emailOnChange] = useInput(loginEmail);

  useEffect(() => {
    setAntiRageClickEnabled(false);
    setLoginError('');
  }, [emailForLogin]);

  async function onSubmit(e) {
    e.preventDefault();

    // to prevent locking user out due to rage clicks
    if (antiRageClickEnabled) return;
    setAntiRageClickEnabled(true);

    const formattedEmail = emailForLogin.replaceAll(/\s+/g, '').toLowerCase();

    if (formattedEmail === '') {
      setLoginError(t('SignIn.EmptyEmailError'));
      return;
    }
    if (!validateEmail(formattedEmail)) {
      setLoginError(t('SignIn.WrongEmailError'));
      return;
    }

    try {
      const res = await callApi('v1/auth/login/check', 'post', {
        email: formattedEmail,
      });
      if (res.data.type === LOGIN_TYPES.LOCAL) {
        setLoginEmail(formattedEmail);
        setAuthenticationView(AUTHENTICATION_VIEWS.SIGN_IN);
      }
      if (res.data.type === LOGIN_TYPES.SSO) {
        const url = `${playerUrl}/api/v1/auth/login/${res.data.ssoId}/initiate`;
        window.location.href = url;
      }
    } catch (error) {
      setAntiRageClickEnabled(false);
      if (error.status === 400) {
        setLoginError(t('SignInSSO.EmailNotFound'));
      }
      if (error.status === 429) {
        setLoginError(t('SignIn.AuthenticationTooManyAttempts'));
      }
    }
  }

  return (
    <Canvas>
      <Top>
        <Title id={AUTHENTICATION_TITLE_ID}>{t('SignInSSO.Title')}</Title>
      </Top>
      <form id="loginSSOForm" onSubmit={onSubmit}>
        {loginError && <ErrorMessage dataCy="loginSSOError">{loginError}</ErrorMessage>}
        {urlParams.isGoogleAuth && (
          <StyledAlert dataCy="loginSSOInfo" severity="info">
            {t('SignInSSO.GoogleInfoMessage')}
          </StyledAlert>
        )}
        <ColumnFlex marginBottom={2} marginTop={2}>
          <InputText
            name="emailForLogin"
            value={emailForLogin}
            onChange={emailOnChange}
            placeholder={t('SignInSSO.EmailPlaceholder')}
            label={t('SignInSSO.EmailField')}
            data-cy="emailField"
            autoComplete="email"
          />
        </ColumnFlex>
        <TextWrap>
          <TextWithTooltip tooltipContent={t('SignInSSO.LoginProblemTooltip')}>
            {t('SignInSSO.LoginProblem')}
          </TextWithTooltip>
        </TextWrap>
        <AuthenticationButtonSSO
          form="loginSSOForm"
          usage="submit"
          type="bigText"
          fullWidth
          content={t('SignInSSO.Continue')}
          value="Submit"
          dataCy="loginSSO"
        />
      </form>
      <Switch>
        <TextLink data-cy="signIn" onClick={() => setAuthenticationView(AUTHENTICATION_VIEWS.SIGN_IN)}>
          {t('SignInSSO.StandardSignIn')}
        </TextLink>
      </Switch>
    </Canvas>
  );
};

SignInWithSSO.propTypes = {
  setAuthenticationView: PropTypes.func,
  handlePostAuthentication: PropTypes.func,
  setLoginEmail: PropTypes.func,
  loginEmail: PropTypes.string,
};

export default SignInWithSSO;
