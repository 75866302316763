import React from 'react';
import Star48SVG from 'icons/surveyStar-48.svg';
import {
  largeSmileyIconMap,
  largeThumbsIconMap,
  numberEmojiMap,
  smileyEmojiMap,
  thumbsEmojiMap,
  ratingOptionIndexListByRatingStyleAndScale,
  getAdjustedOptionIndex,
  ICON_STYLES,
  RATING_STYLES,
} from '@stonlyCommons/constants/CustomSurvey.consts';

export const CUSTOM_SURVEY_STEPS = {
  NPS: 'NPS',
  QUESTION: 'QUESTION',
  THANK_YOU: 'THANK_YOU',
  THANK_YOU_SKIPPED: 'THANK_YOU_SKIPPED',
};

export const mobilePaddingBasedOnRatingScale = {
  2: 6,
  3: 6,
  4: 6,
  5: 6,
  6: 4,
  7: 4,
  8: 3,
  9: 3,
  10: 2,
};

export function getMobileSpacing(ratingScale) {
  switch (ratingScale) {
    case 2:
    case 3:
    case 4:
    case 5: {
      return 16;
    }
    case 6: {
      return 10;
    }
    case 7: {
      return 8;
    }
    case 8: {
      return 6;
    }
    case 9: {
      return 6;
    }
    case 10: {
      return 4;
    }
    default: {
      return 16;
    }
  }
}

export function getMobileSize(ratingStyle, ratingScale) {
  switch (ratingScale) {
    case 2:
    case 3:
    case 4:
    case 5: {
      return 44;
    }
    case 6: {
      return 38;
    }
    case 7: {
      return 34;
    }
    case 8: {
      return 30;
    }
    case 9: {
      return ratingStyle === RATING_STYLES.NUMBERS ? 44 : 26;
    }
    case 10: {
      return ratingStyle === RATING_STYLES.NUMBERS ? 44 : 24;
    }
    default: {
      return 44;
    }
  }
}

export const SMILEY_COLOR_MAP = {
  1: '#E73737',
  2: '#FF7D3A',
  3: '#FEBE31',
  4: '#7CD46C',
  5: '#18C98E',
};

export const SMILEY_LIGHT_COLOR_MAP = {
  1: '#F9CDCD',
  2: '#FFDECE',
  3: '#FFEFCB',
  4: '#DEF4DA',
  5: '#C5F1E2',
};

export const THUMB_COLOR_MAP = {
  1: '#E73737',
  2: '#18C98E',
};

export const THUMB_LIGHT_COLOR_MAP = {
  1: '#F9CDCD',
  2: '#C5F1E2',
};

export function getRatingLabelForPlayer({ ratingStyle, ratingScale, iconStyle, optionIndex }) {
  let label;

  const adjustedOptionIndex = getAdjustedOptionIndex(ratingStyle, ratingScale, optionIndex);

  if (ratingStyle === RATING_STYLES.STARS) {
    label = iconStyle === ICON_STYLES.ICONS ? <Star48SVG /> : '⭐';
  } else if (ratingStyle === RATING_STYLES.SMILEYS) {
    label = iconStyle === ICON_STYLES.ICONS ? largeSmileyIconMap[optionIndex] : smileyEmojiMap[optionIndex];
  } else if (ratingStyle === RATING_STYLES.THUMBS) {
    label = iconStyle === ICON_STYLES.ICONS ? largeThumbsIconMap[optionIndex] : thumbsEmojiMap[optionIndex];
  } else if (ratingStyle === RATING_STYLES.NUMBERS) {
    label = iconStyle === ICON_STYLES.ICONS ? String(adjustedOptionIndex) : numberEmojiMap[adjustedOptionIndex];
  }

  return label;
}

export function scaleRatingToPercentage({ rating, ratingScale, ratingStyle }) {
  const optionIndex = ratingOptionIndexListByRatingStyleAndScale[ratingStyle][ratingScale].indexOf(rating);

  return Math.round(((ratingScale - optionIndex - 1) / (ratingScale - 1)) * 100);
}
