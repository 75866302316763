import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { postMessage } from 'helpers/widgetHelpers';
import { validateGuideData, validateContactFormData, sanitizeSentData } from '@playerCommon/helpers/sentDataValidation';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import DOMPurify from 'isomorphic-dompurify';
import { LOCAL_GUIDE_VARIABLE_PREFIX, CONTACT_FORM_PREFIX } from 'global';
import { getIds } from 'helpers/statIdsManagement';
import { getLocalStorage, setLocalStorage } from 'helpers/storage';

const DEFAULT_PREDEFINED_LOCAL_DATA = {
  stonlyCustomerUserId: undefined,
  stonlySessionId: undefined,
  isWidget: 'false',
  stonlyGroupIds: undefined,
  stonlyGroupNames: undefined,
  stonlyUserEmail: undefined,
  guideLanguage: undefined,
};

export const predefinedLocalVariableNameList = Object.keys(DEFAULT_PREDEFINED_LOCAL_DATA);

const processValue = value => {
  if (value === 'true') {
    return true;
  }
  if (value === 'false') {
    return false;
  }
  if (value === "'true'") {
    return 'true';
  }
  if (value === "'false'") {
    return 'false';
  }
  if (value.startsWith("'") && value.endsWith("'") && !Number.isNaN(Number(value.slice(1, -1)))) {
    // case when number is passed as a string in query parameters (wrapped in single quotes)
    // Although it's a number, type of value should stay as string
    return value.slice(1, -1);
  }
  return value;
};

const SentDataContext = React.createContext();

const sanitizeValue = value =>
  DOMPurify.sanitize(value, {
    FORBID_TAGS: ['style', 'a'],
  });

function getGuideDataFromUrlParams(urlParams) {
  const guideVariableKeys = Object.keys(urlParams).filter(key => key.startsWith(LOCAL_GUIDE_VARIABLE_PREFIX));

  return Object.fromEntries(
    guideVariableKeys.map(key => [
      key.replace(LOCAL_GUIDE_VARIABLE_PREFIX, ''),
      processValue(sanitizeValue(urlParams[key])),
    ])
  );
}

function getContactFormDataFromUrlParams(urlParams) {
  const contactFormKeys = Object.keys(urlParams).filter(key => key.startsWith(CONTACT_FORM_PREFIX));
  return contactFormKeys.reduce((acc, key) => {
    const keyToUse = key.replace(CONTACT_FORM_PREFIX, '');
    const value = sanitizeValue(urlParams[key]);

    if (keyToUse === 'email') {
      return {
        ...acc,
        email: value,
      };
    }

    return {
      ...acc,
      additionalInfo: {
        ...acc?.additionalInfo,
        [keyToUse]: value,
      },
    };
  }, {});
}

const useSentDataValue = (predefinedLocalData = DEFAULT_PREDEFINED_LOCAL_DATA, agentAppGuideData) => {
  const location = useLocation();
  const urlParams = queryString.parse(location.search);
  const [sentData, setSentData] = useState({
    contactForm: getContactFormDataFromUrlParams(urlParams),
    guideData: getGuideDataFromUrlParams(urlParams),
  });

  // Object with internal values that can be used as local variables.
  // At the moment there are 3 keys: "stonlyCustomerUserId", "stonlySessionId" and "isWidget"
  // It should be possible to display them in the guide content, add to the url or use in conditions
  // but they should not be included when contact form is submitted or data transmission is sent
  const [predefinedData, setPredefinedData] = useState(predefinedLocalData);

  useEffect(() => {
    const guideDataFromLocalStorage = getLocalStorage('guideData') || {};

    if (Object.keys(guideDataFromLocalStorage).length) {
      setSentData(prevState => ({
        ...prevState,
        guideData: {
          ...prevState.guideData,
          ...guideDataFromLocalStorage,
        },
      }));
    }
    postMessage({ type: 'requestSentData' });
  }, []);

  useEffect(() => {
    const onMessage = message => {
      if (message.data.type === 'responseSentData') {
        setSentData(message.data.params.sentData);
      }
    };
    window.addEventListener('message', onMessage);
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);

  useEffect(() => {
    const setIds = async () => {
      const { customerUserId, sessionId } = await getIds();
      // def#predefinedLocalVariables
      setPredefinedData(prev => ({
        ...prev,
        stonlyCustomerUserId: customerUserId,
        stonlySessionId: sessionId,
      }));
    };
    setIds();
  }, []);

  useEffect(() => setPredefinedData(prev => ({ ...prev, ...predefinedLocalData })), [predefinedLocalData]);

  // These post messages are used for passing data for STANDALONE guides
  useEffect(() => {
    const onMessageForStandaloneGuide = message => {
      if (message.data.type === 'setGuideData') {
        const { isValid, message: errorMessage } = validateGuideData(message.data.value);
        if (!isValid) {
          if (errorMessage) {
            // eslint-disable-next-line no-console
            console.log(errorMessage);
          }
          return;
        }
        const sanitizedData = sanitizeSentData(message.data.value);
        setSentData(prev => ({
          ...prev,
          guideData: {
            ...prev.guideData,
            ...sanitizedData,
          },
        }));
      }

      if (message.data.type === 'clearGuideData') {
        setSentData(prev => ({
          ...prev,
          guideData: {},
        }));
      }

      if (message.data.type === 'setContactFormData') {
        const { isValid, message: errorMessage } = validateContactFormData(message.data.value);
        if (!isValid) {
          if (errorMessage) {
            // eslint-disable-next-line no-console
            console.log(errorMessage);
          }
          return;
        }
        const sanitizedData = sanitizeSentData(message.data.value);
        setSentData(prev => ({
          ...prev,
          contactForm: {
            ...prev.contactForm,
            ...sanitizedData,
          },
        }));
      }

      if (message.data.type === 'clearContactFormData') {
        setSentData(prev => ({
          ...prev,
          contactForm: {},
        }));
      }

      if (message.data.type === 'clearSentData') {
        setSentData({
          guideData: {},
          contactForm: {},
        });
      }
    };
    window.addEventListener('message', onMessageForStandaloneGuide);
    return () => {
      window.removeEventListener('message', onMessageForStandaloneGuide);
    };
  }, []);

  useEffect(() => {
    if (!agentAppGuideData) {
      return;
    }

    const { isValid, message: errorMessage } = validateGuideData(agentAppGuideData);
    if (!isValid) {
      if (errorMessage) {
        // eslint-disable-next-line no-console
        console.log(errorMessage);
      }
      return;
    }
    const sanitizedData = sanitizeSentData(agentAppGuideData);
    setSentData(prev => ({
      ...prev,
      guideData: {
        ...prev.guideData,
        ...sanitizedData,
      },
    }));
  }, [agentAppGuideData]);

  // Handler for setting data that's coming from the guide internally
  // e.g. via data transmission when the guide is in standalone mode
  const setInternalData = useCallback(newData => {
    const { isValid, message: errorMessage } = validateGuideData(newData);
    if (!isValid) {
      if (errorMessage) {
        // eslint-disable-next-line no-console
        console.log(errorMessage);
      }
      return;
    }
    const sanitizedData = sanitizeSentData(newData);
    setSentData(prev => {
      const newGuideData = {
        ...prev.guideData,
        ...sanitizedData,
      };
      Object.keys(newGuideData).forEach(key => {
        if ([null, ''].includes(newGuideData[key])) {
          delete newGuideData[key];
        }
      });

      setLocalStorage('guideData', newGuideData);
      return {
        ...prev,
        guideData: newGuideData,
      };
    });
  }, []);

  useEffect(() => {
    window.setInternalData = setInternalData;
  }, [setInternalData]);

  return useMemo(
    () => ({
      value: {
        contactForm: sentData.contactForm,
        guideData: sentData.guideData,
        localData: {
          ...sentData.guideData,
          ...predefinedData,
        },
      },
      setInternalData,
    }),
    [sentData, setInternalData, predefinedData]
  );
};

function SentDataProvider({ children, predefinedLocalData, agentAppGuideData }) {
  const data = useSentDataValue(predefinedLocalData, agentAppGuideData);
  return <SentDataContext.Provider value={data}>{children}</SentDataContext.Provider>;
}

function useSentData() {
  const context = React.useContext(SentDataContext);
  if (context === undefined) {
    throw new Error('useSentData must be used within a SentDataContext');
  }
  return context.value;
}

function useSetInternalData() {
  const context = React.useContext(SentDataContext);
  if (context === undefined) {
    throw new Error('useSetInternalData must be used within a SentDataContext');
  }
  return context.setInternalData;
}

export { SentDataProvider, useSentData, useSetInternalData };
