import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { getParsedCookie, setCookie } from 'global/windowUtils';
import { playerUrl } from 'global/env';
import callApi from 'helpers/apiHelpers.js';
import { validateEmail, validateEmptyString } from 'helpers/validationHelpers.js';
import { getIds } from 'helpers/statIdsManagement';
import { useTranslation } from 'react-i18next';
import { posthogCapture } from 'components/Analytics/recorder';
import useForm from '@playerCommon/StandardElements/BaseInputs/useForm';
import { FUNNEL_STEP, POSTHOG_EVENT_NAME } from '@stonlyCommons/constants/Authentication.consts';
import { validatePasswordHints } from '@playerCommon/Authentication/PasswordHints/passwordHints.helpers';
import { AUTHENTICATION_TITLE_ID, AUTHENTICATION_VIEWS, FUNNEL_FLOW_TYPE } from '../Authentication.consts.js';
import { RegistrationCanvas, Top, Title, StyledAlert, Terms, TextLink } from '../Authentication.styles';

import RegistrationForm from './RegistrationForm/RegistrationForm.js';

const Registration = ({
  className,
  token,
  setAuthenticationView,
  handlePostAuthentication,
  email: prefilledEmail,
  firstName: prefilledFirstName,
  lastName: prefilledLastName,
  shouldHideTitle,
  funnelFlow,
  funnelId,
}) => {
  const { t } = useTranslation();
  const [accountCreationError, setAccountCreationError] = useState('');
  const [emailFromToken, setEmailFromToken] = useState('');
  const [tokenError, setTokenError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const isEmailPrefilled = !!prefilledEmail;
  const isNamePrefilled = !!prefilledFirstName && !!prefilledLastName;
  const isAuthenticationPage = funnelFlow === FUNNEL_FLOW_TYPE.AUTHENTICATION_REGISTRATION;

  const initialFormState = {
    emailForAccountCreation: prefilledEmail || '',
    firstName: prefilledFirstName || '',
    lastName: prefilledLastName || '',
    passwordForAccountCreation: '',
  };

  const inputRefs = {
    emailForAccountCreation: useRef(null),
    firstName: useRef(null),
    lastName: useRef(null),
    passwordForAccountCreation: useRef(null),
  };

  const validations = [
    ({ emailForAccountCreation }) =>
      emailFromToken ||
      validateEmail(emailForAccountCreation) || {
        emailForAccountCreation: t('Registration.EmailFormatError'),
      },
    ({ emailForAccountCreation }) =>
      emailFromToken ||
      validateEmptyString(emailForAccountCreation) || {
        emailForAccountCreation: t('Registration.RequiredFieldError'),
      },
    ({ firstName }) => validateEmptyString(firstName.trim()) || { firstName: t('Registration.RequiredFieldError') },
    ({ lastName }) => validateEmptyString(lastName.trim()) || { lastName: t('Registration.RequiredFieldError') },
    ({ passwordForAccountCreation }) =>
      Object.values(validatePasswordHints(passwordForAccountCreation)).every(Boolean) || {
        passwordForAccountCreation: t('Registration.PasswordError'),
      },
    ({ passwordForAccountCreation }) =>
      validateEmptyString(passwordForAccountCreation) || {
        passwordForAccountCreation: t('Registration.RequiredFieldError'),
      },
  ];
  const {
    formValues,
    handleFormChange,
    handleWillSubmit,
    handleFormBlur,
    handleFormFocus,
    isFormValid,
    formStatus,
    updateFormFieldStatus,
  } = useForm({ initialFormState, validations, inputRefs });

  const accountCreationFormSubmit = async e => {
    if (submitting) return false;
    handleWillSubmit();
    posthogCapture(POSTHOG_EVENT_NAME.DEMO_SIGNUP_FUNNEL, {
      funnelId,
      flow: funnelFlow,
      step: FUNNEL_STEP.CLICK_SIGN_UP,
    });

    if (isFormValid) {
      const formattedEmail = formValues.emailForAccountCreation.replaceAll(/\s+/g, '').toLowerCase();
      setSubmitting(true);
      setAccountCreationError('');

      const parsedCookie = getParsedCookie();
      const trackers = {};
      if (parsedCookie.firstPageViewed) trackers.firstPageViewed = parsedCookie.firstPageViewed;
      if (parsedCookie.signUpPageLanding) trackers.signUpPageLanding = parsedCookie.signUpPageLanding;
      trackers.signUpPageConversion = window.location.href;

      const { userId } = await getIds();

      callApi(`v1/user${token ? '/fromInvite' : ''}`, 'post', {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        email: emailFromToken || formattedEmail,
        password: formValues.passwordForAccountCreation,
        invitationId: token,
        logUserId: userId,
        segment: trackers,
      })
        .then(res => {
          if (res.data.user) {
            setCookie('firstPageViewed', '', -1); // delete cookie
            setCookie('signUpPageLanding', '', -1); // delete cookie
            posthogCapture(POSTHOG_EVENT_NAME.DEMO_SIGNUP_FUNNEL, {
              funnelId,
              flow: funnelFlow,
              step: FUNNEL_STEP.SIGNUP_SUCCESS,
            });
            handlePostAuthentication({ res, isRegistration: true });
          } else {
            setAccountCreationError(res.data.message);
          }
          setSubmitting(false);
        })
        .catch(res => {
          setAccountCreationError(t('Registration.SignUpError', { url: `${playerUrl}/authentication/signInWithSSO` }));
          setSubmitting(false);
        });
    }
  };

  const onSignInClick = () => {
    posthogCapture(POSTHOG_EVENT_NAME.DEMO_SIGNUP_FUNNEL, {
      funnelId,
      flow: funnelFlow,
      step: FUNNEL_STEP.CLICK_SIGN_IN,
    });
    setAuthenticationView(AUTHENTICATION_VIEWS.SIGN_IN);
  };

  useEffect(() => {
    if (token) {
      callApi(`v1/user?invitationId=${token}`, 'get')
        .then(res => {
          setEmailFromToken(res.data.email);
        })
        .catch(res => {
          setTokenError(true);
        });
    }
  }, []);

  useEffect(() => {
    if (isEmailPrefilled) {
      updateFormFieldStatus('emailForAccountCreation');
    }
  }, [isEmailPrefilled]);

  useEffect(() => {
    posthogCapture(POSTHOG_EVENT_NAME.DEMO_SIGNUP_FUNNEL, {
      funnelId,
      flow: funnelFlow,
      step: FUNNEL_STEP.START,
    });
  }, []);

  return (
    <RegistrationCanvas className={className} shouldHaveOnlyVerticalPadding={!isAuthenticationPage}>
      {!shouldHideTitle && (
        <Top>
          <Title id={AUTHENTICATION_TITLE_ID}>{t('Registration.Title')}</Title>
        </Top>
      )}
      {tokenError ||
        (accountCreationError && (
          <StyledAlert dataCy="signUpError" severity="error">
            {accountCreationError}
          </StyledAlert>
        ))}
      <RegistrationForm
        accountCreationFormSubmit={accountCreationFormSubmit}
        handleFormChange={handleFormChange}
        handleFormBlur={handleFormBlur}
        handleFormFocus={handleFormFocus}
        formValues={formValues}
        isNamePrefilled={isNamePrefilled}
        emailFromToken={emailFromToken}
        formStatus={formStatus}
        inputRefs={inputRefs}
      />
      {!isEmailPrefilled && (
        <Terms>
          {t('Registration.Terms')}
          <br />
          <br />
          {t('Registration.ExistingUser')}
          <TextLink onClick={onSignInClick} data-cy="signIn">
            {t('Registration.SignInLink')}
          </TextLink>
        </Terms>
      )}
    </RegistrationCanvas>
  );
};

Registration.propTypes = {
  className: PropTypes.string,
  setAuthenticationView: PropTypes.func,
  handlePostAuthentication: PropTypes.func,
  token: PropTypes.string,
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  shouldHideTitle: PropTypes.bool,
  funnelId: PropTypes.string,
  funnelFlow: PropTypes.string,
};

export default Registration;
