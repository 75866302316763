import React, { useContext, useMemo } from 'react';
import { FlatTreeContext } from '../FlatTreeProvider';

/**
    Returns a ref to the list container element
*/
export const useTreeRefs = () => {
  const { flatTreeRef, rowRefByUuidRef } = useContext(FlatTreeContext);

  return useMemo(
    () => ({
      flatTreeRef,
      rowRefByUuidRef,
    }),
    []
  );
};
