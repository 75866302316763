import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const LinkWithQuery = ({ children, to, ...props }) => {
  const { search } = useLocation();
  return (
    <Link to={{ pathname: to, search }} {...props}>
      {children}
    </Link>
  );
};

export default LinkWithQuery;
