import { useContext } from 'react';
import { FlatTreeContext } from '../FlatTreeProvider';
import { resolveVisibleRowUuidList } from '../resolvers';

/** List of all rows in FlatTree. Useful typically for extension lik <Dnd> wrapper */
export const useTreeVisibleRowUuidList = () => {
  const { state } = useContext(FlatTreeContext);

  return resolveVisibleRowUuidList(state);
};
