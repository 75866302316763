import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from '@playerCommon/CustomElements/ErrorMessage/ErrorMessage';
import callApi from 'helpers/apiHelpers.js';
import { useTranslation } from 'react-i18next';
import { validateEmail, validateEmptyString } from 'helpers/validationHelpers.js';
import { InputText } from '@playerCommon/ui/components/inputs/InputText';
import { ColumnFlex } from '@playerCommon/ui/components/Flex/Flex';
import useForm from '@playerCommon/StandardElements/BaseInputs/useForm';
import { AUTHENTICATION_TITLE_ID, AUTHENTICATION_VIEWS } from '../Authentication.consts.js';

import {
  Canvas,
  Top,
  Title,
  SubTitle,
  Switch,
  AuthenticationButton,
  SuccessMessage,
  TextLink,
} from '../Authentication.styles.js';

const PasswordRecovery = ({ setAuthenticationView }) => {
  const { t } = useTranslation();
  const [resetError, setResetError] = useState('');
  const [resetSuccess, setResetSuccess] = useState('');

  const initialFormState = {
    emailForLogin: '',
  };

  const inputRefs = {
    emailForLogin: useRef(null),
  };

  const validations = [
    ({ emailForLogin }) =>
      validateEmail(emailForLogin.replaceAll(/\s+/g, '').toLowerCase()) || {
        emailForLogin: t('Registration.EmailFormatError'),
      },
    ({ emailForLogin }) =>
      validateEmptyString(emailForLogin) || { emailForLogin: t('Registration.RequiredFieldError') },
  ];

  const { formValues, handleFormChange, handleWillSubmit, handleFormBlur, handleFormFocus, isFormValid, formStatus } =
    useForm({ initialFormState, validations, inputRefs });

  const recoverPassword = e => {
    e.preventDefault();

    handleWillSubmit();
    setResetSuccess('');
    setResetError('');
    if (!isFormValid) return;

    const formattedEmail = formValues.emailForLogin.replaceAll(/\s+/g, '').toLowerCase();
    callApi('v1/auth/password/request', 'post', {
      email: formattedEmail,
    })
      .then(() => {
        setResetError('');
        setResetSuccess(t('SignIn.PasswordResetSuccess'));
      })
      .catch(() => {
        setResetError(t('SignIn.PasswordResetError'));
        setResetSuccess('');
      });
  };

  useEffect(() => {
    setResetSuccess('');
    setResetError('');
  }, [formValues]);

  return (
    <Canvas>
      <Top>
        <Title id={AUTHENTICATION_TITLE_ID}>{t('SignIn.PasswordRecoveryTitle')}</Title>
      </Top>
      <SubTitle>{t('SignIn.PasswordRecoveryHeader')}</SubTitle>
      <form id="passwordRecoverForm" onSubmit={recoverPassword}>
        {resetError && <ErrorMessage>{resetError}</ErrorMessage>}
        {resetSuccess && <SuccessMessage>{resetSuccess}</SuccessMessage>}
        <ColumnFlex marginBottom={2} marginTop={1}>
          <InputText
            name="emailForLogin"
            autoComplete="email"
            value={formValues.emailForLogin}
            status={formStatus.emailForLogin.type}
            message={formStatus.emailForLogin.message}
            onChange={handleFormChange}
            onBlur={handleFormBlur}
            onFocus={handleFormFocus}
            placeholder="bill.crisper@yourcompany.com"
            label={t('SignIn.EmailFieldTitle')}
            ref={inputRefs.emailForLogin}
          />
        </ColumnFlex>
      </form>
      <AuthenticationButton
        form="passwordRecoverForm"
        fullWidth
        usage="submit"
        type="bigText"
        content={t('Global.Send')}
        value="Submit"
      />
      <Switch>
        <TextLink
          onClick={() => {
            setAuthenticationView(AUTHENTICATION_VIEWS.SIGN_IN);
          }}
        >
          {t('Global.Back')}
        </TextLink>
      </Switch>
    </Canvas>
  );
};

PasswordRecovery.propTypes = {
  setAuthenticationView: PropTypes.func,
};

export default PasswordRecovery;
