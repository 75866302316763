/* eslint-disable import/prefer-default-export */
import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { simpleDebounce } from 'helpers/debounce';
import i18n from 'helpers/i18n';
import { Search, SearchInput, SearchInputIcon, SearchCrossIcon } from './DropdownFilteringInput.styles';

const Canvas = styled(Search)`
  border: none;
  width: 100%;
`;

export const DropdownFilteringInput = ({ placeholder, initialValue = '', onChange }) => {
  const [inputValue, setInputValue] = useState(initialValue);

  const doOnChange = useCallback(simpleDebounce(onChange, { leading: false }), [onChange]);

  const handleInputChange = e => {
    const val = e.target.value || '';

    setInputValue(val);
    doOnChange(val);
  };

  const placeholderToUse = typeof placeholder === 'string' ? placeholder : i18n('Global.Search');

  return (
    <Canvas>
      <SearchInputIcon />
      <SearchInput
        autoFocus
        type="text"
        placeholder={placeholderToUse}
        value={inputValue}
        onChange={handleInputChange}
      />
      <SearchCrossIcon show={inputValue} onClick={handleInputChange} />
    </Canvas>
  );
};
