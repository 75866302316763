import { useMemo } from 'react';

export function useMergeRefs(refs) {
  return useMemo(() => {
    if (refs.every(ref => ref == null)) {
      return null;
    }

    return value => {
      refs.forEach(ref => {
        if (typeof ref === 'function') {
          ref(value);
        } else if (ref != null) {
          ref.current = value;
        }
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, refs);
}
