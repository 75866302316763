import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { appUrl } from 'global/env';
import { getParsedCookie, setCookie } from 'global/windowUtils';
import callApi from 'helpers/apiHelpers.js';
import { useTranslation } from 'react-i18next';
import { validateEmptyString } from 'helpers/validationHelpers';
import { sanitizeURL } from 'helpers/sanitizeHelpers';
import { posthogCapture } from 'components/Analytics/recorder';
import Alert, { Content, IconWrapper } from '@playerCommon/StandardElements/Alert';
import useForm from '@playerCommon/StandardElements/BaseInputs/useForm';
import { InputText } from '@playerCommon/ui/components/inputs/InputText';
import { ColumnFlex } from '@playerCommon/ui/components/Flex/Flex';
import { FUNNEL_STEP, POSTHOG_EVENT_NAME } from '@stonlyCommons/constants/Authentication.consts';

import { Canvas, Top, Title, SubTitle, Switch, AuthenticationButton, TextLink } from '../Authentication.styles.js';

const StyledAlert = styled(Alert)`
  margin-top: 32px;

  ${Content} {
    font-size: 14px;
    line-height: 24px;
  }

  ${IconWrapper} {
    margin-top: 4px;
  }
`;

const ConfirmPassword = ({ setAuthenticationView, email, token, funnelId }) => {
  const { t } = useTranslation();
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [resetSuccess, setResetSuccess] = useState('');
  const [resetError, setResetError] = useState('');

  const initialFormState = {
    password: '',
  };

  const inputRefs = {
    password: useRef(null),
  };

  const validations = [
    ({ password }) =>
      validateEmptyString(password) || {
        password: t('Registration.RequiredFieldError'),
      },
    () =>
      !isPasswordError || {
        password: t('SignIn.PasswordConfirmationError'),
      },
  ];

  const {
    formValues,
    handleFormChange,
    handleWillSubmit,
    handleFormBlur,
    handleFormFocus,
    isFormValid,
    updateFormFieldStatus,
    formStatus,
  } = useForm({ initialFormState, validations, inputRefs });

  const onPasswordFocus = e => {
    setIsPasswordError(false);
    handleFormFocus(e);
  };

  const onResetPassword = async e => {
    setIsResetPassword(true);
    posthogCapture(POSTHOG_EVENT_NAME.ACCOUNT_CONFIRMATION, {
      funnelId,
      step: FUNNEL_STEP.CLICK_RESET_PASSWORD,
    });
    e.preventDefault();

    try {
      await callApi('v1/auth/password/request', 'post', {
        email,
      });
      setResetSuccess(t('SignIn.PasswordConfirmationResetSuccess'));
      posthogCapture(POSTHOG_EVENT_NAME.ACCOUNT_CONFIRMATION, {
        funnelId,
        step: FUNNEL_STEP.RESET_LINK_SENT,
      });
    } catch {
      setResetError(t('SignIn.PasswordConfirmationResetError'));
      posthogCapture(POSTHOG_EVENT_NAME.ACCOUNT_CONFIRMATION, {
        funnelId,
        step: FUNNEL_STEP.RESET_FAILURE,
      });
    }
  };

  const confirmPasswordSubmit = async e => {
    e.preventDefault();
    posthogCapture(POSTHOG_EVENT_NAME.ACCOUNT_CONFIRMATION, {
      funnelId,
      step: FUNNEL_STEP.CLICK_CONFIRM_PASSWORD,
    });
    setIsPasswordError(false);
    handleWillSubmit();

    if (!isFormValid) return;

    try {
      await callApi('v1/auth/password/verify/account', 'post', {
        verificationCode: token,
        email,
        password: formValues.password,
      });
      await callApi('v1/auth/login', 'post', {
        email,
        password: formValues.password,
        publicSSO: true,
      });
      const parsedCookie = getParsedCookie();
      if (parsedCookie.returnUrl) {
        setCookie('returnUrl', '', -1);
        window.location.href = sanitizeURL(parsedCookie.returnUrl);
      } else {
        window.location.href = `${appUrl}/app/general`;
      }
    } catch {
      setIsPasswordError(true);
    }
  };

  const onResetSuccessBackClick = () => {
    posthogCapture(POSTHOG_EVENT_NAME.ACCOUNT_CONFIRMATION, {
      funnelId,
      step: FUNNEL_STEP.CLICK_BACK_TO_SIGN_IN,
    });
    setAuthenticationView('signIn');
  };

  const onResetErrorBackClick = () => {
    posthogCapture(POSTHOG_EVENT_NAME.ACCOUNT_CONFIRMATION, {
      funnelId,
      step: FUNNEL_STEP.CLICK_BACK_FAIL,
    });
    setIsResetPassword(false);
    setResetSuccess('');
    setResetError('');
  };

  useEffect(() => {
    updateFormFieldStatus('password');
  }, [isPasswordError]);

  useEffect(() => {
    posthogCapture(POSTHOG_EVENT_NAME.ACCOUNT_CONFIRMATION, {
      funnelId,
      step: FUNNEL_STEP.START,
    });
  }, []);

  return (
    <Canvas>
      {isResetPassword && resetSuccess && (
        <>
          <Top>
            <Title>{t('SignIn.PasswordConfirmationResetTitle')}</Title>
          </Top>
          <StyledAlert severity="success" dataCy="changePasswordError">
            {resetSuccess}
          </StyledAlert>
          <Switch>
            <TextLink onClick={onResetSuccessBackClick} data-cy="signIn">
              {t('SignIn.BackToSignIn')}
            </TextLink>
          </Switch>
        </>
      )}
      {isResetPassword && resetError && (
        <>
          <Top>
            <Title>{t('SignIn.PasswordConfirmationNotResetTitle')}</Title>
          </Top>
          <StyledAlert severity="error" dataCy="changePasswordError">
            {resetError}
          </StyledAlert>
          <Switch>
            <TextLink onClick={onResetErrorBackClick}>{t('Global.Back')}</TextLink>
          </Switch>
        </>
      )}
      {!isResetPassword && (
        <>
          <Top>
            <Title>{t('SignIn.PasswordConfirmationTitle')}</Title>
          </Top>
          <SubTitle>{t('SignIn.PasswordConfirmationSubtitle')}</SubTitle>
          <form id="confirmPasswordForm" onSubmit={confirmPasswordSubmit}>
            <ColumnFlex gap={2} marginBottom={2}>
              <InputText name="email" value={email} label={t('SignIn.EmailFieldTitle')} data-cy="emailField" disabled />
              <InputText
                type="password"
                name="password"
                value={formValues.password}
                status={formStatus.password.type}
                message={formStatus.password.message}
                onChange={handleFormChange}
                onBlur={handleFormBlur}
                onFocus={onPasswordFocus}
                placeholder=""
                label={t('SignIn.PasswordFieldTitle')}
                data-cy="passwordField"
                ref={inputRefs.password}
              />
            </ColumnFlex>
            <AuthenticationButton
              form="confirmPasswordForm"
              usage="submit"
              type="bigText"
              fullWidth
              content={t('Global.Confirm')}
              value="Submit"
              dataCy="reset"
            />
          </form>
          <Switch>
            <TextLink onClick={onResetPassword} data-cy="resetPassword">
              {t('SignIn.ResetPassword')}
            </TextLink>
          </Switch>
        </>
      )}
    </Canvas>
  );
};

ConfirmPassword.propTypes = {
  setAuthenticationView: PropTypes.func,
  token: PropTypes.string,
  email: PropTypes.string,
  funnelId: PropTypes.string,
};

export default ConfirmPassword;
