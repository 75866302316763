import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { navigationWithKeyboardStyle } from '@playerCommon/CommonStyledComponents/NavigationWithKeyboard';

const ButtonBody = styled.button`
  position: relative;
  display: inline-flex;
  padding: 0 24px;
  height: 36px;
  align-items: center;
  justify-content: center;
  border: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  outline: none;
  font-size: 12px;
  font-family: inherit;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 32px;
  cursor: pointer;
  transition: box-shadow 0.2s;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}

  ${navigationWithKeyboardStyle}
`;

const BaseButton = React.forwardRef(({ className, ariaLabel, dataCy, children, styledAs, ...restProps }, ref) => {
  return (
    <ButtonBody
      ref={ref}
      className={className}
      tabIndex={0}
      value="submit"
      data-cy={dataCy}
      aria-label={ariaLabel || (typeof children === 'string' ? children : undefined)}
      as={styledAs}
      {...restProps}
    >
      {children}
    </ButtonBody>
  );
});

export const baseButtonPropTypes = {
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  dataCy: PropTypes.string,
  styledAs: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
    PropTypes.object,
    PropTypes.array,
    PropTypes.node,
  ]),
};

BaseButton.propTypes = baseButtonPropTypes;

export default BaseButton;
