import { imagesUrl } from 'global/env';
import { STEP_NEXT_SELECTOR_TYPE, STEP_TYPE } from 'global/index';
import callApi from 'helpers/apiHelpers';
import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import styled, { createGlobalStyle, css } from 'styled-components';
import { TTS_SUPPORTED_LANGUAGE_CODES } from 'global';

const SECTION_NAMES = {
  guideTitle: 'guideTitle',
  nextButton: '#next',
  sendButton: '#send',
  backButton: '#back',
  skipButton: '#skip',
  stepNextButton: 'stepNextButton',
  title: 'title',
  content: 'content',
  internalNote: 'internalNote',
  confirmationText: 'confirmationText',
  npsFollowupQuestion: 'followupQuestion',
  npsFollowupAnswer: 'npsFollowupAnswer',
  npsSubmit: 'npsSubmit',
  npsThankYou: 'npsThankYou',
  npsThankYouSkip: 'npsThankYouSkip',
  npsRatingText: 'ratingText',
  npsRatingScale: '#values',
  checklistItem: 'checklistItem',
  contactFormEmail: 'contactFormEmail',
  contactFormTopic: 'topicValue',
  contactFormDescription: 'descriptionValue',
  formAttachments: '#attachments',
  formSuggestions: '#suggestion',
};

const HIGHLIGHTABLE_SECTIONS = {
  [SECTION_NAMES.guideTitle]: () => '.guide-title',
  [SECTION_NAMES.title]: () => '.subtitle, .contact-form-title',
  [SECTION_NAMES.content]: () => '.content-text',
  [SECTION_NAMES.internalNote]: () => '.content-internal-note',
  [SECTION_NAMES.stepNextButton]: ({ id }) =>
    `.stepNextButton-${id}, .radioOption-${id}, .stepNextTile-${id}, .dropdown-wrap, .single-button-wrap-custom-label`,
  [SECTION_NAMES.checklistItem]: ({ id }) => `.checklist-item-${id}`,
  [SECTION_NAMES.contactFormEmail]: () => '.contact-form-email',
  [SECTION_NAMES.contactFormTopic]: () => '.contact-form-topic',
  [SECTION_NAMES.contactFormDescription]: () => '.contact-form-description',
  [SECTION_NAMES.formAttachments]: () => '.contact-form-attachments',
  [SECTION_NAMES.formSuggestions]: () => '.NOT_YET_THERE',
  [SECTION_NAMES.sendButton]: () => '.contact-form-send',
  // not implemented yet, because I have no idea how to do it reasonably
  // [SECTION_NAMES.confirmationText]: () => '.contact-form-confirmation-text',
  [SECTION_NAMES.npsRatingText]: () => '.nps-description',
  [SECTION_NAMES.npsRatingScale]: () => '.nps-rating-scale',
  [SECTION_NAMES.skipButton]: () => '.nps-skip-button',
  // [SECTION_NAMES.npsFollowupQuestion]: () => '.nps-followup-question',
  // [SECTION_NAMES.npsFollowupAnswer]: () => '.nps-followup-answer',
  [SECTION_NAMES.npsSubmit]: () => '.nps-submit-button',
  [SECTION_NAMES.npsThankYou]: () => '.nps-thank-you',
  [SECTION_NAMES.npsThankYouSkip]: () => '.nps-thank-you-skip',
  [SECTION_NAMES.nextButton]: () => '.single-button-wrap',
  [SECTION_NAMES.backButton]: () => '.back-button, .contact-form-back, .nps-back-button',
};

const sectionsOrder = [
  SECTION_NAMES.guideTitle,
  SECTION_NAMES.title,
  SECTION_NAMES.content,
  SECTION_NAMES.internalNote,
  SECTION_NAMES.confirmationText,
  SECTION_NAMES.npsRatingText,
  SECTION_NAMES.npsRatingScale,
  SECTION_NAMES.checklistItem,
  SECTION_NAMES.contactFormEmail,
  SECTION_NAMES.contactFormTopic,
  SECTION_NAMES.contactFormDescription,
  SECTION_NAMES.formAttachments,
  SECTION_NAMES.formSuggestions,
  SECTION_NAMES.stepNextButton,
  SECTION_NAMES.skipButton,
  SECTION_NAMES.sendButton,
  SECTION_NAMES.nextButton,
  SECTION_NAMES.backButton,
];

const sortSections = (a, b) => sectionsOrder.indexOf(a.type) - sectionsOrder.indexOf(b.type);

const highlightStyle = css`
  outline: 2px solid ${props => props.theme.coral};
  outline-offset: 4px;
  border-radius: 4px;
`;

const TTSPlayerWrap = styled.div`
  display: none;
`;

export const TextToSpeechGlobalHighlightStyle = createGlobalStyle`
  ${({ highlightEnabled, stepId, currentHighlightedSection, id }) => {
    const sectionToHighlight = HIGHLIGHTABLE_SECTIONS[currentHighlightedSection]?.({ id });
    if (!highlightEnabled || !sectionToHighlight) {
      return css``;
    }
    if (currentHighlightedSection === SECTION_NAMES.guideTitle) {
      return css`
        ${sectionToHighlight} {
          ${highlightStyle}
        }
      `;
    }
    return css`
      .step-${stepId} ${sectionToHighlight} {
        ${highlightStyle}
      }
    `;
  }}
`;

const filterOutElements =
  ({ stepInfo, displayBackButton, displayStepTitle, isFirstStep }) =>
  ttsDataRow => {
    const { nextStepSelector, stepConnectionList, stepType, specialStepInfo } = stepInfo;

    if (stepType === STEP_TYPE.nps) {
      const isUnsupportedNPSSection = [
        SECTION_NAMES.npsFollowupQuestion,
        SECTION_NAMES.npsFollowupAnswer,
        SECTION_NAMES.npsSubmit,
        SECTION_NAMES.npsThankYou,
        SECTION_NAMES.npsThankYouSkip,
      ].includes(ttsDataRow.type);

      if (isUnsupportedNPSSection) return false;

      if (ttsDataRow.type === SECTION_NAMES.skipButton && !specialStepInfo?.nps?.properties?.allowSkip) {
        return false;
      }
    }
    if (ttsDataRow.type === SECTION_NAMES.guideTitle && !isFirstStep) return false;
    if (ttsDataRow.type === SECTION_NAMES.title && !displayStepTitle) return false;
    if (ttsDataRow.type === SECTION_NAMES.confirmationText) return false;
    if (ttsDataRow.type === SECTION_NAMES.backButton && (!displayBackButton || isFirstStep)) return false;

    if (
      // scrollable steps
      stepConnectionList.length === 1 && // if only one choice
      !stepConnectionList[0].buttonEnabled && // and is disabled
      // we don't want to read back and next (or stepNext) because it's a scrollable step
      [SECTION_NAMES.backButton, SECTION_NAMES.nextButton, SECTION_NAMES.stepNextButton].includes(ttsDataRow.type)
    ) {
      return false;
    }

    if (
      ttsDataRow.type === SECTION_NAMES.stepNextButton &&
      stepConnectionList.length === 1 && // is only one choice
      !stepConnectionList[0].label // and has no label
    ) {
      // we don't want to read an additional label returned by the backend as it's
      // the one containing the title of the next step, which doesn't apply here
      return false;
    }

    if (ttsDataRow.type === SECTION_NAMES.nextButton) {
      if ([STEP_NEXT_SELECTOR_TYPE.radio, STEP_NEXT_SELECTOR_TYPE.select].includes(nextStepSelector)) return true;
      // eslint-disable-next-line sonarjs/prefer-single-boolean-return
      if (
        nextStepSelector === STEP_NEXT_SELECTOR_TYPE.button && // is a button
        stepConnectionList.length === 1 && // a single button
        !stepConnectionList[0].label // with no label
      ) {
        return true;
      }
      return false;
    }

    return true;
  };

export default function useTextToSpeech({
  enabled,
  guideId,
  stepId,
  teamKnowledgeBaseId,
  language,
  stepInfo,
  guideInfo: { firstStepId },
  guideOptions: { displayBackButton, displayStepTitle },
}) {
  const stepTTSDataCache = useRef({});

  const [isTTSRunning, setIsTTSRunning] = useState(false);
  const [isStepFinishedPlaying, setIsStepFinishedPlaying] = useState(false);
  const [currentlyPlayedSectionIndex, setCurrentlyPlayedSectionIndex] = useState(0);
  const [isLoadingTTSData, setIsLoadingTTSData] = useState(false);
  const [currentStepTTSData, setCurrentStepTTSData] = useState([]);
  const isTTSAvailableOnStep = useMemo(() => {
    if (stepInfo.stepType === STEP_TYPE.contactForm) return false;
    return true;
  }, [stepInfo]);

  const getStepTTSData = useCallback(
    async stepIdToLoad => {
      if (!stepTTSDataCache.current[stepIdToLoad]) stepTTSDataCache.current[stepIdToLoad] = {};
      if (stepTTSDataCache.current[stepIdToLoad][language]) return stepTTSDataCache.current[stepIdToLoad][language];

      try {
        setIsLoadingTTSData(true);
        const res = await callApi('v1/guide/audio', 'get', {
          guideId,
          stepId: stepIdToLoad,
          language,
          teamKnowledgeBaseId,
        });

        // I don't really want to discard the fetched data in this case, as it's still valid
        stepTTSDataCache.current[stepIdToLoad][language] = res.data.sort(sortSections);
        setIsLoadingTTSData(false);

        return stepTTSDataCache.current[stepIdToLoad][language];
      } catch {
        setIsLoadingTTSData(false);
        throw new Error(`couldn't get step TTS data`);
      }
    },
    [guideId, language, teamKnowledgeBaseId]
  );

  const startTTS = useCallback(async () => {
    try {
      const stepTTSData = await getStepTTSData(stepId);
      const filteredStepTTSData = stepTTSData.filter(
        filterOutElements({ stepInfo, displayBackButton, displayStepTitle, isFirstStep: stepId === firstStepId })
      );
      setCurrentStepTTSData(filteredStepTTSData);
      setIsTTSRunning(true);
    } catch {
      setIsTTSRunning(false);
    }
  }, [displayBackButton, getStepTTSData, stepId, stepInfo]);

  const stopTTS = useCallback(() => {
    setIsLoadingTTSData(false);
    setIsTTSRunning(false);
    setIsStepFinishedPlaying(false);
  }, []);

  const onSectionAudioEnd = useCallback(() => {
    setCurrentlyPlayedSectionIndex(currentIndex => {
      const nextIndex = currentIndex + 1;
      if (currentStepTTSData[nextIndex]) {
        return nextIndex;
      }
      // if there's no more, we just stop and go back to the first section
      setIsStepFinishedPlaying(true);
      return 0;
    });
  }, [currentStepTTSData]);

  useEffect(() => {
    stopTTS();
    setCurrentlyPlayedSectionIndex(0);
    // basically resume on another step if it was running before stopTTS was fired
    // yes, it will work, because it has the old scope here (remember the useEffect presentation?)
    if (isTTSRunning && TTS_SUPPORTED_LANGUAGE_CODES.includes(language) && isTTSAvailableOnStep) {
      startTTS();
    }
  }, [stepId, language]);

  const ttsGlobalHighlightStyleProps = useMemo(() => {
    if (!isTTSRunning || isStepFinishedPlaying) return {};
    return {
      highlightEnabled: isTTSRunning,
      stepId,
      currentHighlightedSection: currentStepTTSData?.[currentlyPlayedSectionIndex]?.type,
      ...currentStepTTSData[currentlyPlayedSectionIndex],
    };
  }, [isTTSRunning, isStepFinishedPlaying, stepId, currentStepTTSData, currentlyPlayedSectionIndex]);

  useEffect(() => {
    const onMessage = message => {
      if (message.data.type === 'widgetClosed') {
        stopTTS();
      }
    };
    window.addEventListener('message', onMessage);
    return () => window.removeEventListener('message', onMessage);
  }, []);

  const ttsPlayer = useMemo(
    () =>
      enabled ? (
        <TTSPlayerWrap>
          {currentStepTTSData[currentlyPlayedSectionIndex] ? (
            <ReactPlayer
              key={currentlyPlayedSectionIndex}
              playing={isTTSRunning && !isStepFinishedPlaying}
              url={`${imagesUrl.url}/${currentStepTTSData[currentlyPlayedSectionIndex].file}`}
              onEnded={onSectionAudioEnd}
            />
          ) : null}
        </TTSPlayerWrap>
      ) : null,
    [enabled, isTTSRunning, isStepFinishedPlaying, currentStepTTSData, currentlyPlayedSectionIndex, onSectionAudioEnd]
  );

  return {
    ttsGlobalHighlightStyleProps,
    ttsPlayer,
    isTTSRunning,
    isLoadingTTSData,
    isTTSAvailableOnStep,
    startTTS,
    stopTTS,
  };
}
