import React, { useState, useEffect, useRef, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import useDidUpdate from '@playerCommon/hooks/useDidUpdate';
import { postMessage } from 'helpers/widgetHelpers';
import Loom from './customPlayers/Loom';

export const PlayerWrap = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
`;

export const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;

const isPlayerControlsSupported = url => {
  let host = url;
  try {
    host = new URL(url).host;
  } catch {
    // empty catch
  }
  // Loom doesn't provide API for controlling status of the video,
  // that's why React Player's controls doesn't affect on Loom player
  if (host.includes('loom.com')) {
    return false;
  }
  return true;
};

ReactPlayer.addCustomPlayer(Loom);

const VideoPlayer = ({ start, stop, url, light, className, onDuration, onReady = () => {}, autoplay = false }) => {
  const [isPlaying, setIsPlaying] = useState(Boolean(autoplay));
  const isPlayingRef = useRef(isPlaying);
  const [isMuted, setIsMuted] = useState(!autoplay && start > 0);
  const playerRef = useRef();
  const [forceUnmount, setForceUnmount] = useState(false); // when true video should be unmounted

  const setIsPlayingProxy = useCallback(value => {
    setIsPlaying(value);
    isPlayingRef.current = value;
  }, []);

  const onPlay = () => setIsPlayingProxy(true);
  const onPause = () => setIsPlayingProxy(false);

  const onPlayerProgress = time => {
    if (!isPlayingRef.current) return;

    const { playedSeconds } = time;
    if (playedSeconds >= stop) {
      playerRef.current.seekTo(start);
      setIsPlayingProxy(true);
      setIsPlayingProxy(false);
    }
    if (playedSeconds < start) {
      playerRef.current.seekTo(start);
      if (autoplay) {
        setIsPlayingProxy(false);
        setIsPlayingProxy(true);
      }
    }
  };

  const onReadyProxy = () => {
    const playerCurrentTime = playerRef.current.getCurrentTime();

    onReady();

    if (playerCurrentTime < start) {
      playerRef.current.seekTo(start);
      if (!autoplay) {
        setIsPlayingProxy(true);
        setIsPlayingProxy(false);
      }
    }
  };

  const onStart = () => {
    if (!autoplay && start > 0) {
      setIsPlayingProxy(true);
      setIsPlayingProxy(false);
      setIsMuted(false);
    }
  };

  useDidUpdate(() => {
    const playerCurrentTime = playerRef.current.getCurrentTime();

    if (playerCurrentTime < start || playerCurrentTime > stop) {
      playerRef.current.seekTo(start);
      if (autoplay) {
        setIsPlayingProxy(true);
      } else {
        setIsPlayingProxy(false);
      }
    }
  }, [start, stop]);

  useEffect(() => {
    const onMessage = message => {
      if (message.data.type === 'widgetClosed') {
        setIsPlayingProxy(false);

        if (!isPlayerControlsSupported(url)) {
          setForceUnmount(true);
        }
      }

      if (message.data.type === 'widgetOpened') {
        setForceUnmount(false);
      }
    };
    window.addEventListener('message', onMessage);
    return () => window.removeEventListener('message', onMessage);
  }, [url]);

  useEffect(() => {
    const onFullscreenChanged = () => {
      postMessage({ type: document.fullscreenElement ? 'fullscreenEntered' : 'fullscreenExited' });
    };
    document.addEventListener('fullscreenchange', onFullscreenChanged);
    return () => {
      document.removeEventListener('fullscreenchange', onFullscreenChanged);
    };
  }, []);

  return (
    <PlayerWrap data-cy="player" className={className}>
      <StyledReactPlayer
        data-cy="videoPlayer"
        data-stonly-video-player="true"
        ref={playerRef}
        url={forceUnmount ? '' : url}
        playsinline
        playing={isPlaying}
        volume={isMuted ? 0 : 1}
        onProgress={onPlayerProgress}
        onPlay={onPlay}
        onReady={onReadyProxy}
        onPause={onPause}
        onDuration={onDuration}
        onStart={onStart}
        width="100%"
        height="100%"
        light={light}
        controls
        progressInterval={50}
        config={{
          facebook: {
            attributes: {
              width: 1600,
              height: 900,
            },
          },
          youtube: {
            playerVars: {
              showinfo: 0,
              modestbranding: 1,
              controls: 1,
              rel: 0,
            },
          },
          dailymotion: {
            params: {
              controls: true,
            },
          },
          vimeo: {
            playerOptions: {
              controls: true,
            },
          },
          loom: {
            autoplay,
            startTime: start,
          },
        }}
      />
    </PlayerWrap>
  );
};

VideoPlayer.propTypes = {
  start: PropTypes.number,
  stop: PropTypes.number,
  url: PropTypes.string,
  light: PropTypes.bool,
  className: PropTypes.string,
  onDuration: PropTypes.func,
  onReady: PropTypes.func,
  autoplay: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
};

export default memo(VideoPlayer);
