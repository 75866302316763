import React from 'react';

interface PermissionsContextType {
  permissions: string[];
  currentPlan: string;
  currentPlanFull: string;
  currentPeriod: string;
  upgradePlan: () => void;
  isTrial: boolean;
  goToUpgrade: () => void;
  getPermission: (permissionName: string) => number | boolean | string;
  getTeamPermission: (permissionName: string) => number | boolean | string;
}

const permissionsContext = React.createContext<PermissionsContextType>({
  permissions: [],
  currentPlan: '',
  currentPlanFull: '',
  currentPeriod: '',
  upgradePlan: () => 0,
  isTrial: false,
  goToUpgrade: () => 0,
  getPermission: () => 0,
  getTeamPermission: () => 0,
});

export default permissionsContext;
