import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import ArrowRightSVG from 'icons/arrowRight.svg';
import ExternalLinkSVG from 'icons/externalLink.svg';
import { navigationWithKeyboardStyle } from '@playerCommon/CommonStyledComponents/NavigationWithKeyboard';
import { TILE_LAYOUT, TILE_IMAGE_POSITION } from 'global/index';
import { imagesUrl } from 'global/env';
import { TileImage } from './TileImage';

const ArrowRightIcon = styled(ArrowRightSVG)`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translate3d(-25%, -50%, 0);
  opacity: 0;

  path {
    fill: ${props => props.theme.highlightColor};
  }

  transition: transform 0.2s, opacity 0.2s;
`;

const ExternalLinkIcon = styled(ExternalLinkSVG)`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate3d(-25%, -50%, 0);
  opacity: 0;

  path {
    fill: ${props => props.theme.highlightColor};
  }

  transition: transform 0.2s, opacity 0.2s;
`;

const Canvas = styled.a`
  ${navigationWithKeyboardStyle};
  position: relative;
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.paleGrey};
  color: ${props => props.theme.darkGrey};
  border-radius: 4px;
  text-decoration: none;
  padding: 16px 24px;
  font-size: 16px;
  box-shadow: none;
  transition: box-shadow 0.2s;
  min-height: 72px;
  width: 100%;
  gap: 16px;

  ${({ hasMedia }) =>
    !hasMedia &&
    css`
      padding-right: 56px;
    `}

  ${({ hasMedia, imagePosition, isTextHidden }) => {
    if (hasMedia && isTextHidden) {
      return css`
        justify-content: center;
      `;
    }
    if (hasMedia) {
      if (imagePosition === TILE_IMAGE_POSITION.right) {
        return css`
          justify-content: space-between;
          flex-direction: row-reverse;
        `;
      }
      if (imagePosition === TILE_IMAGE_POSITION.left) {
        return css`
          justify-content: flex-start;
          flex-direction: row;
        `;
      }
      if (imagePosition === TILE_IMAGE_POSITION.top) {
        return css`
          flex-direction: column;
          gap: 8px;
        `;
      }
      if (imagePosition === TILE_IMAGE_POSITION.bottom) {
        return css`
          flex-direction: column-reverse;
          gap: 8px;
        `;
      }
    }
  }}

  @media (hover: hover) and (pointer: fine) {
    ${({ disabled }) =>
      !disabled &&
      css`
        cursor: pointer;

        &:hover {
          box-shadow: 0 1px 4px 0 ${props => props.theme.canvasBox};

          ${ArrowRightIcon}, ${ExternalLinkIcon} {
            opacity: 1;
            transform: translate3d(0%, -50%, 0);
          }
        }
      `}
  }

  @media screen and (max-width: 899px) {
    font-size: 14px;
  }

  ${({ added }) =>
    added &&
    css`
      background: ${props => props.theme.changesAddedColor};
    `}

  ${({ removed }) =>
    removed &&
    css`
      background: ${props => props.theme.changesRemovedColor};
    `}

  ${({ modified }) =>
    modified &&
    css`
      background: ${props => props.theme.changesModifiedColor};
    `}

  ${({ smaller }) =>
    smaller &&
    css`
      font-size: 14px;
    `}
`;

const TileGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;

  ${Canvas} {
    ${({ layout }) => {
      if (layout === TILE_LAYOUT.grid) {
        return css`
          width: calc(50% - 12px);
        `;
      }
      return css`
        width: 100%;
      `;
    }}
  }

  @media screen and (max-width: 480px) {
    ${Canvas} {
      width: 100%;
      margin-right: 0;
    }
  }
`;

const Tile = ({
  content,
  added,
  removed,
  modified,
  isExternalLink,
  urlToOpen,
  disabled,
  onClick,
  className,
  smaller,
  dataStaticValue,
  shouldOpenInNewTab,
  imagePosition,
  imageSize,
  media,
  isTextHidden,
}) => {
  const handleKeyDown = e => {
    if (e.key === ' ' || e.key === 'Enter') {
      onClick(e);
    }
  };

  const hasMedia = imagePosition !== TILE_IMAGE_POSITION.none && media && Object.keys(media).length !== 0;
  const shouldShowContent = !isTextHidden || imagePosition === TILE_IMAGE_POSITION.none;
  const imageSrc =
    hasMedia && `${imagesUrl.url}/${media.path}/${media.imageId}.${media.ext}?s=${media.imageIdSecureHash}`;

  return (
    <Canvas
      data-cy="tileGrid"
      data-static-value={dataStaticValue}
      className={className}
      onClick={disabled ? undefined : onClick}
      onKeyDown={handleKeyDown}
      added={added}
      removed={removed}
      modified={modified}
      disabled={disabled}
      smaller={smaller}
      href={!disabled && isExternalLink ? urlToOpen : undefined}
      target={!disabled && isExternalLink && shouldOpenInNewTab ? '_blank' : undefined}
      role={!isExternalLink && 'button'}
      rel={!disabled && isExternalLink ? 'noopener' : ''}
      tabIndex={0}
      hasMedia={hasMedia}
      imagePosition={imagePosition}
      isTextHidden={isTextHidden}
    >
      {hasMedia && (
        <TileImage
          imageSrc={imageSrc}
          imageSize={imageSize}
          imagePosition={imagePosition}
          isTextHidden={isTextHidden}
        />
      )}
      {shouldShowContent && content}
      {!hasMedia && <>{isExternalLink ? <ExternalLinkIcon /> : <ArrowRightIcon />}</>}
    </Canvas>
  );
};

Tile.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  added: PropTypes.bool,
  removed: PropTypes.bool,
  modified: PropTypes.bool,
  isExternalLink: PropTypes.bool,
  urlToOpen: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  smaller: PropTypes.bool,
  dataStaticValue: PropTypes.string,
  shouldOpenInNewTab: PropTypes.bool,
  imagePosition: PropTypes.string,
  imageSize: PropTypes.string,
  media: PropTypes.object,
  isTextHidden: PropTypes.bool,
};

export { TileGrid };

export default Tile;
