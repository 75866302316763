/* eslint-disable unicorn/numeric-separators-style */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import BackButton from '@playerCommon/CustomElements/BackButton';
import { AI_ANSWER_STEP_NEXT_TYPE } from 'global';
import { replaceVarNamesWithValues } from '@stonlyCommons/helpers/guideVariableHelpers';
import { getGuideIdAndStepFromLink } from 'helpers/guidePlayerHelpers';
import { useSentData } from '@playerCommon/Contexts/sentDataContext';
import { useUserData } from '@playerCommon/Contexts/userDataContext';
import useGuideData from '@playerCommon/hooks/playerHooks/useGuideData';
import Loader from '@playerCommon/CustomElements/Loader/Loader';
import { SmartSearchAnswerWrap, SmartSearchResultsSection, StepCover } from './AiAnswer.styles';
import { ButtonsWrap } from '../ExplanationPlayer/Steps/Steps.styles';

import { useSmartSearchResults } from './useSmartSearchResults';
import { savePathToAiAnswer } from './AiAnswer.helpers';
import AiAnswerActionsList from './components/AiAnswerActionsList';
import AiAnswerPendingAnswer from './components/AiAnswerPendingAnswer';
import AiAnswerSingleAnswer from './components/AiAnswerSingleAnswer';
import { AiAnswerSingleMessageWrap } from './components/AiAnswerSingleMessage';
import AiAnswerMessageInput from './components/AiAnswerMessageInput';

const MIN_COMPACT_HEIGHT = 560; // max-height of the widget is 560

function AiAnswer({
  aiAnswerInfo = {},
  language,
  onStepNextClick,
  nextSteps,
  compact,
  scrollStepToBottom,
  scrollStepToTop,
  pathFromUrl,
  stepId,
  // this we need for sending the right guideId to the AI endpoint
  currentGuideId,
  teamId,
  // StepNavigation props below
  isFirstStep,
  previousUrlToLoad,
  onBackLinkClick,
  isPreview,
  stepConnectionList,
  shouldEveryConnectionBeEnabled,
  onShouldShowNextButtonSelectorChange,
}) {
  const history = useHistory();
  const { t } = useTranslation('player', { keyPrefix: 'AiStep' });

  const backButtonRef = useRef();
  const resultsSectionRef = useRef();
  const [initialMinHeightSet, setInitialMinHeightSet] = useState();
  const [externalGuideIsLoading, setExternalGuideIsLoading] = useState(false);

  const fallbackList = useMemo(
    () => nextSteps.filter(sn => sn.aiStepNextType === AI_ANSWER_STEP_NEXT_TYPE.aiFallback),
    [nextSteps]
  );
  const actionsList = useMemo(
    () => nextSteps.filter(sn => sn.aiStepNextType === AI_ANSWER_STEP_NEXT_TYPE.aiAction),
    [nextSteps]
  );

  const { guide, guideInfo, loadAdditionalGuide, displayProgressBar, guideTeamKnowledgeBaseId } = useGuideData();

  const goToAiSuggestedGuide = useCallback(
    async ({ guideToOpen, startingStepId }) => {
      if (!guideToOpen) return;

      let stepToOpen;

      const alreadyExistingGuide = guide.allGuides.find(g => g.guideId === guideToOpen);

      if (alreadyExistingGuide) {
        stepToOpen = alreadyExistingGuide.firstStepId;
      } else {
        setExternalGuideIsLoading(true);
        const loadedGuide = await loadAdditionalGuide(guideToOpen);
        setExternalGuideIsLoading(false);
        if (!loadedGuide) {
          return;
        }
        stepToOpen = loadedGuide.guideInfo.firstStepId;
      }

      if (startingStepId) {
        stepToOpen = startingStepId;
      }

      const { guideId: currentMainGuideId } = guideInfo;
      savePathToAiAnswer(currentMainGuideId, pathFromUrl);

      setTimeout(() => {
        history.push(`${stepToOpen}`);
      }, 50);
    },
    [guide, history, guideInfo, loadAdditionalGuide]
  );

  const { stepModuleId, isConversationModeEnabled = true, fallbackMessage, hasNoSources } = aiAnswerInfo;

  const { localData } = useSentData();
  const userData = useUserData();
  const { fallbackMessageToDisplay } = useMemo(() => {
    if (!fallbackMessage) {
      return { fallbackMessageToDisplay: t('DefaultFallbackMessage') };
    }
    return {
      fallbackMessageToDisplay: replaceVarNamesWithValues({
        content: fallbackMessage,
        userData,
        localData,
      }),
    };
  }, [fallbackMessage, localData, userData]);

  const {
    // smartSearchResultTextNodeRef,
    smartSearchPendingAnswersList,
    smartSearchAnswers,
    smartSearchAnswerDone,
    smartSearchCurrentQuestion,
    onMakeAdditionalSmartSearchCall,
    onFeedbackClick,
    followupLimitReached,
    onResetSession,
    onLinkClick,
  } = useSmartSearchResults({
    stepModuleId,
    currentLanguage: language,
    errorMessage: t('NoAnswerError'), // fallbackMessageToDisplay,
    guideId: currentGuideId,
    stepId,
    scrollToBottomFunction: scrollStepToBottom,
    onGoToAiSuggestedGuide: goToAiSuggestedGuide,
    teamId,
    knowledgeBaseId: guideTeamKnowledgeBaseId,
  });

  const isInputDisabled = followupLimitReached || isPreview || hasNoSources;
  const [followupQuestion, setFollowupQuestion] = useState('');

  const onResetSessionProxy = useCallback(() => {
    onResetSession?.();
    setTimeout(() => {
      window.dispatchEvent(new Event('ai-answer-reset'));
      scrollStepToTop();
    }, 0);
  }, [onResetSession, scrollStepToTop]);

  const onFollowupQuestionChange = useCallback(e => {
    setFollowupQuestion(e.target.value);
  }, []);

  const onFollowupQuestionSubmit = useCallback(
    e => {
      e?.preventDefault();

      // TODO: REMOVE THIS TEMP CODE ADDED FOR DEMO PURPOSES
      if (followupQuestion.startsWith('debug, open guide ')) {
        goToAiSuggestedGuide({ guideToOpen: followupQuestion.replace('debug, open guide ', '') });
        return;
      }
      const trimmedQuestion = followupQuestion.trim();

      if (trimmedQuestion) {
        onMakeAdditionalSmartSearchCall(trimmedQuestion);
        setFollowupQuestion(''); // this has to happen here, or the input will not reset
      }
    },
    [followupQuestion, onMakeAdditionalSmartSearchCall]
  );

  const onPredefinedQuestionSelected = useCallback(question => {
    setFollowupQuestion('');
    onMakeAdditionalSmartSearchCall(question);
    setTimeout(() => {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }, 0);
  }, []);

  const onLinkClickProxy = useCallback(
    e => {
      if (e.target.tagName === 'A') {
        const url = e.target.getAttribute('href');
        e.preventDefault();
        const answerElement = e.target.closest('.ai-step-result-text:not(.ai-step-result-text-fallback)');
        const questionAnswerId = answerElement?.dataset.aiAnswerId;

        if (questionAnswerId) {
          const questionAnswerData = smartSearchAnswers.find(
            smartSearchAnswer => smartSearchAnswer.questionAnswerId === questionAnswerId
          );
          const linkData = questionAnswerData?.links?.find(link => link.url === url);

          onLinkClick({
            questionAnswerId,
            originalQuestion: questionAnswerData?.question,
            answer: questionAnswerData?.answer,
            phrase: questionAnswerData?.rephrasedQuestion,
            link: url,
            stepId: linkData?.stepId,
            guideId: linkData?.guideId,
            sourceId: linkData?.sourceId,
            contentId: linkData?.contentId,
            linkType: linkData?.linkType,
            page: linkData?.page,
            sectionId: linkData?.sectionId,
            linkContent: e.target.textContent,
          });
        }

        const stonlyGuideLinkData = getGuideIdAndStepFromLink(url);

        if (stonlyGuideLinkData) {
          goToAiSuggestedGuide({
            guideToOpen: stonlyGuideLinkData.guideId,
            startingStepId: stonlyGuideLinkData.stepId,
          });
          setTimeout(() => scrollStepToBottom(), 0); // so that the loader is visible
        } else {
          window.open(url, '_blank');
        }
      }
    },
    [smartSearchAnswers, onLinkClick, goToAiSuggestedGuide, scrollStepToBottom]
  );

  useEffect(() => {
    function computeMinHeight() {
      const guideCanvas = document.querySelector('.guide-canvas');
      const footers = document.querySelectorAll('.steps-footer');
      const stepContentWrap = document.querySelector('.step-content-wrap');

      let footerHeight = Math.max(...[...footers].map(el => el.offsetHeight));
      if (!Number.isFinite(footerHeight)) footerHeight = 0;

      const computedMinHeight =
        (compact ? Math.max(MIN_COMPACT_HEIGHT, guideCanvas.offsetHeight) : guideCanvas.offsetHeight) -
        footerHeight -
        (stepContentWrap.offsetHeight - resultsSectionRef.current.offsetHeight);

      resultsSectionRef.current.style.minHeight = `${computedMinHeight}px`;
      setInitialMinHeightSet(true);
    }
    setTimeout(() => {
      computeMinHeight();
    }, 0);

    window.addEventListener('resize', computeMinHeight);

    return () => window.removeEventListener('resize', computeMinHeight);
  }, []);

  return (
    <>
      <StepCover hidden={initialMinHeightSet} />
      {!isFirstStep && previousUrlToLoad && (
        <ButtonsWrap currentlyActive noTopMargin className="button-wrap" ref={backButtonRef}>
          <BackButton
            link={previousUrlToLoad}
            onClick={onBackLinkClick}
            className="back-button"
            dataCy="backButton"
            disabled={isPreview}
          />
        </ButtonsWrap>
      )}
      <SmartSearchResultsSection ref={resultsSectionRef} initialMinHeightSet={initialMinHeightSet}>
        {smartSearchAnswers.map((smartSearchAnswer, index) => (
          <AiAnswerSingleAnswer
            key={smartSearchAnswer.questionAnswerId}
            smartSearchAnswer={smartSearchAnswer}
            onLinkClickProxy={onLinkClickProxy}
            onFeedbackClick={onFeedbackClick}
            onStepNextClick={onStepNextClick}
            stepId={stepId}
            isPreview={isPreview}
            fallbackList={fallbackList}
            fallbackMessageToDisplay={fallbackMessageToDisplay}
            smartSearchAnswerDone={smartSearchAnswerDone}
            stepConnectionList={stepConnectionList}
            shouldEveryConnectionBeEnabled={shouldEveryConnectionBeEnabled}
            onShouldShowNextButtonSelectorChange={onShouldShowNextButtonSelectorChange}
            onPredefinedQuestionSelected={onPredefinedQuestionSelected}
            isLast={index === smartSearchAnswers.length - 1}
          />
        ))}
        {followupLimitReached && smartSearchAnswerDone && (
          <SmartSearchAnswerWrap>
            <AiAnswerSingleMessageWrap classList="ai-step-result-text">
              {t('FollowupLimitReached')}
            </AiAnswerSingleMessageWrap>
          </SmartSearchAnswerWrap>
        )}
        {!smartSearchAnswerDone && (
          <AiAnswerPendingAnswer
            currentQuestion={smartSearchCurrentQuestion}
            pendingAnswersList={smartSearchPendingAnswersList}
          />
        )}
        {externalGuideIsLoading && <Loader />}
        <AiAnswerActionsList
          showActions={!hasNoSources && !smartSearchAnswers.length && smartSearchAnswerDone}
          options={actionsList}
          onStepNextClick={onStepNextClick}
          stepId={stepId}
          isPreview={isPreview}
          stepConnectionList={stepConnectionList}
          shouldEveryConnectionBeEnabled={shouldEveryConnectionBeEnabled}
          onShouldShowNextButtonSelectorChange={onShouldShowNextButtonSelectorChange}
          onPredefinedAiQuestionSelect={onPredefinedQuestionSelected}
        />
        <AiAnswerMessageInput
          value={followupQuestion}
          onChange={onFollowupQuestionChange}
          onSubmit={onFollowupQuestionSubmit}
          disabled={isInputDisabled}
          onResetSession={onResetSession ? onResetSessionProxy : undefined}
          visible={smartSearchAnswerDone && isConversationModeEnabled}
          messagesCount={smartSearchAnswers.length}
          isPreview={isPreview}
          compact={compact}
          displayProgressBar={displayProgressBar}
        />
      </SmartSearchResultsSection>
    </>
  );
}

AiAnswer.propTypes = {
  aiAnswerInfo: PropTypes.object,
  language: PropTypes.string,
  onStepNextClick: PropTypes.func,
  nextSteps: PropTypes.array,
  compact: PropTypes.bool,
  isFirstStep: PropTypes.bool,
  pathFromUrl: PropTypes.string,
  stepId: PropTypes.number,
  currentGuideId: PropTypes.string,
  previousUrlToLoad: PropTypes.string,
  onBackLinkClick: PropTypes.func,
  isPreview: PropTypes.bool,
  stepConnectionList: PropTypes.array,
  shouldEveryConnectionBeEnabled: PropTypes.bool,
  onShouldShowNextButtonSelectorChange: PropTypes.func,
  scrollStepToBottom: PropTypes.func,
  teamId: PropTypes.number,
};

export default AiAnswer;
