import styled, { css, keyframes } from 'styled-components';

import DownPickerSVG from 'icons/downPicker.svg';
import ConditionalNextStepIconSVG from 'icons/conditionalNextStep.svg';
import Badge from '@playerCommon/CustomElements/Badge';

import {
  BLINKING_CLASS_NAME,
  BLINKING_CLASS_TIMEOUT_MILLISECONDS,
} from '@playerCommon/ComplexElements/FlatTree/parameters';

export const Content = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  border-radius: 2px;
  transition: box-shadow 0.2s;
  line-height: 16px;
`;

export const ContentColumn = styled(Content)`
  flex-direction: column;
`;

export const ContentFooter = styled.div`
  margin-top: 10px;
  color: ${props => props.theme.slateGrey};
  font-size: 12px;
  line-height: 12px;

  &:hover {
    text-decoration: underline;
  }
`;

const BaseRowContainer = styled.div`
  position: relative;
  display: flex;
  min-width: fit-content;
  align-items: center;
  list-style-type: none;
  padding: 12px;
  margin-left: -8px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  outline: none;
  border-radius: 4px;
  ${({ isActive, isSelected, theme }) => {
    return (
      (isActive || isSelected) &&
      css`
        & svg path,
        & svg circle {
          fill: ${isSelected ? 'rgba(254, 190, 49, 0.8)' : theme.secondaryColor};
        }
      `
    );
  }}

  body.navigating-with-keyboard &:focus {
    ${Content} {
      box-shadow: 0px 0px 0px 4px white, 0px 0px 0px 6px ${props => props.theme.darkBlue};
      border-radius: 2px;
    }
  }
`;

const BaseIconWrap = styled.div`
  margin-right: 8px;
  z-index: 2;
  height: 16px;

  & svg {
    height: 16px;
  }
`;

export const ContentWrap = styled.div``;

export const RowBadge = styled(Badge)`
  margin-left: 8px;
`;

const blinkFromSelected = theme => keyframes`
  0% {
    background-color: #FAECD0;
  }

  22% {
    background-color: ${theme.secondaryColor}33;
  }

  28% {
    background-color: ${theme.secondaryColor}33;
  }

  100% {
    background-color:  #FAECD0;
  }
`;

const blink = theme => keyframes`
  0% {
    background-color: ${theme.seaweed}00;
  }

  22% {
    background-color: ${theme.secondaryColor}33;
  }

  28% {
    background-color: ${theme.secondaryColor}33;
  }

  100% {
    background-color: ${theme.seaweed}00;
  }
`;

export const RowCanvas = styled.div`
  width: fit-content;
  min-width: 100%;
  border-radius: 2px;
  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      & > ${BaseRowContainer} {
        background-color: #faecd0;
      }
    `}
  ${({ isUnlinked, theme }) =>
    isUnlinked &&
    css`
      margin-left: 10px;
      min-width: calc(100% - 10px);
      padding-left: 6px;
      border-left: 2px solid ${theme.grey};
    `}
  &.${BLINKING_CLASS_NAME} > ${BaseRowContainer} {
    animation-name: ${({ isSelected, theme }) => (isSelected ? blinkFromSelected(theme) : blink(theme))};
    animation-duration: ${BLINKING_CLASS_TIMEOUT_MILLISECONDS}ms;
    animation-iteration-count: infinite;
  }
`;

/*

  Connection

*/

export const RowConnectionContainer = styled(BaseRowContainer)`
  color: ${props => props.theme.steel};
  cursor: pointer;
`;

export const ConnectionIconWrap = styled(BaseIconWrap)`
  ${({ isOpen }) =>
    isOpen &&
    css`
      ${FolderIcon} {
        transform: rotate(0deg);
      }
    `};
`;

export const FolderIcon = styled(DownPickerSVG)`
  transform: rotate(-90deg);
  height: 16px;
  flex-shrink: 0;
  transition: transform 0.2s ease-in-out;

  & path,
  & circle,
  & rect {
    fill: ${props => props.theme.lightGrey};
  }
`;

export const ConditionalNextStepIcon = styled(ConditionalNextStepIconSVG)`
  height: 16px;
  flex-shrink: 0;
  margin-left: 8px;
  transition: transform 0.2s ease-in-out;

  & path,
  & circle,
  & rect {
    fill: ${props => props.theme.peach};
  }
`;

/*

  Step

*/

export const RowStepContainer = styled(BaseRowContainer)`
  cursor: default;
  align-items: stretch;

  ${({ disabled, theme }) =>
    !disabled &&
    css`
      cursor: pointer;
      &:hover {
        color: ${theme.darkGrey};
        & svg path,
        & svg circle {
          fill: ${theme.secondaryColor};
        }
      }
    `}

  ${({ isActive, theme }) =>
    isActive &&
    css`
      background: rgba(211, 213, 218, 0.2);
    `}
`;

export const StepIconWrap = styled(BaseIconWrap)`
  & svg path,
  & svg circle {
    fill: ${({ theme }) => theme.slateGrey};
    ${({ disabled, theme }) => disabled && `fill: ${theme.lightGrey}`};
  }

  ${({ isActive, theme }) =>
    isActive &&
    css`
      & svg path,
      & svg circle {
        fill: ${theme.secondaryColor};
      }
    `}
`;

/*

  Placeholder

*/

export const RowPlaceholderContainer = styled(BaseRowContainer)`
  padding: 8px;
`;

/*

  Header

*/

export const HeaderContent = styled(Content)`
  color: ${({ theme }) => theme.steel};
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
`;
