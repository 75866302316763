import React from 'react';
import styled, { keyframes } from 'styled-components';

const fadeKeyframes = keyframes`
  0% {
    opacity: 0.5;
    transform: translate3d(0, 0px, 0);
  }
  20% {
    opacity: 1;
    transform: translate3d(0, -4px, 0);
  }
  40% {
    opacity: 0.5;
    transform: translate3d(0, 2px, 0);
  }
  50% {
    transform: translate3d(0, 0px, 0);
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
    transform: translate3d(0, 0px, 0);
  }
`;

const Dot = styled.div`
  display: block;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: center;
  animation-timing-function: ease-in-out;
  animation-name: ${fadeKeyframes};
  width: 8px;
  height: 8px;
  background: ${props => props.theme.charcoal};
  border-radius: 50%;
  transform-origin: bottom;
`;

const DotsWrap = styled.div`
  display: flex;
  align-items: center;
  height: 8px;
  width: 34px;
  justify-content: space-between;

  ${Dot}:nth-child(1) {
    animation-delay: -0.2s;
  }
  ${Dot}:nth-child(3) {
    animation-delay: 0.2s;
  }
`;

const TypingIndicatorAnimated = () => (
  <DotsWrap className="dotsWrap">
    <Dot className="dot" />
    <Dot className="dot" />
    <Dot className="dot" />
  </DotsWrap>
);

export default TypingIndicatorAnimated;
