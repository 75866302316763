import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Row from '@playerCommon/CustomElements/Row/Row';
import { logout } from 'helpers/userManagement.js';
import { isCorrectTeamId } from 'helpers/teamManagement';
import ConsoleIconSVG from 'icons/console.svg';
import TeamIconSVG from 'icons/team.svg';
import SettingsIconSVG from 'icons/settings.svg';
import HelpIconSVG from 'icons/help.svg';
import LogoutIconSVG from 'icons/power.svg';
import { appUrl, playerUrl } from 'global/env';

const Canvas = styled.div`
  padding: 12px;
  color: ${props => props.theme.slateGrey};
`;

const Actions = ({ closeDropdown, user, match }) => {
  const { t } = useTranslation();

  let absolutes = false;
  absolutes = typeof window === 'undefined' ? true : window.location.origin === playerUrl;
  const preUrl = absolutes ? appUrl : '';

  const {
    params: { teamId },
  } = match;

  const teamInfo = useMemo(() => {
    if (isCorrectTeamId(teamId)) {
      const foundTeam = user.teams.find(t => t.teamId === +teamId);
      if (foundTeam) {
        return { teamId: foundTeam.teamId, homeFolderId: foundTeam.homeFolderId };
      }
    }
    const firstTeamWithAdminRight = user.teams.find(t => t.rights === 'admin');
    if (firstTeamWithAdminRight) {
      const { teamId: foundTeamId, homeFolderId } = firstTeamWithAdminRight;
      return { teamId: foundTeamId, homeFolderId };
    }
    return { teamId: user.teams.length ? '-1' : 0, homeFolderId: '' };
  }, [user, teamId]);

  return (
    <Canvas data-cy="actions">
      {[
        {
          id: 'guidesManagementLink',
          content: t('HeaderUserMenu.ExplanationsLink'),
          link: {
            href: `${preUrl}/app/general/${teamInfo.teamId}/guides/${teamInfo.homeFolderId}`,
            absolute: absolutes,
          },
          icon: ConsoleIconSVG,
          onClick: closeDropdown,
        },
        {
          id: 'teamLink',
          content: t('HeaderUserMenu.TeamLink'),
          link: { href: `${preUrl}/app/general/${teamInfo.teamId}/teamSettings/`, absolute: absolutes },
          icon: TeamIconSVG,
          onClick: closeDropdown,
        },
        {
          id: 'settingsLink',
          content: t('HeaderUserMenu.UserSettingsLink'),
          link: { href: `${preUrl}/app/general/userSettings/`, absolute: absolutes },
          icon: SettingsIconSVG,
          onClick: closeDropdown,
        },
        {
          id: 'helpcenter',
          content: t('HeaderUserMenu.HelpCenter'),
          link: { href: 'https://help.stonly.com', newWindow: true },
          icon: HelpIconSVG,
          onClick: closeDropdown,
        },
        {
          id: 'logout',
          content: t('HeaderUserMenu.Logout'),
          icon: LogoutIconSVG,
          onClick: logout,
        },
      ].map(action => (
        <Row
          key={action.id}
          content={action.content}
          bulletMedia={[action.icon]}
          bulletMediaSize={24}
          link={action.link}
          action={action.onClick}
          role="link"
        />
      ))}
    </Canvas>
  );
};

Actions.propTypes = {
  closeDropdown: PropTypes.func,
  user: PropTypes.object,
  match: PropTypes.object,
};

export default withRouter(Actions);
