import React from 'react';
import { useTheme } from 'styled-components';
import { STATIC_CLASS_NAME } from '@playerCommon/ui/constants';
import { mergeClassNames } from '@playerCommon/ui/utils/mergeClassNames';
import { TooltipIcon } from '@playerCommon/ui/components/Tooltip/TooltipIcon';
import HelpSVG from 'resources/icons/tip.svg';

import {
  renderMessageStatusIconForInputStatus,
  resolveMessageColorForInputStatus,
} from '@playerCommon/ui/components/inputs/_shared/helpers';
import Base from './InputWrap.styles';
import type { InputWrapProps } from './InputWrap.types';

/**
 * Wrapper for a single FieldXX that makes it InputXX - which means label on top, colored message on bottom etc.
 */
export const InputWrap = ({
  labelDomId,
  messageDomId,
  required,
  children,
  status,
  message,
  label,
  className,
  tooltip,
}: InputWrapProps) => {
  const theme = useTheme();
  const statusIcon = renderMessageStatusIconForInputStatus(status);
  const statusMessageColor = resolveMessageColorForInputStatus(theme, status);
  return (
    <Base.InputContainer
      className={mergeClassNames(className, STATIC_CLASS_NAME.inputWrap)}
      withMessageBelow={!!message}
      data-status={status}
    >
      {(label || required) && (
        <Base.InputLabel>
          <span id={labelDomId} className={STATIC_CLASS_NAME.inputLabel}>
            {label}
          </span>
          {required && (
            <Base.InputRequiredLabelSuffix aria-hidden className={STATIC_CLASS_NAME.inputRequiredSuffix}>
              *
            </Base.InputRequiredLabelSuffix>
          )}
          {tooltip && <TooltipIcon as={HelpSVG}>{tooltip}</TooltipIcon>}
        </Base.InputLabel>
      )}
      {children}
      {message && (
        <Base.InputMessageWrap
          className={STATIC_CLASS_NAME.inputMessage}
          id={messageDomId}
          $textColor={statusMessageColor}
          aria-live="polite"
        >
          {statusIcon && <Base.InputMessageIconWrap aria-hidden>{statusIcon}</Base.InputMessageIconWrap>}
          {message}
        </Base.InputMessageWrap>
      )}
    </Base.InputContainer>
  );
};
