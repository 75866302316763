import styled from 'styled-components';

const GoogleButton = styled.div`
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  height: 38px;
`;

export const GoogleButtonSignUp = styled(GoogleButton)`
  margin-top: 24px;
`;

export const GoogleButtonSignIn = styled(GoogleButton)`
  margin-top: 24px;
  margin-bottom: 24px;
`;
