/* eslint-disable no-undef */
import React, { useEffect, useRef, useCallback } from 'react';
import scriptLoader from 'react-async-script-loader';
import PropTypes from 'prop-types';
import { clientId } from 'global/env';
import { posthogCapture } from 'components/Analytics/recorder';
import { FUNNEL_STEP, POSTHOG_EVENT_NAME } from '@stonlyCommons/constants/Authentication.consts';

import { GoogleButtonSignUp } from './GoogleAuth.styles';
import useGoogleAuth from './useGoogleAuth';

const GoogleSignUp = ({ isScriptLoaded, isScriptLoadSucceed, containerWidth, funnelId, handlePostAuthentication }) => {
  const googleButtonRef = useRef(null);
  const { handleCredentialResponse } = useGoogleAuth({ funnelId });

  const handleLoad = useCallback(() => {
    google.accounts.id.initialize({
      client_id: clientId,
      cancel_on_tap_outside: false,
      context: 'signup',
      callback: response => {
        posthogCapture(POSTHOG_EVENT_NAME.DEMO_SIGNUP_FUNNEL, {
          funnelId,
          step: FUNNEL_STEP.CLICK_SIGNUP_WITH_GOOGLE,
        });
        handleCredentialResponse(response, handlePostAuthentication);
      },
    });

    google.accounts.id.prompt();

    google.accounts.id.renderButton(googleButtonRef.current, {
      size: 'large',
      width: containerWidth || 352,
      text: 'signup_with',
      shape: 'circle',
      logo_alignment: 'center',
    });
  }, [containerWidth]);

  useEffect(() => {
    if (isScriptLoaded && isScriptLoadSucceed) {
      handleLoad();
    }
  }, [isScriptLoaded, isScriptLoadSucceed]);

  return <GoogleButtonSignUp ref={googleButtonRef} data-cy="googleButtonSignUp" />;
};

GoogleSignUp.propTypes = {
  containerWidth: PropTypes.number,
  isScriptLoaded: PropTypes.bool,
  isScriptLoadSucceed: PropTypes.bool,
  funnelId: PropTypes.string,
  handlePostAuthentication: PropTypes.func,
};

export default scriptLoader('https://accounts.google.com/gsi/client')(GoogleSignUp);
