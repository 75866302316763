import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import i18n from 'helpers/i18n';
import Button from '@playerCommon/StandardElements/Button/Button.js';
import { postMessage } from 'helpers/widgetHelpers';

const Canvas = styled.div``;

const CongratsMessage = styled.div`
  color: #333;
  font-size: 20px;
  line-height: 1.4;
  font-weight: 500;
`;

const NextMessage = styled.div`
  margin-top: 32px;
  text-transform: uppercase;
  color: ${props => props.theme.steel};
  font-size: 12px;
  margin-bottom: 16px;
`;

const ZendeskFinalStep = ({ toggleComponent }) => {
  return (
    <Canvas>
      <CongratsMessage>{i18n('FinalStep.ZendeskMainMessage')}</CongratsMessage>

      <NextMessage>{i18n('FinalStep.ZendeskPrevious')}</NextMessage>
      <Button
        content={i18n('FinalStep.ZendeskPreviousButton')}
        onClick={() => {
          toggleComponent('previousStepsPanel');
        }}
      />
    </Canvas>
  );
};

ZendeskFinalStep.propTypes = {
  toggleComponent: PropTypes.func,
};

export default ZendeskFinalStep;
