import styled, { css } from 'styled-components';
import Icon from '@playerCommon/StandardElements/Icon';
import { SplitPane, Pane } from '@deviousm/react-split-pane';
import { defaultScrollStyles } from '@playerCommon/CommonStyledComponents/CustomScrollbar';
import { navigationWithKeyboardStyle } from '@playerCommon/CommonStyledComponents/NavigationWithKeyboard';
import CloseButton from '@playerCommon/CustomElements/CloseButton/CloseButton';

export const COLLAPSED_PANE_WIDTH = 36;
export const EXPANDED_TREE_PANE_WIDTH = 336;
export const EXPANDED_FLOWCHART_PANE_WIDTH = 640;

export const DesktopWrap = styled.div`
  @media screen and (max-width: 899px) {
    display: none;
  }
`;

export const MobileWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  z-index: 100;
  background-color: ${props => props.theme.white};
  box-shadow: 0 4px 12px 0 ${props => props.theme.canvasBox};
  transition: width 0.3s;

  ${({ isTableOfContentsExpanded }) =>
    isTableOfContentsExpanded &&
    css`
      width: 100%;
    `}

  @media screen and (min-width: 900px) {
    display: none;
  }
`;

export const TableOfContentsPlaceholder = styled.div`
  width: ${COLLAPSED_PANE_WIDTH}px;

  @media screen and (max-width: 899px) {
    display: none;
  }
`;

export const StyledSplitPane = styled(SplitPane)`
  position: absolute;
  width: 100%;

  .splitpane-resizer {
    width: 12px;
    border-color: transparent;
    opacity: 1;
    background-color: ${props => props.theme.silver};
    background-clip: padding-box;
    flex-shrink: 0;

    &:hover,
    &:active,
    &:focus {
      border-color: transparent;
      background-color: ${props => props.theme.lightblueOld};
    }
  }

  ${({ isTableOfContentsExpanded }) =>
    !isTableOfContentsExpanded &&
    css`
      .splitpane-resizer {
        pointer-events: none;
      }
    `}
`;

export const StyledPane = styled(Pane)`
  position: relative;
  z-index: 5;
  height: 100%;
  background-color: ${props => props.theme.white};

  ${({ paneWidth }) =>
    paneWidth &&
    css`
      transition: width 0.3s;
      width: ${paneWidth}px !important;
    `}
`;

export const InvisiblePane = styled(Pane)`
  pointer-events: none;
`;

export const ToggleIcon = styled(Icon)`
  position: absolute;
  top: 28px;
  right: -14px;
  z-index: 2;
  background-color: ${props => props.theme.white};
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.16), 0px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 14px;
  padding: 6px;
  cursor: pointer;
  transition: box-shadow 0.2s;
  ${navigationWithKeyboardStyle};

  @media screen and (max-width: 899px) {
    display: none;
  }
`;

export const OverflowWrap = styled.div`
  overflow-x: hidden;
  height: 100%;
  ${defaultScrollStyles};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: ${EXPANDED_TREE_PANE_WIDTH}px;
  white-space: nowrap;

  @media screen and (max-width: 899px) {
    padding-top: 0;
    min-width: 100vw;
  }
`;

export const WrapSearchSection = styled.div`
  display: flex;
  align-items: center;
  margin: 0 16px;
  padding: 4px 12px;
`;

export const WrapTreeSection = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: 0 0 0 16px;
`;

export const WrapFlowchartSection = styled(WrapTreeSection)`
  padding-left: 0;
`;

export const MobileHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 24px;
  border-bottom: 1px solid ${props => props.theme.silver};

  @media screen and (min-width: 900px) {
    display: none;
  }
`;

export const Title = styled.h5`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.02em;
  margin: 0;
`;

export const StyledCloseButton = styled(CloseButton)`
  div[role='button'] {
    top: 20px;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${props => props.theme.silver};
`;

export const ButtonsWrap = styled.div`
  display: flex;
  padding: 28px 24px;
  width: 100%;
  max-width: 400px;
  align-self: center;

  @media screen and (max-width: 899px) {
    padding: 24px;
  }
`;

export const TabButton = styled.button`
  width: 50%;
  padding: 6px 0;
  text-align: center;
  color: ${props => props.theme.slateGrey};
  font-weight: 500;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Roboto, Segoe UI, Helvetica Neue, sans-serif;
  line-height: 16px;
  background: none;
  border: 1px solid ${props => props.theme.grey};
  cursor: pointer;
  transition: box-shadow 0.2s;
  ${navigationWithKeyboardStyle}

  &:first-child {
    border-radius: 24px 0 0 24px;
  }
  &:last-child {
    border-radius: 0 24px 24px 0;
    margin-left: -1px;
  }

  &:hover {
    color: ${props => props.theme.darkGrey};
  }

  ${({ active }) =>
    active &&
    css`
      color: ${props => props.theme.darkGrey};
      background: ${props => props.theme.paleGrey};
    `}
`;
