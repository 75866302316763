import React from 'react';
import styled, { css } from 'styled-components';
import Textarea from '@playerCommon/StandardElements/Textarea';
import Input from '@playerCommon/StandardElements/Input';
import { defaultScrollStyles } from '@playerCommon/CommonStyledComponents/CustomScrollbar';

import CrossSVG from 'icons/crossMedium.svg';
import BackSVG from 'icons/back.svg';

export const CornerWrap = styled.div``;

export const Title = styled.div`
  font-size: 24px;
  line-height: 40px;
  margin-bottom: 16px;
`;

export const Subtitle = styled.div`
  font-size: 16px;
  line-height: 24px;
`;

export const ContactFormTextarea = styled(Textarea)`
  margin-top: 24px;
`;

export const ContactFormInput = styled(Input)`
  margin-top: 24px;
`;

export const ContactFormError = styled.div`
  margin-top: 4px;
  margin-bottom: -15px;
  font-size: 11px;
  font-weight: 500;
  line-height: 1;
  color: ${props => props.theme.errorColor};
`;

export const ContactFormFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 84px;
  flex-shrink: 0;
  width: 100%;
  padding: 0 24px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top: 1px solid ${props => props.theme.silver};
  background-color: white;
  color: white;
  font-size: 20px;
`;

export const ContactFormBody = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  flex-grow: 1;
  padding: 24px;
  ${defaultScrollStyles};

  ${({ locked }) =>
    locked &&
    css`
      overflow: hidden;
    `}
`;

export const CloseButton = styled(CrossSVG)`
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  margin-left: auto;
  box-shadow: none;
  transition: box-shadow 0.2s;
  cursor: pointer;
  border-radius: 4px;

  path {
    fill: currentColor;
  }

  &:focus {
    outline: none;
  }
  body.navigating-with-keyboard &:focus {
    box-shadow: 0px 0px 0px 2px ${props => props.theme.darkBlue};
  }
`;

const BackButtonSVGFiltered = ({ visible, ...rest }) => <BackSVG {...rest} />;

export const BackButton = styled(BackButtonSVGFiltered)`
  position: relative;
  width: 24px;
  height: 24px;
  margin-left: -6px;
  box-shadow: none;
  transition: box-shadow 0.2s;
  cursor: pointer;
  border-radius: 4px;
  flex-shrink: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0.2s, transform 0.2s;
  transform: translate3d(-12px, 0, 0);

  path {
    fill: currentColor;
  }

  &:focus {
    outline: none;
  }

  body.navigating-with-keyboard &:focus {
    box-shadow: 0px 0px 0px 2px ${props => props.theme.darkBlue};
  }

  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
      visibility: visible;
      transform: translate3d(0, 0, 0);
    `}
`;

export const ContactFormHeaderText = styled.div`
  display: inline-flex;
  align-items: center;
  transition: transform 0.2s;
  margin-left: 6px;
  transform: translate3d(-24px, 0, 0);

  ${({ backVisible }) =>
    backVisible &&
    css`
      transform: translate3d(4px, 0, 0);
    `}
`;

export const ContactFormHeaderLeft = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const Canvas = styled.div`
  position: fixed;
  z-index: 1;
  bottom: 24px;
  right: 24px;
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 48px);
  width: 480px;
  max-height: calc(100% - 128px);
  height: 560px;
  border-radius: 8px;
  background: white;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
  color: ${props => props.theme.darkGrey};
  transition: opacity 0.2s, visibility 0.2s, transform 0.2s;
  opacity: 0;
  visibility: hidden;
  transform: translate3d(0, 12px, 0);
  pointer-events: none;

  ${({ isModal }) =>
    isModal &&
    css`
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      bottom: 0;
      right: 0;
    `}

  ${({ open }) =>
    open &&
    css`
      opacity: 1;
      visibility: visible;
      transform: translate3d(0, 0, 0);
      pointer-events: all;
    `}

  @media screen and (max-width: 899px) {
    ${({ isModal }) =>
      !isModal &&
      css`
        max-height: calc(100% - 108px);
      `}
  }

  @media screen and (max-width: 560px) {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    right: 0;
    bottom: 0;

    ${({ open }) =>
      open &&
      css`
        z-index: 100;
      `}
  }
`;

export const ContactFormHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 64px;
  flex-shrink: 0;
  width: 100%;
  padding: 0 24px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: ${props => props.theme.highlightColor};
  color: white;
  font-size: 20px;
  line-height: 24px;

  ${({ monochromeHeader }) =>
    monochromeHeader &&
    css`
      color: ${props => props.theme.darkGrey};
      background-color: white;
      height: 63px;
      border-bottom: 1px solid ${props => props.theme.silver};
    `}
`;

export const Backdrop = styled.div`
  display: block;
  position: fixed;
  z-index: 2147483001;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  transition: opacity 0.2s, visibility 0.2s, background-color 0.2s;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.3);

  ${props =>
    props.open &&
    css`
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    `}
`;

export const ModalWrap = styled.div`
  display: block;
  position: fixed;
  z-index: 2147483002;
  top: 0;
  left: calc(100% - 500px);
  width: 480px;
  max-width: 480px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
  will-change: transform, opacity, visibility;
  opacity: 0;
  visibility: hidden;
  transform-origin: bottom right;
  pointer-events: none;
  transition: opacity 0.3s, visibility 0.3s, transform 0.3s; // cubic-bezier(0.45, 0.45, 0, 1.2);
  transform-origin: center center;
  transform: scale3d(0.9, 0.9, 0.9);

  ${({ roundedCorners }) =>
    roundedCorners &&
    css`
      border-radius: 8px;
    `};

  ${({ open }) =>
    open &&
    css`
      opacity: 1;
      visibility: visible;
      transform: scale3d(1, 1, 1);
      pointer-events: all;
    `}

  ${props =>
    props.fadeOut &&
    css`
      opacity: 0;
      transform: scale3d(0.9, 0.9, 0.9);
    `}

  @media screen and (min-width: 520px) {
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 40px);
    max-width: calc(100vw - 40px);
    top: 20px !important;
    left: 20px !important;
  }

  @media screen and (min-width: 1000px) {
    left: calc(50vw - 480px) !important;
    max-width: 960px;
  }

  @media screen and (min-height: 680px) {
    top: calc(50vh - 320px) !important;
    max-height: 576px;
  }

  @media screen and (max-height: 730px) {
    top: 20px !important;
  }

  @media screen and (max-width: 520px) {
    max-width: 100vw !important;
    width: 100vw !important;
    height: 100vh !important;
    max-height: 100vh !important;
    right: 0px !important;
    left: 0px !important;
    bottom: 0px !important;
    top: 0px !important;
    border-radius: 0;
    transform-origin: top center;
    transform: translate3d(0px, 24px, 0px);
  }

  ${({ open }) =>
    open &&
    css`
      @media screen and (max-width: 520px) {
        transform: translate3d(0px, 0px, 0px);
      }
    `}
`;
