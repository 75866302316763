import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Loader from '@playerCommon/CustomElements/Loader/Loader';
import EmptyState from '@playerCommon/CustomElements/EmptyState/EmptyState';
import { assetsUrl } from 'global/env';
import i18n from 'helpers/i18n';
import callApi from 'helpers/apiHelpers.js';
import Search from '@playerCommon/Commons/Search/Search.jsx';
import { postMessage } from 'helpers/widgetHelpers.js';
import ListView from './ListView/ListView.js';
import ExplanationView from './ExplanationView/ExplanationView.js';
import Top from './Top/Top.js';

const Canvas = styled.div`
  min-height: 400px;
`;

const PositionedEmptyState = styled(EmptyState)`
  margin-top: 150px;
  text-align: center;
`;

const SearchWrapper = styled.div`
  ${({ type }) => type !== 'small' && 'margin-top: 32px;'}
`;

class Explanations extends Component {
  state = {
    initialSearchValue: [],
    explanations: [],
    explanationsLoaded: false,
    folders: [],
    view: 'guides',
    agentAppGuideData: null,
  };

  async loadFoldersAndGuides() {
    const { userManagement, teamName } = this.props;
    const team = userManagement.user.teams.find(t => t.name === teamName);

    if (team) {
      try {
        const foldersRes = await callApi(`v1/folder?teamId=${team.teamId}&context=view`, 'get');
        const folders = foldersRes.data;
        const folderIds = folders.map(f => f.folderId);

        const expRes = await callApi(`v1/explanation/user/teamView`, 'post', {
          teamId: team.teamId,
          folderIds,
        });
        this.setState({ folders, explanations: expRes.data, explanationsLoaded: true });
      } catch {
        this.setState({ explanationsLoaded: false });
      }
    } else {
      this.setState({ explanationsLoaded: true });
    }
  }

  componentDidMount() {
    const { userManagement, match, teamName } = this.props;
    const { ticketId } = match.params;
    const team = userManagement.user.teams.find(t => t.name === teamName);

    postMessage({ type: 'getInitialSearchValue' });
    postMessage({ type: 'getAgentAppGuideData' });

    window.addEventListener('message', event => {
      if (event.data.type && event.data.type === 'sendInitialSearchValue' && event.data.value.length) {
        this.setState({ initialSearchValue: event.data.value.join(' '), view: 'search', explanationsLoaded: true });
      }

      if (event.data.source === 'front') {
        if (event.data.type.includes('setNote')) {
          callApi(
            `v1/integration/front/notes`,
            'POST',
            {
              content: event.data.value,
              authorId: `alt:email:${event.data.userEmail}`,
              conversationId: ticketId,
              teamId: team.teamId,
              appName: 'front',
            },
            true
          );
        }

        if (event.data.type.includes('cannedResponse')) {
          callApi(`v1/integration/front/responses?teamId=${team.teamId}`, 'get', {}, true).then(response => {
            const cannedToUse = response.data.responses._results.find(res => res.name === event.data.value);
            if (cannedToUse) {
              if (event.data.type.includes('Note')) {
                callApi(
                  `v1/integration/front/notes`,
                  'POST',
                  {
                    content: cannedToUse.body,
                    authorId: `alt:email:${event.data.userEmail}`,
                    conversationId: ticketId,
                    teamId: team.teamId,
                    appName: 'front',
                  },
                  true
                );
              } else if (event.data.type.includes('Reply')) {
                postMessage({ type: 'cannedResponseForReply', cannedToUse });
              }
            }
          });
        }
      }

      if (event.data.type && event.data.type === 'sendAgentAppGuideData' && event.data.value) {
        this.setState({ agentAppGuideData: event.data.value });
      }
    });
    this.loadFoldersAndGuides();
  }

  componentDidUpdate() {
    const { updateParentContainer } = this.props;
    updateParentContainer();
  }

  setView = view => {
    this.setState({ view });
  };

  setFolderToDisplay = folderId => {
    const { history } = this.props;
    history.push(`${folderId}`);
  };

  backTolist = () => {
    const { match, history, teamName } = this.props;
    const { sessionsId, folderId, ticketId, mode, referrerUrl } = match.params;

    history.push(`/${mode}/${teamName}/guides/${sessionsId}/${ticketId}/${referrerUrl}/${folderId}`);
  };

  findCurrentPath = (folderId, folders) => {
    const path = [];

    const findSubPath = subFolderId => {
      path.push(subFolderId);
      const currentFolder = folders.find(folder => folder.folderId === subFolderId);

      if (currentFolder && currentFolder.parentId) {
        findSubPath(currentFolder.parentId);
      }
    };

    findSubPath(folderId);

    return path.reverse();
  };

  render() {
    const { view, explanations, folders, explanationsLoaded, initialSearchValue, agentAppGuideData } = this.state;
    const { match, userManagement, updateParentContainer, teamName } = this.props;
    const { id, folderId, sessionsId, mode, ticketId } = match.params;

    const teamDetails = userManagement.user.teams.find(t => t.name === teamName);
    let homeFolder = null;
    if (teamDetails) {
      homeFolder = teamDetails.homeFolderId;
    }

    const currentFolderId = !folderId || JSON.parse(folderId) === null ? homeFolder : JSON.parse(folderId);
    const path = this.findCurrentPath(currentFolderId, folders);
    const foldersToDisplay = folders.filter(folder => +folder.parentId === +currentFolderId);
    const explanationsToDisplay = explanations.filter(explanation => +explanation.folderId === +currentFolderId);

    return (
      <Canvas>
        {!explanationsLoaded && view === 'guides' && (!id || id === 'null') && <Loader text={i18n('Global.Loading')} />}
        {explanationsLoaded && view === 'guides' && explanations.length === 0 && (
          <PositionedEmptyState
            imageSrc={`${assetsUrl}resources/icons/noExplanation.svg`}
            content={i18n('Console.EmptyScreen')}
          />
        )}
        {view === 'guides' && explanations.length > 0 && (!id || id === 'null') && (
          <>
            <Top
              path={path}
              openSearch={this.setView}
              folders={folders}
              setFolderToDisplay={this.setFolderToDisplay}
              type={mode.includes('Panel') ? 'small' : 'normal'}
            />
            <ListView
              explanations={explanations}
              explanationsToDisplay={explanationsToDisplay}
              folders={folders}
              foldersToDisplay={foldersToDisplay}
              setFolderToDisplay={this.setFolderToDisplay}
              currentUrl={match.url}
              type={mode.includes('Panel') ? 'small' : 'normal'}
            />
          </>
        )}
        {id && id !== 'null' && (
          <ExplanationView
            backTolist={this.backTolist}
            updateParentContainer={updateParentContainer}
            userManagement={userManagement}
            type={mode.includes('Panel') ? 'small' : 'normal'}
            agentAppGuideData={agentAppGuideData}
          />
        )}

        {view === 'search' && !id && (
          <SearchWrapper type={mode.includes('Panel') ? 'small' : 'normal'}>
            <Search
              initialValue={initialSearchValue}
              base={{
                folder: `/${mode}/${teamName}/guides/${sessionsId}/${ticketId}/null`,
                guide: `/${mode}/${teamName}/guides/${sessionsId}/${ticketId}/null/${folderId}`,
                step: `/${mode}/${teamName}/guides/${sessionsId}/${ticketId}/null/${folderId}`,
              }}
              back={() => {
                this.setView('guides');
              }}
            />
          </SearchWrapper>
        )}
      </Canvas>
    );
  }
}

Explanations.propTypes = {
  updateParentContainer: PropTypes.func,
  history: PropTypes.object,
  userManagement: PropTypes.object,
  match: PropTypes.object,
};

export default Explanations;
