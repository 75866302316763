const addCrispChatListeners = () => {
  $crisp.push([
    'on',
    'chat:closed',
    () => {
      $crisp.push(['do', 'chat:hide']);
    },
  ]);
};

export const initializeCrispChat = isWidget => {
  $crisp.push(['do', 'chat:hide']);
  if (!isWidget) addCrispChatListeners();
};
