import React from 'react';
import { FILE_SIZES } from 'global';
import i18n from 'helpers/i18n';
import FileBlankSVG from 'icons/invoice.svg';
import FileDOC from 'icons/fileDoc.svg';
import FileIMG from 'icons/fileImg.svg';
import FilePDF from 'icons/filePdf.svg';
import FilePPT from 'icons/filePpt.svg';
import FileXLS from 'icons/fileXls.svg';
import FileVID from 'icons/fileVid.svg';
import { FileExtIcon } from './ContentFileAttachmentsModule.styles';

export const getFileIconByExt = fileExtension => {
  const images = ['png', 'jpg', 'jpeg', 'gif', 'webp'];
  const videos = ['mp4', 'mov', 'avi'];

  if (images.includes(fileExtension)) return <FileExtIcon iconNode={FileIMG} />;
  if (videos.includes(fileExtension)) return <FileExtIcon iconNode={FileVID} />;
  if (fileExtension === 'doc' || fileExtension === 'docx') return <FileExtIcon iconNode={FileDOC} />;
  if (fileExtension === 'xls' || fileExtension === 'xlsx') return <FileExtIcon iconNode={FileXLS} />;
  if (fileExtension === 'ppt' || fileExtension === 'pptx') return <FileExtIcon iconNode={FilePPT} />;
  if (fileExtension === 'pdf') return <FileExtIcon iconNode={FilePDF} />;
  return <FileExtIcon iconNode={FileBlankSVG} />;
};

export const convertFileSize = size => {
  if (size / FILE_SIZES.ONE_KILOBYTE < 1000) {
    return `${(size / FILE_SIZES.ONE_KILOBYTE).toFixed(1)} ${i18n('Units.Kilobyte')}`;
  }
  return `${(size / FILE_SIZES.ONE_MEGABYTE).toFixed(1)} ${i18n('Units.Megabyte')}`;
};
