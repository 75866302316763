import React, { useContext } from 'react';
import { FlatTreeContext } from '../FlatTreeProvider';

/**
    Returns a ref to the uuid element
*/
export const useTreeRowRef = ({ uuid }) => {
  const { rowRefByUuidRef } = useContext(FlatTreeContext);
  if (!rowRefByUuidRef.current?.[uuid]) {
    rowRefByUuidRef.current[uuid] = React.createRef();
  }

  return rowRefByUuidRef.current[uuid];
};
