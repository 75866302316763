import styled from 'styled-components';
import BaseButton from './BaseButton';

export const TextButton = styled(BaseButton)`
  display: flex;
  align-items: center;
  height: auto;
  padding: 0;
  cursor: pointer;
  color: ${props => props.theme.slateGrey};
  border: none;
  background: none;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  text-decoration: none;
  text-transform: none;

  @media not all and (pointer: coarse), (min--moz-device-pixel-ratio: 0) {
    &:hover {
      color: ${props => props.theme.darkGrey};
    }
  }
`;
