import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { STEP_SEPARATOR, STEP_TYPE, GUIDE_TYPE } from 'global';
import { AnimatePresence } from 'framer-motion';
import ContactForm from '@playerCommon/ComplexElements/ContactForm';
import BackToSpecialStep from '@playerCommon/CustomElements/BackToSpecialStep/BackToSpecialStep';
import Nps from '@playerCommon/ComplexElements/Nps';
import Automation from '@playerCommon/ComplexElements/Automation';
import Checklist from '@playerCommon/ComplexElements/Checklist';
import AiAnswer from '@playerCommon/ComplexElements/AiAnswer/AiAnswer';
import { getTempStorage, setTempStorage } from 'helpers/storage';
import { sendSpecialStepAction } from 'helpers/widgetIntegrations';
import useGuideData from '@playerCommon/hooks/playerHooks/useGuideData';
import { useParams } from 'react-router-dom';
import {
  getPathToChecklist,
  removePathToChecklist,
  isPathInsideChecklist,
} from '@playerCommon/ComplexElements/Checklist/Checklist.helpers';
import CustomSurvey from '@playerCommon/ComplexElements/CustomSurvey/CustomSurvey';
import { getLanguageShorthand } from 'helpers/i18n';
import {
  getPathToAiAnswer,
  isPathInsideAiAnswer,
  removePathToAiAnswer,
} from '@playerCommon/ComplexElements/AiAnswer/AiAnswer.helpers';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import StepNavigation from '../StepNavigation';
import Content from '../Content/Content';
import CompactIllustration from '../CompactIllustration/CompactIllustration';
import IframeEmbed from '../components/IframeEmbed';

import { ContentWrap, FirstWrap, LastWrap, SingleSnoozeButton } from '../Steps.styles';
import { getTitleToDisplay, getGoToNextStepProps } from '../Steps.helpers';

function StepContents({
  firstStep,
  hasIntroduction,
  stepsPath,
  toggleComponent,
  borderlessEmbed,
  goToNextStep,
  onBackLinkClick,
  goBackToChecklist,
  goBackToAiAnswer,
  explanationId,
  currentExplanationId,
  onSingleNextStepClick,
  displayStepTitle,
  stepInfo,
  createPreviousLink,
  currentStepId,
  currentStepsHaveIllustration,
  guideOrientation,
  getVisitedStepsData,
  scrollStepToBottom,
  scrollToTop,
  teamId,
  // scrollable steps params
  scrollable,
  isLast,
  isFirst,
  compact,
  setIsContactFromSuccessMessageDisplayed,
  isInlineGuideLoading,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const language = getLanguageShorthand();
  const { view, mode: matchMode } = useParams();
  const stepInputsRef = useRef(null);
  const {
    guide,
    mode,
    guideInfo: { guideId, type },
    guideEncrypted,
    guideLinksDisabled,
    loadImagesFromRoot,
  } = useGuideData();
  const inKnowledgeBase = ['kb', 'kb-widget'].includes(matchMode);
  const isPreview = mode === 'preview';
  const {
    stepId,
    stepType,
    title,
    content,
    attachments,
    internalNotes,
    nextStepSelector,
    nextSteps,
    stepInputs,
    illustrationType,
    specialStepInfo,
    stepIllustrations,
    stepConnectionList,
    isStepOfEmbeddedGuide,
    showBackButton,
    shouldGoNextOnSelection,
    tileLayout,
    tileImagePosition,
    tileImageSize,
    buttonSettingsContent,
    snoozeButtonSettings,
  } = stepInfo;

  const currentlyActive = currentStepId === stepId;

  const pathFromUrl = stepsPath.join(STEP_SEPARATOR) || String(firstStep);

  const titleToDisplay = getTitleToDisplay(title, stepType, displayStepTitle);

  const isFirstStep = !(
    hasIntroduction ||
    stepsPath.length > 1 ||
    (stepsPath.length === 1 && currentStepId !== firstStep)
  );

  let FillHeightWrap = React.Fragment;
  const fillHeightWrapProps = {};
  if (isFirst) FillHeightWrap = FirstWrap;
  if (isLast) FillHeightWrap = LastWrap;
  if (isFirst || isLast) fillHeightWrapProps.guideOrientation = guideOrientation;

  const [isChecklistSkipped, setIsChecklistSkipped] = useState(false);
  const [isChecklistStatusLoaded, setIsChecklistStatusLoaded] = useState(false);
  const [isChecklistCompleted, setIsChecklistCompleted] = useState(false);
  const [pathToChecklist, setPathToChecklist] = useState(null);
  const [pathToAiAnswer, setPathToAiAnswer] = useState(null);
  const [shouldShowNextButtonSelector, setShouldShowNextButtonSelector] = useState(false);

  const shouldShowSkipChecklistButton =
    stepType === STEP_TYPE.checklist &&
    !!specialStepInfo.checklist.settings.skipEnabled &&
    (isPreview || (isChecklistStatusLoaded && !isChecklistCompleted)) &&
    !isChecklistSkipped;

  useEffect(() => setTempStorage('isFirstRenderFired', true), []);
  const isInChecklist = (() => {
    if (typeof window === 'undefined' || !getTempStorage('isFirstRenderFired')) {
      return false;
    }
    return isPathInsideChecklist(pathFromUrl, guideId);
  })();
  const isInAiAnswer = (() => {
    if (typeof window === 'undefined' || !getTempStorage('isFirstRenderFired')) {
      return false;
    }
    return isPathInsideAiAnswer(pathFromUrl, guideId);
  })();

  useEffect(() => {
    // Remove saved path to checklist when user landed on a step that is outside of checklist
    let savedPathToChecklist = getPathToChecklist(guideId);
    const isStepInsideChecklist = isPathInsideChecklist(pathFromUrl, guideId);
    if (!isStepInsideChecklist && savedPathToChecklist) {
      removePathToChecklist(guideId, true);
      savedPathToChecklist = null;
    }
    setPathToChecklist(savedPathToChecklist);

    // Remove saved path to AI Answer when user landed on a step that is outside of AI answer step
    let savedPathToAiAnswer = getPathToAiAnswer(guideId);
    const isStepInsideAiAnswer = isPathInsideAiAnswer(pathFromUrl, guideId);
    if (!isStepInsideAiAnswer && savedPathToAiAnswer) {
      removePathToAiAnswer(guideId, true);
      savedPathToAiAnswer = null;
    }
    setPathToAiAnswer(savedPathToAiAnswer);
  }, [pathFromUrl]);

  const isFirstStepOfChecklistGuide = isInChecklist && stepsPath.length === 1;
  const isFirstStepOfAiStepGuide = isInAiAnswer && stepsPath.length === 1;
  const previousUrlToLoad = (() => {
    if (isFirstStepOfChecklistGuide) return pathToChecklist;
    if (isFirstStepOfAiStepGuide) return pathToAiAnswer;
    return createPreviousLink();
  })();

  const onStepNextClick = useCallback(
    option => goToNextStep(getGoToNextStepProps({ option, path: pathFromUrl, guide })),
    [goToNextStep, pathFromUrl, guide]
  );

  const onBackLinkClickProxy = useCallback(
    e => {
      if (isFirstStepOfChecklistGuide) {
        goBackToChecklist(pathToChecklist);
        return;
      }
      if (isFirstStepOfAiStepGuide) {
        goBackToAiAnswer(pathToAiAnswer);
        return;
      }
      onBackLinkClick(e);
    },
    [
      onBackLinkClick,
      isFirstStepOfChecklistGuide,
      isFirstStepOfAiStepGuide,
      goBackToAiAnswer,
      pathToAiAnswer,
      goBackToChecklist,
      pathToChecklist,
    ]
  );

  const onAutomationStepOptionSelect = useCallback(
    option => {
      let canRedirect = true;

      const stepIdList = pathFromUrl.split(STEP_SEPARATOR).map(id => +id);
      // Detect if navigation to selected option would cause a loop of automation steps
      if (stepIdList.includes(option.toStepId)) {
        const lastIndexOfStepToGoTo = stepIdList.lastIndexOf(option.toStepId);
        const potentialLoopedPath = stepIdList.slice(lastIndexOfStepToGoTo);

        // Check if every step in potentially looped path has type "automation" or "guide"
        // (we check for type "guide" as well because automation step can be embedded)
        const isEveryStepTypeAutomation = potentialLoopedPath.every(stepIdToCheck => {
          const step = guide.steps.find(s => s.stepId === stepIdToCheck);
          return [STEP_TYPE.automation, STEP_TYPE.guide].includes(step.stepType);
        });

        if (isEveryStepTypeAutomation) {
          canRedirect = false;
          console.log(`STONLY: Abort redirection to the step ${option.toStepId} as it would cause infinite loop`);
        }
      }

      if (canRedirect) {
        const nextStepProps = getGoToNextStepProps({ option, path: pathFromUrl, guide });
        goToNextStep(nextStepProps);
        if (nextStepProps.typeOfNextStep === 'external') {
          // when external app e.g. chat is opened, go to the step prior to the automation step
          onBackLinkClickProxy();
        }
      }
    },
    [goToNextStep, pathFromUrl, guide, onBackLinkClickProxy]
  );

  const shouldShowContentSection = [
    STEP_TYPE.regular,
    STEP_TYPE.checklist,
    STEP_TYPE.aiAnswer,
    'finalStep',
    'introduction',
    'stonlyAd',
  ].includes(stepType);
  const isAutomationStep = stepType === STEP_TYPE.automation;
  const isIframeType = stepType === STEP_TYPE.iframe;
  const isAiStep = stepType === STEP_TYPE.aiAnswer;

  const shouldEveryConnectionBeEnabled =
    type !== GUIDE_TYPE.GUIDED_TOUR || isStepOfEmbeddedGuide || isPreview || mode !== 'widget';

  const hasNoIllustration = illustrationType === 'none';

  // Render step navigation component for not scrollable steps or on the last scrollable step
  const shouldShowStepNavigation = !scrollable || (isLast && currentlyActive);

  const isNotScrollableOrFirstScrollableStep = !scrollable || isFirst;

  return (
    // eslint-disable-next-line react/jsx-fragments
    <FillHeightWrap {...fillHeightWrapProps}>
      <ContentWrap
        className={`step-${stepId} step-content-wrap ${isAiStep ? 'ai-step-content-wrap' : ''}`}
        matchMode={matchMode}
        illustrationType={illustrationType}
        borderlessEmbed={borderlessEmbed}
        mode={mode}
        scrollable={scrollable}
        currentStepsHaveIllustration={currentStepsHaveIllustration}
        currentlyActive={!scrollable || currentlyActive}
        guideOrientation={guideOrientation}
        data-step-id={stepId}
        isIframeType={isIframeType}
        isAiStep={isAiStep}
        shouldShowNextButtonSelector={shouldShowNextButtonSelector}
        compact={compact}
      >
        {isInChecklist && isNotScrollableOrFirstScrollableStep && (
          <AnimatePresence>
            <BackToSpecialStep
              key={isInChecklist}
              onClick={() => goBackToChecklist(pathToChecklist)}
              text={t('StepButtons.BackToChecklist')}
              hasNoIllustration={hasNoIllustration}
            />
          </AnimatePresence>
        )}
        {!isInChecklist && isInAiAnswer && isNotScrollableOrFirstScrollableStep && (
          <AnimatePresence>
            <BackToSpecialStep
              key={isInAiAnswer}
              onClick={() => goBackToAiAnswer(pathToAiAnswer)}
              text={t('StepButtons.BackToAiChat')}
              hasNoIllustration={hasNoIllustration}
            />
          </AnimatePresence>
        )}
        {!isChecklistSkipped && !isAutomationStep && currentStepsHaveIllustration && (
          <CompactIllustration
            stepIllustrations={stepIllustrations}
            illustrationType={illustrationType}
            mode={mode}
            matchMode={matchMode}
            encrypted={guideEncrypted}
            active={currentlyActive}
            scrollable={scrollable}
            guideOrientation={guideOrientation}
            loadImagesFromRoot={loadImagesFromRoot}
          />
        )}
        {shouldShowContentSection && !isChecklistSkipped && (
          <Content
            attachments={attachments}
            internalNotes={internalNotes}
            content={content}
            stepTitle={titleToDisplay}
            stepType={stepType}
            explanationId={explanationId}
            toggleComponent={toggleComponent}
            integration={view}
            stepInputs={stepInputs}
            onBackLinkClick={onBackLinkClickProxy}
            previousStepLink={scrollable ? '' : previousUrlToLoad}
            isPreview={isPreview}
            guideLinksDisabled={guideLinksDisabled}
            mode={mode}
            stepId={stepId}
            ref={stepInputsRef}
          />
        )}
        {isIframeType && (
          <IframeEmbed
            iframeData={specialStepInfo.iframe}
            shouldShowNextButtonSelector={shouldShowNextButtonSelector}
            mode={mode}
            scrollable={scrollable}
            compact={compact}
            borderlessEmbed={borderlessEmbed}
          />
        )}
        {isAiStep && (
          <AiAnswer
            aiAnswerInfo={specialStepInfo.aiAnswer}
            language={language}
            onStepNextClick={onStepNextClick}
            nextSteps={nextSteps}
            compact={compact}
            pathFromUrl={pathFromUrl}
            // StepNavigation props below
            isFirstStep={isFirstStep}
            stepId={stepId}
            currentGuideId={currentExplanationId}
            previousUrlToLoad={previousUrlToLoad}
            onBackLinkClick={onBackLinkClickProxy}
            isPreview={isPreview}
            stepConnectionList={stepConnectionList}
            shouldEveryConnectionBeEnabled={shouldEveryConnectionBeEnabled}
            onShouldShowNextButtonSelectorChange={setShouldShowNextButtonSelector}
            scrollStepToBottom={scrollStepToBottom}
            scrollStepToTop={scrollToTop}
            teamId={teamId}
          />
        )}
        {stepType === STEP_TYPE.checklist && (
          <Checklist
            checklistInfo={specialStepInfo.checklist}
            pathFromUrl={pathFromUrl}
            isChecklistSkipped={isChecklistSkipped}
            isPreview={isPreview}
            checklistId={stepId}
            onChecklistStatusLoad={setIsChecklistStatusLoaded}
            onChecklistCompleted={setIsChecklistCompleted}
          />
        )}
        {stepType === STEP_TYPE.nps && (
          <Nps
            npsStepInfo={specialStepInfo.nps}
            isFirstStep={isFirstStep}
            isPreview={isPreview}
            previousUrlToLoad={previousUrlToLoad}
            onBackLinkClick={onBackLinkClickProxy}
            guide={guide}
            view={view}
            stepId={stepId}
            onStepNextClick={onStepNextClick}
            internalNotes={internalNotes}
          />
        )}
        {stepType === STEP_TYPE.customSurvey && (
          <CustomSurvey
            customSurveyStepInfo={specialStepInfo.customSurvey}
            isFirstStep={isFirstStep}
            isPreview={isPreview}
            previousUrlToLoad={previousUrlToLoad}
            onBackLinkClick={onBackLinkClick}
            guide={guide}
            view={view}
            stepId={stepId}
            onStepNextClick={onStepNextClick}
            internalNotes={internalNotes}
          />
        )}
        {stepType === STEP_TYPE.contactForm && (
          <ContactForm
            inKnowledgeBase={inKnowledgeBase}
            content={content}
            contactFormInfo={specialStepInfo.stepContactForm}
            onBackLinkClick={onBackLinkClickProxy}
            previousUrlToLoad={previousUrlToLoad}
            contactFormTitle={titleToDisplay}
            isPreview={isPreview}
            getVisitedStepsData={getVisitedStepsData}
            stepInputs={stepInputs}
            nextSteps={nextSteps}
            onSingleNextStepClick={onSingleNextStepClick}
            internalNotes={internalNotes}
            isFirstStep={isFirstStep}
            setIsContactFromSuccessMessageDisplayed={setIsContactFromSuccessMessageDisplayed}
          />
        )}
        {isAutomationStep && (
          <Automation
            mode={mode}
            content={content}
            internalNotes={internalNotes}
            isPreview={isPreview}
            options={nextSteps}
            onOptionSelect={onAutomationStepOptionSelect}
            fallbackTimeout={specialStepInfo.automation.fallbackTimeout}
            minimumDisplayTime={specialStepInfo.automation.minimumDisplayTime}
            minimumDisplayTimeEnabled={specialStepInfo.automation.minimumDisplayTimeEnabled}
            serverCalls={specialStepInfo.automation.serverCalls}
            loaderIconData={stepIllustrations[0]}
            guideId={guideId}
            stepId={stepId}
            getVisitedStepsData={getVisitedStepsData}
          />
        )}
        {shouldShowStepNavigation && !isAutomationStep && !isAiStep && (
          <StepNavigation
            options={nextSteps}
            onStepNextClick={onStepNextClick}
            selectorType={nextStepSelector}
            showBackButton={showBackButton}
            shouldGoNextOnSelection={shouldGoNextOnSelection}
            tileLayout={tileLayout}
            tileImagePosition={tileImagePosition}
            tileImageSize={tileImageSize}
            buttonSettingsContent={buttonSettingsContent}
            stepType={stepType}
            shouldShowSkipChecklistButton={shouldShowSkipChecklistButton}
            isChecklistCompleted={isChecklistCompleted}
            currentlyActive={currentlyActive}
            isFirstStep={isFirstStep}
            stepId={stepId}
            previousUrlToLoad={previousUrlToLoad}
            onBackLinkClick={onBackLinkClickProxy}
            onSkipChecklistClick={() => setIsChecklistSkipped(true)}
            onSingleNextStepClick={onSingleNextStepClick}
            scrollable={scrollable}
            isLastScrollableStep={isLast}
            pathToChecklist={pathToChecklist}
            isPreview={isPreview}
            stepConnectionList={stepConnectionList}
            shouldEveryConnectionBeEnabled={shouldEveryConnectionBeEnabled}
            onShouldShowNextButtonSelectorChange={setShouldShowNextButtonSelector}
            isInlineGuideLoading={isInlineGuideLoading}
            ref={stepInputsRef}
          />
        )}
        {snoozeButtonSettings && !isAutomationStep && !isAiStep && (
          <SingleSnoozeButton
            buttonColor={
              snoozeButtonSettings.shouldUseGuideButtonStyle ? theme.highlightColor : snoozeButtonSettings.buttonColor
            }
            content={snoozeButtonSettings.label}
            className="snooze-button"
            onClick={() => sendSpecialStepAction(snoozeButtonSettings.action)}
          />
        )}
      </ContentWrap>
    </FillHeightWrap>
  );
}

StepContents.propTypes = {
  firstStep: PropTypes.number,
  hasIntroduction: PropTypes.bool,
  stepsPath: PropTypes.array,
  toggleComponent: PropTypes.func,
  borderlessEmbed: PropTypes.bool,
  goToNextStep: PropTypes.func,
  onBackLinkClick: PropTypes.func,
  goBackToChecklist: PropTypes.func,
  goBackToAiAnswer: PropTypes.func,
  explanationId: PropTypes.string,
  currentExplanationId: PropTypes.string,
  onSingleNextStepClick: PropTypes.func,
  stepInfo: PropTypes.object,
  displayStepTitle: PropTypes.bool,
  scrollable: PropTypes.bool,
  isLast: PropTypes.bool,
  isFirst: PropTypes.bool,
  createPreviousLink: PropTypes.func,
  currentStepId: PropTypes.number,
  currentStepsHaveIllustration: PropTypes.bool,
  guideOrientation: PropTypes.string,
  encrypted: PropTypes.bool,
  getVisitedStepsData: PropTypes.func,
  compact: PropTypes.bool,
  scrollStepToBottom: PropTypes.func,
  scrollToTop: PropTypes.func,
  teamId: PropTypes.number,
  setIsContactFromSuccessMessageDisplayed: PropTypes.func,
};

export default StepContents;
