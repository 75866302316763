import React, { memo, useState, useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import i18n from 'helpers/i18n';
import { onKeyPressToOnClick } from 'helpers/a11y';
import ActionList from '@playerCommon/CustomElements/ActionList';
import Dropdown from '@playerCommon/CustomElements/Dropdown';
import { navigationWithKeyboardStyle } from '@playerCommon/CommonStyledComponents/NavigationWithKeyboard';
import LanguagePicker from './LanguagePicker';
import HeaderSearch from './HeaderSearch';
import SearchBar from '../SearchBar';
import { useContactFormOptionsChecking } from '../../Pages/helpers';

const HiddenInput = styled.input`
  display: none;
`;

const MobileContent = styled.div`
  display: none;
  width: 100%;

  @media screen and (max-width: 1024px) {
    display: block;
  }
`;

const Canvas = styled.div`
  color: ${props => props.theme.headerTextColor || props.theme.slateGrey};
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  pointer-events: auto;
  width: fit-content;

  ${({ shouldShowHamburgerMenu }) =>
    shouldShowHamburgerMenu &&
    css`
      display: none;
    `}

  @media screen and (max-width: 1024px) {
    position: absolute;
    width: 100%;
    height: calc(100vh - 60px);
    overflow-y: auto;
    top: 60px;
    left: 0;
    padding: 0 24px;
    padding-top: 16px;
    background: ${props => props.theme.kbHeaderBg || 'white'};
    transition: opacity 0.2s, visibility 0.2s, transform 0.2s;
    opacity: 0;
    visibility: hidden;
    transform: translate3d(0, -24px, 0);
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    ${HiddenInput}:checked ~ & {
      opacity: 1;
      visibility: visible;
      transform: translate3d(0, 0, 0);
    }
  }

  @media screen and (max-width: 480px) {
    padding: 0 16px;
    padding-top: 16px;
  }
`;

const HeaderLink = styled.a`
  display: inline-flex;
  align-items: center;
  margin-right: 24px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: opacity 0.2s, box-shadow 0.2s;
  box-shadow: none;
  border-radius: 4px;
  flex-shrink: 0;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    opacity: 0.8;
  }

  ${navigationWithKeyboardStyle}

  @media screen and (max-width: 1024px) {
    font-size: 16px;
    display: inline-flex;
  }
`;

const ContactLink = HeaderLink.withComponent('span');

export const MobileMenuToggleBar = styled.div`
  display: block;
  position: relative;
  height: 2px;
  width: 14px;
  border-radius: 4px;
  transition: transform 0.2s, background-color 0.2s, opacity 0.2s;
  margin-bottom: 4px;
  background-color: ${props => props.theme.headerTextColor || props.theme.slateGrey};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const MobileMenuToggle = styled.label`
  display: none;
  height: 24px;
  width: 24px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    display: flex;
  }

  ${({ show }) =>
    show &&
    css`
      display: flex;
      pointer-events: auto;
    `}

  ${HiddenInput}:checked ~ & > ${MobileMenuToggleBar} {
    margin: 0;

    &:nth-child(1) {
      transform: translate3d(0px, 2px, 0) rotate(225deg);
    }
    &:nth-child(2) {
      opacity: 0;
    }
    &:nth-child(3) {
      transform: translate3d(0px, -2px, 0) rotate(135deg);
    }
  }

  ${({ isToolBarOpen }) =>
    isToolBarOpen &&
    css`
      & > ${MobileMenuToggleBar} {
        margin: 0;

        &:nth-child(1) {
          transform: translate3d(0px, 2px, 0) rotate(225deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          transform: translate3d(0px, -2px, 0) rotate(135deg);
        }
      }
    `}
`;

export const DropdownWrapper = styled.div`
  place-self: center end;

  ${({ isInsideStickyHeader }) =>
    !isInsideStickyHeader &&
    css`
      @media screen and (max-width: 1024px) {
        display: none;
      }
    `}
`;

const SearchBarSmallHeader = styled(SearchBar)`
  margin-left: 0;
  margin-right: 24px;
  transition: opacity 0.2s, visibility 0.2s, transform 0.2s;
  /* transform: translate3d(0, 20%, 0); */
  display: inline-block;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const MENU_CONTAINER_DOM_ID = 'menu-container';
const MENU_TRIGGER_CHECKBOX_DOM_ID = 'menuOpen';

const HeaderMenu = React.forwardRef(
  (
    {
      currentLanguage,
      onLanguageChange,
      languageList,
      onContactClick,
      mobileTopContent,
      onOpen,
      onClose,
      helpcenterData,
      mode,
      isInsideStickyHeader,
      shouldShowHamburgerMenu,
      isBlurred,
      tabIndex,
    },
    menuRef
  ) => {
    const [isToolBarOpen, setIsToolbarOpen] = useState(false);

    const dropdownRef = useRef();

    const closeDropdown = () => {
      dropdownRef.current.closeDropdown();
    };

    useEffect(() => {
      if (isToolBarOpen && isBlurred && isInsideStickyHeader) closeDropdown();
    }, [isInsideStickyHeader, isBlurred, isToolBarOpen]);

    function toggleHeaderOpen() {
      if (isToolBarOpen) {
        setIsToolbarOpen(false);
        if (onClose) onClose();
      } else {
        setIsToolbarOpen(true);
        if (onOpen) onOpen();
      }
    }

    const { knowledgeBase, headerLinks } = helpcenterData;

    const {
      isContactFormEnabled,
      isContactFormOnNoResultsEnabled,
      isContactFormInGuidesHeaderEnabled,
      isContactFormInKnowledgeBaseHeaderEnabled,
    } = useContactFormOptionsChecking(knowledgeBase);

    const isExplanationMode = mode === 'explanation';
    const hasHeaderContactForm = isExplanationMode
      ? isContactFormInGuidesHeaderEnabled
      : isContactFormInKnowledgeBaseHeaderEnabled;
    const isHeaderContactFormEnabled = hasHeaderContactForm && isContactFormEnabled;

    const showMenuToggle = isHeaderContactFormEnabled || (headerLinks && headerLinks.length > 0) || mode !== 'homepage';

    const linksMenu = useMemo(() => {
      const linksList = (headerLinks || []).map(l => {
        return { id: l.teamKnowledgeBaseHeaderLinkId, value: l.url, label: l.text };
      });
      if (onContactClick)
        linksList.push({
          id: 'contactUs',
          value: 'contactUs',
          label: i18n('HelpcenterHome.ContactUs'),
          role: 'menuitem',
        });

      return linksList;
    }, [onContactClick]);

    const onLinkClick = url => {
      if (url === 'contactUs') onContactClick();
      else window.open(url, '_blank');
    };

    const handleKeyDown = e => {
      if (e.key === ' ' || e.key === 'Enter') {
        toggleHeaderOpen();
      }
    };

    return (
      <>
        <HeaderSearch
          helpcenterData={helpcenterData}
          noMarginRight={!showMenuToggle}
          onContactFormClick={isContactFormOnNoResultsEnabled ? onContactClick : undefined}
        />

        <HiddenInput
          aria-hidden
          type="checkbox"
          id={MENU_TRIGGER_CHECKBOX_DOM_ID}
          checked={isToolBarOpen}
          onChange={toggleHeaderOpen}
        />
        {showMenuToggle && !isInsideStickyHeader && (
          <MobileMenuToggle
            role="button"
            tabIndex={0}
            onKeyDown={handleKeyDown}
            aria-expanded={isToolBarOpen}
            aria-label="menu"
            aria-controls={MENU_CONTAINER_DOM_ID}
            htmlFor={MENU_TRIGGER_CHECKBOX_DOM_ID}
          >
            <MobileMenuToggleBar />
            <MobileMenuToggleBar />
            <MobileMenuToggleBar />
          </MobileMenuToggle>
        )}

        {shouldShowHamburgerMenu && (
          <DropdownWrapper isInsideStickyHeader={isInsideStickyHeader}>
            <Dropdown
              ref={dropdownRef}
              trigger={
                <MobileMenuToggle
                  role="button"
                  tabIndex={0}
                  show
                  isToolBarOpen={isToolBarOpen}
                  aria-expanded={isToolBarOpen}
                  aria-label="menu"
                  aria-controls={MENU_CONTAINER_DOM_ID}
                  htmlFor={MENU_TRIGGER_CHECKBOX_DOM_ID}
                >
                  <MobileMenuToggleBar />
                  <MobileMenuToggleBar />
                  <MobileMenuToggleBar />
                </MobileMenuToggle>
              }
              offsetValue={10}
              onOpen={() => setIsToolbarOpen(true)}
              onClose={() => setIsToolbarOpen(false)}
            >
              <ActionList options={linksMenu} onPostSelect={closeDropdown} globalAction={onLinkClick} />
            </Dropdown>
          </DropdownWrapper>
        )}

        {!(isInsideStickyHeader && shouldShowHamburgerMenu) && (
          <Canvas
            ref={menuRef}
            shouldShowHamburgerMenu={shouldShowHamburgerMenu}
            id={MENU_CONTAINER_DOM_ID}
            role="menu"
          >
            <MobileContent>{mobileTopContent}</MobileContent>
            <LanguagePicker currentLanguage={currentLanguage} onChange={onLanguageChange} languageList={languageList} />
            {isExplanationMode && (
              <SearchBarSmallHeader
                mode="inHeader"
                helpcenterData={helpcenterData}
                placeholder={knowledgeBase.searchInputText}
                autofocus
                onContactFormClick={isContactFormOnNoResultsEnabled ? onContactClick : undefined}
              />
            )}
            {headerLinks && (
              <>
                {headerLinks
                  .sort((a, b) => a.order - b.order)
                  .map(link => (
                    <HeaderLink
                      key={link.teamKnowledgeBaseHeaderLinkId}
                      href={link.url}
                      target="_blank"
                      role="menuitem"
                      className={`header-link ${isExplanationMode ? 'guide-header-link' : ''}`}
                      tabIndex={tabIndex}
                    >
                      {link.text}
                    </HeaderLink>
                  ))}
              </>
            )}
            {!!isHeaderContactFormEnabled && !!onContactClick && (
              <ContactLink
                tabIndex={tabIndex}
                onClick={onContactClick}
                onKeyPress={onContactClick ? onKeyPressToOnClick(onContactClick) : undefined}
                data-stonly-trigger="kbHeaderContactUsLink"
              >
                {knowledgeBase.contactButtonText || i18n('HelpcenterHome.ContactUs')}
              </ContactLink>
            )}
          </Canvas>
        )}
      </>
    );
  }
);

HeaderMenu.propTypes = {
  currentLanguage: PropTypes.string,
  onLanguageChange: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onContactClick: PropTypes.func,
  languageList: PropTypes.string,
  mobileTopContent: PropTypes.object,
  helpcenterData: PropTypes.object,
  mode: PropTypes.string,
  isInsideStickyHeader: PropTypes.bool,
  shouldShowHamburgerMenu: PropTypes.bool,
  isBlurred: PropTypes.bool,
  tabIndex: PropTypes.number,
};

export default memo(HeaderMenu);
