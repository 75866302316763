import { getGlobal } from 'global/windowUtils';
import { callWidgetApi } from 'helpers/apiHelpers';
import { postMessage } from 'helpers/widgetHelpers';
import { getTempStorage, setTempStorage } from 'helpers/storage';
import { getIds } from 'helpers/statIdsManagement';
import { extractBaseData } from 'components/Analytics/recorder';
import { checklistItemTypes } from './Checklist.consts';

const getChecklistPathKey = guideId => {
  const widgetId = getGlobal('widgetRuleId');
  let key = `stonly_checklist_paths_${guideId}`;
  if (widgetId) {
    key += `_${widgetId}`;
  }
  return key;
};

const getPaths = guideId => {
  const key = getChecklistPathKey(guideId);
  return JSON.parse(window.localStorage.getItem(key)) || [];
};

const setPaths = (guideId, paths) => {
  const key = getChecklistPathKey(guideId);
  window.localStorage.setItem(key, JSON.stringify(paths));
};

const removePaths = guideId => {
  const key = getChecklistPathKey(guideId);
  window.localStorage.removeItem(key);
};

const getIsInitialStatusRequestSentByChecklistId = checklistId => {
  const initialStatusRequestSentByChecklistId = getTempStorage('checklistInitialStatusRequestSentByChecklistId') || {};
  return !initialStatusRequestSentByChecklistId[checklistId];
};

const setIsInitialStatusRequestSentByChecklistId = checklistId => {
  const initialStatusRequestSentByChecklistId = getTempStorage('checklistInitialStatusRequestSentByChecklistId') || {};
  setTempStorage('checklistInitialStatusRequestSentByChecklistId', {
    ...initialStatusRequestSentByChecklistId,
    [checklistId]: true,
  });
};

export const savePathToChecklist = (guideId, path) => {
  if (typeof window !== 'undefined') {
    const paths = getPaths(guideId);
    if (!paths.includes(path)) {
      paths.push(path);
      setPaths(guideId, paths);
    }
  }
};

export const getPathToChecklist = guideId => {
  if (typeof window !== 'undefined') {
    const paths = getPaths(guideId);
    return paths[paths.length - 1] || null;
  }
  return null;
};

export const removePathToChecklist = (guideId, isRemoveAll = false) => {
  if (typeof window !== 'undefined') {
    if (isRemoveAll) {
      return removePaths(guideId);
    }
    const paths = getPaths(guideId);
    paths.pop();
    if (paths.length) {
      setPaths(guideId, paths);
    } else {
      removePaths(guideId);
    }
  }
};

export const isPathInsideChecklist = (currentPath, guideId) => {
  const path = getPathToChecklist(guideId);
  return path ? !path.includes(currentPath) : false;
};

export const getChecklistStatus = async ({ checklistId, guideId, teamId }) => {
  try {
    const initial = getIsInitialStatusRequestSentByChecklistId(checklistId);
    if (initial) {
      setIsInitialStatusRequestSentByChecklistId(checklistId);
    }

    const baseData = await extractBaseData();
    const { data } = await callWidgetApi('v1/checklist/status', 'post', {
      ...baseData,
      stepId: checklistId,
      guideId,
      teamId,
      initial,
    });
    return Object.fromEntries(data.map(item => [item.checklistItemId, Boolean(item.completed)]));
  } catch {
    return null;
  }
};

export const completeChecklistItem = async ({ checklistId, checklistItemId, guideId, teamId }) => {
  try {
    const baseData = await extractBaseData();
    await callWidgetApi('v1/checklist/item/complete', 'post', {
      ...baseData,
      stepModuleId: checklistItemId,
      stepId: checklistId,
      guideId,
      teamId,
    });

    return true;
  } catch {
    return false;
  }
};

export const resetChecklistItem = async ({ checklistId, checklistItemId, guideId, teamId }) => {
  try {
    const baseData = await extractBaseData();
    await callWidgetApi('v1/checklist/item/reset', 'post', {
      ...baseData,
      stepModuleId: checklistItemId,
      stepId: checklistId,
      guideId,
      teamId,
    });
    return true;
  } catch {
    return false;
  }
};

export const resetChecklist = async ({ checklistId, guideId, teamId }) => {
  try {
    const baseData = await extractBaseData();
    await callWidgetApi(
      'v1/checklist/reset',
      'post',
      {
        ...baseData,
        stepId: checklistId,
        guideId,
        teamId,
      },
      { withCredentials: true }
    );
    return true;
  } catch {
    return false;
  }
};

export const skipChecklist = async ({ checklistId, guideId, teamId }) => {
  try {
    const baseData = await extractBaseData();
    await callWidgetApi('v1/checklist/skip', 'post', {
      ...baseData,
      stepId: checklistId,
      guideId,
      teamId,
    });
  } catch {} // eslint-disable-line no-empty
};

export const isChecklistItemCompleted = (item = {}, status = {}) =>
  status[item.id] || item.checklistItemType === checklistItemTypes.PRE_CHECKED;

export const postChecklistMessage = async (data = {}) => {
  if (['checklistItemCompleted', 'checklistViewed', 'checklistSkipped', 'checklistCompleted'].includes(data.type)) {
    const { userId, sessionId } = await getIds();
    postMessage({
      ...data,
      userId,
      sessionId,
    });
  }
};

const getTargetingEventsAndPropertiesForItem = item => {
  const { group } = item.completionCondition.externalCondition;
  return group.reduce((acc, mainGroup) => {
    const keyNames = mainGroup.group ? mainGroup.group.map(nestedGroup => nestedGroup.keyName) : [mainGroup.keyName];
    return [...acc, ...keyNames];
  }, []);
};

export const getTargetingEventsAndProperties = (items, status) => {
  const externalConditionItems = items
    .filter(item => item.checklistItemType === checklistItemTypes.EXTERNAL_CONDITION)
    .filter(item => !isChecklistItemCompleted(item, status));
  return externalConditionItems.reduce((acc, item) => [...acc, ...getTargetingEventsAndPropertiesForItem(item)], []);
};

export const getSavedChecklistStatusByChecklistId = checklistId => {
  const statusesByChecklistId = getTempStorage('checklistStatusesByChecklistId') || {};
  return statusesByChecklistId[checklistId] || {};
};

export const setSavedChecklistStatusByChecklistId = (checklistId, status) => {
  const statusesByChecklistId = getTempStorage('checklistStatusesByChecklistId') || {};
  setTempStorage('checklistStatusesByChecklistId', {
    ...statusesByChecklistId,
    [checklistId]: status,
  });
};
