import React, { memo, forwardRef } from 'react';
import styled, { useTheme } from 'styled-components';
import isEqual from 'react-fast-compare';
import PropTypes from 'prop-types';
import { fileUrl } from 'global/env';
import useParsedStepContent from '@playerCommon/hooks/useParsedStepContent';
import { track as recorderTrack } from 'components/Analytics/recorder';
import { useTranslation } from 'react-i18next';
import {
  FileWrapper,
  FileFrontWrapper,
  FileRearWrapper,
  FileNameWrapper,
  FileSize,
  FileTitle,
} from '@playerCommon/StandardElements/ContentEditable/ContentFileAttachmentsModule.styles';
import {
  getFileIconByExt,
  convertFileSize,
} from '@playerCommon/StandardElements/ContentEditable/ContentFileAttachmentsModule.helpers';
import InternalNote from '@playerCommon/StandardElements/ContentEditable/InternalNote/InternalNote.player';
import Icon from '@playerCommon/StandardElements/Icon';
import DownloadSVG from 'icons/download.svg';
import OpenSVG from 'icons/open.svg';
import StepInputs from '../StepInputs';
import PageSubtitle from '../../components/PageSubtitle/PageSubtitle.js';
import FinalStepContent from './FinalStepContent/FinalStepContent.js';
import MainContentText from './ContentText.js';

const CanvasWrap = styled.div`
  @media screen and (max-width: 899px) {
    padding: 0 ${({ theme }) => theme.stepsContentPaddings.contentPadding}px;
  }
`;

const Canvas = styled.div`
  /* & ul {
    padding-inline-start: 25px;
    margin-block-start: 8px;
    margin-block-end: 8px;
  } */

  a {
    color: ${props => props.theme.linkColor};
    text-decoration: underline;
  }
`;

const FILE_EXTENSIONS_TO_DISPLAY_INLINE = new Set(['pdf']);

const Content = forwardRef(
  (
    {
      attachments,
      internalNotes,
      content,
      stepType,
      explanationId,
      toggleComponent,
      integration,
      stepTitle,
      stepInputs,
      onBackLinkClick,
      previousStepLink,
      isPreview,
      mode,
      stepId,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const contentToDisplay = useParsedStepContent({
      content,
      isPreview,
    });

    const onDownload = file => {
      const link = document.createElement('a');

      if (FILE_EXTENSIONS_TO_DISPLAY_INLINE.has(file.ext.toLowerCase())) {
        link.target = '_blank';
      } else {
        link.download = file.name;
      }
      link.href = `${fileUrl}/${file.path}/${file.fileId}.${file.ext}`;
      document.body.append(link);
      link.click();
      link.remove();
      recorderTrack({
        actionType: 'stepAttachmentDownload',
        actionDetail: {
          fileName: file.name,
          fileId: file.fileId,
          stepModuleStaticId: file.stepModuleStaticId,
        },
      });
    };

    const onDownloadProxy = (e, attachment) => {
      if (e.key === ' ' || e.key === 'Enter') {
        onDownload(attachment);
      }
    };

    return (
      <CanvasWrap theme={theme} className="content">
        {stepType === 'finalStep' ? (
          <FinalStepContent
            explanationId={explanationId}
            toggleComponent={toggleComponent}
            integration={integration}
            onBackLinkClick={onBackLinkClick}
            previousStepLink={previousStepLink}
          />
        ) : (
          <Canvas>
            {stepTitle ? <PageSubtitle subtitle={stepTitle} /> : null}
            <MainContentText className="content-text" text={contentToDisplay} mode={mode} stepId={stepId} />
            {stepInputs?.length > 0 ? <StepInputs ref={ref} stepInputs={stepInputs} isPreview={isPreview} /> : null}
            {attachments.map(attachment => (
              <FileWrapper
                key={attachment.fileId}
                onClick={isPreview ? undefined : () => onDownload(attachment)}
                isHoverable={!isPreview}
                isAdded={attachment.added}
                isRemoved={attachment.removed}
                tabIndex="0"
                onKeyDown={isPreview ? undefined : e => onDownloadProxy(e, attachment)}
              >
                <FileFrontWrapper>
                  {getFileIconByExt(attachment.ext)}
                  <FileNameWrapper>
                    <FileTitle>{attachment.name}</FileTitle>
                    <FileSize>{convertFileSize(attachment.size)}</FileSize>
                  </FileNameWrapper>
                </FileFrontWrapper>
                <FileRearWrapper>
                  <Icon
                    color={theme.lightGrey}
                    colorHover={isPreview ? theme.lightGrey : theme.darkGrey}
                    iconNode={attachment.ext === 'pdf' ? OpenSVG : DownloadSVG}
                    tooltip={
                      isPreview
                        ? t(attachment.ext === 'pdf' ? 'Preview.OpenUnavailable' : 'Preview.DownloadUnavailable')
                        : undefined
                    }
                    positionTooltip="top-end"
                  />
                </FileRearWrapper>
              </FileWrapper>
            ))}
            {internalNotes.map(internalNote => (
              <InternalNote
                key={internalNote.id}
                added={internalNote.added}
                removed={internalNote.removed}
                content={internalNote.content}
              />
            ))}
          </Canvas>
        )}
      </CanvasWrap>
    );
  }
);

Content.propTypes = {
  attachments: PropTypes.array,
  internalNotes: PropTypes.array,
  content: PropTypes.string,
  stepType: PropTypes.string,
  stepTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  explanationId: PropTypes.string,
  toggleComponent: PropTypes.func,
  integration: PropTypes.string,
  stepInputs: PropTypes.array,
  onBackLinkClick: PropTypes.func,
  previousStepLink: PropTypes.string,
  isPreview: PropTypes.bool,
  mode: PropTypes.string,
  stepId: PropTypes.number,
};

export default memo(Content, isEqual);
