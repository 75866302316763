import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import DropdownInput from '@playerCommon/StandardElements/DropdownInput';
import Checkbox from '@playerCommon/StandardElements/Checkbox/Checkbox';
import Input, { Textarea, STATUS_TYPES } from '@playerCommon/StandardElements/BaseInputs';
import { STEP_INPUTS_TYPE } from './StepInputs.consts';

const ErrorMessage = styled.div`
  margin-top: 2px;
  color: ${({ theme }) => theme.errorColor};
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  min-height: 20px;
`;

const focusStyle = css`
  transition: box-shadow 0.2s;
  &:focus-within {
    outline: none;
  }
  body.navigating-with-keyboard &:focus-within {
    box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 4px ${props => props.theme.darkBlue};
  }
`;

const StyledInput = styled(Input)`
  .input-wrap {
    ${focusStyle}
  }
`;

const StyledTextarea = styled(Textarea)`
  .input-wrap {
    ${focusStyle}
  }
`;

const StyledCheckbox = styled(Checkbox)`
  .checkbox-input {
    ${focusStyle}
  }
`;

const StepInput = ({
  id,
  name,
  type,
  disabled,
  value,
  onChange,
  onChangeValue,
  onBlur,
  onFocus,
  label,
  placeholder,
  statusType,
  statusMessage,
  options,
  required,
}) => {
  return (
    <>
      {[STEP_INPUTS_TYPE.TEXT, STEP_INPUTS_TYPE.EMAIL, STEP_INPUTS_TYPE.PHONE].includes(type) && (
        <StyledInput
          id={id}
          name={name}
          label={label}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          placeholder={placeholder}
          statusType={statusType}
          statusMessage={statusMessage}
          maxLength={255}
          disabled={disabled}
          required={required}
        />
      )}
      {type === STEP_INPUTS_TYPE.LONG_TEXT && (
        <StyledTextarea
          id={id}
          name={name}
          label={label}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          placeholder={placeholder}
          statusType={statusType}
          statusMessage={statusMessage}
          maxLength={10_000}
          disabled={disabled}
          required={required}
        />
      )}
      {type === STEP_INPUTS_TYPE.CHECKBOX && (
        <>
          <StyledCheckbox
            id={id}
            checked={!!value}
            label={label}
            required={required}
            onClick={() => onChangeValue({ name, value: !value })}
            invalid={statusType === STATUS_TYPES.ERROR}
          />
          <ErrorMessage>
            {statusMessage && (
              <span id={id && `status-message-${id}`} aria-live="assertive">
                {statusMessage}
              </span>
            )}
          </ErrorMessage>
        </>
      )}
      {type === STEP_INPUTS_TYPE.DROPDOWN && (
        <>
          <DropdownInput
            triggerId={id}
            title={label}
            required={required}
            value={value}
            name={name}
            options={options}
            onChange={e => {
              onChange(e);
              onBlur(e);
            }}
            placeholder={placeholder}
            maxHeight={280}
            invalid={statusType === STATUS_TYPES.ERROR}
            isValid={statusType === STATUS_TYPES.SUCCESS}
          />
          <ErrorMessage>
            {statusMessage && (
              <span id={id && `status-message-${id}`} aria-live="assertive">
                {statusMessage}
              </span>
            )}
          </ErrorMessage>
        </>
      )}
    </>
  );
};

StepInput.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  onChange: PropTypes.func,
  onChangeValue: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  statusType: PropTypes.string,
  statusMessage: PropTypes.string,
  options: PropTypes.array,
  required: PropTypes.bool,
};

export default StepInput;
