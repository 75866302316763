import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import UserInfo from '../components/UserInfo/UserInfo.js';
import Actions from '../components/Actions/Actions.js';

const Canvas = styled.div`
  display: flex;
  flex-direction: column;
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.grey};
  opacity: 0.4;
`;

const UserOptions = ({ user, closeDropdown }) => (
  <Canvas data-cy="userOptions">
    <UserInfo user={user} />
    <Separator />
    <Actions dataCy="userOptionsActions" user={user} closeDropdown={closeDropdown} />
  </Canvas>
);

UserOptions.propTypes = {
  user: PropTypes.object,
  closeDropdown: PropTypes.func,
};

export default UserOptions;
