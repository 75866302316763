/* eslint-disable import/prefer-default-export */

const defaultSettings = { timeout: 250, leading: true };
export const simpleDebounce = (func, options = {}) => {
  const settings = { ...defaultSettings, ...options };
  const { timeout, leading } = settings;

  let firstCall = leading;
  let timer;

  return (...args) => {
    if (firstCall) {
      firstCall = false;
      func(...args);
    } else {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
        firstCall = true;
      }, timeout);
    }
  };
};
