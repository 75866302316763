import React, { useCallback, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import InputClearSVG from 'icons/cross.svg';
import { useMergeRefs } from '@playerCommon/hooks/useMergeRefs';

const Canvas = styled.div`
  position: relative;
  font-size: 14px;
`;

const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;

  ${({ iconFirst }) =>
    !iconFirst &&
    css`
      order: 2;
    `}
`;

const Field = styled.input`
  font-family: inherit;
  width: 100%;
  min-width: 0;
  border: 0;
  outline: 0;
  padding: 0;
  margin: 0;
  font-size: inherit;
  color: #212121;
  background: transparent;
  transition: border-color 0.2s;
  appearance: none;

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${props => props.theme.lightGrey};
      background-color: ${props => props.theme.paleGrey};
      border-radius: 4px;
    `}

  ${({ skin }) =>
    skin === 'regular' &&
    css`
      padding: 12px;
    `}
`;

const FieldWrapper = styled.div`
  display: flex;
  ${({ skin }) =>
    skin === 'grey' &&
    css`
      border-radius: 4px;
      background: #f6f7f8;
      padding: ${({ size }) => (size === 'normal' ? '16px' : '8px')};

      ${Field} {
        &::placeholder {
          color: ${props => props.theme.lightGrey};
        }
      }
    `}

  ${({ skin }) =>
    skin === 'regular' &&
    css`
      background: white;
      border-radius: 4px;
      border-width: 1px;
      border-style: solid;
      border-color: ${({ theme, invalid }) => (invalid ? theme.errorColor : theme.grey)};
      margin-top: 8px;
      height: 40px;

      ${Field} {
        &::placeholder {
          color: ${props => props.theme.lightGrey};
        }
      }

      ${({ readOnly }) =>
        readOnly &&
        css`
          background: ${props => props.theme.paleGrey};
          border-color: ${props => props.theme.silver};

          ${Field} {
            color: ${props => props.theme.steel};
            cursor: auto;
          }
        `}
    `}

  ${({ skin }) =>
    skin === 'normal' &&
    css`
      background: transparent;

      ${Field} {
        padding: 9px 0 9px 4px;
        border-bottom: 1px solid ${props => props.theme.grey};
        &:focus {
          border-bottom: 1px solid ${props => props.theme.steel};
        }
        &::placeholder {
          color: ${props => props.theme.lightGrey};
          font-weight: normal;
        }
      }
    `}

  ${({ skin }) =>
    skin === 'wrapped' &&
    css`
      height: 100%;
      background: transparent;

      ${Field} {
        line-height: inherit;
        &::placeholder {
          color: ${props => props.theme.lightGrey};
          font-weight: normal;
        }
      }
    `}

    ${({ skin }) =>
    skin === 'bordered' &&
    css`
      ${Field} {
        padding: 10px 12px;
        border: 1px solid ${props => props.theme.grey};
        border-radius: 4px;
        background: white;
        transition: border-color 0.2s;
        &:focus {
          border-color: ${props => props.theme.silver};
        }
        &::placeholder {
          color: ${props => props.theme.lightGrey};
          font-weight: normal;
        }
        ${({ invalid }) =>
          invalid &&
          css`
            border-color: ${props => props.theme.errorColor};
          `}
      }

      &::placeholder {
        color: ${props => props.theme.lightGrey};
        font-size: 14px;
      }
    `}
`;

const Title = styled.label`
  display: flex;
  transition: 0.2s;
  font-size: 12px;
  color: ${props => props.theme.steel};
  font-weight: 500;
  padding-left: 4px;

  ${({ skin }) =>
    skin === 'regular' &&
    css`
      padding-left: 0;
    `}

  ${({ skin }) =>
    skin === 'bordered' &&
    css`
      margin-bottom: 8px;
      padding-left: 0;
    `}
`;

const InputClear = styled(InputClearSVG)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
  width: 20px;
  height: 20px;
  cursor: pointer;

  path {
    fill: ${props => props.theme.darkGrey};
  }
`;

const Input = React.forwardRef(
  (
    {
      className,
      value,
      defaultValue,
      id,
      iconField: IconField,
      iconFirst = false,
      size = 'normal',
      type = 'text',
      readOnly = false,
      skin = 'normal',
      onChange,
      onClick,
      placeholder,
      title,
      name,
      maxLength,
      onFocus,
      onBlur,
      onKeyPress,
      onClearField,
      disabled,
      dataCy,
      invalid,
      required = false,
      min,
      max,
      step,
      autofocus,
      select,
      stonlyTrigger = '',
      tabIndex,
    },
    ref
  ) => {
    const inputRef = useRef();
    const refs = useMergeRefs([inputRef, ref]);

    const onKeyPressProxied = useCallback(
      e => {
        if (e.key === 'Enter' && typeof onBlur === 'function') {
          onBlur(e);
        }
      },
      [onBlur]
    );

    useEffect(() => {
      if (autofocus || select) {
        setTimeout(() => {
          if (inputRef.current) {
            if (autofocus) inputRef.current.focus();
            if (select) inputRef.current.select();
          }
        }, 0);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Canvas className={className} data-stonly-trigger={stonlyTrigger}>
        {title && (
          <Title htmlFor={id} data-cy={dataCy ? `${dataCy}Title` : undefined} skin={skin}>
            {title}
          </Title>
        )}
        <FieldWrapper size={size} skin={skin} invalid={invalid} readOnly={readOnly} className="inputWrapper">
          {IconField && (
            <IconWrap iconFirst={iconFirst}>
              <IconField />
            </IconWrap>
          )}
          <Field
            ref={refs}
            skin={skin}
            id={id}
            value={value}
            min={min}
            max={max}
            step={step}
            defaultValue={defaultValue}
            name={name}
            onChange={onChange}
            onClick={onClick}
            onFocus={onFocus}
            onBlur={onBlur}
            placeholder={placeholder}
            readOnly={readOnly}
            maxLength={maxLength}
            type={type}
            onKeyPress={onKeyPress || onKeyPressProxied}
            disabled={disabled}
            invalid={invalid}
            required={required}
            data-cy={dataCy}
            tabIndex={tabIndex}
          />
          {((!!value && onClearField && onClearField.func) ||
            (onClearField && onClearField.func && onClearField.visible)) && <InputClear onClick={onClearField.func} />}
        </FieldWrapper>
      </Canvas>
    );
  }
);

Input.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxLength: PropTypes.number,
  type: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node]),
  readOnly: PropTypes.bool,
  name: PropTypes.string,
  skin: PropTypes.string,
  autofocus: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  select: PropTypes.bool,
  iconField: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  iconFirst: PropTypes.bool,
  size: PropTypes.string,
  onClearField: PropTypes.func,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  required: PropTypes.bool,
  dataCy: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  stonlyTrigger: PropTypes.string,
  tabIndex: PropTypes.number,
};

export default Input;
