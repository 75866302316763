import styled, { css } from 'styled-components';
import { defaultScrollStyles } from '@playerCommon/CommonStyledComponents/CustomScrollbar';
import ScrollDownIndicator from '@playerCommon/CustomElements/ScrollDownIndicator';
import { motion } from 'framer-motion';
import Button from '@playerCommon/StandardElements/Button';
import Loader from '@playerCommon/CustomElements/Loader';
import ConditionalNextStepIconSVG from 'icons/conditionalNextStep.svg';

export const Canvas = styled.div`
  background: white;
  box-shadow: ${({ mode, matchMode = '', theme }) =>
    mode !== 'widget' && !matchMode.includes('agentApp') && css`0 1px 4px 0 ${theme.canvasBox}`};
  position: relative;
  width: 100%;
  flex-grow: 1;

  ${({ mode }) =>
    ['embed', 'widget', 'embedModal', 'hc', 'hc-widget', 'preview'].includes(mode)
      ? css`
          height: 100%;
          ${defaultScrollStyles};
        `
      : css`
          height: calc(100vh - 88px);
        `}

  display: block;

  @media screen and (min-width: 900px) {
    display: flex;
  }

  @media screen and (max-width: 899px) {
    ${({ mode }) =>
      ['normal', 'widget', 'embed', 'embedModal', 'hc', 'hc-widget'].includes(mode) &&
      css`
        position: static;
      `};

    ${({ mode }) =>
      ['hc', 'hc-widget', 'normal', 'preview'].includes(mode) &&
      css`
        box-shadow: none;
      `}

    ${({ mode, isFullscreen }) =>
      ['embed', 'embedModal', 'hc', 'hc-widget'].includes(mode) &&
      !isFullscreen &&
      css`
        position: relative;
      `};

    ${({ mode }) =>
      !['normal', 'widget', 'hc', 'hc-widget', 'preview'].includes(mode) &&
      css`
        min-height: 640px;
      `};
  }

  ${({ mode }) =>
    mode === 'widget' &&
    css`
      min-height: 100%;
    `}

  @media screen and (max-width: 621px) {
    ${({ mode }) =>
      !['embed', 'embedModal', 'widget', 'hc', 'hc-widget'].includes(mode) &&
      css`
        height: 100%;
      `}
  }

  ${({ mode }) =>
    mode === 'embed' &&
    css`
      min-height: 640px;
    `}

  @media screen and (min-width: 900px) {
    ${({ mode }) =>
      mode === 'embed' &&
      css`
        min-height: 50vw;
      `}
  }

  ${({ matchMode = '' }) => matchMode.includes('agentApp') && 'min-height: 200px !important;'}
`;

export const ContentWrap = styled.div`
  padding: ${({ theme }) => theme.contentWrapBigDesktopPadding};
  width: ${({ illustrationType }) => (illustrationType === 'none' ? 80 : 100)}%;
  max-width: 840px;
  margin: auto;
  opacity: 0.2;
  transition: opacity 0.2s;

  @media screen and (max-width: 1281px) {
    padding: ${({ theme }) => theme.contentWrapDesktopPadding};
  }

  @media screen and (max-width: 899px) {
    padding: ${({ theme }) => `${theme.contentWrapSubDesktopPadding} 0`};
    padding-bottom: ${({ theme }) => theme.contentWrapSubDesktopPaddingBottom};
    width: 100%;
    max-width: none;

    ${({ scrollable, theme }) =>
      scrollable &&
      css`
        padding: ${theme.contentWrapSubDesktopScrollableVerticalPadding}px 0;
      `}

    ${({ illustrationType, theme }) =>
      illustrationType === 'none' &&
      css`
        padding-top: ${theme.contentWrapSubDesktopNoIllustrationPaddingTop};
      `};

    ${({ matchMode = '' }) => matchMode.includes('agentApp') && 'padding: 12px 0;'}
  }

  @media screen and (max-width: 480px) {
    ${({ illustrationType, theme }) =>
      illustrationType === 'none' &&
      css`
        padding-top: ${theme.contentWrapMobileNoIllustrationPaddingTop};
      `};
  }

  ${({ isAiStep }) =>
    isAiStep &&
    css`
      padding-bottom: 0 !important;
    `}

  ${({ scrollable, currentStepsHaveIllustration }) =>
    scrollable &&
    currentStepsHaveIllustration &&
    css`
      width: 100%;
    `}

  ${({ currentlyActive }) =>
    currentlyActive &&
    css`
      opacity: 1;
    `}

  ${({ currentlyActive }) =>
    !currentlyActive &&
    css`
      pointer-events: none;
    `}


  ${({ guideOrientation }) =>
    guideOrientation === 'vertical' &&
    css`
      @media screen and (min-width: 900px) {
        width: 80%;
      }
    `}

    ${({ isIframeType, scrollable, compact }) =>
      isIframeType &&
      scrollable &&
      !compact &&
      css`
        height: 100%;
      `}

    ${({ isIframeType, scrollable, shouldShowNextButtonSelector }) =>
      isIframeType &&
      !scrollable &&
      !shouldShowNextButtonSelector &&
      css`
        height: 100%;
        display: flex;
        flex-direction: column;
        ${({ mode, theme }) =>
          mode === 'embed' &&
          css`
            height: calc(
              100vh - ${theme.contentCanvasPaddingBottom} - ${theme.embedWrapPadding} - ${theme.embedWrapPadding}
            );
          `}
      `}

      ${({ isIframeType, mode }) =>
        isIframeType &&
        mode === 'preview' &&
        css`
          height: 100vh;
        `}

      ${({ theme, shouldShowNextButtonSelector, mode, isIframeType, scrollable, compact, borderlessEmbed }) =>
        isIframeType &&
        !scrollable &&
        !shouldShowNextButtonSelector &&
        mode === 'widget' &&
        css`
          height: calc(
            100vh - ${compact || borderlessEmbed ? '0px' : theme.widgetHeaderHeight} -
              ${compact ? '0px' : theme.footerBigDesktopHeight}
          );
          min-height: 400px;

          @media screen and (max-width: 899px) {
            height: calc(
              100vh - ${compact || borderlessEmbed ? '0px' : theme.widgetHeaderHeight} -
                ${compact ? '0px' : theme.footerSubDesktopHeight}
            );
          }

          @media screen and (max-width: 480px) {
            height: calc(
              100vh - ${compact || borderlessEmbed ? '0px' : theme.widgetHeaderHeight} -
                ${compact ? '0px' : theme.footerMobileHeight}
            );
          }
        `}
  }
`;

export const ContentCanvas = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  flex-grow: 1;
  flex-direction: row;
  width: 100%;
  ${defaultScrollStyles};
  -webkit-overflow-scrolling: touch;

  @media screen and (max-width: 899px) {
    justify-content: flex-start;
  }

  @media screen and (min-width: 900px) {
    overflow-y: auto;
    overflow-x: hidden;
  }

  align-items: flex-start;
  @supports (display: grid) {
    align-items: unset;
  }

  @media screen and (min-width: 900px) and (max-width: 1281px) {
    ${({ mode }) =>
      ['embed', 'hc', 'hc-widget'].includes(mode) &&
      css`
        justify-content: flex-start;
      `};
  }

  @media screen and (max-width: 899px) {
    ${({ mode, compact, theme }) =>
      !['preview'].includes(mode) &&
      !compact &&
      css`
        padding-bottom: ${theme.contentCanvasPaddingBottom};
      `};
  }

  @media screen and (max-width: 480px) {
    ${({ mode, compact, theme }) =>
      !['preview'].includes(mode) &&
      !compact &&
      css`
        padding-bottom: ${theme.contentCanvasPaddingBottomMobile};
      `}
  }

  ${({ scrollable, mode, borderlessEmbed, compact }) =>
    scrollable &&
    css`
      display: block;

      @media screen and (min-width: 900px) {
        ${mode === 'embed' &&
        css`
          height: calc(100vh - 102px);
        `}
        ${mode === 'preview' &&
        css`
          height: 100vh;
        `}
      }
      @media screen and (max-width: 900px) {
        height: calc(100vh - ${compact ? '0px' : '88px'});
        overflow-y: auto;
        overflow-y: overlay;
        overflow-x: hidden;

        ${mode === 'hc' &&
        css`
          height: calc(100vh - 60px);
        `}
        ${mode === 'hc-widget' &&
        css`
          height: calc(100vh - 65px);
        `}
        ${mode === 'preview' &&
        css`
          height: 100vh;
        `}
        ${borderlessEmbed &&
        css`
          height: 100vh;
        `}
        ${({ matchMode = '' }) =>
          matchMode.includes('agentApp') &&
          css`
            height: calc(100vh - 124px); // 124px = header (40px) + footer (68px) + padding of EmbedWrap (16px)
          `}
      }
      @media screen and (max-width: 622px) {
        height: calc(100vh - 60px);
        ${mode === 'widget' &&
        css`
          height: calc(100vh - ${compact ? '0px' : '64px'});
        `}
        ${mode === 'embed' &&
        css`
          height: calc(100vh - 16px);
        `}
        ${mode === 'hc-widget' &&
        css`
          height: calc(100vh - 65px);
        `}
        ${mode === 'preview' &&
        css`
          height: 100vh;
        `}
        ${borderlessEmbed &&
        css`
          height: 100vh;
        `}
        ${({ matchMode = '' }) =>
          matchMode.includes('agentApp') &&
          css`
            height: calc(100vh - 124px); // 124px = header (40px) + footer (68px) + padding of EmbedWrap (16px)
          `}
      }
    `}
`;

export const RightCanvas = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  width: 100%;
  max-width: 100%;

  @media screen and (min-width: 900px) {
    overflow-y: auto;
    overflow-x: hidden;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 50%;
    max-width: initial;
    ${defaultScrollStyles};
  }

  ${({ illustrationType }) =>
    illustrationType === 'none' &&
    css`
      width: 100%;
    `}

  ${({ scrollable }) =>
    scrollable &&
    css`
      min-height: 400px;
    `}

  @media screen and (max-width: 901px) {
    ${({ illustrationType, scrollable }) =>
      illustrationType === 'none' && !scrollable
        ? css`
            min-height: 100%;
          `
        : css`
            height: auto;
          `}
  }

  @media screen and (max-width: 480px) {
    ${({ illustrationType }) =>
      illustrationType !== 'none' &&
      css`
        height: auto;
      `};
  }

  @media screen and (min-width: 900px) {
    ${({ illustrationType }) =>
      illustrationType === 'none' &&
      css`
        min-height: 240px;
      `};
  }

  @media screen and (max-width: 901px) {
    ${({ mode, illustrationType }) =>
      mode === 'widget' &&
      illustrationType !== 'none' &&
      css`
        height: auto;
      `};

    ${({ mode, illustrationType, isFullscreen }) =>
      ['embed', 'hc', 'hc-widget'].includes(mode) &&
      illustrationType === 'none' &&
      !isFullscreen &&
      css`
        min-height: inherit;
      `};
  }

  ${({ mode, illustrationType, isIframeType }) =>
    ['hc', 'hc-widget', 'normal'].includes(mode) &&
    illustrationType === 'none' &&
    isIframeType &&
    css`
      height: 100%;
    `};
`;

export const ButtonsWrap = styled.div`
  display: flex;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0.2s;
  margin-top: ${({ theme }) => theme.nextStepSelectorButtonsTypeMarginTop};

  ${({ currentlyActive }) =>
    currentlyActive &&
    css`
      opacity: 1;
      visibility: visible;
    `};

  ${({ noTopMargin }) =>
    noTopMargin &&
    css`
      margin-top: 0;
    `}

  @media screen and (max-width: 899px) {
    padding: 0 ${({ theme }) => theme.stepsContentPaddings.contentPadding}px;
  }

  &:empty {
    margin-top: 0;
  }

  &:has(+ .snooze-button) {
    margin-bottom: 16px;
  }
`;

const wrapStyle = css`
  display: flex;
  /* min-height: 50vh; */

  @media screen and (max-width: 899px) {
    min-height: 60vh;

    & > * {
      margin: 0 auto;
    }
  }

  ${({ guideOrientation }) =>
    guideOrientation === 'vertical' &&
    css`
      min-height: 60vh;

      & > * {
        margin: 0 auto;
      }
    `}
`;

export const FirstWrap = styled.div`
  ${wrapStyle};
  align-items: flex-end;

  ${({ guideOrientation }) => css`
    @media screen and (min-width: 900px) {
      ${guideOrientation !== 'vertical' &&
      css`
        padding-top: calc(50vh - 104px); /* 88px top bar + 104px minimum content size / 2 - 52px correction / 2 */
      `}
    }
  `}
`;

export const LastWrap = styled.div`
  ${wrapStyle};
  align-items: flex-start;

  ${({ guideOrientation }) => css`
    @media screen and (min-width: 900px) {
      ${guideOrientation !== 'vertical' &&
      css`
        padding-bottom: calc((50vh - 136px) - 26px);
      `}
    }
  `}
`;

export const ScrollIndicatorWrap = styled(motion.div)`
  position: fixed;
  bottom: 60px;
  width: 100%;
  height: 80px;
  background: rgba(255, 255, 255, 0.4);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 100%);
  pointer-events: none;

  @media screen and (min-width: 481px) {
    bottom: ${({ compact }) => (compact ? '0px' : '68px')};
  }

  @media screen and (max-width: 480px) {
    bottom: ${({ compact }) => (compact ? '0px' : '68px')};
  }

  @media screen and (min-width: 900px) {
    bottom: ${({ compact }) => (compact ? '0px' : '84px')};
    position: absolute;
  }
`;

export const ScrollIndicator = styled(ScrollDownIndicator)`
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  pointer-events: none;
`;

export const SingleNextButton = styled(Button)`
  [data-cy='tooltip'] {
    left: 20px;
  }
`;

export const StyledConditionalNextStepIcon = styled(ConditionalNextStepIconSVG)`
  flex-shrink: 0;
  margin-left: 8px;
  &.slate-grey {
    & path {
      fill: ${({ theme }) => theme.slateGrey};
    }
  }
`;

export const StyledLoader = styled(Loader)`
  box-sizing: content-box;
  width: 40px;
  svg {
    width: 40px;
  }
  @media screen and (max-width: 899px) {
    padding: 0 ${({ theme }) => theme.stepsContentPaddings.contentPadding}px;
  }
  circle {
    stroke: ${({ theme }) => theme.highlightColor};
  }
`;

export const StyledLoaderSmall = styled(Loader).attrs({ type: 'dots', monochrome: true })`
  .dot {
    width: 4px;
    height: 4px;
    background: ${props => props.theme.charcoal};
  }

  .dotsWrap {
    width: 20px;
  }
`;

export const SingleSnoozeButton = styled(Button)`
  @media screen and (max-width: 899px) {
    padding: 0 ${({ theme }) => theme.stepsContentPaddings.contentPadding}px;
  }

  button {
    color: ${({ buttonColor }) => buttonColor};
    background: transparent;
    border: 1px solid ${({ buttonColor }) => buttonColor};
  }

  button:hover,
  button:active {
    background: ${({ buttonColor }) => buttonColor};
    border: 1px solid ${({ buttonColor }) => buttonColor};
    color: ${props => props.theme.white};
  }
`;
