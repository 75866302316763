import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const BadgeContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background: ${props => props.theme.backgroundPrimary};
  font-size: 11px;
  line-height: 0;
  font-weight: 500;
  color: ${props => props.theme.white};

  ${({ background }) =>
    background &&
    css`
      background: ${background};
    `};
`;

const Badge = ({ value, color, className, dataCy }) => (
  <BadgeContainer className={className} background={color} data-cy={dataCy}>
    {value}
  </BadgeContainer>
);

Badge.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  color: PropTypes.string,
  dataCy: PropTypes.string,
};

export default memo(Badge);
