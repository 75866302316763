import React, { Component } from 'react';
import styled, { withTheme, ThemeProvider, createGlobalStyle } from 'styled-components';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import callApi from 'helpers/apiHelpers';
import { RIGHT, STEP_NEXT_VISIBILITY } from 'global';
import i18n, { getLanguageShorthand } from 'helpers/i18n';
import { setLanguage } from 'helpers/i18n.helpers';
import { parseFoldersPath } from 'helpers/helpcenterHelpers';
import { getWidgetInjectionCode, postMessage } from 'helpers/widgetHelpers';
import { createPathFromParam, loadExplanation, getNewLanguageUrl } from 'helpers/explanationManagement.js';
import { getIds } from 'helpers/statIdsManagement';
import withUser from '@playerCommon/HOC/withUser/withUser';
import NoViewAccess from '@playerCommon/Commons/NoViewAccess';
import Loader, { FullscreenLoaderWrap } from '@playerCommon/CustomElements/Loader/Loader';
import ExplanationPlayer from '@playerCommon/ComplexElements/ExplanationPlayer';
import withContextProviders from 'HOC/withContextProviders';
import withHcExplanationData from 'HOC/withHcExplanationData';
import { imagesUrl } from 'global/env';
import { getStepsGlobalScrollbarStyles } from '@playerCommon/CommonStyledComponents/CustomScrollbar';
import { parseGuideData } from 'helpers/guidePlayerHelpers';
import { parseBoolean } from '@playerCommon/helpers/booleanHelpers';
import { SkipToMainContentLink } from '@playerCommon/CommonStyledComponents/SkipToMainContentLink';
import ExplanationTop from './ExplanationTop';

const Canvas = styled.div`
  @media screen and (max-width: 899px) {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

const ExplanationWrap = styled.div`
  width: 100%;
  height: calc(100vh - 96px);
  border-radius: 4px;

  @media screen and (max-width: 1024px) {
    width: 100%;
    margin: 0;
    border-radius: 0;
    border: 0;
    height: 100%;
  }
`;

const ExplanationContent = styled.div`
  width: 100%;
  height: calc(100vh - 96px);
  display: flex;

  @media screen and (max-width: 1024px) {
    height: calc(100vh - 60px);
  }
`;

const GlobalScrollStyles = createGlobalStyle`
  ${getStepsGlobalScrollbarStyles({ marginTop: '96px', marginBottom: '68px' })}

  @media screen and (max-width: 899px) {
    ${getStepsGlobalScrollbarStyles({ marginTop: '60px', marginBottom: '68px' })}
  }

  @media screen and (max-width: 480px) {
    ${getStepsGlobalScrollbarStyles({ marginTop: '60px', marginBottom: '60px' })}
  }
`;

const ALLOWED_RIGHTS = new Set([RIGHT.FULL_RIGHTS, RIGHT.VIEW, RIGHT.VIEW_EDIT, RIGHT.VIEW_EDIT_PUBLISH]);

const getProcessedState = (match, history, loadedGuide, statusCode) => {
  if (statusCode === 403) {
    return { explanationLoaded: true, accessToExplanation: false, guide: {} };
  }
  if (!loadedGuide) return null;

  const accessToExplanation = ALLOWED_RIGHTS.has(loadedGuide.guideInfo.access);
  if (!accessToExplanation) {
    return { explanationLoaded: true, accessToExplanation: false, guide: {} };
  }

  return {
    guide: loadedGuide,
    explanationLoaded: true,
    accessToExplanation,
  };
};

class Explanation extends Component {
  constructor(props) {
    super(props);

    const ssrInitialState = getProcessedState(props.match, props.history, props.loadedGuide, props.statusCode);
    const stepsPath = createPathFromParam(props.match.params.pathToLoad);

    this.state = { ...ssrInitialState, breadcrumbs: props.breadcrumbs, hcData: props.helpcenterData, stepsPath } || {
      guide: [],
      explanationLoaded: false,
      accessToExplanation: true,
      breadcrumbs: [],
      hcData: {},
      stepsPath,
    };
  }

  componentDidMount() {
    const { explanationLoaded, guide } = this.state;
    if (!explanationLoaded) this.initiateExplanation();

    getIds().then(({ sessionId }) => {
      postMessage({
        type: 'params',
        name: 'stonlySessionId',
        value: sessionId,
      });
    });
    postMessage({ type: 'initPlugin' });
    postMessage({ type: 'currentGuide', currentGuide: guide.guideInfo.guideId });
  }

  componentDidUpdate(prevProps) {
    const { userManagement, match } = this.props;
    const { match: oldMatch } = prevProps;

    if (userManagement.user.id && !prevProps.userManagement.user.id) {
      this.initiateExplanation();
    }
    if (match.params.pathToLoad !== oldMatch.params.pathToLoad) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ stepsPath: createPathFromParam(match.params.pathToLoad) });
    }
  }

  setLanguage = async language => {
    const { forceUpdateAtRoot, history, match } = this.props;
    setLanguage(language);
    forceUpdateAtRoot();
    await this.initiateExplanation();
    const { guide } = this.state;
    history.replace(getNewLanguageUrl(language, match, guide.guideInfo.title));
  };

  initiateExplanation = async () => {
    const { match, history, location } = this.props;

    try {
      const language = getLanguageShorthand();
      const { id, view } = match.params;
      const { widgetId, segmentAnonymousId, segmentUserId, stonlyAnonymousId, customerUserId } = queryString.parse(
        location.search
      );
      const loadedGuide = await loadExplanation(id, {
        isPreview: view === 'PreviewSteps',
        isShortlink: location.pathname.slice(1, 3) === 'sl',
        widgetId,
        segmentAnonymousId,
        segmentUserId,
        stonlyAnonymousId,
        customerUserId,
      });
      const parsedGuide = parseGuideData(loadedGuide, language);
      const newState = getProcessedState(match, history, parsedGuide);

      const res = await callApi(`v1/knowledgebase/integration?language=${language}`, 'get');
      const helpcenterData = res.data;
      const breadcrumbs = parseFoldersPath(parsedGuide.guideOptions.folderId, helpcenterData.folders, false);
      breadcrumbs.unshift({
        folderId: null,
        caption: { [language]: { name: helpcenterData.knowledgeBase.knowledgeBaseName } },
      });

      if (!newState) return;

      this.setState({ ...newState, breadcrumbs });
    } catch {
      this.setState({ explanationLoaded: true, accessToExplanation: false });
    }
  };

  contentBasedOnLinkAndAccess(guide, title) {
    /*
     * this function creates an object with the content to display
     * depending on whether there URL is wrong or the access to the explanation is restricted.
     */

    let titleToDisplay;
    let errorToDisplay;
    if (guide.guideInfo.guideId) {
      titleToDisplay = guide.guideInfo.access === RIGHT.NONE ? 'Restricted access.' : title;
      errorToDisplay =
        guide.guideInfo.access === RIGHT.NONE ? <div>Sorry you do not have access to this guide.</div> : '';
    } else {
      titleToDisplay = 'Ooops, no guide found.';
      errorToDisplay = 'Are you sure you have the right link?';
    }
    return { titleToDisplay, errorToDisplay };
  }

  render() {
    const { match, theme } = this.props;
    const { guide, explanationLoaded, accessToExplanation, breadcrumbs, hcData, stepsPath } = this.state;

    if (!explanationLoaded) {
      return (
        <FullscreenLoaderWrap>
          <Loader text={i18n('Global.Loading')} />
        </FullscreenLoaderWrap>
      );
    }

    if (!accessToExplanation) {
      return <NoViewAccess />;
    }

    const { title } = guide.guideInfo;

    const hasConditionalConnection = guide.allGuides
      .flatMap(({ stepNext }) => stepNext)
      .some(({ visibility }) => visibility === STEP_NEXT_VISIBILITY.externalCondition);

    let displayProgressBar = accessToExplanation ? parseBoolean(guide.guideOptions.displayProgressBar, true) : true;
    displayProgressBar = displayProgressBar && !hasConditionalConnection;

    const content = this.contentBasedOnLinkAndAccess(guide, title);

    const { view, language } = match.params;
    // mainExplanationInfo.view = view;

    let firstStep = null;
    const firstStepObj = guide.allGuides.find(info => info.guideId === match.params.id);
    if (firstStepObj) firstStep = firstStepObj.startId;

    const { knowledgeBase } = hcData;
    const themeModified = { ...theme };

    if (knowledgeBase.highlightColor) {
      themeModified.highlightColor = knowledgeBase.highlightColor;
    }

    let loadImagesFromRoot = false;
    if (knowledgeBase?.isPrivate && knowledgeBase?.knowledgeBaseOrigin) {
      loadImagesFromRoot = true;
    }

    return (
      <ThemeProvider theme={themeModified}>
        <GlobalScrollStyles />
        {knowledgeBase.customHtml ? <span dangerouslySetInnerHTML={{ __html: knowledgeBase.customHtml }} /> : null}
        {knowledgeBase.widgetIntegrationId ? (
          <script dangerouslySetInnerHTML={{ __html: `var STONLY_WID = "${knowledgeBase.widgetIntegrationId}";` }} />
        ) : null}
        <script dangerouslySetInnerHTML={{ __html: getWidgetInjectionCode() }} />
        <Helmet>
          {knowledgeBase.favicon && (
            <link
              rel="shortcut icon"
              href={`${imagesUrl.url}/${knowledgeBase.favicon}?s=${knowledgeBase.faviconSecureHash}`}
            />
          )}
        </Helmet>
        <SkipToMainContentLink />
        <Canvas className="kb-guide">
          <ExplanationTop
            folderId={guide.guideOptions.folderId}
            breadcrumbs={breadcrumbs}
            title={content.titleToDisplay}
            helpcenterData={hcData}
            currentStepId={stepsPath[stepsPath.length - 1]}
            stepsPath={stepsPath}
            displayProgressBar={displayProgressBar}
            language={language}
            guide={guide}
          />
          <ExplanationContent id="main">
            <ExplanationWrap>
              {view.includes('Steps') && (
                <ExplanationPlayer
                  mode="hc"
                  guide={guide}
                  firstStep={firstStep}
                  stepsPath={stepsPath}
                  displayProgressBar={displayProgressBar}
                  setLanguage={this.setLanguage}
                  loadImagesFromRoot={loadImagesFromRoot}
                />
              )}
            </ExplanationWrap>
          </ExplanationContent>
        </Canvas>
      </ThemeProvider>
    );
  }
}

Explanation.propTypes = {
  match: PropTypes.object,
  userManagement: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  statusCode: PropTypes.number,
  theme: PropTypes.object,
  breadcrumbs: PropTypes.array,
  loadedGuide: PropTypes.object,
  forceUpdateAtRoot: PropTypes.func,
};

export default withHcExplanationData(withContextProviders(withUser(withTheme(Explanation))));
