import styled, { css } from 'styled-components';
import CrossSVG from 'icons/cross.svg';
import { defaultScrollStyles } from '@playerCommon/CommonStyledComponents/CustomScrollbar';
import Input from '@playerCommon/StandardElements/Input';
import SearchInputIconSVG from 'icons/search.svg';
import { navigationWithKeyboardStyle } from '@playerCommon/CommonStyledComponents/NavigationWithKeyboard';

export const Canvas = styled.div`
  position: relative;
  width: 100%;
  max-width: 680px;
  outline: none;

  ${({ mode }) =>
    mode === 'inHeader' &&
    css`
      max-width: 360px;
    `}

  ${({ mode }) =>
    mode === 'mobile' &&
    css`
      max-width: 900px;
    `}

  ${({ mode }) =>
    mode === 'widget' &&
    css`
      max-width: 100%;
    `}
`;

export const SearchInputFlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SearchInput = styled(Input)`
  width: 100%;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 46px;
  flex-shrink: 1;
  margin-right: 1px;

  @media screen and (max-width: 640px) {
    line-height: 36px;
  }

  *::-ms-clear {
    width: 0;
    height: 0;
  }

  ${({ mode }) =>
    ['inHeader', 'mobile', 'widget'].includes(mode) &&
    css`
      line-height: 36px;
      font-size: 14px;
      margin-right: 0;
    `}

  ${({ mode }) =>
    mode === 'inHeader' &&
    css`
      & input ::placeholder {
        font-weight: 500 !important;
      }
    `}
`;

export const SearchInputSubmit = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  width: 140px;
  height: 48px;
  flex-shrink: 0;
  color: white;
  text-transform: uppercase;
  border: 0;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  cursor: pointer;
  background: ${props => props.theme.highlightColor};
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  transition: opacity 0.2s;
  outline: none;
  margin-right: -1px;
  transition: box-shadow 0.2s;

  ${navigationWithKeyboardStyle}

  &:hover {
    opacity: 0.9;
  }

  @media screen and (max-width: 640px) {
    height: 36px;
    width: 100px;
    font-size: 12px;
  }

  ${({ mode }) =>
    ['inHeader', 'mobile'].includes(mode) &&
    css`
      height: 36px;
      width: 100px;
      font-size: 12px;
      margin-right: 0;
    `}

  ${({ isStrokeEnabled }) =>
    isStrokeEnabled &&
    css`
      border-left: 1px solid ${props => props.theme.grey};
    `}
`;

export const SearchInputIcon = styled(SearchInputIconSVG).attrs({
  'aria-hidden': 'true',
})`
  margin-top: 1px;
  path {
    fill: ${props => props.theme.lightGrey};
  }
`;

export const DarkSearchInputIcon = styled(SearchInputIcon).attrs({
  'aria-hidden': 'true',
})`
  path {
    fill: ${props => props.theme.slateGrey};
  }
`;

export const SearchInputWrap = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
  border-radius: 24px;
  background: white;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.08);
  transition: background-color 0.2s;

  @media screen and (max-width: 640px) {
    height: 36px;
  }

  ${({ mode }) =>
    ['inHeader', 'mobile'].includes(mode) &&
    css`
      height: 36px;
      background: ${props => props.theme.paleGrey};
      box-shadow: none;
    `}

  ${({ mode }) =>
    mode === 'inStickyHeader' &&
    css`
      height: 40px;
    `}

  ${({ mode, focused }) =>
    mode === 'widget' &&
    css`
      height: 36px;
      box-shadow: none;

      ${focused &&
      css`
        background: ${props => props.theme.paleGrey};
      `}
    `}

    ${({ isStrokeEnabled }) =>
    isStrokeEnabled &&
    css`
      outline: 1px solid ${props => props.theme.grey};
      box-shadow: none;
    `}
`;

export const SearchResultsWrap = styled.div`
  position: absolute;
  width: calc(100% - 48px);
  max-height: 536px;
  overflow-y: auto;
  overflow-y: overlay;
  border-radius: 4px;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.16);
  background: white;
  padding-top: 24px;
  min-height: 120px;
  transition: opacity 0.2s, visibility 0.2s, transform 0.2s;
  opacity: 0;
  visibility: hidden;
  transform: translate3d(0, -12px, 0);
  margin-top: 12px;
  z-index: 1;
  ${defaultScrollStyles};

  ${({ hasOnlyShowAllResultsButton }) =>
    hasOnlyShowAllResultsButton &&
    css`
      padding-top: 0;
      min-height: auto;
    `}

  ${({ show }) =>
    show &&
    css`
      opacity: 1;
      visibility: visible;
      transform: translate3d(0, 0, 0);
    `}

  ${({ mode }) =>
    ['mobile', 'widget'].includes(mode) &&
    css`
      position: fixed;
      top: 60px;
      left: 0;
      margin-top: 0;
      border-radius: 0px;
      width: 100%;
      transform: translate3d(0, 0, 0);
    `}

    ${({ mode }) =>
    mode === 'mobile' &&
    css`
      max-height: calc(100% - 60px);
    `}

    ${({ mode }) =>
    mode === 'widget' &&
    css`
      max-height: initial;
      height: calc(100% - 60px);
    `}

  ${({ mode }) =>
    mode === 'inHeader' &&
    css`
      width: 100%;
      min-width: 480px;
      right: 0;
      left: auto;
    `}
`;

export const CloseWrap = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${props => props.theme.grey};
  margin-right: 16px;
  cursor: pointer;

  @media not all and (pointer: coarse) {
    &:hover {
      background-color: ${props => props.theme.silver};
    }
  }
`;

export const Cross = styled(CrossSVG)`
  pointer-events: none;
  width: 20px;
  height: 20px;
`;
