import { useReducer, useCallback, useMemo } from 'react';

type Status = 'error' | 'warning' | 'success' | 'info';

type FormState = Record<string, any>;
type FormError = Record<keyof FormState, string> | undefined;
type FormStatus = { type: Status | null; message: string } | null;

export interface ValidationType<T> {
  (formState: T): FormError;
}

interface useFormProps<T> {
  initialFormState: FormState;
  validations: ValidationType<T>[];
  inputRefs?: Record<keyof FormState, React.RefObject<HTMLInputElement>>;
}

interface State {
  formValues: FormState;
  touchedFormFields: Record<keyof FormState, boolean>;
  focusedFormFields: Record<keyof FormState, boolean>;
  dirtyFormFields: Record<keyof FormState, boolean>;
  formErrors: FormError;
  formStatus: Record<keyof FormState, FormStatus>;
}

// Define actions
const ACTION = {
  updateFormValues: 'UPDATE_FORM_VALUES',
  setFormErrors: 'SET_FORM_ERRORS',
  setFieldTouched: 'SET_FIELD_TOUCHED',
  setFieldFocused: 'SET_FIELD_FOCUSED',
  setFieldDirty: 'SET_FIELD_DIRTY',
  setFormStatus: 'SET_FORM_STATUS',
} as const;

type ActionPayload =
  | { type: typeof ACTION.updateFormValues; payload: Partial<FormState> }
  | { type: typeof ACTION.setFormErrors; payload: FormError }
  | { type: typeof ACTION.setFieldTouched; payload: { fieldName: keyof FormState; value: boolean } }
  | { type: typeof ACTION.setFieldFocused; payload: { fieldName: keyof FormState; value: boolean } }
  | { type: typeof ACTION.setFieldDirty; payload: { fieldName: keyof FormState } }
  | { type: typeof ACTION.setFormStatus; payload: { fieldName: keyof FormState; status: FormStatus } };

// Reducer function
function formReducer(state: State, action: ActionPayload) {
  switch (action.type) {
    case ACTION.updateFormValues: {
      return { ...state, formValues: { ...state.formValues, ...action.payload } };
    }
    case ACTION.setFormErrors: {
      return { ...state, formErrors: action.payload };
    }
    case ACTION.setFieldTouched: {
      return {
        ...state,
        touchedFormFields: { ...state.touchedFormFields, [action.payload.fieldName]: action.payload.value },
      };
    }
    case ACTION.setFieldFocused: {
      return {
        ...state,
        focusedFormFields: { ...state.focusedFormFields, [action.payload.fieldName]: action.payload.value },
      };
    }
    case ACTION.setFieldDirty: {
      return { ...state, dirtyFormFields: { ...state.dirtyFormFields, [action.payload.fieldName]: true } };
    }
    case ACTION.setFormStatus: {
      return { ...state, formStatus: { ...state.formStatus, [action.payload.fieldName]: action.payload.status } };
    }
    default: {
      return state;
    }
  }
}

function validate<T>(validations: ValidationType<T>[], values: FormState): FormError {
  const errors = validations.map(validation => validation(values)).filter(validation => typeof validation === 'object');
  return Object.assign({}, ...errors);
}

function getStatus(fieldName: keyof FormState, errors: FormError): FormStatus {
  if (!errors) return null;
  if (errors[fieldName]) {
    return { type: 'error', message: errors[fieldName] };
  }
  return { type: 'success', message: '' };
}

function useForm<T>({ initialFormState, validations = [], inputRefs = {} }: useFormProps<T>) {
  const [state, dispatch] = useReducer(formReducer, {
    formValues: initialFormState,
    touchedFormFields: Object.fromEntries(Object.keys(initialFormState).map(key => [key, !!initialFormState[key]])),
    focusedFormFields: Object.fromEntries(Object.keys(initialFormState).map(key => [key, false])),
    dirtyFormFields: Object.fromEntries(Object.keys(initialFormState).map(key => [key, false])),
    formErrors: validate(validations, initialFormState),
    formStatus: Object.fromEntries(Object.keys(initialFormState).map(key => [key, { type: null, message: '' }])),
  });

  const handleFormValueChange = useCallback(
    ({ name, value }: { name: keyof FormState; value: any }) => {
      dispatch({ type: ACTION.updateFormValues, payload: { [name]: value } });
      dispatch({ type: ACTION.setFieldDirty, payload: { fieldName: name } });
    },
    [dispatch]
  );

  const handleFormChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.persist) event.persist();
      const fieldName = event.target.name;
      const newValue = event.target.value;
      handleFormValueChange({ name: fieldName, value: newValue });
    },
    [dispatch]
  );

  const handleFormFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (event.persist) event.persist();
      const fieldName = event.target.name;
      dispatch({ type: ACTION.setFieldFocused, payload: { fieldName, value: true } });
    },
    [dispatch]
  );

  const handleFormBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (event.persist) event.persist();
      const fieldName = event.target.name;
      const { value } = event.target;
      const newErrors = validate(validations, { ...state.formValues, [fieldName]: value });
      const newStatus = getStatus(fieldName, newErrors);

      dispatch({ type: ACTION.setFormErrors, payload: newErrors });
      dispatch({ type: ACTION.setFormStatus, payload: { fieldName, status: newStatus } });
      dispatch({ type: ACTION.setFieldTouched, payload: { fieldName, value: true } });
      dispatch({ type: ACTION.setFieldFocused, payload: { fieldName, value: false } });
    },
    [dispatch, state.formValues, validations]
  );

  const updateFormState = useCallback(
    (partialStateToUpdate: Partial<FormState>) => {
      const stateToUpdate = { ...state.formValues, ...partialStateToUpdate };
      dispatch({ type: ACTION.updateFormValues, payload: stateToUpdate });
      dispatch({
        type: ACTION.setFormErrors,
        payload: validate(validations, stateToUpdate),
      });
    },
    [dispatch, state.formValues, validations]
  );

  const updateFormFieldStatus = useCallback(
    (fieldName: string) => {
      const newErrors = validate(validations, state.formValues);
      const newStatus = getStatus(fieldName, newErrors);
      dispatch({ type: ACTION.setFormErrors, payload: newErrors });
      dispatch({ type: ACTION.setFormStatus, payload: { fieldName, status: newStatus } });
    },
    [dispatch, state.formValues, validations]
  );

  const handleWillSubmit = useCallback(() => {
    const fieldNames = Object.keys(state.formValues);
    const newErrors = validate(validations, state.formValues);
    dispatch({ type: ACTION.setFormErrors, payload: newErrors });
    fieldNames.forEach(fieldName => {
      dispatch({ type: ACTION.setFormStatus, payload: { fieldName, status: getStatus(fieldName, newErrors) } });
    });

    // Find first error key and focus the corresponding input field
    const firstErrorKey = newErrors ? Object.keys(newErrors).find(key => newErrors[key]) : undefined;
    if (firstErrorKey && inputRefs[firstErrorKey]) {
      inputRefs[firstErrorKey].current?.focus();
    }
  }, [dispatch, validations, state.formValues, inputRefs]);

  const isFormValid = useMemo(() => {
    const errors = validate(validations, state.formValues);
    return errors ? Object.keys(errors).length === 0 : true;
  }, [state.formValues]);

  return {
    formValues: state.formValues,
    handleFormChange,
    handleFormValueChange,
    formErrors: state.formErrors,
    touchedFormFields: state.touchedFormFields,
    dirtyFormFields: state.dirtyFormFields,
    isFormValid,
    handleWillSubmit,
    handleFormBlur,
    handleFormFocus,
    focusedFormFields: state.focusedFormFields,
    formStatus: state.formStatus,
    updateFormState,
    updateFormFieldStatus,
    inputRefs,
  };
}

export default useForm;
