import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { ListItemStyles } from './_shared/styles';

export interface ListHeaderTitleProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
}

const Container = styled.div`
  display: flex;
  padding: 4px 0px 4px 12px;
  align-items: center;
  gap: 12px;
`;

export const ListHeaderTitle = forwardRef<HTMLDivElement, ListHeaderTitleProps>(({ children }, forwardedRef) => {
  return (
    <Container ref={forwardedRef}>
      <ListItemStyles.HeaderTitle>{children}</ListItemStyles.HeaderTitle>
    </Container>
  );
});
