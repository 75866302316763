import { useRef, useEffect } from 'react';

export default function useDidUpdate(callback, deps) {
  const hasMount = useRef(false);

  useEffect(() => {
    if (hasMount.current) {
      return callback();
    }
    hasMount.current = true;
  }, deps);
}
