import { KB_EXTERNAL_CHAT_TYPES } from 'global';

export const INTEGRATION_NAMES = {
  [KB_EXTERNAL_CHAT_TYPES.CRISP]: 'Open Crisp Chat',
  [KB_EXTERNAL_CHAT_TYPES.FRESHCHAT]: 'Open Freshchat',
  [KB_EXTERNAL_CHAT_TYPES.FRONT]: 'Open Front Chat',
  [KB_EXTERNAL_CHAT_TYPES.GORGIAS]: 'Open Gorgias Chat',
  [KB_EXTERNAL_CHAT_TYPES.HELPSHIFT]: 'Open Helpshift',
  [KB_EXTERNAL_CHAT_TYPES.HUBSPOT]: 'Open Hubspot Chat',
  [KB_EXTERNAL_CHAT_TYPES.INTERCOM]: 'Open Intercom',
  [KB_EXTERNAL_CHAT_TYPES.LIVECHAT]: 'Open Livechat',
  [KB_EXTERNAL_CHAT_TYPES.SALESFORCE]: 'Open Salesforce Chat',
  [KB_EXTERNAL_CHAT_TYPES.SALESFORCE_MESSAGING]: 'Open Salesforce Messaging for Web Chat',
  [KB_EXTERNAL_CHAT_TYPES.ZENDESK]: 'Open Zendesk Chat',
  [KB_EXTERNAL_CHAT_TYPES.GENESYS]: 'Open Genesys Chat',
};

export const WIDGET_PLACEMENT_TYPES = {
  BOTTOM_CORNER: 'BOTTOM_CORNER',
  MODAL: 'MODAL',
};
