/* eslint-disable no-await-in-loop */

/** Execute function multiple times until it returns true or execution limit is reached */
export function retryWithInterval(func, { interval = 400, limit = 3 }) {
  let executionInterval;
  let counter = 1; // 1st execution immediately
  const result = func();

  if (!result) {
    executionInterval = setInterval(() => {
      counter++;
      const result = func();

      if (counter >= limit || result) {
        clearInterval(executionInterval);
      }
    }, interval);
  }

  return executionInterval;
}

/** Execute function multiple times at a growing interval until it returns a truthy value or execution limit is reached */
export async function retryWithGrowingInterval(func, { interval = 100, limit = 10 }) {
  const growingIntervals = Array.from(Array(limit)).reduce((prev, _, index) => {
    if (index === 0) return [0];
    if (index === 1) return [0, interval];
    return [...prev, prev[index - 1] * 2];
  }, []);

  for (let i = 0; i < limit; i++) {
    try {
      await new Promise(resolve => setTimeout(resolve, growingIntervals[i]));

      // func() should return falsy value to keep going or truthy value to stop and return it
      const result = await func();
      if (result) {
        return result;
      }
    } catch {
      // we don't care
    }
  }

  return false;
}
