/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useUserManagement from '@playerCommon/hooks/useUserManagement';
import { playerUrl, appUrl } from 'global/env';
import { setCookie, getParsedCookie } from 'global/windowUtils';

/**
 * This component is checking if user is logged in, and if not it redirects to authentication
 */
const PlayerPrivateRoute = ({ component: Component, ...rest }) => {
  const userManagement = useUserManagement();
  const location = useLocation();
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  if (!mounted) {
    return null;
  }
  if (Object.keys(userManagement.user).length === 0) {
    const parsedCookie = getParsedCookie();
    const authenticationUrl = parsedCookie.sso ? 'authentication/signInWithSSO' : 'authentication';
    setCookie('returnUrl', encodeURIComponent(`${playerUrl}${location.pathname}${location.search}`));
    window.location.href = `${playerUrl}/${authenticationUrl}`;
    return null;
  }
  return <Component {...rest} />;
};

PlayerPrivateRoute.propTypes = {
  component: PropTypes.func,
};

export default PlayerPrivateRoute;
