import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { assetsUrl } from 'global/env';
import i18n from 'helpers/i18n';
import SearchInputIconSVG from 'icons/search.svg';
import TextLink from '@playerCommon/StandardElements/TextLink';
import Icon from '@playerCommon/StandardElements/Icon';
import { getFolderCaption } from 'helpers/helpcenterHelpers';

const Canvas = styled.div`
  ${({ type }) =>
    type !== 'small' &&
    css`
      padding-top: 40px;
      padding-bottom: 36px;
    `};
  margin-bottom: 16px;
  margin-left: 8px;
  display: flex;
`;

const SearchIcon = styled(SearchInputIconSVG)`
  width: 16px;
  height: 16px;
  margin-right: 24px;

  cursor: pointer;

  path {
    fill: ${props => props.theme.darkGray};
    transition: fill 0.2s;
  }

  &:hover {
    path {
      fill: ${props => props.theme.seaweed};
    }
  }
`;

const NextIcon = styled(Icon)`
  margin: 0 4px;
`;

const Path = styled.div`
  display: flex;
  align-items: center;
`;

const PathItem = styled.div`
  display: flex;
  align-items: center;
  height: 17px;
`;

const FolderName = styled(TextLink)`
  font-weight: 500;
  font-size: 14px;
`;

const CurrentFolderName = styled.div`
  color: ${props => props.theme.darkGrey};
  font-weight: 500;
  font-size: 14px;
`;

class Top extends Component {
  state = {};

  render() {
    const { openSearch, path, folders, setFolderToDisplay, type } = this.props;

    return (
      <Canvas type={type}>
        <SearchIcon
          data-cy="searchIcon"
          onClick={() => {
            openSearch('search');
          }}
        />
        <Path data-cy="foldersPath">
          {path.map((folderId, index) => {
            let name = i18n('ExplanationPlayer.Home');
            let id = null;

            if (index === path.length - 1 || index === path.length - 2 || type !== 'small') {
              const current = index === path.length - 1;
              if (String(folderId) !== 'null') {
                const currentFolder = folders.find(folder => folderId === folder.folderId);
                name = getFolderCaption(currentFolder).name;
                id = currentFolder.folderId;
              }

              return (
                <PathItem data-cy="pathItem" key={id}>
                  {current ? (
                    <CurrentFolderName>{name}</CurrentFolderName>
                  ) : (
                    <>
                      <FolderName data-cy="folderName" type="grey" onClick={() => setFolderToDisplay(id)}>
                        {name}
                      </FolderName>
                      <NextIcon src={`${assetsUrl}resources/icons/next.svg`} />
                    </>
                  )}
                </PathItem>
              );
            }
            return null;
          })}
        </Path>
      </Canvas>
    );
  }
}

Top.propTypes = {
  openSearch: PropTypes.func,
  path: PropTypes.array,
  folders: PropTypes.array,
  setFolderToDisplay: PropTypes.func,
  type: PropTypes.string,
};

export default Top;
