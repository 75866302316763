import { createPath, isPathStartsWith } from '@playerCommon/ComplexElements/FlatTree/helpers';
import { AI_ANSWER_STEP_NEXT_TYPE } from 'global/index';
import { parseStepIdNumber } from '@stonlyCommons/helpers/guideTreeStructure';
import i18n from '@stonlyCommons/helpers/i18n';

const DEFAULT_ROOT_PATH = createPath('root');
const MAX_PATH_DUPLICATES_RENDERED = 0;

const generateStepRowUuid = (stepId, fromConnectionId, counter = 0) =>
  `step:${stepId}_through:${fromConnectionId}_count${counter}`;

const generateConnectionRowUuid = (connectionId, counter = 0) => `conn:${connectionId}_count:${counter}`;

const defaultGetStepId = step => step.id;

export const convertGuideStepsToFlatTreeList = ({
  nodeList: stepList,
  connectionList,
  config: {
    firstStepId,
    rootPath: ROOT_PATH = DEFAULT_ROOT_PATH,
    shouldAddIntroduction,
    showUnlinked = true,
    getStepId = defaultGetStepId,
    insertTopPlaceholder = true,
  },
}) => {
  const metadata = {
    stepById: {},
    connectionById: {},
    connectionIdListFromStepId: {},
    isStepLinked: {},
    unlinkedStepIdList: [],
  };

  // Steps basic metadata collection
  stepList.forEach(step => {
    metadata.stepById[getStepId(step)] = step;
  });

  // Connection basic metadata collection
  connectionList.forEach(connection => {
    metadata.connectionById[connection.id] = connection;
  });

  // Connection ids from step
  connectionList.forEach(connection => {
    const { fromStepId } = connection;
    const { toStepId } = connection;

    if (Array.isArray(metadata.connectionIdListFromStepId[fromStepId])) {
      metadata.connectionIdListFromStepId[fromStepId].push(connection.id);
    } else {
      metadata.connectionIdListFromStepId[fromStepId] = [connection.id];
    }

    //
  });

  // Collecting info about linked/unlinked steps
  const linkedAnalyzedStepId = {};
  (function collectLinkedChildInfo(stepId) {
    if (linkedAnalyzedStepId[stepId]) {
      return;
    }

    linkedAnalyzedStepId[stepId] = true;
    metadata.isStepLinked[stepId] = true;
    (metadata.connectionIdListFromStepId[stepId] || [])
      .map(connectionId => metadata.connectionById[connectionId])
      .map(connectionDef => connectionDef.toStepId)
      .forEach(collectLinkedChildInfo);
  })(firstStepId);

  // Sorting steps children (way more performant than sorting entire list)
  Object.values(metadata.connectionIdListFromStepId).forEach(connectionIdList => {
    connectionIdList.sort((a, b) => metadata.connectionById[a]?.ordering - metadata.connectionById[b]?.ordering);
  });

  const pathForAnalyzedStepIds = {
    /* [stepId]: ['path', 'path', 'path'] */
  };

  const referenceUuidForStepId = {
    /* [stepId]: firstGeneratedUuid - TODO @Mateusz - firstGeneratedUuid should be on higher level */
  };

  const mainTreeRowList = [];

  if (shouldAddIntroduction) {
    mainTreeRowList.push({
      path: ROOT_PATH,
      isFolder: false,
      uuid: 'introduction',
      payload: { stepId: -2 },
    });
  }

  function generateStepWithChildrenRows({ stepId, fromConnectionId = 'root', path = ROOT_PATH, isUnlinked = false }) {
    const result = [];
    if (!stepId) {
      return [];
    }
    const isStepFirstTime = !pathForAnalyzedStepIds[stepId];
    const parsedStepIdNumber = parseStepIdNumber(stepId);

    if (isStepFirstTime) {
      // step handled for the first time
      pathForAnalyzedStepIds[stepId] = [];
    }
    const stepPathList = pathForAnalyzedStepIds[stepId];
    const stepDef = metadata.stepById[stepId];
    const isStepExisting = !!stepDef;
    const connectionIdListFromStep = metadata.connectionIdListFromStepId[stepId] || [];
    const hasChild = !!connectionIdListFromStep.length;
    const hasMultipleChildren = connectionIdListFromStep.length > 1;

    const rowUuid = generateStepRowUuid(stepId, fromConnectionId, stepPathList.length);
    if (isStepFirstTime) {
      referenceUuidForStepId[stepId] = rowUuid;
    }

    /*
      Handling step itself
    */
    const isReferenceToPreviouslyRenderedStep =
      parsedStepIdNumber > 0 &&
      (stepPathList.length > MAX_PATH_DUPLICATES_RENDERED ||
        stepPathList.some(stepAnalyzedPath => {
          return isPathStartsWith(path, stepAnalyzedPath);
        }));

    // we add referenceId only if step has a children (that will generate "go to step" button)
    const referenceUuid = hasChild ? referenceUuidForStepId[stepId] : undefined;

    if (isStepExisting) {
      result.push({
        path,
        uuid: rowUuid,
        isFolder: false,
        payload: {
          /* real "step" data generated from step definition */
          ...stepDef.payload,
          isReference: isReferenceToPreviouslyRenderedStep,
          title: stepDef.title,
          stepId,
          stepType: stepDef.type,
          linkUrl: undefined,
          actionType: undefined,
          hasMultipleChildren,
          referenceUuid,
          isUnlinked,
        },
      });
    } else if (parsedStepIdNumber < 0) {
      // push "virtual special step" info taken from connection instead of step
      const fromConnectionDef = metadata.connectionById[fromConnectionId];

      result.push({
        path,
        uuid: rowUuid,
        isFolder: false,
        payload: {
          /* virtual "step" data generated from connection definition */
          ...fromConnectionDef.payload,
          isReference: isReferenceToPreviouslyRenderedStep,
          title: fromConnectionDef.label,
          stepId,
          stepType: undefined,
          linkUrl: fromConnectionDef.linkUrl,
          actionType: fromConnectionDef.action?.type,
          hasMultipleChildren: false,
          referenceUuid,
          isNewTabEnabled: fromConnectionDef.newTabEnabled,
          isNavigationTypeButton: fromConnectionDef.isNavigationTypeButton,
          isNavigationTypeInteraction: fromConnectionDef.isNavigationTypeInteraction,
          isUnlinked,
        },
      });
    } else {
      console.log('STON - lack of stepId', stepId);
    }

    /*
      Handling step children if was not handled before
    */

    // increase repeat counter (one step can be handled multiple times)
    stepPathList.push(path);
    //

    if (!isReferenceToPreviouslyRenderedStep) {
      connectionIdListFromStep.forEach(connectionId => {
        const connectionDef = metadata.connectionById[connectionId];

        if (hasMultipleChildren) {
          const connectionFolderRowUuid = generateConnectionRowUuid(connectionId, stepPathList.length);
          // create "folder" which is actually a connection label

          let title = connectionDef.label;
          if (connectionDef.aiStepNextType === AI_ANSWER_STEP_NEXT_TYPE.aiAction) {
            title = `${i18n('ExplanationEditNextSteps.AiAnswerStep.ActionInputLabel', {
              itemOrder: connectionDef.ordering + 1,
            })}`;
          }
          if (connectionDef.aiStepNextType === AI_ANSWER_STEP_NEXT_TYPE.aiFallback) {
            title = `${i18n('ExplanationEditNextSteps.AiAnswerStep.FallbackStepInputLabel', {
              itemOrder: connectionDef.ordering + 1 - 1000,
            })}`;
          }

          result.push({
            path,
            isFolder: true,
            uuid: connectionFolderRowUuid,
            payload: {
              ...connectionDef.payload,
              title,
              connectionId,
              fromStepId: connectionDef.fromStepId,
              isUnlinked,
              isNavigationTypeButton: connectionDef.isNavigationTypeButton,
              isNavigationTypeInteraction: connectionDef.isNavigationTypeInteraction,
              isConditional: connectionDef.isConditional,
            },
          });
          result.push(
            ...generateStepWithChildrenRows({
              stepId: connectionDef.toStepId,
              fromConnectionId: connectionId,
              path: createPath(path, connectionFolderRowUuid),
              isUnlinked,
            })
          );
        } else {
          result.push(
            ...generateStepWithChildrenRows({
              stepId: connectionDef.toStepId,
              fromConnectionId: connectionId,
              path,
              isUnlinked,
            })
          );
        }
      });
    }
    return result;
  }

  mainTreeRowList.push(...generateStepWithChildrenRows({ stepId: firstStepId, isUnlinked: false }));

  const unlinkedTreesRowList = [];

  if (showUnlinked) {
    metadata.unlinkedStepIdList = stepList.map(getStepId).filter(stepId => !metadata.isStepLinked[stepId]);

    const unlinkedPathList = metadata.unlinkedStepIdList
      .map(unlinkedStepId => {
        return generateStepWithChildrenRows({ stepId: unlinkedStepId, isUnlinked: true });
      })
      .sort((listA, listB) => listB.length - listA.length)
      .reduce((acc, currList, idx) => {
        // insert "placeholder" between every list
        return [
          ...acc,
          {
            uuid: `plh_split_${idx}-unlinked`, // first step from the list is unique between lists
            path: ROOT_PATH,
            isSpecial: true,
            payload: { isSpacing: true },
          },
          ...currList,
        ];
      }, [])
      .flat();

    if (unlinkedPathList.length) {
      unlinkedTreesRowList.push(
        {
          uuid: `plh_before_unlinked`,
          path: ROOT_PATH,
          isSpecial: true,
          payload: { isSpacing: true },
        },
        {
          uuid: `plh_unlinked_header`,
          path: ROOT_PATH,
          isSpecial: true,

          payload: { isSpecialRowHeaderUnlinkedSteps: true },
        },
        ...unlinkedPathList
      );
    }
  }

  // Workaround - instead of "padding-top" for the tree
  const topPlaceholderRowList = insertTopPlaceholder
    ? [
        {
          uuid: `plh_before_all`,
          path: ROOT_PATH,
          isSpecial: true,
          payload: { isSpacing: true },
        },
      ]
    : [];

  return [
    ...topPlaceholderRowList,
    ...mainTreeRowList,
    ...unlinkedTreesRowList,
    // Workaround - instead of "padding-bottom" for the tree
    {
      uuid: `plh_after_all`,
      path: ROOT_PATH,
      isSpecial: true,
      payload: { isSpacing: true },
    },
  ];
};
