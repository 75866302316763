import styled, { css } from 'styled-components';
import TrashSVG from 'icons/trash.svg';
import Icon from '@playerCommon/StandardElements/Icon';
import TextareaAutosize from 'react-autosize-textarea';
import UnsafeHtml from '@playerCommon/StandardElements/UnsafeHtml/UnsafeHtml';
import { mobilePlayerContentTextStyle, playerContentTextStyles } from '@playerCommon/CommonStyledComponents/Typography';

export const TrashIcon = styled(TrashSVG)`
  position: absolute;
  right: 16px;
  top: 16px;
  opacity: 0;
  transition: opacity 0.2s;
  cursor: pointer;

  path {
    fill: ${props => props.theme.steel};
    transition: fill 0.2s;
  }
  &:hover {
    path {
      fill: ${props => props.theme.slateGrey};
    }
  }
`;

export const InternalNoteWrapper = styled.div`
  position: relative;
  display: flex;
  margin: 16px 0;
  border-radius: 8px;

  &:hover {
    ${TrashIcon} {
      opacity: 1;
    }
  }

  ${({ isAdded }) =>
    isAdded &&
    css`
      border: 3px solid ${props => props.theme.secondaryColor};
    `}

  ${({ isRemoved }) =>
    isRemoved &&
    css`
      border: 3px solid ${props => props.theme.deleteColor};
    `}
`;

export const PadlockIcon = styled(Icon)`
  width: 16px;
  padding: 11px 1px;
  background-color: ${props => props.theme.mango};
  border-radius: 4px 0 0 4px;

  & > span {
    height: 100%;
  }
`;

const contentStyles = css`
  background-color: rgba(254, 190, 49, 0.1);
  min-height: 46px;
  padding: 12px 40px 12px 12px;
  width: 100%;
  border-radius: 0 4px 4px 0;
  overflow-wrap: anywhere;
  white-space: pre-wrap;

  p {
    margin: 4px 0;
  }
`;

// player styles
export const InternalNoteContent = styled(UnsafeHtml)`
  ${contentStyles};
  ${playerContentTextStyles};

  @media screen and (max-width: 899px) {
    ${mobilePlayerContentTextStyle};
  }
`;

// editor styles
export const InternalNoteContentTextarea = styled(TextareaAutosize)`
  ${contentStyles};
  font-family: inherit;
  border: none;
  outline: none;
  resize: none;

  & > .ProseMirror {
    margin: 0;
    padding: 0;
  }

  &:focus {
    & ~ ${TrashIcon} {
      opacity: 1;
    }
  }
`;
