import { format } from 'date-fns';
import { getGlobal } from 'global/windowUtils';

import i18n from 'helpers/i18n';

const ONE_WEEK_IN_MILLISECONDS = 7 * 24 * 60 * 60 * 1000;

const displayDate = (timestamp, options = {}) => {
  const i18nInstance = getGlobal('i18n');

  const time = (Date.now() - timestamp) / 1000;

  if (time < 120) {
    return i18n('Dates.FewMoments');
  }
  if (time < 3600) {
    return `${Math.floor(time / 60)} ${i18n('Dates.Minutes')}`;
  }
  if (time < 3600 * 2) {
    return i18n('Dates.Hour');
  }
  if (time < 3600 * 24) {
    return `${Math.floor(time / 3600)} ${i18n('Dates.Hours')}`;
  }
  if (time < 3600 * 24 * 2) {
    return i18n('Dates.Yesterday');
  }
  if (time < 3600 * 24 * 4) {
    return `${Math.floor(time / 86_400)} ${i18n('Dates.Days')}`;
  }

  const currentDate = new Date(timestamp);

  const dateOptions = { year: 'numeric', month: 'long', day: 'numeric', ...options };

  return currentDate.toLocaleDateString(i18nInstance.language, dateOptions);
};

export const secondsToMinutesAndSeconds = time => {
  let mins = null;
  let secs = null;

  if (time < 60) {
    return `${time}s`;
  }
  mins = Math.floor(time / 60);
  secs = Math.floor(time - 60 * mins);

  if (secs === 0) {
    return `${mins}m`;
  }

  return `${mins}m ${secs}s`;
};

export const hhmmssToHoursMinutesAndSeconds = time => {
  const segments = time.split(':');
  const hours = +segments[0];
  const minutes = +segments[1];
  const seconds = +segments[2];

  let result = '';

  if (!hours && !minutes && !seconds) {
    return i18n('Dates.Bounced');
  }

  if (hours > 0) {
    result += `${hours}h`;
  }
  if (hours > 0 || minutes > 0) {
    result += ` ${minutes}m`;
  }
  result += ` ${seconds}s`;

  return result.trim();
};

export const displayDateVsDateString = (dateLike, vsDateLike, FORMAT = 'EEE dd MMM') => {
  const firstDateString = format(new Date(dateLike), FORMAT);

  if (!vsDateLike) {
    return firstDateString;
  }

  return `${firstDateString} vs ${format(new Date(vsDateLike), FORMAT)}`;
};

export const getFormattedDate = timestamp => {
  if (typeof window === 'undefined') {
    // This function shouldn't be used on SSR, but in case it is, this if statement would prevent error
    return '';
  }

  const locale = window.i18n.language;
  const updateDate = new Date(timestamp);
  let dateOptions = {};
  const { hour12 } = window.Intl.DateTimeFormat(locale).resolvedOptions();

  // today
  const todayStartTimestamp = new Date().setHours(0, 0, 0, 0);
  const todayEndTimestamp = new Date().setHours(23, 59, 59, 999);
  const isToday = todayStartTimestamp < timestamp && timestamp < todayEndTimestamp;

  // less than a week
  const now = Date.now();
  const delta = now - timestamp;
  const isWithinWeek = delta < ONE_WEEK_IN_MILLISECONDS;

  // this year
  const currentYear = new Date().getFullYear();
  const updateYear = updateDate.getFullYear();
  const isThisYear = currentYear === updateYear;

  if (isToday) {
    dateOptions = { hour: '2-digit', minute: '2-digit', hour12 };
  } else if (isWithinWeek) {
    dateOptions = { weekday: 'long', hour: '2-digit', minute: '2-digit', hour12 };
  } else if (isThisYear) {
    dateOptions = { day: 'numeric', month: 'short' };
  } else {
    dateOptions = { day: 'numeric', month: 'short', year: 'numeric' };
  }

  return window.Intl.DateTimeFormat(locale, dateOptions).format(updateDate);
};

export const is24HourFormat = () => {
  const date = new Date();
  date.setHours(13, 0, 0); // 13:00:00

  const formattedTime = new Intl.DateTimeFormat(navigator.language, {
    hour: 'numeric',
    minute: 'numeric',
  }).format(date);

  return formattedTime.includes('13');
};

export const getFormattedDateAndTime = dateStr => {
  return format(new Date(dateStr), is24HourFormat() ? 'd LLL y HH:mm (zzzz)' : 'd LLL y hh:mm b (zzzz)');
};

export default displayDate;
