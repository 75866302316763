import React, { useMemo, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { STEP_NAVIGATION_TYPES, STEP_NEXT_TYPE } from 'global';
import { isObjectEmpty } from 'helpers/objectManagement.js';
import useGuideStepConnectionsVisibility from '@playerCommon/hooks/playerHooks/useGuideStepConnectionsVisibility';
import useGuidedTourStepEnabledConnections from '@playerCommon/hooks/playerHooks/useGuidedTourStepEnabledConnections';
import { StyledLoader } from '@playerCommon/ComplexElements/ExplanationPlayer/Steps/Steps.styles';
import AiAnswerActionsListStepNexts from './AiAnswerActionsListStepNexts';

function AiAnswerActionsList({
  showActions,
  options,
  onStepNextClick,
  stepId,
  isPreview,
  stepConnectionList,
  shouldEveryConnectionBeEnabled,
  onShouldShowNextButtonSelectorChange,
  onPredefinedAiQuestionSelect,
}) {
  const { isLoading, visibleConnectionIdList } = useGuideStepConnectionsVisibility({
    connections: stepConnectionList,
    isPreview,
    stepId,
  });
  const guidedTourEnabledConnectionIdsList = useGuidedTourStepEnabledConnections({
    stepConnectionList,
    shouldEveryConnectionBeEnabled,
  });
  const isEveryConnectionHasTypeInteraction = stepConnectionList.every(
    ({ navigationType }) => navigationType === STEP_NAVIGATION_TYPES.INTERACTION
  );
  const visibleConnections = useMemo(() => {
    const visibleOptions = options.filter(({ id }) => visibleConnectionIdList.includes(id));
    return shouldEveryConnectionBeEnabled
      ? visibleOptions
      : visibleOptions.map(o => ({
          ...o,
          disabled: !guidedTourEnabledConnectionIdsList.includes(o.id),
        }));
  }, [options, visibleConnectionIdList, guidedTourEnabledConnectionIdsList, shouldEveryConnectionBeEnabled]);

  const buttonClickedRef = useRef(false);

  const firstConnection = visibleConnections[0];
  const hasNextStep =
    visibleConnections.length > 1 || (visibleConnections.length === 1 && !isObjectEmpty(firstConnection));

  const shouldShowNextButtonSelector = hasNextStep && showActions;

  useEffect(() => {
    buttonClickedRef.current = false;
  }, [stepId]);

  useEffect(() => {
    onShouldShowNextButtonSelectorChange?.(shouldShowNextButtonSelector);
  }, [shouldShowNextButtonSelector]);

  // Prevent clicking on the button multiple times within short period of time
  // Consequent clicks are causing the guided tour to stop working due to multiple steps being skipped at the same time
  const onStepNextClickProxy = useCallback(
    option => {
      if (option.toStepId === STEP_NEXT_TYPE.AI_QUESTION) {
        onPredefinedAiQuestionSelect(option.label);
        return;
      }
      if (buttonClickedRef.current) {
        return;
      }
      buttonClickedRef.current = true;
      setTimeout(() => {
        buttonClickedRef.current = false;
      }, 1000);
      onStepNextClick(option);
    },
    [onStepNextClick, onPredefinedAiQuestionSelect]
  );

  if (isLoading && !isEveryConnectionHasTypeInteraction) {
    return <StyledLoader type="circle" />;
  }

  return shouldShowNextButtonSelector ? (
    <AiAnswerActionsListStepNexts
      options={visibleConnections}
      onStepNextClick={onStepNextClickProxy}
      disabled={isPreview}
    />
  ) : null;
}

AiAnswerActionsList.propTypes = {
  options: PropTypes.array,
  onStepNextClick: PropTypes.func,
  stepId: PropTypes.number,
  isPreview: PropTypes.bool,
  stepConnectionList: PropTypes.array,
  shouldEveryConnectionBeEnabled: PropTypes.bool,
  onShouldShowNextButtonSelectorChange: PropTypes.func,
  onPredefinedAiQuestionSelect: PropTypes.func,
  showActions: PropTypes.bool,
};

export default AiAnswerActionsList;
