import React, { Suspense, lazy } from 'react';
import Loader, { FullscreenLoaderWrap } from '@playerCommon/CustomElements/Loader/Loader';

const TableOfContentsTree = lazy(() => import(/* webpackChunkName: "tableOfContentsTree" */ './TableOfContentsTree'));

const renderLoadingIndicator = () => (
  <FullscreenLoaderWrap>
    <Loader type="circle" />
  </FullscreenLoaderWrap>
);

export function TableOfContentsTreeAsyncWrapper(props) {
  const isSSR = typeof window === 'undefined';

  return isSSR ? null : (
    <Suspense fallback={renderLoadingIndicator()}>
      <TableOfContentsTree {...props} />
    </Suspense>
  );
}
