import React from 'react';
import styled, { css } from 'styled-components';
import DownPickerSVG from 'icons/downPickerDark.svg';
import PropTypes from 'prop-types';

const DropdownIcon = styled(DownPickerSVG)`
  position: absolute;
  right: 12px;
  opacity: 1;
  transition: opacity 0.2s;
`;

const DropdownLeftIconWrapper = styled.div`
  display: inline-flex;
  position: absolute;
  left: 8px;

  & > svg {
    display: block;

    ${({ shouldUseOriginalIconFill }) =>
      !shouldUseOriginalIconFill &&
      css`
        path,
        circle {
          fill: ${({ theme, isHighlighted }) => (isHighlighted ? theme.secondaryColor : theme.grey)};
        }
      `}
  }
`;

const DropdownInputWrap = styled.div`
  width: 100%;
  position: relative;
  height: 40px;
  display: inline-flex;
  align-items: center;
  padding: 12px;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  background: white;
  transition: color 0.2s;
  padding-right: 32px;
  margin-top: 8px;
  padding-left: ${({ hasLeftIcon, leftIconSize }) => (hasLeftIcon ? leftIconSize + 20 : 12)}px;
  border: 1px solid ${({ theme, isHighlighted }) => (isHighlighted ? theme.secondaryColor : theme.grey)};
  ${({ background }) =>
    background &&
    css`
      background: ${background};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      color: ${props => props.theme.grey};

      ${DropdownIcon} {
        opacity: 0.3;
      }
    `}

  ${({ readOnly }) =>
    readOnly &&
    css`
      background: ${props => props.theme.paleGrey};
      border-color: ${props => props.theme.silver};
      color: ${props => props.theme.steel};
      cursor: auto;

      ${DropdownIcon} {
        opacity: 0.3;
      }
    `}

  ${({ isPlaceholder }) =>
    isPlaceholder &&
    css`
      color: ${props => props.theme.lightGrey};
    `}

  ${({ invalid }) =>
    invalid &&
    css`
      border: 1px solid ${({ theme }) => theme.errorColor};
    `}

    ${({ isValid }) =>
    isValid &&
    css`
      border: 1px solid ${({ theme }) => theme.seaweed};
    `}
`;

export const NameWrap = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: -1px;
`;

const DropdownButton = React.forwardRef(
  (
    {
      name,
      background,
      className,
      onClick,
      disabled,
      isPlaceholder,
      isHighlighted,
      dataCy,
      leftIcon: LeftIcon,
      leftIconSize = 16,
      invalid = false,
      isValid,
      onMouseEnter,
      onMouseLeave,
      shouldUseOriginalIconFill = false,
    },
    ref
  ) => (
    <DropdownInputWrap
      background={background}
      className={['dropdownInputWrap', className].join(' ')}
      onClick={onClick}
      disabled={disabled}
      isPlaceholder={isPlaceholder}
      isHighlighted={isHighlighted}
      data-cy={dataCy}
      hasLeftIcon={!!LeftIcon}
      leftIconSize={leftIconSize}
      invalid={invalid}
      isValid={isValid}
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {LeftIcon && (
        <DropdownLeftIconWrapper isHighlighted={isHighlighted} shouldUseOriginalIconFill={shouldUseOriginalIconFill}>
          <LeftIcon />
        </DropdownLeftIconWrapper>
      )}
      <NameWrap data-cy="nameWrap">{name}</NameWrap>
      <DropdownIcon />
    </DropdownInputWrap>
  )
);

DropdownButton.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]),
  background: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  isPlaceholder: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  disabled: PropTypes.bool,
  dataCy: PropTypes.string,
  leftIcon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  leftIconSize: PropTypes.number,
  invalid: PropTypes.bool,
  isValid: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  shouldUseOriginalIconFill: PropTypes.bool,
};

export default DropdownButton;
