/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable no-undef */
import isMobile from 'ismobilejs-es5';
import { getGlobal } from 'global/windowUtils';

const isMobileSafari = () =>
  isMobile && isMobile(getGlobal('userAgent')).apple && isMobile(getGlobal('userAgent')).apple.phone;
const isMobileDevice = () => isMobile && isMobile(getGlobal('userAgent')).any;

const safeWindow = typeof window === 'undefined' ? {} : window;

const isFirefox = typeof InstallTrigger !== 'undefined';

const isSafari =
  /constructor/i.test(safeWindow.HTMLElement) ||
  (function (p) {
    return p.toString() === '[object SafariRemoteNotification]';
  })(!safeWindow.safari || (typeof safari !== 'undefined' && safari.pushNotification));

const isIE = typeof document !== 'undefined' && /* @cc_on!@ */ (false || !!document.documentMode);

const isEdge = !isIE && !!safeWindow.StyleMedia;

const isChrome = () => !!safeWindow.chrome;

const getIsMobileIntegration = () => getGlobal('userAgent')?.includes('Stonly/MobileIntegration');
const getIsiOSMobileIntegration = () => getGlobal('userAgent')?.includes('Stonly/MobileIntegration/Apple');
const getIsAndroidMobileIntegration = () => getGlobal('userAgent')?.includes('Stonly/MobileIntegration/Android');

const isMac = !!safeWindow.navigator && safeWindow.navigator.platform.toUpperCase().includes('MAC');

export {
  isMobileSafari,
  isMobileDevice,
  isFirefox,
  isSafari,
  isIE,
  isEdge,
  isChrome,
  getIsMobileIntegration,
  getIsiOSMobileIntegration,
  getIsAndroidMobileIntegration,
  isMac,
};
