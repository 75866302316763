import React, { useCallback } from 'react';
import { TreeSearchInput } from '@playerCommon/ComplexElements/FlatTree/blocks/TreeSearchInput';
import { useGuideStepsFinder } from '../../shared/GuideStepsFinderProvider';

export const GuideStepsTreeSearchInput = props => {
  const { getIsStepIdMatchingPhrase } = useGuideStepsFinder();

  const rowMatcher = useCallback(
    (row, phrase) => {
      if (row.isFolder) {
        return false;
      }
      if (row.payload.stepId <= 0) {
        return false;
      }
      return getIsStepIdMatchingPhrase(row.payload.stepId, phrase);
    },
    [getIsStepIdMatchingPhrase]
  );

  return <TreeSearchInput getIsRowMatching={rowMatcher} {...props} />;
};
