import React from 'react';
import styled, { useTheme } from 'styled-components';
import CheckboxTickSVG from 'icons/tickSmall.svg';
import type { TickProps } from './FieldCheck.types';

const IconTick = styled(CheckboxTickSVG)<{ $color: string }>`
  position: absolute;
  width: 16px;
  height: 16px;
  & path {
    fill: ${({ $color }) => $color};
  }
`;

const Rect = styled.div<{ $borderColor?: string; $backgroundColor?: string; $checked?: boolean }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  transition: border-color 0.2s, background-color 0.2s;
  margin-top: 4px;
  margin-left: 2px;
  border-color: ${({ $borderColor }) => $borderColor};
  background-color: ${({ $backgroundColor }) => $backgroundColor};

  ${IconTick} {
    visibility: ${({ $checked }) => ($checked ? 'visible' : 'hidden')};
  }
`;

export const TickCheckboxRect = ({ disabled, checked, statusBorderColor, ...restDivAttributes }: TickProps) => {
  const theme = useTheme();

  /* Resolve border color */
  let borderColor = '';
  if (disabled) {
    borderColor = theme.silver;
  } else if (checked) {
    borderColor = theme.highlightColor;
  } else if (statusBorderColor) {
    borderColor = statusBorderColor;
  } else {
    borderColor = theme.grey;
  }

  /* Resolve background color */
  let backgroundColor = '';
  if (disabled) {
    if (checked) {
      backgroundColor = theme.silver;
    }
    backgroundColor = theme.paleGrey;
  } else if (checked) {
    // enabled & checked
    backgroundColor = theme.highlightColor;
  } else {
    backgroundColor = theme.white;
  }

  /* Resolve check color */
  const checkColor = disabled ? theme.lightGrey : theme.white;

  return (
    <Rect {...restDivAttributes} $borderColor={borderColor} $backgroundColor={backgroundColor} $checked={!!checked}>
      <IconTick $color={checkColor} />
    </Rect>
  );
};
