import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import RadioButton from './RadioButton';

const Canvas = styled.fieldset`
  border: none;
  ${({ added, removed, modified }) => {
    if (removed) {
      return css`
        span {
          border-color: ${props => props.theme.amaranth};
        }
      `;
    }
    if (added) {
      return css`
        span {
          border-color: ${props => props.theme.seaweed};
        }
      `;
    }
    if (modified) {
      return css`
        span {
          border-color: ${props => props.theme.mango};
        }
      `;
    }
  }}
`;

const ErrorMessage = styled.div`
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.functional.errorRed};
  padding-bottom: 8px;
`;

const RadioButtons = ({
  className,
  options,
  selectedId,
  selectOption,
  added,
  removed,
  modified,
  disabled,
  errorMessage,
}) => (
  <Canvas className={className} added={added} removed={removed} modified={modified}>
    {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    {options.map(option => (
      <RadioButton
        key={option.id}
        id={option.id}
        dataCy="radioButton"
        dataStaticValue={option.staticValue}
        label={option.label}
        onClick={() => selectOption(option.id, option)}
        value={option.value}
        checked={options.length === 1 || selectedId === option.id}
        disabled={disabled}
        added={option.added}
        removed={option.removed}
        modified={option.modified}
        className={option.className}
      />
    ))}
  </Canvas>
);

RadioButtons.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array,
  selectedId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selectOption: PropTypes.func,
  disabled: PropTypes.bool,
  added: PropTypes.bool,
  removed: PropTypes.bool,
  modified: PropTypes.bool,
  errorMessage: PropTypes.string,
};
export default RadioButtons;
