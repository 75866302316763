export const AUTHENTICATION_VIEWS = Object.freeze({
  ACCEPT_INVITATION: 'acceptInvitation',
  CONFIRM_PASSWORD: 'confirmPassword',
  PASSWORD_RECOVERY: 'passwordRecovery',
  REGISTRATION: 'registration',
  REGISTRATION_WITH_GOOGLE: 'registrationWithGoogle',
  RESET_PASSWORD: 'resetPassword',
  SIGN_IN: 'signIn',
  SIGN_IN_WITH_SSO: 'signInWithSSO',
  UNSUBSCRIBE: 'unsubscribe',
  VERIFY_EMAIL: 'verifyEmail',
});

export const POST_AUTHENTICATION_ACTIONS = Object.freeze({
  CREATE_FROM_TEMPLATE: 'createFromTemplate',
});

export const SIGN_UP_FLOW_VARIANTS = Object.freeze({
  GOOGLE_DIALOG_FULL_SCREEN: 'google_dialog_full_screen',
  GOOGLE_BUTTON_FULL_SCREEN: 'google_button_full_screen',
});

export const SIGN_IN_HASH = '#signin';

export const FUNNEL_FLOW_TYPE = Object.freeze({
  CLASSIC: 'classic_authentication',
  GOOGLE: 'google_authentication',
  SEE_IN_ACTION: 'see_in_action',
  GET_A_DEMO: 'get_a_demo',
  SIGNUP_PAGE: 'signup_page',
  AUTHENTICATION_REGISTRATION: 'authentication_registration',
});

export const PHONE_NUMBER_STEP = Object.freeze({
  CLICK_CONTINUE: 'phone_number_click_continue',
  FORM_ERROR: 'phone_number_format_error',
  REQUEST_SHOWN: 'phone_number_request_shown',
});

export const AUTHENTICATION_TITLE_ID = 'authentication-title';
