/* eslint-disable react/jsx-one-expression-per-line */
import React, { useCallback, useState } from 'react';
import SendSVG from 'icons/send-24.svg';

import { useTranslation } from 'react-i18next';
import {
  SmartSearchResultsFollowupInputWrapWrapper,
  SmartSearchResultsFollowupInput,
  SmartSearchResultsFollowupInputWrap,
  SmartSearchResultsFollowupInputWrapInner,
  SmartSearchResultsFollowupSend,
  SmartSearchTextCounter,
} from './AiAnswerMessageInput.styles';
import AiAnswerResetButton from './AiAnswerResetButton';
import { AiAnswerUnreadMessagesButton } from './AiAnswerUnreadMessagesButton';

const MAX_QUESTION_LENGTH = 250;
const INPUT_TWO_LINES_HEIGHT = 72;
const WARNING_THRESHOLD = 0.2;

const AiAnswerMessageInput = ({
  value,
  onChange,
  onSubmit,
  disabled,
  onResetSession,
  visible,
  messagesCount,
  isPreview,
  compact,
  displayProgressBar,
}) => {
  const { t } = useTranslation();
  const isWarningVisible = value.length > MAX_QUESTION_LENGTH * (1 - WARNING_THRESHOLD);

  const [isFollowupInputFocused, setIsFollowupInputFocused] = useState(false);

  const onFollowupInputFocus = useCallback(() => setIsFollowupInputFocused(true), [setIsFollowupInputFocused]);
  const onFollowupInputBlur = useCallback(() => setIsFollowupInputFocused(false), [setIsFollowupInputFocused]);

  const [isQuestionLengthCounterVisible, setIsQuestionLengthCounterVisible] = useState(false);

  const onTextareaResize = useCallback(height => {
    setIsQuestionLengthCounterVisible(height > INPUT_TWO_LINES_HEIGHT);
  }, []);

  const onFollowupQuestionInputKeydown = useCallback(
    e => {
      if (e.key === 'Enter') {
        onSubmit();
      }
    },
    [onSubmit]
  );

  return (
    <SmartSearchResultsFollowupInputWrapWrapper
      className="ai-step-followup-input-sticky-wrap"
      isPreview={isPreview}
      compact={compact}
      visible={visible}
      progressBarVisible={displayProgressBar}
    >
      <AiAnswerUnreadMessagesButton isVisible={visible} />
      <SmartSearchResultsFollowupInputWrap
        className="ai-step-followup-input-wrap"
        onSubmit={disabled ? undefined : onSubmit}
        compact={compact}
        progressBarVisible={displayProgressBar}
      >
        <SmartSearchResultsFollowupInputWrapInner role="search">
          <SmartSearchTextCounter
            visible={isQuestionLengthCounterVisible}
            warning={isWarningVisible}
            aria-live={isWarningVisible ? 'polite' : undefined}
            aria-atomic={isWarningVisible ? 'true' : undefined}
            role={isWarningVisible ? 'status' : undefined}
          >
            {value.length}/{MAX_QUESTION_LENGTH}
          </SmartSearchTextCounter>
          <SmartSearchResultsFollowupInput
            value={value}
            onChange={onChange}
            onKeyDown={onFollowupQuestionInputKeydown}
            onFocus={onFollowupInputFocus}
            onBlur={onFollowupInputBlur}
            onResize={onTextareaResize}
            maxLength={MAX_QUESTION_LENGTH}
            disabled={disabled || !visible}
            placeholder={messagesCount ? t('AiStep.FollowupPrompt') : t('AiStep.TypeQuestionPrompt')}
            className="followup-input"
            aria-label={messagesCount ? t('AiStep.FollowupPrompt') : t('AiStep.TypeQuestionPrompt')}
          />
          {!!onResetSession && !value && !isFollowupInputFocused && (
            <AiAnswerResetButton onResetSession={onResetSession} />
          )}
          <SmartSearchResultsFollowupSend
            disabled={disabled || !visible}
            onClick={disabled ? undefined : onSubmit}
            className="followup-input-submit-button"
            aria-label={t('Global.Send')}
          >
            <SendSVG aria-hidden />
          </SmartSearchResultsFollowupSend>
        </SmartSearchResultsFollowupInputWrapInner>
      </SmartSearchResultsFollowupInputWrap>
    </SmartSearchResultsFollowupInputWrapWrapper>
  );
};

export default AiAnswerMessageInput;
