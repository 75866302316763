import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const AnchorLink = styled.a`
  position: fixed;
  top: 8px;
  left: 8px;
  z-index: 101;
  padding: 8px;
  opacity: 0;
  pointer-events: none;
  background-color: ${props => props.theme.silver};

  &:focus {
    outline: none;
    opacity: 1;
    pointer-events: all;
    box-shadow: 0px 0px 0px 2px ${props => props.theme.white}, 0px 0px 0px 4px ${props => props.theme.darkBlue};
  }
`;

export const SkipToMainContentLink = () => {
  const { t } = useTranslation();
  return (
    <AnchorLink tabIndex={0} href="#main">
      {t('ExplanationPlayer.SkipToMainContent')}
    </AnchorLink>
  );
};
