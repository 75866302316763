import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ItemWrapper, ChecklistItemIcon } from './Checklist.styles';
import ChecklistItemLabel from './ChecklistItemLabel';

const ChecklistItem = ({
  id,
  title,
  triggerActionOnClick,
  toggleItem,
  isChecked = false,
  disabled = false,
  isStatusLoaded = false,
  hasActionOnClick = false,
  canBeCheckedManually = false,
}) => {
  const { t } = useTranslation();
  const toggleItemProxy = useCallback(() => {
    if (!disabled) {
      toggleItem(id);
    }
  }, [id, toggleItem, disabled]);

  const toggleItemKeyDown = e => {
    if (e.key === ' ' || e.key === 'Enter') {
      toggleItemProxy();
    }
  };

  const triggerActionOnClickProxy = useCallback(
    e => {
      e.preventDefault();
      if (!disabled) {
        triggerActionOnClick(id);
      }
    },
    [id, triggerActionOnClick, disabled]
  );

  const triggerActionOnClickKeyDown = e => {
    if (e.key === ' ' || e.key === 'Enter') {
      triggerActionOnClickProxy(e);
    }
  };

  const itemWrapperCommonProps = useMemo(
    () => ({
      className: [
        'checklist-item',
        `checklist-item-${id}`,
        isChecked ? 'checked' : undefined,
        disabled ? 'disabled' : undefined,
      ]
        .filter(Boolean)
        .join(' '),
      isChecked,
      isDisabled: disabled,
      isStatusLoaded,
    }),
    [id, isChecked, disabled, isStatusLoaded]
  );

  const checklistItemIconCommonProps = useMemo(
    () => ({
      id,
      isChecked,
      isDisabled: disabled,
      isLoading: !isStatusLoaded,
    }),
    [id, isChecked, disabled, isStatusLoaded]
  );

  if (hasActionOnClick) {
    if (canBeCheckedManually) {
      return (
        <ItemWrapper canBeCheckedManually {...itemWrapperCommonProps}>
          <ChecklistItemIcon
            {...checklistItemIconCommonProps}
            onClick={toggleItemProxy}
            onKeyDown={toggleItemKeyDown}
          />
          <ChecklistItemLabel
            showChevronIcon
            title={title}
            onClick={triggerActionOnClickProxy}
            onKeyDown={triggerActionOnClickKeyDown}
            checkboxId={id}
            tabIndex={0}
          />
        </ItemWrapper>
      );
    }

    return (
      <ItemWrapper
        hasSingleAction
        onClick={triggerActionOnClickProxy}
        onKeyDown={triggerActionOnClickKeyDown}
        {...itemWrapperCommonProps}
        tabIndex={0}
        role="link"
        aria-disabled={disabled}
        aria-label={`${title} ${isChecked ? t('Checklist.ChecklistChecked') : t('Checklist.ChecklistUnchecked')}, ${t(
          'Checklist.ChecklistLinkOrGuideLabel'
        )}`}
      >
        <ChecklistItemIcon {...checklistItemIconCommonProps} tabIndex={-1} />
        <ChecklistItemLabel showChevronIcon title={title} checkboxId={id} />
      </ItemWrapper>
    );
  }

  if (canBeCheckedManually) {
    return (
      <ItemWrapper
        hasSingleAction
        canBeCheckedManually
        onClick={toggleItemProxy}
        onKeyDown={toggleItemKeyDown}
        {...itemWrapperCommonProps}
      >
        <ChecklistItemIcon {...checklistItemIconCommonProps} />
        <ChecklistItemLabel title={title} checkboxId={id} />
      </ItemWrapper>
    );
  }

  return (
    <ItemWrapper isReadOnly {...itemWrapperCommonProps}>
      <ChecklistItemIcon {...checklistItemIconCommonProps} tabIndex={-1} />
      <ChecklistItemLabel title={title} checkboxId={id} />
    </ItemWrapper>
  );
};

ChecklistItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  triggerActionOnClick: PropTypes.func,
  toggleItem: PropTypes.func,
  isChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  isStatusLoaded: PropTypes.bool,
  hasActionOnClick: PropTypes.bool,
  canBeCheckedManually: PropTypes.bool,
};

export default ChecklistItem;
