import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import Input from '@playerCommon/StandardElements/Input';
import SearchIconSVG from 'icons/search.svg';
import SelectUpSVG from 'icons/selectUp.svg';
import SelectDownSVG from 'icons/selectDown.svg';
import CrossIconSVG from 'icons/crossMedium.svg';
import { useTreeSearchInputProps } from '../hooks/useTreeSearchInputProps';

const DarkSearchIcon = styled(SearchIconSVG)`
  margin-right: 8px;
  flex-shrink: 0;
  path {
    fill: ${props => props.theme.darkGrey};
  }
`;

const Canvas = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 4px 0;
  ${({ isFocused, theme }) =>
    isFocused &&
    css`
      ${DarkSearchIcon} {
        & path {
          fill: ${theme.secondaryColor};
        }
      }
    `}
`;

const SearchInput = styled(Input)`
  width: 100%;
  margin-right: auto;
`;

const SearchDetails = styled.div`
  display: flex;
  align-items: center;
`;

const iconStyle = css`
  margin-left: 8px;
  cursor: pointer;
  path {
    fill: ${props => props.theme.darkGrey};
  }
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
    `}
`;

const CloseAndClearSearch = styled(CrossIconSVG)`
  ${iconStyle};
  width: 16px;
  height: 16px;
`;

const UpIcon = styled(SelectUpSVG)`
  ${iconStyle};
`;

const DownIcon = styled(SelectDownSVG)`
  ${iconStyle};
`;

const FocusedItemNumber = styled.div`
  margin-right: 4px;
  font-size: 10px;
  color: ${props => props.theme.lightGrey};
  user-select: none;
`;

const generateDistinctStepRowUuidList = matchedRowList => {
  const foundStepIds = {};
  return matchedRowList.reduce((acc, curr) => {
    const { stepId } = curr.payload;
    if (foundStepIds[stepId]) {
      return acc;
    }
    foundStepIds[stepId] = true;
    return [...acc, curr.uuid];
  }, []);
};

export const TreeSearchInput = ({
  onFocus,
  onBlur,
  onReset,
  autofocus,
  getIsRowMatching,
  placeholder = '',
  ...restProps
}) => {
  const [isFieldFocused, setIsFieldFocused] = useState(!!autofocus);
  const {
    searchPhrase,
    setSearchPhrase,
    goToNextResultRow,
    goToPreviousResultRow,
    resetSearch,
    resultsCount,
    focusedResultRowIndex,
    focusedResultRowDisplayNumber,
    isGoToNextResultRowDisabled,
    isGoToPreviousResultRowDisabled,
  } = useTreeSearchInputProps({
    getIsRowMatching,
    generateFocusRowUuidListFromResult: generateDistinctStepRowUuidList,
  });

  const handleReset = () => {
    resetSearch();
    setIsFieldFocused(false);
    if (typeof onReset === 'function') {
      onReset();
    }
  };

  const handleFocus = () => {
    if (!isFieldFocused) {
      setIsFieldFocused(true);
    }
  };

  const handleBlur = () => {
    if (!searchPhrase.length) {
      setIsFieldFocused(false);
    }
  };

  useEffect(() => {
    if (isFieldFocused) {
      if (typeof onFocus === 'function') {
        onFocus();
      }
    } else if (typeof onBlur === 'function') {
      onBlur();
    }
  }, [isFieldFocused]);

  return (
    <Canvas isFocused={isFieldFocused} {...restProps}>
      <DarkSearchIcon />
      <SearchInput
        onChange={setSearchPhrase}
        placeholder={placeholder}
        skin="wrapped"
        value={searchPhrase}
        onFocus={handleFocus}
        onBlur={handleBlur}
        autofocus={autofocus}
      />
      <SearchDetails>
        {resultsCount > 0 && (
          <>
            <FocusedItemNumber>{`${focusedResultRowDisplayNumber}/${resultsCount}`}</FocusedItemNumber>
            <UpIcon disabled={isGoToPreviousResultRowDisabled} onClick={goToPreviousResultRow} />
            <DownIcon disabled={isGoToNextResultRowDisabled} onClick={goToNextResultRow} />
          </>
        )}
        {searchPhrase.length > 0 && <CloseAndClearSearch onClick={handleReset} />}
      </SearchDetails>
    </Canvas>
  );
};
