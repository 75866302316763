import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Canvas = styled.div`
  color: ${({ theme }) => theme.errorColor};
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  margin-top: 8px;
`;

const ErrorMessage = ({ children, className, dataCy }) => {
  return (
    <Canvas className={className} data-cy={dataCy}>
      {children}
    </Canvas>
  );
};

ErrorMessage.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string,
  dataCy: PropTypes.string,
};

export default ErrorMessage;
