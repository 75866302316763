import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Markup } from 'interweave';
import { getGlobal } from 'global/windowUtils';
import {
  QuestionAnswerWrapper,
  QuestionCanvas,
  AnswerCanvas,
  AnswerVotedCanvas,
  LinkClickedCanvas,
  Link,
  StepIcon,
  ThumbsUpIcon,
  ResetIcon,
  ConversationRestartedCanvas,
  ExternalLinkIcon,
} from './ConversationInteraction.styles.js';

const AI_INTERACTION = {
  aiAnswerSearchCompleted: 'aiAnswerSearchCompleted',
  semanticSearchCompleted: 'semanticSearchCompleted',
  searchVote: 'searchVote',
  aiConversationRestarted: 'aiConversationRestarted',
  aiAnswerLinkClicked: 'aiAnswerLinkClicked',
};

const ConversationInteraction = ({ aiInteraction }) => {
  const { t } = useTranslation();
  const hostname = getGlobal('host');

  return (
    <>
      {[AI_INTERACTION.semanticSearchCompleted, AI_INTERACTION.aiAnswerSearchCompleted].includes(
        aiInteraction.actionType
      ) && (
        <QuestionAnswerWrapper>
          <QuestionCanvas>{aiInteraction.originalQuestion}</QuestionCanvas>
          <AnswerCanvas hostname={hostname}>
            <Markup content={aiInteraction.answer} />
          </AnswerCanvas>
        </QuestionAnswerWrapper>
      )}
      {aiInteraction.actionType === AI_INTERACTION.aiAnswerLinkClicked && (
        <LinkClickedCanvas>
          {aiInteraction.sourceId ? <ExternalLinkIcon /> : <StepIcon />}
          {t('SessionDetail.LinkOpened')}
          <Link href={aiInteraction.link}>{aiInteraction.linkContent}</Link>
        </LinkClickedCanvas>
      )}
      {aiInteraction.actionType === AI_INTERACTION.searchVote && (
        <AnswerVotedCanvas vote={aiInteraction.vote}>
          <ThumbsUpIcon reversed={!aiInteraction.vote} />
          {aiInteraction.vote ? t('SessionDetail.AnswerUpvoted') : t('SessionDetail.AnswerDownvoted')}
        </AnswerVotedCanvas>
      )}
      {aiInteraction.actionType === AI_INTERACTION.aiConversationRestarted && (
        <ConversationRestartedCanvas>
          <ResetIcon />
          {t('SessionDetail.ConversationRestarted')}
        </ConversationRestartedCanvas>
      )}
    </>
  );
};

ConversationInteraction.propTypes = {
  aiInteraction: PropTypes.object,
};

export default ConversationInteraction;
