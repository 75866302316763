import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';
import { assetsUrl } from 'global/env';
import Warning from 'resources/icons/warning.svg';
import Tip from 'resources/icons/tip.svg';
import Tick from 'resources/icons/tickMedium.svg';
import Eye from 'resources/icons/eye.svg';
import EyeClosed from 'resources/icons/eyeClosed.svg';
import Icon from '@playerCommon/StandardElements/Icon';
import { BaseInput } from './Input.styles';
import { STATUS_TYPES } from './Input.consts';

const IconWrapPassword = styled(BaseInput.IconWrap)`
  margin: 12px 12px 12px 0;
  color: ${({ theme }) => theme.slateGrey};
  cursor: pointer;
`;

const EyeIcon = styled(Eye)`
  path {
    fill: ${({ theme }) => theme.slateGrey};
  }
`;

const EyeClosedIcon = styled(EyeClosed)`
  path {
    fill: ${({ theme }) => theme.slateGrey};
  }
`;

const PasswordTip = styled(Icon)`
  margin-left: 8px;
  vertical-align: sub;
`;

const PasswordInput = ({
  id,
  value,
  type,
  name,
  label,
  statusMessage,
  statusType,
  disabled,
  placeholder,
  iconLeft,
  onChange,
  onBlur,
  onFocus,
  dataCy,
  onEyeToggle,
  isEyeShown,
  tooltip,
  forceOpenTooltip,
}) => {
  const inputId = id && `input-${id}`;
  const statusMessageId = id && `status-message-${id}`;
  const MessageIcon = useMemo(() => {
    if (statusType === STATUS_TYPES.ERROR || statusType === STATUS_TYPES.WARNING) return <Warning />;
    if (statusType === STATUS_TYPES.SUCCESS) return <Tick />;
    if (statusType === STATUS_TYPES.INFO) return <Tip />;
    return null;
  }, [statusType]);
  const theme = useTheme();

  return (
    <BaseInput.Container>
      <BaseInput.Label data-cy={dataCy && `${dataCy}Title`} htmlFor={inputId}>
        {label}
        {tooltip && (
          <PasswordTip
            src={`${assetsUrl}resources/icons/questionMarkCircleSmall.svg`}
            tooltip={tooltip}
            color={theme.lightGrey}
            colorHover={theme.secondaryColor}
            positionTooltip="upLeft"
            forceOpenTooltip={forceOpenTooltip}
            widthTooltip={286}
          />
        )}
      </BaseInput.Label>
      <BaseInput.ElementWrap statusType={statusType} disabled={disabled}>
        {iconLeft && <BaseInput.IconWrapLeft>{iconLeft}</BaseInput.IconWrapLeft>}
        <BaseInput.InputElement
          id={inputId}
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          data-cy={dataCy}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          aria-invalid={statusType === STATUS_TYPES.ERROR}
          aria-errormessage={statusType === STATUS_TYPES.ERROR ? statusMessageId : undefined}
          tabIndex="0"
        />
        {MessageIcon && <BaseInput.IconWrapRight statusType={statusType}>{MessageIcon}</BaseInput.IconWrapRight>}
        {isEyeShown && (
          <IconWrapPassword onMouseDown={onEyeToggle}>
            {type === 'password' ? <EyeIcon /> : <EyeClosedIcon />}
          </IconWrapPassword>
        )}
      </BaseInput.ElementWrap>
      <BaseInput.MessageWrap statusType={statusType} data-cy={dataCy && `${dataCy}Status`}>
        {statusMessage && (
          <span id={statusMessageId} aria-live="assertive">
            {statusMessage}
          </span>
        )}
      </BaseInput.MessageWrap>
    </BaseInput.Container>
  );
};

PasswordInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  statusMessage: PropTypes.string,
  statusType: PropTypes.oneOf([STATUS_TYPES.ERROR, STATUS_TYPES.INFO, STATUS_TYPES.SUCCESS, STATUS_TYPES.WARNING]),
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  iconLeft: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  dataCy: PropTypes.string,
  onEyeToggle: PropTypes.func,
  isEyeShown: PropTypes.bool,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
  forceOpenTooltip: PropTypes.bool,
};
export default PasswordInput;
