/* eslint-disable no-plusplus */
import { random } from 'lodash';

export const arrayContainsArray = (superset, subset) => {
  if (subset.length === 0) {
    return false;
  }
  return subset.every(value => superset.includes(value));
};

export const dynamicSort = property => {
  let sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    // eslint-disable-next-line no-param-reassign
    property = property.slice(1);
  }

  // eslint-disable-next-line func-names
  return function (a, b) {
    const firstComp = typeof a[property] === 'string' ? a[property].toLowerCase() : a[property];
    const secondComp = typeof b[property] === 'string' ? b[property].toLowerCase() : b[property];

    // eslint-disable-next-line no-nested-ternary
    const result = firstComp < secondComp ? -1 : firstComp > secondComp ? 1 : 0;
    return result * sortOrder;
  };
};

export const unique = mainArray => {
  const arr = [];
  for (const element of mainArray) {
    if (!arr.includes(element)) {
      arr.push(element);
    }
  }
  return arr;
};

export const uniqBy = (arr, predicate) => {
  const cb = typeof predicate === 'function' ? predicate : o => o[predicate];

  return [
    ...arr
      .reduce((map, item) => {
        const key = item === null || item === undefined ? item : cb(item);

        /* eslint-disable-next-line no-unused-expressions */
        map.has(key) || map.set(key, item);

        return map;
      }, new Map())
      .values(),
  ];
};

export const testArrayEqual = (array1, array2) => {
  // if the other array is a falsy value, return
  if (!array1) return false;

  // compare lengths - can save a lot of time
  if (array2.length !== array1.length) return false;

  for (let i = 0, l = array2.length; i < l; i++) {
    // Check if we have nested arrays
    if (Array.isArray(array2[i]) && Array.isArray(array1[i])) {
      // recurse into the nested arrays
      if (!array2[i].equals(array1[i])) return false;
    } else if (array2[i] !== array1[i]) {
      // Warning - two different object instances will never be equal: {x:20} != {x:20}
      return false;
    }
  }
  return true;
};

export const findOddNumberOfElements = array => {
  const newArray = [];
  const done = [];
  array.forEach(element => {
    if (!done.includes(element)) {
      if (array.filter(el => el === element).length % 2 !== 0) {
        newArray.push(element);
      }
      done.push(element);
    }
  });

  return newArray;
};

export const mergeRefs = (...refs) => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 0) return filteredRefs[0];
  return inst => {
    for (const ref of filteredRefs) {
      if (typeof ref === 'function') {
        ref(inst);
      } else if (ref) {
        ref.current = inst;
      }
    }
  };
};

// a little function to help with reordering elements in array
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const arraysIntersection = arrays => {
  if (!arrays || !arrays.length) return [];

  const intersectionArray = [];
  const [first, ...rest] = arrays;

  for (const value of first) {
    if (!intersectionArray.includes(value)) {
      let j;
      for (j = 0; j < rest.length; j++) {
        if (!rest[j].includes(value)) break;
      }
      if (j === rest.length) intersectionArray.push(value);
    }
  }

  return intersectionArray;
};

/** Groups elements from one array into two, depending on the provided condition's truthiness for each element */
export const partition = (array, condition) => {
  const pass = [];
  const fail = [];
  array.forEach((el, idx, arr) => (condition(el, idx, arr) ? pass : fail).push(el));
  return [pass, fail];
};

/** Returns last index of element that pass the condition(item, idx, arr) or -1 if not found */
export const findLastIndex = (array, condition) => {
  let idx = array.length;

  while (idx--) {
    if (condition(array[idx], idx, array)) {
      return idx;
    }
  }
  // not found
  return -1;
};

export function getRandomValuesArray(length, limitLow = 0, limitHigh = 100) {
  return new Array(length).fill(0).map(() => random(0, limitHigh, limitLow));
}
