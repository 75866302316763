import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CloseIconSVG from 'icons/close.svg';
import TickIconSVG from 'icons/tickMedium.svg';
import { passwordHintNames } from './passwordHints.consts';

const PasswordTooltipWrap = styled.div``;

const TooltipText = styled.div<{ isValid: boolean }>`
  color: ${({ isValid, theme }) => (isValid ? theme.mint : 'rgba(255, 255, 255, 0.7)')};
  margin-left: 5px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 6px;
    path {
      fill: ${({ isValid, theme }) => (isValid ? theme.mint : 'rgba(255, 255, 255, 0.7)')};
    }
  }
`;

interface PasswordHintsTooltipProps {
  passwordHints?: Record<string, boolean>;
}

const PasswordHintsTooltip = ({ passwordHints }: PasswordHintsTooltipProps) => {
  const { t } = useTranslation();
  return (
    <PasswordTooltipWrap>
      <span>{t('Registration.PasswordHintTitle')}</span>
      {passwordHints &&
        Object.entries(passwordHints).map(([key, value]) => (
          <TooltipText isValid={value} key={key}>
            {value ? <TickIconSVG /> : <CloseIconSVG />}
            {t(passwordHintNames[key])}
          </TooltipText>
        ))}
    </PasswordTooltipWrap>
  );
};

export default PasswordHintsTooltip;
