import callApi from 'helpers/apiHelpers.js';
import { playerUrl } from 'global/env';
import { jwtDecode } from '@playerCommon/helpers/jwtHelpers';
import { posthogCapture } from 'components/Analytics/recorder';
import { FUNNEL_STEP, POSTHOG_EVENT_NAME } from '@stonlyCommons/constants/Authentication.consts';
import { FUNNEL_FLOW_TYPE } from '../Authentication.consts';

const useGoogleAuth = ({ funnelId }) => {
  const handleRedirection = (res, handlePostAuthentication) => {
    if (res.data.newUser) {
      posthogCapture(POSTHOG_EVENT_NAME.DEMO_SIGNUP_FUNNEL, {
        funnelId,
        flow: FUNNEL_FLOW_TYPE.GOOGLE,
        step: FUNNEL_STEP.SIGNUP_SUCCESS,
      });
      posthogCapture(POSTHOG_EVENT_NAME.DEMO_SIGNUP_FUNNEL, {
        funnelId,
        flow: FUNNEL_FLOW_TYPE.GOOGLE,
        step: FUNNEL_STEP.SIGNUP_SUCCESS,
      });
      handlePostAuthentication({ res, isRegistration: true });
    } else {
      posthogCapture(POSTHOG_EVENT_NAME.SIGN_IN_FUNNEL, {
        funnelId,
        flow: FUNNEL_FLOW_TYPE.GOOGLE,
        step: FUNNEL_STEP.SIGNIN_SUCCESS,
      });
      window.location.href = `${playerUrl}/sso/google/status/success`;
    }
  };

  const handleErrors = (e, res) => {
    const userInfo = jwtDecode(res.credential);
    if (e.status === 404) {
      let url = `${playerUrl}/sso/google/status/failure`;
      url += `?reason=${e.error.message}`;
      window.location.href = url;
    } else if (e.status === 403) {
      posthogCapture(POSTHOG_EVENT_NAME.DEMO_SIGNUP_FUNNEL, {
        funnelId,
        flow: FUNNEL_FLOW_TYPE.GOOGLE,
        step: FUNNEL_STEP.REDIRECTED_TO_SSO,
      });
      window.location.href = `${playerUrl}/authentication/signInWithSSO?email=${userInfo.email}&isGoogleAuth=true`;
    } else if (e.status === 400) {
      window.location.href = `${playerUrl}/authentication/confirmPassword/${e.error.verificationCode}?email=${userInfo.email}&funnelId=${funnelId}`;
    }
  };

  const handleCredentialResponse = async (response, handlePostAuthentication) => {
    try {
      const res = await callApi(`v1/auth/login/google/callback`, 'post', { credential: response.credential });
      handleRedirection(res, handlePostAuthentication);
    } catch (e) {
      handleErrors(e, response);
    }
  };

  return {
    handleCredentialResponse,
    handleRedirection,
  };
};

export default useGoogleAuth;
