import React, { useCallback, useContext } from 'react';
import { FlatTreeContext } from '../FlatTreeProvider';
import { calculatePathLevel, isPathStartsWith } from '../helpers';
import {
  resolveDraggingUuid,
  resolveIsPathOpen,
  resolveRowByUuid,
  resolveIsUuidActive,
  resolveDraggingFolderFullPath,
  resolveHasPathActiveUuid,
  resolveUuidHighlightColor,
  resolveIsUuidSelected,
  resolveHasPathSelectedUuid,
} from '../resolvers';
import { useTreeModifiers } from './useTreeModifiers';

/** All the props needed to build a Row/Folder/Placeholder component. */
export const useTreeRowProps = ({ uuid }) => {
  const { state } = useContext(FlatTreeContext);
  const { openFolder, closeFolder, memoizedGetCanDropBelow, memoizedGetCanDropInside, setActive, goToRow, treeName } =
    useTreeModifiers();

  const draggingFolderPath = resolveDraggingFolderFullPath(state);
  const draggingUuid = resolveDraggingUuid(state);
  const rowData = resolveRowByUuid(state, uuid);
  const draggingRowData = resolveRowByUuid(state, draggingUuid);

  const { isFolder, path, isSpecial, payload, uuid: foundUuid } = rowData;

  /* isActive */
  const isActive = resolveIsUuidActive(state, uuid);
  const hasActiveInside = isFolder ? resolveHasPathActiveUuid(state, path, uuid) : false;

  /* isSelected */
  const isSelected = resolveIsUuidSelected(state, uuid);
  const hasSelectedInside = isFolder ? resolveHasPathSelectedUuid(state, path, uuid) : false;

  /* highlight */
  const highlightColor = resolveUuidHighlightColor(state, uuid);
  const isHighlighted = !!highlightColor;

  /* isVisible */
  const isVisible = resolveIsPathOpen(state, path);

  /* isOpen */
  const isOpen = isFolder && resolveIsPathOpen(state, path, uuid);

  /* level */
  const level = calculatePathLevel(path);

  /* isInDraggingFolder */
  const isInDraggingFolder = draggingFolderPath ? isPathStartsWith(path, draggingFolderPath) : false;

  /* isDraggingNow */
  const isDraggingNow = draggingUuid === uuid;

  /* canDropBelow */
  const canDropBelow =
    !isOpen && !isInDraggingFolder && !isDraggingNow && memoizedGetCanDropBelow(rowData, draggingRowData);

  /* canDropInside */
  const canDropInside =
    isFolder && !isInDraggingFolder && !isDraggingNow && memoizedGetCanDropInside(rowData, draggingRowData);

  /* isDropLocked - a tmp dropd lock (for the time that something is dragged) */
  const isDropLocked = isDraggingNow || isInDraggingFolder;

  /* toggleFolderOpen - nothing will happen if is not a folder */
  const toggleFolderOpen = useCallback(() => {
    if (isOpen) {
      closeFolder({ uuid });
    } else {
      openFolder({ uuid });
    }
  }, [isOpen, closeFolder, openFolder, uuid]);

  if (!foundUuid) {
    return undefined;
  }

  return {
    /* def#FlatTreeRowParameters */
    uuid: foundUuid,
    treeName,
    isFolder,
    isOpen,
    isSpecial,
    isVisible,
    isActive,
    isSelected,
    isHighlighted,
    highlightColor,
    path,
    level,
    hasActiveInside,
    hasSelectedInside,
    openFolder,
    closeFolder,
    setActive,
    toggleFolderOpen,
    canDropBelow,
    canDropInside,
    isDropLocked,
    payload,
    goToRow,
  };
};
