import { STEP_SEPARATOR } from 'global';

const ELEMENT_COUNT_LIMIT = 200_000;

const getStepId = stepState => stepState.id;
const getConnectionId = connectionState => connectionState.id;
const getConnectionToStepId = connectionState => connectionState?.toStepId;
const getConnectionFromStepId = connectionState => connectionState.fromStepId;
const getEmbeddedStepId = stepState => stepState.embeddedFirstStepId;

// in the player, we may have stepId as chain of actual stepIds (like: '123,456'). This is separator used there.

const mergeIds = (id, embeddingPath = '') => (embeddingPath ? `${embeddingPath}${STEP_SEPARATOR}${id}` : String(id));

/**
 * for path "a,b,c" returns ["a", "a,b", "a,b,c"]
 */
const generatePathChainList = (path = '') => {
  const pathStepIdList = String(path).split(STEP_SEPARATOR);

  return pathStepIdList
    .reduce((acc, currPathPart, idx) => {
      return [...acc, idx === 0 ? currPathPart : mergeIds(currPathPart, acc[idx - 1])];
    }, [])
    .reverse(/* we need from longest to shortest */);
};

export const parseStepIdNumber = (stepIdString = '') => String(stepIdString).split(STEP_SEPARATOR).map(Number).pop();

const readPathFromMergedIds = (idString = '') => {
  const splitted = String(idString).split(STEP_SEPARATOR);

  if (splitted.length > 1) {
    splitted.pop();

    return splitted.join(STEP_SEPARATOR);
  }
  return undefined;
};

const getIsStepTypeEmbeddedGuide = stepState => (stepState || {}).type === 'guide';

const trimLeftToFirstOccurence = (id, path = '') => {
  const splitted = path.split(STEP_SEPARATOR);
  const foundIndex = splitted.indexOf(String(id));
  if (foundIndex !== -1) {
    return splitted.slice(0, foundIndex).join(STEP_SEPARATOR);
  }
  return path;
};

export function convertPlayerGuidesDataToGuideTreeStructure({ stepList, connectionList, firstStepId }) {
  const metadata = {
    stepById: {
      /* type, id, title */
    },
    connectionById: {},
    connectionIdListFromStepId: {},
    embeddingTargetStepIdStepId: {},
  };

  const result = {
    connectionList: [],
    stepList: [],
    firstStepId,
  };

  // collect stepById
  stepList.forEach(stepDef => {
    metadata.stepById[getStepId(stepDef)] = stepDef;
  });

  // collect connectionById
  connectionList.forEach(connectionDef => {
    metadata.connectionById[getConnectionId(connectionDef)] = connectionDef;
  });

  // collect connectionIdListFromStepId
  connectionList.forEach(connectionDef => {
    const fromStepId = getConnectionFromStepId(connectionDef);

    if (Array.isArray(metadata.connectionIdListFromStepId[fromStepId])) {
      metadata.connectionIdListFromStepId[fromStepId].push(getConnectionId(connectionDef));
    } else {
      metadata.connectionIdListFromStepId[fromStepId] = [getConnectionId(connectionDef)];
    }
  });

  const exitingConnectionDefForEmbeddingPath = {
    /* path: connectionDef */
  };
  const analyzedPath = {
    /* path: boolean */
  };

  const analyzedBridgeTargetByToken = {
    // if we have brdige to specific step with back step defined, we want to reuse it if possible
    // to optimize tree data. Here we will store the target paths.
    /* [targetStepInNoReturnableEmbeddedGuide]: fullPathThatWasUsed */
  };

  /**
   * Takes step children and update result.stepList & result.connectionList object with proper data.
   * This is recursive function
   * @param stepDef - definition of step
   * @param bridgeStepIdPath - string - chain of embedding stepIds like '123,234,...'. (it's like "embedding context")
   *                                    this is necessary for building url in the player :-/
   */
  function collectStepChildren(stepDef, { bridgeStepIdPath: bridgeStepIdPathRaw } = {}) {
    if (!stepDef) {
      console.log('STON:ERROR no step def', { stepDef, bridgeStepIdPathRaw });
      return;
    }

    if (result.connectionList.length + result.stepList.length > ELEMENT_COUNT_LIMIT) {
      console.log(`STON:WARNING - nodes count limit ${ELEMENT_COUNT_LIMIT} elements reached`);
      return;
    }

    const bridgeStepIdPath = bridgeStepIdPathRaw ? String(bridgeStepIdPathRaw) : '';
    const stepId = getStepId(stepDef); // can be path-like, merged with bridge step like `123/567`
    const stepIdNumber = parseStepIdNumber(stepId); // allways number from the end of the path
    const stepFullPath = mergeIds(stepIdNumber, bridgeStepIdPath);

    if (analyzedPath[stepFullPath]) {
      return;
    }
    analyzedPath[stepFullPath] = true;

    const connectionFromStepList = (metadata.connectionIdListFromStepId[stepIdNumber] || []).map(
      connectionId => metadata.connectionById[connectionId]
    );

    if (!connectionFromStepList.length) {
      // This is "exit-from-embedding-context": the last-step-in-the-guide.
      // 1) Put "exit-from-embedded-guide" connection if relevant.
      const embeddingPathChainList = generatePathChainList(bridgeStepIdPath);
      const pathToExitTo = embeddingPathChainList.find(path => exitingConnectionDefForEmbeddingPath[path]);

      if (pathToExitTo) {
        const exitingConnectionDefForPath = exitingConnectionDefForEmbeddingPath[pathToExitTo];
        const prefixedExitingConnectionId = mergeIds(getConnectionId(exitingConnectionDefForPath), stepFullPath);
        const exitingConnectionDef = {
          ...exitingConnectionDefForPath,
          id: prefixedExitingConnectionId,
          fromStepId: stepFullPath,
        };
        result.connectionList.push(exitingConnectionDef);

        // 2) Collect exiting target step children. The exiting step itself is added to the result in "embedding-context"
        const exitingStepTargetId = getConnectionToStepId(exitingConnectionDef);
        const exitingStepTargetIdNumber = parseStepIdNumber(exitingStepTargetId);

        if (exitingStepTargetIdNumber > 0) {
          const exitingStepTargetDef = metadata.stepById[exitingStepTargetIdNumber];
          collectStepChildren(exitingStepTargetDef, { bridgeStepIdPath: readPathFromMergedIds(pathToExitTo) });
        }
      }
    }

    connectionFromStepList.forEach(connectionDef => {
      const childStepId = getConnectionToStepId(connectionDef);
      const childStepIdNumber = parseStepIdNumber(childStepId);
      const childStepDef = metadata.stepById[childStepIdNumber];
      const isChildStepBridge = getIsStepTypeEmbeddedGuide(childStepDef);
      const prefixedConnectionToChildId = mergeIds(getConnectionId(connectionDef), bridgeStepIdPath);

      if (childStepIdNumber < 0) {
        // Child is special step - just put clone of connection with modified "fromStepId"
        const prefixedConnectionDefToSpecialStep = {
          ...connectionDef,
          id: prefixedConnectionToChildId,
          fromStepId: stepFullPath,
        };
        result.connectionList.push(prefixedConnectionDefToSpecialStep);
      } else if (!childStepDef) {
        // Child is not found - do nothing. Probably connection pointing to nowhere (empty toStepId)
        console.log('STON: connection to not existing step detected', { stepId, stepIdNumber, childStepId });
      } else if (!isChildStepBridge) {
        // Child is not a bridge (is every other type of step):
        const prefixedChildStepId = mergeIds(childStepIdNumber, bridgeStepIdPath);

        // 1) add analyzed connection to this child step to the results
        const prefixedConnectionToChildDef = {
          ...connectionDef,
          id: prefixedConnectionToChildId,
          fromStepId: stepFullPath,
          toStepId: prefixedChildStepId,
        };
        result.connectionList.push(prefixedConnectionToChildDef);
        // 2) add clone of this step to results with eventually prefixed ids
        const prefixedChildStepDef = {
          ...childStepDef,
          id: prefixedChildStepId,
        };
        result.stepList.push(prefixedChildStepDef);
        // analyze further children of the child (keep bridge in the path until "exiting embedd step" reached)
        collectStepChildren(prefixedChildStepDef, { bridgeStepIdPath });
      } else if (isChildStepBridge) {
        // Child step is a bridge
        const bridgeTargetStepId = getEmbeddedStepId(childStepDef);
        const bridgeTargetStepIdNumber = parseStepIdNumber(bridgeTargetStepId);
        const bridgeTargetStepDef = metadata.stepById[bridgeTargetStepIdNumber];

        //  WE DON'T COVER CASE WHERE we have bridge pointing directly to another bridge yet
        if (getIsStepTypeEmbeddedGuide(bridgeTargetStepDef)) {
          return;
        }

        const shortestPathToChildStepIdInBridgePath = trimLeftToFirstOccurence(childStepId, bridgeStepIdPath);
        const wasChildStepIdAlreadyInPath = String(shortestPathToChildStepIdInBridgePath) !== String(bridgeStepIdPath);
        const embeddingPath = mergeIds(childStepIdNumber, shortestPathToChildStepIdInBridgePath);
        const prefixedBridgeTargetStepId = mergeIds(bridgeTargetStepIdNumber, embeddingPath);

        const bridgeExitingConnectionDef = (metadata.connectionIdListFromStepId[childStepIdNumber] || []).map(
          connectionId => metadata.connectionById[connectionId]
        )[0]; // there can by only one exiting-embedded-guide-step, because embedding-guide-step

        const bridgeExitingStepId = getConnectionToStepId(bridgeExitingConnectionDef); // may be undefined
        const analyzedBridgeTargetToken = `${bridgeExitingStepId}|${bridgeTargetStepId}`;

        if (analyzedBridgeTargetByToken[analyzedBridgeTargetToken]) {
          // #IF
          // we have a brdige to step+exit that was already used.
          // So we just create a connection to that step+exit and that's it in terms of analyzing this child
          result.connectionList.push({
            ...connectionDef,
            id: prefixedConnectionToChildId,
            fromStepId: stepFullPath,
            toStepId: analyzedBridgeTargetByToken[analyzedBridgeTargetToken],
          });

          return;
        }
        // #ELSE
        // we have bridge to step+exit for the first time.
        analyzedBridgeTargetByToken[analyzedBridgeTargetToken] = prefixedBridgeTargetStepId;

        // 1) create a connection from stepId -> omit bridge step -> bridge target step
        result.connectionList.push({
          ...connectionDef,
          id: prefixedConnectionToChildId,
          fromStepId: stepFullPath,
          toStepId: prefixedBridgeTargetStepId,
        });

        // If it would be self referenced, then it means that we created connection to existing step,
        // so no further analysis needed. Otherwise:
        if (!wasChildStepIdAlreadyInPath) {
          // 2) add clone of bridgeTarget step with eventually prefixed ids
          const prefixedBridgeTargetStepDef = {
            ...bridgeTargetStepDef,
            id: prefixedBridgeTargetStepId,
          };
          result.stepList.push(prefixedBridgeTargetStepDef);

          // 3) if the bridgeStep has connection to an exiting stepId (it can be special step; for now it can by only one)
          let prefixedBridgeExitingStepDefId;
          if (bridgeExitingConnectionDef) {
            const bridgeExitingStepIdNumber = parseStepIdNumber(bridgeExitingStepId);
            prefixedBridgeExitingStepDefId = mergeIds(bridgeExitingStepIdNumber, bridgeStepIdPath);

            // 3.1) store exiting connection for this embedding path
            exitingConnectionDefForEmbeddingPath[embeddingPath] = {
              ...bridgeExitingConnectionDef,
              toStepId: prefixedBridgeExitingStepDefId,
              // don't care about `id` & `fromStepId` because those will be handled in "exiting embedded context"
            };
            // 3.2) then if it's not a special step, do prefixed clone of this step. Don't collect it's child yet. It will be done in "exiting embedded context"
            if (bridgeExitingStepIdNumber > 0) {
              const bridgeExitingStepDef = metadata.stepById[bridgeExitingStepIdNumber];
              const prefixedBridgeExitingStepDef = {
                ...bridgeExitingStepDef,
                id: mergeIds(bridgeExitingStepIdNumber, bridgeStepIdPath),
              };

              result.stepList.push(prefixedBridgeExitingStepDef);
            }
          }

          collectStepChildren(prefixedBridgeTargetStepDef, {
            bridgeStepIdPath: embeddingPath,
          });
        }
      }
    });
  }

  const firstStepDef = metadata.stepById[firstStepId];

  if (firstStepDef) {
    result.stepList.push({ ...firstStepDef });
    collectStepChildren(firstStepDef);
  }

  return result;
}
