import React, { useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

const stepInputsContext = React.createContext({});

export const StepInputsProvider = ({ children }) => {
  const [inputValues, setInputValues] = useState({});
  const [isInputValuesValid, setIsInputValuesValid] = useState(true);
  const [dirtyInputs, setDirtyInputs] = useState({});

  return (
    <stepInputsContext.Provider
      value={useMemo(
        () => ({
          inputValues,
          setInputValues,
          isInputValuesValid,
          setIsInputValuesValid,
          dirtyInputs,
          setDirtyInputs,
        }),
        [inputValues, isInputValuesValid, dirtyInputs]
      )}
    >
      {children}
    </stepInputsContext.Provider>
  );
};

StepInputsProvider.propTypes = {
  children: PropTypes.node,
};

export function useStepInputsState() {
  const stepInputValues = useContext(stepInputsContext);
  if (!stepInputValues) {
    throw new Error('Component with useStepInputsState() needs to be somewhere inside <StepInputsProvider>');
  }
  return stepInputValues;
}
