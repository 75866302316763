import React, { Component } from 'react';
import styled from 'styled-components';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import i18n from 'helpers/i18n';
import withUser from '@playerCommon/HOC/withUser';
import { PERMISSION } from 'global';
import withPermissions from '@playerCommon/HOC/withPermissions';
import NoViewAccess from '@playerCommon/Commons/NoViewAccess';
import NoAccess from '@playerCommon/CustomElements/NoAccess';
import withContextProviders from 'HOC/withContextProviders';
import SegmentInjector from '@playerCommon/CustomElements/SegmentInjector';
import withRequestData from 'HOC/withRequestData';
import { postMessage } from 'helpers/widgetHelpers.js';
import { trackSegment } from '@stonlyCommons/components/Analytics/recorder';
import { localStorageSupported } from 'helpers/storage.js';
import DropdownInput from '@playerCommon/StandardElements/DropdownInput/DropdownInput.jsx';
import Explanations from './Explanations/Explanations.js';
import UserHistory from './UserHistory/UserHistory.js';
import { agentStorage } from './helpers.js';

const Canvas = styled.div``;

class AgentApp extends Component {
  canvas = React.createRef();

  isSSR = typeof window === 'undefined';

  componentDidMount() {
    // this will make it fire only once in the life of a browser (Jean's request)
    if (localStorageSupported && localStorage.getItem('agentAppTrackFired')) return;

    const { userManagement } = this.props;
    setTimeout(() => {
      if (userManagement.user) {
        trackSegment({
          userId: userManagement.user.logUserId,
          url: document.location.href,
          referrer: document.referrer,
          type: 'identify',
          params: {
            name: `${userManagement.user.firstName} ${userManagement.user.lastName}`,
            email: userManagement.user.email,
          },
          event: 'agent.agent-app-view',
        });
      }
      trackSegment({
        url: document.location.href,
        referrer: document.referrer,
        type: 'track',
        params: {},
        event: 'agent.agent-app-view',
      });
      if (localStorageSupported) localStorage.setItem('agentAppTrackFired', true);
    }, 50);
  }

  updateParentContainer = () => {
    const { match } = this.props;
    if (this.canvas.current) {
      postMessage({ status: 'updateHeight', height: this.canvas.current.offsetHeight, context: match.params.mode });
    }
  };

  resolveTeamName = () => {
    const {
      match: { params },
      userManagement: { user },
    } = this.props;

    let teamName = '';

    if (params.teamName && params.teamName !== 'null') {
      // first - get from url
      teamName = params.teamName;
    } else if (
      // second - if only one team - get it
      user.teams.length === 1
    ) {
      teamName = user.teams[0].name;
    } else {
      // third - get team from the storage
      const storedTeamId = agentStorage.readTeam({ userId: user.id });
      if (storedTeamId) {
        teamName = (user.teams.find(team => team.teamId === storedTeamId) || {}).name;
      }
    }

    console.log(`STON: using team:"${teamName}"`);

    return teamName;
  };

  handleTeamChange = event => {
    const teamId = event.target.value;
    if (teamId) {
      const { userManagement } = this.props;
      agentStorage.saveTeam({ userId: userManagement.user.id, teamId });
      window.location.reload();
    }
  };

  render() {
    const { userManagement, getPermission, upgradePlan } = this.props;

    if (!userManagement.user.id) {
      return <NoViewAccess />;
    }

    const canUseAgentApp = getPermission(PERMISSION.CAN_USE_AGENTAPP);
    if (!canUseAgentApp) {
      return (
        <NoAccess
          title={i18n('AgentApp.NoAccessTitle')}
          subtitle={i18n('AgentApp.NoAccessText')}
          onButtonClick={() => upgradePlan(i18n('Paywall.AgentApp'), 'agentApp')}
        />
      );
    }

    const teamName = this.resolveTeamName();

    if (!teamName && !this.isSSR) {
      const teamOptionList = userManagement.user.teams.map(team => ({
        id: team.teamId,
        value: team.teamId,
        label: team.name,
      }));

      return (
        <Canvas>
          <p>{i18n('AgentApp.NoTeamTitle')}</p>
          <p>
            <DropdownInput options={teamOptionList} onChange={this.handleTeamChange} />
          </p>
        </Canvas>
      );
    }

    return (
      <>
        <SegmentInjector />
        <Canvas ref={this.canvas}>
          <Switch>
            <Route
              path="/:mode(agentApp|agentAppFront|agentAppZendeskModal|agentAppZendeskPanel|agentAppFresh)/:teamNameLegacy/(guides|explanations)/:sessionsId?/:ticketId?/:referrerUrl?/:folderId?/:id?/:pathToLoad?"
              render={props => (
                <Explanations
                  userManagement={userManagement}
                  updateParentContainer={this.updateParentContainer}
                  teamName={teamName}
                  {...props}
                />
              )}
            />
            <Route
              path="/:mode(agentApp|agentAppFront|agentAppZendeskModal|agentAppZendeskPanel|agentAppFresh)/:teamNameLegacy/userHistory/:sessionsId?/:ticketId?/:referrerUrl?/:folderId?/:id?/:pathToLoad?"
              render={props => (
                <UserHistory
                  userManagement={userManagement}
                  updateParentContainer={this.updateParentContainer}
                  {...props}
                />
              )}
            />
          </Switch>
        </Canvas>
      </>
    );
  }
}

AgentApp.propTypes = {
  userManagement: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  getPermission: PropTypes.func,
  upgradePlan: PropTypes.func,
};

export default withRequestData(withContextProviders(withUser(withPermissions(AgentApp)), { disableAnalytics: true }));
