import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { appUrl } from 'global/env';
import { setCookie } from 'global/windowUtils.js';
import callApi from 'helpers/apiHelpers.js';
import { useTranslation } from 'react-i18next';

import useAuthenticationInitiateRedirect from '../useAuthenticationInitiateRedirect.js';
import { Canvas, Top, Title, ErrorText } from '../Authentication.styles.js';

const AcceptInvitation = ({ token }) => {
  const { t } = useTranslation();
  const [tokenInvalid, setTokenInvalid] = useState(false);
  const [verificationIsPending, setVerificationIsPending] = useState(true);
  const { redirectCountdown, initiateRedirect } = useAuthenticationInitiateRedirect();

  useEffect(() => {
    callApi(`v1/team/members/fromInvite`, 'get', { invitationId: token })
      .then(res => {
        setCookie('registration_without_introGuide', 'true');
        setTokenInvalid(false);
        setVerificationIsPending(false);
        initiateRedirect({
          timeoutCallback: () => (window.location.href = `${appUrl}/app/general/${res.data.teamId}/guides/`),
        });
      })
      .catch(e => {
        setTokenInvalid(true);
        setVerificationIsPending(false);
      });
  }, []);

  return (
    <Canvas>
      {!tokenInvalid && !verificationIsPending && (
        <>
          <Top>
            <Title>{t('SignIn.ConfirmInvitationTokenVerifiedTitle')}</Title>
          </Top>
          <ErrorText>{t('SignIn.ConfirmInvitationTokenVerifiedDescription', { sec: redirectCountdown })}</ErrorText>
        </>
      )}
      {tokenInvalid && !verificationIsPending && (
        <>
          <Top>
            <Title>{t('SignIn.ConfirmInvitationTokenInvalidTitle')}</Title>
          </Top>
          <ErrorText>{t('SignIn.ConfirmInvitationTokenInvalidDescription')}</ErrorText>
        </>
      )}
    </Canvas>
  );
};

AcceptInvitation.propTypes = {
  token: PropTypes.string,
};

export default AcceptInvitation;
