import { SALESFORCE_TARGET_PREFIX } from './salesforce';

export const COMMUNICATION_TARGET_PREFIX = {
  ZAPIER: 'Zapier - ',
  SALESFORCE: SALESFORCE_TARGET_PREFIX,
} as const;

const COMMUNICATION_TYPES = {
  CHOICE_LABEL: 'choiceLabel',
  CUSTOM_TEXT: 'customText',
  CHOICE_STATIC_VALUE: 'choiceStaticValue',
  STEP_TITLE: 'stepTitle',
  INPUT_VALUE: 'inputValue',
  ALL_INPUTS_VALUES: 'allInputsValues',
  ALL_GUIDE_DATA: 'allGuideData',
} as const;

type CommunicationTypeKeys = keyof typeof COMMUNICATION_TYPES;
type CommunicationType = (typeof COMMUNICATION_TYPES)[CommunicationTypeKeys];

export interface Communication {
  type: CommunicationType;
  target: string;
  value?: string;
}
