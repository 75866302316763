import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

import ScrollForMoreChevron from 'icons/scrollForMoreChevron.svg';
import { motion } from 'framer-motion';

const Canvas = styled(motion.div)`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const bounceAnimation = keyframes`
  0% {
    transform: translateY(0%);
  }
  10% {
    transform: translateY(30%);
  }
  30% {
    transform: translateY(-70%);
  }
  50% {
    transform: translateY(50%);
  }
  60% {
    transform: translateY(-10%);
  }
  70% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(0%);
  }
`;

export const ScrollChevron = styled(ScrollForMoreChevron)`
  animation: ${bounceAnimation} 4s 2 cubic-bezier(0.28, 0.84, 0.42, 1);
  path {
    fill: ${props => props.theme.highlightColor};
  }
`;

const ScrollDownIndicator = ({ className, initial, animate, exit, transition }) => (
  <Canvas className={className} initial={initial} animate={animate} exit={exit} transition={transition}>
    <ScrollChevron />
  </Canvas>
);

ScrollDownIndicator.propTypes = {
  className: PropTypes.string,
  initial: PropTypes.object,
  animate: PropTypes.object,
  exit: PropTypes.object,
  transition: PropTypes.object,
};

export default ScrollDownIndicator;
