import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import ActionList from '@playerCommon/CustomElements/ActionList';
import Dropdown from '@playerCommon/CustomElements/Dropdown';
import Icon from '@playerCommon/StandardElements/Icon';

const IconDropdown = ({
  options,
  globalAction,
  size,
  color,
  colorHover,
  src,
  className,
  tooltip,
  positionTooltip,
  dropdownPlacement = 'bottom-start',
  dropdownOffsetValue = 8,
  selectedOption,
  width = 200,
  dataCy,
}) => {
  const dropdownRef = useRef();
  const closeDropdown = () => {
    dropdownRef.current.closeDropdown();
  };
  return (
    <Dropdown
      data-cy={dataCy}
      className={className}
      ref={dropdownRef}
      placement={dropdownPlacement}
      width={width}
      offsetValue={dropdownOffsetValue}
      trigger={
        <Icon
          data-cy={dataCy ? `${dataCy}-icon` : undefined}
          color={color}
          colorHover={colorHover}
          size={size}
          src={typeof src === 'string' ? src : undefined}
          iconNode={typeof src === 'function' ? src : undefined}
          tooltip={tooltip}
          positionTooltip={positionTooltip}
        />
      }
    >
      <ActionList
        data-cy={dataCy ? `${dataCy}-dropdown` : undefined}
        options={options}
        onPostSelect={closeDropdown}
        selectedOption={selectedOption}
        globalAction={globalAction}
      />
    </Dropdown>
  );
};

IconDropdown.propTypes = {
  toggleElementToDisplay: PropTypes.func,
  elementToDisplay: PropTypes.object,
  options: PropTypes.array,
  globalAction: PropTypes.func,
  size: PropTypes.number,
  color: PropTypes.string,
  colorHover: PropTypes.string,
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  className: PropTypes.string,
  tooltip: PropTypes.string,
  positionTooltip: PropTypes.string,
  selectedOption: PropTypes.string,
  dropdownPlacement: PropTypes.string,
  dropdownOffsetValue: PropTypes.number,
  width: PropTypes.number,
  dataCy: PropTypes.string,
};

export default IconDropdown;
