import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import StartoverSVG from 'icons/startover.svg';
import useTooltip from '@playerCommon/hooks/useTooltip';
import { useTranslation } from 'react-i18next';

const StyledButton = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 54px;
  bottom: 6px;
  width: 24px;
  height: 36px;
  border: 0;
  background: transparent;
  cursor: pointer;
  transition: opacity 0.2s;

  svg path {
    stroke: ${props => props.theme.steel};
  }

  &:hover {
    opacity: 0.8;
  }

  svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }
`;

const AiAnswerResetButton = ({ onResetSession }) => {
  const { t } = useTranslation();

  const { tooltipElement, setElementToStickToRef, setTooltipVisible } = useTooltip({
    content: t('AiStep.ResetConversationTooltip'),
    className: 'reset-conversation-tooltip',
    placement: 'top-end',
  });
  const onMouseEnter = useCallback(() => setTooltipVisible(true), [setTooltipVisible]);
  const onMouseLeave = useCallback(() => setTooltipVisible(false), [setTooltipVisible]);

  return (
    <>
      <StyledButton
        ref={setElementToStickToRef}
        onClick={onResetSession}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className="reset-conversation-button"
        aria-label={t('AiStep.ResetConversationTooltip')}
      >
        <StartoverSVG aria-hidden />
      </StyledButton>
      {tooltipElement}
    </>
  );
};

AiAnswerResetButton.propTypes = {
  onResetSession: PropTypes.func,
};

export default AiAnswerResetButton;
