import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SecondaryIconButton } from '@playerCommon/StandardElements/Buttons/SecondaryButton';
import { copyAiAnswerToClipboard } from '@playerCommon/helpers/aiAnswerHelpers';
import { FEEDBACK_TYPE } from '../useSmartSearchResults';
import { SmartSearchResultToolbar, ThumbsUpIcon, CopiedIcon, CopyIcon } from './AiAnswerResultActions.styles';

export const AiAnswerCopyButton = ({ textToCopy, isCopyEntire }) => {
  const { t } = useTranslation();
  const [isAnswerCopied, setIsAnswerCopied] = useState(false);

  const copyAiAnswer = content => {
    setIsAnswerCopied(true);
    copyAiAnswerToClipboard(content);
  };

  return isAnswerCopied ? (
    <SecondaryIconButton
      className="ai-answer-copied"
      tooltip={isCopyEntire ? t('AiStep.AiAnswerCopied') : t('AiStep.AiAnswerMessageCopied')}
      onMouseLeave={() => setIsAnswerCopied(false)}
      aria-label={isCopyEntire ? t('AiStep.AiAnswerCopied') : t('AiStep.AiAnswerMessageCopied')}
      aria-pressed
    >
      <CopiedIcon aria-hidden />
    </SecondaryIconButton>
  ) : (
    <SecondaryIconButton
      className="copy-ai-answer"
      onClick={() => copyAiAnswer(textToCopy)}
      tooltip={isCopyEntire ? t('AiStep.CopyEntireAiAnswer') : t('AiStep.CopyAiAnswer')}
      aria-label={isCopyEntire ? t('AiStep.CopyEntireAiAnswer') : t('AiStep.CopyAiAnswer')}
      aria-pressed={false}
    >
      <CopyIcon aria-hidden />
    </SecondaryIconButton>
  );
};

const AiAnswerResultActions = ({ answerText, answerVote, isLast, onFeedbackClick }) => {
  const { t } = useTranslation();

  const onFeedbackClickProxy = feedbackType => {
    onFeedbackClick(feedbackType);
  };

  return (
    <SmartSearchResultToolbar feedbackValue={answerVote} feedbackHidden={!isLast}>
      <SecondaryIconButton
        onClick={answerVote === undefined ? () => onFeedbackClickProxy(FEEDBACK_TYPE.POSITIVE) : undefined}
        disabled={answerVote === FEEDBACK_TYPE.NEGATIVE}
        selected={answerVote === FEEDBACK_TYPE.POSITIVE}
        tooltip={t('AiStep.FeedbackHelpful')}
        className="feedback feedback-positive"
        aria-label={t('AiStep.FeedbackHelpful')}
        aria-pressed={answerVote === FEEDBACK_TYPE.POSITIVE}
      >
        <ThumbsUpIcon aria-hidden />
      </SecondaryIconButton>
      <SecondaryIconButton
        onClick={answerVote === undefined ? () => onFeedbackClickProxy(FEEDBACK_TYPE.NEGATIVE) : undefined}
        disabled={answerVote === FEEDBACK_TYPE.POSITIVE}
        selected={answerVote === FEEDBACK_TYPE.NEGATIVE}
        tooltip={t('AiStep.FeedbackNotHelpful')}
        className="feedback feedback-negative"
        aria-label={t('AiStep.FeedbackNotHelpful')}
        aria-pressed={answerVote === FEEDBACK_TYPE.NEGATIVE}
      >
        <ThumbsUpIcon reversed aria-hidden />
      </SecondaryIconButton>
      <AiAnswerCopyButton textToCopy={answerText} isCopyEntire />
    </SmartSearchResultToolbar>
  );
};

export default AiAnswerResultActions;
