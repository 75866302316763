import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';
import styled, { ThemeProvider, css, withTheme } from 'styled-components';
import queryString from 'query-string';
import i18n from 'helpers/i18n';
import NoAccess from '@playerCommon/CustomElements/NoAccess';
import withContextProviders from 'HOC/withContextProviders';
import withUser from '@playerCommon/HOC/withUser';
import Loader from '@playerCommon/CustomElements/Loader';
import Button from '@playerCommon/StandardElements/Button';
import useAuthenticationFlow from '@playerCommon/Authentication/useAuthenticationFlow';
import { setCookie } from 'global/windowUtils';
import { validateHexaDecimal } from 'helpers/validationHelpers';
import useUserManagement from '@playerCommon/hooks/useUserManagement';

const StyledLoader = styled(Loader)`
  color: ${props => props.theme.darkGrey};

  svg {
    margin-bottom: 24px;
  }
`;

const SSOSignInWrap = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 16px;
  flex-direction: column;
  color: ${props => props.theme.darkGrey};
`;

const SSOTextTitle = styled.div`
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
`;

const SSOTextSubtitle = styled.div`
  position: relative;
  font-size: 14px;
  max-width: 280px;
  text-align: center;
  line-height: 24px;
  font-weight: 400;
  margin-top: 8px;
`;

const SSOErrorText = styled.p`
  position: absolute;
  margin: 0;
  bottom: -64px;
  left: 50%;
  width: 448px;
  padding: 0px 16px;
  max-width: 100vw;
  transform: translate3d(-50%, 90%, 0);
  font-size: 12px;
  line-height: 20px;
  color: ${props => props.theme.coral};
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, transform 0.2s;

  ${({ open }) =>
    open &&
    css`
      opacity: 1;
      visibility: visible;
      transform: translate3d(-50%, 100%, 0);
    `}
`;
const SSOErrorTextTryAgain = styled.strong`
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 48px;
`;

function Page403({ userManagement, ssoURL, location, theme, isWidget = false }) {
  const [loginPanelTriggered, setLoginPanelTriggered] = useState(false);
  const [timedOut, setTimedOut] = useState(false);
  const [ssoTriggered, setSsoTriggered] = useState(!isWidget && ssoURL);
  const { onOpenSignIn } = useAuthenticationFlow();
  const { user } = useUserManagement();
  const isConnected = Object.keys(user).length !== 0;

  function triggerSSOLogin() {
    if (isWidget) {
      window.addEventListener('message', e => {
        if (e.data === 'sso_success_fire_reload') {
          window.location.reload();
        }
      });
      window.open(ssoURL, '_blank');
    } else {
      setCookie('returnUrl', encodeURIComponent(window.location.href));
      window.location.href = ssoURL;
    }
  }

  function loginUser() {
    if (ssoURL) {
      setSsoTriggered(true);
      setTimeout(() => setTimedOut(true), 3000);
      triggerSSOLogin();
    } else {
      onOpenSignIn({ returnUrl: window.location.href });
      setLoginPanelTriggered(true);
    }
  }

  const loginUserText = ssoURL ? i18n('SSO.SignIn') : i18n('Global.SignIn');

  useEffect(() => {
    if (!isWidget && ssoURL) {
      loginUser();
    }
    if (isWidget && ssoURL) {
      window.addEventListener('storage', () => {
        if (window.localStorage.getItem('ssoLoginDone')) {
          window.localStorage.removeItem('ssoLoginDone');
          window.location.reload();
        }
      });
    }
  }, []);

  useEffect(() => {
    if (loginPanelTriggered && Object.keys(userManagement.user).length > 0) {
      window.location.reload();
    }
  }, [loginPanelTriggered, userManagement]);

  const urlParams = queryString.parse(location.search);
  const themeModified = { ...theme };

  if (urlParams.accent && validateHexaDecimal(urlParams.accent)) {
    themeModified.highlightColor = urlParams.accent;
  }

  return (
    <ThemeProvider theme={themeModified}>
      <Route
        render={({ staticContext }) => {
          if (staticContext) staticContext.statusCode = 403;
          return (
            <>
              {ssoURL ? (
                <SSOSignInWrap>
                  {ssoTriggered ? (
                    <>
                      <StyledLoader monochrome type="circle" />
                      <SSOTextTitle>{i18n('SSO.LoginInProgress')}</SSOTextTitle>
                      <SSOTextSubtitle>
                        {i18n('SSO.LoginInProgressSubtitle')}
                        <SSOErrorText open={isWidget && timedOut}>
                          {i18n('SSO.LoginPopupTimeoutError')}
                          <SSOErrorTextTryAgain onClick={triggerSSOLogin}>
                            {i18n('SSO.LoginPopupTimeoutErrorTryAgain')}
                          </SSOErrorTextTryAgain>
                        </SSOErrorText>
                      </SSOTextSubtitle>
                    </>
                  ) : (
                    <>
                      <SSOTextTitle>{i18n('SSO.LoginInSSOTitle')}</SSOTextTitle>
                      <SSOTextSubtitle>
                        {isConnected ? i18n('RestrictedAccess.NoAccess') : i18n('SSO.LoginInSSOSubtitle')}
                      </SSOTextSubtitle>
                      {!isConnected && (
                        <StyledButton onClick={loginUser} content={i18n('SSO.SignIn')} dataCy="signInSSO" />
                      )}
                    </>
                  )}
                </SSOSignInWrap>
              ) : (
                <NoAccess
                  title={i18n('KnowledgeBase.KnowledgeBaseNoAccess403')}
                  subtitle={
                    isConnected ? i18n('RestrictedAccess.NoAccess') : i18n('KnowledgeBase.KnowledgeBaseNoAccessThisSub')
                  }
                  onButtonClick={isConnected ? undefined : loginUser}
                  buttonText={loginUserText}
                />
              )}
            </>
          );
        }}
      />
    </ThemeProvider>
  );
}

Page403.propTypes = {
  userManagement: PropTypes.object,
  ssoURL: PropTypes.string,
  isWidget: PropTypes.bool,
  location: PropTypes.object,
  theme: PropTypes.object,
};

export default withContextProviders(withUser(withTheme(withRouter(Page403))));
