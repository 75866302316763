import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import useDidUpdate from '@playerCommon/hooks/useDidUpdate';
import { setSessionStorage, getSessionStorage } from 'helpers/storage';

const ServerCallDataContext = React.createContext();

function ServerCallDataProvider({ serverCallVariables: previewServerCallVariables, children }) {
  const [serverCallDataValue, setServerCallDataValue] = useState({
    serverCallStatuses: {},
    serverCallVariables: previewServerCallVariables || {},
  });

  const setServerCallData = useCallback(
    ({ statuses, variables } = {}) => {
      const data = {
        serverCallStatuses: {
          ...serverCallDataValue.serverCallStatuses,
          ...statuses,
        },
        serverCallVariables: {
          ...serverCallDataValue.serverCallVariables,
          ...variables,
        },
      };

      setServerCallDataValue(data);
      setSessionStorage('serverCallData', data);
    },
    [serverCallDataValue]
  );

  const clearServerCallData = useCallback(() => {
    setServerCallDataValue({
      serverCallStatuses: {},
      serverCallVariables: {},
    });
  }, []);

  useDidUpdate(() => {
    if (previewServerCallVariables) {
      setServerCallDataValue({
        serverCallVariables: previewServerCallVariables,
      });
    }
  }, [previewServerCallVariables]);

  useEffect(() => {
    const fromSessionStorage = getSessionStorage('serverCallData');
    if (fromSessionStorage) {
      setServerCallData({
        statuses: fromSessionStorage.serverCallStatuses,
        variables: fromSessionStorage.serverCallVariables,
      });
    }
  }, []);

  const contextValue = useMemo(
    () => ({
      setServerCallData,
      clearServerCallData,
      serverCallStatuses: serverCallDataValue.serverCallStatuses,
      serverCallVariables: serverCallDataValue.serverCallVariables,
    }),
    [setServerCallData, serverCallDataValue]
  );

  return <ServerCallDataContext.Provider value={contextValue}>{children}</ServerCallDataContext.Provider>;
}

function useServerCallData() {
  const context = React.useContext(ServerCallDataContext);
  if (context === undefined) {
    throw new Error('useServerCallData must be used within a ServerCallDataProvider');
  }
  return context;
}

ServerCallDataProvider.propTypes = {
  children: PropTypes.object,
};

export { ServerCallDataProvider, useServerCallData };
