import React, { memo, useState, useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import isEqual from 'react-fast-compare';
import PropTypes from 'prop-types';
import { sanitizeURL } from 'helpers/sanitizeHelpers';
import { postMessage } from 'helpers/widgetHelpers';
import { replaceVarNamesWithValues } from '@stonlyCommons/helpers/guideVariableHelpers.js';
import { useSentData } from '@playerCommon/Contexts/sentDataContext';
import { useUserData } from '@playerCommon/Contexts/userDataContext';
import { useServerCallData } from '@playerCommon/Contexts/serverCallDataContext';

const IframeWrap = styled.div`
  height: 100%;
  border: 1px solid ${props => props.theme.grey};
  border-radius: 4px;
  overflow: hidden;

  @media screen and (max-width: 899px) {
    margin: ${({ theme }) => `0 ${theme.stepsContentPaddings.contentPadding}px`};
  }

  ${({ scrollable, mode, shouldShowNextButtonSelector, theme, compact, borderlessEmbed }) =>
    (scrollable || shouldShowNextButtonSelector) &&
    css`
      ${mode === 'widget' &&
      css`
        height: calc(
          100vh - ${compact || borderlessEmbed ? '0px' : theme.widgetHeaderHeight} - ${theme.contentWrapDesktopPadding} -
            ${theme.contentWrapDesktopPadding} - ${compact ? '0px' : theme.footerBigDesktopHeight}
        );
        @media screen and (max-width: 899px) {
          height: calc(
            100vh - ${compact || borderlessEmbed ? '0px' : theme.widgetHeaderHeight} -
              ${theme.contentWrapMobileNoIllustrationPaddingTop} - ${theme.contentWrapDesktopPadding} -
              ${compact ? '0px' : theme.footerSubDesktopHeight}
          );
        }
      `}
      ${['embed', 'borderlessembed'].includes(mode) &&
      css`
        height: calc(
          100vh - ${theme.footerSubDesktopHeight} - ${theme.embedWrapPadding} - ${theme.embedWrapPadding} -
            ${theme.contentWrapDesktopPadding} - ${theme.contentWrapDesktopPadding}
        );
      `}
      ${mode === 'normal' &&
      css`
        height: calc(
          100vh - ${theme.normalHeaderBigDesktopHeight} - ${theme.footerBigDesktopHeight} -
            ${theme.contentWrapDesktopPadding} - ${theme.contentWrapDesktopPadding}
        );
        margin: 0 auto;
        @media screen and (max-width: 899px) {
          height: calc(
            100vh - ${theme.normalHeaderBigDesktopHeight} - ${theme.footerSubDesktopHeight} -
              ${theme.contentWrapMobileNoIllustrationPaddingTop} - ${theme.contentWrapDesktopPadding}
          );
        }
        @media screen and (max-width: 621px) {
          height: calc(
            100vh - ${theme.normalHeaderMobileDesktopHeight} - ${theme.footerMobileHeight} -
              ${theme.contentWrapMobileNoIllustrationPaddingTop} - ${theme.contentWrapDesktopPadding}
          );
        }
      `}
      ${mode === 'hc' &&
      css`
        height: calc(
          100vh - ${theme.hcHeaderBigDesktopHeight} - ${theme.footerBigDesktopHeight} -
            ${theme.contentWrapDesktopPadding} - ${theme.contentWrapDesktopPadding}
        );
        margin: 0 auto;
        @media screen and (max-width: 899px) {
          height: calc(
            100vh - ${theme.hcHeaderSubDesktopHeight} - ${theme.footerSubDesktopHeight} -
              ${theme.contentWrapMobileNoIllustrationPaddingTop} - ${theme.contentWrapDesktopPadding}
          );
        }
      `}
      ${mode === 'hc-widget' &&
      css`
        height: calc(
          100vh - ${theme.hcWidgetHeaderHeight} - ${theme.footerBigDesktopHeight} - ${theme.contentWrapDesktopPadding} -
            ${theme.contentWrapDesktopPadding}
        );
        margin: 0 auto;
        @media screen and (max-width: 899px) {
          height: calc(
            100vh - ${theme.hcWidgetHeaderHeight} - ${theme.footerSubDesktopHeight} -
              ${theme.contentWrapMobileNoIllustrationPaddingTop} - ${theme.contentWrapDesktopPadding}
          );
        }
      `}
    `}
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 4px;
`;

// fix for URLs as our backend replaces & with &amp; :|
const fixURL = url => url.replaceAll(new RegExp('&amp;', 'g'), '&');

// Calendly onboarding stuff handling
const isCalendlyEvent = e => e.data.event && e.data.event.indexOf('calendly') === 0;

const broadcastCalendlyEventWithUrl = url => {
  if (url) {
    const parsedUrl = new URL(url);
    postMessage({
      type: 'calendlyEventScheduled',
      path: parsedUrl.pathname,
    });
  }
};

const IframeEmbed = ({ iframeData, mode, shouldShowNextButtonSelector, scrollable, compact, borderlessEmbed }) => {
  const { iframeUrl: iframeUrlToParse } = iframeData;
  const [isVisible, setIsVisible] = useState(true);
  const { mode: matchMode } = useParams();
  const { localData } = useSentData();
  const userData = useUserData();
  const { serverCallVariables } = useServerCallData();

  const iframeUrl = useMemo(() => {
    if (!iframeUrlToParse) {
      return '';
    }
    const resultUrl = replaceVarNamesWithValues({
      content: iframeUrlToParse,
      userData,
      localData,
      serverCallVariables,
    });
    return sanitizeURL(fixURL(resultUrl));
  }, [iframeUrlToParse, localData, userData, serverCallVariables]);

  // Calendly onboarding stuff handling
  const calendlyMessageHandler = useCallback(
    e => {
      if (isCalendlyEvent(e) && e.data.event === 'calendly.event_scheduled') {
        broadcastCalendlyEventWithUrl(iframeUrl);
      }
    },
    [iframeUrl]
  );

  useEffect(() => {
    window.addEventListener('message', calendlyMessageHandler);
    return () => {
      window.removeEventListener('message', calendlyMessageHandler);
    };
  }, [calendlyMessageHandler]);
  // ENDOF Calendly onboarding stuff handling

  useEffect(() => {
    const onToggleWidgetMessage = message => {
      // Hide iframe when widget is closed and render it again when it's opened
      // This is necessary for the case when iframe contains video that is playing.
      // Due to the fact that when widget is closed, it stays on the page but becomes hidden,
      // the video from the iframe would keep playing even if the widget is closed.
      // This code forces iframe to be removed from the DOM when widget is closed
      // but renders it again (in it's initial state) when widget is opened.
      if (message.data.type === 'widgetClosed') {
        const HIDING_TIMEOUT = 300; // hide iframe when closing transition is done
        setTimeout(() => setIsVisible(false), HIDING_TIMEOUT);
      }

      if (message.data.type === 'widgetOpened') {
        setIsVisible(true);
      }
    };
    window.addEventListener('message', onToggleWidgetMessage);
    return () => window.removeEventListener('message', onToggleWidgetMessage);
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <IframeWrap
      matchMode={matchMode}
      mode={mode}
      shouldShowNextButtonSelector={shouldShowNextButtonSelector}
      scrollable={scrollable}
      compact={compact}
      borderlessEmbed={borderlessEmbed}
    >
      <Iframe
        allow="camera *; microphone *; display-capture *;"
        data-cy="iframeElement"
        frameBorder="0"
        allowFullScreen
        src={iframeUrl}
      />
    </IframeWrap>
  );
};

IframeEmbed.propTypes = {
  iframeData: PropTypes.shape({
    iframeUrl: PropTypes.string,
  }),
  shouldShowNextButtonSelector: PropTypes.bool,
  mode: PropTypes.string,
  scrollable: PropTypes.bool,
  compact: PropTypes.bool,
  borderlessEmbed: PropTypes.bool,
};

export default memo(IframeEmbed, isEqual);
