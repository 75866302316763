import { track as recorderTrack } from 'components/Analytics/recorder';
import { getIds } from 'helpers/statIdsManagement';
import { postMessage } from './widgetHelpers';

const updateSessionId = () => {
  getIds().then(({ sessionId }) => {
    postMessage({
      type: 'params',
      name: 'stonlySessionId',
      value: sessionId,
    });
  });
};

const createOpenIntegrationFunction = integrationName => () => {
  recorderTrack({
    actionType: integrationName,
    actionDetail: {},
  });
  updateSessionId();
  postMessage({ type: integrationName });
};

export const INTEGRATION_NAME = {
  zendeskChat: 'Open Zendesk Chat',
  intercom: 'Open Intercom',
  frontChat: 'Open Front Chat',
  freshChat: 'Open Freshchat',
  helpshift: 'Open Helpshift',
  liveChat: 'Open Livechat',
  crispChat: 'Open Crisp Chat',
  hubspotChat: 'Open Hubspot Chat',
  gorgiasChat: 'Open Gorgias Chat',
  salesforceChat: 'Open Salesforce Chat',
  salesforceMessagingChat: 'Open Salesforce Messaging for Web Chat',
  genesysChat: 'Open Genesys Chat',
};

export const integrations = {
  [INTEGRATION_NAME.zendeskChat]: createOpenIntegrationFunction('openZendeskChat'),
  [INTEGRATION_NAME.intercom]: createOpenIntegrationFunction('openIntercom'),
  [INTEGRATION_NAME.frontChat]: createOpenIntegrationFunction('openFrontChat'),
  [INTEGRATION_NAME.freshChat]: createOpenIntegrationFunction('openFreshChat'),
  [INTEGRATION_NAME.helpshift]: createOpenIntegrationFunction('openHelpshift'),
  [INTEGRATION_NAME.liveChat]: createOpenIntegrationFunction('openLiveChat'),
  [INTEGRATION_NAME.crispChat]: createOpenIntegrationFunction('openCrispChat'),
  [INTEGRATION_NAME.hubspotChat]: createOpenIntegrationFunction('openHubspotChat'),
  [INTEGRATION_NAME.gorgiasChat]: createOpenIntegrationFunction('openGorgiasChat'),
  [INTEGRATION_NAME.salesforceChat]: createOpenIntegrationFunction('openSalesforceChat'),
  [INTEGRATION_NAME.salesforceMessagingChat]: createOpenIntegrationFunction('openSalesforceMessagingChat'),
  Intercom: createOpenIntegrationFunction('openIntercom'), // this one is a fallback for the old name
  [INTEGRATION_NAME.genesysChat]: createOpenIntegrationFunction('openGenesysChat'),
};

export const openIntegration = integrationName => {
  if (integrations[integrationName]) integrations[integrationName]();
};

export const sendSpecialStepAction = action => {
  recorderTrack({
    actionType: 'specialStepAction',
    actionDetail: action || {},
  });
  updateSessionId();
  postMessage({ type: 'specialAction', action });
};

const integrationUrlList = Object.values(INTEGRATION_NAME);
export const getIsIntegrationSpecialUrl = url => {
  return integrationUrlList.includes(url);
};
