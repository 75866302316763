import { PASSWORD_HINT } from './passwordHints.consts';

export function validatePasswordHints(password: string) {
  return {
    [PASSWORD_HINT.NUMBER_OF_CHARACTERS]: password.length >= 8,
    [PASSWORD_HINT.HAS_LETTER]: /[A-Za-z]/.test(password),
    [PASSWORD_HINT.HAS_NUMBER]: /\d/.test(password),
    [PASSWORD_HINT.HAS_UPPER_AND_LOWER_CASE]: /(?=.*[a-z])(?=.*[A-Z])/.test(password),
  };
}
