/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { imagesUrl, playerUrl } from 'global/env';
import ExplanationHelmet from '@playerCommon/CustomElements/ExplanationHelmet';
import { loadExplanation } from 'helpers/explanationManagement.js';
import slugify from 'slugifyjs';
import callApi from 'helpers/apiHelpers';
import i18n, { getLanguageShorthand, shorthandToCodeLanguageMap } from 'helpers/i18n';
import { getGlobal, setGlobal } from 'global/windowUtils';
import { parseFoldersPath, getPageUrlFromKb } from 'helpers/helpcenterHelpers';
import { RIGHT } from 'global';
import { getIntroductionContent, getRedirectUrl, getStepContent, parseGuideData } from 'helpers/guidePlayerHelpers';
import { convertHtmlToMetaTagDescripton } from 'helpers/sanitizeHelpers';
import Page403 from '../ErrorPages/Page403';
import Page404 from '../ErrorPages/Page404';

const Slugify = slugify.fromLocale('en');

function withHcExplanationData(WrappedComponent) {
  return class extends Component {
    static async getInitialProps({ req, match }) {
      const languages = getGlobal('languages');
      const userAgent = getGlobal('userAgent');
      const basename = getGlobal('basename');
      const shouldProxyKBAssets = getGlobal('kb-proxy-assets');

      const returnable = { languages, userAgent, basename, shouldProxyKBAssets };

      if (req === undefined) {
        return returnable;
      }

      if (match.params.language) {
        const siteLanguage = match.params.language;
        setGlobal('siteLanguage', siteLanguage);
        req.i18n.changeLanguage(shorthandToCodeLanguageMap[siteLanguage] || siteLanguage);
      }

      const currentLanguage = getLanguageShorthand();
      const parsedLocation = req._parsedUrl;

      const userRes = await callApi('v1/auth/status', 'get');
      returnable.csrfToken = userRes.data.csrfToken;

      if (userRes.data.logged) {
        returnable.user = userRes.data.user;
      }

      try {
        const response = await callApi(`v1/knowledgebase/integration?language=${currentLanguage}`, 'get');
        const helpcenterData = response.data;

        returnable.helpcenterData = helpcenterData;
        returnable.knowledgeBase = helpcenterData.knowledgeBase;
      } catch (e) {
        console.log(e);

        if (e.status === 404 || e.status === 400) {
          return { statusCode: 404, ...returnable };
        }
        if (e.status === 403 || e.status === 401) {
          return {
            statusCode: 403,
            ssoURL:
              !userRes.data.logged && e.error.sso
                ? `${playerUrl}/api/v1/auth/login/${e.error.sso}/initiate`
                : undefined,
            ...returnable,
          };
        }
        if (e.status === 500) {
          return { statusCode: 500, ...returnable };
        }
      }

      let loadedGuide;
      try {
        const { id, view } = match.params;
        const { widgetId, segmentAnonymousId, segmentUserId, stonlyAnonymousId, customerUserId } = queryString.parse(
          parsedLocation.search
        );
        loadedGuide = await loadExplanation(id, {
          isPreview: view === 'PreviewSteps',
          isShortlink: parsedLocation.pathname.slice(1, 3) === 'sl',
          widgetId,
          segmentAnonymousId,
          segmentUserId,
          stonlyAnonymousId,
          customerUserId,
        });
        loadedGuide = parseGuideData(loadedGuide, currentLanguage, returnable.knowledgeBase);

        // temporary solution for lack of 403
        if (loadedGuide.guideInfo.access === RIGHT.NONE) {
          throw { message: '', status: 403 };
        }
      } catch (e) {
        console.log(e);
        if (e.status === 404 || e.status === 400) {
          return { guideStatus: 404, ...returnable };
        }
        if (e.status === 403 || e.status === 401) {
          return { statusCode: 403, ...returnable };
        }
        if (e.status === 500) {
          return { statusCode: 500, ...returnable };
        }
      }

      const { guideId, title, startId } = loadedGuide.guideInfo;
      const titleSlug = Slugify.parse(title);
      const host = req.headers['x-forwarded-host'] || req.headers.host;
      const ogURL = `https://${host}${basename}/${match.params.mode}/${match.params.language}/guide/${titleSlug}-${guideId}/Steps/${startId}`;

      returnable.customCSS = loadedGuide.guideOptions.designSettings.customHtml;

      const redirectUrl = getRedirectUrl({
        urlParams: match.params,
        urlSearch: parsedLocation.search,
        basename,
        loadedGuide,
      });

      if (redirectUrl) {
        return { statusCode: 301, redirectTo: redirectUrl };
      }

      let breadcrumbs;

      if (returnable.knowledgeBase) {
        breadcrumbs = parseFoldersPath(loadedGuide.guideOptions.folderId, returnable.helpcenterData.folders, false);
        breadcrumbs.unshift({
          folderId: null,
          caption: { [currentLanguage]: { name: returnable.helpcenterData.knowledgeBase.knowledgeBaseName } },
          defaultLanguage: currentLanguage,
        });
      }

      return {
        loadedGuide,
        ogURL,
        breadcrumbs,
        ...returnable,
      };
    }

    render() {
      const { loadedGuide, ogURL, statusCode, match, knowledgeBase, ssoURL, guideStatus, customCSS, basename } =
        this.props;
      setGlobal('siteLanguage', match.params.language);

      const currentLanguage = getLanguageShorthand();

      let titleToDisplay;
      let description;
      let url;
      let lang;
      let favicon;
      let faviconSecureHash;
      let thumbnail;
      let thumbnailSecureHash;
      let isPrivate;
      let knowledgeBaseName;
      let kbMetaDescription;
      let helpcenterData;
      let noIndex;

      if (knowledgeBase) {
        ({
          favicon,
          faviconSecureHash,
          thumbnail,
          thumbnailSecureHash,
          isPrivate,
          knowledgeBaseName,
          helpcenterData,
          noIndex,
        } = knowledgeBase);
        if (helpcenterData) kbMetaDescription = helpcenterData.metaDescription;
      }

      if (loadedGuide && loadedGuide.guideInfo.access !== RIGHT.NONE) {
        const { title, options } = loadedGuide.guideInfo;
        const firstStep = loadedGuide.steps.find(s => s.stepId === loadedGuide.guideInfo.startId);
        const firstStepContent = getStepContent(firstStep, currentLanguage, options.defaultLanguage);
        const introductionContent = getIntroductionContent(firstStep, currentLanguage, options.defaultLanguage);

        titleToDisplay = title;
        url = ogURL;
        lang = loadedGuide.guideOptions.languageList.includes(currentLanguage)
          ? currentLanguage
          : loadedGuide.guideOptions.defaultLanguage;
        noIndex = noIndex === undefined ? false : noIndex;
        isPrivate = isPrivate ? true : noIndex;

        // SEO title and description
        const { titleTag, metaDescription } = options;

        titleToDisplay = titleTag[lang] || titleToDisplay;

        description = metaDescription[lang]
          ? metaDescription[lang]
          : convertHtmlToMetaTagDescripton(introductionContent || firstStepContent);
      } else if (loadedGuide && loadedGuide.access === RIGHT.NONE) {
        titleToDisplay = i18n('RestrictedAccess.RestrictedAccessShort');
        description = i18n('RestrictedAccess.ViewerRestrictedAccess');
      } else {
        titleToDisplay = i18n('RestrictedAccess.RestrictedAccessShort');
        description = i18n('RestrictedAccess.ViewerRestrictedAccess');
      }

      if (statusCode === 500) {
        return <div>Server error, please try again in a few minutes</div>;
      }

      if (statusCode === 403) {
        return <Page403 ssoURL={ssoURL} isWidget={match.params.mode === 'kb-widget'} />;
      }

      if (guideStatus === 404) {
        return <Page404 knowledgeBase={knowledgeBase} />;
      }

      const pageUrl = getPageUrlFromKb(knowledgeBase || {});

      return (
        <>
          <ExplanationHelmet
            title={titleToDisplay}
            description={description || kbMetaDescription}
            companyName={knowledgeBaseName}
            url={url}
            canonicalUrl={url}
            lang={lang}
            favicon={favicon ? `${imagesUrl.url}/${favicon}?s=${faviconSecureHash}` : undefined}
            ogImage={thumbnail ? `${imagesUrl.url}/${thumbnail}?s=${thumbnailSecureHash}` : undefined}
            twitterImage={thumbnail ? `${imagesUrl.url}/${thumbnail}?s=${thumbnailSecureHash}` : undefined}
            isPrivate={Boolean(isPrivate)}
            customCSS={customCSS}
          />
          <WrappedComponent {...this.props} />
        </>
      );
    }

    // eslint-disable-next-line react/static-property-placement
    static propTypes = {
      loadedGuide: PropTypes.object,
      knowledgeBase: PropTypes.object,
      basename: PropTypes.string,
      match: PropTypes.object,
      ogURL: PropTypes.string,
      ssoURL: PropTypes.string,
      statusCode: PropTypes.number,
      customCSS: PropTypes.string,
      guideStatus: PropTypes.number,
    };
  };
}

export default withHcExplanationData;
