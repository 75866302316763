import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CONTACT_FORM_STEPS } from 'global';
import i18n from 'helpers/i18n';
import Button from '@playerCommon/StandardElements/Button';
import BackButton from '@playerCommon/CustomElements/BackButton';

import { InputTextarea } from '@playerCommon/ui/components/inputs/InputTextarea';
import { ColumnFlex } from '@playerCommon/ui/components/Flex';
import { Title, ContactFormBody, ButtonsWrap } from '../ContactForm.styles';

function Intro({
  setStep,
  question = '',
  suggestionsEnabled,
  setSearchQuery,
  previousUrlToLoad,
  onBackClick,
  shouldShowBackButton,
}) {
  const [search, setSearch] = useState(question);

  const onSubmit = () => {
    setSearchQuery(search);
    if (search.length >= 3 && search.length < 250 && suggestionsEnabled) {
      setStep(CONTACT_FORM_STEPS.SUGGESTED_SOLUTIONS);
    } else {
      setStep(CONTACT_FORM_STEPS.CONTACT_FORM);
    }
  };

  return (
    <>
      <ContactFormBody>
        <Title>{i18n('HelpcenterContact.TypeYourQuestion')}</Title>
        <ColumnFlex marginTop={3}>
          <InputTextarea
            value={search}
            minRows={6}
            onChange={e => setSearch(e.target.value)}
            placeholder={i18n('HelpcenterContact.QuestionExample')}
            maxLength={1000}
          />
        </ColumnFlex>
      </ContactFormBody>

      <ButtonsWrap>
        {shouldShowBackButton && <BackButton link={previousUrlToLoad} onClick={onBackClick} />}
        <Button
          background="outlineAmaranth"
          mouseover="highlight"
          onClick={onSubmit}
          dataCy="nextStepButton"
          content={i18n('HelpcenterContact.AskQuestion')}
        />
      </ButtonsWrap>
    </>
  );
}

Intro.propTypes = {
  setStep: PropTypes.func,
  setSearchQuery: PropTypes.func,
  question: PropTypes.string,
  suggestionsEnabled: PropTypes.bool,
  onBackClick: PropTypes.func,
  previousUrlToLoad: PropTypes.string,
  shouldShowBackButton: PropTypes.bool,
};

export default Intro;
