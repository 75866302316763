import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { defaultScrollStyles } from '@playerCommon/CommonStyledComponents/CustomScrollbar';
import { PopupContent } from '@playerCommon/CustomElements/Popup';
import Authentication from '../Authentication';
import { AUTHENTICATION_TITLE_ID } from '../Authentication.consts';

const StyledPopupBody = styled(PopupContent.Body)`
  padding: 0;
  overflow-y: auto;
  max-height: 100vh;
  ${defaultScrollStyles};
`;

const StyledAuthentication = styled(Authentication)`
  width: 458px;

  @media screen and (max-width: 458px) {
    width: 100vw;
    min-height: 100vh;
    padding-top: 24px;
  }
`;

const Popup = ({ authenticationView, onCloseClick, hideClose }) => {
  return (
    <StyledPopupBody role="dialog" aria-modal="true" aria-label={AUTHENTICATION_TITLE_ID}>
      <StyledAuthentication authenticationView={authenticationView} onCloseClick={onCloseClick} hideClose={hideClose} />
    </StyledPopupBody>
  );
};

Popup.propTypes = {
  authenticationView: PropTypes.string,
  onCloseClick: PropTypes.func,
  hideClose: PropTypes.bool,
};

export default Popup;
