import styled, { css } from 'styled-components';
import i18n from 'helpers/i18n';
import Icon from '@playerCommon/StandardElements/Icon';
import SearchEmptySVG from 'icons/searchGraphic.svg';
import FolderIconSVG from 'icons/folder.svg';
import ChatIconSVG from 'icons/chat-bubble-16.svg';
import ExplanationIconSVG from 'icons/explanationIcon.svg';
import GuidedTourIconSVG from 'icons/guidedTourSmall.svg';
import StepIconSVG from 'icons/stepIcon.svg';
import NextIconSVG from 'icons/next.svg';
import PadlockIconSVG from 'icons/padlock.svg';
import Button from '@playerCommon/StandardElements/Button';
import SearchInputIconSVG from 'icons/search.svg';
import { navigationWithKeyboardStyle } from '@playerCommon/CommonStyledComponents/NavigationWithKeyboard';

export const Canvas = styled.div`
  width: 100%;
`;

export const ResultsTitleSep = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${props => props.theme.steel};
  text-transform: uppercase;
  margin-top: 32px;
  margin-bottom: 16px;

  &:first-child {
    margin-top: 0;
  }
`;

export const SearchEmptyWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
  ${({ mode }) =>
    ['header', 'searchpage'].includes(mode) &&
    css`
      padding-top: 0;
    `}
  ${({ showAllResults }) =>
    showAllResults &&
    css`
      padding-top: 16px;
      padding-bottom: 40px;
    `}
`;

export const SearchEmptyIcon = styled(SearchEmptySVG)`
  width: 72px;
  height: 72px;
`;

export const SearchEmptyText = styled.div`
  max-width: 680px;
  width: ${props => (props.isFixedWidth ? '320px' : 'unset')};
  padding: 0 40px;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: ${props => props.theme.steel};
`;

export const SearchInputWrap = styled.div`
  position: relative;
  display: block;
`;

const iconStyle = css`
  width: 16px;
  height: 16px;
`;

export const AIPromptIcon = styled(ChatIconSVG)`
  ${iconStyle};
`;

export const FolderIcon = styled(FolderIconSVG)`
  ${iconStyle};
`;

export const ExplanationIcon = styled(ExplanationIconSVG)`
  ${iconStyle};
`;

export const GuidedTourIcon = styled(GuidedTourIconSVG)`
  ${iconStyle};
`;

export const StepIcon = styled(StepIconSVG)`
  ${iconStyle};

  path {
    fill: ${props => props.theme.amaranth};
  }
`;

export const PadlockIcon = styled(Icon).attrs({
  iconNode: PadlockIconSVG,
  tooltip: i18n('Console.RestrictedRightsTooltip'),
})`
  width: 16px;
  height: 16px;
`;

const smallIconStyle = css`
  width: 12px;
  height: 12px;
  vertical-align: middle;
  margin-right: 4px;
  margin-top: -2px;

  [dir='rtl'] & {
    margin-right: 0;
    margin-left: 4px;
  }
`;

export const ExplanationIconSmall = styled(ExplanationIconSVG)`
  ${smallIconStyle};
`;

export const GuidedTourIconSmall = styled(GuidedTourIconSVG)`
  ${smallIconStyle};
`;

export const ResultsWrap = styled.div`
  display: block;
  padding-left: 24px;
  padding-right: 24px;
`;

export const ResultsRow = styled.a`
  display: block;
  width: 100%;
  display: flex;
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 1px;
  transition: background-color 0.2s, box-shadow 0.2s;
  text-decoration: none;
  cursor: pointer;
  box-shadow: none;
  border-radius: 4px;

  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      background-color: ${props => props.theme.paleGrey};
    `}

  &:hover {
    background-color: ${props => props.theme.paleGrey};
  }

  ${navigationWithKeyboardStyle}
`;

export const ResultsIcon = styled.div`
  display: inline-flex;
  width: 24px;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: flex-start;
  margin-top: 4px;
`;

export const ResultsBreadcrumbIcon = styled(NextIconSVG)`
  width: 11px;
  height: 11px;
  margin-left: 4px;
  margin-right: 4px;
  vertical-align: middle;
  margin-top: -2px;

  /* @noflip */
  [dir='rtl'] & {
    transform: scaleX(-1);
  }

  path {
    fill: ${props => props.theme.steel};
  }
`;

export const ResultsContent = styled.div`
  display: block;
  width: 100%;
`;

export const ResultsTitle = styled.div`
  display: flex;
  gap: 4px;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.darkGrey};

  span {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const ResultsTitleWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const ResultsHighlight = styled.div`
  position: relative;
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 16px;
  color: ${props => props.theme.slateGrey};

  b {
    color: ${props => props.theme.darkGrey};
  }
`;

export const ResultsBreadcrumbs = styled.div`
  display: block;
  font-size: 12px;
  line-height: 16px;
  margin-top: 6px;
  color: ${props => props.theme.steel};
`;

export const Crumb = styled.span`
  display: inline;
  text-decoration: none;
  color: ${props => props.theme.steel};
  transition: color 0.2s;
`;
export const ResultsMore = styled.div`
  font-size: 16px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 2px;
  color: ${props => props.theme.slateGrey};
  transition: background-color 0.2s, box-shadow 0.2s;

  &:hover {
    background-color: ${props => props.theme.paleGrey};
  }
`;

export const ResultsMoreContainer = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  border-top: 1px solid ${props => props.theme.silver};
  padding: 6px 16px;
  background-color: ${props => props.theme.white};
  outline: none;

  body.navigating-with-keyboard &:focus ${ResultsMore} {
    box-shadow: 0px 0px 0px 2px ${props => props.theme.white}, 0px 0px 0px 4px ${props => props.theme.darkBlue};
  }

  ${({ noTopBorder }) =>
    noTopBorder &&
    css`
      border-top: 0;
    `}
`;

export const SearchIcon = styled(SearchInputIconSVG)`
  margin-right: 12px;
  path {
    fill: ${props => props.theme.lightGrey};
  }
`;

export const ContainerRow = styled.div`
  scroll-margin: 52px; //height of show all results button
`;

export const ContactButton = styled(Button)`
  padding-top: 32px;
`;
