import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Input from '@playerCommon/StandardElements/Input';
import SearchInputIconSVG from 'icons/search.svg';
import callApi from 'helpers/apiHelpers';
import { getLanguageShorthand } from 'helpers/i18n';
import SearchResults from './SearchResults';

const Canvas = styled.div`
  width: 100%;
`;

const SearchInput = styled(Input)`
  margin-bottom: 32px;
`;

const SearchInputIcon = styled(SearchInputIconSVG)`
  path {
    fill: ${props => props.theme.seaweed};
  }
`;

class Search extends Component {
  state = {
    searchPhrase: '',
    results: [],
    loading: false,
  };

  searchDebounceId = 0;

  componentDidMount() {
    const { initialValue } = this.props;

    if (initialValue && initialValue.length > 0) {
      this.updateSearchPhrase({ target: { value: initialValue } }, true);
    }
  }

  updateSearchPhrase = e => {
    const { value } = e.target;

    this.setState({ searchPhrase: value, loading: true }, () => {
      clearTimeout(this.searchDebounceId);
      if (value === '') {
        this.clearSearch();
      } else if (value.length > 2) {
        this.searchDebounceId = setTimeout(() => this.getSearchResults(), 500);
      } else {
        this.setState({ results: [], loading: false });
      }
    });
  };

  clearSearch = () => {
    clearTimeout(this.searchDebounceId);
    this.setState({
      searchPhrase: '',
      results: [],
      loading: false,
    });
  };

  async getSearchResults() {
    const { searchPhrase } = this.state;
    const { context = 'view' } = this.props;
    const language = getLanguageShorthand();
    this.setState({ loading: true, results: [] });

    const {
      data: { results, total },
    } = await callApi(`v1/guide/search`, 'GET', { search: searchPhrase, language, context });

    const folderResults = results.filter(el => el.type === 'folder');
    const guideResults = results.filter(el => el.type === 'guide');
    const stepResults = results.filter(el => el.type === 'step');

    this.setState({ results: [...folderResults, ...guideResults, ...stepResults], loading: false });
  }

  render() {
    const { searchPhrase, results, loading } = this.state;
    const { base = {}, back } = this.props;

    const SearchInputClearAction = back ? { func: back, visible: true } : { func: this.clearSearch, visible: false };

    return (
      <Canvas>
        <SearchInput
          autofocus
          iconField={SearchInputIcon}
          iconFirst
          skin="grey"
          size="small"
          value={searchPhrase}
          onChange={this.updateSearchPhrase}
          placeholder="Search for a folder, guide, or step"
          onClearField={SearchInputClearAction}
          maxLength={250}
        />
        <SearchResults searchPhrase={searchPhrase} results={results} loading={loading} base={base} />
      </Canvas>
    );
  }
}

Search.propTypes = {
  initialValue: PropTypes.array,
  base: PropTypes.object,
  back: PropTypes.func,
  context: PropTypes.string,
};

export default Search;
