import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { FILE_SIZES, CONTACT_FORM_STAT_TYPE, CONTACT_FORM_STEPS } from 'global';
import i18n from 'helpers/i18n';
import callApi from 'helpers/apiHelpers';
import { getIds } from 'helpers/statIdsManagement';
import useInput from '@playerCommon/hooks/useInput';
import useUserManagement from '@playerCommon/hooks/useUserManagement';
import { validateEmail } from 'helpers/validationHelpers.js';
import Button from '@playerCommon/StandardElements/Button';
import AttachmentIconSVG from 'icons/attachmentIcon.svg';
import CrossSVG from 'icons/crossMedium.svg';
import { track as recorderTrack } from 'components/Analytics/recorder';
import Loader from '@playerCommon/CustomElements/Loader';

import {
  Title,
  ContactFormTextarea,
  ContactFormInput,
  ContactFormError,
  ContactFormFooter,
  ContactFormBody,
} from '../ContactForm.styles';
import FormFileInput from './FormFileInput';

const AttachmentIcon = styled(AttachmentIconSVG)`
  height: 16px;
  width: 16px;
  margin-right: 12px;
  flex-shrink: 0;
  path {
    fill: ${props => props.theme.lightGrey};
  }
`;

const FilesList = styled.div`
  margin-top: 8px;
`;

const FileRemove = styled(CrossSVG)`
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-left: auto;
  opacity: 0;
  transition: opacity 0.2s;
  cursor: pointer;

  path {
    fill: ${props => props.theme.slateGrey};
  }
`;

const File = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  font-size: 14px;
  line-height: 16px;
  color: ${props => props.theme.slateGrey};

  &:hover {
    ${FileRemove} {
      opacity: 1;
    }
  }
`;

const SendingOverlay = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(4px);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0.2s, opacity 0.2s;

  ${({ open }) =>
    open &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

function parseFileSize(number) {
  if (number < FILE_SIZES.ONE_KILOBYTE) {
    return `${number}bytes`;
  }
  if (number >= FILE_SIZES.ONE_KILOBYTE && number < FILE_SIZES.ONE_MEGABYTE) {
    return `${(number / FILE_SIZES.ONE_KILOBYTE).toFixed(1)}KB`;
  }
  if (number >= FILE_SIZES.ONE_MEGABYTE) {
    return `${(number / FILE_SIZES.ONE_MEGABYTE).toFixed(1)}MB`;
  }
  return 'unknown size';
}

function Form({ setStep, question, setBackVisible }) {
  const userManagement = useUserManagement();
  const [email, setEmail] = useInput(userManagement?.user?.email || '');
  const [topic, setTopic] = useInput('');
  const [description, setDescription] = useInput(question);
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState({});
  const [sending, setSending] = useState(false);
  const contactFormBodyRef = useRef(null);

  const formValid = () => {
    let valid = true;
    const newErrors = {};
    if (!validateEmail(email)) {
      valid = false;
      newErrors.email = i18n('Validation.WrongEmail');
    }
    if (email.length === 0) {
      valid = false;
      newErrors.email = i18n('Validation.EmptyField');
    }
    if (topic.trim().length === 0) {
      valid = false;
      newErrors.topic = i18n('Validation.EmptyField');
    }
    if (description.trim().length === 0) {
      valid = false;
      newErrors.description = i18n('Validation.EmptyField');
    }
    const totalFilesSize = files.reduce((acc, cur) => acc + cur.size, 0);
    if (totalFilesSize > FILE_SIZES.TEN_MEGABYTES) {
      valid = false;
      newErrors.files = i18n('Validation.FileSizeTooBig');
    }
    setErrors(valid ? {} : newErrors);

    return valid;
  };

  const onSubmit = async () => {
    if (formValid() && !sending) {
      const formData = new FormData();
      formData.append('email', email);
      formData.append('title', topic);
      formData.append('description', description);
      const { sessionId } = await getIds();
      if (sessionId) formData.append('sessionId', sessionId);
      if (files.length !== 0) {
        formData.append('selectedFile', files[0], files[0].name);
      }
      // files.forEach(file => {
      //   formData.append('files[]', file, file.name);
      // });
      contactFormBodyRef.current.scrollTo(0, 0);
      setSending(true);
      setBackVisible(false);
      try {
        await callApi('v1/knowledgebase/contact', 'post', formData);
        recorderTrack({
          actionType: 'contactFormSubmission',
          actionDetail: {
            formType: CONTACT_FORM_STAT_TYPE.DEFAULT_KB_CONTACT_FORM,
          },
        });
        setSending(false);
        setStep(CONTACT_FORM_STEPS.THANK_YOU);
      } catch {
        setErrors({ senderror: i18n('Validation.SomethingWentWrong') });
        setBackVisible(true);
        setSending(false);
      }
    }
  };

  const handleFileDrop = f => {
    const filesToAppend = [];
    [...f].forEach(file => {
      if (!files.find(existingFile => existingFile.name === file.name)) {
        filesToAppend.push(file);
      }
    });
    setFiles([...files].concat(filesToAppend));
  };

  const handleFileRemove = f => {
    setFiles(currentFiles => currentFiles.filter(file => file !== f));
    setErrors({ ...errors, files: undefined });
  };

  return (
    <>
      <ContactFormBody locked={sending} ref={contactFormBodyRef}>
        <Title>{i18n('HelpcenterContact.SubmitARequest')}</Title>
        {!!errors.senderror && <ContactFormError>{errors.senderror}</ContactFormError>}
        <ContactFormInput
          type="text"
          value={email}
          skin="bordered"
          onChange={setEmail}
          title={i18n('HelpcenterContact.EmailFieldLabel')}
          placeholder="bill.crisper@gmail.com"
          invalid={!!errors.email}
          maxLength={250}
        />
        {!!errors.email && <ContactFormError>{errors.email}</ContactFormError>}
        <ContactFormInput
          type="text"
          value={topic}
          skin="bordered"
          onChange={setTopic}
          title={i18n('HelpcenterContact.Topic')}
          placeholder={i18n('HelpcenterContact.TopicPlaceholder')}
          invalid={!!errors.topic}
          maxLength={250}
        />
        {!!errors.topic && <ContactFormError>{errors.topic}</ContactFormError>}
        <ContactFormTextarea
          value={description}
          row={6}
          skin="bordered"
          onChange={setDescription}
          title={i18n('HelpcenterContact.Description')}
          placeholder={i18n('HelpcenterContact.DescriptionPlaceholder')}
          invalid={!!errors.description}
          maxLength={20_000}
        />
        {!!errors.description && <ContactFormError>{errors.description}</ContactFormError>}
        <FormFileInput onFile={handleFileDrop} disabled={files.length !== 0} />
        {!!errors.files && <ContactFormError>{errors.files}</ContactFormError>}
        <FilesList>
          {files.map(file => (
            <File key={file.name}>
              <AttachmentIcon />
              {file.name} {parseFileSize(file.size)}
              <FileRemove onClick={() => handleFileRemove(file)} />
            </File>
          ))}
        </FilesList>
        <SendingOverlay open={sending}>
          <Loader />
        </SendingOverlay>
      </ContactFormBody>
      <ContactFormFooter>
        <Button
          background="outlineAmaranth"
          mouseover="highlight"
          onClick={onSubmit}
          dataCy="nextStepButton"
          content={i18n('HelpcenterContact.SubmitRequest')}
        />
      </ContactFormFooter>
    </>
  );
}

Form.propTypes = {
  setStep: PropTypes.func,
  setBackVisible: PropTypes.func,
  question: PropTypes.string,
};

export default Form;
