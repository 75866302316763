import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { motion, animate } from 'framer-motion';
import usePrevious from '@playerCommon/hooks/usePrevious';

const COMPLETION_TRESHOLD = {
  start: 'start',
  end: 'end',
};

const Progress = styled.div`
  position: relative;
  display: block;
  width: 100%;
`;

const Line = styled.div`
  background-color: white;
  width: 100%;
  height: ${({ height }) => height}px;
  ${({ noBackground }) =>
    noBackground &&
    css`
      background-color: transparent;
    `}
`;

const Bar = styled(motion.div)`
  position: absolute;
  height: ${({ height }) => height}px;
  max-width: 100%;
  background-color: ${props => props.theme.defaultGreen};
  border-radius: 4px;
`;

const Text = styled.div`
  position: absolute;
  right: 0;
  top: 10px;
  font-size: 14px;
  margin-left: 12px;
  font-weight: 500;
  color: white;
  border-radius: 4px;
  padding: 2px 6px;
  background-color: ${props => props.theme.defaultGreen};
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s, visibility 0.2s, transform 0.5s;
  ${({ show }) =>
    show &&
    css`
      visibility: visible;
      opacity: 1;
    `}
`;

const Canvas = styled.div`
  width: 100%;
  display: block;

  ${Line} {
    background-color: ${props => props.theme.defaultGreen};
    opacity: 0.3;
  }

  ${Text} {
    left: 100%;
    right: auto;
    margin-left: 0;
    transform: translateX(-50%);
    top: -25px;

    ${({ completionTreshold }) => {
      if (completionTreshold === COMPLETION_TRESHOLD.end) {
        return css`
          transform: translateX(calc(-100% + -4px));
        `;
      }
      if (completionTreshold === COMPLETION_TRESHOLD.start) {
        return css`
          transform: translateX(4px);
        `;
      }
    }}
  }
`;

const ProgressBar = ({ completion = 0, className, height = 4, noBackground }) => {
  let showTextTimeout;
  const [showText, setShowText] = useState(false);
  const textWrapRef = useRef();
  const previousCompletion = usePrevious(completion);

  useEffect(() => {
    clearTimeout(showTextTimeout);
    setShowText(true);
    showTextTimeout = setTimeout(() => {
      setShowText(false);
    }, 1000);

    const controls = animate(previousCompletion || 0, completion, {
      duration: 1,
      type: 'spring',
      bounce: 0,
      onUpdate(value) {
        textWrapRef.current.textContent = `${value.toFixed(0)}%`;
      },
    });

    return () => {
      controls.stop();
      clearTimeout(showTextTimeout);
    };
  }, [completion]);

  let completionTreshold = '';
  if (completion >= 90) {
    completionTreshold = COMPLETION_TRESHOLD.end;
  } else if (completion <= 10) {
    completionTreshold = COMPLETION_TRESHOLD.start;
  }

  return (
    <Canvas role="region" aria-live="polite" className={className} completionTreshold={completionTreshold}>
      <Progress data-cy="progressBar" className="progressBar">
        <Bar
          height={height}
          animate={{ width: `${completion}%` }}
          transition={{ type: 'spring', bounce: 0, velocity: 0 }}
          className="progressBar-bar"
          role="progressbar"
          aria-valuenow={completion}
          title="progress"
        >
          <Text show={showText} className="progressBar-text">
            <span ref={textWrapRef} />
          </Text>
        </Bar>
        <Line height={height} noBackground={noBackground} className="progressBar-line" />
      </Progress>
    </Canvas>
  );
};

ProgressBar.propTypes = {
  completion: PropTypes.number,
  className: PropTypes.string,
  height: PropTypes.number,
  noBackground: PropTypes.bool,
};

export default ProgressBar;
