import { useEffect } from 'react';
import { KB_EXTERNAL_CHAT_TYPES } from 'global';
import { initializeCrispChat } from './crispExternalChat';
import { initializeFreshChat } from './freshExternalChat';
import { initializeGorgiasChat } from './gorgiasExternalChat';
import { initializeHelpshiftChat } from './helpshiftExternalChat';
import { initializeHubspotChat } from './hubspotExternalChat';
import { initializeIntercomChat } from './intercomExternalChat';
import { initializeLiveChat } from './livechatExternalChat';
import { initializeZendeskChat } from './zendeskExternalChat';
import { initializeSalesforceChat } from './salesforceExternalChat';
import { initializeGenesysChat } from './genesysExternalChat';

function ExternalChat({ externalChatType, onContactOpen, onContactClose, isWidget }) {
  useEffect(() => {
    if (externalChatType === KB_EXTERNAL_CHAT_TYPES.CRISP && window.$crisp) {
      initializeCrispChat(isWidget);
    }

    if (externalChatType === KB_EXTERNAL_CHAT_TYPES.FRESHCHAT) {
      initializeFreshChat(isWidget);
    }

    if (externalChatType === KB_EXTERNAL_CHAT_TYPES.GORGIAS) {
      initializeGorgiasChat(isWidget, onContactOpen, onContactClose);
    }

    if (externalChatType === KB_EXTERNAL_CHAT_TYPES.HELPSHIFT && window.Helpshift) {
      initializeHelpshiftChat();
    }

    if (externalChatType === KB_EXTERNAL_CHAT_TYPES.HUBSPOT) {
      initializeHubspotChat(isWidget, onContactOpen, onContactClose);
    }

    if (externalChatType === KB_EXTERNAL_CHAT_TYPES.INTERCOM && window.Intercom) {
      initializeIntercomChat();
    }
    if (externalChatType === KB_EXTERNAL_CHAT_TYPES.LIVECHAT && window.LiveChatWidget) {
      initializeLiveChat(isWidget);
    }
    if (externalChatType === KB_EXTERNAL_CHAT_TYPES.ZENDESK && window.zE) {
      initializeZendeskChat(isWidget);
    }
    if (externalChatType === KB_EXTERNAL_CHAT_TYPES.SALESFORCE && window.embedded_svc) {
      initializeSalesforceChat(isWidget);
    }
    if (externalChatType === KB_EXTERNAL_CHAT_TYPES.GENESYS) {
      initializeGenesysChat(isWidget, onContactOpen, onContactClose);
    }
  }, []);

  return null;
}

export default ExternalChat;
