import React from 'react';

const notificationContext = React.createContext({
  notifications: [],
  pushNotification: () => {},
  removeNotification: () => {},
});

export default notificationContext;

export const pushNotificationContext = React.createContext();
