import React, { memo, useEffect, useCallback, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import i18n from 'helpers/i18n';
import { sanitizeURL } from 'helpers/sanitizeHelpers';
import { isObjectEmpty } from 'helpers/objectManagement';
import { postMessage } from 'helpers/widgetHelpers';
import useGuideData from '@playerCommon/hooks/playerHooks/useGuideData';
import usePrevious from '@playerCommon/hooks/usePrevious';
import useTargetingChangedMessage from '@playerCommon/hooks/useTargetingChangedMessage';
import { GUIDE_TYPE, STEP_WIDGET_ACTION_TYPES, START_TYPES, RIGHT, CHECKLIST_RESET_RIGHTS } from 'global';

import ChecklistItem from './ChecklistItem';
import ResetChecklistButton from './ResetChecklistButton';
import { ChecklistWrapper, ChecklistSkippedText, ChecklistSkippedIcon } from './Checklist.styles';
import {
  savePathToChecklist,
  getChecklistStatus,
  completeChecklistItem,
  resetChecklistItem,
  isChecklistItemCompleted,
  postChecklistMessage,
  getTargetingEventsAndProperties,
  resetChecklist,
  getSavedChecklistStatusByChecklistId,
  setSavedChecklistStatusByChecklistId,
} from './Checklist.helpers';
import { actionsOnClick, checklistItemTypes } from './Checklist.consts';

const Checklist = ({
  checklistInfo,
  pathFromUrl,
  isChecklistSkipped,
  isPreview,
  checklistId,
  onChecklistStatusLoad,
  onChecklistCompleted,
}) => {
  const { items, settings, checklistModuleGuideId } = checklistInfo;
  const { completeInOrder, allowManualChecklistReset, resetRights } = settings;
  const history = useHistory();
  const { guide, guideInfo, isWidget, access } = useGuideData();
  const [checklistStatus, setChecklistStatus] = useState(() => getSavedChecklistStatusByChecklistId(checklistId));
  const isStatusLoaded = !isObjectEmpty(checklistStatus);
  const previousChecklistStatus = usePrevious(checklistStatus);

  const canResetChecklist =
    resetRights === CHECKLIST_RESET_RIGHTS.EVERYONE ||
    (resetRights === CHECKLIST_RESET_RIGHTS.EDITORS && access >= RIGHT.VIEW_EDIT);

  const { isChecklistCompleted, disabledItemsMap } = useMemo(() => {
    let disabledItems = {};
    if (completeInOrder) {
      disabledItems = items.reduce((acc, item, index, array) => {
        const previous = array.slice(0, index);
        const isAllPreviousCompleted = previous.every(prevItem => isChecklistItemCompleted(prevItem, checklistStatus));
        return {
          ...acc,
          [item.id]: !isAllPreviousCompleted && !isChecklistItemCompleted(item, checklistStatus),
        };
      }, {});
    }
    return {
      isChecklistCompleted: isStatusLoaded && items.every(item => isChecklistItemCompleted(item, checklistStatus)),
      disabledItemsMap: disabledItems,
    };
  }, [items, checklistStatus, completeInOrder, isStatusLoaded]);

  const targetingEventAndProperties = useMemo(
    () => getTargetingEventsAndProperties(items, checklistStatus),
    [items, checklistStatus]
  );

  const setStatus = useCallback(status => {
    if (!isPreview) {
      setSavedChecklistStatusByChecklistId(checklistId, status);
      setChecklistStatus(status);
    }
  }, []);

  const getStatus = useCallback(async () => {
    const result = await getChecklistStatus({
      checklistId,
      guideId: checklistModuleGuideId,
      teamId: guideInfo.teamId,
    });
    if (result) {
      setStatus(result);
    }
  }, []);

  useEffect(() => {
    const onVisibilityChange = e => {
      if (e.target.visibilityState === 'visible') {
        getStatus();
      }
    };

    if (!isPreview) {
      postChecklistMessage({
        type: 'checklistViewed',
        guideId: checklistModuleGuideId,
        checklistId,
      });

      getStatus();
      document.addEventListener('visibilitychange', onVisibilityChange);
    }
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, []);

  useEffect(() => {
    const onMessage = message => {
      if (message.data.type === 'widgetOpened') {
        getStatus();
      }
    };
    window.addEventListener('message', onMessage);
    return () => window.removeEventListener('message', onMessage);
  }, []);

  const goToGuideInsideChecklist = useCallback(
    ({ guideToOpen, startingStepId }) => {
      const { guideId: currentGuideId } = guideInfo;
      if (guideToOpen) {
        const stepToOpen = `${startingStepId}`;
        savePathToChecklist(currentGuideId, pathFromUrl);
        setTimeout(() => history.push(stepToOpen), 50);
      }
    },
    [history, guide, pathFromUrl]
  );

  const completeItem = useCallback(
    async ({ id }) =>
      completeChecklistItem({
        checklistItemId: id,
        checklistId,
        guideId: checklistModuleGuideId,
        teamId: guideInfo.teamId,
      }),
    []
  );

  const completeItemAndFetchStatus = useCallback(async ({ id, title }) => {
    const result = await completeItem({ id });
    if (result) {
      await getStatus();
      postChecklistMessage({
        type: 'checklistItemCompleted',
        guideId: checklistModuleGuideId,
        checklistId,
        checklistItemId: id,
        checklistItemTitle: title,
      });
    }
  }, []);

  const resetItem = useCallback(async ({ id, title }) => {
    const result = await resetChecklistItem({
      checklistItemId: id,
      checklistId,
      guideId: checklistModuleGuideId,
      teamId: guideInfo.teamId,
    });
    if (result) {
      await getStatus();
      postChecklistMessage({
        type: 'checklistItemReset',
        guideId: checklistModuleGuideId,
        checklistId,
        checklistItemId: id,
        checklistItemTitle: title,
      });
    }
  }, []);

  const doResetChecklist = useCallback(async () => {
    const result = await resetChecklist({
      checklistId,
      guideId: checklistModuleGuideId,
      teamId: guideInfo.teamId,
    });
    if (result) {
      await getStatus();
      postChecklistMessage({
        type: 'checklistReset',
        guideId: checklistModuleGuideId,
        checklistId,
      });
    }
  }, []);

  const triggerActionOnClick = useCallback(
    async itemId => {
      if (isPreview || !isStatusLoaded) {
        return;
      }

      const item = items.find(({ id }) => id === itemId);
      const { actionOnClick = {}, completionCondition, checklistItemType, id, title } = item;

      if (actionOnClick.type === actionsOnClick.LINK) {
        const url = actionOnClick.url || completionCondition.url;
        const sanitizedUrl = sanitizeURL(url);
        const isOpenInNewTab = !!actionOnClick.openLinkInNewTab;
        const shouldCompleteChecklistItem = checklistItemType === checklistItemTypes.URL_OPENED;

        if (isOpenInNewTab) {
          if (shouldCompleteChecklistItem) {
            completeItemAndFetchStatus({ id, title });
          }
          window.open(sanitizedUrl, '_blank', 'noopener');
        } else {
          if (shouldCompleteChecklistItem) {
            await completeItem({ id });
          }
          window.top.location.href = sanitizedUrl;
        }
      }

      if (actionOnClick.type === actionsOnClick.GUIDE && actionOnClick.guideId) {
        const guideToOpen = guide.allGuides.find(g => g.guideId === actionOnClick.guideId);
        if (!guideToOpen) {
          return;
        }

        if (guideToOpen.type === GUIDE_TYPE.GUIDED_TOUR && isWidget) {
          postMessage({
            type: 'specialAction',
            action: {
              type: STEP_WIDGET_ACTION_TYPES.GUIDED_TOUR,
              value: {
                uniqueGuideId: item.actionOnClick.guideId,
              },
            },
          });
          postMessage({
            type: 'pathToChecklist',
            currentSteps: pathFromUrl,
            currentKBPath: document.location.origin + document.location.pathname,
          });
        } else {
          const { stepStartType, startFromStepId } = actionOnClick;
          const shouldStartFromSpecificStep =
            stepStartType === START_TYPES.SPECIFIC_STEP &&
            startFromStepId &&
            guideToOpen.steps.find(({ stepId }) => stepId === startFromStepId);
          const startingStepId = shouldStartFromSpecificStep ? startFromStepId : guideToOpen.firstStepId;
          goToGuideInsideChecklist({ guideToOpen, startingStepId });
        }
      }
    },
    [items, goToGuideInsideChecklist, pathFromUrl, isPreview, completeItem, completeItemAndFetchStatus, isStatusLoaded]
  );

  const toggleItem = useCallback(
    itemId => {
      if (isPreview || !isStatusLoaded) {
        return;
      }

      const item = items.find(({ id }) => id === itemId);
      const { id, title } = item;
      const isCompleted = checklistStatus[id];

      if (isCompleted) {
        resetItem({ id, title });
      } else {
        completeItemAndFetchStatus({ id, title });
      }
    },
    [items, completeItemAndFetchStatus, resetItem, checklistStatus, isStatusLoaded, isPreview]
  );

  useEffect(() => {
    const hasPreviousStatus = !isObjectEmpty(previousChecklistStatus);
    const hadUncompletedItems = !items.every(item => isChecklistItemCompleted(item, previousChecklistStatus));
    if (isChecklistCompleted && hasPreviousStatus && hadUncompletedItems) {
      postChecklistMessage({
        type: 'checklistCompleted',
        guideId: checklistModuleGuideId,
        checklistId,
      });
    }
  }, [isChecklistCompleted, previousChecklistStatus, items]);

  const onTargetingChange = useCallback(
    ({ properties, event }) => {
      if (targetingEventAndProperties.length) {
        const propertyNameList = properties ? Object.keys(properties) : [];
        const eventNameList = event ? [event] : [];

        if ([...propertyNameList, ...eventNameList].some(name => targetingEventAndProperties.includes(name))) {
          getStatus();
        }
      }
    },
    [targetingEventAndProperties, getStatus]
  );

  useTargetingChangedMessage({ onTargetingChange });

  useEffect(() => {
    if (isStatusLoaded) {
      onChecklistStatusLoad(true);
    }
  }, [isStatusLoaded]);

  useEffect(() => onChecklistCompleted(isChecklistCompleted), [isChecklistCompleted]);

  return (
    <ChecklistWrapper isChecklistSkipped={isChecklistSkipped}>
      {!isChecklistSkipped && (
        <>
          {items.map(item => (
            <ChecklistItem
              key={item.id}
              id={item.id}
              itemType={item.checklistItemType}
              title={item.title}
              hasActionOnClick={item.actionOnClick.type && item.actionOnClick.type !== actionsOnClick.NONE}
              triggerActionOnClick={triggerActionOnClick}
              toggleItem={toggleItem}
              isChecked={
                item.checklistItemType === checklistItemTypes.PRE_CHECKED ||
                (isPreview ? false : checklistStatus[item.id])
              }
              disabled={!isStatusLoaded || !!disabledItemsMap[item.id]}
              isStatusLoaded={isStatusLoaded || isPreview}
              canBeCheckedManually={item.checklistItemType === checklistItemTypes.MANUAL_CHECK || item.allowManualCheck}
            />
          ))}
          {allowManualChecklistReset && canResetChecklist && (
            <ResetChecklistButton onClick={doResetChecklist} isPreview={isPreview} />
          )}
        </>
      )}
      {isChecklistSkipped && (
        <>
          <ChecklistSkippedIcon />
          <ChecklistSkippedText>{i18n('Checklist.ChecklistSkipped')}</ChecklistSkippedText>
        </>
      )}
    </ChecklistWrapper>
  );
};

Checklist.propTypes = {
  checklistInfo: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    settings: PropTypes.shape({
      completeInOrder: PropTypes.bool,
    }).isRequired,
    checklistModuleGuideId: PropTypes.string.isRequired,
  }),
  pathFromUrl: PropTypes.string,
  isChecklistSkipped: PropTypes.bool,
  isPreview: PropTypes.bool,
  checklistId: PropTypes.number,
  onChecklistStatusLoad: PropTypes.func,
  onChecklistCompleted: PropTypes.func,
};

export default memo(Checklist);
