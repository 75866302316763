import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { loadExplanation } from 'helpers/explanationManagement';
import { getIds } from 'helpers/statIdsManagement';
import { getSegmentIds, postMessage } from 'helpers/widgetHelpers';
import { useLocation } from 'react-router-dom';
import { parseGuideData } from 'helpers/guidePlayerHelpers';
import { setTempStorage } from 'helpers/storage';

const AdditionalLoadedGuideDataContext = React.createContext();

function AdditionalLoadedGuideDataProvider({ children }) {
  const location = useLocation();

  const [additionalLoadedGuides, setAdditionalLoadedGuides] = useState([]);

  useEffect(() => {
    // required by the recorder.js to be able to track the right guides data
    setTempStorage('additionalLoadedGuides', additionalLoadedGuides);
  }, [additionalLoadedGuides]);

  const getIsAdditionalGuideLoadedAlready = useCallback(
    (id /* , language */) => {
      const alreadyLoadedGuide = additionalLoadedGuides.find(g => g.guideInfo.guideId === id);
      return !!alreadyLoadedGuide;
    },
    [additionalLoadedGuides]
  );

  /**
   * This function has a safety check to check if you have not loaded the guide you have previously loaded,
   * but has no check against loading the same guide you're initially in, so remember to check for it.
   */
  const loadAdditionalGuide = useCallback(
    async (id, language) => {
      const alreadyLoadedGuide = additionalLoadedGuides.find(g => g.guideInfo.guideId === id);
      if (alreadyLoadedGuide) {
        return alreadyLoadedGuide;
      }

      try {
        const urlParams = queryString.parse(location.search);
        const { widgetId, segmentAnonymousId, segmentUserId, stonlyAnonymousId, customerUserId } = urlParams;
        const { stonlyAnonymousId: _stonlyAnonymousId, customerUserId: _customerUserId } = await getIds();
        const { segmentAnonymousId: _segmentAnonymousId, segmentUserId: _segmentUserId } = getSegmentIds();

        const loadedExplanation = await loadExplanation(id, {
          isPreview: false, // this will always load the published guides
          isShortlink: false, // we'll never use shortlink paths here
          widgetId,
          segmentAnonymousId: segmentAnonymousId || _segmentAnonymousId,
          segmentUserId: segmentUserId || _segmentUserId,
          stonlyAnonymousId: stonlyAnonymousId || _stonlyAnonymousId,
          customerUserId: customerUserId || _customerUserId,
        });

        const loadedGuide = parseGuideData(loadedExplanation, language);

        setAdditionalLoadedGuides(g => [...g, loadedGuide]);

        postMessage({
          type: 'stonlyAdditionalEmbeddedGuideLoaded',
          guideId: loadedGuide.guideInfo.guideId,
          stepId: loadedGuide.guideInfo.firstStepId,
        });

        return loadedGuide;
      } catch {
        return false;
      }
    },
    [additionalLoadedGuides]
  );

  return (
    <AdditionalLoadedGuideDataContext.Provider
      value={useMemo(
        () => ({ additionalLoadedGuides, loadAdditionalGuide, getIsAdditionalGuideLoadedAlready }),
        [additionalLoadedGuides, loadAdditionalGuide, getIsAdditionalGuideLoadedAlready]
      )}
    >
      {children}
    </AdditionalLoadedGuideDataContext.Provider>
  );
}

function useAdditionalLoadedGuidesData() {
  const context = React.useContext(AdditionalLoadedGuideDataContext);
  if (context === undefined) {
    throw new Error('useAdditionalLoadedGuidesData must be used within a AdditionalLoadedGuideDataProvider');
  }
  return context;
}

AdditionalLoadedGuideDataProvider.propTypes = {
  children: PropTypes.node,
};

export { AdditionalLoadedGuideDataProvider, useAdditionalLoadedGuidesData };
