import { useState, useEffect } from 'react';
import { postMessage } from 'helpers/widgetHelpers';

export default function useGuidedTourStepEnabledConnections({
  stepConnectionList = [],
  shouldEveryConnectionBeEnabled = true,
} = {}) {
  const [enabledConnectionIdsList, setEnabledConnectionIdsList] = useState(() =>
    stepConnectionList.filter(({ hasEnablingConditions }) => !hasEnablingConditions).map(({ id }) => id)
  );

  useEffect(() => {
    if (shouldEveryConnectionBeEnabled) {
      return;
    }
    postMessage({ type: 'getEnabledConnectionIdsList' });
    const onMessage = message => {
      if (message.data.type === 'enabledConnectionIdsList') {
        setEnabledConnectionIdsList(message.data.enabledConnectionIdsList);
      }
    };
    window.addEventListener('message', onMessage);
    return () => window.removeEventListener('message', onMessage);
  }, [shouldEveryConnectionBeEnabled]);

  return enabledConnectionIdsList;
}
