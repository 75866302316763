import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AUTOMATION_STEP_LOADER } from 'global';
import DotsSpinner from './icons/DotsSpinner';
import Spinner from './icons/Spinner';
import ProgressCircle from './icons/ProgressCircle';
import ProgressBar from './icons/ProgressBar';

const Wrapper = styled.div`
  width: 96px;
  height: 96px;
  margin: 32px auto;

  @media screen and (max-width: 960px) {
    width: 64px;
    height: 64px;
  }
`;

const AutomationLoader = ({ iconName, iconColor, isPreview, animationDuration, shouldAccelerateAnimation }) => (
  <Wrapper className="automation-loader" aria-busy="true" aria-live="polite">
    {iconName === AUTOMATION_STEP_LOADER.spinner && <Spinner iconColor={iconColor} />}
    {iconName === AUTOMATION_STEP_LOADER.dotsSpinner && <DotsSpinner iconColor={iconColor} />}
    {iconName === AUTOMATION_STEP_LOADER.progressBar && (
      <ProgressBar
        iconColor={iconColor}
        isPreview={isPreview}
        animationDuration={animationDuration}
        shouldAccelerateAnimation={shouldAccelerateAnimation}
      />
    )}
    {iconName === AUTOMATION_STEP_LOADER.filledSpinner && (
      <ProgressCircle
        iconColor={iconColor}
        isPreview={isPreview}
        animationDuration={animationDuration}
        shouldAccelerateAnimation={shouldAccelerateAnimation}
      />
    )}
  </Wrapper>
);

AutomationLoader.propTypes = {
  iconName: PropTypes.string,
  iconColor: PropTypes.string,
  isPreview: PropTypes.bool,
  animationDuration: PropTypes.number,
  shouldAccelerateAnimation: PropTypes.bool,
};

export default AutomationLoader;
