import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import callApi from 'helpers/apiHelpers';

import { getFolderCaption, parseFoldersPath } from 'helpers/helpcenterHelpers';
import useSearchTrack from '@playerCommon/hooks/useSearchTrack';
import { SEARCH_RESULT_TYPES } from 'components/consts';
import { trackSearchImmediate } from 'components/Analytics/recorder';
import { useIsSmartSearchQueryStringPresent } from '../../Pages/helpers';

function doesResponseContainAIResult(results) {
  return !!results.find(res => res.type === SEARCH_RESULT_TYPES.AI);
}

const SearchContext = React.createContext();

export const SearchProvider = ({ initialValue, children, language, helpcenterData }) => {
  const [searchPhrase, setSearchPhrase] = useState(initialValue);
  const [results, setResults] = useState([]);
  const [allResultsLength, setAllResultsLength] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchCallback, setSearchCallback] = useState(undefined);
  const [hasSemanticAnswer, setHasSemanticAnswer] = useState(false);
  const { hasSearchTrackAction, setSearchTrackActionData, trackSearchAction, onSearchResultClick } =
    useSearchTrack(results);

  const isSmartSearchQueryStringPresent = useIsSmartSearchQueryStringPresent();

  const searchDebounceId = useRef();

  useEffect(() => {
    setSearchPhrase(initialValue);
    if (initialValue) setLoading(true);
  }, [initialValue]);

  const onSearchChange = useCallback((value, callback) => {
    setSearchPhrase(value);
    if (value) setLoading(true);
    if (callback && typeof callback === 'function') setSearchCallback(() => callback);
  }, []);

  const clearSearch = useCallback(() => {
    clearTimeout(searchDebounceId.current);

    if (hasSearchTrackAction()) {
      setSearchTrackActionData({ results });
      trackSearchAction();
    }

    setSearchPhrase('');
    setResults([]);
    setLoading(false);
  }, []);

  async function getSearchResults() {
    setResults([]);
    setLoading(true);
    setHasSemanticAnswer(false);

    const {
      data: { results: resResults, total },
    } = await callApi(`v1/knowledgebase/search`, 'GET', {
      search: searchPhrase,
      language,
      smartSearch: isSmartSearchQueryStringPresent,
    });

    if (doesResponseContainAIResult(resResults)) {
      setHasSemanticAnswer(true);
      trackSearchImmediate({ actionType: 'semanticSearchProposed', actionDetail: { term: searchPhrase } });
    }

    if (!hasSearchTrackAction()) {
      setSearchTrackActionData({
        searchStartDate: Date.now(),
        term: searchPhrase,
      });
    } else if (searchPhrase.length > 2) {
      setSearchTrackActionData({
        term: searchPhrase,
      });
    }

    const resultsWithBreadcrumbs =
      resResults.length > 0
        ? resResults.map(result => {
            const bc = parseFoldersPath(result.folderId, helpcenterData.folders, false);
            const breadcrumbs = bc.map(el => ({
              folderId: el.folderId,
              name: getFolderCaption(el, language).name,
              type: 'folder',
            }));
            breadcrumbs.unshift({
              folderId: null,
              type: 'folder',
              name: helpcenterData.knowledgeBase.knowledgeBaseName,
            });
            if (result.type === 'step') {
              const { title } = helpcenterData.guides.find(el => el.guideId === result.guideId) || {};
              breadcrumbs.push({ guideId: result.guideId, name: title, type: 'guide' });
            }
            return {
              ...result,
              breadcrumbs,
            };
          })
        : [];

    const aiResults = resultsWithBreadcrumbs.filter(el => el.type === SEARCH_RESULT_TYPES.AI);
    const folderResults = resultsWithBreadcrumbs.filter(el => el.type === SEARCH_RESULT_TYPES.FOLDER);
    const guideResults = resultsWithBreadcrumbs.filter(el => el.type === SEARCH_RESULT_TYPES.GUIDE);
    const stepResults = resultsWithBreadcrumbs.filter(el => el.type === SEARCH_RESULT_TYPES.STEP);

    setAllResultsLength(total);
    setResults([...aiResults, ...folderResults, ...guideResults, ...stepResults].filter(Boolean));
    setLoading(false);
  }

  useEffect(() => {
    clearTimeout(searchDebounceId.current);

    if (searchPhrase === '') {
      clearSearch();
    } else if (searchPhrase.length > 2) {
      searchDebounceId.current = setTimeout(() => {
        getSearchResults();

        /* wrap can loose focus on some users mouse interaction, we need to recover it */
        if (searchCallback && typeof searchCallback === 'function') searchCallback();
      }, 500);
    } else {
      setResults([]);
      setLoading(false);
    }
  }, [searchPhrase, searchCallback]);

  const searchContextValue = useMemo(
    () => ({
      searchPhrase,
      onSearchChange,
      clearSearch,
      onSearchResultClick,
      results,
      allResultsLength,
      loading,
      hasSemanticAnswer,
    }),
    [
      searchPhrase,
      onSearchChange,
      clearSearch,
      onSearchResultClick,
      results,
      allResultsLength,
      loading,
      hasSemanticAnswer,
    ]
  );

  return <SearchContext.Provider value={searchContextValue}>{children}</SearchContext.Provider>;
};

export const useSearchContext = () => {
  const context = React.useContext(SearchContext);
  if (context === undefined) {
    throw new Error('useSearchContext must be used within a SearchProvider');
  }
  return context;
};

SearchProvider.propTypes = {
  initialValue: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isSmartSearchEnabled: PropTypes.bool,
  language: PropTypes.string,
  helpcenterData: PropTypes.object,
};
