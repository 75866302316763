/* eslint-disable react/function-component-definition */
import { useSentData } from '@playerCommon/Contexts/sentDataContext';
import { useServerCallData } from '@playerCommon/Contexts/serverCallDataContext';
import { useUserData } from '@playerCommon/Contexts/userDataContext';
import { convertTemplateStringToDisplayString } from 'helpers/guideVariableHelpers';
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { STEP_NEXT_VISIBILITY } from 'global';
import useGuideData from '@playerCommon/hooks/playerHooks/useGuideData';
import ButtonWithTooltip from '@playerCommon/StandardElements/Buttons/ButtonWithTooltip';
import { useTranslation } from 'react-i18next';
import { StyledConditionalNextStepIcon } from '@playerCommon/ComplexElements/ExplanationPlayer/Steps/Steps.styles';

const Canvas = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;

  margin-top: 0;
  margin-bottom: 0px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const NextStepButton = styled(ButtonWithTooltip)`
  display: block;
  text-transform: none;
  font-size: ${props => props.theme.aiAnswerButtonFontSize};
  line-height: ${props => props.theme.aiAnswerButtonLineHeight};
  color: ${props => props.theme.darkGrey};
  font-weight: normal;
  height: auto;
  padding: ${props => props.theme.aiAnswerButtonPadding};
  background: ${props => props.theme.anotherWeirdLightGrey};
  transition: background-color 0.3s;
  max-width: 70%;
  text-align: right;
  border-radius: 20px; // half of the max one-line height, to keep corners roundness.

  &:hover {
    background: ${props => props.theme.pearl};
  }
`;

const ChoiceLabel = styled.span`
  align-items: center;
  display: flex;
`;

const getOptionLabel = (option, { isPreview, localData, userData, serverCallVariables }) => {
  if (typeof option.label === 'string') {
    return convertTemplateStringToDisplayString({
      content: option.label,
      localData,
      userData,
      serverCallVariables,
      isPreview,
      shouldWrapVarName: isPreview,
    });
  }
  if (typeof option.title === 'string') {
    return option.title;
  }
  return '';
};

const AiAnswerActionsListStepNexts = ({ options, disabled, onStepNextClick }) => {
  const { t } = useTranslation();
  const { mode } = useGuideData();
  const { localData } = useSentData();
  const userData = useUserData();
  const { serverCallVariables } = useServerCallData();

  const isPreview = mode === 'preview';

  return (
    <Canvas data-cy="selectorTypes" isButtonSelectorType>
      {options.map(option => (
        <NextStepButton
          className={`ai-action-button aiAction-${option.id}`}
          key={option.id}
          onClick={() => onStepNextClick(option)}
          disabled={disabled || option.disabled}
          tooltip={isPreview ? t('Preview.ButtonsDisabled') : undefined}
          tooltipPosition="top-start"
          data-cy="nextStepButton"
          data-static-value={option.staticValue}
          aria-label={option.label || option.title}
        >
          <ChoiceLabel>
            {getOptionLabel(option, { isPreview, localData, userData, serverCallVariables })}
            {isPreview &&
              [STEP_NEXT_VISIBILITY.externalCondition, STEP_NEXT_VISIBILITY.fallback].includes(option.visibility) && (
                <StyledConditionalNextStepIcon data-cy="styledConditionalNextStepIcon" />
              )}
          </ChoiceLabel>
        </NextStepButton>
      ))}
    </Canvas>
  );
};

AiAnswerActionsListStepNexts.propTypes = {
  options: PropTypes.array,
  disabled: PropTypes.bool,
  onStepNextClick: PropTypes.func,
};

export default AiAnswerActionsListStepNexts;
