import isMobile from 'ismobilejs-es5';

function exitFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  }
}

export const openFullscreen = elem => {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.webkitRequestFullscreen) {
    elem.webkitRequestFullscreen();
  } else if (elem.mozRequestFullScreen) {
    elem.mozRequestFullScreen();
  } else if (elem.msRequestFullscreen) {
    elem.msRequestFullscreen();
  }
};

export const fullscreenEnabled = () => {
  if (typeof document === 'undefined') return false;
  if (
    document.fullscreenEnabled ||
    document.webkitFullscreenEnabled ||
    document.mozFullScreenEnabled ||
    document.msFullscreenEnabled
  ) {
    return true;
  }
  return false;
};

export const isFullscreen = () => {
  if (typeof document === 'undefined') return false;
  if (
    document.fullscreenElement ||
    document.webkitFullscreenElement ||
    document.mozFullScreenElement ||
    document.msFullscreenElement
  ) {
    return true;
  }
  return false;
};

export const addOnFullscreenChangeListener = func => {
  document.addEventListener('fullscreenchange', func);
  document.addEventListener('mozfullscreenchange', func);
  document.addEventListener('webkitfullscreenchange', func);
  document.addEventListener('MSFullscreenChange', func);
};

export const removeOnFullscreenChangeListener = func => {
  document.removeEventListener('fullscreenchange', func);
  document.removeEventListener('mozfullscreenchange', func);
  document.removeEventListener('webkitfullscreenchange', func);
  document.removeEventListener('MSFullscreenChange', func);
};

export const closeFullscreen = () => {
  if (
    document.fullscreenElement ||
    document.webkitFullscreenElement ||
    document.mozFullScreenElement ||
    document.msFullscreenElement
  ) {
    exitFullscreen();
  }
};

// export default { openFullscreen, closeFullscreen };
export const getWindowDimensions = userAgent => {
  let width;
  let height;
  if (typeof window === 'undefined') {
    const isMobileDevice = isMobile(userAgent).any;
    width = isMobileDevice ? 480 : 1440;
    height = isMobileDevice ? 640 : 900;
  } else {
    width =
      window.innerWidth && window.document.documentElement.clientWidth
        ? Math.min(window.innerWidth, window.document.documentElement.clientWidth)
        : window.innerWidth ||
          window.document.documentElement.clientWidth ||
          window.document.querySelectorAll('body')[0].clientWidth;
    height = window.innerHeight;
  }
  return { width, height };
};

export const getElementOffset = el => {
  if (typeof window !== 'undefined') {
    const rect = el.getBoundingClientRect();

    return {
      top: rect.top + window.pageYOffset,
      left: rect.left + window.pageXOffset,
    };
  }
  return { top: 0, left: 0 };
};

export const requestAnimationFrame =
  typeof window === 'undefined'
    ? () => {}
    : window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.oRequestAnimationFrame ||
      window.msRequestAnimationFrame;

export const cancelAnimationFrame =
  typeof window === 'undefined'
    ? () => {}
    : window.cancelAnimationFrame ||
      window.webkitCancelAnimationFrame ||
      window.mozCancelAnimationFrame ||
      window.oCancelAnimationFrame ||
      window.msCancelAnimationFrame;
