import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Warning from 'resources/icons/warning.svg';
import Tip from 'resources/icons/tip.svg';
import Tick from 'resources/icons/tickMedium.svg';
import { BaseInput } from './Input.styles';
import { STATUS_TYPES } from './Input.consts';

const Input = ({
  id, // TODO - @Wojtuś/@mateusz what if undefined?
  className,
  value,
  type,
  name,
  label,
  statusMessage, // TODO - @Wojtuś/@mateusz allow for falsy values maybe?
  statusType, // TODO - @Wojtuś/@mateusz allow for falsy values maybe?
  disabled,
  placeholder,
  iconLeft,
  maxLength,
  onChange,
  onBlur,
  onFocus,
  onKeyPress,
  dataCy, // TODO - @Wojtuś/@mateusz what if undefined?
  readOnly,
  required,
}) => {
  const inputId = id && `input-${id}`;
  const statusMessageId = id && `status-message-${id}`;
  const MessageIcon = useMemo(() => {
    if (statusType === STATUS_TYPES.ERROR || statusType === STATUS_TYPES.WARNING) return <Warning />;
    if (statusType === STATUS_TYPES.SUCCESS) return <Tick />;
    if (statusType === STATUS_TYPES.INFO) return <Tip />;
    return null;
  }, [statusType]);

  return (
    <BaseInput.Container className={className}>
      <BaseInput.Label data-cy={dataCy && `${dataCy}Title`} htmlFor={inputId}>
        {label}
        {required && <BaseInput.RequiredLabel className="label-asterisk">*</BaseInput.RequiredLabel>}
      </BaseInput.Label>
      <BaseInput.ElementWrap className="input-wrap" statusType={statusType} disabled={disabled}>
        {iconLeft && <BaseInput.IconWrapLeft>{iconLeft}</BaseInput.IconWrapLeft>}
        <BaseInput.InputElement
          id={inputId}
          required={required}
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          data-cy={dataCy}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyPress={onKeyPress}
          readOnly={readOnly}
          maxLength={maxLength}
          aria-invalid={statusType === STATUS_TYPES.ERROR}
          aria-errormessage={statusType === STATUS_TYPES.ERROR ? statusMessageId : undefined}
          tabIndex="0"
        />
        {MessageIcon && (
          <BaseInput.IconWrapRight className="input-message-icon" statusType={statusType}>
            {MessageIcon}
          </BaseInput.IconWrapRight>
        )}
      </BaseInput.ElementWrap>
      <BaseInput.MessageWrap statusType={statusType} data-cy={dataCy && `${dataCy}Status`}>
        {statusMessage && (
          <span id={statusMessageId} aria-live="assertive">
            {statusMessage}
          </span>
        )}
      </BaseInput.MessageWrap>
    </BaseInput.Container>
  );
};

Input.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  statusMessage: PropTypes.string,
  statusType: PropTypes.oneOf([STATUS_TYPES.ERROR, STATUS_TYPES.INFO, STATUS_TYPES.SUCCESS, STATUS_TYPES.WARNING]),
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  iconLeft: PropTypes.object,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyPress: PropTypes.func,
  dataCy: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
};

export default Input;
