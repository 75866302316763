import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css, useTheme } from 'styled-components';
import Button from '@playerCommon/StandardElements/Button/Button.js';
import Icon from '@playerCommon/StandardElements/Icon';
import callApi from 'helpers/apiHelpers.js';
import ErrorMessage from '@playerCommon/CustomElements/ErrorMessage/ErrorMessage';
import Input, { Textarea, STATUS_TYPES } from '@playerCommon/StandardElements/BaseInputs';

import i18n from 'helpers/i18n';
import TickIconSVG from 'icons/tick.svg';
import { extractBaseData } from 'components/Analytics/recorder';
import useInput from '@playerCommon/hooks/useInput';
import { validateEmail } from 'helpers/validationHelpers';
import { assetsUrl } from 'global/env';
import { blendColors } from 'helpers/colorHelpers';
import PanelCloseButton from '../../components/PanelCloseButton';

const Canvas = styled.div`
  padding: 48px 32px 0 32px;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    padding: 18px 24px 0 24px;
  }

  ${({ mode }) =>
    ['widget', 'embed', 'borderlessembed'].includes(mode) &&
    css`
      padding: 18px 24px 0 24px;
    `}
`;

const Title = styled.div`
  line-height: 1.4;
  font-size: 24px;
  margin-bottom: 40px;
`;

const PositionedErrorMessage = styled(ErrorMessage)`
  margin-top: -15px;
  margin-bottom: 0;
  transform: translateY(calc(-100% - 12px));
  z-index: 0;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

const SendButton = styled(Button)`
  align-self: flex-end;
  margin-bottom: 24px;

  button {
    background: ${props => props.theme.highlightColor};
    border: 1px solid ${props => props.theme.highlightColor};
    ${({ disabled, theme }) =>
      !disabled &&
      css`
        &:hover,
        &:active {
          background: ${blendColors(theme.highlightColor, theme.white, 0.125)};

          color: ${theme.white};
        }
      `}
  }
`;

const focusStyle = css`
  transition: box-shadow 0.2s;
  &:focus-within {
    outline: none;
  }
  body.navigating-with-keyboard &:focus-within {
    box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 4px ${props => props.theme.darkBlue};
  }
`;

const StyledInput = styled(Input)`
  .input-wrap {
    ${focusStyle}
  }
`;

const StyledTextarea = styled(Textarea)`
  .input-wrap {
    ${focusStyle}
  }
`;

const FeedbackSubmitted = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const AddAnotherFeedback = styled(Button)`
  margin-top: 48px;
`;

const SuccessMessage = styled.div`
  font-size: 20px;
  margin-top: 16px;
`;

const TickIcon = styled(TickIconSVG)`
  max-width: 50%;
  margin-top: -32px;
`;

export const Tip = styled(Icon)`
  margin-left: 4px;
  vertical-align: sub;
`;

export const FEEDBACK_TITLE_ID = 'ston-feedback-title';

function Feedback({ stepId, currentExplanationId, moduleWidth, toggleComponent, mode }) {
  const theme = useTheme();
  const [issue, issueOnChange] = useInput('');
  const [email, emailOnChange] = useInput('');
  const [submitStatus, setSubmitStatus] = useState(undefined);
  const [issueError, setIssueError] = useState();
  const [emailError, setEmailError] = useState();

  async function sendFeedback(e) {
    e.preventDefault();
    let valid = true;
    if (!issue) {
      setIssueError(i18n('StepFeedback.IssueError'));
      valid = false;
    }

    if (email && !validateEmail(email)) {
      setEmailError(i18n('StepFeedback.EmailError'));
      valid = false;
    }

    if (!valid) return;

    const baseData = await extractBaseData();

    callApi('v1/stepReaction/issueReport', 'post', {
      reaction: issue,
      reactionType: 'issueReport',
      email: email || undefined,
      stepId,
      originalGuideId: currentExplanationId,
      ...baseData,
    })
      .then(() => setSubmitStatus('success'))
      .catch(() => setSubmitStatus('failure'));
  }

  function newFeedback() {
    issueOnChange({ target: { value: '' } });
    emailOnChange({ target: { value: '' } });
    setIssueError(undefined);
    setEmailError(undefined);
    setSubmitStatus(undefined);
  }

  function closeFeedback() {
    newFeedback();
    toggleComponent();
  }

  useEffect(() => {
    setIssueError(undefined);
  }, [issue]);

  useEffect(() => {
    setEmailError(undefined);
  }, [email]);

  return (
    <Canvas width={moduleWidth} mode={mode}>
      {!submitStatus && (
        <Title data-cy="feedbackTitle" id={FEEDBACK_TITLE_ID}>
          <PanelCloseButton dataCy="feedbackCloseButton" mode={mode} onClick={closeFeedback} />
          {i18n('StepFeedback.FeedbackOnThisStep')}
        </Title>
      )}

      {submitStatus === 'success' && (
        <FeedbackSubmitted>
          <TickIcon />
          <SuccessMessage data-cy="successMessage">
            <PanelCloseButton dataCy="feedbackCloseButton" mode={mode} onClick={closeFeedback} />
            {i18n('StepFeedback.FeedbackSubmitted')}
          </SuccessMessage>
          <AddAnotherFeedback
            dataCy="addAnotherFeedbackButton"
            background="white"
            content={i18n('Global.Close')}
            onClick={closeFeedback}
          />
        </FeedbackSubmitted>
      )}

      {submitStatus === 'failure' && (
        <FeedbackSubmitted>
          <div>{i18n('StepFeedback.Error')}</div>
          <AddAnotherFeedback content="Retry" background="white" onClick={newFeedback}>
            {i18n('StepFeedback.TryAgain')}
          </AddAnotherFeedback>
        </FeedbackSubmitted>
      )}

      {!submitStatus && (
        <Form onSubmit={sendFeedback}>
          <StyledTextarea
            dataCy="commentArea"
            id="commentArea"
            statusType={issueError ? STATUS_TYPES.ERROR : undefined}
            onChange={issueOnChange}
            value={issue}
            placeholder={i18n('StepFeedback.IssuePlaceholder')}
            statusMessage={issueError}
          />
          <StyledInput
            id="email"
            value={email}
            onChange={emailOnChange}
            statusType={emailError ? STATUS_TYPES.ERROR : undefined}
            statusMessage={emailError}
            placeholder={i18n('StepFeedback.EmailPlaceholder')}
            label={
              <>
                {i18n('StepFeedback.EmailTitle')}
                <Tip
                  tooltip={i18n('StepFeedback.EmailTitleTooltip')}
                  positionTooltip="up"
                  color={theme.steel}
                  colorHover={theme.seaweed}
                  src={`${assetsUrl}resources/icons/tipTiny.svg`}
                />
              </>
            }
          />
          <SendButton dataCy="sendButton" content={i18n('StepFeedback.Send')} onClick={sendFeedback} />
        </Form>
      )}
    </Canvas>
  );
}

Feedback.propTypes = {
  stepId: PropTypes.number,
  currentExplanationId: PropTypes.string,
  moduleWidth: PropTypes.number,
  toggleComponent: PropTypes.func,
  mode: PropTypes.string,
};

export default Feedback;
