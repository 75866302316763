import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import CloseButton from '@playerCommon/CustomElements/CloseButton/CloseButton';
import BackSVG from 'icons/listNavBack.svg';

const BackIcon = styled(BackSVG)`
  display: inline-block;
  width: 16px;
  height: 16px;
  /* @noflip */
  [dir='rtl'] & {
    transform: scaleX(-1);
  }
`;

const BackIconWrap = styled.div`
  display: ${({ mode }) => (['widget', 'hc-widget'].includes(mode) ? 'inline-flex' : 'none')};
  margin-right: 16px;
  margin-top: -3px;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  cursor: pointer;

  @media screen and (max-width: 620px) {
    ${({ mode }) =>
      ['hc'].includes(mode) &&
      css`
        display: inline-flex;
      `}
  }
`;

const StyledCloseButton = styled(CloseButton)`
  display: ${({ mode }) => ['widget', 'hc-widget'].includes(mode) && 'none'};
  @media screen and (max-width: 620px) {
    ${({ mode }) =>
      ['hc'].includes(mode) &&
      css`
        display: none;
      `}
  }
`;

function PanelCloseButton({ mode, onClick, dataCy }) {
  return (
    <>
      <StyledCloseButton mode={mode} placement={20} dataCy={dataCy} onClick={onClick} />
      <BackIconWrap mode={mode}>
        <BackIcon onClick={onClick} />
      </BackIconWrap>
    </>
  );
}

PanelCloseButton.propTypes = {
  mode: PropTypes.string,
  onClick: PropTypes.func,
  dataCy: PropTypes.string,
};

export default memo(PanelCloseButton);
