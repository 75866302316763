export const filePrompt = (inputProps = {}, inputWrapperRef) =>
  new Promise((resolve, reject) => {
    let inputElement = document.createElement('input');
    inputElement.style.display = 'none';
    Object.assign(inputElement, inputProps);

    const removeInputElement = () => {
      if (inputWrapperRef?.current) inputElement.remove();
    };

    const addFileProxy = e => {
      const { files } = e.target;
      if (files.length > 0) {
        resolve(files);
      } else {
        reject();
      }
      removeInputElement();
      inputElement = undefined;
    };

    inputElement.type = 'file';
    inputElement.addEventListener('change', addFileProxy);
    inputElement.addEventListener('cancel', removeInputElement);
    if (inputWrapperRef?.current) inputWrapperRef?.current.append(inputElement);
    setTimeout(() => {
      inputElement.click();
    }, 0);
  });

export function getImageUrl(illustration) {
  return `${illustration.path}/${illustration.imageId}.${illustration.ext}${
    illustration.imageIdSecureHash ? `?s=${illustration.imageIdSecureHash}` : ''
  }`;
}

export function getImageSrcSet(image, image2x, image3x) {
  const srcset = [];
  if (image && image) srcset.push(`${image.url} 1x`);
  if (image2x && image2x.url) srcset.push(`${image2x.url} 2x`);
  if (image3x && image3x.url) srcset.push(`${image3x.url} 3x`);
  return srcset.join(',');
}

export function convertExtensionToLowerCase(filename) {
  return filename.replace(/(\.[^.]+)$/, match => match.toLowerCase());
}

export async function checkIfFileExistsAtUrl(url) {
  const res = await fetch(url, { method: 'HEAD' });

  if (res.status === 200) {
    return true;
  }
  return false;
}

export function isMediaFileVideo(url) {
  return /\.gif|\.mp4/gi.test(url);
}

export function convertVideoMediaUrl(url) {
  if (/\.gif/gi.test(url)) {
    return `${url}&fm=mp4`;
  }
  return url;
}
