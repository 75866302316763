import React from 'react';
import PropTypes from 'prop-types';

const UnsafeHtml = ({ text, className, dataCy, ...props }) => (
  <div className={className} dangerouslySetInnerHTML={{ __html: text }} data-cy={dataCy} {...props} />
);

UnsafeHtml.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
};

export default UnsafeHtml;
