import styled from 'styled-components';
import TextLink from '@playerCommon/StandardElements/TextLink';
import ChatBubbleSVG from 'icons/chat-bubble-16.svg';

export const Canvas = styled.div``;

export const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

export const AllConversationsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const AllConversationsLink = styled(TextLink)`
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

export const ChatBubbleIcon = styled(ChatBubbleSVG)`
  path {
    fill: ${props => props.theme.charcoal};
  }
`;

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
  padding-top: 4px;
  max-height: 600px;
  overflow: scroll;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  & {
    -ms-overflow-style: none;
  }
  & {
    overflow: -moz-scrollbars-none;
  }
`;
