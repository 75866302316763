import styled, { css } from 'styled-components';
import { STATUS_TYPES } from './Input.consts';

const Container = styled.div``;

const ElementWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.grey};
  background: ${({ theme }) => theme.white};
  border-radius: 4px;
  margin-top: 8px;

  ${({ theme, statusType }) =>
    (statusType === STATUS_TYPES.ERROR &&
      css`
        border-color: ${theme.errorColor};
      `) ||
    (statusType === STATUS_TYPES.WARNING &&
      css`
        border-color: ${theme.mango};
      `) ||
    (statusType === STATUS_TYPES.SUCCESS &&
      css`
        border-color: ${theme.seaweed};
      `) ||
    (statusType === STATUS_TYPES.INFO &&
      css`
        border-color: ${theme.lightblue};
      `)}

  ${({ theme, disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      background: ${theme.paleGrey};
      > input {
        color: ${theme.lightGrey};
      }
      path {
        fill: ${theme.lightGrey};
      }
    `}
`;

const InputElement = styled.input`
  font-size: 14px;
  line-height: 24px;
  font-family: inherit;
  color: ${({ theme }) => theme.darkGrey};
  width: 100%;
  min-width: 0;
  border: 0;
  outline: 0;
  padding: 0;
  margin: 0;
  background: transparent;
  appearance: none;
  padding: 8px 12px;

  &::placeholder {
    color: ${({ theme }) => theme.lightGrey};
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  width: 16px;
  height: 16px;
  & svg path,
  svg rect {
    ${({ theme, statusType, disabled }) =>
      (statusType === STATUS_TYPES.ERROR &&
        css`
          fill: ${theme.errorColor};
        `) ||
      (statusType === STATUS_TYPES.WARNING &&
        css`
          fill: ${theme.mango};
        `) ||
      (statusType === STATUS_TYPES.INFO &&
        css`
          fill: ${theme.lightblue};
        `) ||
      (statusType === STATUS_TYPES.SUCCESS &&
        css`
          fill: ${theme.seaweed};
        `) ||
      (disabled &&
        css`
          fill: ${theme.lightGrey};
        `)}
  }
`;

const IconWrapRight = styled(IconWrap)`
  margin: 12px 12px 12px 0;
  color: ${({ theme }) => theme.steel};
  ${({ theme, disabled }) =>
    disabled &&
    css`
      color: ${theme.lightGrey};
    `}
`;

const Label = styled.label`
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.steel};
`;

const RequiredLabel = styled.span`
  color: ${({ theme }) => theme.pink};
  margin-left: 2px;
`;

const MessageWrap = styled.div`
  font-size: 12px;
  line-height: 20px;
  min-height: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.darkGrey};
  margin-top: 2px;

  ${({ theme, statusType }) =>
    (statusType === STATUS_TYPES.ERROR &&
      css`
        color: ${theme.errorColor};
      `) ||
    (statusType === STATUS_TYPES.SUCCESS &&
      css`
        color: ${theme.seaweed};
      `)}
`;

const IconWrapDropdown = styled(IconWrap)`
  height: 8px;
  width: 8px;
  padding: 16px 16px 16px 0;
  & svg path {
    fill: ${({ theme }) => theme.slateGrey};
  }
`;

export const BaseInput = {
  Container,
  InputElement,
  ElementWrap,
  Label,
  RequiredLabel,
  MessageWrap,
  IconWrap,
  IconWrapRight,
  IconWrapDropdown,
};
