import { partition } from 'helpers/arrayManagement';
import { createPath, isPathStartsWith } from './helpers';

export const resolveRowUuidList = state => state.rowUuidList || [];

export const resolveDraggingUuid = state => state.draggingUuid;

export const resolveDraggingFolderFullPath = state => state.draggingFolderFullPath;

export const resolveIsPathTemporaryOpened = (state, path, uuid) => {
  const pathToCheck = uuid ? createPath(path, uuid) : path;
  if (state.temporaryOpenedPath[pathToCheck]) {
    // temprorary open path (e.g. when jumping around between search result items)
    return true;
  }
  return false;
};

export const resolveIsPathStaticOpened = (state, path, uuid) => {
  const pathToCheck = uuid ? createPath(path, uuid) : path;

  if (state.mainPathName === pathToCheck) {
    // root path cannot be closed (the tree would disappear)
    return true;
  }
  if (state.staticOpenedPath[pathToCheck]) {
    // regular opened path
    return true;
  }

  return false;
};

export const resolveIsPathOpen = (state, path, uuid) => {
  return resolveIsPathStaticOpened(state, path, uuid) || resolveIsPathTemporaryOpened(state, path, uuid);
};

export const resolveActiveUuidList = state => {
  return state.activeUuidList || [];
};

export const resolveIsUuidActive = (state, uuid) => {
  return resolveActiveUuidList(state).includes(uuid);
};

export const resolveSelectedUuidList = state => {
  return state.selectedUuidList || [];
};

export const resolveIsUuidSelected = (state, uuid) => {
  return resolveSelectedUuidList(state).includes(uuid);
};

export const resolveUuidHighlightColor = (state, uuid) => {
  return state.highlightColorByUuid[uuid];
};

export const resolveHasPathActiveUuid = (state, path, uuid) => {
  const pathToCheck = uuid ? createPath(path, uuid) : path;

  return state.activePathList.some(activePath => isPathStartsWith(activePath, pathToCheck));
};

export const resolveHasPathSelectedUuid = (state, path, uuid) => {
  const pathToCheck = uuid ? createPath(path, uuid) : path;

  return state.selectedPathList.some(selectedPath => isPathStartsWith(selectedPath, pathToCheck));
};

export const resolveStaticOpenedPath = state => {
  return Object.entries(state.staticOpenedPath)
    .filter(([path, isOpen]) => !!isOpen)
    .reduce(
      (acc, [path]) => {
        acc[path] = true; // #perf mutation
        return acc;
      },
      state.mainPathName ? { [state.mainPathName]: true } : {} // keep mainPathName opened
    );
};

export const resolveRowByUuid = (state, uuid) => state.rowByUuid[uuid] || {};

export const resolveRowList = state => resolveRowUuidList(state).map(uuid => resolveRowByUuid(state, uuid));

export const resolveVisibleRowList = state =>
  resolveRowList(state).filter(rowDef => resolveIsPathOpen(state, rowDef.path));

export const resolveVisibleRowUuidList = state => {
  return resolveVisibleRowList(state).map(rowDef => rowDef.uuid);
};

export const resolveRowChildrenListByExactPath = (state, path) =>
  resolveRowList(state).filter(item => item.path === path);

export const resolveFullPathForFolderUuid = (state, uuid) => {
  const row = resolveRowByUuid(state, uuid);
  return row.isFolder ? createPath(row.path, uuid) : undefined;
};

export const resolvePathForUuid = (state, uuid) => {
  return resolveRowByUuid(state, uuid).path;
};

export const resolveFolderDescendantList = (state, folderUuid) => {
  const folderFullPath = resolveFullPathForFolderUuid(state, folderUuid);
  return folderFullPath ? resolveRowList(state).filter(item => isPathStartsWith(item.path, folderFullPath)) : [];
};

export const resolveChildListByUuid = (state, uuid) =>
  resolveRowChildrenListByExactPath(state, resolveFullPathForFolderUuid(state, uuid));

export const resolveSiblingListByUuid = (state, uuid) =>
  resolveRowChildrenListByExactPath(state, resolvePathForUuid(state, uuid));

/* Split elements into item family (that means item itself and all it's descendants. No siblings) */
export const resolveFamilyUuidPartition = (state, uuid) => {
  const { isFolder, path } = resolveRowByUuid(state, uuid);

  const [familyUuidList, otherUuidList] = partition(
    state.rowUuidList,
    isFolder
      ? rowUuid => rowUuid === uuid || isPathStartsWith(resolveRowByUuid(state, rowUuid).path, createPath(path, uuid))
      : rowUuid => rowUuid === uuid // if not folder, then only the elements itself goes to "family" partition
  );

  return [familyUuidList, otherUuidList];
};

export const resolveInitializationKey = state => state.initializationKey; // #docForTreeInitializationKey

export const resolveIsAllStaticallyExpanded = state => {
  return resolveRowUuidList(state).every(uuid => {
    const pathForUuid = resolvePathForUuid(state, uuid);
    return resolveIsPathStaticOpened(state, pathForUuid);
  });
};

export const resolveIsAllStaticallyCollapsed = state => {
  const staticallyOpenedPathList = Object.keys(resolveStaticOpenedPath(state));

  if (staticallyOpenedPathList.length > 1) {
    return false;
  }

  if (!staticallyOpenedPathList.length) {
    return true;
  }

  // If only one is opened, then if it's not root means that something is manually expanded
  return state.mainPathName ? staticallyOpenedPathList[0] === state.mainPathName : false;
};
