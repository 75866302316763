import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import callApi from 'helpers/apiHelpers.js';
import { useTranslation } from 'react-i18next';
import { validateEmptyString } from 'helpers/validationHelpers.js';
import Button from '@playerCommon/StandardElements/Button/Button.js';
import Alert from '@playerCommon/StandardElements/Alert';
import useForm from '@playerCommon/StandardElements/BaseInputs/useForm';
import { STATUS_TYPES } from '@playerCommon/StandardElements/BaseInputs';
import PasswordHintsTooltip from '@playerCommon/Authentication/PasswordHints/PasswordHintsTooltip';
import { validatePasswordHints } from '@playerCommon/Authentication/PasswordHints/passwordHints.helpers';
import { defaultPasswordHintIsValid } from '@playerCommon/Authentication/PasswordHints/passwordHints.consts';
import { InputPassword } from '@playerCommon/ui/components/inputs/InputPassword';
import { ColumnFlex } from '@playerCommon/ui/components/Flex';
import { AUTHENTICATION_VIEWS } from '../Authentication.consts';

import { Canvas, Top, Title, SubTitle, Switch, AuthenticationButton, TextLink } from '../Authentication.styles.js';

const StyledButton = styled(Button)`
  margin: 40px 0 8px 0;
`;

const StyledAlert = styled(Alert)`
  margin-top: 32px;
`;

const ResetPassword = ({ setAuthenticationView, token }) => {
  const { t } = useTranslation();
  const [changePasswordError, setChangePasswordError] = useState('');
  const [tokenInvalid, setTokenInvalid] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [passwordHints, setPasswordHints] = useState(defaultPasswordHintIsValid);

  const initialFormState = {
    newPassword: '',
    verifyPassword: '',
  };

  const validations = [
    ({ newPassword }) =>
      Object.values(validatePasswordHints(newPassword)).every(Boolean) || {
        newPassword: t('Registration.PasswordError'),
      },
    ({ newPassword }) =>
      validateEmptyString(newPassword) || {
        newPassword: t('Registration.RequiredFieldError'),
      },
    ({ verifyPassword, newPassword }) =>
      newPassword === verifyPassword || { verifyPassword: t('SignIn.ResetPasswordErrorNotMatch') },
  ];
  const {
    formValues,
    formErrors,
    handleFormChange,
    handleWillSubmit,
    handleFormBlur,
    handleFormFocus,
    isFormValid,
    formStatus,
  } = useForm({ initialFormState, validations });

  const resetPasswordSubmit = e => {
    e.preventDefault();
    handleWillSubmit();

    if (!isFormValid) return;

    callApi('v1/auth/password/reset', 'post', {
      token,
      newPassword: formValues.newPassword,
      verifyPassword: formValues.verifyPassword,
    })
      .then(() => {
        setPasswordChanged(true);
        setChangePasswordError('');
      })
      .catch(() => {
        setChangePasswordError(t('Registration.ServerError'));
      });
  };

  useEffect(() => {
    callApi(`v1/auth/password/token?token=${token}`, 'get')
      .then(() => {})
      .catch(() => {
        setTokenInvalid(true);
      });
  }, []);

  const confirmNewPasswordStatus = useMemo(() => {
    if (formErrors.newPassword) return null;
    if (!formErrors.verifyPassword) {
      return {
        type: STATUS_TYPES.SUCCESS,
        message: '',
      };
    }

    return {
      type: STATUS_TYPES.ERROR,
      message: formErrors.verifyPassword,
    };
  }, [formErrors.newPassword, formErrors.verifyPassword]);

  return (
    <Canvas>
      <Top>
        <Title>{t('SignIn.ResetTitle')}</Title>
      </Top>
      {!tokenInvalid && !passwordChanged && <SubTitle>{t('SignIn.ResetPasswordHeader')}</SubTitle>}
      {tokenInvalid && !passwordChanged && (
        <>
          <StyledAlert severity="error">{t('SignIn.ResetTokenInvalid')}</StyledAlert>
          <StyledButton
            onClick={() => setAuthenticationView(AUTHENTICATION_VIEWS.PASSWORD_RECOVERY)}
            background="filledGreyBorder"
            type="bigText"
            fullWidth
            content={t('SignIn.ResetTokenInvalidLink')}
          />
        </>
      )}
      {!tokenInvalid && !passwordChanged && (
        <form id="loginForm" onSubmit={resetPasswordSubmit}>
          {changePasswordError && (
            <StyledAlert severity="error" dataCy="changePasswordError">
              {changePasswordError}
            </StyledAlert>
          )}
          <ColumnFlex marginTop={2} gap={2}>
            <InputPassword
              name="newPassword"
              status={formStatus.newPassword.type}
              message={formStatus.newPassword.message}
              value={formValues.newPassword}
              onChange={event => {
                handleFormChange(event);
                setPasswordHints(validatePasswordHints(event.target.value));
              }}
              placeholder={t('SignIn.ResetPasswordPlaceholder')}
              label={t('SignIn.ResetNewPasswordTitle')}
              data-cy="passwordField"
              onFocus={handleFormFocus}
              onBlur={handleFormBlur}
              tooltip={<PasswordHintsTooltip passwordHints={passwordHints} />}
              autoComplete="new-password"
            />
            <InputPassword
              name="verifyPassword"
              status={confirmNewPasswordStatus?.type}
              message={confirmNewPasswordStatus?.message}
              value={formValues.verifyPassword}
              onChange={handleFormChange}
              placeholder={t('SignIn.ResetPasswordPlaceholder')}
              label={t('SignIn.ResetNewPasswordRepeatTitle')}
              data-cy="passwordField"
              onFocus={handleFormFocus}
              onBlur={handleFormBlur}
              autoComplete="new-password"
            />
          </ColumnFlex>
          <AuthenticationButton
            form="loginForm"
            usage="submit"
            type="bigText"
            fullWidth
            content={t('SignIn.ResetButtonTitle')}
            value="Submit"
            dataCy="reset"
          />
        </form>
      )}
      {passwordChanged && (
        <>
          <StyledAlert severity="success">{t('SignIn.ResetPasswordChanged')}</StyledAlert>
          <StyledButton
            link="/authentication"
            onClick={() => setAuthenticationView(AUTHENTICATION_VIEWS.SIGN_IN)}
            background="filledGreyBorder"
            type="bigText"
            fullWidth
            content={t('Global.SignIn')}
          />
        </>
      )}
      {!passwordChanged && (
        <Switch>
          {t('SignIn.ResetTitleIRemember')}
          <TextLink onClick={() => setAuthenticationView(AUTHENTICATION_VIEWS.SIGN_IN)} data-cy="signIn">
            &nbsp;
            {t('SignIn.Title')}
          </TextLink>
        </Switch>
      )}
    </Canvas>
  );
};

ResetPassword.propTypes = {
  setAuthenticationView: PropTypes.func,
  token: PropTypes.string,
};

export default ResetPassword;
