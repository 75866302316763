import React, { memo } from 'react';
import styled from 'styled-components';
import Icon from '@playerCommon/StandardElements/Icon';
import PropTypes from 'prop-types';

const Canvas = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 16px 0 24px 0;
  width: 100%;
`;

const Content = styled.div`
  font-size: 14px;
  color: ${props => props.theme.darkGrey};
`;

const Title = styled.div`
  font-size: 20px;
  margin-bottom: 8px;
  margin-top: 24px;
  color: ${props => props.theme.darkGrey};
`;

const EmptyState = ({ imageSrc, content, className, title }) => (
  <Canvas className={className}>
    <Icon src={imageSrc} />
    {title && <Title>{title}</Title>}
    <Content data-cy="emptyStateContent">{content}</Content>
  </Canvas>
);

EmptyState.propTypes = {
  className: PropTypes.string,
  imageSrc: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.string,
};

export default memo(EmptyState);
