import { WIDGET_PLACEMENTS } from 'components/consts';
import getStepTheme, { stepsContentPaddings } from './stepsTheme';

const contentCanvas = {
  contentCanvasPaddingBottom: '68px',
  contentCanvasPaddingBottomMobile: '60px',
};

const getCompactIllustration = (isModal = false) => ({
  compactIllustrationTopMargin: isModal ? '-12px' : '-4px',
  // icon
  compactIllustrationIconHeight: isModal ? '200px' : '152px',
  compactIllustrationIconVerticalMargin: isModal ? '-24px' : '-8px',
  // video and code
  compactIllustrationVideoCodeVerticalMargin: isModal ? '-24px' : '-8px',
  compactIllustrationBorderRadius: isModal ? 'unset' : '4px',
});

const nextStepSelector = {
  nextStepSelectorMarginTop: '16px',
  nextStepSelectorButtonsTypeMarginTop: '24px',
  nextStepSelectorButtonMarginBottom: '8px',
};

const contentWrap = {
  contentWrapSubDesktopPadding: '16px',
  contentWrapSubDesktopPaddingBottom: '24px',
  contentWrapSubDesktopScrollableVerticalPadding: 64,
  contentWrapSubDesktopNoIllustrationPaddingTop: '20px',
  contentWrapMobileNoIllustrationPaddingTop: '20px',
};

const buttonStyles = {
  buttonTextHeight: '28px',
  buttonTextVerticalPadding: '16px',
  buttonTextFontSize: '11px',
  buttonIconHeight: '28px',
  buttonIconWidth: '28px',
  buttonIconIconSize: '14px',
  backButtonMarginRight: '12px',
};

const playerStyles = {
  playerFontSize: '14px',
  playerFontLineHeight: '22px',
  playerTitleFontSize: '24px',
  playerTitleLineHeight: '30px',
  playerTitleMarginBottom: '4px',
  playerListPaddingLeft: '16px',
  playerListMarginVertical: '12px',
  playerListItemMarginVertical: '4px',
  // steps styles
  // playerContentWrapPadding:
  // main content styles
  playerMainContentMarginBottom: '24px',
  // heading styles
  playerHeadingLargeFontSize: '22px',
  playerHeadingLargeLineHeight: '28px',
  playerHeadingLargeMarginTop: '12px',
  playerHeadingLargeMarginBottom: '8px',
  playerHeadingMediumFontSize: '18px',
  playerHeadingMediumLineHeight: '22px',
  playerHeadingMediumMarginTop: '8px',
  playerHeadingMediumMarginBottom: '8px',
  playerHeadingSmallFontSize: '16px',
  playerHeadingSmallLineHeight: '20px',
  playerHeadingSmallMarginTop: '4px',
  playerHeadingSmallMarginBottom: '4px',
  // additional info styles
  playerAdditionalInfoFontSize: '14px',
  playerAdditionalInfoLineHeight: '22px',
  playerAdditionalInfoListPaddingLeft: '16px',
  // table styles
  playerTableFontSize: '14px',
  playerTableLineHeight: '22px',

  // mobile styles
  playerMobileFontSize: '14px',
  playerMobileFontLineHeight: '22px',
  playerMobileTitleFontSize: '20px',
  playerMobileTitleLineHeight: '28px',
  playerMobileTitleMarginBottom: '4px',
  playerMobileListPaddingLeft: '16px',
  // mobile heading styles
  playerMobileHeadingLargeFontSize: '22px',
  playerMobileHeadingLargeLineHeight: '28px',
  playerMobileHeadingLargeMarginTop: '12px',
  playerMobileHeadingLargeMarginBottom: '8px',
  playerMobileHeadingMediumFontSize: '18px',
  playerMobileHeadingMediumLineHeight: '22px',
  playerMobileHeadingMediumMarginTop: '8px',
  playerMobileHeadingMediumMarginBottom: '8px',
  playerMobileHeadingSmallFontSize: '16px',
  playerMobileHeadingSmallLineHeight: '20px',
  playerMobileHeadingSmallMarginTop: '4px',
  playerMobileHeadingSmallMarginBottom: '4px',
};

const aiStep = {
  aiAnswerInputBottomTop: '16px',
  aiAnswerInputBottomPadding: '16px',
  playerMobileAiAnswerInputBottomTop: '16px',
  playerMobileAiAnswerInputBottomPadding: '16px',
  aiAnswerInputFontSize: '14px',
  aiAnswerInputLineHeight: '24px',
  playerMobileAiAnswerInputFontSize: '14px',
  playerMobileAiAnswerInputLineHeight: '24px',
  // ai answer styles
  aiAnswerAnswerFontSize: '14px',
  aiAnswerAnswerLineHeight: '24px',
  aiAnswerButtonFontSize: '14px',
  aiAnswerButtonLineHeight: '24px',
  aiAnswerButtonPadding: '6px 16px',
  // ai answer styles mobile
  playerMobileAiAnswerAnswerFontSize: '14px',
  playerMobileAiAnswerAnswerLineHeight: '24px',
  playerMobileAiAnswerButtonPadding: '6px 16px',
};

const specialStepBackButton = {
  marginTop: '-8px',
  marginBottom: '16px',

  subDesktop: {
    marginTop: '-8px',
    marginBottom: '16px',
    noIllustration: {
      marginTop: '-12px',
      marginBottom: '8px',
    },
  },
  mobile: {
    marginTop: '-4px',
    marginBottom: '20px',
    noIllustration: {
      marginTop: '-8px',
      marginBottom: '12px',
    },
  },
};

const specialStepBackButtonInModal = {
  marginTop: '0px',
  marginBottom: '20px',

  subDesktop: {
    marginTop: '0px',
    marginBottom: '48px',
    noIllustration: {
      marginTop: '-16px',
      marginBottom: '20px',
    },
  },
  mobile: {
    marginTop: '0px',
    marginBottom: '48px',
    noIllustration: {
      marginTop: '-8px',
      marginBottom: '20px',
    },
  },
};

export const getCompactStepsTheme = ({ widgetPlacement }) => {
  const stepsTheme = getStepTheme();
  const isModal = widgetPlacement === WIDGET_PLACEMENTS.MODAL;
  const widgetLightStyles = stepsContentPaddings.widget.light;
  if (isModal) {
    return {
      ...stepsTheme,
      ...getCompactIllustration(true),
      contentWrapSubDesktopScrollableVerticalPadding: contentWrap.contentWrapSubDesktopScrollableVerticalPadding,
      specialStepBackButton: specialStepBackButtonInModal,
      stepsContentPaddings: {
        mediaPadding: widgetLightStyles.modal.mediaPadding,
        contentPadding: widgetLightStyles.modal.contentPadding,
      },
    };
  }
  return {
    ...stepsTheme,
    ...contentCanvas,
    ...getCompactIllustration(),
    ...nextStepSelector,
    ...contentWrap,
    ...buttonStyles,
    ...playerStyles,
    ...aiStep,
    specialStepBackButton,
    stepsContentPaddings: {
      mediaPadding: widgetLightStyles.cornerWindow.mediaPadding,
      contentPadding: widgetLightStyles.cornerWindow.contentPadding,
    },
  };
};
