import { getGlobal } from 'global/windowUtils';

const getAiAnswerPathKey = guideId => {
  const widgetId = getGlobal('widgetRuleId');
  let key = `stonly_ai_answer_paths_${guideId}`;
  if (widgetId) {
    key += `_${widgetId}`;
  }
  return key;
};

const getPaths = guideId => {
  const key = getAiAnswerPathKey(guideId);
  return JSON.parse(window.localStorage.getItem(key)) || [];
};

const setPaths = (guideId, paths) => {
  const key = getAiAnswerPathKey(guideId);
  window.localStorage.setItem(key, JSON.stringify(paths));
};

const removePaths = guideId => {
  const key = getAiAnswerPathKey(guideId);
  window.localStorage.removeItem(key);
};

export const savePathToAiAnswer = (guideId, path) => {
  if (typeof window !== 'undefined') {
    const paths = getPaths(guideId);
    if (!paths.includes(path)) {
      paths.push(path);
      setPaths(guideId, paths);
    }
  }
};

export const getPathToAiAnswer = guideId => {
  if (typeof window !== 'undefined') {
    const paths = getPaths(guideId);
    return paths[paths.length - 1] || null;
  }
  return null;
};

export const removePathToAiAnswer = (guideId, isRemoveAll = false) => {
  if (typeof window !== 'undefined') {
    if (isRemoveAll) {
      return removePaths(guideId);
    }
    const paths = getPaths(guideId);
    paths.pop();
    if (paths.length) {
      setPaths(guideId, paths);
    } else {
      removePaths(guideId);
    }
  }
};

export const isPathInsideAiAnswer = (currentPath, guideId) => {
  const path = getPathToAiAnswer(guideId);
  return path ? !path.includes(currentPath) : false;
};

// TEMP (hopefully): replaces the br tags that make the response ugly
export const replaceUglyBrTags = answer => {
  return answer.replaceAll(/<br\s*?\/?>\s*?(?=<li>|<\/(?:ol|ul)>)/gim, '');
};

const SPLIT_DELIMITER = '|||SPLIT_DELIMITER|||';

export const splitAnswerIntoParts = answer => {
  try {
    const regex = '(?<=</(?:p|ul|ol)>)(?:<br\\s*/?>|\\s)*?(?=<(?:p|ul|ol)>)';
    return answer.split(new RegExp(regex, 'gim'));
  } catch {
    return answer
      .replaceAll(
        /(?<endTag><\/(?:p|ul|ol)>)(?:<br\s*\/?>|\s)*?(?<startTag><(?:p|ul|ol)>)/gim,
        `$<endTag>${SPLIT_DELIMITER}$<startTag>`
      )
      .split(SPLIT_DELIMITER);
  }
};
