export const PASSWORD_HINT = {
  NUMBER_OF_CHARACTERS: 'NUMBER_OF_CHARACTERS',
  HAS_LETTER: 'HAS_LETTER',
  HAS_NUMBER: 'HAS_NUMBER',
  HAS_UPPER_AND_LOWER_CASE: 'HAS_UPPER_AND_LOWER_CASE',
};

export const defaultPasswordHintIsValid = {
  [PASSWORD_HINT.NUMBER_OF_CHARACTERS]: false,
  [PASSWORD_HINT.HAS_LETTER]: false,
  [PASSWORD_HINT.HAS_NUMBER]: false,
  [PASSWORD_HINT.HAS_UPPER_AND_LOWER_CASE]: false,
};

export const passwordHintNames = {
  [PASSWORD_HINT.NUMBER_OF_CHARACTERS]: 'Registration.PasswordHintLength',
  [PASSWORD_HINT.HAS_LETTER]: 'Registration.PasswordHintLetter',
  [PASSWORD_HINT.HAS_NUMBER]: 'Registration.PasswordHintNumber',
  [PASSWORD_HINT.HAS_UPPER_AND_LOWER_CASE]: 'Registration.PasswordHintUpperAndLowerCase',
};
