import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import SearchIconSVG from 'icons/search.svg';
import SearchBar from '../../SearchBar';

export const HeaderSearchCanvas = styled.div`
  z-index: 1;
  color: ${props => props.theme.slateGrey};
  display: none;
  align-items: center;
  flex-shrink: 0;
  position: absolute;
  width: 100%;
  height: 60px;
  top: 0px;
  left: 0;
  padding: 0 16px;
  background: white;
  transition: opacity 0.2s, visibility 0.2s, transform 0.2s;
  opacity: 0;
  visibility: hidden;
  /* transform: translate3d(0, -24px, 0); */
  align-items: center;

  @media screen and (max-width: 1024px) {
    display: flex;

    ${({ open }) =>
      open &&
      css`
        opacity: 1;
        visibility: visible;
        /* transform: translate3d(0, 0, 0); */
      `}
  }

  @media screen and (max-width: 480px) {
    padding: 0 16px;
  }
`;

const SearchIcon = styled(SearchIconSVG)`
  display: inline-block;
  width: 16px;
  height: 16px;

  path {
    fill: currentColor;
  }
`;

const SearchButton = styled.div`
  display: none;
  position: relative;
  height: 24px;
  width: 24px;
  margin-right: 16px;
  margin-left: auto;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    display: inline-flex;
  }

  ${({ noMarginRight }) =>
    noMarginRight &&
    css`
      margin-right: 0;
    `}
`;

export const SearchBarSmallHeader = styled(SearchBar)`
  margin-left: 0;
  margin-right: 0;
  transition: opacity 0.2s, visibility 0.2s, transform 0.2s;
  /* transform: translate3d(0, 20%, 0); */
  display: none;
  @media screen and (max-width: 1024px) {
    display: block;
  }
`;

function HeaderSearch({ helpcenterData, noMarginRight, onContactFormClick }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const onClearSearchMemoized = useCallback(() => setOpen(false), []);
  const { knowledgeBase } = helpcenterData;

  const handleKeyDown = e => {
    if (e.key === ' ' || e.key === 'Enter') {
      setOpen(true);
    }
  };

  return (
    <>
      <SearchButton
        noMarginRight={noMarginRight}
        role="button"
        onClick={() => setOpen(true)}
        aria-expanded={open}
        aria-label={t('Global.Search')}
        onKeyDown={handleKeyDown}
        tabIndex={0}
      >
        <SearchIcon />
      </SearchButton>
      <HeaderSearchCanvas open={open} aria-hidden={!open}>
        <SearchBarSmallHeader
          helpcenterData={helpcenterData}
          placeholder={knowledgeBase.searchInputText}
          mode="mobile"
          onClearSearch={onClearSearchMemoized}
          onContactFormClick={onContactFormClick}
        />
      </HeaderSearchCanvas>
    </>
  );
}

HeaderSearch.propTypes = {
  helpcenterData: PropTypes.object,
  noMarginRight: PropTypes.bool,
  onContactFormClick: PropTypes.func,
};

export default HeaderSearch;
