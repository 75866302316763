import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FILE_SIZES } from 'global';
import FormFileInput from '@playerCommon/ComplexElements/ContactForm/Form/FormFileInput/FormFileInput';
import AttachmentIconSVG from 'icons/attachmentIcon.svg';
import CrossSVG from 'icons/crossMedium.svg';

const ErrorMessage = styled.div`
  margin-top: 2px;
  color: ${({ theme }) => theme.errorColor};
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  min-height: 20px;
`;

const AttachmentIcon = styled(AttachmentIconSVG)`
  height: 16px;
  width: 16px;
  margin-right: 12px;
  flex-shrink: 0;
  path {
    fill: ${props => props.theme.lightGrey};
  }
`;

const FilesList = styled.div`
  margin-top: 8px;
`;

const FileRemove = styled(CrossSVG)`
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-left: auto;
  opacity: 0;
  transition: opacity 0.2s;
  cursor: pointer;

  path {
    fill: ${props => props.theme.slateGrey};
  }
`;

const File = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  font-size: 14px;
  line-height: 16px;
  color: ${props => props.theme.slateGrey};

  &:hover {
    ${FileRemove} {
      opacity: 1;
    }
  }
`;

function parseFileSize(number) {
  if (number < FILE_SIZES.ONE_KILOBYTE) {
    return `${number}bytes`;
  }
  if (number >= FILE_SIZES.ONE_KILOBYTE && number < FILE_SIZES.ONE_MEGABYTE) {
    return `${(number / FILE_SIZES.ONE_KILOBYTE).toFixed(1)}KB`;
  }
  if (number >= FILE_SIZES.ONE_MEGABYTE) {
    return `${(number / FILE_SIZES.ONE_MEGABYTE).toFixed(1)}MB`;
  }
  return 'unknown size';
}

const StepInputAttachment = ({
  id,
  onChangeValue,
  fileList,
  message,
  status,
  placeholder,
  label,
  required,
  isPreview,
}) => {
  const { t } = useTranslation();
  const [fileUploadError, setFileUploadError] = useState(false);
  const errorMessageId = id && `error-message-${id}`;
  const inputId = id && `input-${id}`;
  const totalFilesSize = Array.isArray(fileList) && fileList.reduce((acc, cur) => acc + cur.size, 0);

  const handleFileDrop = f => {
    const filesToAppend = [];
    [...f].forEach(file => {
      if (totalFilesSize + file.size > FILE_SIZES.TEN_MEGABYTES) {
        setFileUploadError(t('Validation.FileSizeTooBig'));
        return;
      }
      setFileUploadError(false);
      if (!fileList.find(existingFile => existingFile.name === file.name)) {
        filesToAppend.push(file);
      }
    });
    onChangeValue([...fileList, ...filesToAppend]);
  };

  const handleFileRemove = f => {
    onChangeValue(fileList.filter(file => file !== f));
  };

  return (
    <>
      <FormFileInput
        id={inputId}
        onFile={handleFileDrop}
        disabled={isPreview || totalFilesSize >= FILE_SIZES.TEN_MEGABYTES}
        placeholder={placeholder}
        label={label}
        required={required}
        aria-invalid={status === 'error'}
        aria-errormessage={status === 'error' ? errorMessageId : undefined}
      />
      <FilesList>
        {fileList.length > 0 &&
          fileList.map(file => (
            <File key={file.name}>
              <AttachmentIcon />
              {file.name} {parseFileSize(file.size)}
              <FileRemove onClick={() => handleFileRemove(file)} />
            </File>
          ))}
      </FilesList>
      <ErrorMessage data-cy="contactFormAttachmentsError">
        {(message || fileUploadError) && (
          <span id={errorMessageId} aria-live="assertive">
            {fileUploadError || message}
          </span>
        )}
      </ErrorMessage>
    </>
  );
};

StepInputAttachment.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChangeValue: PropTypes.func,
  fileList: PropTypes.array,
  error: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  isPreview: PropTypes.bool,
};

export default StepInputAttachment;
