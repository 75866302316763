import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import i18n from 'helpers/i18n';
import { onKeyPressToOnClick } from 'helpers/a11y';
import { getGlobal } from 'global/windowUtils';
import { navigationWithKeyboardStyle } from '@playerCommon/CommonStyledComponents/NavigationWithKeyboard';
import SearchBar from 'Helpcenter/components/SearchBar';
import BackSVG from 'icons/leftThinChevron.svg';
import Link from '@playerCommon/StandardElements/LinkWithQuery/LinkWithQuery';
import LanguagePicker from './LanguagePicker';
import { useContactFormOptionsChecking } from '../../../helpers';

const HiddenInput = styled.input`
  display: none;
`;

const MobileContent = styled.div`
  display: block;
  width: 100%;
`;

const Canvas = styled.div`
  color: ${props => props.theme.slateGrey};
  display: inline-flex;
  flex-shrink: 0;
  position: absolute;
  width: 100%;
  height: auto;
  overflow-y: auto;
  top: 64px;
  left: 0;
  padding: 0 24px;
  padding-top: 16px;
  background: white;
  transition: opacity 0.2s, visibility 0.2s, transform 0.2s;
  opacity: 0;
  visibility: hidden;
  transform: translate3d(0, -24px, 0);
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 4px 8px -4px ${props => props.theme.canvasBox};

  ${({ searchFocused }) =>
    !searchFocused &&
    css`
      ${HiddenInput}:checked ~ & {
        opacity: 1;
        visibility: visible;
        transform: translate3d(0, 0, 0);
      }
    `}

  @media screen and (max-width: 480px) {
    padding: 0 16px;
    padding-top: 16px;
  }
`;

const HeaderLink = styled.a`
  display: inline-flex;
  align-items: center;
  margin-right: 24px;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 24px;
  font-weight: 500;
  cursor: pointer;
  transition: opacity 0.2s, box-shadow 0.2s;
  box-shadow: none;
  border-radius: 4px;
  flex-shrink: 0;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    opacity: 0.8;
  }

  ${navigationWithKeyboardStyle}
`;

const ContactLink = HeaderLink.withComponent('span');

export const MobileMenuToggleBar = styled.div`
  display: block;
  position: relative;
  height: 2px;
  width: 14px;
  border-radius: 4px;
  transition: transform 0.2s, background-color 0.2s, opacity 0.2s;
  margin-bottom: 4px;
  background-color: currentColor;

  &:last-child {
    margin-bottom: 0;
  }
`;

const MobileMenuToggle = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  margin-right: -4px;
  cursor: pointer;
  transition: opacity 0.2s, width 0.2s;

  ${HiddenInput}:checked ~ & > ${MobileMenuToggleBar} {
    margin: 0;
    &:nth-child(1) {
      transform: translate3d(0px, 2px, 0) rotate(225deg);
    }
    &:nth-child(2) {
      opacity: 0;
    }
    &:nth-child(3) {
      transform: translate3d(0px, -2px, 0) rotate(135deg);
    }
  }

  ${({ hidden }) =>
    hidden &&
    css`
      width: 0;
      opacity: 0;
    `}
`;

const SearchBarWidget = styled(SearchBar)`
  width: calc(100% - 96px);
  margin-left: 0;
`;

const ParentLink = styled(Link)`
  font-size: 20px;
  line-height: 24px;
  margin-right: 16px;
  transition: margin-right 0.2s, opacity 0.2s, visibility 0.2s, transform 0.2s;
  transform: translate3d(0px, 0, 0);
  color: ${props => props.theme.headerTextColor};
  outline: none;
`;

const ParentA = styled.a`
  font-size: 20px;
  line-height: 24px;
  margin-right: 16px;
  transition: margin-right 0.2s, opacity 0.2s, visibility 0.2s, transform 0.2s;
  transform: translate3d(0px, 0, 0);
  color: ${props => props.theme.headerTextColor};
  outline: none;
`;

const ParentLinkWrap = styled.div`
  display: block;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 32px;
  transition: width 0.2s;

  ${({ show }) =>
    !show &&
    css`
      width: 0 !important;

      ${ParentLink} {
        visibility: hidden;
        margin-right: 0;
        opacity: 0;
        transform: translate3d(-12px, 0, 0);
      }
    `}
`;

const BackButtonIcon = styled(BackSVG)`
  transform: translateY(1px);
  path {
    fill: currentColor;
    stroke: currentColor;
    stroke-width: 0.3px;
  }

  /* @noflip */
  [dir='rtl'] & {
    transform: translateY(1px) scaleX(-1);
  }
`;

const ParentAStyled = styled(Link).attrs({ component: ParentA })``;
function HeaderMenu({
  currentLanguage,
  onLanguageChange,
  languageList,
  onContactClick,
  mobileTopContent,
  onOpen,
  onClose,
  helpcenterData,
  mode,
  parentLink,
  parentHref,
  forceParentHref,
}) {
  const [searchFocused, setSearchFocused] = useState(false);
  const [referrerURL] = useState(getGlobal('referrer'));
  const host = getGlobal('host');

  const referrerIsValid =
    referrerURL.includes(host) && !referrerURL.includes('/guide/') && referrerURL.includes('kb-widget');

  function onHeaderStateChange(e) {
    if (e.target.checked && onOpen) onOpen();
    if (!e.target.checked && onClose) onClose();
  }

  const { knowledgeBase, headerLinks } = helpcenterData;

  const {
    isContactFormCustomGuide,
    isContactFormEnabled,
    isContactFormOnNoResultsEnabled,
    isContactFormInKnowledgeBaseHeaderEnabled,
    isContactFormInGuidesHeaderEnabled,
    isContactFormInWidgetEnabled,
  } = useContactFormOptionsChecking(knowledgeBase);

  const isExplanationMode = mode === 'explanation';

  const showMenuToggle =
    (isContactFormInKnowledgeBaseHeaderEnabled && !isContactFormInWidgetEnabled) ||
    (headerLinks && headerLinks.length > 0) ||
    mode !== 'homepage';

  const hasHeaderContactForm =
    isContactFormEnabled &&
    ((isExplanationMode && isContactFormInGuidesHeaderEnabled) ||
      (!isExplanationMode && isContactFormInKnowledgeBaseHeaderEnabled));

  const getBackLink = () => {
    if (parentLink) {
      return (
        <ParentLink tabIndex={0} to={parentLink} className="kbWidget-guideTop-backLink">
          <BackButtonIcon />
        </ParentLink>
      );
    }

    if (referrerIsValid && !forceParentHref) {
      return (
        <ParentA className="kbWidget-guideTop-backLink" href={referrerURL}>
          <BackButtonIcon />
        </ParentA>
      );
    }

    return (
      <ParentAStyled className="kbWidget-guideTop-backLink" to={parentHref}>
        <BackButtonIcon />
      </ParentAStyled>
    );
  };

  return (
    <>
      <ParentLinkWrap
        show={(!!parentLink || !!parentHref) && !searchFocused}
        className="kbWidget-guideTop-backLinkWrap"
      >
        {getBackLink()}
      </ParentLinkWrap>
      <HiddenInput type="checkbox" id="menuOpen" onChange={onHeaderStateChange} />
      {showMenuToggle ? (
        <MobileMenuToggle className="mobileMenuToggle" htmlFor="menuOpen" hidden={searchFocused}>
          <MobileMenuToggleBar />
          <MobileMenuToggleBar />
          <MobileMenuToggleBar />
        </MobileMenuToggle>
      ) : null}
      <SearchBarWidget
        mode="widget"
        helpcenterData={helpcenterData}
        onFocus={() => setSearchFocused(true)}
        onBlur={() => setSearchFocused(false)}
        placeholder={knowledgeBase.searchInputText}
        onContactFormClick={isContactFormOnNoResultsEnabled ? onContactClick : undefined}
      />
      <Canvas searchFocused={searchFocused}>
        <MobileContent>{mobileTopContent}</MobileContent>
        <LanguagePicker currentLanguage={currentLanguage} onChange={onLanguageChange} languageList={languageList} />
        {headerLinks && (
          <>
            {headerLinks
              .sort((a, b) => a.order - b.order)
              .map(link => (
                <HeaderLink key={link.teamKnowledgeBaseHeaderLinkId} href={link.url} target="_blank" rel="noopener">
                  {link.text}
                </HeaderLink>
              ))}
          </>
        )}
        {!!onContactClick &&
          !!hasHeaderContactForm &&
          (isContactFormCustomGuide ? (
            <Link
              component={HeaderLink}
              tabIndex="0"
              to={`/kb-widget/guide/${currentLanguage}/${knowledgeBase.guideId}/Steps/`}
            >
              {knowledgeBase.contactButtonText || i18n('HelpcenterHome.ContactUs')}
            </Link>
          ) : (
            <ContactLink
              tabIndex="0"
              onClick={onContactClick}
              onKeyPress={onContactClick ? onKeyPressToOnClick(onContactClick) : undefined}
            >
              {knowledgeBase.contactButtonText || i18n('HelpcenterHome.ContactUs')}
            </ContactLink>
          ))}
      </Canvas>
    </>
  );
}

HeaderMenu.propTypes = {
  currentLanguage: PropTypes.string,
  onLanguageChange: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onContactClick: PropTypes.func,
  languageList: PropTypes.string,
  mobileTopContent: PropTypes.object,
  helpcenterData: PropTypes.object,
  mode: PropTypes.string,
  parentLink: PropTypes.string,
  parentHref: PropTypes.string,
  forceParentHref: PropTypes.bool,
};

export default memo(HeaderMenu);
