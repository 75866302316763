import React, { useCallback } from 'react';
import T from 'prop-types';
import styled, { css } from 'styled-components';
import i18n from 'helpers/i18n';
import Button from '@playerCommon/StandardElements/Button';
import useGuideData from '@playerCommon/hooks/playerHooks/useGuideData';
import { postMessage } from 'helpers/widgetHelpers';
import { STEP_WIDGET_ACTION_TYPES } from 'global';
import { postChecklistMessage, skipChecklist } from './Checklist.helpers';

const StyledButton = styled(Button)`
  ${({ isFirstStep }) =>
    isFirstStep &&
    css`
      button {
        background: unset !important;
        border: unset !important;
        color: ${({ theme }) => theme.slateGrey} !important;
        font-size: 14px;
        font-weight: normal;
        font-weight: 600;
        font-family: system-ui;
        height: 16px;
        padding: 0;
      }
      &:hover button {
        color: ${({ theme }) => theme.darkGrey} !important;
      }
    `}

  ${({ tooltip }) =>
    tooltip &&
    css`
      button {
        pointer-events: none;
      }
    `}
`;

const SkipChecklistButton = ({ isFirstStep, isPreview, onSkipChecklistClick, checklistId }) => {
  const { guideInfo, isWidget } = useGuideData();
  const onChecklistSkip = useCallback(() => {
    postChecklistMessage({
      type: 'checklistSkipped',
      guideId: guideInfo.guideId,
      checklistId,
    });
    skipChecklist({
      guideId: guideInfo.guideId,
      teamId: guideInfo.teamId,
      checklistId,
    });
    // close widget after click on skip, but show "checklist skipped" page in KB
    if (isWidget) {
      postMessage({
        type: 'specialAction',
        action: {
          type: STEP_WIDGET_ACTION_TYPES.CLOSE_WIDGET,
        },
      });
    } else {
      onSkipChecklistClick();
    }
  }, []);

  return (
    <StyledButton
      isFirstStep={isFirstStep}
      background="filledGreyBorder"
      onClick={onChecklistSkip}
      content={i18n('Checklist.Skip')}
      tooltip={isPreview ? i18n('Preview.ButtonsDisabled') : undefined}
      positionTooltip={isFirstStep ? 'right' : 'up'}
    />
  );
};

SkipChecklistButton.propTypes = {
  isFirstStep: T.bool,
  isPreview: T.bool,
  onSkipChecklistClick: T.func,
  checklistId: T.number,
};

export default SkipChecklistButton;
