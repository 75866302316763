import React, { useEffect, useRef } from 'react';

import { AnimatePresence } from 'framer-motion';
import TypingIndicatorAnimated from '@playerCommon/CustomElements/TypingIndicatorAnimated/TypingIndicatorAnimated';
import { SmartSearchAnswerWrapAnimatedWrap, SmartSearchQuestionWrap } from '../AiAnswer.styles';
import AiAnswerSingleMessage, { AiAnswerSingleMessageWrap } from './AiAnswerSingleMessage';
import { replaceUglyBrTags } from '../AiAnswer.helpers';

const AiAnswerPendingAnswer = ({ currentQuestion, pendingAnswersList }) => {
  const answerRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      answerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 0);
  }, []);

  useEffect(() => {
    if (pendingAnswersList.length) {
      setTimeout(() => {
        answerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 0);
    }
  }, [pendingAnswersList]);

  return (
    <>
      <SmartSearchQuestionWrap className="ai-step-question-wrap">{currentQuestion}</SmartSearchQuestionWrap>
      <SmartSearchAnswerWrapAnimatedWrap ref={answerRef}>
        <AnimatePresence>
          {pendingAnswersList.map((answer, index) => (
            <AiAnswerSingleMessage
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              isAnimated
              text={replaceUglyBrTags(answer)}
              messageId={`${currentQuestion}-pending-${index}`}
            />
          ))}
          <AiAnswerSingleMessageWrap className="ai-step-result-text">
            <TypingIndicatorAnimated />
          </AiAnswerSingleMessageWrap>
        </AnimatePresence>
      </SmartSearchAnswerWrapAnimatedWrap>
    </>
  );
};

export default AiAnswerPendingAnswer;
