import React, { memo, useMemo, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { STEP_NEXT_VISIBILITY } from 'global';
import InternalNote from '@playerCommon/StandardElements/ContentEditable/InternalNote/InternalNote.player';
import useParsedStepContent from '@playerCommon/hooks/useParsedStepContent';
import useDidUpdate from '@playerCommon/hooks/useDidUpdate';
import useIsMounted from '@playerCommon/hooks/useIsMounted';
import { useSentData } from '@playerCommon/Contexts/sentDataContext';
import { useServerCallData } from '@playerCommon/Contexts/serverCallDataContext';
import MainContentText from '@playerCommon/ComplexElements/ExplanationPlayer/Steps/Content/ContentText.js';
import { extractBaseData } from 'components/Analytics/recorder';
import { postMessage } from 'helpers/widgetHelpers';
import { runServerCallsAndGetResolvedOption } from './Automation.helpers';
import AutomationLoader from './AutomationLoader';

const Canvas = styled.div`
  @media screen and (max-width: 899px) {
    padding: 0 ${({ theme }) => theme.stepsContentPaddings.contentPadding}px;
  }

  a {
    color: ${props => props.theme.linkColor};
    text-decoration: underline;
  }
`;

const Title = styled.div`
  font-size: 24px;
  line-height: 40px;
  margin-bottom: 16px;
  text-align: center;
`;

const StyledMainContentText = styled(MainContentText)`
  text-align: center;
`;

const MAX_RESOLVE_REQUESTS_COUNT = 2;

function getFallbackOption(options) {
  const fallbackOption = options.find(o => o.visibility === STEP_NEXT_VISIBILITY.fallback);
  // When automation step is a last step of the embedded guide and there is a connection to the step after it (configured in the embedding guide),
  // we use this connection as a fallback option for automation step to make sure that the user won't stuck in the embedded guide.
  const alwaysVisibleConnection = options.find(o => o.visibility === STEP_NEXT_VISIBILITY.always);
  return fallbackOption || alwaysVisibleConnection;
}

const Automation = ({
  options,
  content,
  internalNotes,
  isPreview,
  onOptionSelect,
  mode,
  fallbackTimeout,
  minimumDisplayTime,
  minimumDisplayTimeEnabled = false,
  loaderIconData,
  getVisitedStepsData,
  stepId,
  guideId,
  serverCalls,
}) => {
  const { localData } = useSentData();
  const { serverCallStatuses, serverCallVariables, setServerCallData } = useServerCallData();
  const [resolvedOption, setResolvedOption] = useState(null);
  const [fallbackTimePassed, setFallbackTimePassed] = useState(false);
  const [shouldGoToFallback, setShouldGoToFallback] = useState(false);
  const [minimumDisplayTimePassed, setMinimumDisplayTimePassed] = useState(!minimumDisplayTimeEnabled);
  const [shouldShowFallbackScreen, setShouldShowFallbackScreen] = useState(false);
  const [isOptionSelectionRequested, setIsOptionSelectionRequested] = useState(false);
  const { t } = useTranslation();
  const shouldWaitForLocalDataRef = useRef(false);
  const resolveRequestCount = useRef(0);
  const fallbackOption = getFallbackOption(options);
  const goToFallbackTimeoutRef = useRef();
  const optionsToResolveList = useMemo(
    () => options.filter(({ visibility }) => visibility === STEP_NEXT_VISIBILITY.externalCondition),
    [options]
  );
  const checkIsMounted = useIsMounted();
  const hasServerCalls = !!serverCalls.length;

  async function doResolveOptions() {
    resolveRequestCount.current += 1;
    const result = await runServerCallsAndGetResolvedOption({
      optionList: optionsToResolveList,
      localData,
      serverCallStatuses,
      serverCallVariables,
      serverCalls,
    });

    if (!result || !checkIsMounted()) {
      return;
    }

    if (hasServerCalls)
      setServerCallData({
        statuses: result.serverCallStatuses,
        variables: result.serverCallVariables,
      });

    if (result.resolvedOption) {
      setResolvedOption(result.resolvedOption);
    }

    return result.resolvedOption || null;
  }

  useEffect(() => {
    if (isPreview) {
      return;
    }

    extractBaseData().then(({ sessionId, customerUserId }) => {
      postMessage({
        type: 'automationStepStarted',
        guideId,
        stepId,
        sessionId,
        customerUserId,
        visitedStepsData: getVisitedStepsData(),
      });
    });

    goToFallbackTimeoutRef.current = setTimeout(() => setFallbackTimePassed(true), fallbackTimeout * 1000);
    const minimumDisplayTimeTimeout = setTimeout(() => setMinimumDisplayTimePassed(true), minimumDisplayTime * 1000);

    return () => {
      clearTimeout(goToFallbackTimeoutRef.current);
      clearTimeout(minimumDisplayTimeTimeout);
    };
  }, []);

  useEffect(() => {
    if (isPreview) {
      return;
    }

    const hasOptionDependantOnLocalData = optionsToResolveList.some(o => !!o.conditionLocalProperties?.length);

    if (hasOptionDependantOnLocalData && !hasServerCalls) {
      // Check if among options to resolve there is at least one that potentially can be resolved with existing data.
      // If yes, we try to resolve conditions, otherwise we wait for new local data to be passed to the guide.
      const hasAtLeastOneOptionThatCanBeResolved = optionsToResolveList.some(
        o =>
          !o.conditionLocalProperties?.length ||
          o.conditionLocalProperties.every(localProperty => localData[localProperty] !== undefined)
      );

      if (hasAtLeastOneOptionThatCanBeResolved) {
        doResolveOptions().then(option => {
          if (option === null) {
            shouldWaitForLocalDataRef.current = true;
          }
        });
      } else {
        shouldWaitForLocalDataRef.current = true;
      }
    } else {
      // There are no options that depend on local data, go to fallback if none of the options are resolved
      doResolveOptions().then(option => {
        if (option === null) {
          setShouldGoToFallback(true);
        }
      });
    }
  }, []);

  useDidUpdate(() => {
    if (isPreview || !shouldWaitForLocalDataRef.current || resolveRequestCount.current === MAX_RESOLVE_REQUESTS_COUNT) {
      return;
    }

    const hasLocalDataForAtLeastOneOption = optionsToResolveList.some(
      o =>
        !!o.conditionLocalProperties?.length &&
        o.conditionLocalProperties.every(localProperty => localData[localProperty] !== undefined)
    );

    if (hasLocalDataForAtLeastOneOption) {
      doResolveOptions().then(option => {
        if (option === null) {
          setShouldGoToFallback(true);
        }
      });
    }
  }, [isPreview, localData]);

  useEffect(() => {
    if (isPreview) {
      return;
    }

    let selectOptionTimeout;
    function selectOption(option) {
      setIsOptionSelectionRequested(true);
      // the timeout is needed in order to give enough time for progress animation to finish smoothly
      selectOptionTimeout = setTimeout(() => onOptionSelect(option), 100);
      // clear fallback timeout to prevent option from being selected for the second time
      // in case when fallback time passes while guide being hidden
      clearTimeout(goToFallbackTimeoutRef.current);
    }

    if (minimumDisplayTimePassed) {
      if (resolvedOption) {
        selectOption(resolvedOption);
      } else if (shouldGoToFallback || fallbackTimePassed) {
        if (fallbackOption) {
          selectOption(fallbackOption);
        } else {
          setShouldShowFallbackScreen(true);
        }
      }
    }
    return () => clearTimeout(selectOptionTimeout);
  }, [resolvedOption, minimumDisplayTimePassed, fallbackTimePassed, isPreview, shouldGoToFallback, fallbackOption]);

  const contentToDisplay = useParsedStepContent({
    content,
    isPreview,
  });

  if (shouldShowFallbackScreen) {
    return (
      <Canvas>
        <Title>{t('Automation.FallbackScreen')}</Title>
      </Canvas>
    );
  }

  return (
    <Canvas>
      {loaderIconData && (
        <AutomationLoader
          iconName={loaderIconData.iconName}
          iconColor={loaderIconData.iconColor}
          isPreview={isPreview}
          animationDuration={
            minimumDisplayTimeEnabled ? Math.max(fallbackTimeout, minimumDisplayTime) : fallbackTimeout
          }
          shouldAccelerateAnimation={isOptionSelectionRequested}
        />
      )}
      <StyledMainContentText className="content-text" dataCy="contentText" text={contentToDisplay} mode={mode} />
      {internalNotes.map(internalNote => (
        <InternalNote
          key={internalNote.id}
          added={internalNote.added}
          removed={internalNote.removed}
          content={internalNote.content}
        />
      ))}
    </Canvas>
  );
};

Automation.propTypes = {
  options: PropTypes.array,
  content: PropTypes.string,
  isPreview: PropTypes.bool,
  mode: PropTypes.string,
  onOptionSelect: PropTypes.func.isRequired,
  internalNotes: PropTypes.array,
  fallbackTimeout: PropTypes.number,
  minimumDisplayTime: PropTypes.number,
  minimumDisplayTimeEnabled: PropTypes.bool,
  loaderIconData: PropTypes.shape({
    iconName: PropTypes.string,
    iconColor: PropTypes.string,
  }),
  getVisitedStepsData: PropTypes.func,
  stepId: PropTypes.number,
  guideId: PropTypes.string,
};

export default memo(Automation);
