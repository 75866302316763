import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SessionPlayer from '@playerCommon/ComplexElements/SessionPlayer/SessionPlayer.js';

const Canvas = styled.div`
  margin-top: 48px;
`;

const userHistory = ({ history, updateParentContainer, match }) => {
  const { sessionsId, id, pathToLoad, referrerUrl } = match.params;
  return (
    <Canvas>
      <SessionPlayer
        width="100%"
        id={id}
        context="userSession"
        referrerUrl={referrerUrl}
        pathToLoad={pathToLoad}
        sessionId={sessionsId}
        history={history}
        updateParentContainer={updateParentContainer}
      />
    </Canvas>
  );
};

userHistory.propTypes = {
  history: PropTypes.object,
  id: PropTypes.string,
  match: PropTypes.object,
  pathToLoad: PropTypes.string,
  updateParentContainer: PropTypes.func,
  ticketId: PropTypes.string,
  referrerUrl: PropTypes.string,
};

export default userHistory;
