import styled, { css } from 'styled-components';
import { focusInputOutlineStyle, FieldStyles } from '../../_shared/Field.styles';

const ButtonElement = styled.button<{
  $isOpen?: boolean;
  $isPlaceholder?: boolean;
}>`
  font-family: inherit;
  border: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  outline: none;
  background: none;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 4px;
  outline: none;
  padding: 0;
  cursor: pointer;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      z-index: 1; /* to make outline cover additional action button */
      ${focusInputOutlineStyle}
    `}

  ${({ theme, $isPlaceholder }) =>
    $isPlaceholder &&
    css`
      color: ${theme.lightGrey};
    `}

  ${({ theme, disabled }) =>
    disabled &&
    css`
      color: ${theme.lightGrey};
      background-color: ${theme.paleGrey};
      & svg path {
        fill: ${theme.grey};
      }
    `}
`;

const Text = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  text-align: start;
  font-family: inherit;
  width: 100%;
  min-width: 0;
  border: 0;
  outline: 0;
  padding: 0;
  margin: 0;
  background: transparent;
  padding: 10px 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  * + & {
    padding-left: 8px; // smaller padding when icon on the left
  }
`;

export default { ButtonElement, Text };
