import React, { useMemo } from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import ActionsDropdown from '@playerCommon/CustomElements/ActionsDropdown';
import i18n from 'helpers/i18n';
import EditIconSVG from 'resources/icons/settingsSubHeader.svg';
import KeyIconSVG from 'icons/key.svg';
import MoveToIconSVG from 'icons/moveToNoPadding.svg';
import DeleteIconSVG from 'icons/deleteNoPadding.svg';
import DuplicateIconSVG from 'icons/duplicateNoPadding.svg';
import ViewIconSVG from 'icons/externalLinkSmall.svg';
import { RIGHT } from 'global';

const GuideOptionsDropdown = ({
  guideId,
  guide,
  accessRights,
  onView,
  onOpenSettings,
  onEditRights,
  onDuplicate,
  onMoveTo,
  onDelete,
  trigger,
  theme,
}) => {
  const options = useMemo(() => {
    const optionsToUse = [
      {
        id: 'viewGuide',
        dataCy: 'viewGuide',
        label: i18n(guide.status === 'draft' ? 'Console.PreviewExplanation' : 'Console.ViewExplanation'),
        icon: ViewIconSVG,
        onClick: () => onView(guideId),
      },
    ];
    if (accessRights === RIGHT.FULL_RIGHTS) {
      optionsToUse.push({
        id: 'editGuideRights',
        dataCy: 'editGuideRights',
        label: i18n('KnowledgeBaseManagement.ContentRowDropdownEditRights'),
        icon: KeyIconSVG,
        onClick: () => onEditRights(guideId),
      });
      optionsToUse.push({
        id: 'moveGuide',
        dataCy: 'moveGuide',
        label: i18n('KnowledgeBaseManagement.ContentRowDropdownMove'),
        icon: MoveToIconSVG,
        onClick: () => onMoveTo({ type: 'explanation', id: guide.id, teamId: guide.teamId, name: guide.title }),
      });
    }
    if ([RIGHT.VIEW_EDIT, RIGHT.VIEW_EDIT_PUBLISH, RIGHT.FULL_RIGHTS].includes(accessRights)) {
      optionsToUse.push({
        id: 'duplicateGuide',
        dataCy: 'duplicateGuide',
        label: i18n('KnowledgeBaseManagement.ContentRowDropdownDuplicate'),
        icon: DuplicateIconSVG,
        onClick: () => onDuplicate(guide),
      });
    }
    optionsToUse.push({
      id: 'goToGuideSettings',
      dataCy: 'goToGuideSettings',
      label: i18n('Console.GoToExplanationSettings'),
      icon: EditIconSVG,
      onClick: () => onOpenSettings(guideId),
    });
    if (accessRights === RIGHT.FULL_RIGHTS) {
      optionsToUse.push({
        id: 'deleteGuide',
        dataCy: 'deleteGuide',
        label: i18n('KnowledgeBaseManagement.ContentRowDropdownDelete'),
        icon: DeleteIconSVG,
        onClick: () => onDelete(guideId),
        separation: true,
        iconHoverColor: theme.deleteColor,
        textHoverColor: theme.deleteColor,
        backgroundHoverColor: theme.deleteColorBg,
      });
    }
    return optionsToUse;
  }, [guideId, guide, accessRights, onOpenSettings, onEditRights, onDuplicate, onMoveTo, onDelete]);

  return <ActionsDropdown width={160} trigger={trigger} options={options} animateIconHover />;
};

GuideOptionsDropdown.propTypes = {
  guide: PropTypes.object,
  guideId: PropTypes.string,
  addGuide: PropTypes.func,
  addFolder: PropTypes.func,
  onView: PropTypes.func,
  accessRights: PropTypes.number,
  onEditRights: PropTypes.func,
  onDuplicate: PropTypes.func,
  onOpenSettings: PropTypes.func,
  onMoveTo: PropTypes.func,
  onDelete: PropTypes.func,

  trigger: PropTypes.object,
  theme: PropTypes.object,
};

export default withTheme(GuideOptionsDropdown);
