import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import SVG from 'react-inlinesvg';
import PropTypes from 'prop-types';
import { resourcesUrl } from 'global/env';

const IconScaler = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  ${({ added, removed, modified }) =>
    (added || removed || modified) &&
    css`
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
        z-index: 10;
        border: 4px solid ${props => props.theme.changesAddedColorSolid};

        ${added &&
        css`
          border-color: ${props => props.theme.changesAddedColorSolid};
          background: ${props => props.theme.changesAddedColor};
        `}
        ${removed &&
        css`
          border-color: ${props => props.theme.changesRemovedColorSolid};
          background: ${props => props.theme.changesRemovedColor};
        `}
        ${modified &&
        css`
          border-color: ${props => props.theme.changesModifiedColorSolid};
          background: ${props => props.theme.changesModifiedColor};
        `}
      }
    `}
`;

const IconWrap = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  transition: background-color 0.2s;
`;

const IconCircle = styled.svg`
  position: absolute;
  width: ${(264 / 512) * 100}%;
  height: ${(264 / 512) * 100}%;
  max-width: 400px;
  max-height: 400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  circle {
    opacity: ${({ circleOpacity }) => circleOpacity};
    stroke: none;
    fill: ${props => props.theme.white};
  }

  ${({ size }) =>
    size === 'fill' &&
    css`
      width: ${(152 / 216) * 100}%;
      height: ${(152 / 216) * 100}%;
    `}
`;

const LoadingIcon = styled.div`
  ${({ getRaw }) =>
    getRaw
      ? css`
          background-image: url(${({ src }) => src});
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        `
      : css`
          background-color: ${({ color }) => color};
          mask: url(${({ src }) => src}) no-repeat;
          mask-size: contain;
          mask-position: center;
        `}
`;

const Icon = styled.div`
  width: ${(264 / 512) * 100}%;
  height: ${(264 / 512) * 100}%;
  max-width: 400px;
  max-height: 400px;
  z-index: 1;

  & ${LoadingIcon} {
    display: block;
    width: 100%;
    height: 100%;
  }

  & svg {
    display: block;
    width: 100%;
    height: 100%;
    ${({ getRaw }) =>
      !getRaw &&
      css`
        path,
        circle,
        line,
        polyline,
        ellipse,
        rect,
        polygon {
          transition: stroke 0.6s;
          fill: transparent;
          stroke-width: ${({ iconThickness }) => iconThickness};

          stroke: #000000;
          stroke: ${({ iconColor }) => iconColor};
        }
      `}
  }

  ${({ size }) =>
    size === 'fill' &&
    css`
      width: ${(152 / 216) * 100}%;
      height: ${(152 / 216) * 100}%;
    `}
`;

const IconIllustration = ({
  backgroundColor,
  iconColor,
  iconName,
  iconThickness = 5,
  className,
  circleOpacity = 0.2,
  added,
  removed,
  modified,
  getRaw = false,
  size = 'regular',
}) => (
  <IconScaler className={className} added={added} removed={removed} modified={modified}>
    <IconWrap data-cy="icon" backgroundColor={backgroundColor} className="illustration-icon-wrap">
      <IconCircle viewBox="0 0 24 24" circleOpacity={circleOpacity} size={size}>
        <circle cx="50%" cy="50%" r="50%" />
      </IconCircle>
      <Icon getRaw={getRaw} iconColor={iconColor} iconThickness={iconThickness} size={size}>
        <SVG
          loader={
            <LoadingIcon
              getRaw={getRaw}
              color={iconColor}
              src={`${resourcesUrl.url}resources/icons/picker/XL/${iconName}.svg`}
            />
          }
          src={`${resourcesUrl.url}resources/icons/picker/XL/${iconName}.svg`}
        >
          <LoadingIcon
            getRaw={getRaw}
            color={iconColor}
            src={`${resourcesUrl.url}resources/icons/picker/XL/${iconName}.svg`}
          />
        </SVG>
      </Icon>
    </IconWrap>
  </IconScaler>
);

IconIllustration.propTypes = {
  backgroundColor: PropTypes.string,
  iconColor: PropTypes.string,
  iconName: PropTypes.string,
  iconThickness: PropTypes.number,
  className: PropTypes.string,
  circleOpacity: PropTypes.number,
  added: PropTypes.bool,
  removed: PropTypes.bool,
  modified: PropTypes.bool,
  getRaw: PropTypes.bool,
  size: PropTypes.string,
};

export default memo(IconIllustration);
