import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const progress = keyframes`
  0% {
    transform: scaleX(0)
   }
  100% {
    transform: scaleX(1)
   }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .bar {
    opacity: 0.2;
    border-radius: 2px;
    border: 1px solid ${({ iconColor }) => iconColor};
    height: 0px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  .progress {
    animation: ${progress} linear forwards;
    animation-iteration-count: ${({ isPreview }) => (isPreview ? 'infinite' : 1)};
    animation-duration: ${({ animationDuration }) => `${animationDuration}s`};
    border-radius: 2px;
    border: 1px solid ${({ iconColor }) => iconColor};
    height: 0px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    transform: scaleX(0);
    transform-origin: left;
  }

  .accelerated {
    animation-duration: 100ms;
  }
`;

const ProgressBar = ({ iconColor, isPreview, animationDuration, shouldAccelerateAnimation }) => (
  <Wrapper iconColor={iconColor} isPreview={isPreview} animationDuration={animationDuration}>
    <div className="bar" />
    <div className="progress" />
    {shouldAccelerateAnimation && <div className="progress accelerated" />}
  </Wrapper>
);

ProgressBar.propTypes = {
  iconColor: PropTypes.string,
  isPreview: PropTypes.bool,
  animationDuration: PropTypes.number,
  shouldAccelerateAnimation: PropTypes.bool,
};

export default ProgressBar;
