import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import i18n from 'helpers/i18n';
import Button from '@playerCommon/StandardElements/Button';
import ResetChecklistSVG from 'icons/resetChecklist.svg';

const StyledResetChecklistIcon = styled(ResetChecklistSVG)``;

const StyledButton = styled(Button)`
  margin: 16px 0 0 12px;
  padding: 4px 0;

  button {
    background: unset !important;
    border-radius: unset;
    border: unset !important;
    color: ${({ theme }) => theme.slateGrey} !important;
    font-family: system-ui;
    font-size: 12px;
    font-weight: 600;
    height: unset;
    line-height: 12px;
    padding: 0;
  }

  ${StyledResetChecklistIcon} {
    margin-right: 8px;
    path {
      fill: ${({ theme }) => theme.slateGrey};
      transition: fill 0.2s;
    }
  }

  &:hover {
    ${StyledResetChecklistIcon} {
      path {
        fill: ${({ theme }) => theme.darkGrey};
      }
    }
    button {
      color: ${({ theme }) => theme.darkGrey} !important;
    }
  }

  ${({ tooltip }) =>
    tooltip &&
    css`
      button {
        pointer-events: none;
      }
    `}
`;

const ResetChecklistText = styled.span``;

const ResetChecklistButton = ({ onClick, isPreview }) => (
  <StyledButton
    className="reset-checklist-button"
    onClick={onClick}
    tooltip={isPreview ? i18n('Preview.ButtonsDisabled') : undefined}
    positionTooltip="up"
    content={
      <>
        <StyledResetChecklistIcon className="reset-checklist-icon" />
        <ResetChecklistText className="reset-checklist-text">{i18n('Checklist.Reset')}</ResetChecklistText>
      </>
    }
  />
);

ResetChecklistButton.propTypes = {
  onClick: PropTypes.func,
  isPreview: PropTypes.bool,
};

export default ResetChecklistButton;
