import { findEmbeddedNextStepBasedOnPath, getSafeConnectionList } from 'helpers/guidePathHelpers';
import { postMessage } from 'helpers/widgetHelpers';
import {
  STEP_SEPARATOR,
  STEP_MODULE_TYPE,
  STEP_TYPE,
  STEP_NEXT_TYPE,
  STEP_NAVIGATION_TYPES,
  GUIDE_TYPE,
  GUIDE_MODULE_TYPE,
  STEP_NEXT_SELECTOR_TYPE,
  STEP_NEXT_VISIBILITY,
  STEP_NEXT_CONDITION_TYPE,
  STEP_WIDGET_ACTION_TYPES,
} from 'global';
import { SPECIAL_STEPS_BUTTON_NAME } from 'global/translated-names';
import slugify from 'slugifyjs';
import generateTempId from './randomValues';
import i18n from './i18n';
import { findNonStonlyLinks, URL_REGEX } from './validationHelpers';

const Slugify = slugify.fromLocale('en');

export function createSlug(text) {
  return Slugify.parse(text);
}

export const createPathFromParam = param => {
  if (param) {
    if (param === 'introduction') {
      return [-2];
    }
    return param.split(STEP_SEPARATOR).map(Number);
  }
  return [];
};

function getGuideTitle(title, language, defaultLanguage) {
  if (title) {
    return title[language]?.title || title[defaultLanguage]?.title || '';
  }
  return '';
}

export function getConnectionLabelAndURL(connection, language, defaultLanguage) {
  if (!connection) return {};
  return connection[language] || connection[defaultLanguage] || {};
}

export function getStepContent(step, language, defaultLanguage) {
  const contentModule = step.stepModule.find(sm => sm.type === STEP_MODULE_TYPE.content);
  if (contentModule) {
    return contentModule.content[language]?.content || contentModule.content[defaultLanguage]?.content || '';
  }
  return '';
}

export function getButtonSettingsContent(step, language, defaultLanguage) {
  const buttonSettingsModule = step.stepModule.find(sm => sm.type === STEP_MODULE_TYPE.buttonSettings);
  if (buttonSettingsModule) {
    return {
      nextStepButtonText:
        buttonSettingsModule.content[language]?.nextStepButtonText ||
        buttonSettingsModule.content[defaultLanguage]?.nextStepButtonText ||
        '',
      placeholderText:
        buttonSettingsModule.content[language]?.placeholderText ||
        buttonSettingsModule.content[defaultLanguage]?.placeholderText ||
        '',
    };
  }
  return {};
}

export function getStepTitle(step, language, defaultLanguage) {
  const contentModule = step.stepModule.find(sm => sm.type === STEP_MODULE_TYPE.content);
  if (contentModule) {
    return contentModule.content[language]?.title || contentModule.content[defaultLanguage]?.title || '';
  }
  return '';
}

export function getStepCommunications(step) {
  const communicationModules = step.stepModule.filter(sm => sm.type === STEP_MODULE_TYPE.communication);
  if (communicationModules.length) {
    return communicationModules.map(cm => ({ ...cm.properties, stepCommunicationPublicId: cm.stepModuleStaticId }));
  }
  return [];
}

export function getStepInput(step, language, defaultLanguage) {
  const inputModule = step.stepModule.filter(sm => sm.type === STEP_MODULE_TYPE.input);
  if (inputModule.length) {
    return inputModule.map(input => ({
      content: {
        label: input.content[language]?.label || input.content[defaultLanguage]?.label || '',
        placeholder: input.content[language]?.placeholder || input.content[defaultLanguage]?.placeholder || '',
        errorMessage: input.content[language]?.errorMessage || input.content[defaultLanguage]?.errorMessage || '',
        defaultValue: input.content[language]?.defaultValue || input.content[defaultLanguage]?.defaultValue || '',
        hiddenValue: input.content[language]?.hiddenValue || input.content[defaultLanguage]?.hiddenValue || '',
        optionList: input.content[language]?.optionList || input.content[defaultLanguage]?.optionList || [],
      },
      properties: input.properties,
      stepInputModuleId: input.stepModuleStaticId,
      stepId: step.stepId,
    }));
  }
  return [];
}

export function getStepContactForm(step, language, defaultLanguage) {
  const contactFormModule = step.stepModule.find(sm => sm.type === STEP_MODULE_TYPE.form);
  if (contactFormModule) {
    return {
      content: contactFormModule.content[language] || contactFormModule.content[defaultLanguage] || {},
      properties: contactFormModule.properties,
      stepContactFormPublicId: contactFormModule.stepModuleStaticId,
    };
  }
  return {};
}

export function getStepIframe(step, language, defaultLanguage) {
  const iframeModule = step.stepModule.find(sm => sm.type === STEP_MODULE_TYPE.iframe);
  if (iframeModule) {
    const { iframeUrl } = iframeModule.content[language] || iframeModule.content[defaultLanguage] || {};
    return { iframeUrl };
  }
  return {};
}

export function getStepNps(step, language, defaultLanguage) {
  const npsModule = step.stepModule.find(sm => sm.type === STEP_MODULE_TYPE.nps);
  if (npsModule) {
    return {
      content: npsModule.content[language] || npsModule.content[defaultLanguage] || {},
      properties: npsModule.properties,
      npsModuleGuideId: step.guideId,
      npsModuleStepId: step.stepId,
      npsModuleId: npsModule.stepModuleStaticId,
    };
  }
  return {};
}

export function getStepCustomSurvey(step, language, defaultLanguage) {
  const customSurveyModule = step.stepModule.find(sm => sm.type === STEP_MODULE_TYPE.customSurvey);
  if (customSurveyModule) {
    return {
      content: customSurveyModule.content[language] || customSurveyModule.content[defaultLanguage] || {},
      properties: customSurveyModule.properties,
      customSurveyModuleGuideId: step.guideId,
      customSurveyModuleStepId: step.stepId,
      customSurveyModuleId: customSurveyModule.stepModuleStaticId,
    };
  }
  return {};
}

export function getStepAttachments(step, language, defaultLanguage) {
  const attachmentModules = step.stepModule
    .filter(sm => sm.type === STEP_MODULE_TYPE.attachment)
    .filter(module => {
      const content = module.content[language] || module.content[defaultLanguage];
      if (content && Object.keys(content).length === 0) return false;
      return Object.keys(module.content).includes(language) || Object.keys(module.content).includes(defaultLanguage);
    })
    .map(attachment => {
      const content = attachment.content[language] || attachment.content[defaultLanguage];
      return {
        ...content,
        added: attachment.added,
        removed: attachment.removed,
        stepModuleStaticId: attachment.stepModuleStaticId,
      };
    });
  return attachmentModules || [];
}

export function getStepInternalNotes(step, language, defaultLanguage) {
  const internalNoteModules = step.stepModule.filter(sm => sm.type === STEP_MODULE_TYPE.internalNote);

  return internalNoteModules.map(module => {
    const content = module.content[language] || module.content[defaultLanguage] || {};
    return { ...content, added: module.added, removed: module.removed, id: module.id };
  });
}

export function getStepChecklist(step, language, defaultLanguage) {
  const checklistItemModuleList = step.stepModule
    .filter(sm => sm.type === STEP_MODULE_TYPE.checklistItem)
    .sort((a, b) => a.properties?.ordering - b.properties?.ordering);
  const items = checklistItemModuleList.map(module => {
    const content = module.content[language] || module.content[defaultLanguage] || {};
    const completionConditionUrlObj = module.properties.completionCondition?.url || {};
    const actionOnClickUrlObj = module.properties.actionOnClick?.url || {};
    return {
      ...module.properties,
      allowManualCheck: !!module.properties.allowManualCheck,
      title: content.title || i18n('ChecklistEdit.UntitledItem'),
      completionCondition: {
        ...module.properties.completionCondition,
        url: completionConditionUrlObj[language]?.url || completionConditionUrlObj[defaultLanguage]?.url || '',
      },
      actionOnClick: {
        ...module.properties.actionOnClick,
        url: actionOnClickUrlObj[language]?.url || actionOnClickUrlObj[defaultLanguage]?.url || '',
      },
      checked: false,
      id: module.id || module.stepModuleStaticId,
    };
  });
  const [first] = items;
  const settings = {
    skipEnabled: !!first?.skipEnabled,
    restartOnPageRefresh: !!first?.restartOnPageRefresh,
    completeInOrder: !!first?.completeInOrder,
    allowManualChecklistReset: !!first?.allowManualChecklistReset,
    resetRights: first?.resetRights,
  };
  return { items, settings, checklistModuleGuideId: step.guideId };
}

export function getStepAutomation(step) {
  const automationModule = step.stepModule.find(sm => sm.type === STEP_MODULE_TYPE.automation);
  if (automationModule) {
    const {
      serverCallIds = [],
      fallbackTimeout,
      minimumDisplayTime,
      minimumDisplayTimeEnabled,
    } = automationModule.properties;
    const { localDataPlaceholdersProperties = {}, serverDataPlaceholdersProperties = {} } = automationModule;
    const serverCalls = serverCallIds.map(id => ({
      id,
      localPropertyNames: localDataPlaceholdersProperties[id] || [],
      serverPropertyNames: serverDataPlaceholdersProperties[id] || [],
    }));

    return {
      serverCalls,
      fallbackTimeout,
      minimumDisplayTime,
      minimumDisplayTimeEnabled: !!minimumDisplayTimeEnabled,
    };
  }
  return {};
}

export function getStepAiAnswer(step, language, defaultLanguage) {
  const aiAnswerModule = step.stepModule.find(sm => sm.type === STEP_MODULE_TYPE.aiAnswer);
  if (aiAnswerModule) {
    const content = aiAnswerModule.content[language] || aiAnswerModule.content[defaultLanguage] || {};
    return {
      hasNoSources:
        !aiAnswerModule.properties.stonlySources.included.length &&
        !Object.values(aiAnswerModule.properties.externalSources).some(sourceList => !!sourceList.length) &&
        !Object.values(aiAnswerModule.properties.documentSources).some(sourceList => !!sourceList.length),
      stepModuleId: aiAnswerModule.stepModuleStaticId,
      isConversationModeEnabled: true, //! !aiAnswerModule.properties.isConversationModeEnabled, //to uncomment when setting is ready
      // maxFollowupQuestions: aiAnswerModule.properties.maxFollowupQuestions, // to uncomment when ready/usable
      fallbackMessage: content.fallbackAnswer,
    };
  }
  return {};
}

export function getStepMedia(step, language, defaultLanguage) {
  const { mediaType: type, media } = step;
  if (type === 'none') {
    return { type, media: [] };
  }

  // TODO: remove both `Array.isArray` checks after STON-12725 deployment
  const purifiedMedia =
    (Array.isArray(media[language]) && media[language]) ||
    (Array.isArray(media[defaultLanguage]) && media[defaultLanguage]) ||
    [];

  return { type, media: purifiedMedia };
}

export function getIntroductionContent(step, language, defaultLanguage) {
  const contentModule = step.stepModule.find(sm => sm.type === STEP_MODULE_TYPE.introduction);

  if (contentModule) {
    return contentModule.content[language]?.content || contentModule.content[defaultLanguage]?.content || '';
  }
  return '';
}

export function getHasIntroduction(step) {
  return !!step.stepModule.find(sm => sm.type === STEP_MODULE_TYPE.introduction);
}

export function getEmbeddedGuideInfo(step) {
  const embeddedGuideModule = step.stepModule.find(sm => sm.type === STEP_MODULE_TYPE.embeddedGuide);

  if (embeddedGuideModule) {
    return embeddedGuideModule.properties;
  }
  return null;
}

export function replaceURLs(text = '') {
  let replacedText = text;

  const urlsToFilter = findNonStonlyLinks(replacedText);
  urlsToFilter.forEach(url => {
    // Handle case when url is a number
    const number = Number.parseInt(url.replaceAll(/\./gm, ''), 10);
    // If a found string could be transformed to number, we don't need to replace it.
    if (Number.isNaN(number) === false) return;

    const newReplacedUrl = url.replace(URL_REGEX, '$1$2*****$4$5');
    replacedText = replacedText.replace(url, newReplacedUrl);
  });

  return replacedText;
}

export function parseGuideData(loadedGuide, language, knowledgeBaseData = {}) {
  const firstGuide = loadedGuide.guides[0];
  if (firstGuide.access === 0) {
    return { guideInfo: { access: 0 } };
  }

  const { steps: _, stepNext: __, additionalOptions, options, title, ...rest } = loadedGuide.guides[0];
  const { defaultLanguage } = firstGuide.options;
  let stepNextToConvert = loadedGuide.guides.flatMap(g => g.stepNext);
  const titleToPass = getGuideTitle(title, language, defaultLanguage);
  let steps = loadedGuide.guides.flatMap(g => g.steps);
  const firstStep = steps.find(s => s.stepId === firstGuide.firstStepId);
  const guideInfo = {
    ...rest,
    options,
    teamId: additionalOptions.teamId,
    widgetId: additionalOptions.widgetId,
    startId: firstGuide.firstStepId,
    title: titleToPass,
    hasIntroduction: getHasIntroduction(firstStep),
    guideLinksDisabled: !additionalOptions.showLinks,
    ttsEnabled: options.ttsEnabled || knowledgeBaseData?.ttsEnabled,
    guideTeamKnowledgeBaseId: knowledgeBaseData?.teamKnowledgeBaseId,
  };

  if (guideInfo.guideLinksDisabled) {
    try {
      steps = JSON.parse(replaceURLs(JSON.stringify(steps)));
      stepNextToConvert = JSON.parse(replaceURLs(JSON.stringify(stepNextToConvert)));
      guideInfo.title = replaceURLs(guideInfo.title);
    } catch (e) {
      console.log(e);
    }
  }

  const stepConnectionList = stepNextToConvert.map(sn => {
    const {
      stepId,
      action,
      stepNextId,
      followingStepId,
      ordering,
      buttonEnabled,
      newTabEnabled,
      highlighted,
      condition,
      label,
      staticValue,
      navigationSettings,
      navigationType,
      conditionParam: visibility,
      conditionLocalProperties = [],
      conditionServerCallProperties = [],
      conditionType,
      media,
      aiStepNextType,
    } = sn;

    const labelToUse = getConnectionLabelAndURL(label, language, defaultLanguage);

    if (!labelToUse.linkUrl && String(followingStepId) === '-3') {
      // do additional attempt to find linkUrl in default language
      // see ADR#019
      const fallbackLinkUrl = getConnectionLabelAndURL(label, defaultLanguage).linkUrl;
      if (fallbackLinkUrl) {
        labelToUse.linkUrl = fallbackLinkUrl;
      }
    }

    // ADR#016
    let fallbackStepLabel = '';

    const currentStep = steps.find(s => s.stepId === stepId);
    const { guideId, moduleList, stepModule } = currentStep;
    const buttonSettingsModule = (moduleList || stepModule).find(m => m.type === STEP_MODULE_TYPE.buttonSettings);
    const { nextStepSelector } = buttonSettingsModule.properties;
    const stepStepNexts = stepNextToConvert.filter(stepNext => stepNext.stepId === stepId);

    if (
      followingStepId &&
      !labelToUse.label &&
      !(stepStepNexts.length === 1 && nextStepSelector === STEP_NEXT_SELECTOR_TYPE.button)
    ) {
      const followingStep = steps.find(s => s.stepId === followingStepId);
      if (followingStep && followingStep.stepType === STEP_TYPE.regular) {
        fallbackStepLabel = getStepTitle(followingStep, language, defaultLanguage);
      }
      if (followingStep && followingStep.stepType === STEP_TYPE.guide) {
        const embeddedGuideInfo = getEmbeddedGuideInfo(followingStep);
        if (embeddedGuideInfo) {
          fallbackStepLabel = getGuideTitle(embeddedGuideInfo.title, language, defaultLanguage);
        }
      }
      if (followingStepId === STEP_NEXT_TYPE.SPECIAL && action?.type) {
        fallbackStepLabel = SPECIAL_STEPS_BUTTON_NAME[action.type];
      }
    }
    // --- end of ADR#016

    return {
      id: stepNextId,
      label: labelToUse.label || fallbackStepLabel,
      linkUrl: labelToUse.linkUrl,
      staticValue,
      action,
      fromStepId: stepId,
      toStepId: followingStepId,
      ordering,
      navigationType,
      buttonEnabled: !!navigationSettings?.buttonEnabled || !!buttonEnabled,
      highlighted: !!navigationSettings?.buttonHighlighted || !!highlighted,
      newTabEnabled: !!navigationSettings?.newTabEnabled || !!newTabEnabled,
      conditionList: typeof condition === 'string' ? condition.split(',').filter(Boolean).map(Number) : undefined,
      visibility,
      conditionLocalProperties,
      conditionServerCallProperties,
      guideId,
      hasEnablingConditions:
        !!navigationSettings.conditionsToEnableRequired && !!navigationSettings.enablingConditions?.group.length,
      conditionType,
      media: media?.[language] || media?.[defaultLanguage] || {},
      isTextHidden: !!navigationSettings?.isTextHidden,
      aiStepNextType,
    };
  });

  const { guideVariables, guideVariableNameList } = getGuideVariablesData(loadedGuide.guides, language);
  const triggerSettings = parseTriggerSettings(loadedGuide.triggerSettings, language);

  return {
    guideInfo,
    guideOptions: { ...options, ...additionalOptions },
    steps,
    stepConnectionList: getSafeConnectionList({ steps, stepConnectionList, allGuides: loadedGuide.guides }),
    // all below has to be there
    contributors: loadedGuide.contributors,
    allGuides: loadedGuide.guides,
    knowledgeBaseUrl: loadedGuide.knowledgeBaseUrl,
    guideVariables,
    guideVariableNameList,
    triggerSettings,
  };
}

export function getRedirectUrl({ urlParams, urlSearch, basename = '', loadedGuide }) {
  const { title } = loadedGuide.guideInfo;
  const { view, pathToLoad, slug, id, mode, language } = urlParams;
  const titleSlug = createSlug(title);
  const allExplanationSteps = [0, -1, -2, -4, ...loadedGuide.steps.map(s => s.stepId)];
  const stepsPath = createPathFromParam(pathToLoad);
  const currentStep = stepsPath.at(-1);
  const currentStepIncorrect = !allExplanationSteps.includes(currentStep);
  const isAdStep = currentStep === -4;
  const isPreview = view === 'PreviewSteps';

  const shouldRedirect =
    !view ||
    !pathToLoad ||
    ((mode === 'guide' || mode === 'kb') && (!slug || slug !== `${titleSlug}-`)) ||
    currentStepIncorrect ||
    isAdStep ||
    (mode === 'guide' && loadedGuide.knowledgeBaseUrl && !isPreview) ||
    !loadedGuide.guideInfo.options.languageList.includes(language);

  if (!shouldRedirect) return null;

  const { hasIntroduction, startId } = loadedGuide.guideInfo;
  let validUrl = `${basename}/${mode}/`;

  if (mode === 'kb' || mode === 'kb-widget') {
    validUrl += `guide/`;
  }

  validUrl += loadedGuide.guideInfo.options.languageList.includes(language)
    ? `${language}/`
    : `${loadedGuide.guideInfo.options.defaultLanguage}/`;

  validUrl += mode === 'kb' || mode === 'guide' ? `${titleSlug}-${id}/` : `${id}/`;

  validUrl += view ? `${view}/` : 'Steps/';

  if (!pathToLoad || currentStepIncorrect || isAdStep) {
    if (!pathToLoad || currentStepIncorrect) {
      validUrl += hasIntroduction ? 'introduction' : `${startId}`;
    } else if (isAdStep) {
      const stepsPathUpdated = [...stepsPath].reverse().slice(1).reverse();
      validUrl += stepsPathUpdated;
    }
  } else {
    validUrl += `${pathToLoad}`;
  }
  if (urlSearch) validUrl += urlSearch;

  if (loadedGuide.knowledgeBaseUrl && mode === 'guide' && !isPreview) {
    validUrl = `${loadedGuide.knowledgeBaseUrl}${validUrl}`;
  }

  return validUrl;
}

// created for future-proofing stepConnections (support for potential future data structure)
// this function filters out all non-button connections
export const filterOutNonButtonNavigationType = (stepConnection, performFiltering) => {
  if (performFiltering) {
    // if used in a preview player
    if (stepConnection.isNavigationTypeButton) return true;

    if (stepConnection.conditionType === STEP_NEXT_CONDITION_TYPE.automation) return true;

    // if used in a regular player
    if (stepConnection.navigationType && stepConnection.navigationType === STEP_NAVIGATION_TYPES.BUTTON) {
      return true;
    }
    if (
      stepConnection.navigationType &&
      stepConnection.navigationType === STEP_NAVIGATION_TYPES.BUTTON_AND_INTERACTION
    ) {
      return true;
    }
    return false;
  }
  // we don't want to filter anything for non-GUIDED_TOURs
  return true;
};

export const getStepInfo = ({ guide, currentStepId, stepsPath, language, adContinueNextStep }) => {
  const {
    steps,
    stepConnectionList,
    guideInfo: { startId, type, guideId: mainGuideId },
    guideOptions: { defaultLanguage },
    allGuides,
    triggerSettings,
  } = guide;

  const currentStep = steps.find(s => s.stepId === currentStepId);
  const currentStepGuide = allGuides.find(g => g.guideId === currentStep?.guideId); // case when the guide is embedded
  const isStepOfEmbeddedGuide = mainGuideId !== currentStep?.guideId;

  let stepId = null;
  let stepType = '';
  let title = '';
  let content = [];
  let attachments = [];
  let internalNotes = [];
  let nextSteps = [];
  let nextStepsByStepId = [];
  let stepInputs = [];
  let stepIllustrations = [];
  let stepCommunications = [];
  const specialStepInfo = {};
  let nextStepSelector = '';
  let illustrationType = '';
  let showBackButton = true;
  let shouldGoNextOnSelection = false;
  let tileLayout = '';
  let tileImagePosition = '';
  let tileImageSize = '';
  let buttonSettingsContent = {};
  let snoozeButtonSettings;

  if ([-1, -2, -4].includes(currentStepId)) {
    if (currentStepId === -1) {
      postMessage({ type: 'endOfExplanation' });
      title = i18n('ExplanationPlayer.ExplanationCompleted');
      stepType = 'finalStep';
      illustrationType = 'none';
      content = '';
    }
    if (currentStepId === -2) {
      stepId = -2;
      postMessage({ type: 'explanationIntroduction' });
      title = i18n('ExplanationPlayer.Introduction');
      stepType = 'introduction';
      illustrationType = 'none';
      const firstStep = steps.find(step => step.stepId === startId);
      content = getIntroductionContent(firstStep, language, defaultLanguage);
      nextSteps = [
        {
          fromStepId: -2,
          toStepId: startId,
          action: null,
          buttonEnabled: true,
          conditionList: undefined,
          highlighted: false,
          label: i18n('ExplanationPlayer.Start'),
          linkUrl: '',
          newTabEnabled: true,
          ordering: 0,
          id: stepId, // <-- important for conditional next steps
          visibility: STEP_NEXT_VISIBILITY.always,
        },
      ];
    }
    if (currentStepId === -4) {
      const authorId = guide.contributors.length > 0 ? guide.contributors[0].userId : '-1';
      stepId = -4;
      title = i18n('ExplanationPlayer.StonlyAdTitle');
      stepType = 'stonlyAd';
      illustrationType = 'icon';
      nextStepSelector = STEP_NEXT_SELECTOR_TYPE.button;
      stepIllustrations = [
        {
          type: 'icon',
          backgroundColor: '#283d7b',
          iconColor: '#FFFFFF',
          iconName: 'stonlyLogo',
          circleOpacity: 0,
          getRaw: true,
        },
      ];
      content = i18n('ExplanationPlayer.StonlyAdText', { utmSource: authorId }, undefined, { raw: true });
      nextSteps = [
        {
          fromStepId: -4,
          toStepId: adContinueNextStep,
          action: null,
          buttonEnabled: true,
          conditionList: undefined,
          highlighted: false,
          label: i18n('ExplanationPlayer.StonlyAdButtonContinue'),
          linkUrl: '',
          newTabEnabled: true,
          ordering: 0,
          id: stepId, // <-- important for conditional next steps
          visibility: STEP_NEXT_VISIBILITY.always,
        },
      ];
    }
  } else {
    const stepMedia = getStepMedia(currentStep, language, defaultLanguage);
    stepIllustrations = stepMedia.media;
    illustrationType = stepMedia.type;

    // Why do we need to check guide type + modules (and not modules only)? See ADR#021
    const isCurrentStepValidGuidedTourStep =
      type === GUIDE_TYPE.GUIDED_TOUR && currentStep && currentStepGuide?.type === GUIDE_TYPE.GUIDED_TOUR
        ? !!(currentStep.moduleList || currentStep.stepModule).find(m => m.type === STEP_MODULE_TYPE.guidedTour)
        : false;

    nextStepsByStepId = stepConnectionList.filter(sc => sc.toStepId).filter(sc => sc.fromStepId === +currentStepId);
    // filter out non-buttons for guided-tours
    nextSteps = nextStepsByStepId.filter(sc => filterOutNonButtonNavigationType(sc, isCurrentStepValidGuidedTourStep));

    if (currentStep) {
      title = getStepTitle(currentStep, language, defaultLanguage);
      const buttonSettingsModule = (currentStep.moduleList || currentStep.stepModule).find(
        m => m.type === STEP_MODULE_TYPE.buttonSettings
      );
      nextStepSelector = buttonSettingsModule.properties.nextStepSelector;
      showBackButton = !!buttonSettingsModule.properties.showBackButton;
      shouldGoNextOnSelection = !!buttonSettingsModule.properties.shouldGoNextOnSelection;
      tileLayout = buttonSettingsModule.properties.tileLayout;
      tileImagePosition = buttonSettingsModule.properties.tileImagePosition;
      tileImageSize = buttonSettingsModule.properties.tileImageSize;

      ({ stepId, stepType } = currentStep);
    }
    nextSteps = nextSteps.map(sc => {
      return {
        ...sc,
        label:
          nextSteps.length === 1 && nextStepSelector === STEP_NEXT_SELECTOR_TYPE.button
            ? sc.label
            : sc.label || sc.title || sc.linkUrl,
      };
    });

    stepInputs = getStepInput(currentStep, language, defaultLanguage);
    stepCommunications = getStepCommunications(currentStep);
    content = getStepContent(currentStep, language, defaultLanguage);
    attachments = getStepAttachments(currentStep, language, defaultLanguage);
    internalNotes = getStepInternalNotes(currentStep, language, defaultLanguage);
    buttonSettingsContent = getButtonSettingsContent(currentStep, language, defaultLanguage);

    // specialStepsInfo
    specialStepInfo.stepContactForm = getStepContactForm(currentStep, language, defaultLanguage);
    specialStepInfo.iframe = getStepIframe(currentStep, language, defaultLanguage);
    specialStepInfo.nps = getStepNps(currentStep, language, defaultLanguage);
    specialStepInfo.customSurvey = getStepCustomSurvey(currentStep, language, defaultLanguage);
    specialStepInfo.checklist = getStepChecklist(currentStep, language, defaultLanguage);
    specialStepInfo.automation = getStepAutomation(currentStep);
    specialStepInfo.aiAnswer = getStepAiAnswer(currentStep, language, defaultLanguage);

    // Add snooze option to the first step of the guide
    if (currentStepId === startId && triggerSettings?.snoozeSettings) {
      const { label, shouldUseGuideButtonStyle, buttonColor, duration, durationType } = triggerSettings.snoozeSettings;
      const snoozeAction = {
        type: STEP_WIDGET_ACTION_TYPES.TRIGGER_SNOOZE,
        value: { duration, durationType },
      };

      if (shouldUseGuideButtonStyle && [STEP_TYPE.regular, STEP_TYPE.iframe].includes(currentStep.stepType)) {
        // Snooze will be added as a step next
        const snoozeOption = {
          fromStepId: currentStepId,
          toStepId: STEP_NEXT_TYPE.SPECIAL,
          action: snoozeAction,
          visibility: STEP_NEXT_VISIBILITY.always,
          id: generateTempId(),
          label,
          conditionType: STEP_NEXT_CONDITION_TYPE.visibility,
          buttonEnabled: true,
        };

        nextSteps.push(snoozeOption);
        nextStepsByStepId.push(snoozeOption);
      } else {
        // Populate snooze button settings that will be applied to standalone snooze button
        snoozeButtonSettings = {
          label,
          buttonColor,
          shouldUseGuideButtonStyle,
          action: snoozeAction,
        };
      }
    }

    nextSteps.sort((obj1, obj2) => obj1.ordering - obj2.ordering);
    // [ADR#012] to rethink this workaround for missing empty stepNext that we were always assuming would be here
    nextSteps = nextSteps.length ? nextSteps : [{}];

    // We make old -1 work as well as make a new "forced because steps have shared mid-step -1" work
    nextSteps = nextSteps.map(ns =>
      ns.toStepId && ns.toStepId !== -1 ? ns : findEmbeddedNextStepBasedOnPath(ns, stepsPath, guide)
    );
  }

  // Sync "nextStepsByStepId" with "nextSteps" by adding connections that were found in embedded guides as well as
  // "introduction" and "advertisement" steps
  nextSteps.forEach(ns => {
    if (ns.id && !nextStepsByStepId.find(({ id }) => id === ns.id)) {
      nextStepsByStepId.push(ns);
    }
  });

  return {
    stepId,
    stepType,
    title,
    content,
    attachments,
    internalNotes,
    nextSteps,
    stepInputs,
    stepIllustrations,
    stepCommunications,
    nextStepSelector,
    showBackButton,
    shouldGoNextOnSelection,
    tileLayout,
    tileImagePosition,
    tileImageSize,
    buttonSettingsContent,
    illustrationType,
    specialStepInfo,
    stepConnectionList: nextStepsByStepId,
    isStepOfEmbeddedGuide,
    snoozeButtonSettings,
  };
};

export function getGuideVariablesData(guides, language) {
  const guideVariableModules = guides
    .flatMap(g => g.guideModules)
    .filter(module => module && module.type === GUIDE_MODULE_TYPE.guideVariables);

  let guideVariableNameList = guideVariableModules.flatMap(m => m.content[language]?.list).filter(Boolean);
  guideVariableNameList = [...new Set(guideVariableNameList)];

  const guideVariables = guideVariableModules
    .map(m => m.content[language]?.variables)
    .reduce(
      (acc, variable) => ({
        ...acc,
        ...variable,
      }),
      {}
    );

  return {
    guideVariables,
    guideVariableNameList,
  };
}

export function getGuideIdAndStepFromLink(link) {
  const guideDataRegex = /\/guide\/[a-z]{2,7}\/([^/]*?)(?<guideId>\w{10}|\d+)\/\w+\/(?<stepId>\d+)$/;
  if (guideDataRegex.test(link)) {
    const match = guideDataRegex.exec(link);
    return {
      guideId: match.groups.guideId,
      stepId: match.groups.stepId,
    };
  }
  return null;
}

function parseTriggerSettings(triggerSettings, guideLanguage) {
  if (!triggerSettings?.snoozeSettings) {
    return;
  }

  const { buttonColor, label, defaultLanguage, shouldUseGuideButtonStyle, duration, durationType } =
    triggerSettings.snoozeSettings;

  return {
    snoozeSettings: {
      buttonColor,
      shouldUseGuideButtonStyle,
      label: label[guideLanguage] || i18n('ExplanationPlayer.SnoozeButtonPlaceholder') || label[defaultLanguage],
      duration: +duration,
      durationType,
    },
  };
}

export { mergeGuideStates } from './guidePlayerHelpers.pure';
