import React, { useState } from 'react';
import styled from 'styled-components';
import Avatar from '@playerCommon/CustomElements/Avatar/Avatar.jsx';
import PropTypes from 'prop-types';
import i18n from 'helpers/i18n';
import Textarea from '@playerCommon/StandardElements/Textarea/Textarea.js';
import callApi from 'helpers/apiHelpers.js';
import TextLink from '@playerCommon/StandardElements/TextLink';
import { extractBaseData } from 'components/Analytics/recorder';
import useAuthenticationFlow from '@playerCommon/Authentication/useAuthenticationFlow';

const Canvas = styled.div``;

const CanvasLogout = styled.div`
  display: flex;
  color: #999999;
  white-space: nowrap;
  flex-wrap: wrap;
  line-height: 1.3;
`;

const CommentForm = styled.form`
  display: flex;
`;

const PostComment = styled(TextLink)`
  width: ${({ show }) => (show ? '112px' : '0px')};
  padding: ${({ show, width }) => (show && width > 620 ? '0 20px 0 24px' : '0 0px 0 8px')};
  opacity: ${({ show }) => (show ? '1' : '0')};
  overflow: hidden;
  transition: width 0.3s;
`;

const CommentField = styled(Textarea)`
  margin-left: 12px;
`;

const NewComment = ({
  stepId,
  guideId,
  currentGuideId,
  userManagement,
  parentId = null,
  updateStepComments,
  className,
  width,
}) => {
  const [stepComment, setStepComment] = useState('');
  const { onOpenSignIn } = useAuthenticationFlow();
  const { user } = userManagement;

  const setTitleInput = e => {
    setStepComment(e.target.value);
  };

  const postComment = async () => {
    const { headline, firstName, lastName, picture, pictureSecureHash } = user;
    const baseData = await extractBaseData();
    const res = await callApi('v1/stepReaction/comment', 'post', {
      reaction: stepComment,
      reactionType: 'comment',
      stepId,
      guideId,
      originalGuideId: currentGuideId,
      parentId,
      ...baseData,
    });
    const newComment = {
      stepCommentId: res.data.id,
      comment: stepComment,
      parentId,
      stepId,
      headline,
      guideId,
      firstName,
      lastName,
      picture,
      pictureSecureHash,
      commentDate: Date.now(),
    };

    setStepComment('');
    updateStepComments(newComment);
  };

  if (Object.keys(user).length === 0) {
    return (
      <CanvasLogout>
        {i18n('StepComments.PreSignIn')}
        <TextLink type="lowlight" dataCy="signIn" onClick={() => onOpenSignIn({ returnUrl: window.location.href })}>
          &nbsp;
          {i18n('StepComments.SignIn')}
          &nbsp;
        </TextLink>
        {i18n('StepComments.PostRegister')}
      </CanvasLogout>
    );
  }

  const addCommentCopy = i18n('StepComments.AddComment');
  return (
    <Canvas className={className}>
      <CommentForm>
        <Avatar
          src={user.picture}
          srcSecureHash={user.pictureSecureHash}
          type="user"
          targetName={`${user.firstName} ${user.lastName}`}
          numberForBackground={user.creationDate}
        />
        <CommentField
          dataCy="commentField"
          skin="grey"
          onChange={setTitleInput}
          value={stepComment}
          placeholder={addCommentCopy}
          ariaLabel={addCommentCopy}
        />

        <PostComment dataCy="postComment" type="lowlight" show={stepComment} width={width} onClick={postComment}>
          {i18n('StepComments.AddCommentLink')}
        </PostComment>
      </CommentForm>
    </Canvas>
  );
};

NewComment.propTypes = {
  updateStepComments: PropTypes.func,
  userManagement: PropTypes.object,
  stepId: PropTypes.number,
  guideId: PropTypes.string,
  currentGuideId: PropTypes.string,
  parentId: PropTypes.number,
  width: PropTypes.number,
  className: PropTypes.string,
};

export default NewComment;
