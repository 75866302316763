import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const RadioCheck = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 12px;
  width: 12px;
  border: 1.5px solid ${props => props.theme.grey};
  border-radius: 50%;
  transition: border 0.2s, background-color 0.2s, box-shadow 0.2s;
`;

const LabelButton = styled.label`
  display: block;
  position: relative;
  padding-left: 28px;
  user-select: none;
  margin-bottom: 12px;
  color: ${props => props.theme.darkGrey};
  outline: 0;

  &:last-child {
    margin-bottom: 0;
  }

  input {
    position: absolute;
    opacity: 0;
  }

  & > input:checked + ${RadioCheck} {
    background: ${({ theme }) => theme.highlightColor};
    border: 1.5px solid ${({ theme }) => theme.highlightColor};
  }

  ${({ disabled }) =>
    !disabled &&
    css`
      &,
      label,
      input {
        cursor: pointer;
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover ${RadioCheck}, & > input:focus + ${RadioCheck} {
          border: 1.5px solid ${({ theme }) => theme.highlightColor};
        }
      }
    `}
  body.navigating-with-keyboard & > input:focus + ${RadioCheck} {
    box-shadow: 0px 0px 0px 2px ${props => props.theme.white}, 0px 0px 0px 4px ${props => props.theme.darkBlue};
  }

  ${({ added, removed, modified, theme }) => {
    if (removed) {
      return css`
        color: ${theme.amaranth};
        span {
          border-color: ${theme.amaranth};
          background: ${theme.changesRemovedColor};
        }
      `;
    }
    if (added) {
      return css`
        color: ${theme.seaweed};
        span {
          border-color: ${theme.seaweed};
          background: ${theme.changesAddedColor};
        }
      `;
    }
    if (modified) {
      return css`
        color: ${theme.mango};
        span {
          border-color: ${theme.mango};
          background: ${theme.changesModifiedColor};
        }
      `;
    }
  }}
`;

const RadioButton = ({
  id,
  label,
  onClick,
  value,
  checked,
  disabled,
  dataCy,
  dataStaticValue,
  added,
  removed,
  modified,
  className,
}) => (
  <LabelButton
    data-static-value={dataStaticValue}
    added={added}
    removed={removed}
    modified={modified}
    className={className}
  >
    <label htmlFor={id}>{label || ' '}</label>
    <input
      id={id}
      type="radio"
      name={label}
      onClick={onClick}
      value={value}
      checked={checked}
      data-cy={dataCy}
      disabled={disabled}
      readOnly
      tabIndex="0"
    />
    <RadioCheck className="radio-check" />
  </LabelButton>
);

RadioButton.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  checked: PropTypes.bool,
  dataCy: PropTypes.string,
  dataStaticValue: PropTypes.string,
  disabled: PropTypes.bool,
  added: PropTypes.bool,
  removed: PropTypes.bool,
  modified: PropTypes.bool,
  className: PropTypes.string,
};

export default RadioButton;
