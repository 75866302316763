import React, { useEffect, useMemo, useImperativeHandle, forwardRef, createRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FILE_SIZES } from 'global';
import i18n from 'helpers/i18n';
import AttachmentIconSVG from 'icons/attachmentIcon.svg';
import useDidUpdate from '@playerCommon/hooks/useDidUpdate';
import { validateEmail, validateEmptyString, validatePhone } from 'helpers/validationHelpers.js';
import { replaceVarNamesWithValues } from '@stonlyCommons/helpers/guideVariableHelpers.js';
import { useSentData, useSetInternalData } from '@playerCommon/Contexts/sentDataContext';
import { useUserData } from '@playerCommon/Contexts/userDataContext';
import useForm from '@playerCommon/StandardElements/BaseInputs/useForm';
import { useServerCallData } from '@playerCommon/Contexts/serverCallDataContext';
import { ColumnFlex } from '@playerCommon/ui/components/Flex';
import { InputFileList } from '@playerCommon/ui/components/inputs/InputFileList';
import { useStepInputsState } from '../provider/StepInputsProvider';
import StepInput from './StepInput';
import StepInputOld from './StepInputOld';
import StepInputAttachmentOld from './StepInputAttachmentOld';
import StepInputSingleAttachmentOld from './StepInputSingleAttachmentOld';
import StepInputSingleAttachment from './StepInputSingleAttachment';
import { STEP_INPUTS_TYPE, STEP_INPUTS_CONTENT_TYPE } from './StepInputs.consts';

const validateTotalFilesSize = files => {
  const totalFilesSize = files?.reduce((acc, cur) => acc + cur.size, 0);
  return totalFilesSize > FILE_SIZES.TEN_MEGABYTES;
};

const emailValidators = ({ stepInputName, customErrorMessage, required }) => {
  return [
    values =>
      validateEmptyString(values[stepInputName]) &&
      (validateEmail(values[stepInputName]?.replaceAll(/\s+/g, '').toLowerCase()) || {
        [stepInputName]: i18n('StepInputs.EmailValidationFailed'),
      }),
    required
      ? values =>
          validateEmptyString(values[stepInputName]) || {
            [stepInputName]: customErrorMessage || i18n('StepInputs.RequiredValidationFailed'),
          }
      : () => {},
  ];
};

const textValidators = ({ stepInputName, customErrorMessage, required }) => {
  return [
    required
      ? values =>
          validateEmptyString(values[stepInputName]) || {
            [stepInputName]: customErrorMessage || i18n('StepInputs.RequiredValidationFailed'),
          }
      : () => {},
  ];
};

const phoneValidators = ({ stepInputName, customErrorMessage, required }) => {
  return [
    values =>
      validateEmptyString(values[stepInputName]) &&
      (validatePhone(values[stepInputName]) || {
        [stepInputName]: i18n('StepInputs.PhoneValidationFailed'),
      }),
    required
      ? values =>
          validateEmptyString(values[stepInputName]) || {
            [stepInputName]: customErrorMessage || i18n('StepInputs.RequiredValidationFailed'),
          }
      : () => {},
  ];
};

const checkboxValidators = ({ stepInputName, customErrorMessage, required }) => {
  return [
    required
      ? values =>
          !!values[stepInputName] || {
            [stepInputName]: customErrorMessage || i18n('StepInputs.RequiredValidationFailed'),
          }
      : () => {},
  ];
};

// eslint-disable-next-line sonarjs/no-identical-functions
const dropdownValidators = ({ stepInputName, customErrorMessage, required }) => {
  return [
    required
      ? values =>
          validateEmptyString(values[stepInputName]) || {
            [stepInputName]: customErrorMessage || i18n('StepInputs.RequiredValidationFailed'),
          }
      : () => {},
  ];
};

const attachmentValidators = ({ stepInputName, customErrorMessage, required }) => {
  return [
    values =>
      values[stepInputName]?.length !== 0 &&
      (!validateTotalFilesSize(values[stepInputName]) || {
        [stepInputName]: i18n('Validation.FileSizeTooBig'),
      }),
    required
      ? values =>
          values[stepInputName]?.length !== 0 || {
            [stepInputName]: customErrorMessage || i18n('StepInputs.RequiredValidationFailed'),
          }
      : () => {},
  ];
};

const getPlaceholder = ({
  stepInputSubtype,
  stepInputIsObligatoryInput,
  stepInputPlaceholder,
  stepInputContentType,
  userData,
  localData,
  serverCallVariables,
  isContactForm,
}) => {
  const placeholderToDisplay = replaceVarNamesWithValues({
    content: stepInputPlaceholder,
    userData,
    localData,
    serverCallVariables,
  });
  if (stepInputContentType === STEP_INPUTS_CONTENT_TYPE.placeholder) {
    if (stepInputSubtype === STEP_INPUTS_TYPE.EMAIL && !stepInputIsObligatoryInput) {
      return placeholderToDisplay || i18n('StepInputs.DefaultPlaceholderEmail');
    }
    if (stepInputSubtype === STEP_INPUTS_TYPE.TEXT && !stepInputIsObligatoryInput) {
      return placeholderToDisplay || i18n('StepInputs.DefaultPlaceholderShorttext');
    }
    if (stepInputSubtype === STEP_INPUTS_TYPE.LONG_TEXT && !stepInputIsObligatoryInput) {
      return placeholderToDisplay || i18n('StepInputs.DefaultPlaceholderLongtext');
    }
    if (stepInputSubtype === STEP_INPUTS_TYPE.PHONE) {
      return placeholderToDisplay || i18n('StepInputs.DefaultPlaceholderPhone');
    }
  }
  if (stepInputSubtype === STEP_INPUTS_TYPE.DROPDOWN) {
    return placeholderToDisplay || i18n('StepInputs.DefaultPlaceholderDropdownList');
  }
  if (stepInputSubtype === STEP_INPUTS_TYPE.ATTACHMENT) {
    return (
      placeholderToDisplay ||
      (isContactForm ? i18n('HelpcenterContact.FileInput') : i18n('StepInputs.DefaultPlaceholderFileInput'))
    );
  }
  return placeholderToDisplay;
};

const getLabel = ({ stepInputLabel, userData, localData, serverCallVariables }) => {
  return replaceVarNamesWithValues({
    content: stepInputLabel,
    userData,
    localData,
    serverCallVariables,
  });
};

const getValue = ({
  stepInput,
  prevValue,
  userData,
  localData,
  inputValue,
  isPreview,
  isDirty,
  serverCallVariables,
}) => {
  const stepInputSubtype = stepInput.properties.subtype;
  const stepInputHiddenValue = stepInput.content.hiddenValue;
  const stepInputDefaultValue = stepInput.content.defaultValue;

  if (stepInputSubtype === STEP_INPUTS_TYPE.ATTACHMENT) {
    return inputValue || [];
  }

  const value = isDirty
    ? stepInputHiddenValue || (isPreview ? '' : inputValue) || prevValue || stepInputDefaultValue || ''
    : stepInputHiddenValue || prevValue || stepInputDefaultValue || (isPreview ? '' : inputValue) || '';

  if (stepInputSubtype === STEP_INPUTS_TYPE.CHECKBOX) {
    return value || false;
  }

  return replaceVarNamesWithValues({
    content: typeof value === 'string' ? value : '',
    userData,
    localData,
    isPreview,
    serverCallVariables,
  });
};

const StepInputs = forwardRef(({ stepInputs, prevData, isPreview, isContactForm }, ref) => {
  const { inputValues, setInputValues, setIsInputValuesValid, dirtyInputs, setDirtyInputs } = useStepInputsState();
  const sortedStepInputs = useMemo(
    () => stepInputs.sort((a, b) => a.properties.ordering - b.properties.ordering),
    [stepInputs]
  );
  const stepId = useMemo(() => stepInputs[0]?.stepId, [stepInputs]);
  const { localData } = useSentData();
  const userData = useUserData();
  const { serverCallVariables } = useServerCallData();
  const setInternalData = useSetInternalData();

  const initialFormState = useMemo(
    () =>
      stepInputs.reduce(
        (acc, stepInput) => ({
          ...acc,
          [stepInput.stepInputModuleId]: getValue({
            stepInput,
            prevValue: prevData && prevData[stepInput.stepInputModuleId], // values which are saved in Local Storage
            userData,
            localData,
            inputValue: inputValues[stepInput.stepInputModuleId], // values which are saved in inputValues context
            isPreview,
            isDirty: dirtyInputs[stepInput.stepInputModuleId], // checking if the value was filled by the user
            serverCallVariables,
          }),
        }),
        []
      ),
    [stepInputs]
  );

  const validations = useMemo(
    () =>
      stepInputs.reduce((acc, stepInput) => {
        const { stepInputModuleId, properties, content } = stepInput;
        const stepInputSubtype = properties.subtype;
        const stepInputRequired = properties.isRequired;
        const stepInputContentType = properties.contentType;
        const isHiddenValue = stepInputContentType === STEP_INPUTS_CONTENT_TYPE.hiddenValue;
        const stepInputErrorMessage = content.errorMessage;
        const stepInputShouldShowIfEmpty = properties.shouldShowIfEmpty;

        if (isHiddenValue && !stepInputShouldShowIfEmpty) return [...acc];

        if (!stepInputRequired) {
          return [...acc];
        }

        if (stepInputSubtype === STEP_INPUTS_TYPE.TEXT) {
          return [
            ...acc,
            ...textValidators({
              stepInputName: stepInputModuleId,
              customErrorMessage: stepInputErrorMessage,
              required: stepInputRequired,
            }),
          ];
        }
        if (stepInputSubtype === STEP_INPUTS_TYPE.EMAIL) {
          return [
            ...acc,
            ...emailValidators({
              stepInputName: stepInputModuleId,
              customErrorMessage: stepInputErrorMessage,
              required: stepInputRequired,
            }),
          ];
        }
        if (stepInputSubtype === STEP_INPUTS_TYPE.PHONE) {
          return [
            ...acc,
            ...phoneValidators({
              stepInputName: stepInputModuleId,
              customErrorMessage: stepInputErrorMessage,
              required: stepInputRequired,
            }),
          ];
        }
        if (stepInputSubtype === STEP_INPUTS_TYPE.LONG_TEXT) {
          return [
            ...acc,
            ...textValidators({
              stepInputName: stepInputModuleId,
              customErrorMessage: stepInputErrorMessage,
              required: stepInputRequired,
            }),
          ];
        }
        if (stepInputSubtype === STEP_INPUTS_TYPE.CHECKBOX) {
          return [
            ...acc,
            ...checkboxValidators({
              stepInputName: stepInputModuleId,
              customErrorMessage: stepInputErrorMessage,
              required: stepInputRequired,
            }),
          ];
        }
        if (stepInputSubtype === STEP_INPUTS_TYPE.DROPDOWN) {
          return [
            ...acc,
            ...dropdownValidators({
              stepInputName: stepInputModuleId,
              customErrorMessage: stepInputErrorMessage,
              required: stepInputRequired,
            }),
          ];
        }
        if (stepInputSubtype === STEP_INPUTS_TYPE.ATTACHMENT) {
          return [
            ...acc,
            ...attachmentValidators({
              stepInputName: stepInputModuleId,
              customErrorMessage: stepInputErrorMessage,
              required: stepInputRequired,
            }),
          ];
        }
        return acc;
      }, []),
    [stepInputs]
  );

  const inputRefs = useMemo(
    () =>
      stepInputs.reduce(
        (acc, stepInput) => ({
          ...acc,
          [stepInput.stepInputModuleId]: createRef(),
        }),
        []
      ),
    [stepInputs]
  );

  const totalAttachmentSizePerStep = useMemo(
    () =>
      isContactForm
        ? 0 // not applicable for inputs in contact form
        : sortedStepInputs
            .filter(input => input.properties.subtype === STEP_INPUTS_TYPE.ATTACHMENT)
            .map(input => inputValues[input.stepInputModuleId]?.[0])
            .filter(Boolean)
            .reduce((acc, cur) => acc + cur.size, 0),
    [inputValues, sortedStepInputs, isContactForm]
  );

  const onFileUploadSuccess = useCallback(
    ({ name, result } = {}) => {
      const stepInput = sortedStepInputs.find(input => input.stepInputModuleId === name);
      if (stepInput?.properties.key && stepInput?.properties.isInputSavedAsLocalVariable) {
        setInternalData({ [stepInput.properties.key]: result });
      }
    },
    [sortedStepInputs, setInternalData]
  );

  const onFileRemove = useCallback(
    name => {
      const stepInput = sortedStepInputs.find(input => input.stepInputModuleId === name);
      if (stepInput?.properties.key) {
        // TODO (Kyrylo): get rid of setTimeout. This is a hack needed to fix the issue that the attachment input value remains after being removed.
        // This is due to outdated inputValues and formValues inside useDidUpdate hook.
        setTimeout(() => setInternalData({ [stepInput.properties.key]: undefined }), 0);
      }
    },
    [sortedStepInputs, setInternalData]
  );

  const {
    formValues,
    handleFormChange,
    handleFormValueChange,
    handleWillSubmit,
    handleFormBlur,
    handleFormFocus,
    dirtyFormFields,
    isFormValid,
    formStatus,
    updateFormState,
  } = useForm({ initialFormState, validations, inputRefs });

  useImperativeHandle(ref, () => {
    return { handlePreSendActions: () => handleWillSubmit() };
  });

  useEffect(() => {
    setIsInputValuesValid(isFormValid);
    const newInputValues = {
      ...inputValues,
      ...formValues,
    };
    setInputValues(newInputValues);
    setDirtyInputs(prevDirtyInputs => {
      return {
        ...prevDirtyInputs,
        ...Object.fromEntries(Object.entries(dirtyFormFields).filter(([, val]) => val === true)),
      };
    });
    return () => {
      setIsInputValuesValid(true);
    };
  }, [formValues, dirtyFormFields, isFormValid]);

  const onBlur = useCallback(
    e => {
      handleFormBlur(e);

      if (e.persist) {
        e.persist();
      }

      const fieldName = e.target.name;
      const { value } = e.target;

      const stepInput = sortedStepInputs.find(input => input.stepInputModuleId === fieldName);
      if (stepInput?.properties.key && stepInput?.properties.isInputSavedAsLocalVariable) {
        setInternalData({ [stepInput.properties.key]: value });
      }
    },
    [sortedStepInputs, handleFormBlur, setInternalData]
  );

  const onChangeValue = useCallback(
    ({ name, value } = {}) => {
      handleFormValueChange({ name, value });

      const stepInput = sortedStepInputs.find(input => input.stepInputModuleId === name);
      if (stepInput?.properties.key && stepInput?.properties.isInputSavedAsLocalVariable && value !== undefined) {
        setTimeout(() => setInternalData({ [stepInput.properties.key]: value.toString() }), 0);
      }
    },
    [sortedStepInputs, handleFormValueChange, setInternalData]
  );

  useDidUpdate(() => {
    const partialStateToUpdate = {};
    stepInputs.forEach(stepInput => {
      const newValue = getValue({
        stepInput,
        prevValue: prevData && prevData[stepInput.stepInputModuleId], // values which are saved in Local Storage
        userData,
        localData,
        inputValue: inputValues[stepInput.stepInputModuleId], // values which are saved in inputValues context
        isPreview,
        isDirty: dirtyInputs[stepInput.stepInputModuleId], // checking if the value was filled by the user
        serverCallVariables,
      });

      if (JSON.stringify(newValue) !== JSON.stringify(formValues[stepInput.stepInputModuleId])) {
        partialStateToUpdate[stepInput.stepInputModuleId] = newValue;
      }
    });
    updateFormState(partialStateToUpdate);
  }, [stepInputs, localData]);

  return (
    // <ColumnFlex gap={2} marginBottom={2}> //TODO @Wojtek uncomment after applying new inputs
    <ColumnFlex>
      {sortedStepInputs
        .filter(
          stepInput =>
            stepInput.properties.contentType !== STEP_INPUTS_CONTENT_TYPE.hiddenValue ||
            stepInput.properties.shouldShowIfEmpty
        )
        .map(stepInput => {
          if (stepInput.properties.subtype === STEP_INPUTS_TYPE.ATTACHMENT) {
            // const AttachmentComponent = isContactForm ? InputFileList : StepInputSingleAttachment; //TODO @Wojtek uncomment after applying new inputs and delete line below
            const AttachmentComponent = isContactForm ? StepInputAttachmentOld : StepInputSingleAttachmentOld;
            return (
              <AttachmentComponent
                id={stepInput.stepInputModuleId}
                key={stepInput.stepInputModuleId}
                name={stepInput.stepInputModuleId}
                iconLeft={<AttachmentIconSVG />}
                onChangeValue={files => {
                  handleFormValueChange({ name: stepInput.stepInputModuleId, value: files });
                  handleFormBlur({ target: { name: stepInput.stepInputModuleId, value: files } });
                }}
                fileList={formValues[stepInput.stepInputModuleId]}
                message={formStatus[stepInput.stepInputModuleId]?.message}
                status={stepInput.properties.isRequired ? formStatus[stepInput.stepInputModuleId]?.type : undefined}
                placeholder={getPlaceholder({
                  stepInputSubtype: stepInput.properties.subtype,
                  stepInputPlaceholder: stepInput.content.placeholder,
                  userData,
                  localData,
                  serverCallVariables,
                  isContactForm,
                })}
                label={stepInput.content.label}
                required={stepInput.properties.isRequired}
                disabled={!!isPreview}
                ref={inputRefs[stepInput.stepInputModuleId]}
                data-cy={stepInput.properties.dataCy || 'inputAttachment'}
                totalAttachmentSizePerStep={totalAttachmentSizePerStep}
                stepId={stepId}
                {...(!isContactForm && {
                  onFileUploadSuccess,
                  onFileRemove,
                })}
              />
            );
          }
          return (
            <StepInputOld
              // <StepInput //TODO @Wojtek uncomment after applying new inputs and delete StepInputOld
              key={stepInput.stepInputModuleId}
              id={stepInput.stepInputModuleId}
              value={formValues[stepInput.stepInputModuleId]}
              onChange={handleFormChange}
              onChangeValue={onChangeValue}
              onBlur={onBlur}
              onFocus={handleFormFocus}
              name={stepInput.stepInputModuleId}
              label={getLabel({
                stepInputLabel: stepInput.content.label,
                userData,
                localData,
                serverCallVariables,
              })}
              placeholder={getPlaceholder({
                stepInputSubtype: stepInput.properties.subtype,
                stepInputPlaceholder: stepInput.content.placeholder,
                stepInputIsObligatoryInput: stepInput.properties.isObligatoryInput,
                stepInputContentType: stepInput.properties.contentType,
                userData,
                localData,
                serverCallVariables,
              })}
              statusType={stepInput.properties.isRequired ? formStatus[stepInput.stepInputModuleId]?.type : undefined}
              statusMessage={formStatus[stepInput.stepInputModuleId]?.message}
              type={stepInput.properties.subtype}
              options={stepInput.content.optionList}
              required={stepInput.properties.isRequired}
              inputRef={inputRefs[stepInput.stepInputModuleId]}
              dataCy={stepInput.properties.dataCy}
            />
          );
        })}
    </ColumnFlex>
  );
});

StepInputs.propTypes = {
  stepInputs: PropTypes.array,
  prevData: PropTypes.object,
  isPreview: PropTypes.bool,
  isContactForm: PropTypes.bool,
};

export default StepInputs;
