import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'helpers/i18n';

import { Title, Subtitle, ContactFormBody } from '../ContactForm.styles';

function ThankYou() {
  return (
    <ContactFormBody>
      <Title>{i18n('HelpcenterContact.ThankYou')}</Title>
      <Subtitle>{i18n('HelpcenterContact.ThankYouSubtitle')}</Subtitle>
    </ContactFormBody>
  );
}

ThankYou.propTypes = {
  setStep: PropTypes.func,
  setSearchQuery: PropTypes.func,
};

export default ThankYou;
