import { useMemo } from 'react';
import { getContentWithLinkAttributes } from '@playerCommon/ComplexElements/ExplanationPlayer/Steps/Steps.helpers';
import { replaceVarNamesWithValues } from '@stonlyCommons/helpers/guideVariableHelpers';
import { useSentData } from '@playerCommon/Contexts/sentDataContext';
import { useUserData } from '@playerCommon/Contexts/userDataContext';
import { useServerCallData } from '@playerCommon/Contexts/serverCallDataContext';

// Parses provided content and populates variables with values
function useParsedStepContent({ content, isPreview } = {}) {
  const { localData } = useSentData();
  const userData = useUserData();
  const { serverCallVariables } = useServerCallData();

  return useMemo(() => {
    if (!content) {
      return '';
    }

    const parsedContent = getContentWithLinkAttributes(content);

    return replaceVarNamesWithValues({
      content: parsedContent,
      userData,
      localData,
      shouldReplaceLocalVariables: !isPreview,
      serverCallVariables,
      isPreview,
    });
  }, [content, localData, userData, serverCallVariables, isPreview]);
}

export default useParsedStepContent;
