// eslint-disable-next-line import/prefer-default-export
export const NPS_FACE = {
  happy: 'happy',
  neutral: 'neutral',
  sad: 'sad',
};

export const NPS_COLOR = {
  [NPS_FACE.happy]: '#18C98E',
  [NPS_FACE.neutral]: '#FEBE31',
  [NPS_FACE.sad]: '#E0045F',
};

export const NPS_COLORS_MAP = {
  0: NPS_COLOR.sad,
  1: NPS_COLOR.sad,
  2: NPS_COLOR.sad,
  3: NPS_COLOR.sad,
  4: NPS_COLOR.sad,
  5: NPS_COLOR.sad,
  6: NPS_COLOR.sad,
  7: NPS_COLOR.neutral,
  8: NPS_COLOR.neutral,
  9: NPS_COLOR.happy,
  10: NPS_COLOR.happy,
};

export const NPS_FACE_FOR_SCORE = {
  0: NPS_FACE.sad,
  1: NPS_FACE.sad,
  2: NPS_FACE.sad,
  3: NPS_FACE.sad,
  4: NPS_FACE.sad,
  5: NPS_FACE.sad,
  6: NPS_FACE.sad,
  7: NPS_FACE.neutral,
  8: NPS_FACE.neutral,
  9: NPS_FACE.happy,
  10: NPS_FACE.happy,
};
