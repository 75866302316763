import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Avatar from '@playerCommon/CustomElements/Avatar/Avatar.jsx';

const Canvas = styled.div``;

const Top = styled.div`
  display: flex;
  align-items: center;
  margin: 16px;
`;

const BasicInfo = styled.div`
  margin-left: 16px;
  width: 100%;
  overflow: hidden;
`;

const Username = styled.div`
  font-size: 20px;
  color: ${props => props.theme.darkGrey};
`;

const Email = styled.div`
  font-size: 14px;
  color: #bfc1c5;
  margin-top: 4px;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
`;

const UserInfo = ({ user, className }) => (
  <Canvas className={className}>
    <Top>
      <Avatar
        src={user.picture}
        srcSecureHash={user.pictureSecureHash}
        type="user"
        size={48}
        targetName={`${user.firstName} ${user.lastName}`}
        numberForBackground={user.creationDate}
      />
      <BasicInfo>
        <Username>{`${user.firstName} ${user.lastName}`}</Username>
        <Email>{user.email}</Email>
      </BasicInfo>
    </Top>
  </Canvas>
);

UserInfo.propTypes = {
  user: PropTypes.object,
  className: PropTypes.string,
};

export default UserInfo;
