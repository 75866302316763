import React, { memo } from 'react';
import T from 'prop-types';

import i18n from 'helpers/i18n';
import { buildBackgroundStyleForHighlightColor, buildLeftGapStyleForLevel } from '../../shared/helpers';
import {
  RowStepContainer,
  ContentWrap,
  RowBadge,
  ContentColumn,
  StepIconWrap,
  RowCanvas,
  ContentFooter,
} from '../../shared/components/styles';

/**
 * Standard row step (for Editor, Player, PickerMicroapp).
 */
export const RowPlayerStep = memo(
  React.forwardRef(
    (
      {
        uuid,
        level,
        isActive,
        icon: Icon,
        isClickable,
        linkUrl,
        isNewTabEnabled,
        stepId,
        label,
        onStepClick,
        isReference,
        referenceUuid,
        goToRow,
        isUnlinked,
        highlightColor,
        isHighlighted,
        isSelected,
        badgeLabel,
      },
      forwardedRef
    ) => {
      const shouldShowScrollToReferenceButton = isReference && referenceUuid;

      const handleClick = () => {
        if (isClickable) {
          onStepClick({
            stepId,
            linkUrl,
            isNewTabEnabled,
          });
        }
      };

      const onGoToOriginalStepClick = e => {
        e.stopPropagation();
        if (shouldShowScrollToReferenceButton) {
          goToRow({ uuid: referenceUuid });
        }
      };

      const colorToHighlight = isSelected ? undefined : highlightColor;

      return (
        <RowCanvas isUnlinked={isUnlinked} ref={forwardedRef} isSelected={isSelected}>
          <RowStepContainer
            isActive={isActive && !isHighlighted}
            onClick={handleClick}
            onKeyPress={handleClick}
            tabIndex={0}
            role="link"
            style={{
              ...buildLeftGapStyleForLevel(level),
              ...buildBackgroundStyleForHighlightColor(colorToHighlight, { colorOpacity: 33 }),
            }}
            disabled={!isClickable}
          >
            {Icon && (
              <StepIconWrap isActive={isActive} disabled={!isClickable}>
                <Icon />
              </StepIconWrap>
            )}
            <ContentWrap>
              <ContentColumn data-cy="rowContent">
                <span>{label}</span>
                {shouldShowScrollToReferenceButton && (
                  <ContentFooter onClick={onGoToOriginalStepClick}>
                    {i18n('ExplanationEditNextSteps.ScrollToStep')}
                  </ContentFooter>
                )}
              </ContentColumn>
            </ContentWrap>
            {badgeLabel && <RowBadge color={highlightColor} value={badgeLabel} />}
          </RowStepContainer>
        </RowCanvas>
      );
    }
  )
);

RowPlayerStep.propTypes = {
  level: T.number,
  isActive: T.bool,
  icon: T.func,
  isClickable: T.bool,
  stepId: T.oneOfType([T.number, T.string]),
  label: T.string,
  onStepClick: T.func,
  goToRow: T.func,
  isReference: T.bool,
  isUnlinked: T.bool,
  highlightColor: T.string,
  linkUrl: T.string,
  isNewTabEnabled: T.bool,
  isHighlighted: T.bool,
  isSelected: T.bool,
  badgeLabel: T.oneOfType([T.string, T.number]),
  uuid: T.string,
  referenceUuid: T.string,
};
