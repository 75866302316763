import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import FocusLock from 'react-focus-lock';

const BackgroundLayer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: ${({ theme }) => theme.mainColor};
  opacity: 0.6;
`;

const PopupBody = styled(motion.div)`
  position: relative;
  display: block;
  background-color: ${props => props.theme.white};
  border-radius: 8px;
  box-shadow: 0 1px 4px 0 ${props => props.theme.canvasBox};
  max-width: 640px;
`;

const Canvas = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 110;
`;

const PopupContainer = ({ open, children, onExit, popupBodyStyle, className }) => {
  return (
    <AnimatePresence onExitComplete={onExit}>
      {open && (
        <FocusLock>
          <Canvas
            className={className}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.25 }}
          >
            <BackgroundLayer className="popupBackgroundLayer" />
            <PopupBody
              initial={{ y: -24 }}
              animate={{ y: 0 }}
              exit={{ y: 24 }}
              transition={{ duration: 0.25, ease: 'easeInOut' }}
              style={popupBodyStyle}
              className="popupBody"
              data-cy="popupBody"
            >
              {children}
            </PopupBody>
          </Canvas>
        </FocusLock>
      )}
    </AnimatePresence>
  );
};

PopupContainer.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.node,
  onExit: PropTypes.func,
  popupBodyStyle: PropTypes.object,
  className: PropTypes.string,
};

export default PopupContainer;
