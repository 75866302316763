import styled, { css } from 'styled-components';
import TickSVG from 'icons/tickMedium.svg';
import ChevronRightSVG from 'icons/chevronRight.svg';
import ChevronLeftSVG from 'icons/chevronLeft.svg';

export const focusListItemOutlineStyle = css`
  outline: 2px solid ${({ theme }) => theme.darkBlue};
  outline-offset: 0;
`;

const TextContainer = styled.div`
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
`;

const DescriptionContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const Badge = styled.div`
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  font-weight: 500;
  color: ${props => props.theme.white};
  background-color: ${props => props.theme.lightGrey};
  padding: 0 4px;
  margin-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;

const Title = styled.div`
  color: ${props => props.theme.slateGrey};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
`;

const Description = styled.div`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: ${props => props.theme.slateGrey};
`;

const TickIcon = styled(TickSVG)`
  margin-left: 12px;
  margin-top: 2px;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  path {
    fill: ${props => props.theme.seaweed};
  }
`;

const ChevronRightIcon = styled(ChevronRightSVG)`
  flex-shrink: 0;
  margin: auto 0;
  margin-left: 12px;
`;

const ChevronLeftIcon = styled(ChevronLeftSVG)`
  path {
    color: ${({ theme }) => theme.steel};
  }
`;

const IconContainer = styled.div`
  width: 16px;
  height: 16px;
  margin-top: 2px;
  flex-shrink: 0;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const LeftIconContainer = styled(IconContainer)`
  margin-right: 8px;
`;

const TrailingIconContainer = styled(IconContainer)`
  margin-left: 8px;
`;

const ContentTypeIconContainer = styled(IconContainer)`
  margin-top: 0;
  margin-right: 4px;
  width: 12px;
  height: 20px;
`;

const Container = styled.div`
  display: flex;
  padding: 8px 16px 8px 12px;
  border-radius: 4px;
  transition: background-color 0.2s;
  cursor: pointer;
  outline: none;
  scroll-margin: 50px;
`;

const ContainerSelectable = styled(Container)<{ isDisabled?: boolean; isSelected?: boolean; isHighlighted?: boolean }>`
  ${({ isHighlighted }) => isHighlighted && focusListItemOutlineStyle};

  &:active {
    ${Title} {
      color: ${props => props.theme.seaweed};
    }
  }

  ${({ theme, isSelected }) =>
    isSelected &&
    css`
      ${Title} {
        color: ${theme.darkGrey};
      }
    `}

  ${({ theme, isDisabled }) =>
    !isDisabled &&
    css`
      &:hover {
        background-color: ${theme.paleGrey};

        ${Title} {
          color: ${theme.darkGrey};
        }
      }
    `}

  ${({ theme, isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
      ${Title} {
        color: ${theme.lightGrey};
      }
      ${Badge} {
        background-color: ${theme.silver};
      }

      ${IconContainer} {
        svg path {
          fill: ${theme.silver};
        }
      }
    `}
`;

/** RED style container - for ListItemDelete and maybe more in the future */
const ContainerDelete = styled(Container)<{ isDisabled?: boolean; isSelected?: boolean; isHighlighted?: boolean }>`
  ${Title} {
    color: ${props => props.theme.errorColor};
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }

  ${LeftIconContainer} {
    svg path {
      fill: ${props => props.theme.errorColor};
    }
  }

  ${({ isHighlighted, theme }) =>
    isHighlighted &&
    css`
      background-color: ${theme.errorColor}0D;
      ${focusListItemOutlineStyle};
    `}

  ${({ theme, isDisabled }) =>
    !isDisabled &&
    css`
      &:hover {
        background-color: ${theme.errorColor}0D;
      }
    `}


${({ theme, isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
      background-color: ${theme.paleGrey};

      ${Title} {
        color: ${theme.lightGrey};
      }
      ${LeftIconContainer} {
        svg path {
          fill: ${theme.silver};
        }
      }
    `}
`;

const HeaderTitle = styled.div`
  color: ${({ theme }) => theme.darkGrey};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  font-weight: 400;
  flex-grow: 1;
`;

export const ListItemStyles = {
  Container,
  ContainerSelectable,
  ContainerDelete,
  TextContainer,
  TitleContainer,
  DescriptionContainer,
  Title,
  Description,
  Badge,
  IconContainer,
  TickIcon,
  LeftIconContainer,
  TrailingIconContainer,
  ContentTypeIconContainer,
  ChevronRightIcon,
  HeaderTitle,
  ChevronLeftIcon,
};
