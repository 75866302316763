import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import i18n, { getLanguageShorthand } from 'helpers/i18n';
import callApi from 'helpers/apiHelpers';
import { STEP_NEXT_TYPE, STEP_SEPARATOR, STEP_TYPE, VARIABLE_GUIDE_DATA, guideTypeMap } from 'global';
import { appUrl, appUrlHost, playerHost } from 'global/env';
import { AnimatePresence } from 'framer-motion';
import { track as recorderTrack, extractBaseData } from 'components/Analytics/recorder';
import { Helmet } from 'react-helmet';
import withUser from '@playerCommon/HOC/withUser';
import {
  getCompletionRate,
  findEmbeddedFirstStep,
  getFullScrollableStepsPath,
} from '@stonlyCommons/helpers/guidePathHelpers';
import { integrations, openIntegration, sendSpecialStepAction } from 'helpers/widgetIntegrations';
import panel from '@playerCommon/HOC/Panel/Panel';
import withFullscreen from '@playerCommon/HOC/withFullscreen';
import { getWidgetIframeParams, postMessage } from 'helpers/widgetHelpers';
import { mergeRefs } from 'helpers/arrayManagement';
import { isURLRelative, sanitizeURL } from 'helpers/sanitizeHelpers';
import {
  getGuideDataVariableData,
  getGuideDataVariableList,
  replaceVarNamesWithValues,
} from '@stonlyCommons/helpers/guideVariableHelpers.js';
import { setTempStorage } from 'helpers/storage';
import { getHasIntroduction, getStepInput, getStepInfo, getGuideIdAndStepFromLink } from 'helpers/guidePlayerHelpers';
import usePopup from '@playerCommon/hooks/usePopup';
import usePrevious from '@playerCommon/hooks/usePrevious';
import useGuideData from '@playerCommon/hooks/playerHooks/useGuideData';
import useDidUpdate from '@playerCommon/hooks/useDidUpdate';
import useScrolledSteps from '@playerCommon/hooks/playerHooks/useScrolledSteps';
import { useSentData, useSetInternalData } from '@playerCommon/Contexts/sentDataContext';
import { useUserData } from '@playerCommon/Contexts/userDataContext';
import { useServerCallData } from '@playerCommon/Contexts/serverCallDataContext';
import { removePathToChecklist } from '@playerCommon/ComplexElements/Checklist/Checklist.helpers';
import { isNonStonlyLink } from 'helpers/validationHelpers';
import useMediaQuery from '@playerCommon/hooks/useMediaQuery';
import { getIds } from 'helpers/statIdsManagement';
import { identify, track } from '@playerCommon/helpers/targeting';
import { getCommunicationTargetAndValue } from '@playerCommon/helpers/dataTransmission';
import { removePathToAiAnswer } from '@playerCommon/ComplexElements/AiAnswer/AiAnswer.helpers';
import { useAdditionalLoadedGuidesData } from '@playerCommon/Contexts/additionalGuideLoaderContext';
import { STEP_INPUTS_TYPE } from './StepInputs/StepInputs.consts';
import { useStepInputsState } from './provider/StepInputsProvider';
import StepComments, { COMMENTS_TITLE_ID } from '../components/StepComments/StepComments';
import ExplanationLanguages, { LANGUAGES_TITLE_ID } from '../components/ExplanationLanguages/ExplanationLanguages';
import PreviousSteps, { PREVIOUS_STEPS_TITLE_ID } from '../components/PreviousSteps/PreviousSteps';
import Feedback, { FEEDBACK_TITLE_ID } from './Feedback/Feedback';
import ScamGuidePopup from './ScamGuidePopup/ScamGuidePopup';
import StepContents from './StepContents';
import TableOfContents from './TableOfContents';
import Illustrations from './Illustrations/Illustrations';
import StepsFooter from './StepsFooter';
import useTextToSpeech, { TextToSpeechGlobalHighlightStyle } from './TextToSpeech/useTextToSpeech';

import { Canvas, ContentCanvas, RightCanvas, ScrollIndicator, ScrollIndicatorWrap } from './Steps.styles';
import { getGoToNextStepProps, getNextStepLabel, postStepIdMessage } from './Steps.helpers';

const RightPanelFeedback = panel(Feedback, { ariaLabelledBy: FEEDBACK_TITLE_ID });
const RightPanelPreviousSteps = panel(PreviousSteps, { ariaLabelledBy: PREVIOUS_STEPS_TITLE_ID });
const RightPanelComments = panel(withUser(StepComments), { ariaLabelledBy: COMMENTS_TITLE_ID });
const RightPanelLanguage = panel(ExplanationLanguages, { ariaLabelledBy: LANGUAGES_TITLE_ID });

function Steps({
  stepsPath,
  currentExplanationId,
  borderlessEmbed,
  setLanguage,
  compact,
  mode,
  widgetSizeType,
  // parsed in parent component
  displayStepTitle,
  displayProgressBar,
  displayAds,
  guideOrientation,
  // fullscreen handlers
  parentCanvasRef,
  parentIsFullscreen,
  openParentFullscreen,
  closeParentFullscreen,
}) {
  const { mode: matchMode, view: matchView } = useParams();
  const history = useHistory();
  const [isInlineGuideLoading, setIsInlineGuideLoading] = useState(false);

  const [isTableOfContentsExpanded, setIsTableOfContentsExpanded] = useState(false);

  const {
    guide,
    steps,
    stepConnectionList,
    guideInfo,
    guideOptions = {},
    firstStepId,
    access,
    guideEncrypted,
    guideLinksDisabled,
    ttsEnabled,
    guideTeamKnowledgeBaseId,
    displayBackButton,
    isWidget,
  } = useGuideData();

  const { loadAdditionalGuide, getIsAdditionalGuideLoadedAlready } = useAdditionalLoadedGuidesData();

  const { inputValues } = useStepInputsState();
  const { stepTags = [] } = guideOptions;
  const language = getLanguageShorthand();
  const [selectedComponent, toggleComponent] = useState('');
  const prevStepsPath = usePrevious(stepsPath);
  const currentStepId = stepsPath[stepsPath.length - 1];
  setTempStorage('currentStepId', currentStepId);
  const [adContinueNextStep, setAdContinueNextStep] = useState();
  const [choicesLabelsValues, setChoicesLabelsValues] = useState({});
  const [choicesStaticValues, setChoicesStaticValues] = useState({});
  const [userScrolled, setUserScrolled] = useState(false);
  const [showScrollArrow, setShowScrollArrow] = useState(false);
  const [isContactFromSuccessMessageDisplayed, setIsContactFromSuccessMessageDisplayed] = useState(false);
  const { openPopup, closePopup } = usePopup();
  const displayTableOfContentsTree = !!guideOptions.displayTreeView;
  const displayTableOfContentsFlowchart = !!guideOptions.displayFlowchart;

  const isTableOfContentsEnabled =
    !isWidget && mode !== 'preview' && (displayTableOfContentsTree || displayTableOfContentsFlowchart);

  const matchesMobileMediaQuery = useMediaQuery('(max-width: 899px)');
  const { guideData, localData } = useSentData();
  const setInternalData = useSetInternalData();
  const userData = useUserData();
  const { serverCallVariables, clearServerCallData } = useServerCallData();

  const contentWrapRef = useRef();
  const canvasWrapRef = useRef();
  const scrollArrowTimeout = useRef();

  const hasIntroduction = useMemo(
    () => getHasIntroduction(steps.find(s => s.stepId === firstStepId)),
    [steps, firstStepId]
  );

  const scrollToTop = () => {
    if (contentWrapRef.current) {
      if (mode === 'embed') {
        postMessage({
          type: 'stonlyEmbedScrollToTop',
          guideId: guideInfo.guideId,
        });
      } else {
        contentWrapRef.current.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
    }
  };

  const scrollToBottom = useCallback(({ behavior = 'smooth' } = {}) => {
    if (contentWrapRef.current) {
      contentWrapRef.current.scrollTo({ top: contentWrapRef.current.scrollHeight, behavior });
    }
    document.documentElement.scrollTo({ top: document.documentElement.scrollHeight, behavior });
  }, []);

  const postHeightMessage = () => {
    if (canvasWrapRef && canvasWrapRef.current) {
      const { offsetHeight } = canvasWrapRef.current;
      const { height } = canvasWrapRef.current.getBoundingClientRect();

      if (offsetHeight) {
        postMessage({ type: 'contentHeight', height: Math.ceil(Math.max(height, offsetHeight)) });
      }
    }
  };

  const setDefaultHeight = () => {
    postMessage({ type: 'contentHeight', height: 0 });
  };

  const getScrollableStepsPath = useCallback(
    providedStepsPath => getFullScrollableStepsPath({ stepsPath: providedStepsPath, guide }),
    [guide]
  );

  const checkIfShouldShowScrollDownArrow = useCallback(
    providedScrollableSteps => {
      if (userScrolled) return;
      // to save computations if not needed we reuse already computed path
      const scrollableSteps = providedScrollableSteps || getScrollableStepsPath(stepsPath);
      // if scrollable step with more than one step & is of those scrollable steps
      if (scrollableSteps.length > 1 && scrollableSteps[0] === +stepsPath[stepsPath.length - 1]) {
        setUserScrolled(false);
        setShowScrollArrow(false);
        scrollArrowTimeout.current = setTimeout(() => setShowScrollArrow(true), 2000);
      }
    },
    [userScrolled, stepsPath, getScrollableStepsPath]
  );

  const stepInfo = useMemo(
    () => getStepInfo({ guide, currentStepId, language, stepsPath, adContinueNextStep }),
    [currentStepId, guide, language, stepsPath, adContinueNextStep]
  );

  const {
    ttsGlobalHighlightStyleProps,
    ttsPlayer,
    isTTSRunning,
    isTTSAvailableOnStep,
    isLoadingTTSData,
    startTTS,
    stopTTS,
  } = useTextToSpeech({
    enabled: ttsEnabled,
    stepId: currentStepId,
    teamKnowledgeBaseId: guideTeamKnowledgeBaseId,
    guideId: currentExplanationId,
    language,
    stepInfo,
    guideInfo: { firstStepId },
    guideOptions: { displayBackButton, displayStepTitle },
  });

  const triggerDataTransmissionCommunication = useCallback(
    async comm => {
      const { target, value } = await getCommunicationTargetAndValue(comm);

      if (!target) {
        return;
      }

      const baseData = await extractBaseData();
      await callApi('v1/guide/communication', 'POST', {
        value,
        target,
        type: comm.type,
        stepId: currentStepId,
        teamId: guideInfo.teamId,
        guideId: guideInfo.guideId,
        guideData,
        stepCommunicationPublicId: comm.stepCommunicationPublicId,
        ...baseData,
      });
    },
    [guideInfo, guideData, currentStepId]
  );

  const sendDataTransmission = useCallback(
    comm => {
      const { type, value, target } = comm;
      const isSetLocalData = target.startsWith('St_localData');
      const isSetTargetingProperty = target.startsWith('St_property');
      const isTrackTargetingEvent = target.startsWith('St_event');
      const isServerDataEvent = target.startsWith('St_serverData');

      if (isServerDataEvent && value?.trim().toLowerCase() === 'clear') {
        clearServerCallData();
        return;
      }

      if (isSetLocalData && !isWidget) {
        // pass local data for standalone guide;
        if (type === 'allGuideData') {
          return;
        }

        if (type === 'allInputsValues') {
          setInternalData(value);
        } else {
          const propertyName = target.replace('St_localData - ', '').trim();
          if (propertyName) {
            setInternalData({
              [propertyName]: value,
            });
          }
        }
      } else if (isSetTargetingProperty && !isWidget) {
        // identify user in the standalone guide;
        if (type === 'allGuideData') {
          return;
        }

        let properties = {};
        if (type === 'allInputsValues') {
          properties = value;
        } else {
          const propertyName = target.replace('St_property - ', '').trim();
          if (propertyName) {
            properties = {
              [propertyName]: value,
            };
          }
        }
        identify(properties).then(() => {
          postMessage({
            type: 'targetingChanged',
            properties,
          });
        });
      } else if (isTrackTargetingEvent && !isWidget) {
        // track event in the standalone guide;
        const event = target.replace('St_event - ', '').trim();
        if (event) {
          track(event);
        }
      } else {
        triggerDataTransmissionCommunication(comm);
        postMessage({
          source: 'stonlyDataTransmission',
          type,
          value,
          target,
        });
      }
    },
    [triggerDataTransmissionCommunication, isWidget, setInternalData, clearServerCallData]
  );

  const triggerStepOpenCommunication = useCallback(() => {
    if (mode === 'preview') return;

    stepInfo.stepCommunications
      .filter(comm => comm.type === 'customTextStart')
      .forEach(async comm => {
        const guideDataVariableList = getGuideDataVariableList(comm.value);
        let guideDataVariables;
        if (guideDataVariableList.length) {
          const { sessionId } = await getIds();
          guideDataVariables = await getGuideDataVariableData({
            variablesList: guideDataVariableList,
            guideTeamKnowledgeBaseId,
            guideId: guideInfo.guideId,
            sessionId,
          });
        }
        sendDataTransmission({
          ...comm,
          value: replaceVarNamesWithValues({
            content: comm.value,
            userData,
            localData,
            serverCallVariables,
            guideDataVariables,
          }),
        });
      });
  }, [stepInfo, mode, sendDataTransmission, userData, localData, serverCallVariables]);

  const getInputsWithValuesForStep = useCallback(() => {
    const { defaultLanguage } = guideInfo;
    const step = steps.find(s => s.stepId === currentStepId);
    if (!step) return {};

    const stepInputForThisStep = getStepInput(step, language, defaultLanguage);
    return stepInputForThisStep
      .filter(input => input.properties.subtype !== STEP_INPUTS_TYPE.ATTACHMENT) // for now attachments are only used for server call
      .map(input => {
        const {
          properties: { key, isRecordingUserInput, subtype },
          stepInputModuleId,
        } = input;
        let value = inputValues[stepInputModuleId] || '';
        if (subtype === STEP_INPUTS_TYPE.CHECKBOX) {
          value = inputValues[stepInputModuleId].toString();
        }
        return { stepInputModuleId, value, name: key, isRecordingUserInput };
      });
  }, [currentStepId, guideInfo, steps, inputValues, language]);

  const getVisitedStepsData = useCallback(
    ({ guideDataVariables, currentStepData } = {}) => {
      const visitedStepsData = [];

      const choiceLabelValueByStepId = { ...choicesLabelsValues };
      const choiceStaticValueByStepId = { ...choicesStaticValues };

      if (currentStepData) {
        choiceLabelValueByStepId[currentStepData.stepIdToLog] = currentStepData.nextStepLabelToLog || 'Next';
        choiceStaticValueByStepId[currentStepData.stepIdToLog] = currentStepData.nextStepValueToLog;
      }

      const {
        guideOptions: { defaultLanguage },
      } = guide;
      const stepInputList = steps
        .map(step => getStepInput(step, language, defaultLanguage))
        .filter(si => si.length)
        .flat();
      const inputsStepIds = Object.keys(inputValues)
        .map(inputId => stepInputList.find(si => si.stepInputModuleId === inputId))
        .map(si => (si ? si.stepId : null))
        .filter(Boolean)
        .map(String);
      const stepIds = new Set([
        ...Object.keys(choiceLabelValueByStepId),
        ...Object.keys(choiceStaticValueByStepId),
        ...inputsStepIds,
      ]);

      stepIds.forEach(stepId => {
        const { title: stepTitle, stepInputs } = getStepInfo({
          guide,
          currentStepId: +stepId,
          language,
          stepsPath,
          adContinueNextStep,
        });
        const stepInputsValues = stepInputs
          .filter(si => si.properties.subtype !== STEP_INPUTS_TYPE.ATTACHMENT)
          .map(si => ({
            name: si.properties.key,
            value: inputValues[si.stepInputModuleId],
          }));

        const stepData = {
          stepId,
          stepTitle,
          stepInputsValues,
          choiceLabel: choiceLabelValueByStepId[stepId],
          choiceValues: choiceStaticValueByStepId[stepId],
        };

        if (guideDataVariables?.[VARIABLE_GUIDE_DATA.sessionAiConversations]) {
          const stepAiConversations = guideDataVariables[VARIABLE_GUIDE_DATA.sessionAiConversations]
            .filter(aiConversationEntry => +aiConversationEntry.stepId === +stepId)
            .map(entry => ({
              time: entry.creationDate,
              guideId: entry.guideId,
              originalGuideId: entry.originalGuideId,
              details: entry.actionDetail,
            }));

          if (stepAiConversations.length) {
            stepData.aiConversation = stepAiConversations;
          }
        }
        visitedStepsData.push(stepData);
      });

      return visitedStepsData;
    },
    [inputValues, guide, steps, language, choicesLabelsValues, stepsPath, adContinueNextStep, choicesStaticValues]
  );

  const triggerCommunication = useCallback(
    async ({ nextStepLabelToLog, nextStepValueToLog, stepIdToLog }) => {
      const { title, stepCommunications } = stepInfo;

      if (mode === 'preview' || !stepCommunications.length) return;

      const inputsWithValues = getInputsWithValuesForStep();
      stepCommunications
        .filter(comm => comm.type !== 'customTextStart')
        .forEach(async comm => {
          let { value } = comm;

          if (comm.type === 'choiceLabel') {
            value = nextStepLabelToLog || 'Next';
          }

          if (comm.type === 'choiceStaticValue') {
            value = nextStepValueToLog;
          }

          if (comm.type === 'stepTitle') {
            value = title;
          }

          if (comm.type === 'allGuideData') {
            const { sessionId } = await getIds();
            const guideDataVariables = await getGuideDataVariableData({
              variablesList: [VARIABLE_GUIDE_DATA.sessionAiConversations, VARIABLE_GUIDE_DATA.sessionReportLink],
              sessionId,
              guideId: guideInfo.guideId,
              guideTeamKnowledgeBaseId,
            });
            /*
              Need to pass stepIdToLog, nextStepLabelToLog and nextStepValueToLog as an argument because
              at the time triggerCommunication function is called, these values are not populated to choicesLabelsValues and choicesStaticValues
            */
            value = getVisitedStepsData({
              guideDataVariables,
              currentStepData: {
                stepIdToLog,
                nextStepLabelToLog,
                nextStepValueToLog,
              },
            });
          }

          if (comm.type === 'inputValue') {
            value = inputsWithValues[0]?.value;
          }

          if (comm.type === 'allInputsValues') {
            value = Object.fromEntries(inputsWithValues.map(i => [i.name, i.value]));
          }

          if (comm.type === 'customText') {
            const guideDataVariableList = getGuideDataVariableList(value);
            let guideDataVariables;
            if (guideDataVariableList.length) {
              const { sessionId } = await getIds();
              guideDataVariables = await getGuideDataVariableData({
                variablesList: guideDataVariableList,
                guideTeamKnowledgeBaseId,
                guideId: guideInfo.guideId,
                sessionId,
              });
            }
            value = replaceVarNamesWithValues({
              content: value,
              userData,
              localData,
              serverCallVariables,
              guideDataVariables,
            });
          }

          sendDataTransmission({ ...comm, value });
        });
    },
    [
      stepInfo,
      guideInfo,
      getInputsWithValuesForStep,
      mode,
      getVisitedStepsData,
      sendDataTransmission,
      userData,
      localData,
      serverCallVariables,
    ]
  );

  const logGoToNextStep = useCallback(
    (stepIdToLog, nextStepId, stepNextId, nextStepLabel, nextStepStaticValue) => {
      const { title } = stepInfo;
      const nextStepIdToLog = nextStepId;
      const nextStepLabelToLog = nextStepLabel;
      const nextStepValueToLog = nextStepStaticValue;
      const stepNextIdToLog = stepNextId;
      const shouldStore = !!nextStepId;

      postMessage({
        type: 'nextStepClicked',
        currentStepId: stepIdToLog,
        currentStepTitle: title,
        nextStepId: nextStepIdToLog,
        nextStepLabel: nextStepLabelToLog,
        nextStepValue: nextStepValueToLog,
        stepNextId: stepNextIdToLog,
      });

      triggerCommunication({ nextStepLabelToLog, nextStepValueToLog, stepIdToLog });

      if (shouldStore) {
        const stepNextToLog =
          stepConnectionList.find(sc => sc.id === stepNextIdToLog) ||
          stepConnectionList.find(n => n.toStepId === nextStepIdToLog && n.fromStepId === stepIdToLog);

        if (stepNextToLog) {
          setChoicesStaticValues(currentChoicesStaticValues => ({
            ...currentChoicesStaticValues,
            [stepIdToLog]: nextStepValueToLog,
          }));
          setChoicesLabelsValues(currentChoicesValues => ({
            ...currentChoicesValues,
            [stepIdToLog]: nextStepLabelToLog,
          }));
          recorderTrack({
            actionType: 'choice',
            actionDetail: {
              stepNextId: stepNextToLog.id,
              nextStepId: stepNextToLog.toStepId,
            },
            stepId: stepNextToLog.fromStepId,
          });
        }
      }
    },
    [stepConnectionList, stepInfo, triggerCommunication]
  );

  const createPreviousLink = useCallback(() => {
    const scrollableSteps = getScrollableStepsPath(stepsPath);

    let previousUrlToLoad;
    if (stepsPath.length === 1 && stepsPath[0] === firstStepId) {
      previousUrlToLoad = hasIntroduction ? 'introduction' : '';
    } else if (stepsPath.length === 1 && stepsPath[0] !== firstStepId) {
      previousUrlToLoad = hasIntroduction ? 'introduction' : String(firstStepId);
    } else {
      let stepsPathToUse = [...stepsPath];
      const stepsPathIndexOfFirstStep = stepsPathToUse.indexOf(scrollableSteps[0]);
      if (stepsPathIndexOfFirstStep > -1) {
        stepsPathToUse = stepsPathToUse.slice(0, stepsPathIndexOfFirstStep + 1);
      }
      const previousStepIdToLoad = stepsPathToUse[stepsPathToUse.length - 2];
      const isEmbeddingStep = steps.find(s => s.stepId === previousStepIdToLoad && s.stepType === STEP_TYPE.guide);
      stepsPathToUse = isEmbeddingStep ? stepsPathToUse.slice(0, -2) : stepsPathToUse.slice(0, -1);
      // starting from the end of the path, find id of the step that is not "automation" or "embedding"
      const previousStepId = stepsPathToUse
        .slice()
        .reverse()
        .find(stepId =>
          steps.find(s => s.stepId === stepId && s.stepType !== STEP_TYPE.guide && s.stepType !== STEP_TYPE.automation)
        );
      if (previousStepId) {
        const indexOfPreviousStep = stepsPathToUse.indexOf(previousStepId);
        stepsPathToUse = stepsPathToUse.slice(0, indexOfPreviousStep + 1);
      } else {
        stepsPathToUse = [];
      }
      previousUrlToLoad = stepsPathToUse.join(STEP_SEPARATOR);
    }
    return previousUrlToLoad;
  }, [firstStepId, getScrollableStepsPath, hasIntroduction, steps, stepsPath]);

  // TODO:// this function could be possibly removed or much simplified with a proper use of getGoToNextStepProps, but it's too late for me to think about it
  const createNextLink = useCallback(
    nextStepToLoad => {
      const pathForURL = stepsPath.join(STEP_SEPARATOR);

      let nextUrlToLoad = '';
      let typeOfNextStep = 'internal';
      let openInNewTab = true;
      let action = null;
      if (!nextStepToLoad.toStepId) {
        nextUrlToLoad = '';
      } else if (nextStepToLoad.toStepId === STEP_NEXT_TYPE.EXTERNAL_LINK) {
        typeOfNextStep = 'external';
        nextUrlToLoad = nextStepToLoad.linkUrl;
        openInNewTab = nextStepToLoad.newTabEnabled;
      } else if (nextStepToLoad.toStepId === STEP_NEXT_TYPE.SPECIAL) {
        typeOfNextStep = 'special';
        nextUrlToLoad = '';
        openInNewTab = false;
        action = nextStepToLoad.action;
      } else {
        const computedNextStep = findEmbeddedFirstStep(nextStepToLoad.toStepId, guide);
        nextUrlToLoad = `${pathForURL}${STEP_SEPARATOR}${computedNextStep}`;
      }

      const res = { typeOfNextStep, nextUrlToLoad, action, openInNewTab };
      if (!nextStepToLoad.linkURL) res.toStepId = nextStepToLoad.toStepId;

      return res;
    },
    [stepsPath, guide]
  );

  const saveInputValue = useCallback(() => {
    const stepInputValues = getInputsWithValuesForStep();
    if (!stepInputValues.length) return;
    const isPreview = matchView === 'PreviewSteps';

    const stepId = stepsPath[stepsPath.length - 1];

    stepInputValues.forEach(input => {
      const stepObject = steps.find(el => el.stepId === stepId);
      const stepGuideId = stepObject && stepObject.guideId;
      if (input.value && input.isRecordingUserInput && !isPreview) {
        getIds().then(({ sessionId }) => {
          callApi('v1/step/input', 'put', {
            guideId: stepGuideId || guideInfo.guideId,
            sessionId,
            language,
            value: input.value,
            stepModuleId: input.stepInputModuleId,
            stepId,
          });
        });
        recorderTrack({
          actionType: 'inputValueSubmitted',
          actionDetail: {
            value: input.value,
            stepModuleId: input.stepInputModuleId,
          },
        });
      }
    });
  }, [getInputsWithValuesForStep, guideInfo, language, matchView, steps, stepsPath]);

  const openGuideLinksDisabledPopup = useCallback(() => {
    openPopup({
      text: i18n('ExplanationPlayer.FreeTierLinksPopupText'),
      title: i18n('ExplanationPlayer.FreeTierLinksPopupTitle'),
      confirmButtonText: i18n('Global.Ok'),
      hideClose: true,
    });
  }, [openPopup]);

  const onContentClick = useCallback(
    e => {
      if (e.target.tagName === 'A') {
        const url = e.target.getAttribute('href'); // Tiles do not have href attributes, but they are <a> elements
        if (url) {
          if (guideLinksDisabled) {
            try {
              const parsedURL = new URL(e.target.href);
              if (![playerHost, appUrlHost].includes(parsedURL.host)) {
                e.preventDefault();
                openGuideLinksDisabledPopup();
              }
            } catch {
              e.preventDefault();
              openGuideLinksDisabledPopup();
            }
          } else if (isURLRelative(url)) {
            e.preventDefault();

            const { hostOrigin } = getWidgetIframeParams();
            const linkTarget = e.target.getAttribute('target');
            const urlToOpen = sanitizeURL(hostOrigin ? `${hostOrigin}${url}` : url);

            if (linkTarget === '_blank') {
              window.open(urlToOpen, '_blank');
            } else {
              window.top.location.href = urlToOpen;
            }
          }
        }
      }
    },
    [guideLinksDisabled, openGuideLinksDisabledPopup]
  );

  const goToNextStep = useCallback(
    async ({
      newPath,
      toStepId,
      stepConnectionId,
      nextStepLabel,
      nextStepStaticValue,
      typeOfNextStep,
      openInNewTab = true,
      action,
    }) => {
      if (toStepId === null) return;

      logGoToNextStep(currentStepId, toStepId, stepConnectionId, nextStepLabel, nextStepStaticValue);
      saveInputValue();

      if (typeOfNextStep === 'special') {
        sendSpecialStepAction(action);
        return;
      }

      if (typeOfNextStep === 'external') {
        if (integrations[newPath]) {
          openIntegration(newPath);
          return;
        }
        if (guideLinksDisabled && isNonStonlyLink(newPath)) {
          openGuideLinksDisabledPopup();
          return;
        }

        const url = replaceVarNamesWithValues({
          content: newPath,
          userData,
          localData,
          serverCallVariables,
        });

        if (openInNewTab) {
          window.open(sanitizeURL(url), '_blank', 'noopener');
          return;
        }

        if (!openInNewTab) {
          if (getGuideIdAndStepFromLink(url)) {
            const guideToLoadInfo = getGuideIdAndStepFromLink(url);
            if (!getIsAdditionalGuideLoadedAlready(guideToLoadInfo.guideId)) {
              setIsInlineGuideLoading(true);
            }
            const loadedGuideData = await loadAdditionalGuide(guideToLoadInfo.guideId, language);
            const firstStepOfLoadedGuide = loadedGuideData.guideInfo.firstStepId;

            newPath = stepsPath.includes(firstStepOfLoadedGuide)
              ? stepsPath
              : `${stepsPath},${guideToLoadInfo.stepId || firstStepOfLoadedGuide}`;
          } else {
            const widgetIFrameParams = getWidgetIframeParams();
            window.top.location.href =
              widgetIFrameParams.hostOrigin && url.indexOf('/') === 0
                ? sanitizeURL(`${widgetIFrameParams.hostOrigin}${url}`)
                : sanitizeURL(url);
            return;
          }
        }
      }

      if (stepsPath.length > 0 && stepsPath.length % 4 === 0 && displayAds) {
        const lastStepId = newPath.split(STEP_SEPARATOR).pop();
        const modifiedNewPath = [...newPath.split(STEP_SEPARATOR).reverse().slice(1).reverse(), STEP_NEXT_TYPE.AD].join(
          STEP_SEPARATOR
        );
        setAdContinueNextStep(lastStepId);
        history.push(modifiedNewPath);
        return;
      }

      if (newPath && JSON.stringify(newPath) !== JSON.stringify(stepsPath)) {
        postStepIdMessage(newPath);
      }
      setTimeout(() => {
        history.push(newPath);
        setIsInlineGuideLoading(false);
      }, 50);
    },
    [
      currentStepId,
      displayAds,
      history,
      logGoToNextStep,
      saveInputValue,
      stepsPath,
      triggerStepOpenCommunication,
      userData,
      localData,
      serverCallVariables,
    ]
  );

  /* Goes to any step by clicking on the flowchart/steps tree */
  const jumpToStep = useCallback(
    ({ stepId, linkUrl, isNewTabEnabled }) => {
      if (!stepId) {
        return;
      }
      if (matchesMobileMediaQuery) {
        // close TableOfContent panel if on mobile view
        setIsTableOfContentsExpanded(false);
      }
      if (Number(stepId) === STEP_NEXT_TYPE.EXTERNAL_LINK) {
        // why casting to number? Bc it can be a string if used in bridge (used as a return from embedded guide)
        // we don't allow for jumping into external chats, so here won't have such "links"
        const target = isNewTabEnabled ? '_blank' : '_self';
        window.open(sanitizeURL(linkUrl), target, 'noopener');
      } else {
        const firstStepIdPath = String(firstStepId);
        const currentUrlStepIdPath = stepsPath.length === 0 ? firstStepIdPath : stepsPath.join(STEP_SEPARATOR);

        // Can't put all the embedded steps in stepIdChain until we started supporting embedding on embedded step
        // So for now it can have max 2 stepIds [embeddingStepId?, stepId]
        const targetStepIdList = String(stepId).split(STEP_SEPARATOR).slice(-2).filter(Boolean);
        const targetStepIdChain = targetStepIdList.join(STEP_SEPARATOR);

        if (!currentUrlStepIdPath.endsWith(targetStepIdChain)) {
          const isJumpingToFirstStep = targetStepIdChain === firstStepIdPath;
          const newUrlStepIdPath = isJumpingToFirstStep
            ? firstStepIdPath
            : [currentUrlStepIdPath, targetStepIdChain].join(STEP_SEPARATOR);

          const stepIdToTrack = isJumpingToFirstStep ? firstStepId : targetStepIdList.pop();

          postStepIdMessage(newUrlStepIdPath);
          recorderTrack({
            actionType: 'tableOfContentsChoice',
            actionDetail: {
              nextStepId: Number(stepIdToTrack),
            },
            stepId: currentStepId,
          });
          setTimeout(() => {
            history.push(newUrlStepIdPath);
            scrollToStep(stepIdToTrack);
          }, 50);
        }
      }
    },
    [stepsPath, firstStepId, history, matchesMobileMediaQuery]
  );

  const goToNextStepByConnectionId = useCallback(
    ({ stepConnectionId }) => {
      const pathFromUrl = stepsPath.length === 0 ? String(firstStepId) : stepsPath.join(STEP_SEPARATOR);
      const stepConnection = stepConnectionList.find(sc => sc.id === stepConnectionId);
      goToNextStep(getGoToNextStepProps({ option: stepConnection, guide, path: pathFromUrl }));
    },
    [firstStepId, goToNextStep, guide, stepConnectionList, stepsPath]
  );

  const onSingleGoToNextStepClick = useCallback(
    stepNextObj => {
      const navigationButtons = createNextLink(stepNextObj);

      if (!navigationButtons.nextUrlToLoad && navigationButtons.typeOfNextStep !== 'special') return;

      goToNextStep({
        newPath: navigationButtons.nextUrlToLoad,
        toStepId: navigationButtons.toStepId || '',
        stepConnectionId: stepNextObj.id, // TODO:// @Andrii Alieshchev was there a reason it was `null`?
        nextStepLabel: getNextStepLabel(stepNextObj),
        nextStepStaticValue: stepNextObj.staticValue,
        typeOfNextStep: navigationButtons.typeOfNextStep,
        openInNewTab: navigationButtons.openInNewTab,
        action: navigationButtons.action,
      });
    },
    [createNextLink, goToNextStep]
  );

  const onMessage = useCallback(
    message => {
      if (message.data.type === 'goToNextStep') {
        goToNextStepByConnectionId({ stepConnectionId: message.data.stepNextId });
      }
      if (message.data.type === 'setLanguage') {
        setLanguage(message.data.language);
      }
    },
    [goToNextStepByConnectionId, setLanguage]
  );

  const setCurrentStep = useCallback(
    stepId => {
      const scrollableStepsPath = getScrollableStepsPath(stepsPath);

      if (scrollableStepsPath.includes(stepId)) {
        const indexOfScrollableCurrentStepId = scrollableStepsPath.indexOf(stepId);
        const indexOfFirstScrollableStep = stepsPath.indexOf(scrollableStepsPath[0]);
        const stepsToPush = scrollableStepsPath.slice(0, indexOfScrollableCurrentStepId + 1);

        let newPath = [...stepsPath];
        if (indexOfFirstScrollableStep > -1) {
          newPath = newPath.slice(0, indexOfFirstScrollableStep);
        }
        newPath.push(...stepsToPush);

        if (newPath.length > stepsPath.length) {
          const stepNextToLog = stepConnectionList.find(
            n => n.toStepId === stepId && n.fromStepId === stepsToPush[stepsToPush.length - 2]
          );
          if (!userScrolled) {
            clearTimeout(scrollArrowTimeout.current);
            setUserScrolled(true);
          }
          if (stepNextToLog) {
            recorderTrack({
              actionType: 'choice',
              actionDetail: {
                stepNextId: stepNextToLog.id,
                nextStepId: stepNextToLog.toStepId,
              },
              stepId: stepNextToLog.fromStepId,
            });
          }
        }

        history.replace(newPath.join(STEP_SEPARATOR));
      }
    },
    [getScrollableStepsPath, history, stepConnectionList, stepsPath, userScrolled]
  );

  const onBackLinkClick = useCallback(
    ev => {
      if (ev && ev.preventDefault) ev.preventDefault();

      const previousUrlToLoad = createPreviousLink();
      postStepIdMessage(previousUrlToLoad);
      setTimeout(() => {
        history.push(previousUrlToLoad);
        // only if we go back to the beginning of the guide from the first scrollable path
        if (previousUrlToLoad === String(firstStepId)) scrollToTop();
      }, 50);

      try {
        const previousStepId = stepsPath[stepsPath.length - 2];
        postMessage({ type: 'goBackClicked', currentStepId, previousStepId });
      } catch (e) {
        if (Sentry) Sentry.captureException(e);
      }
    },
    [createPreviousLink, currentStepId, stepsPath, firstStepId]
  );

  const goBackToChecklist = useCallback(
    pathToChecklist => {
      if (pathToChecklist) {
        removePathToChecklist();
        setTimeout(() => history.push(pathToChecklist), 50);
      }
    },
    [history]
  );

  const goBackToAiAnswer = useCallback(
    pathToAiAnswer => {
      if (pathToAiAnswer) {
        removePathToAiAnswer();
        setTimeout(() => history.push(pathToAiAnswer), 50);
      }
    },
    [history]
  );

  const toggleFullscreen = useCallback(() => {
    if (matchMode === 'agentAppZendeskPanel') {
      postMessage({ type: 'stepButton', action: 'openModal' });
      return;
    }
    if (parentIsFullscreen) {
      closeParentFullscreen();
    } else {
      openParentFullscreen();
    }
  }, [matchMode, parentIsFullscreen, closeParentFullscreen, openParentFullscreen]);

  const editExplanation = useCallback(() => {
    const url = `${appUrl}/app/${guideTypeMap[guideInfo.type]}/${currentExplanationId}/editor/${currentStepId}`;
    window.open(url, '_blank');
  }, [currentExplanationId, currentStepId, guideInfo.type]);

  useEffect(() => {
    const isScamGuide = guideInfo.suspicious;
    if (isScamGuide && !(mode === 'preview' || isWidget)) {
      openPopup({
        customContent: <ScamGuidePopup onConfirm={closePopup} />,
        hideClose: true,
        confirmButtonText: null,
        dismissButtonText: null,
      });
    }
  }, [guideInfo, mode, isWidget]);

  useEffect(() => {
    if (!compact) {
      return;
    }
    let wrapResizeObserver;
    if (window.ResizeObserver) {
      wrapResizeObserver = new ResizeObserver(entries => entries.forEach(postHeightMessage));
      if (canvasWrapRef && canvasWrapRef.current) {
        wrapResizeObserver.observe(canvasWrapRef.current);
      }
    } else {
      postHeightMessage();
    }

    window.addEventListener('unload', setDefaultHeight);

    return () => {
      if (wrapResizeObserver) {
        wrapResizeObserver.disconnect();
      }
      window.removeEventListener('unload', setDefaultHeight);
    };
  }, [compact]);

  useEffect(() => {
    postMessage({
      type: 'stepIllustrationType',
      stepId: currentStepId,
      illustrationType: stepInfo.illustrationType,
    });

    postMessage({
      type: 'stepType',
      stepId: currentStepId,
      stepType: stepInfo.stepType,
    });
  }, [stepInfo]);

  useEffect(() => {
    triggerStepOpenCommunication();
    checkIfShouldShowScrollDownArrow();

    return () => clearTimeout(scrollArrowTimeout.current);
  }, []);

  useEffect(() => {
    if (isWidget) {
      window.addEventListener('message', onMessage);
    }
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [isWidget, onMessage]);

  const { stepId, stepType, illustrationType } = stepInfo;

  const scrollableSteps = useMemo(() => getScrollableStepsPath(stepsPath), [stepsPath, getScrollableStepsPath]);
  const stepsToShowInfo = useMemo(
    () =>
      scrollableSteps.map(step =>
        getStepInfo({
          guide,
          currentStepId: step,
          language,
          stepsPath,
          adContinueNextStep,
        })
      ),
    [scrollableSteps]
  );

  let currentStepsHaveIllustration = false;
  for (const element of stepsToShowInfo) {
    if (element.illustrationType !== 'none' && element.stepType !== STEP_TYPE.automation) {
      currentStepsHaveIllustration = true;
      break;
    }
  }
  const isScrollable = stepsToShowInfo.length > 1;

  const { scrollToStep } = useScrolledSteps({
    isScrollable: mode === 'preview' ? false : isScrollable,
    containerRef: contentWrapRef,
    setCurrentStep,
    currentStepId,
    currentFirstScrollableStepId: isScrollable ? stepsToShowInfo[0].stepId : undefined,
  });

  const stepsToShow = useMemo(() => {
    return stepsToShowInfo.map((info, index) => (
      <StepContents
        key={info.stepId}
        firstStep={firstStepId}
        hasIntroduction={hasIntroduction}
        stepsPath={stepsPath}
        toggleComponent={toggleComponent}
        borderlessEmbed={borderlessEmbed}
        goToNextStep={goToNextStep}
        onBackLinkClick={onBackLinkClick}
        goBackToChecklist={goBackToChecklist}
        goBackToAiAnswer={goBackToAiAnswer}
        explanationId={guideInfo.guideId}
        currentExplanationId={currentExplanationId}
        onSingleNextStepClick={onSingleGoToNextStepClick}
        displayStepTitle={displayStepTitle}
        stepInfo={info}
        createPreviousLink={createPreviousLink}
        currentStepsHaveIllustration={currentStepsHaveIllustration && !isContactFromSuccessMessageDisplayed}
        encrypted={!!guideEncrypted}
        guideOrientation={guideOrientation}
        scrollable={isScrollable}
        isFirst={isScrollable && index === 0}
        isLast={isScrollable && stepsToShowInfo.length - 1 === index}
        getVisitedStepsData={getVisitedStepsData}
        currentStepId={currentStepId}
        compact={compact}
        scrollToTop={scrollToTop}
        scrollStepToBottom={scrollToBottom}
        teamId={guideInfo.teamId}
        setIsContactFromSuccessMessageDisplayed={setIsContactFromSuccessMessageDisplayed}
        isInlineGuideLoading={isInlineGuideLoading}
      />
    ));
  }, [currentStepId, guide, goToNextStep, isContactFromSuccessMessageDisplayed]);

  const isFirstStep = stepsPath.length === 1;

  const currentStepTags = stepTags
    .filter(st => (isFirstStep ? true : st.stepTags.includes(currentStepId)))
    .filter(st => st.isSearchable)
    .map(st => st.caption[getLanguageShorthand()] || st.name)
    .join(', ');

  const completionRate = useMemo(() => {
    if (displayProgressBar && currentStepId !== STEP_NEXT_TYPE.INTRODUCTION) {
      return getCompletionRate(stepsPath, guide);
    }
    return 0;
  }, [displayProgressBar, stepsPath, guide, currentStepId]);

  useEffect(() => {
    const { guideId } = guideInfo;
    postMessage({ type: 'currentGuide', guideId });
  }, []);

  useDidUpdate(() => {
    const { guideId } = guideInfo;
    if (stepsPath) {
      const currentStepsDataToPost = {
        type: 'currentSteps',
        path: stepsPath.join(STEP_SEPARATOR),
        explanationId: guideId,
      };
      if (isWidget) {
        currentStepsDataToPost.fromWidget = true;
        postMessage({
          type: 'currentPath',
          origin: document.location.origin,
          path: window.location.pathname,
          fromWidget: true,
        });
      }
      postMessage(currentStepsDataToPost);
    }

    if (stepsPath) {
      triggerStepOpenCommunication();
    }

    const scrollableSteps = getScrollableStepsPath(stepsPath);
    const prevScrollableSteps = getScrollableStepsPath(prevStepsPath);
    const scrollableStepsChanged = JSON.stringify(scrollableSteps) !== JSON.stringify(prevScrollableSteps);

    if (scrollableSteps.length !== 1 && scrollableStepsChanged) {
      scrollToStep(currentStepId);
    } else if (scrollableSteps.length === 1 || scrollableStepsChanged) {
      scrollToTop();
    }

    checkIfShouldShowScrollDownArrow(scrollableSteps);
  }, [stepsPath]);

  const footerProps = {
    compact,
    displayProgressBar,
    mode,
    matchMode,
    completion: completionRate,
    parentIsFullscreen,
    borderlessEmbed,
    stepType,
    isFirstStep,
    toggleComponent,
    toggleFullscreen,
    editExplanation,
    language,
    widgetSizeType,
    displayTableOfContentsTree,
    displayTableOfContentsFlowchart,
    isMobile: matchesMobileMediaQuery,
    isTableOfContentsEnabled,
    isTableOfContentsExpanded,
    setIsTableOfContentsExpanded,
    isTTSRunning,
    isTTSAvailableOnStep,
    isLoadingTTSData,
    startTTS,
    stopTTS,
  };

  return (
    <Canvas
      id="main"
      mode={mode}
      matchMode={matchMode}
      ref={mergeRefs(parentCanvasRef, canvasWrapRef)}
      isFullscreen={parentIsFullscreen}
      className={`guide-${guideInfo.guideId} guide-canvas`}
    >
      {currentStepTags && (
        <Helmet>
          <meta name="keywords" content={currentStepTags} />
        </Helmet>
      )}
      <TextToSpeechGlobalHighlightStyle {...ttsGlobalHighlightStyleProps} />
      {ttsPlayer}
      {isTableOfContentsEnabled && (
        <TableOfContents
          displayTree={displayTableOfContentsTree}
          displayFlowchart={displayTableOfContentsFlowchart}
          isTableOfContentsExpanded={isTableOfContentsExpanded}
          setIsTableOfContentsExpanded={setIsTableOfContentsExpanded}
          jumpToStep={jumpToStep}
          stepsPathString={stepsPath.join(STEP_SEPARATOR)}
          isMobile={matchesMobileMediaQuery}
        />
      )}
      {currentStepsHaveIllustration && !isContactFromSuccessMessageDisplayed && guideOrientation !== 'vertical' && (
        <Illustrations
          stepsToShowInfo={stepsToShowInfo}
          currentStepId={currentStepId}
          mode={mode}
          parentIsFullscreen={parentIsFullscreen}
          encrypted={!!guideEncrypted}
          scrollable={isScrollable}
          scrollableSteps={scrollableSteps}
          key={JSON.stringify(scrollableSteps)}
        />
      )}
      <RightCanvas
        mode={mode}
        illustrationType={illustrationType}
        isFullscreen={parentIsFullscreen}
        scrollable={isScrollable}
        isIframeType={stepType === STEP_TYPE.iframe}
      >
        <ContentCanvas
          mode={mode}
          matchMode={matchMode}
          illustrationType={illustrationType}
          isFullscreen={parentIsFullscreen}
          ref={contentWrapRef}
          scrollable={isScrollable}
          borderlessEmbed={borderlessEmbed}
          guideOrientation={guideOrientation}
          compact={compact}
          onClick={onContentClick}
          className="scrollable-step-canvas"
        >
          {stepsToShow}
        </ContentCanvas>
        {mode !== 'preview' && (
          <AnimatePresence>
            {!userScrolled && showScrollArrow && (
              <ScrollIndicatorWrap
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                compact={compact}
              >
                <ScrollIndicator
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                />
              </ScrollIndicatorWrap>
            )}
          </AnimatePresence>
        )}
        {mode !== 'preview' && <StepsFooter dataCy="stepButtonsDesktop" type="desktop" {...footerProps} />}
      </RightCanvas>
      {mode !== 'preview' && <StepsFooter dataCy="stepButtonsMobile" type="mobile" {...footerProps} />}
      <RightPanelFeedback
        toggleComponent={toggleComponent}
        currentExplanationId={currentExplanationId}
        stepId={stepId}
        show={selectedComponent === 'feedbackPanel'}
        mode={mode}
        overrideType={mode === 'embed' || mode === 'embedModal' ? 'wrapped' : undefined}
      />
      <RightPanelPreviousSteps
        toggleComponent={toggleComponent}
        stepsPath={stepsPath}
        show={selectedComponent === 'previousStepsPanel'}
        overrideType={mode === 'embed' || mode === 'embedModal' ? 'wrapped' : undefined}
        onStepClick={clickedStepId => scrollToStep(clickedStepId)}
        mode={mode}
      />
      <RightPanelComments
        toggleComponent={toggleComponent}
        stepId={currentStepId}
        guideId={guideInfo.guideId}
        currentGuideId={currentExplanationId}
        access={access}
        mode={mode}
        show={selectedComponent === 'commentsPanel'}
        overrideType={mode === 'embed' || mode === 'embedModal' ? 'wrapped' : undefined}
      />
      <RightPanelLanguage
        toggleComponent={toggleComponent}
        languageList={guideOptions.languageList}
        setLanguage={setLanguage}
        mode={mode}
        show={selectedComponent === 'languagePanel'}
        overrideType={mode === 'embed' || mode === 'embedModal' ? 'wrapped' : undefined}
      />
    </Canvas>
  );
}

Steps.propTypes = {
  firstStep: PropTypes.number,
  displayStepTitle: PropTypes.bool,
  displayProgressBar: PropTypes.bool,
  displayTableOfContentsTree: PropTypes.bool,
  displayTableOfContentsFlowchart: PropTypes.bool,
  stepsPath: PropTypes.array,
  mode: PropTypes.string,
  match: PropTypes.object,
  history: PropTypes.object,
  currentExplanationId: PropTypes.string,
  borderlessEmbed: PropTypes.bool,
  displayAds: PropTypes.bool,
  setLanguage: PropTypes.func,
  userManagement: PropTypes.object,
  guideOrientation: PropTypes.string,
  compact: PropTypes.bool,
  widgetSizeType: PropTypes.string,

  // withFullscreen
  parentCanvasRef: PropTypes.object,
  parentIsFullscreen: PropTypes.bool,
  openParentFullscreen: PropTypes.func,
  closeParentFullscreen: PropTypes.func,
};

export default withFullscreen(Steps);
