import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTreeModifiers } from './useTreeModifiers';

const EMPTY_FOCUS_ROW_UUID_LIST = [];

const defaultGenerateFocusRowUuidListFromResult = resultArr => resultArr.map(item => item.uuid);

/** Props for input to search in the tree - along with actions, resultCount etc. */
export const useTreeSearchInputProps = ({
  /** Determines if row is considered as matching the search */
  getIsRowMatching,
  generateFocusRowUuidListFromResult = defaultGenerateFocusRowUuidListFromResult,
}) => {
  const goToStepTimeoutRef = useRef();
  const {
    setSelectedByRule: setSelectedTreeRowByRule,
    setSelected: setSelectedTreeRow,
    goToRow: goToTreeRow,
    closeTemporaryOpened: closeTemporaryOpenedTreeFolders,
  } = useTreeModifiers();

  const [searchValue, setSearchValue] = useState('');
  const [focusedResultRowIndex, setFocusedIndex] = useState(0);
  const [focusRowUuidList, setFocusRowUuidList] = useState(EMPTY_FOCUS_ROW_UUID_LIST);

  const resultsCount = focusRowUuidList.length;
  const isGoToNextResultRowDisabled = !resultsCount;
  const isGoToPreviousResultRowDisabled = !resultsCount;

  const clearResults = () => {
    setFocusRowUuidList(EMPTY_FOCUS_ROW_UUID_LIST);
    setFocusedIndex(0);
    setSelectedTreeRow({});
    closeTemporaryOpenedTreeFolders();
  };

  const scrollToUuidIfThereIsSome = (uuid, behavior /* default for FlatTree is "smooth" */) => {
    if (uuid) {
      clearTimeout(goToStepTimeoutRef.current);
      goToStepTimeoutRef.current = setTimeout(() => {
        goToTreeRow({ uuid, behavior });
      }, 100);
    }
  };

  const goToNextResultRow = () => {
    if (!isGoToNextResultRowDisabled) {
      const newFocusedRowIndex = focusedResultRowIndex >= resultsCount - 1 ? 0 : focusedResultRowIndex + 1;
      setFocusedIndex(newFocusedRowIndex);
    }
  };

  const goToPreviousResultRow = () => {
    if (!isGoToPreviousResultRowDisabled) {
      const newFocusedRowIndex = focusedResultRowIndex > 0 ? focusedResultRowIndex - 1 : resultsCount - 1;
      setFocusedIndex(newFocusedRowIndex);
    }
  };

  const setSearchPhrase = e => {
    setSearchValue(e.target.value);
  };

  const resetSearch = () => {
    setSearchValue('');
  };

  useEffect(() => {
    const uuidToFocusTo = focusRowUuidList[focusedResultRowIndex];
    scrollToUuidIfThereIsSome(uuidToFocusTo, 'smooth');
  }, [focusedResultRowIndex]);

  useLayoutEffect(() => {
    if (searchValue.length > 2) {
      const foundRowList = setSelectedTreeRowByRule(row => getIsRowMatching(row, searchValue));
      const focusRowUuidListFromResult = generateFocusRowUuidListFromResult(foundRowList);
      setFocusedIndex(0);
      setFocusRowUuidList(focusRowUuidListFromResult);
      const elementToScrollTo = focusRowUuidListFromResult[0];

      if (elementToScrollTo) {
        scrollToUuidIfThereIsSome(focusRowUuidListFromResult[0], 'auto');
      } else {
        closeTemporaryOpenedTreeFolders();
      }
    } else {
      clearResults(undefined);
    }
  }, [searchValue]);

  useEffect(() => () => clearTimeout(goToStepTimeoutRef.current), []);

  return {
    searchPhrase: searchValue,
    setSearchPhrase,
    goToNextResultRow,
    goToPreviousResultRow,
    isGoToNextResultRowDisabled,
    isGoToPreviousResultRowDisabled,
    resetSearch,
    resultsCount,
    focusedResultRowIndex,
    focusedResultRowDisplayNumber: focusedResultRowIndex + 1,
  };
};
