import React, { memo, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import i18n from 'helpers/i18n';
import { fullscreenEnabled } from '@playerCommon/helpers/windowManagement';
import { getIsMobileIntegration, isMobileSafari } from 'helpers/userAgentHelpers';
import Button from '@playerCommon/StandardElements/Button/Button.js';
import FlagSVG from 'resources/icons/flag.svg';
import CommentIconSVG from 'resources/icons/commentIcon.svg';
import CompletedStepsIconSVG from 'resources/icons/completedSteps.svg';
import MoreIconSVG from 'resources/icons/more.svg';
import OpenFullscreenSVG from 'resources/icons/openFullscreen.svg';
import CloseFullscreenSVG from 'resources/icons/closeFullscreen.svg';
import OutboundLinkIconSVG from 'resources/icons/outboundLinkIcon.svg';
import LanguageIconSmallSVG from 'icons/languageIconSmall.svg';
import TTSIconSmallSVG from 'icons/tts-24.svg';
import TTSStopIconSmallSVG from 'icons/stopIcon-24.svg';
import TableOfContentsSVG from 'icons/tableOfContents.svg';
import PencilSVG from 'resources/icons/pencil.svg';
import { WIDGET_SIZE_TYPES } from 'components/consts';
import useGuideData from '@playerCommon/hooks/playerHooks/useGuideData';
import { RIGHT, TTS_SUPPORTED_LANGUAGE_CODES } from 'global';

import StepButtonsDropdown from './StepButtonsDropdown';
import { StyledLoaderSmall } from '../../Steps/Steps.styles';

const ButtonWithTooltips = styled(Button)`
  margin-left: 16px;
`;

const MoreTriggerButton = styled(Button)`
  margin-left: 16px;
  display: none;

  ${({ show }) =>
    show &&
    css`
      display: inline-block;
    `}
`;

const Wrapper = styled.div`
  bottom: 0;
  display: block;
  margin-left: auto;
`;

const Canvas = styled.div`
  margin-top: auto;
  display: flex;
`;

const StepButtons = ({
  toggleComponent,
  isFirstStep,
  mode,
  matchMode,
  toggleFullscreen,
  isFullscreen,
  editExplanation,
  isSurvey,
  widgetSizeType,
  language,
  displayTableOfContentsTree,
  displayTableOfContentsFlowchart,
  isMobile,
  isTableOfContentsEnabled,
  setIsTableOfContentsExpanded,
  isTTSRunning,
  isTTSAvailableOnStep,
  isLoadingTTSData,
  startTTS,
  stopTTS,
}) => {
  const [fullscreenSupported, setFullscreenSupported] = useState(true);
  const { guideOptions = {}, ttsEnabled, isWidget, access } = useGuideData();
  const { allowComments, allowFeedback, languageList } = guideOptions;

  useEffect(() => {
    setFullscreenSupported(fullscreenEnabled());
  }, []);

  /*
   * If no next step is selected (for instance when there are radioButtons)
   * the "next" button is disabled
   */

  let fullscreenButtonIcon;
  let fullscreenButtonLabel;
  if ((fullscreenSupported && mode === 'embed') || isWidget || matchMode.includes('agentAppZendeskPanel')) {
    fullscreenButtonIcon = isFullscreen ? CloseFullscreenSVG : OpenFullscreenSVG;
    fullscreenButtonLabel = i18n('StepButtons.FullscreenButtonTooltip');
  } else {
    fullscreenButtonIcon = OutboundLinkIconSVG;
    fullscreenButtonLabel = i18n('StepButtons.OpenInNewTab');
  }

  const buttonsConfig = [];

  if (ttsEnabled && TTS_SUPPORTED_LANGUAGE_CODES.includes(language) && isTTSAvailableOnStep) {
    let ttsButtonContent = isTTSRunning ? TTSStopIconSmallSVG : TTSIconSmallSVG;
    if (isLoadingTTSData) ttsButtonContent = StyledLoaderSmall;
    buttonsConfig.push({
      content: ttsButtonContent,
      label: isTTSRunning ? i18n('StepButtons.TTSStop') : i18n('StepButtons.TTSStart'),
      tooltip: isTTSRunning ? i18n('StepButtons.TTSStop') : i18n('StepButtons.TTSStart'),
      dataCy: 'ttsButton',
      onClick: isTTSRunning || isLoadingTTSData ? stopTTS : startTTS,
    });
  }

  if (isMobile && isTableOfContentsEnabled && (displayTableOfContentsTree || displayTableOfContentsFlowchart)) {
    buttonsConfig.push({
      content: TableOfContentsSVG,
      label: i18n('ExplanationPlayer.TableOfContents'),
      tooltip: i18n('ExplanationPlayer.TableOfContents'),
      dataCy: 'tableOfContentsButton',
      onClick: () => setIsTableOfContentsExpanded(true),
    });
  }
  if (allowComments && !isSurvey) {
    buttonsConfig.push({
      content: CommentIconSVG,
      label: i18n('StepButtons.CommentsButton'),
      tooltip: i18n('StepButtons.CommentsButtonTooltip'),
      dataCy: 'commentsButton',
      onClick: () => toggleComponent('commentsPanel'),
    });
  }
  if (
    (mode === 'embed' || isWidget || matchMode.includes('agentAppZendeskPanel')) &&
    !getIsMobileIntegration() &&
    !isMobileSafari() &&
    widgetSizeType !== WIDGET_SIZE_TYPES.FULL_SCREEN
  ) {
    buttonsConfig.push({
      content: fullscreenButtonIcon,
      tooltip: fullscreenButtonLabel,
      label: fullscreenButtonLabel,
      onClick: () => toggleFullscreen(),
    });
  }

  if (allowFeedback && !isSurvey) {
    buttonsConfig.push({
      content: FlagSVG,
      label: i18n('StepButtons.FeedbackButton'),
      tooltip: i18n('StepButtons.FeedbackButtonTooltip'),
      dataCy: 'feedbackButton',
      onClick: () => toggleComponent('feedbackPanel'),
    });
  }

  if (!isFirstStep) {
    buttonsConfig.push({
      content: CompletedStepsIconSVG,
      label: i18n('StepButtons.CompletedStepsButton'),
      tooltip: i18n('StepButtons.CompletedStepsButtonToolTip'),
      dataCy: 'completedStepsButton',
      onClick: () => toggleComponent('previousStepsPanel'),
    });
  }

  if (access >= RIGHT.VIEW_EDIT) {
    buttonsConfig.push({
      content: PencilSVG,
      label: i18n('StepButtons.EditExplanationButton'),
      tooltip: i18n('StepButtons.EditExplanationButton'),
      dataCy: 'editButton',
      onClick: editExplanation,
    });
  }

  if (languageList && languageList.split(',').length > 1) {
    buttonsConfig.push({
      content: LanguageIconSmallSVG,
      label: i18n('StepButtons.Language'),
      tooltip: i18n('StepButtons.LanguageTooltip'),
      separation: true,
      dataCy: 'changeLanguage',
      onClick: () => toggleComponent('languagePanel'),
    });
  }

  const displayedButtonsNumber = buttonsConfig.length > 3 ? 2 : 3;

  return (
    <Wrapper mode={mode} className="stepBottomButtons">
      <Canvas>
        {buttonsConfig.slice(0, displayedButtonsNumber).map(btn => (
          <ButtonWithTooltips
            key={btn.tooltip}
            background="white"
            type="icon"
            mouseover="grey"
            disabled={btn.disabled}
            content={btn.content}
            ariaLabel={btn.tooltip}
            tooltip={btn.tooltip}
            positionTooltip="upRight"
            onClick={btn.onClick}
            dataCy={btn.dataCy}
          />
        ))}
        <StepButtonsDropdown
          buttonsConfig={buttonsConfig.slice(displayedButtonsNumber, buttonsConfig.length)}
          trigger={
            <MoreTriggerButton
              background="white"
              type="icon"
              mouseover="grey"
              content={MoreIconSVG}
              show={buttonsConfig.length > displayedButtonsNumber}
            />
          }
        />
      </Canvas>
    </Wrapper>
  );
};

StepButtons.propTypes = {
  toggleComponent: PropTypes.func,
  isFirstStep: PropTypes.bool,
  mode: PropTypes.string,
  matchMode: PropTypes.string,
  toggleFullscreen: PropTypes.func,
  isFullscreen: PropTypes.bool,
  editExplanation: PropTypes.func,
  isSurvey: PropTypes.bool,
  widgetSizeType: PropTypes.string,
  language: PropTypes.string,
  displayTableOfContentsTree: PropTypes.bool,
  displayTableOfContentsFlowchart: PropTypes.bool,
  isMobile: PropTypes.bool,
  isTableOfContentsEnabled: PropTypes.bool,
  isTableOfContentsExpanded: PropTypes.bool,
  setIsTableOfContentsExpanded: PropTypes.func,
  isTTSRunning: PropTypes.bool,
  isLoadingTTSData: PropTypes.bool,
  startTTS: PropTypes.func,
  stopTTS: PropTypes.func,
};

export default memo(StepButtons, isEqual);
