import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import CrossSVG from 'icons/cross-12.svg';
import CopySVG from 'icons/copy.svg';
import TickSVG from 'icons/tickMedium.svg';

export const SmartSearchResultsSection = styled.section`
  max-width: 100%;
  margin-top: 60px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  transition-delay: 0.1s;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${({ initialMinHeightSet }) =>
    initialMinHeightSet &&
    css`
      opacity: 1;
      visibility: visible;
    `}

  @media screen and (min-width: 900px) {
    width: 100%;
  }

  @media screen and (max-width: 899px) {
    margin-top: 40px;
    padding: 0 ${props => props.theme.contentWrapSubDesktopPadding};
  }

  @media screen and (max-width: 480px) {
    margin-top: 32px;
    padding: 0 ${props => props.theme.contentWrapSubDesktopPadding};
  }
`;

export const SourceStepsResultsSection = styled.section`
  max-width: 100%;
  margin-top: 34px;

  @media screen and (min-width: 900px) {
    max-width: 640px;
  }
`;

export const SmartSearchAnswerWrap = styled.div`
  position: relative;
  display: block;
  border-radius: 10px;
`;

export const SmartSearchAnswerWrapAnimatedWrap = styled.div`
  position: relative;
  display: block;
  border-radius: 10px;
`;

export const SmartSearchResultTitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${props => props.theme.steel};
  text-transform: uppercase;
  margin-top: 32px;
  margin-bottom: 16px;
  padding-left: 0px;

  &:first-child {
    margin-top: 0;
  }

  @media screen and (max-width: 480px) {
    padding-left: 16px;
  }
`;

export const WidgetSmartSearchWrap = styled.div`
  ${SmartSearchResultTitle} {
    padding-left: 24px;
  }

  ${SmartSearchAnswerWrap} {
    padding: 0 16px;
    position: relative;
    border-radius: 0px;
    margin-top: 0px;
    background: transparent;

    &:before {
      display: none;
    }
  }
`;

export const CrossIcon = styled(CrossSVG)`
  vertical-align: middle;
  align-self: center;
  margin-left: 6px;
  margin-right: -4px;
  path {
    fill: currentColor !important;
  }
`;

export const CopyIcon = styled(CopySVG)`
  path {
    fill: currentColor !important;
  }
`;

export const CopiedIcon = styled(TickSVG)`
  path {
    fill: currentColor !important;
  }
`;

export const SmartSearchQuestionWrap = styled.blockquote`
  margin: 0;
  display: flex;
  width: auto;
  max-width: 80%;
  align-self: flex-end;
  padding: 8px 16px;
  align-items: center;
  font-size: ${props => props.theme.aiAnswerAnswerFontSize};
  line-height: ${props => props.theme.aiAnswerAnswerLineHeight};
  font-weight: 500;
  border-radius: 12px;
  margin-bottom: 0px;
  background: ${props => props.theme.defaultQuestion};
  color: white;
  outline: none;
  word-break: break-word;

  @media screen and (max-width: 899px) {
    font-size: ${props => props.theme.playerMobileAiAnswerAnswerFontSize};
    line-height: ${props => props.theme.playerMobileAiAnswerAnswerLineHeight};
  }
`;

export const StepCover = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  transition: opacity 0.3s, visibility 0.3s;
  transition-delay: 0.1s;
  opacity: 1;
  visibility: visible;
  z-index: 1;
  background: white;

  ${({ hidden }) =>
    hidden &&
    css`
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    `}
`;
