import React, { useMemo } from 'react';

import { FlatTree } from '@playerCommon/ComplexElements/FlatTree/FlatTree';
import { Row } from './Row';

export const GuideStepsTree = ({ onStepClick }) => {
  const rowStaticProps = useMemo(() => ({ onStepClick }), [onStepClick]);

  return <FlatTree RowBlockComponent={Row} rowStaticProps={rowStaticProps} />;
};
