import React, { useEffect, useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory, withRouter } from 'react-router-dom';
import useOnClickOutside from '@playerCommon/hooks/useClickOutside';
import i18n from 'helpers/i18n';
import SearchResults from '@playerCommon/Commons/Search/SearchResults';
import { createSlug } from 'helpers/guidePlayerHelpers';
import {
  Canvas,
  CloseWrap,
  Cross,
  SearchInput,
  SearchInputFlexContainer,
  SearchInputIcon,
  SearchInputSubmit,
  SearchInputWrap,
  SearchResultsWrap,
  DarkSearchInputIcon,
} from './SearchBar.styles';
import { useSearchContext } from './searchContext';
import { useContactFormOptionsChecking } from '../../Pages/helpers';

// static value to check if one of the search is focused
let isSomeSearchFocused = false;

function setIsSomeSearchFocused(value) {
  isSomeSearchFocused = value;
}

function SearchBar({
  mode,
  className,
  helpcenterData,
  onFocus,
  onBlur,
  match,
  placeholder,
  onClearSearch,
  isStrokeEnabled,
  isBlurred,
  onContactFormClick,
  shouldHideSubmit,
  tabIndex,
  shouldFocusSearchInput,
}) {
  const [shouldShowSearchPopover, setShouldShowSearchPopover] = useState(true);

  const [focused, setFocused] = useState(false);
  const [clickedOutside, setClickedOutside] = useState(true);
  const [searchWrapFocused, setSearchWrapFocused] = useState(false);

  const {
    searchPhrase,
    onSearchChange,
    clearSearch,
    onSearchResultClick,
    results,
    loading,
    allResultsLength,
    hasSemanticAnswer,
  } = useSearchContext();

  const canvasRef = useRef();
  const inputRef = useRef();
  const history = useHistory();

  const { isContactFormOnNoResultsEnabled } = useContactFormOptionsChecking(helpcenterData.knowledgeBase);

  const shouldDisplaySearchPopover =
    !!helpcenterData.knowledgeBase.searchSettings.showStepsInSuggestions ||
    !!helpcenterData.knowledgeBase.searchSettings.showGuidesInSuggestions ||
    !!helpcenterData.knowledgeBase.searchSettings.showFoldersInSuggestions;
  const shouldShowSearchResults =
    searchPhrase.length > 2 && (!clickedOutside || focused) && shouldDisplaySearchPopover && shouldShowSearchPopover;
  const shouldShowClose = mode === 'mobile';
  const shouldShowSubmit = !['widget', 'inHeader', 'inStickyHeader', 'mobile'].includes(mode) && !shouldHideSubmit;

  useEffect(() => {
    const onSearchWrapFocus = () => setSearchWrapFocused(true);
    const onSearchWrapBlur = () => setSearchWrapFocused(false);

    canvasRef.current.addEventListener('focus', onSearchWrapFocus);
    canvasRef.current.addEventListener('blur', onSearchWrapBlur);

    return () => {
      canvasRef.current.removeEventListener('focus', onSearchWrapFocus);
      canvasRef.current.removeEventListener('blur', onSearchWrapBlur);
    };
  }, []);

  useEffect(() => {
    if (isBlurred) {
      setFocused(false);
      setClickedOutside(true);
      setSearchWrapFocused(false);
    }
  }, [isBlurred]);

  const {
    params: { mode: kbMode, language },
  } = match;

  const isWidget = mode === 'widget';

  function getItemUrl(item) {
    switch (item.type) {
      case 'folder': {
        return `/${kbMode || 'kb'}/${language}/${item.folderId ? `${createSlug(item.name)}-${item.folderId}` : ''}`;
      }
      case 'explanation':
      case 'guide': {
        return `/${kbMode || 'kb'}/guide/${language}/${createSlug(item.name)}-${item.guideId}/Steps/${
          item.firstStepId
        }`;
      }
      case 'step': {
        return `/${kbMode || 'kb'}/guide/${language}/${createSlug(item.name)}-${item.guideId}/Steps/${item.stepId}`;
      }
      case 'ai': {
        return `/${isWidget ? 'kb-widget' : 'kb'}/${language}/search/smart/${encodeURIComponent(item.name)}`;
      }
      default: {
        return '';
      }
    }
  }

  function goToSearchPage() {
    if (searchPhrase) {
      window.location = history.createHref({
        pathname: `/${isWidget ? 'kb-widget' : 'kb'}/${language}/search/all/${encodeURIComponent(searchPhrase)}`,
        search: window.location.search,
      });
    }
  }

  function goToSmartSearchPage() {
    if (searchPhrase) {
      window.location = history.createHref({
        pathname: `/${isWidget ? 'kb-widget' : 'kb'}/${language}/search/smart/${encodeURIComponent(searchPhrase)}`,
        search: window.location.search,
      });
    }
  }

  function handleSearchInputKeyPress(e) {
    if (e.which === 13) {
      if (hasSemanticAnswer) {
        goToSmartSearchPage();
      } else {
        goToSearchPage();
      }
    }
  }

  useOnClickOutside(canvasRef, () => {
    setClickedOutside(true);
    setSearchWrapFocused(false);
  });

  const onClearSearchProxy = useCallback(() => {
    if (onClearSearch) onClearSearch();
    clearSearch();
  }, [clearSearch, onClearSearch]);

  const handleKeyDown = e => {
    if (e.key === ' ' || e.key === 'Enter') {
      onClearSearchProxy();
    }
  };

  const searchInputClearAction = {
    func: onClearSearchProxy,
    visible: false,
  };

  const handleSearchInputClearField = () => {
    if (!['mobile'].includes(mode)) searchInputClearAction();
  };

  const getSearchInputPlaceholder = () =>
    placeholder || (mode === 'inHeader' ? i18n('HelpcenterHome.Search') : i18n('HelpcenterHome.SearchLong'));

  const onFocusProxy = useCallback(() => {
    if (onFocus) onFocus();
    setFocused(true);
    setSearchWrapFocused(true);
    setClickedOutside(false);
    setIsSomeSearchFocused(true);
  }, [onFocus]);

  function updateSearchPhrase(e) {
    const { value } = e.target;
    onSearchChange(value, onFocusProxy);
  }

  const onBlurProxy = useCallback(() => {
    if (onBlur) {
      setTimeout(() => onBlur(), 50);
    }
    setFocused(false);
    setIsSomeSearchFocused(false);
  }, [onBlur]);

  const onContactFormClickProxy = useCallback(() => {
    onContactFormClick();
    setFocused(false);
    setClickedOutside(true);
    setSearchWrapFocused(false);
  }, [onContactFormClick]);

  useEffect(() => {
    if (!shouldShowSearchPopover && (focused || searchPhrase !== decodeURIComponent(match.params.search))) {
      setShouldShowSearchPopover(true);
    }
  }, [searchPhrase, focused]);

  // autofocus
  useEffect(() => {
    if (inputRef.current && shouldFocusSearchInput) {
      inputRef.current.focus();
      setIsSomeSearchFocused(true);
    }
  }, []);

  useEffect(() => {
    if (!shouldFocusSearchInput) {
      return;
    }
    if (searchPhrase) {
      return;
    }
    if (!isSomeSearchFocused) {
      return;
    }
    inputRef.current?.focus?.();
  }, [searchPhrase, shouldFocusSearchInput]);

  const hasOnlyShowAllResultsButton = results.length === 0 && !loading && allResultsLength > 0;

  return (
    <Canvas className={['searchBar', className].join(' ')} mode={mode} ref={canvasRef} tabIndex={tabIndex}>
      <SearchInputFlexContainer data-stonly-trigger={`kbSearchBar${mode || ''}`}>
        {shouldShowClose && (
          <CloseWrap
            onClick={onClearSearchProxy}
            onKeyDown={handleKeyDown}
            tabIndex={0}
            role="button"
            aria-label={i18n('Global.Close')}
          >
            <Cross />
          </CloseWrap>
        )}
        <SearchInputWrap mode={mode} focused={focused} isStrokeEnabled={isStrokeEnabled}>
          <SearchInput
            ref={inputRef}
            iconField={mode === 'inStickyHeader' ? DarkSearchInputIcon : SearchInputIcon}
            iconFirst
            skin="wrapped"
            size="small"
            value={searchPhrase}
            onChange={updateSearchPhrase}
            placeholder={getSearchInputPlaceholder()}
            onClearField={handleSearchInputClearField}
            mode={mode}
            onKeyPress={handleSearchInputKeyPress}
            onFocus={onFocusProxy}
            onBlur={onBlurProxy}
            maxLength={250}
            tabIndex={tabIndex}
          />
          {shouldShowSubmit && (
            <SearchInputSubmit
              onClick={hasSemanticAnswer ? goToSmartSearchPage : goToSearchPage}
              mode={mode}
              isStrokeEnabled={isStrokeEnabled}
              tabIndex={tabIndex}
            >
              {i18n('HelpcenterHome.Search')}
            </SearchInputSubmit>
          )}
        </SearchInputWrap>
      </SearchInputFlexContainer>
      <SearchResultsWrap
        mode={mode}
        show={shouldShowSearchResults}
        hasOnlyShowAllResultsButton={hasOnlyShowAllResultsButton && !hasSemanticAnswer}
      >
        <SearchResults
          searchPhrase={searchPhrase}
          results={results}
          allResultsLength={allResultsLength}
          loading={loading}
          showSearchEmpty={false}
          showLengthTooShort={false}
          mode="header"
          itemUrlFormatter={getItemUrl}
          disableBreadcrumbs
          shouldShowHighlight
          shouldOpenInNewTab={!isWidget}
          enableKeyboardNavigation={searchWrapFocused}
          onShowAllResultsClick={goToSearchPage}
          onResultClick={onSearchResultClick}
          onContactFormClick={isContactFormOnNoResultsEnabled ? onContactFormClickProxy : undefined}
          kbContactButtonText={helpcenterData.knowledgeBase.contactButtonText}
          kbResultsNotFoundDropdownText={helpcenterData.knowledgeBase.resultsNotFoundDropdownText}
        />
      </SearchResultsWrap>
    </Canvas>
  );
}

SearchBar.propTypes = {
  className: PropTypes.string,
  mode: PropTypes.string,
  onClearSearch: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  helpcenterData: PropTypes.object,
  match: PropTypes.object,
  placeholder: PropTypes.string,
  autofocus: PropTypes.bool,
  isStrokeEnabled: PropTypes.bool,
  isBlurred: PropTypes.bool,
  onContactFormClick: PropTypes.func,
  shouldHideSubmit: PropTypes.bool,
  tabIndex: PropTypes.number,
  shouldFocusSearchInput: PropTypes.bool,
};

export default withRouter(SearchBar);
