export const getSelectedText = () => {
  let text = '';
  const activeEl = document.activeElement;
  const activeElTagName = activeEl ? activeEl.tagName.toLowerCase() : null;
  if (
    (activeElTagName === 'textarea' || activeElTagName === 'input') &&
    /^(?:text|search|password|tel|url)$/i.test(activeEl.type) &&
    typeof activeEl.selectionStart === 'number'
  ) {
    text = activeEl.value.slice(activeEl.selectionStart, activeEl.selectionEnd);
  } else if (window.getSelection) {
    text = window.getSelection().toString();
  }
  return text;
};

export const removeTags = content => {
  const divToRemoveTags = document.createElement('div');
  divToRemoveTags.innerHTML = content;
  return divToRemoveTags.textContent || divToRemoveTags.innerText || '';
};

export const getTitleFontSize = (title = '') => {
  if (title.length < 50) {
    return 28;
  }
  if (title.length > 65) {
    return 22;
  }
  return 22;
};

const fallbackCopyTextToClipboard = text => {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.append(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error('Oops, unable to copy', err);
  }

  textArea.remove();
};

export const copyTextToClipboard = text => {
  try {
    window.navigator.clipboard.writeText(text);
  } catch {
    fallbackCopyTextToClipboard(text);
  }
};

// Only works in Chrome, not FF
export const copyHTMLToClipboard = text => {
  try {
    const clipboardItem = new ClipboardItem({
      'text/plain': new Blob([text], { type: 'text/plain' }),
      'text/html': new Blob([text], { type: 'text/html' }),
    });

    window.navigator.clipboard.write([clipboardItem]);
  } catch {
    fallbackCopyTextToClipboard(text);
  }
};

// remove diacritics/accents like 'ā' or 'é'
export const removeDiacriticsAndAccents = text => text.normalize('NFD').replaceAll(/\p{Diacritic}/gu, '');

const htmlEntities = {
  '&amp;': '&',
  '&lt;': '<',
  '&gt;': '>',
  '&quot;': '"',
  '&apos;': "'",
};

export function decodeHTMLEntities(str) {
  return str.replaceAll(/((&amp;)|(&lt;)|(&gt;)|(&quot;)|(&apos;)])/g, match => htmlEntities[match]);
}
