import { useServerCallData } from '@playerCommon/Contexts/serverCallDataContext';
import { useUserData } from '@playerCommon/Contexts/userDataContext';
import { convertTemplateStringToDisplayString } from 'helpers/guideVariableHelpers';
import React, { useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { CONTACT_FORM_STEPS, CONTACT_FORM_STAT_TYPE } from 'global';
import i18n from 'helpers/i18n';
import callApi from 'helpers/apiHelpers';
import { validateEmail } from 'helpers/validationHelpers.js';
import useParsedStepContent from '@playerCommon/hooks/useParsedStepContent';
import Button from '@playerCommon/StandardElements/Button';
import StepInputs from '@playerCommon/ComplexElements/ExplanationPlayer/Steps/StepInputs';
import Loader from '@playerCommon/CustomElements/Loader';
import BackButton from '@playerCommon/CustomElements/BackButton';
import { extractBaseData, track as recorderTrack } from 'components/Analytics/recorder';
import { useSentData } from '@playerCommon/Contexts/sentDataContext';
import { generateSessionId } from 'helpers/statIdsManagement';
import InternalNote from '@playerCommon/StandardElements/ContentEditable/InternalNote/InternalNote.player';
import { getNextStepLabel } from '@playerCommon/ComplexElements/ExplanationPlayer/Steps/Steps.helpers';
import {
  STEP_INPUTS_TYPE,
  STEP_INPUTS_CONTENT_TYPE,
} from '@playerCommon/ComplexElements/ExplanationPlayer/Steps/StepInputs/StepInputs.consts';
import { useStepInputsState } from '@playerCommon/ComplexElements/ExplanationPlayer/Steps/provider/StepInputsProvider';
import Alert from '@playerCommon/StandardElements/Alert';
import { Title, ContactFormBody, ButtonsWrap, MainContent } from '../ContactForm.styles';

const SendingOverlay = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(4px);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0.2s, opacity 0.2s;

  ${({ open }) =>
    open &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

const StyledLoader = styled(Loader)`
  width: 16px;
  height: 16px;

  svg circle {
    stroke-width: 4px;
  }
`;

const uploadFile = async (file, sessionId, stepContactFormPublicId) =>
  new Promise((resolve, reject) => {
    const selectedFile = file;
    if (selectedFile) {
      const formData = new FormData();
      formData.append('sessionId', sessionId);
      formData.append('selectedFile', selectedFile, selectedFile.name);
      formData.append('stepModuleId', stepContactFormPublicId);

      callApi(`v1/step/contact/file`, 'post', formData)
        .then(result => {
          resolve(result.data);
        })
        .catch(error => reject(error));
    }
  });

const getSavedFormData = formId => {
  if (!formId) return {};
  try {
    const savedFormData = window.localStorage.getItem(`cf_${formId}`);
    if (savedFormData) {
      return JSON.parse(savedFormData);
    }
  } catch {
    // mute
  }
  return {};
};

const saveFormData = (formId, data) => {
  if (!formId) return;
  try {
    window.localStorage.setItem(`cf_${formId}`, JSON.stringify(data));
  } catch {
    // mute
  }
};

const clearFormData = formId => {
  if (!formId) return;
  try {
    window.localStorage.removeItem(`cf_${formId}`);
  } catch {
    // mute
  }
};

const parseStepInputsToDisplay = ({ stepInputs, emailKey, descriptionKey, subjectKey, question, emailValue }) => {
  return stepInputs.map(stepInput => {
    const stepInputKey = stepInput.properties.key;
    const stepInputContentType = stepInput.properties.contentType;
    const stepInputDefaultValue = stepInput.content.defaultValue;
    const stepInputHiddenValue = stepInput.content.hiddenValue;
    const stepInputLabel = stepInput.content.label;
    if (stepInputKey === emailKey) {
      if (!validateEmail(emailValue)) {
        return {
          ...stepInput,
          properties: {
            ...stepInput.properties,
            // for obligatory email field we will display field if has empty value
            shouldShowIfEmpty: stepInputContentType === STEP_INPUTS_CONTENT_TYPE.hiddenValue && !stepInputHiddenValue,
            isObligatoryInput: true,
            dataCy: 'emailInput',
          },
          content: {
            ...stepInput.content,
            label: stepInputLabel || i18n('HelpcenterContact.EmailFieldLabel'),
          },
        };
      }

      return {
        ...stepInput,
        properties: {
          ...stepInput.properties,
          // for obligatory email field we will display field if has empty value
          shouldShowIfEmpty: stepInputContentType === STEP_INPUTS_CONTENT_TYPE.hiddenValue && !emailValue,
          isObligatoryInput: true,
          dataCy: 'emailInput',
        },
        content: {
          ...stepInput.content,
          label: stepInputLabel || i18n('HelpcenterContact.EmailFieldLabel'),
          hiddenValue:
            stepInputContentType === STEP_INPUTS_CONTENT_TYPE.hiddenValue ? emailValue : stepInputHiddenValue,
          defaultValue: [STEP_INPUTS_CONTENT_TYPE.defaultValue, STEP_INPUTS_CONTENT_TYPE.placeholder].includes(
            stepInputContentType
          )
            ? emailValue
            : stepInputDefaultValue,
        },
      };
    }
    if (stepInputKey === descriptionKey) {
      return {
        ...stepInput,
        properties: {
          ...stepInput.properties,
          isObligatoryInput: true,
          dataCy: 'descriptionInput',
        },
        content: {
          ...stepInput.content,
          defaultValue:
            stepInputContentType === STEP_INPUTS_CONTENT_TYPE.placeholder ? question : stepInputDefaultValue,
          label: stepInputLabel || i18n('HelpcenterContact.Description'),
        },
      };
    }
    if (stepInputKey === subjectKey) {
      return {
        ...stepInput,
        properties: {
          ...stepInput.properties,
          isObligatoryInput: true,
          dataCy: 'subjectInput',
        },
        content: {
          ...stepInput.content,
          label: stepInputLabel || i18n('HelpcenterContact.Topic'),
        },
      };
    }
    return stepInput;
  });
};

// Only save input values that has been provided by the user
// This is to make sure that the input fields are properly populated if they depend on the variables
const getInputValuesToSaveToLocalStorage = (formId, values = {}, dirtyInputs = {}) => {
  const valuesToSave = getSavedFormData(formId) || {};
  Object.keys(values).forEach(key => {
    if (dirtyInputs[key]) {
      if (values[key]) {
        valuesToSave[key] = values[key];
      } else {
        delete valuesToSave[key];
      }
    }
  });
  return valuesToSave;
};

const parseInputValuesToSend = ({ values, stepInputs, emailKey, descriptionKey, subjectKey }) =>
  Object.keys(values).reduce((value, key) => {
    const stepInput = stepInputs.find(stepI => stepI.stepInputModuleId === key);
    if (!stepInput) return value;
    if (stepInput.properties.subtype === STEP_INPUTS_TYPE.ATTACHMENT) return value;

    const stepInputKey = stepInput.properties.key;
    if (stepInputKey === emailKey && values[key]) {
      return {
        ...value,
        email: values[key].trim(),
      };
    }
    if (stepInputKey === descriptionKey && values[key]) {
      return {
        ...value,
        description: values[key],
      };
    }
    if (stepInputKey === subjectKey && values[key]) {
      return {
        ...value,
        title: values[key],
      };
    }
    return {
      ...value,
      inputs: {
        ...value.inputs,
        [stepInputKey]: values[key],
      },
    };
  }, {});

function Form({
  setStep,
  question,
  onBackClick,
  previousUrlToLoad,
  contactFormInfo,
  contactFormTitle,
  isPreview,
  getVisitedStepsData,
  content,
  contactFormContent,
  stepInputs,
  internalNotes,
  onSingleNextStepClick,
  firstConnection,
  shouldShowBackButton,
}) {
  const { submitLabel } = contactFormContent;
  const { stepContactFormPublicId, properties: contactFormProperties } = contactFormInfo;
  const stepInputsRef = useRef();
  const { localData } = useSentData();
  const userData = useUserData();
  const { serverCallVariables } = useServerCallData();

  const [isSending, setIsSending] = useState(false);
  const { inputValues, dirtyInputs, isInputValuesValid } = useStepInputsState();

  const { contactForm: sentContactFormData = {}, guideData } = useSentData();
  const prevFormData = isPreview ? undefined : getSavedFormData(stepContactFormPublicId);
  const [error, setError] = useState();
  const contactFormBodyRef = useRef(null);

  const contentToDisplay = useParsedStepContent({
    content,
    isPreview,
  });

  const singleNextButtonLabel = useMemo(() => {
    const labelWithVariables =
      firstConnection && Object.keys(firstConnection).length !== 0 ? getNextStepLabel(firstConnection) : submitLabel;
    const label = convertTemplateStringToDisplayString({
      content: labelWithVariables,
      localData,
      userData,
      serverCallVariables,
      isPreview,
      shouldWrapVarName: isPreview,
    });
    return label || i18n('Global.Send');
  }, [firstConnection, submitLabel]);

  const onSubmit = async () => {
    stepInputsRef.current.handlePreSendActions();
    if (isInputValuesValid) {
      setIsSending(true);
      const inputValuesToSend = parseInputValuesToSend({
        values: inputValues,
        stepInputs,
        emailKey: contactFormProperties.emailKey,
        descriptionKey: contactFormProperties.descriptionKey,
        subjectKey: contactFormProperties.subjectKey,
      });
      const formData = {
        ...inputValuesToSend,
        stepModuleId: stepContactFormPublicId,
        userAgent: window.navigator.userAgent,
        value: getVisitedStepsData(),
        additionalInfo: sentContactFormData?.additionalInfo
          ? JSON.stringify(sentContactFormData.additionalInfo, null, 2)
          : undefined,
        guideData,
      };

      const { teamId, ...baseData } = await extractBaseData();

      const stepInputAttachments = stepInputs.filter(
        stepInput => stepInput.properties.subtype === STEP_INPUTS_TYPE.ATTACHMENT
      );

      const filesToUpload = [];
      Object.keys(inputValues).forEach(inputValueKey => {
        stepInputAttachments.forEach(stepInputAttachment => {
          if (inputValueKey === stepInputAttachment.stepInputModuleId) {
            filesToUpload.push(...inputValues[inputValueKey]);
          }
        });
      });

      const filesUploads = filesToUpload.map(file => uploadFile(file, baseData.sessionId, stepContactFormPublicId));
      try {
        const fileIds = await Promise.all(filesUploads);
        formData.selectedFiles = fileIds;
      } catch {
        setError(i18n('Validation.FileSizeTooBig'));
        setIsSending(false);
        return;
      }

      contactFormBodyRef.current.scrollTo(0, 0);
      try {
        await callApi('v1/step/contact', 'post', { ...formData, ...baseData });
        recorderTrack({
          actionType: 'contactFormSubmission',
          actionDetail: {
            formType: CONTACT_FORM_STAT_TYPE.CONTACT_FORM,
          },
        });
        setIsSending(false);
        if (firstConnection && Object.keys(firstConnection).length !== 0) {
          onSingleNextStepClick(firstConnection);
        } else {
          setStep(CONTACT_FORM_STEPS.SUCCESS_MESSAGE);
        }
        clearFormData(stepContactFormPublicId);
        // reset session to start tracking as new user
        generateSessionId();
      } catch {
        setError(i18n('Validation.SomethingWentWrong'));
        setIsSending(false);
      }
    }
  };

  const stepInputList = useMemo(
    () =>
      parseStepInputsToDisplay({
        stepInputs,
        emailKey: contactFormProperties.emailKey,
        descriptionKey: contactFormProperties.descriptionKey,
        subjectKey: contactFormProperties.subjectKey,
        question,
        emailValue: sentContactFormData.email,
      }),
    [
      sentContactFormData.email,
      question,
      stepInputs,
      contactFormProperties.emailKey,
      contactFormProperties.subjectKey,
      contactFormProperties.descriptionKey,
    ]
  );

  useEffect(
    () =>
      saveFormData(
        stepContactFormPublicId,
        getInputValuesToSaveToLocalStorage(stepContactFormPublicId, inputValues, dirtyInputs)
      ),
    [inputValues, stepContactFormPublicId, dirtyInputs]
  );

  return (
    <>
      <ContactFormBody data-cy="contactFormBody" locked={isSending} ref={contactFormBodyRef}>
        {contactFormTitle ? <Title className="contact-form-title">{contactFormTitle}</Title> : null}
        <MainContent className="content-text" dataCy="contentText" text={contentToDisplay} isPreview={isPreview} />
        {!!error && (
          <Alert severity="error" dataCy="contactFormError">
            {error}
          </Alert>
        )}
        <StepInputs
          ref={stepInputsRef}
          stepInputs={stepInputList}
          prevData={prevFormData}
          isPreview={isPreview}
          isContactForm
        />
        <SendingOverlay open={isSending}>
          <Loader />
        </SendingOverlay>
      </ContactFormBody>
      {internalNotes.map(internalNote => (
        <InternalNote
          key={internalNote.id}
          added={internalNote.added}
          removed={internalNote.removed}
          content={internalNote.content}
        />
      ))}
      <ButtonsWrap>
        {shouldShowBackButton && (
          <BackButton
            onClick={onBackClick}
            link={previousUrlToLoad}
            disabled={isSending || isPreview}
            className="contact-form-back"
          />
        )}
        <Button
          background="outlineAmaranth"
          mouseover="highlight"
          onClick={onSubmit}
          dataCy="nextStepButton"
          className="contact-form-send"
          disabled={isSending || isPreview}
          content={isSending ? <StyledLoader type="circle" /> : singleNextButtonLabel}
          tooltip={isPreview ? i18n('Preview.ButtonsDisabled') : undefined}
          forceTooltip={isPreview}
        />
      </ButtonsWrap>
    </>
  );
}

Form.propTypes = {
  setStep: PropTypes.func,
  onBackClick: PropTypes.func,
  question: PropTypes.string,
  previousUrlToLoad: PropTypes.string,
  contactFormInfo: PropTypes.object,
  contactFormTitle: PropTypes.string,
  contactFormContent: PropTypes.object,
  content: PropTypes.string,
  isPreview: PropTypes.bool,
  getVisitedStepsData: PropTypes.func.isRequired,
  stepsInput: PropTypes.array,
  firstConnection: PropTypes.object,
  shouldShowBackButton: PropTypes.bool,
};

export default Form;
