import React, { useState, useEffect, useMemo } from 'react';
import { StyleSheetManager } from 'styled-components';
import { Helmet } from 'react-helmet';
import rtlPlugin from 'stylis-plugin-rtl';
import queryString from 'query-string';
import i18n, { getLanguageShorthand, isRTL } from 'helpers/i18n';
import { posthogIdentify, trackSegment } from 'components/Analytics/recorder';
import userContext from '@playerCommon/Contexts/userContext';
import popupContext from '@playerCommon/Contexts/popupContext';
import notificationContext from '@playerCommon/Contexts/notificationContext';
import permissionsContext from '@playerCommon/Contexts/permissionsContext';
import countryCodeContext from '@playerCommon/Contexts/countryCodeContext';
import { MainThemeProvider } from '@playerCommon/Contexts/mainThemeContext';
import StonlyAnalytics from '@playerCommon/Analytics/StonlyAnalytics';
import Notifications from '@playerCommon/CustomElements/Notifications';
import Popup from '@playerCommon/CustomElements/Popup';
import callApi from 'helpers/apiHelpers';
import { setPublicUserId, setUserId } from 'helpers/statIdsManagement';
import { appUrl } from 'global/env';
import { setGlobal, getGlobal } from 'global/windowUtils';
import useDidUpdate from '@playerCommon/hooks/useDidUpdate';
import useForceUpdate from '@playerCommon/hooks/useForceUpdate';
import useSessionStorage from '@playerCommon/hooks/useSessionStorageValue';
import { AdditionalLoadedGuideDataProvider } from '@playerCommon/Contexts/additionalGuideLoaderContext';

function setTrackingIsEnabled(user = {}) {
  if (Object.keys(user).length === 0) {
    setGlobal('userTrackingEnabled', true);
  } else {
    setGlobal('userTrackingEnabled', !!user.trackingEnabled);
  }
}

const withContextProviders =
  (Component, { disableAnalytics = false } = {}) =>
  passedProps => {
    const forceUpdate = useForceUpdate();

    if (passedProps.location) {
      const urlParams = queryString.parse(passedProps.location.search);
      if (urlParams.widgetRuleId) setGlobal('widgetRuleId', urlParams.widgetRuleId);
      if (urlParams.customerUserId) setGlobal('customerUserId', urlParams.customerUserId);
    }

    // -- user context --
    const [user, setUser] = useState(passedProps.user || {});
    if (!getGlobal('csrfToken')) {
      setGlobal('csrfToken', passedProps.csrfToken);
    }
    setTrackingIsEnabled(user);

    useEffect(() => {
      const trackingEnabled = getGlobal('userTrackingEnabled');
      if (trackingEnabled) {
        setTimeout(() => {
          try {
            if (window.analytics) {
              window.analytics.identify(user.logUserId, {
                name: `${user.firstName} ${user.lastName}`,
                email: user.email,
                userAgent: window.navigator.userAgent,
              });
            }
            posthogIdentify({ uniqueName: user.logUserId });
            if (window.posthog) {
              window.posthog.onFeatureFlags(() => {
                if (window.posthog.isFeatureEnabled('session-recording')) {
                  window.posthog.startSessionRecording();
                }
                // it's a test of segmentation for Jean
                if (window.posthog.getFeatureFlag) window.posthog.getFeatureFlag('a_a_test_distribution_1');
              });
            }
          } catch {
            // meh, we don't really care
          }
        }, 50);
      }
    }, []);

    const loadUser = () => {
      callApi('v1/auth/status', 'get').then(res => {
        setGlobal('csrfToken', res.data.csrfToken);
        setTrackingIsEnabled(res.data.user);
        if (res.data.logged) {
          window.stn_userIsLogged = true;
          setUser(res.data.user);
          setUserId(res.data.user.logUserId);
          setPublicUserId(res.data.user.id);
        }
      });
    };
    // -- / user context --

    // -- notification context --
    const [notifications, setNotifications] = useState([]);
    /**
     * Push notification to notification array
     * @param {Object} notifObject
     * @param {Component|string} notifObject.content - Component to show as content
     * @param {Component} notifObject.icon - Component to show as an Icon
     * @param {boolean} notifObject.dismissable - Should show X
     * @param {number} notifObject.timeout - After how much time should close automatically
     * @param {Object} notifObject.onLeave - Function triggered when element disappears
     * @return {Object} Enriched nofifObject with id which can be used to forcefully remove notification
     */
    const pushNotification = notifObject => {
      const defaultNotification = { dismissable: true, key: Date.now() + Math.random() };
      const newNotification = Object.assign(defaultNotification, notifObject);

      setNotifications(notifs => notifs.concat(newNotification));
      return newNotification;
    };

    const removeNotification = notifObject =>
      setNotifications(notifs => notifs.filter(notif => notif.key !== notifObject.key));
    // -- / notification context --

    // -- popup context --
    const [popupOpen, setPopupOpen] = useState(false);
    const closePopup = () => setPopupOpen(false);
    const defaultConfirm = () => {};
    function getInitialPopup() {
      return {
        customContent: undefined,
        style: undefined,
        text: '',
        title: '',
        hideDismiss: false,
        confirmFunction: defaultConfirm,
        dismissFunction: closePopup,
        confirmButtonText: i18n('Global.Confirm'),
        dismissButtonText: i18n('Global.Cancel'),
      };
    }

    const [popup, setPopup] = useState(getInitialPopup());
    useDidUpdate(() => {
      if (!popupOpen) {
        setTimeout(() => setPopup(getInitialPopup()), 200);
      }
    }, [popupOpen]);

    /**
     * Open popup with actionable CTA's
     * @param {Object} popupObject
     * @param {Object} popupObject.customContent - Component to show before title or instead of all other
     * @param {Object} popupObject.style - Custom popup body style
     * @param {string} popupObject.title - Text to show as title
     * @param {string|Component} popupObject.text - Text or component to show as content
     * @param {boolean} popupObject.dismissable - Should allow closing via X
     * @param {Object} popupObject.confirmFunction - Function triggered when confirm button is pressed
     * @param {string} popupObject.confirmButtonText - Text on confirm button
     * @param {string} popupObject.confirmButtonColor - Color of confirm button
     * @param {Object} popupObject.dismissFunction - Function triggered when dismiss button is pressed
     * @param {string} popupObject.dismissButtonText - Text on dismiss button
     */
    const openPopup = popupObject => {
      const initialPopup = getInitialPopup();
      const extendedPopup = { ...initialPopup, ...popupObject };

      const dismissFunction = () => {
        if (popupObject.dismissFunction) popupObject.dismissFunction();
        closePopup();
      };
      const confirmFunction = () => {
        if (popupObject.confirmFunction) popupObject.confirmFunction();
        closePopup();
      };
      setPopup(Object.assign(extendedPopup, { dismissFunction, confirmFunction }));
      setPopupOpen(true);
    };
    // -- / popup context --

    // -- country code context --
    const [countryCode, setCountryCode] = useSessionStorage('countryCode', passedProps.countryCode);
    // -- / country code context --

    // -- permissions context --
    const goToUpgrade = () => {
      window.location.href = `${appUrl}/app/general/userSettings/Plan`;
    };

    const upgradePlan = (upgradeText, reason) => {
      trackSegment({
        url: document.location.href,
        referrer: document.referrer,
        type: 'track',
        params: {},
        event: `billing.paywall-${reason}`,
      });

      openPopup({
        title: i18n('Upgrade.Upgrade'),
        text: upgradeText,
        dismissable: true,
        dismissFunction: closePopup,
        dismissButtonText: i18n('Global.Cancel'),
        confirmFunction: goToUpgrade,
        confirmButtonText: i18n('Upgrade.Upgrade'),
      });
    };

    const getPermission = permissionName => {
      if (user.features) {
        const feature = user.features.find(el => el.feature_code === permissionName);
        if (!feature) return false;

        if (feature.type === 'base') return Boolean(feature.amount);

        if (feature.type === 'amount') {
          return feature.amount === 0 ? Number.POSITIVE_INFINITY : feature.amount;
        }
      }
      return false;
    };

    const planFromBackend = user && user.package;
    let currentPeriod = '';
    let currentPlan = '';
    let currentPlanFull = '';

    if (user && user.package) {
      const isTeamMemberPlan = planFromBackend.includes('member');
      const isEnterprisePlan = planFromBackend.includes('enterprise');

      const planSplit = planFromBackend.split('_');
      [currentPlan] = planSplit;

      currentPlanFull = planFromBackend.replaceAll(/_new|_12m|_1m|_14d/gim, '');

      if (user && user.package && currentPlan !== 'basic' && !isTeamMemberPlan && !isEnterprisePlan) {
        const possiblePeriod = planSplit[planSplit.length - 1];

        if (['12m', '1m', '14d'].includes(possiblePeriod)) {
          currentPeriod = possiblePeriod;
        }
      }
    }
    // -- / permissions context --

    useEffect(() => {
      if (passedProps.user) {
        window.stn_userIsLogged = true;
        setUserId(passedProps.user.logUserId);
        setPublicUserId(passedProps.user.id);
      }
      if (!passedProps.user) loadUser();
    }, []);

    const userManagement = useMemo(() => ({ user, setUser, reloadUser: loadUser }), [user]);
    const notificationContextValue = useMemo(
      () => ({ notifications, pushNotification, removeNotification }),
      [notifications]
    );
    const popupContextValue = useMemo(() => ({ popup, popupOpen, openPopup, closePopup }), [popup, popupOpen]);

    const permissionsContextValue = useMemo(
      () => ({
        permissions: user.features,
        currentPlanFull,
        currentPlan,
        currentPeriod,
        getPermission,
        goToUpgrade,
        upgradePlan,
        isTrial: Boolean(user.trial),
      }),
      [user]
    );

    const countryCodeContextValue = useMemo(
      () => ({
        countryCode,
        setCountryCode,
      }),
      [countryCode, setCountryCode]
    );

    const languageShorthand = getLanguageShorthand();

    const stylisPlugins = [];
    const languageIsRTL = isRTL(languageShorthand);
    if (languageIsRTL) stylisPlugins.push(rtlPlugin);

    return (
      <MainThemeProvider>
        <Helmet>
          <html lang={languageShorthand} dir={languageIsRTL ? 'rtl' : 'ltr'} />
        </Helmet>
        <StyleSheetManager stylisPlugins={stylisPlugins}>
          <userContext.Provider value={userManagement}>
            <notificationContext.Provider value={notificationContextValue}>
              <popupContext.Provider value={popupContextValue}>
                <permissionsContext.Provider value={permissionsContextValue}>
                  <countryCodeContext.Provider value={countryCodeContextValue}>
                    <AdditionalLoadedGuideDataProvider>
                      {!disableAnalytics && <StonlyAnalytics.RouteTracker />}
                      <Notifications />
                      <Popup />
                      <Component {...passedProps} forceUpdateAtRoot={forceUpdate} />
                    </AdditionalLoadedGuideDataProvider>
                  </countryCodeContext.Provider>
                </permissionsContext.Provider>
              </popupContext.Provider>
            </notificationContext.Provider>
          </userContext.Provider>
        </StyleSheetManager>
      </MainThemeProvider>
    );
  };

export default withContextProviders;
