import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import callApi from 'helpers/apiHelpers.js';
import { useTranslation } from 'react-i18next';

import { Canvas, Top, Title, ErrorText } from '../Authentication.styles.js';

const Unsubscribe = ({ token }) => {
  const { t } = useTranslation();
  const [tokenInvalid, setTokenInvalid] = useState(false);
  const [unsubscribed, setUnsubscribed] = useState(false);
  const [hasOtherError, setHasOtherError] = useState(false);

  useEffect(() => {
    callApi(`v1/user/unsubscribe`, 'post', { verificationCode: token })
      .then(() => {
        setTokenInvalid(false);
        setUnsubscribed(true);
      })
      .catch(res => {
        const errorMessage = res.error?.message;
        if (errorMessage === 'could_not_unsubscribe') {
          setTokenInvalid(true);
          setUnsubscribed(false);
        } else {
          setHasOtherError(true);
        }
      });
  }, []);

  return (
    <Canvas>
      <Top>
        <Title>{t('SignIn.UnsubscribeTitle')}</Title>
      </Top>
      {tokenInvalid && !unsubscribed && <ErrorText>{t('SignIn.UnsubscribeTokenInvalid')}</ErrorText>}
      {!tokenInvalid && unsubscribed && <ErrorText>{t('SignIn.Unsubscribed')}</ErrorText>}
      {hasOtherError && <ErrorText>{t('SignIn.UnsubscribeUnknownError')}</ErrorText>}
    </Canvas>
  );
};

Unsubscribe.propTypes = {
  token: PropTypes.string,
};

export default Unsubscribe;
