import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled, { createGlobalStyle, ThemeProvider, useTheme } from 'styled-components';
import { setLanguage } from 'helpers/i18n.helpers';
import { createPathFromParam } from 'helpers/explanationManagement.js';
import { validateHexaDecimal } from 'helpers/validationHelpers.js';
import Loader, { FullscreenLoaderWrap } from '@playerCommon/CustomElements/Loader/Loader';
import ExplanationPlayer from '@playerCommon/ComplexElements/ExplanationPlayer';
import withContextProviders from 'HOC/withContextProviders';
import fullscreenContext from '@playerCommon/Contexts/fullscreenContext';
import { getStepsGlobalScrollbarStyles } from '@playerCommon/CommonStyledComponents/CustomScrollbar.jsx';
import { postMessage } from 'helpers/widgetHelpers';
import { generatePath, withRouter } from 'react-router-dom';
import ExplanationHelmet from '@playerCommon/CustomElements/ExplanationHelmet';
import { appUrl } from 'global/env';

import {
  openFullscreen,
  closeFullscreen,
  fullscreenEnabled,
  isFullscreen,
} from '@playerCommon/helpers/windowManagement';

const Canvas = styled.div`
  height: 100%;
  @media screen and (max-width: 899px) {
    display: flex;
    flex-direction: column;
  }
`;

const GlobalScrollStyles = createGlobalStyle`
  ${getStepsGlobalScrollbarStyles({ marginTop: '0px', marginBottom: '0px' })}
`;

function GuideEditorPreview({ match, history, forceUpdateAtRoot }) {
  const theme = useTheme();
  const [guide, setGuide] = useState({});
  const [guideLoaded, setGuideLoaded] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const fullscreenCanvasRef = useRef();
  const { pathToLoad } = match.params;

  const messageListener = useCallback(message => {
    if (!message.origin.startsWith(appUrl)) return;

    if (message.data.type === 'setGuideDataForPreview') {
      setGuide(message.data.value);
      setGuideLoaded(true);
    }
    if (message.data.type === 'setStep') {
      history.replace(generatePath(match.path, { ...match.params, pathToLoad: message.data.value }));
    }
    if (message.data.type === 'setCurrentLanguage') {
      setLanguage(message.data.value);
      forceUpdateAtRoot();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', messageListener);
    postMessage({ type: 'initiateGuidePreview' });

    return () => window.removeEventListener('message', messageListener);
  }, []);

  const themeToProvide = useMemo(() => {
    if (!guide || !guide.guideInfo) return theme;
    const { buttonColor: buttonColorFromInfo } = guide.guideInfo;
    const { buttonColor: buttonColorFromOptions } = guide.guideOptions;
    const buttonColor = buttonColorFromInfo || buttonColorFromOptions;
    if (buttonColor && validateHexaDecimal(buttonColor)) {
      return { ...theme, highlightColor: buttonColor };
    }
    return theme;
  }, [guide.guideInfo]);

  const stepsPath = useMemo(() => createPathFromParam(pathToLoad), [pathToLoad]);

  const openParentFullscreen = useCallback(() => {
    if (fullscreenEnabled()) {
      openFullscreen(fullscreenCanvasRef.current);
      setIsFullScreen(true);
    }
  }, []);

  const closeParentFullscreen = useCallback(() => {
    if (isFullScreen && isFullscreen()) {
      closeFullscreen();
    }
    setIsFullScreen(false);
  }, [isFullScreen]);

  const fullscreenContextValue = useMemo(() => {
    return {
      parentIsFullscreen: isFullScreen,
      openParentFullscreen,
      closeParentFullscreen,
      parentCanvasRef: fullscreenCanvasRef,
    };
  }, [closeParentFullscreen, openParentFullscreen, isFullScreen]);

  if (!guideLoaded) {
    return (
      <FullscreenLoaderWrap>
        <Loader type="circle" />
      </FullscreenLoaderWrap>
    );
  }

  let firstStep = null;
  if (guide.guideInfo) firstStep = guide.guideInfo.startId;

  // eslint-disable-next-line xss/no-mixed-html
  return (
    <ThemeProvider theme={themeToProvide}>
      <ExplanationHelmet customCSS={guide?.guideOptions?.designSettings?.customHtml} />
      <GlobalScrollStyles />
      <Canvas ref={fullscreenCanvasRef}>
        <fullscreenContext.Provider value={fullscreenContextValue}>
          <ExplanationPlayer
            mode="preview"
            guide={guide}
            firstStep={firstStep}
            stepsPath={stepsPath}
            displayProgressBar={false}
          />
        </fullscreenContext.Provider>
      </Canvas>
    </ThemeProvider>
  );
}

GuideEditorPreview.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  forceUpdateAtRoot: PropTypes.func,
};

export default withContextProviders(withRouter(GuideEditorPreview));
