import React, { useState } from 'react';
import i18n from 'helpers/i18n';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import WarningSVG from 'icons/warning.svg';
import { PopupContent } from '@playerCommon/CustomElements/Popup';
import Checkbox from '@playerCommon/StandardElements/Checkbox';

const Canvas = styled.div`
  display: flex;
  flex-direction: column;
`;

const CheckboxWrapper = styled.div`
  margin-top: 24px;
`;

const StyledPopupContentActions = styled(PopupContent.Actions)`
  padding: 32px 32px 28px 32px;
`;

const StyledTitleIcon = styled(PopupContent.TitleIcon)`
  display: flex;
  justify-content: center;
`;

const StyledWarningIcon = styled(WarningSVG)`
  width: 32px;
  height: 32px;

  path {
    fill: ${({ theme }) => theme.mango};
  }
`;

const ScamGuidePopup = ({ onConfirm }) => {
  const [isConfirmChecked, setIsConfirmChecked] = useState(false);

  return (
    <>
      <PopupContent.Body>
        <Canvas>
          <PopupContent.Title>
            <StyledTitleIcon>
              <StyledWarningIcon />
            </StyledTitleIcon>
            {i18n('ExplanationPlayer.ScamGuidePopupTitle')}
          </PopupContent.Title>
          <PopupContent.Text>{i18n('ExplanationPlayer.ScamGuidePopupText')}</PopupContent.Text>
          <CheckboxWrapper>
            <Checkbox
              checked={isConfirmChecked}
              onClick={() => setIsConfirmChecked(!isConfirmChecked)}
              label={i18n('ExplanationPlayer.ScamGuidePopupCheckbox')}
              size="normal"
            />
          </CheckboxWrapper>
        </Canvas>
      </PopupContent.Body>
      <StyledPopupContentActions>
        <PopupContent.ConfirmButton
          content={i18n('ExplanationPlayer.ScamGuidePopupButton')}
          onClick={onConfirm}
          disabled={!isConfirmChecked}
        />
      </StyledPopupContentActions>
    </>
  );
};

ScamGuidePopup.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  onConfirm: PropTypes.func,
};

export default ScamGuidePopup;
