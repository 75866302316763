export const buildLeftGapStyleForLevel = (level = 0) => ({
  marginLeft: `${level * 24}px`,
  width: `calc(100% - ${level * 24}px)`,
});

const EMPTY_STYLE = {};
export const buildBackgroundStyleForHighlightColor = (color = '', { colorOpacity } = {}) => {
  if (!color) {
    return EMPTY_STYLE;
  }

  const isHexColor = color.startsWith('#') && color.length === 7;
  const opacity = colorOpacity || '';
  return {
    backgroundColor: isHexColor ? `${color}${opacity}` : color,
  };
};
