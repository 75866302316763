import styled, { css } from 'styled-components';

const TICK_ICON_CLASS = 'tick-icon';

const InputElement = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const TooltipWrap = styled.span`
  line-height: 16px;
  margin-top: 2px;
  margin-left: 4px;
`;

const Text = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin-left: 8px;
  user-select: none;

  p {
    margin: 0;
    display: inline;
  }
`;

const RequiredLabelSuffix = styled.span.attrs({ ariaHidden: true })`
  color: ${({ theme }) => theme.pink};
  margin-left: 2px;
`;

const FieldCheckWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const LabelWrap = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  align-items: start;
`;

const LabelElement = styled.label<{ $disabled?: boolean }>`
  display: flex;
  align-items: start;
  cursor: pointer;
  word-break: break-word;
  border-radius: 2px;

  ${({ $disabled, theme }) =>
    $disabled &&
    css`
      pointer-events: none;
      ${Text} {
        color: ${theme.lightGrey};
      }
    `}

  &:hover .${TICK_ICON_CLASS} {
    border-color: ${({ theme }) => theme.highlightColor};
  }

  &:focus-within {
    outline: 2px solid ${({ theme }) => theme.darkBlue};
    outline-offset: 2px 4px;
  }
`;

const InputMessageWrap = styled.div<{ $textColor?: string | false }>`
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  width: 100%;
  color: ${({ theme, $textColor }) => $textColor || theme.darkGrey};
  margin-top: 4px;
  display: flex;
  align-items: flex-start;
`;

const InputMessageIconWrap = styled.div<{ $color: string | undefined }>`
  display: flex;
  align-items: center;
  height: 12px;
  width: 12px;
  margin-top: 2px;
  margin-right: 4px;
  & svg path {
    fill: currentColor;
  }
`;

export default {
  LabelElement,
  Text,
  FieldCheckWrap,
  LabelWrap,
  InputElement,
  RequiredLabelSuffix,
  TooltipWrap,
  TICK_ICON_CLASS,
  InputMessageWrap,
  InputMessageIconWrap,
};
