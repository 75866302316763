import i18n from 'helpers/i18n';
import { assetsUrl } from 'global/env';

export const checklistItemTypes = {
  GUIDE_COMPLETED: 'GUIDE_COMPLETED',
  EXTERNAL_CONDITION: 'EXTERNAL_CONDITION',
  URL_OPENED: 'URL_OPENED',
  MANUAL_CHECK: 'MANUAL_CHECK',
  PRE_CHECKED: 'PRE_CHECKED',
};

export const actionsOnClick = {
  GUIDE: 'GUIDE',
  LINK: 'LINK',
  NONE: 'NONE',
};

export const checklistItemTypeNames = {
  [checklistItemTypes.GUIDE_COMPLETED]: i18n('ChecklistEdit.MarkCompletedGuide'),
  [checklistItemTypes.EXTERNAL_CONDITION]: i18n('ChecklistEdit.MarkCompletedExternal'),
  [checklistItemTypes.URL_OPENED]: i18n('ChecklistEdit.MarkCompletedUrl'),
  [checklistItemTypes.MANUAL_CHECK]: i18n('ChecklistEdit.MarkCompletedManual'),
  [checklistItemTypes.PRE_CHECKED]: i18n('ChecklistEdit.MarkCompletedPreChecked'),
};

export const checklistItemTypeDescriptions = {
  [checklistItemTypes.GUIDE_COMPLETED]: i18n('ChecklistEdit.MarkCompletedGuideDescription'),
  [checklistItemTypes.EXTERNAL_CONDITION]: i18n('ChecklistEdit.MarkCompletedExternalDescription'),
  [checklistItemTypes.URL_OPENED]: i18n('ChecklistEdit.MarkCompletedUrlDescription'),
  [checklistItemTypes.MANUAL_CHECK]: i18n('ChecklistEdit.MarkCompletedManualDescription'),
  [checklistItemTypes.PRE_CHECKED]: i18n('ChecklistEdit.MarkCompletedPreCheckedDescription'),
};

export const checklistItemTypeMedia = {
  [checklistItemTypes.GUIDE_COMPLETED]: `${assetsUrl}resources/icons/guideHollowIcon.svg`,
  [checklistItemTypes.EXTERNAL_CONDITION]: `${assetsUrl}resources/icons/externalCondition.svg`,
  [checklistItemTypes.URL_OPENED]: `${assetsUrl}resources/icons/link.svg`,
  [checklistItemTypes.MANUAL_CHECK]: `${assetsUrl}resources/icons/pointerHandSmall.svg`,
  [checklistItemTypes.PRE_CHECKED]: `${assetsUrl}resources/icons/automaticCheck.svg`,
};

export const checklistItemTypeOptions = Object.keys(checklistItemTypes).map(el => ({
  id: el,
  label: checklistItemTypeNames[el],
  subContent: checklistItemTypeDescriptions[el],
  value: el,
  separation: el === checklistItemTypes.PRE_CHECKED,
  bulletMedia: [checklistItemTypeMedia[el]],
  bulletMediaSize: 16,
}));

const defaultActionNames = {
  [actionsOnClick.GUIDE]: i18n('ChecklistEdit.ActionOnClickGuide'),
  [actionsOnClick.LINK]: i18n('ChecklistEdit.ActionOnClickUrl'),
  [actionsOnClick.NONE]: i18n('ChecklistEdit.ActionOnClickNone'),
};

const actionOnClickDescriptions = {
  [actionsOnClick.GUIDE]: i18n('ChecklistEdit.ActionOnClickGuideDescription'),
  [actionsOnClick.LINK]: i18n('ChecklistEdit.ActionOnClickUrlDescription'),
  [actionsOnClick.NONE]: i18n('ChecklistEdit.ActionOnClickNoneDescription'),
};

const actionOnClickOptionsByChecklistItemType = {
  [checklistItemTypes.GUIDE_COMPLETED]: [actionsOnClick.GUIDE, actionsOnClick.LINK],
  [checklistItemTypes.URL_OPENED]: [actionsOnClick.LINK],
  [checklistItemTypes.EXTERNAL_CONDITION]: [actionsOnClick.GUIDE, actionsOnClick.LINK, actionsOnClick.NONE],
  [checklistItemTypes.MANUAL_CHECK]: [actionsOnClick.GUIDE, actionsOnClick.LINK, actionsOnClick.NONE],
  [checklistItemTypes.NONE]: [actionsOnClick.NONE],
};

const resolveActionLabel = (actionKey, checklistItemType = undefined) => {
  if (checklistItemType === checklistItemTypes.URL_OPENED && actionKey === actionsOnClick.LINK) {
    return i18n('ChecklistEdit.ActionOnClickUrlForUrlOpened');
  }
  return defaultActionNames[actionKey];
};

export const getActionOnClickOptions = checklistItemType => {
  const keys = actionOnClickOptionsByChecklistItemType[checklistItemType] || [];
  return keys.map(el => ({
    id: el,
    label: resolveActionLabel(el, checklistItemType),
    subContent: actionOnClickDescriptions[el],
    value: el,
  }));
};
