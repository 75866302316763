export const STEP_INPUTS_TYPE = {
  TEXT: 'text',
  LONG_TEXT: 'longText',
  EMAIL: 'email',
  PHONE: 'phone',
  CHECKBOX: 'checkbox',
  ATTACHMENT: 'attachment',
  DROPDOWN: 'dropdown',
};

export const STEP_INPUTS_CONTENT_TYPE = {
  placeholder: 'placeholder',
  hiddenValue: 'hiddenValue',
  defaultValue: 'defaultValue',
};
