import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { assetsUrl } from 'global/env';
import { dynamicSort } from 'helpers/arrayManagement.js';
import TextLink from '@playerCommon/StandardElements/TextLink';
import Icon from '@playerCommon/StandardElements/Icon';
import FolderRow from '@playerCommon/CustomElements/RowList/FolderRow/FolderRow';
import ExplanationRow from '@playerCommon/CustomElements/RowList/ExplanationRow/ExplanationRow';
import i18n from 'helpers/i18n';
import { generatePath, withRouter } from 'react-router-dom';

const Canvas = styled.div`
  flex: 1;
  padding: 0 8px;

  overflow-y: auto;
`;

const Header = styled.div`
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  text-transform: uppercase;
  color: ${props => props.theme.steel};
  font-weight: 500;
  padding: 0 8px;
  margin: 0 -8px 12px -8px;
`;

const HeaderLabels = styled.span`
  display: flex;
  align-items: center;
`;

const Text = styled(TextLink)`
  user-select: none;
  margin-right: 4px;
`;

const FolderEmpty = styled.div`
  font-size: 16px;
  color: ${props => props.theme.slateGrey};
  text-align: center;
  margin-top: 32px;
  margin-bottom: 16px;
`;

const getGuideURL = (match, guideId) => {
  return generatePath(match.path, { ...match.params, id: guideId });
};

class ListView extends Component {
  state = {
    sorting: 'nameUp',
  };

  getUpdatedSorting = sortOption => {
    const { sorting } = this.state;
    let updatedSorting = `${sortOption}Up`;

    if (sorting === `${sortOption}Up`) {
      updatedSorting = `${sortOption}Down`;
    }

    return updatedSorting;
  };

  updateSorting = sorting => {
    this.setState({ sorting });
  };

  goToGuide = () => {};

  render() {
    const {
      explanations,
      folders,
      explanationsToDisplay,
      foldersToDisplay,
      setFolderToDisplay,
      currentUrl,
      type,
      match,
    } = this.props;
    const { sorting } = this.state;

    let sortDirection = 'Down';

    if (sorting.includes('name')) {
      foldersToDisplay.sort(dynamicSort('name'));
      explanationsToDisplay.sort(dynamicSort('title'));
    } else if (sorting.includes('updateDate')) {
      foldersToDisplay.sort(dynamicSort('updateDate'));
      explanationsToDisplay.sort(dynamicSort('updateDate'));
    }

    if (sorting.includes('Down')) {
      foldersToDisplay.reverse();

      explanationsToDisplay.reverse();
      sortDirection = 'Up';
    }

    return (
      <Canvas>
        {type !== 'small' && (
          <Header>
            <HeaderLabels style={{ width: '80%' }}>
              <Text onClick={() => this.updateSorting(this.getUpdatedSorting('name'))}>
                {i18n('Console.TableHeaderTitle')}
              </Text>
              {sorting.includes('name') && (
                <Icon
                  src={`${assetsUrl}resources/icons/sorting${sortDirection}.svg`}
                  onClick={() => this.updateSorting(this.getUpdatedSorting('name'))}
                />
              )}
            </HeaderLabels>

            <HeaderLabels style={{ width: '20%' }}>
              <Text onClick={() => this.updateSorting(this.getUpdatedSorting('updateDate'))}>
                {i18n('Console.TableHeaderModified')}
              </Text>
              {sorting.includes('updateDate') && (
                <Icon
                  src={`${assetsUrl}resources/icons/sorting${sortDirection}.svg`}
                  onClick={() => this.updateSorting(this.getUpdatedSorting('updateDate'))}
                />
              )}
            </HeaderLabels>
          </Header>
        )}
        {foldersToDisplay.map(folder => (
          <FolderRow
            key={folder.folderId}
            folder={folder}
            folders={folders}
            setFolderToDisplay={setFolderToDisplay}
            loadedExplanations={explanations}
            context={type === 'small' ? 'smallView' : 'view'}
          />
        ))}
        {explanationsToDisplay.map(explanation => (
          <ExplanationRow
            key={explanation.id}
            explanation={explanation}
            explanationURL={getGuideURL(match, explanation.id)}
            context={type === 'small' ? 'smallView' : 'view'}
          />
        ))}
        {foldersToDisplay.length === 0 && explanationsToDisplay.length === 0 && (
          <FolderEmpty>{i18n('AgentApp.FolderEmpty')}</FolderEmpty>
        )}
      </Canvas>
    );
  }
}

ListView.propTypes = {
  explanations: PropTypes.array,
  folders: PropTypes.array,
  explanationsToDisplay: PropTypes.array,
  foldersToDisplay: PropTypes.array,
  setFolderToDisplay: PropTypes.func,
  currentUrl: PropTypes.string,
  type: PropTypes.string,
  match: PropTypes.object,
};

export default withRouter(ListView);
