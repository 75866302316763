import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getLanguageShorthand } from 'helpers/i18n';
import { supportedLanguages } from 'helpers/i18n.helpers';
import Dropdown from '@playerCommon/CustomElements/Dropdown';
import ActionList from '@playerCommon/CustomElements/ActionList/ActionList';
import ChevronDownSVG from 'icons/downPickerDark.svg';

const DownPickerIcon = styled(ChevronDownSVG)`
  box-sizing: content-box;
  padding-left: 4px;
  path {
    fill: currentColor;
  }
`;

const DropdownTrigger = styled.div`
  cursor: pointer;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  margin-right: 24px;
  font-weight: 500;
  color: inherit;
  display: none;

  @media screen and (min-width: 900px) {
    display: flex;
  }
`;

const LanguagePicker = ({ currentLanguage = getLanguageShorthand(), onChange = () => {}, languageList = 'en' }) => {
  const dropdownRef = useRef();
  const closeDropdown = () => {
    dropdownRef.current.closeDropdown();
  };

  const languageListSplitted = languageList.split(',');
  const availableLanguages = supportedLanguages.filter(lang => languageListSplitted.includes(lang.value));

  const languageFullName = supportedLanguages.find(el => el.value === currentLanguage);

  const Trigger = (
    <DropdownTrigger data-cy="languageDropdown">
      {languageFullName.name}
      <DownPickerIcon />
    </DropdownTrigger>
  );

  if (availableLanguages.length === 1) return null;

  return (
    <Dropdown width={200} ref={dropdownRef} trigger={Trigger} offsetValue={10}>
      <ActionList
        dataCy="languagesList"
        globalAction={onChange}
        options={availableLanguages.map(el => ({
          id: el.value,
          label: el.name,
          value: el.value,
        }))}
        selectedOption={currentLanguage}
        onPostSelect={closeDropdown}
      />
    </Dropdown>
  );
};

LanguagePicker.propTypes = {
  currentLanguage: PropTypes.string,
  onChange: PropTypes.func,
  languageList: PropTypes.string,
};

export default LanguagePicker;
