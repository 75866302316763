import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { filePrompt } from 'helpers/fileHelpers';
import { onKeyPressToOnClick } from 'helpers/a11y';
import { navigationWithKeyboardStyle } from '@playerCommon/CommonStyledComponents/NavigationWithKeyboard';
import CrossSVG from 'icons/crossMedium.svg';
import AttachmentIconSVG from 'icons/attachmentIcon.svg';
import Loader from '@playerCommon/CustomElements/Loader';

const FileInputWrap = styled.div``;

const ContactFormFileInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 12px;
  height: 40px;
  border: 1px dashed ${props => props.theme.grey};
  border-radius: 4px;
  font-size: 14px;
  line-height: 24px;
  color: ${props => props.theme.lightGrey};
  background: transparent;
  cursor: pointer;
  transition: opacity 0.2s, box-shadow 0.2s;
  box-shadow: none;

  ${({ fileOver }) =>
    fileOver &&
    css`
      border-style: solid;
      border-color: ${props => props.theme.seaweed};
    `}

  ${navigationWithKeyboardStyle}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: default;
    `}

  ${({ isFileAdded }) =>
    isFileAdded &&
    css`
      opacity: 1;
      color: ${props => props.theme.darkGrey};
    `}
`;

const AttachmentIcon = styled(AttachmentIconSVG)`
  height: 16px;
  width: 16px;
  margin-right: 8px;
  flex-shrink: 0;
`;

const FileInputLabel = styled.label`
  display: flex;
  font-size: 12px;
  color: ${props => props.theme.steel};
  font-weight: 500;
  padding-left: 0;
  margin-bottom: 8px;
`;

const RequiredLabel = styled.span`
  color: ${({ theme }) => theme.pink};
  margin-left: 2px;
`;

const RemoveFileIcon = styled(CrossSVG)`
  width: 16px;
  height: 16px;
  cursor: pointer;

  path {
    fill: ${props => props.theme.slateGrey};
  }
`;

const RightIconWrapper = styled.div`
  flex-shrink: 0;
  margin-left: auto;
  margin-right: 12px;
  display: flex;
  align-items: center;
`;

function FormFileInput({ onFile, disabled, placeholder, label, required, isUploading, fileName, onRemoveFile }) {
  const [fileOver, setFileOver] = useState(false);

  const inputWrapperRef = useRef();

  const handleOnClick = () => {
    if (disabled) return;
    filePrompt({}, inputWrapperRef).then(onFile);
    // inputElement.accept =
    //   'image/*,video/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  };

  const handleFileDrop = e => {
    e.preventDefault();
    if (disabled) return;
    setFileOver(false);
    const { files } = e.dataTransfer;
    let filesValid = true;
    if (files.length > 1) filesValid = false; // TODO: remove when we support more than one file on the backend
    // for (let i = 0; i < files.length; i++) {
    //   if (
    //     !files[i].type.match(
    //       /image\/*|video\/*|application\/pdf|application\/msword,application\/vnd\.openxmlformats-officedocument\.wordprocessingml\.document/
    //     )
    //   )
    //     filesValid = false;
    // }
    if (filesValid) {
      onFile(files);
    }
  };

  const handleFileDragLeave = e => {
    e.preventDefault();
    setFileOver(false);
  };

  const handleFileDragOver = e => {
    e.preventDefault();
    setFileOver(true);
  };

  return (
    <FileInputWrap className="contact-form-attachments" ref={inputWrapperRef}>
      <FileInputLabel>
        {label}
        {required && <RequiredLabel className="label-asterisk">*</RequiredLabel>}
      </FileInputLabel>
      <ContactFormFileInput
        fileOver={fileOver}
        onDrop={handleFileDrop}
        onDragOver={handleFileDragOver}
        onDragLeave={handleFileDragLeave}
        onClick={handleOnClick}
        onKeyPress={onKeyPressToOnClick(handleOnClick)}
        tabIndex="0"
        disabled={disabled}
        isFileAdded={!!fileName}
      >
        <AttachmentIcon />
        {fileName || placeholder}

        <RightIconWrapper>
          {isUploading && <Loader type="dots" monochrome />}
          {!!fileName && onRemoveFile && !isUploading && <RemoveFileIcon onClick={onRemoveFile} />}
        </RightIconWrapper>
      </ContactFormFileInput>
    </FileInputWrap>
  );
}

FormFileInput.propTypes = {
  onFile: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  isUploading: PropTypes.bool,
  fileName: PropTypes.string,
  onRemoveFile: PropTypes.func,
};

export default FormFileInput;
