/* eslint-disable max-classes-per-file */
/* eslint-disable no-restricted-syntax */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { getTempStorage, setTempStorage, deleteTempStorage } from 'helpers/storage';
import { trackSearchImmediate } from 'components/Analytics/recorder';
import { getGlobal, setGlobal } from 'global/windowUtils';
import i18n from 'helpers/i18n';
import { fileUrl } from '@stonlyCommons/global/env';
import { splitAnswerIntoParts } from './AiAnswer.helpers';

const wait = time => new Promise(resolve => setTimeout(resolve, time));

const checkIsTextOneWord = text => {
  if (!text) return false;
  const textToTest = text.replaceAll(/\s+/gim, ' ');
  return textToTest.split(/\s/gim).length === 1;
};

const getAbsoluteUrl = (url, linkType) => {
  if (linkType === 'pdf') return `${fileUrl}/${url}`;
  if (url.startsWith('/')) return `${window.location.protocol}//${window.location.host}${url}`;
  return url;
};

const getProcessedLinks = links => {
  return links.map(link => ({
    ...link,
    url: getAbsoluteUrl(link.url, link.linkType),
  }));
};

const replacesBracketsWithLinks = (answer, links) => {
  return answer.replaceAll(/\[{(\d+)}]/g, (match, p1) => {
    const link = links[p1 - 1];
    return link ? `<a href="${link.url}">${link.title}</a>` : match;
  });
};

const replaceNumbersInHrefsWithLinks = (answer, links) => {
  return answer.replaceAll(/<a href=["'](\d+)["']>(.*?)<\/a>/g, (match, p1, p2) => {
    const link = links[p1 - 1];
    const isTitleOneWord = checkIsTextOneWord(p2);
    return link ? `<a href="${link.url}">${(isTitleOneWord ? p2 : link.title) || p2}</a>` : match;
  });
};

const getProcessedTextWithFixedLinks = (answer, links) => {
  if (!answer) return '';
  const answerWithLinks = replacesBracketsWithLinks(answer, links);
  return replaceNumbersInHrefsWithLinks(answerWithLinks, links);
};

const resolveStatLinks = (linkIndexList, links) => {
  return linkIndexList
    .map(linkIndex => {
      const link = links[linkIndex - 1];
      if (link) {
        return {
          linkType: link.linkType,
          stepId: link.stepId,
          guideId: link.guideId,
          contentId: link.contentId,
          sourceId: link.sourceId,
          sectionId: link.sectionId,
          page: link.page,
          url: link.url,
        };
      }
      return null;
    })
    .filter(Boolean);
};

function cacheSearchResults(stepId, answersList) {
  setTempStorage(`aiStepSearchResult_${stepId}`, answersList);
}

function retrieveSearchResultsCache(stepId) {
  return getTempStorage(`aiStepSearchResult_${stepId}`);
}

function deleteSearchResultsCache(stepId) {
  deleteTempStorage(`aiStepSearchResult_${stepId}`);
}

function cacheSessionId(stepId, sessionId) {
  setTempStorage(`aiStepSessionId_${stepId}`, sessionId);
}

function retrieveSessionIdCache(stepId) {
  return getTempStorage(`aiStepSessionId_${stepId}`);
}

export const FEEDBACK_TYPE = {
  POSITIVE: 'POSITIVE',
  NEGATIVE: 'NEGATIVE',
};

export const FEEDBACK_VALUE_MAP = {
  [FEEDBACK_TYPE.POSITIVE]: true,
  [FEEDBACK_TYPE.NEGATIVE]: false,
};

const SIGNALS = {
  AI_SEARCH_HEADER_START: '[AI_SEARCH_HEADER_START]',
  AI_SEARCH_HEADER_END: '[AI_SEARCH_HEADER_END]',
  AI_ANSWER_HEADER_START: '[AI_ANSWER_HEADER_START]',
  AI_ANSWER_HEADER_END: '[AI_ANSWER_HEADER_END]',
  METADATA_START: '[METADATA_START]',
  METADATA_END: '[METADATA_END]',
  END_OF_ANSWER: '[EOL]',
  END_OF_TRANSMISSION: '[EOT]',
};

class SmartSearchRunner {
  constructor({
    searchPhrase,
    language,
    onProgress,
    onDone,
    onError,
    onRelatedStep,
    onSetConversationId,
    onRemainingFollowupQuestionsChange,
    onDeclarativeAnswerReturned,
    conversationId,
    guideId,
    stepId,
    teamId,
    knowledgeBaseId,
    errorMessage,
    // type, // temporarily disabled, until we have a searchresults component
  }) {
    this.searchPhrase = searchPhrase;
    this.language = language;
    this.onProgress = onProgress;
    this.onSetConversationId = onSetConversationId;
    this.onDone = onDone;
    this.onError = onError;
    this.onRelatedStep = onRelatedStep;
    this.onRemainingFollowupQuestionsChange = onRemainingFollowupQuestionsChange;
    this.onDeclarativeAnswerReturned = onDeclarativeAnswerReturned;
    this.conversationId = conversationId;
    this.guideId = guideId;
    this.stepId = stepId;
    this.type = 'semantic';
    this.teamId = teamId;
    this.knowledgeBaseId = knowledgeBaseId;
    this.isFollowUp = !!conversationId;
    this.errorMessage = errorMessage;
  }

  eventSource;

  eolFired = false;

  aiSearchHeaderCaptureMode = false;

  aiAnswerHeaderCaptureMode = false;

  isFallback = false;

  answerComputed = '';

  rephrasedQuestion = '';

  questionAnswerId = null;

  links = [];

  linksForReplacement = [];

  isAnswerEmpty = false;

  previousPMatchCount = 0;

  onSmartSearchEventSourceEvent = message => {
    try {
      const text = message.data;

      if (text === SIGNALS.AI_SEARCH_HEADER_START) {
        this.aiSearchHeaderCaptureMode = true;
        return;
      }

      if (text === SIGNALS.AI_SEARCH_HEADER_END) {
        this.aiSearchHeaderCaptureMode = false;
        return;
      }

      if (this.aiSearchHeaderCaptureMode) {
        const aiSearchData = JSON.parse(text);

        this.conversationId = aiSearchData?.conversationId;
        this.onSetConversationId(this.conversationId);
        this.onRemainingFollowupQuestionsChange(aiSearchData?.remainingFollowupQuestions);
        this.questionAnswerId = aiSearchData?.questionAnswerId;
        return;
      }

      if (text === SIGNALS.AI_ANSWER_HEADER_START) {
        this.aiAnswerHeaderCaptureMode = true;
        return;
      }

      if (text === SIGNALS.AI_ANSWER_HEADER_END) {
        this.aiAnswerHeaderCaptureMode = false;
        return;
      }

      if (this.aiAnswerHeaderCaptureMode) {
        const aiAnswerData = JSON.parse(text);
        this.linksForReplacement = getProcessedLinks(aiAnswerData.linksForReplacement);
        return;
      }

      if (text === SIGNALS.METADATA_START) {
        this.metadataCaptureMode = true;
        return;
      }

      if (text === SIGNALS.METADATA_END) {
        this.metadataCaptureMode = false;
        return;
      }

      if (this.metadataCaptureMode) {
        const metadata = JSON.parse(text);
        this.isFallback = metadata.isFallback;
        this.rephrasedQuestion = metadata.phrase;
        // declarative answer handling
        if (metadata.intentRecognised) {
          const isKb = window.location.pathname.includes('/kb/');
          const basename = getGlobal('basename');
          const guideUrl = `${window.location.origin}/${basename ? `${basename}/` : ''}${isKb ? 'kb/' : ''}guide/${
            this.language
          }/${metadata.guideId}/Steps/${metadata.stepId}`;

          const computedAHref = `<a href="${guideUrl}">${metadata.linkText}</a>`;
          const computedAnswer = metadata.answerText?.replace('{{link}}', computedAHref);

          this.answerComputed =
            computedAnswer ||
            i18n(
              'AiStep.WeHaveAGuide',
              {
                guideUrl,
              },
              this.language,
              { raw: true }
            );

          this.onDeclarativeAnswerReturned({
            guideId: metadata.guideId,
            stepId: metadata.stepId,
            question: this.searchPhrase,
            answer: this.answerComputed,
          });
          trackSearchImmediate({
            actionType: 'guidedAnswerRedirect',
            actionDetail: {
              conversationId: this.conversationId,
              questionAnswerId: this.questionAnswerId,
              detectedIntent: metadata.detectedIntent,
              phrase: metadata.phrase,
              originalQuestion: metadata.originalQuestion,
              guideId: metadata.guideId,
              stepId: metadata.stepId,
            },
          });
          this.eventSource.close();
        }
        this.isAnswerEmpty = this.answerComputed.trim().length === 0;

        if (metadata.links) {
          this.links = resolveStatLinks(metadata.links, this.linksForReplacement);
        }

        const processedAnswer = getProcessedTextWithFixedLinks(this.answerComputed, this.linksForReplacement);

        trackSearchImmediate({
          actionType: 'aiAnswerSearchCompleted',
          actionDetail: {
            conversationId: this.conversationId,
            language: this.language,
            phrase: metadata.phrase,
            originalQuestion: metadata.originalQuestion,
            answer: this.isAnswerEmpty ? this.errorMessage : processedAnswer,
            confidenceScore: metadata.confidenceScore === '0' ? 0 : +metadata.confidenceScore || undefined,
            linksCount: metadata.linksCount,
            isFollowUp: this.isFollowUp,
            questionAnswerId: this.questionAnswerId,
            links: this.links,
          },
        });
        if (metadata.intentRecognised) {
          this.destroy();
        }
        return;
      }

      if (text === SIGNALS.END_OF_ANSWER) {
        this.eolFired = true;
        return;
      }

      if (text === SIGNALS.END_OF_TRANSMISSION) {
        this.eventSource.close();

        const computedAnswerWithReplacedLinks = getProcessedTextWithFixedLinks(
          this.answerComputed,
          this.linksForReplacement
        );

        this.onDone({
          question: this.searchPhrase,
          answer: this.isAnswerEmpty ? this.errorMessage : computedAnswerWithReplacedLinks,
          isFallback: this.isFallback || this.isAnswerEmpty,
          answerVote: undefined,
          links: this.links,
          questionAnswerId: this.questionAnswerId,
          rephrasedQuestion: this.rephrasedQuestion,
        });

        this.destroy();
        return;
      }

      if (this.eolFired) {
        if (this.onRelatedStep) this.onRelatedStep(JSON.parse(text));
        // this is temporarily left in case we will want to use related steps again
        // this.relatedSteps.push(JSON.parse(text));
        // setSmartSearchCurrentQuestion(undefined);
        return;
      }

      this.answerComputed += text;
      const splitAnswer = splitAnswerIntoParts(this.answerComputed);
      const pMatchCount = splitAnswer.length - 1;
      if (pMatchCount > this.previousPMatchCount) {
        this.previousPMatchCount = pMatchCount;
        const processedText = getProcessedTextWithFixedLinks(splitAnswer[pMatchCount - 1], this.linksForReplacement);
        this.onProgress(processedText);
      }
    } catch {
      //
    }
  };

  onSmartSearchEventError = e => {
    console.log('error', e);
    trackSearchImmediate({
      actionType: 'aiAnswerSearchError',
      actionDetail: {
        language: this.language,
        originalQuestion: this.searchPhrase,
      },
    });
    this.onError(this.questionAnswerId);
    this.destroy();
  };

  run() {
    const params = {
      search: this.searchPhrase,
      language: this.language,
      smartSearch: true,
      guideId: this.guideId,
      stepId: this.stepId,
      type: this.type,
      conversationId: this.conversationId ? this.conversationId : undefined,
      teamId: this.teamId,
      knowledgeBaseId: this.knowledgeBaseId,
    };

    const paramsString = Object.entries(params)
      .filter(([, value]) => !!value)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    const basename = getGlobal('basename');
    this.eventSource = new EventSource(`${basename}/api/v1/step/search?${paramsString}`);

    this.eventSource.addEventListener('message', this.onSmartSearchEventSourceEvent);
    this.eventSource.addEventListener('error', this.onSmartSearchEventError);
  }

  destroy() {
    this.eventSource.removeEventListener('message', this.onSmartSearchEventSourceEvent);
    this.eventSource.removeEventListener('error', this.onSmartSearchEventError);
    this.eventSource.close();
    this.searchPhrase = undefined;
    this.language = undefined;
    this.onProgress = undefined;
    this.onDone = undefined;
    this.onError = undefined;
    this.onRelatedStep = undefined;
    this.onRemainingFollowupQuestionsChange = undefined;
    this.onQuestionAnswerIdChange = undefined;
    this.onDeclarativeAnswerReturned = undefined;
    this.conversationId = undefined;
    this.guideId = undefined;
    this.stepId = undefined;
    this.knowledgeBaseId = undefined;
    this.type = undefined;
    this.isFallback = undefined;
    this.isFollowUp = undefined;
    this.errorMessage = undefined;
    this.isAnswerEmpty = undefined;
    this.linksForReplacement = undefined;
  }
}

export function useSmartSearchResults({
  currentLanguage,
  stepModuleId,
  sessionId,
  errorMessage,
  guideId,
  stepId,
  scrollToBottomFunction,
  onGoToAiSuggestedGuide,
  teamId,
  knowledgeBaseId,
}) {
  const [conversationId, setConversationId] = useState(sessionId);
  const [aiResponsesList, setAiResponsesList] = useState([]);
  const [pendingAnswersList, setPendingAnswersList] = useState([]);
  const [smartSearchCurrentQuestion, setSmartSearchCurrentQuestion] = useState('');

  useEffect(() => {
    if (aiResponsesList.length) {
      setGlobal('lastAiAnswer', aiResponsesList[aiResponsesList.length - 1].answer);
    }
    return () => setGlobal('lastAiAnswer', undefined);
  }, [aiResponsesList.length]);

  const delayedScrollToBottom = useCallback(() => {
    setTimeout(() => {
      scrollToBottomFunction({ behavior: 'smooth' });
    }, 0);
  }, [scrollToBottomFunction]);

  const onSetConversationId = useCallback(sessionId => {
    setConversationId(sessionId);
    cacheSessionId(stepId, sessionId);
  }, []);

  const [smartSearchAnswerRelatedSteps, setSmartSearchAnswerRelatedSteps] = useState([]);
  const [smartSearchAnswerDone, setSmartSearchAnswerDone] = useState(true);
  const [remainingFollowupQuestions, setRemainingFollowupQuestions] = useState(Number.POSITIVE_INFINITY);

  const smartSearchRunnerRef = useRef();

  const onFeedbackClick = useCallback(
    (feedbackValue, { id, answer, question, questionAnswerId, rephrasedQuestion }) => {
      setAiResponsesList(responses => {
        const newResponses = JSON.parse(JSON.stringify(responses));
        newResponses.find(response => response.id === id).answerVote = feedbackValue;
        cacheSearchResults(stepModuleId, newResponses);
        return newResponses;
      });
      trackSearchImmediate({
        actionType: 'searchVote',
        actionDetail: {
          conversationId,
          term: question,
          phrase: rephrasedQuestion,
          answer,
          vote: FEEDBACK_VALUE_MAP[feedbackValue],
          questionAnswerId,
        },
      });
    },
    [conversationId, stepModuleId]
  );

  const onLinkClick = useCallback(
    metadata => {
      trackSearchImmediate({
        actionType: 'aiAnswerLinkClicked',
        actionDetail: {
          conversationId,
          ...metadata,
        },
      });
    },
    [conversationId]
  );

  const launchSmartAnswer = searchPhrase => {
    setSmartSearchAnswerDone(false);
    smartSearchRunnerRef.current = new SmartSearchRunner({
      searchPhrase,
      language: currentLanguage,
      conversationId,
      guideId,
      stepId,
      teamId,
      knowledgeBaseId,
      errorMessage,
      onSetConversationId,
      onRemainingFollowupQuestionsChange: setRemainingFollowupQuestions,
      onDeclarativeAnswerReturned: async ({ guideId, stepId, question, answer }) => {
        setPendingAnswersList([answer]);
        await wait(1500);
        setSmartSearchAnswerDone(true);
        setAiResponsesList(list => {
          const currentResponseList = [
            ...list,
            { id: list.length, question, answer, answerVote: undefined, isFallback: false },
          ];
          cacheSearchResults(stepModuleId, currentResponseList);
          return currentResponseList;
        });
        setSmartSearchAnswerDone(true);
        onGoToAiSuggestedGuide({ guideToOpen: guideId, startingStepId: stepId });
      },
      onDone: ({ question, answer, answerVote, isFallback, links, questionAnswerId, rephrasedQuestion }) => {
        setSmartSearchAnswerDone(true);
        setAiResponsesList(list => {
          const currentResponseList = [...list];
          currentResponseList.push({
            id: list.length,
            question,
            answer,
            answerVote,
            isFallback,
            links,
            questionAnswerId,
            rephrasedQuestion,
          });
          cacheSearchResults(stepModuleId, currentResponseList);
          setPendingAnswersList([]);
          return currentResponseList;
        });
      },
      onProgress: currentText => {
        setPendingAnswersList(currentList => [...currentList, currentText]);
      },
      onError: questionAnswerId => {
        setSmartSearchAnswerDone(true);

        setAiResponsesList(list => {
          const currentResponseList = [
            ...list,
            {
              id: list.length,
              question: searchPhrase,
              answer: errorMessage,
              answerVote: undefined,
              isFallback: true,
              questionAnswerId,
            },
          ];
          cacheSearchResults(stepModuleId, currentResponseList);
          setPendingAnswersList([]);
          return currentResponseList;
        });
      },
    });

    smartSearchRunnerRef.current.run();

    setSmartSearchCurrentQuestion(searchPhrase);
  };

  useEffect(() => {
    // check if we already loaded an answer during this page load, and if so, we use it and skip the backend call
    const savedSmartAnswers = retrieveSearchResultsCache(stepModuleId);
    setConversationId(retrieveSessionIdCache(stepId));
    if (savedSmartAnswers) {
      setAiResponsesList(savedSmartAnswers);
      setSmartSearchAnswerDone(true);
      return;
    }

    return () => {
      smartSearchRunnerRef.current?.destroy();
    };
  }, []);

  const onResetSession = () => {
    if (conversationId && aiResponsesList.length) {
      const { question, answer, questionAnswerId, rephrasedQuestion } = aiResponsesList[aiResponsesList.length - 1];
      trackSearchImmediate({
        actionType: 'aiConversationRestarted',
        actionDetail: {
          conversationId,
          questionAnswerId,
          originalQuestion: question,
          phrase: rephrasedQuestion,
          answer,
        },
      });
    }

    onSetConversationId(undefined);
    setPendingAnswersList([]);
    setAiResponsesList([]);
    deleteSearchResultsCache(stepModuleId);
  };

  return {
    smartSearchPendingAnswersList: pendingAnswersList,
    smartSearchAnswerDone,
    smartSearchAnswerRelatedSteps,
    smartSearchAnswers: aiResponsesList,
    smartSearchCurrentQuestion,
    onFeedbackClick,
    onMakeAdditionalSmartSearchCall: launchSmartAnswer,
    followupLimitReached: remainingFollowupQuestions < 1,
    onResetSession: conversationId ? onResetSession : undefined,
    onLinkClick,
  };
}
