import React, { Component } from 'react';
import styled, { createGlobalStyle, css, withTheme, ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import i18n, { getLanguageShorthand } from 'helpers/i18n';
import { setLanguage } from 'helpers/i18n.helpers';
import { createPathFromParam, loadExplanation, getNewLanguageUrl } from 'helpers/explanationManagement.js';
import { validateHexaDecimal } from 'helpers/validationHelpers.js';
import { RIGHT, STEP_NEXT_VISIBILITY } from 'global';
import withUser from '@playerCommon/HOC/withUser/withUser';
import NoViewAccess from '@playerCommon/Commons/NoViewAccess';
import Loader, { FullscreenLoaderWrap } from '@playerCommon/CustomElements/Loader/Loader';
import ExplanationPlayer from '@playerCommon/ComplexElements/ExplanationPlayer';
import withContextProviders from 'HOC/withContextProviders';
import withExplanationData from 'HOC/withExplanationData';
import { getStepsGlobalScrollbarStyles } from '@playerCommon/CommonStyledComponents/CustomScrollbar.jsx';
import { parseGuideData } from 'helpers/guidePlayerHelpers.js';
import { parseBoolean } from '@playerCommon/helpers/booleanHelpers.js';
import { SkipToMainContentLink } from '@playerCommon/CommonStyledComponents/SkipToMainContentLink.jsx';
import ExplanationTop from './ExplanationTop/ExplanationTop.js';
// import FlowchartPlayer from './FlowchartPlayer/FlowchartPlayer.js';

const Canvas = styled.div`
  /* ${({ isFlowchart }) =>
    isFlowchart &&
    css`
      margin-bottom: -64px;
    `} */

  @media screen and (max-width: 899px) {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

const GlobalScrollStyles = createGlobalStyle`
  ${getStepsGlobalScrollbarStyles({ marginTop: '88px', marginBottom: '68px' })}

  @media screen and (max-width: 620px) {
    ${getStepsGlobalScrollbarStyles({ marginTop: '60px', marginBottom: '68px' })}
  }

  @media screen and (max-width: 480px) {
    ${getStepsGlobalScrollbarStyles({ marginTop: '60px', marginBottom: '60px' })}
  }
`;

const ALLOWED_RIGHTS = new Set([RIGHT.FULL_RIGHTS, RIGHT.VIEW, RIGHT.VIEW_EDIT, RIGHT.VIEW_EDIT_PUBLISH]);

const getProcessedState = (match, history, loadedGuide, statusCode) => {
  if (statusCode === 403) {
    return { explanationLoaded: true, accessToExplanation: false, guide: {} };
  }
  if (!loadedGuide) return null;

  const accessToExplanation = ALLOWED_RIGHTS.has(loadedGuide.guideInfo.access);
  if (!accessToExplanation) {
    return { explanationLoaded: true, accessToExplanation: false, guide: {} };
  }

  /*
   * Once the guide (including the sub explanations) is loaded
   * The path is taken from the URL to load the right step. Depending on
   * whether the current step belongs to the main guide or a sub one,
   * or if this is the last step or the introduction, different parameters
   * are loaded
   */

  // const currentStepId = stepsPath[stepsPath.length - 1];
  // const explanationSteps = loadedGuide.steps.map(el => el.stepId);
  // const firstStep = loadedGuide.guideInfo.startId;

  // if (![0, -1, -2, ...explanationSteps].includes(currentStepId)) {
  //   if (loadedGuide.guideInfo && loadedGuide.guideInfo.hasIntroduction) {
  //     history.replace(
  //       `/${match.params.mode}/${match.params[0] ? match.params[0] : ''}${match.params.id}/${
  //         match.params.view
  //       }/introduction`
  //     );
  //   } else if (currentStepId === -4) {
  //     const stepsPathUpdated = [...stepsPath]
  //       .reverse()
  //       .slice(1)
  //       .reverse();
  //     history.replace(
  //       `/${match.params.mode}/${match.params[0] ? match.params[0] : ''}${match.params.id}/${
  //         match.params.view
  //       }/${stepsPathUpdated.join(STEP_SEPARATOR)}`
  //     );
  //   } else {
  //     history.replace(
  //       `/${match.params.mode}/${match.params[0] ? match.params[0] : ''}${match.params.id}/${
  //         match.params.view
  //       }/${firstStep}`
  //     );
  //   }
  // }

  return {
    guide: loadedGuide,
    explanationLoaded: true,
    accessToExplanation,
  };
};

class Explanation extends Component {
  constructor(props) {
    super(props);

    const ssrInitialState = getProcessedState(props.match, props.history, props.loadedGuide, props.statusCode);
    const stepsPath = createPathFromParam(props.match.params.pathToLoad);
    this.state = ssrInitialState
      ? { ...ssrInitialState, stepsPath }
      : {
          guide: {},
          explanationLoaded: false,
          accessToExplanation: true,
          stepsPath,
        };
  }

  componentDidMount() {
    const { explanationLoaded } = this.state;
    if (!explanationLoaded) this.initiateExplanation();
  }

  componentDidUpdate(prevProps) {
    const { userManagement, match } = this.props;
    const { match: oldMatch } = prevProps;

    if (userManagement.user.id && !prevProps.userManagement.user.id) {
      this.initiateExplanation();
    }

    if (match.params.pathToLoad !== oldMatch.params.pathToLoad) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ stepsPath: createPathFromParam(match.params.pathToLoad) });
    }
  }

  setLanguage = async language => {
    const { forceUpdateAtRoot, match, history } = this.props;
    setLanguage(language);
    forceUpdateAtRoot();
    await this.initiateExplanation();
    const { guide } = this.state;
    history.replace(getNewLanguageUrl(language, match, guide.guideInfo.title));
  };

  initiateExplanation = async () => {
    const { match, history, location } = this.props;

    const { currentExplanationId } = this.state;

    if (currentExplanationId) {
      this.setState({
        currentExplanationId: null,
      });
    }

    try {
      const language = getLanguageShorthand();
      const { id, view } = match.params;
      const { widgetId, segmentAnonymousId, segmentUserId, stonlyAnonymousId, customerUserId } = queryString.parse(
        location.search
      );
      const loadedGuide = await loadExplanation(id, {
        isPreview: view === 'PreviewSteps',
        isShortlink: location.pathname.slice(1, 3) === 'sl',
        widgetId,
        segmentAnonymousId,
        segmentUserId,
        stonlyAnonymousId,
        customerUserId,
      });
      const newState = getProcessedState(match, history, parseGuideData(loadedGuide, language));

      if (!newState) return;

      this.setState(newState);
    } catch {
      this.setState({ explanationLoaded: true, accessToExplanation: false });
    }
  };

  render() {
    const { match, theme } = this.props;
    const { guide, explanationLoaded, accessToExplanation, stepsPath } = this.state;

    if (!explanationLoaded) {
      return (
        <FullscreenLoaderWrap>
          <Loader text={i18n('Global.Loading')} />
        </FullscreenLoaderWrap>
      );
    }

    if (!accessToExplanation) {
      return <NoViewAccess />;
    }
    const currentStepId = stepsPath[stepsPath.length - 1];

    const { title } = guide.guideInfo;
    const hasConditionalConnection = guide.allGuides
      .flatMap(({ stepNext }) => stepNext)
      .some(({ visibility }) => visibility === STEP_NEXT_VISIBILITY.externalCondition);

    let displayProgressBar = accessToExplanation ? parseBoolean(guide.guideOptions.displayProgressBar, true) : true;
    displayProgressBar = displayProgressBar && !hasConditionalConnection;

    const { view, id } = match.params;

    let firstStep = null;
    const firstStepObj = guide.allGuides.find(info => info.guideId === id);
    if (firstStepObj) firstStep = firstStepObj.startId;

    let overridingTheme;
    let teamIcon;
    const { buttonColor, logo, logoSecureHash } = guide.guideOptions;
    if (buttonColor && validateHexaDecimal(buttonColor)) {
      overridingTheme = { ...theme, highlightColor: buttonColor };
    }
    if (logo) {
      teamIcon = logo;
    }

    return (
      <ThemeProvider theme={overridingTheme || theme}>
        <GlobalScrollStyles />
        <Canvas isFlowchart={false /* view.includes('Flowchart') */} className="regular-guide">
          <SkipToMainContentLink />
          <ExplanationTop
            title={title}
            currentStepId={currentStepId}
            stepsPath={stepsPath}
            displayProgressBar={displayProgressBar}
            teamIcon={teamIcon}
            teamIconSecureHash={logoSecureHash}
            guide={guide}
          />
          {view.includes('Steps') && (
            <ExplanationPlayer
              guide={guide}
              firstStep={firstStep}
              stepsPath={stepsPath}
              displayProgressBar={displayProgressBar}
              setLanguage={this.setLanguage}
            />
          )}
          {/* {view.includes('Flowchart') && (
            <FlowchartPlayer
              guide={guide}
              currentExplanationId={currentExplanationId}
              firstStep={firstStep}
              stepsPath={stepsPath}
              location={location}
            />
          )} */}
        </Canvas>
      </ThemeProvider>
    );
  }
}

Explanation.propTypes = {
  match: PropTypes.object,
  userManagement: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  statusCode: PropTypes.number,
  theme: PropTypes.object,
  loadedGuide: PropTypes.object,
  forceUpdateAtRoot: PropTypes.func,
};

export default withExplanationData(withContextProviders(withUser(withTheme(Explanation))));
