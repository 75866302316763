import { SEARCH_RESULT_TYPES } from 'components/consts';
import { imagesUrl, baseDomain } from 'global/env';
import { getLanguageShorthand } from './i18n';

export const getFolderIdFromPath = folder => (typeof folder === 'string' ? folder.split('-').pop() : folder);

export const parseFoldersPath = (folder, folders, onlyIds = true) => {
  const folderId = getFolderIdFromPath(folder);
  let safetyBreak = 0;
  const lastFolder = folders.find(el => +el.folderId === +folderId);
  if (!lastFolder) return [];

  const currentFoldersPath = [lastFolder];

  while (currentFoldersPath[currentFoldersPath.length - 1].parentId && safetyBreak < 10) {
    const { parentId } = currentFoldersPath[currentFoldersPath.length - 1];
    currentFoldersPath.push(folders.find(el => +el.folderId === +parentId));
    safetyBreak += 1;
  }
  currentFoldersPath.reverse();

  if (onlyIds) {
    return currentFoldersPath.map(el => el.folderId);
  }
  return currentFoldersPath;
};

export const getPageUrlFromKb = knowledgeBase => {
  return `https://${knowledgeBase.knowledgeBaseOrigin || `${knowledgeBase.knowledgeBaseDomain}.${baseDomain}`}`;
};

export const getCustomIconURL = (customIcon, noRoot = false, customIconSecureHash, ignoreSecureHash) => {
  if (!customIcon) return null;
  if (typeof customIcon === 'object') {
    return `${noRoot ? '' : `${imagesUrl.url}/`}${customIcon.path}/${customIcon.name}.${customIcon.ext}${
      customIcon.nameSecureHash && !ignoreSecureHash ? `?s=${customIcon.nameSecureHash}` : ''
    }`;
  }
  return `${noRoot ? '' : `${imagesUrl.url}/`}${customIcon}?s=${customIconSecureHash}`;
};

export const parseCustomIconURL = customIconURL => {
  if (!customIconURL) return null;
  if (typeof customIconURL === 'object') return customIconURL;
  const URLRegex = /(.*)\/([\da-f]{8}(?:-[\da-f]{4}){3}-[\da-f]{12}).(.{3})/gim;
  const results = URLRegex.exec(customIconURL);
  if (results && results.length) {
    const [, path, name, ext] = results;
    return { path, name, ext };
  }
  return null;
};

export function getFolderCaption(folder, language) {
  if (!folder || !folder.caption) return {};
  return (
    folder.caption[language] || folder.caption[getLanguageShorthand()] || folder.caption[folder.defaultLanguage] || {}
  );
}

export function getGuideTitle(guide, language) {
  if (!guide) return '';
  const { defaultLanguage, titles, title } = guide;
  const titleLanguages = titles ? Object.keys(titles) : [];
  return (
    titles[language]?.title ||
    titles[getLanguageShorthand()]?.title ||
    titles[defaultLanguage]?.title ||
    titles[titleLanguages[0]]?.title ||
    title ||
    ''
  );
}

export function getGuideDescription(guide, language) {
  if (!guide) return '';
  const { defaultLanguage, descriptions } = guide;
  const descriptionLanguages = descriptions ? Object.keys(descriptions) : [];

  return (
    descriptions?.[language]?.description ||
    descriptions?.[getLanguageShorthand()]?.description ||
    descriptions?.[defaultLanguage]?.description ||
    descriptions?.[descriptionLanguages[0]]?.description ||
    ''
  );
}

export const enrichSearchResultsWithBreadcrumbs = ({
  results,
  folders,
  guides,
  knowledgeBaseName,
  currentLanguage,
}) => {
  const resultsWithBreadcrumbs =
    results.length > 0
      ? results.map(result => {
          const breadcrumbs = [];
          if (result.folderId) {
            const bc = parseFoldersPath(result.folderId, folders, false);
            breadcrumbs.push(
              ...bc.map(el => ({
                folderId: el.folderId,
                name: getFolderCaption(el, currentLanguage).name,
                type: SEARCH_RESULT_TYPES.FOLDER,
              }))
            );
          }
          breadcrumbs.unshift({
            folderId: null,
            type: SEARCH_RESULT_TYPES.FOLDER,
            name: knowledgeBaseName,
          });
          if (result.type === SEARCH_RESULT_TYPES.STEP) {
            const foundStep = guides.find(el => el.guideId === result.guideId);
            if (foundStep) {
              const { title } = foundStep;
              breadcrumbs.push({ guideId: result.guideId, name: title, type: SEARCH_RESULT_TYPES.GUIDE });
            }
          }
          return {
            ...result,
            breadcrumbs,
          };
        })
      : [];

  const folderResults = resultsWithBreadcrumbs.filter(el => el.type === SEARCH_RESULT_TYPES.FOLDER);
  const guideResults = resultsWithBreadcrumbs.filter(el => el.type === SEARCH_RESULT_TYPES.GUIDE);
  const stepResults = resultsWithBreadcrumbs.filter(el => el.type === SEARCH_RESULT_TYPES.STEP);

  return [...folderResults, ...guideResults, ...stepResults];
};
