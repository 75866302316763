/* eslint-disable xss/no-mixed-html */
import React, { useCallback, useEffect, useRef } from 'react';
import { tabbable } from 'tabbable';
import type { FocusableElement } from 'tabbable';

export interface ArrowsNavigationAreaProps {
  children: React.ReactNode;
  autoFocus?: boolean;
  disabled?: boolean;
  restoreFocusOnUnmount?: boolean;
}

/**
 * Container in which focus change is done by arrow keys pressing. Not a tab.
 */
export const ArrowsNavigationArea = ({
  children,
  autoFocus = true,
  disabled,
  restoreFocusOnUnmount,
}: ArrowsNavigationAreaProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const initialFocusDoneFlagRef = useRef(false);
  const lastActiveElement = useRef(document.activeElement).current;

  const getActiveElementIfInside = useCallback(() => {
    const $activeNode = document.activeElement;

    return $activeNode && containerRef.current?.contains($activeNode) ? $activeNode : undefined;
  }, []);

  const getFocusableChildrenList = useCallback((element?: HTMLDivElement | null) => {
    if (element) {
      return tabbable(element, {
        includeContainer: false,
        displayCheck: 'full',
        getShadowRoot: false,
      });
    }
    return [];
  }, []);

  const getNextFocusableSibling = useCallback(
    ($element?: Element) => {
      const focusableChildrenList = getFocusableChildrenList(containerRef.current);
      if (focusableChildrenList.length && $element) {
        const elementIndex = focusableChildrenList.indexOf($element as FocusableElement);
        return elementIndex === -1 ? undefined : focusableChildrenList[elementIndex + 1];
      }
      return undefined;
    },
    [getFocusableChildrenList]
  );

  const getPreviousFocusableSibling = useCallback(
    ($element?: Element) => {
      const focusableChildrenList = getFocusableChildrenList(containerRef.current);
      if (focusableChildrenList.length && $element) {
        const elementIndex = focusableChildrenList.indexOf($element as FocusableElement);
        return elementIndex === -1 ? undefined : focusableChildrenList[elementIndex - 1];
      }
      return undefined;
    },
    [getFocusableChildrenList]
  );

  const getLastFocusableElement = useCallback(() => {
    const focusableChildrenList = getFocusableChildrenList(containerRef.current);
    return focusableChildrenList[focusableChildrenList.length - 1];
  }, [getFocusableChildrenList]);

  const getFirstFocusableElement = useCallback(() => {
    return getFocusableChildrenList(containerRef.current)[0];
  }, [getFocusableChildrenList]);

  const handleContainerKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (!disabled) {
        if (e.key === 'ArrowDown') {
          const $activeElement = getActiveElementIfInside();
          const $focusToElement = $activeElement ? getNextFocusableSibling($activeElement) : getFirstFocusableElement();
          ($focusToElement as HTMLElement | undefined)?.focus?.();
          e.preventDefault();
        } else if (e.key === 'ArrowUp') {
          const $activeElement = getActiveElementIfInside();
          const $focusToElement = $activeElement
            ? getPreviousFocusableSibling($activeElement)
            : getLastFocusableElement();
          ($focusToElement as HTMLElement | undefined)?.focus?.();
          e.preventDefault();
        } else if (e.key === 'Tab') {
          e.preventDefault();
        }
      }
    },
    [
      getPreviousFocusableSibling,
      getNextFocusableSibling,
      getActiveElementIfInside,
      getFirstFocusableElement,
      getLastFocusableElement,
      disabled,
    ]
  );

  /** autofocus */
  useEffect(() => {
    if (autoFocus && !initialFocusDoneFlagRef.current) {
      getFirstFocusableElement()?.focus();
      initialFocusDoneFlagRef.current = true;
    }
  }, [autoFocus, getFirstFocusableElement]);

  /** restore focus on unmounting */
  useEffect(() => {
    return () => {
      if (restoreFocusOnUnmount && lastActiveElement) {
        (lastActiveElement as HTMLElement | undefined)?.focus?.();
      }
    };
  }, [restoreFocusOnUnmount, lastActiveElement]);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div onKeyDown={handleContainerKeyDown} ref={containerRef}>
      {children}
    </div>
  );
};
