import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import useTooltip from '@playerCommon/hooks/useTooltip';
import { useMergeRefs } from '@playerCommon/hooks/useMergeRefs';
import BaseButton, { baseButtonPropTypes } from './BaseButton';

const oldPlacementMap = {
  up: 'top',
  down: 'bottom',
  upleft: 'top-start',
  upright: 'top-end',
  downleft: 'bottom-start',
  downright: 'bottom-end',
  left: 'left',
  right: 'right',
};

const ButtonWithTooltip = React.forwardRef(
  (
    {
      children,
      tooltip,
      tooltipPosition = 'top',
      tooltipBackground,
      tooltipForceVisible,
      onMouseOver,
      onMouseOut,
      ...restProps
    },
    ref
  ) => {
    const placementConverted = oldPlacementMap[(tooltipPosition || '').toLowerCase()] || tooltipPosition;

    const { tooltipElement, setElementToStickToRef, setTooltipVisible } = useTooltip({
      content: tooltip,
      placement: placementConverted,
      forceVisible: tooltipForceVisible,
    });

    const refs = useMergeRefs([ref, setElementToStickToRef]);

    const proxiedOnMouseOver = useCallback(
      e => {
        if (onMouseOver) onMouseOver(e);
        setTooltipVisible(true);
      },
      [onMouseOver, setTooltipVisible]
    );

    const proxiedOnMouseOut = useCallback(
      e => {
        if (onMouseOut) onMouseOut(e);
        setTooltipVisible(false);
      },
      [onMouseOut, setTooltipVisible]
    );

    return (
      <BaseButton
        {...restProps}
        onMouseOver={proxiedOnMouseOver}
        onFocus={proxiedOnMouseOver}
        onMouseOut={proxiedOnMouseOut}
        onBlur={proxiedOnMouseOut}
        ref={refs}
      >
        {children}
        {tooltip ? tooltipElement : null}
      </BaseButton>
    );
  }
);

export const buttonWithTooltipPropTypes = {
  ...baseButtonPropTypes,
  tooltip: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  tooltipPosition: PropTypes.oneOf([
    'top',
    'top-start',
    'top-end',
    'right',
    'right-start',
    'right-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'left-start',
    'left-end',
    // other legacy options
    'up',
    'down',
    'upLeft',
    'upRight',
    'downLeft',
    'downRight',
  ]),
  tooltipBackground: PropTypes.string,
  tooltipForceVisible: PropTypes.bool,
};

ButtonWithTooltip.propTypes = buttonWithTooltipPropTypes;

export default ButtonWithTooltip;
