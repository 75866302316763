import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import callApi from 'helpers/apiHelpers.js';
import { useTranslation } from 'react-i18next';

import useAuthenticationInitiateRedirect from '../useAuthenticationInitiateRedirect.js';
import { Canvas, Top, Title, ErrorText } from '../Authentication.styles.js';

const ConfirmAccount = ({ token, handlePostAuthentication }) => {
  const { t } = useTranslation();
  const [tokenInvalid, setTokenInvalid] = useState(false);
  const [accountVerified, setAccountVerified] = useState(false);
  const [hasOtherError, setHasOtherError] = useState(false);
  const { redirectCountdown, initiateRedirect } = useAuthenticationInitiateRedirect();

  useEffect(() => {
    callApi(`v1/user/email/verify`, 'post', { verificationCode: token })
      .then(res => {
        setTokenInvalid(false);
        setAccountVerified(true);
        initiateRedirect({
          timeoutCallback: handlePostAuthentication({ res }),
        });
      })
      .catch(res => {
        const errorMessage = res.error?.message;
        if (errorMessage === 'could_not_verify') {
          setTokenInvalid(true);
          setAccountVerified(false);
        } else if (errorMessage === 'already_verified') {
          setTokenInvalid(false);
          setAccountVerified(true);
        } else {
          setHasOtherError(true);
        }
      });
  }, []);

  return (
    <Canvas>
      <Top>
        <Title>{t('SignIn.ConfirmTitle')}</Title>
      </Top>
      {tokenInvalid && accountVerified && <ErrorText>{t('SignIn.ConfirmTokenAlreadyVerified')}</ErrorText>}
      {tokenInvalid && !accountVerified && <ErrorText>{t('SignIn.ConfirmTokenInvalid')}</ErrorText>}
      {!tokenInvalid && accountVerified && (
        <ErrorText>{t('SignIn.ConfirmVerified', { count: redirectCountdown })}</ErrorText>
      )}
      {hasOtherError && <ErrorText>{t('SignIn.ConfirmUnknownError')}</ErrorText>}
    </Canvas>
  );
};

ConfirmAccount.propTypes = {
  handlePostAuthentication: PropTypes.func,
  token: PropTypes.string,
};

export default ConfirmAccount;
