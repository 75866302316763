import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { resourcesUrl } from 'global/env';

const defaultDesc = 'Create easy to follow explanations and guides. Improve customer success and reduce support cost';
const defaultTitle = 'Interactive step-by-step guides and troubleshooting';
const defaultUrl = 'https://stonly.com';
const defaultOgImage = `${resourcesUrl.url}resources/facebook-cover.jpg`;
const defaultTwitterImage = `${resourcesUrl.url}resources/twitter-cover.png`;

export default function ExplanationHelmet({
  title = defaultTitle,
  description = defaultDesc,
  url = defaultUrl,
  canonicalUrl,
  lang,
  favicon,
  isPrivate = false,
  companyName = 'Stonly',
  noBranding = false,
  ogImage = defaultOgImage,
  twitterImage = defaultTwitterImage,
  customCSS,
}) {
  const [savedTitle] = useState(title);
  const [savedDescription] = useState(description);
  const [savedUrl] = useState(url);
  const [savedLang] = useState(lang);
  const [savedFavicon] = useState(favicon);
  const [savedIsPrivate] = useState(isPrivate);
  const [savedCompanyName] = useState(companyName);
  const [savedNoBranding] = useState(noBranding);
  const [savedOgImage] = useState(ogImage);
  const [savedTwitterImage] = useState(twitterImage);
  const [savedCustomCSS] = useState(customCSS);

  return (
    <Helmet>
      <html lang={savedLang} />
      <title>{`${savedTitle}${savedNoBranding ? '' : ` | ${savedCompanyName}`}`}</title>
      {savedFavicon && <link rel="shortcut icon" href={savedFavicon} />}
      <meta name="description" content={savedDescription} />
      <meta name="og:description" content={savedDescription} />
      <meta property="og:title" content={`${savedTitle}${savedNoBranding ? '' : ` | ${savedCompanyName}`}`} />
      <meta property="og:url" content={savedUrl} />
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      <meta property="og:image" content={savedOgImage} />
      <meta name="twitter:image" content={savedTwitterImage} />
      {savedIsPrivate && <meta name="robots" content="noindex, follow" />}
      {savedCustomCSS && <style type="text/css">{savedCustomCSS}</style>}
    </Helmet>
  );
}

ExplanationHelmet.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  canonicalUrl: PropTypes.string,
  lang: PropTypes.string,
  favicon: PropTypes.string,
  teamId: PropTypes.number,
  staticPage: PropTypes.bool,
  isPrivate: PropTypes.bool,
  companyName: PropTypes.string,
  noBranding: PropTypes.bool,
  ogImage: PropTypes.string,
  twitterImage: PropTypes.string,
  customCSS: PropTypes.string,
};
