import React, { useMemo, useState, useEffect, useRef, useCallback, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { STEP_TYPE, STEP_NEXT_SELECTOR_TYPE, STEP_NEXT_VISIBILITY, STEP_NAVIGATION_TYPES } from 'global';
import { isObjectEmpty } from 'helpers/objectManagement.js';
import { isMobileDevice } from 'helpers/userAgentHelpers';
import SkipChecklistButton from '@playerCommon/ComplexElements/Checklist/SkipChecklistButton';
import BackButton from '@playerCommon/CustomElements/BackButton';
import useGuideStepConnectionsVisibility from '@playerCommon/hooks/playerHooks/useGuideStepConnectionsVisibility';
import useGuidedTourStepEnabledConnections from '@playerCommon/hooks/playerHooks/useGuidedTourStepEnabledConnections';
import { useTranslation } from 'react-i18next';
import { convertTemplateStringToDisplayString } from '@stonlyCommons/helpers/guideVariableHelpers';
import { useSentData } from '@playerCommon/Contexts/sentDataContext';
import { useUserData } from '@playerCommon/Contexts/userDataContext';
import { useServerCallData } from '@playerCommon/Contexts/serverCallDataContext';
import { useStepAttachments } from '@playerCommon/Contexts/stepAttachmentsProvider';
import { useStepInputsState } from '../provider/StepInputsProvider';
import NextStepSelector from '../NextStepSelector/NextStepSelector';
import { ButtonsWrap, SingleNextButton, StyledConditionalNextStepIcon, StyledLoader } from '../Steps.styles';
import { getNextStepLabel, isSnoozeTriggerOption } from '../Steps.helpers';

const StepNavigation = forwardRef(
  (
    {
      options,
      onStepNextClick,
      selectorType,
      showBackButton,
      shouldGoNextOnSelection,
      tileLayout,
      tileImagePosition,
      tileImageSize,
      buttonSettingsContent,
      stepType,
      shouldShowSkipChecklistButton,
      isChecklistCompleted,
      currentlyActive,
      isFirstStep,
      stepId,
      previousUrlToLoad,
      onBackLinkClick,
      onSkipChecklistClick,
      onSingleNextStepClick,
      pathToChecklist,
      isPreview,
      stepConnectionList,
      shouldEveryConnectionBeEnabled,
      onShouldShowNextButtonSelectorChange,
      isInlineGuideLoading,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { localData } = useSentData();
    const userData = useUserData();
    const { serverCallVariables } = useServerCallData();
    const { isInputValuesValid } = useStepInputsState();
    const { isAttachmentUploading } = useStepAttachments();
    const { isLoading, visibleConnectionIdList } = useGuideStepConnectionsVisibility({
      connections: stepConnectionList,
      isPreview,
      stepId,
    });
    const guidedTourEnabledConnectionIdsList = useGuidedTourStepEnabledConnections({
      stepConnectionList,
      shouldEveryConnectionBeEnabled,
    });
    const isEveryConnectionHasTypeInteraction = stepConnectionList.every(
      ({ navigationType }) => navigationType === STEP_NAVIGATION_TYPES.INTERACTION
    );
    const visibleConnections = useMemo(() => {
      const visibleOptions = options.filter(
        option => visibleConnectionIdList.includes(option.id) || isSnoozeTriggerOption(option)
      );
      return shouldEveryConnectionBeEnabled
        ? visibleOptions
        : visibleOptions.map(o => ({
            ...o,
            disabled: !guidedTourEnabledConnectionIdsList.includes(o.id) && !isSnoozeTriggerOption(o),
          }));
    }, [options, visibleConnectionIdList, guidedTourEnabledConnectionIdsList, shouldEveryConnectionBeEnabled]);
    const [selectedOptionId, setSelectedOptionId] = useState();

    const buttonClickedRef = useRef(false);

    const isStepTypeNps = stepType === STEP_TYPE.nps;
    const isStepTypeCustomSurvey = stepType === STEP_TYPE.customSurvey;
    const isStepTypeChecklist = stepType === STEP_TYPE.checklist;

    const firstConnection = visibleConnections[0];
    const hasNextStep =
      visibleConnections.length > 1 || (visibleConnections.length === 1 && !isObjectEmpty(firstConnection));
    const hasSingleNextStep = hasNextStep && visibleConnections.length === 1;

    const isNextStepHiddenDueToChecklistNotCompleted = isStepTypeChecklist && !isChecklistCompleted;

    const shouldShowNextButtonSelector =
      hasNextStep &&
      !isNextStepHiddenDueToChecklistNotCompleted &&
      ([STEP_NEXT_SELECTOR_TYPE.radio, STEP_NEXT_SELECTOR_TYPE.select, STEP_NEXT_SELECTOR_TYPE.tiles].includes(
        selectorType
      ) ||
        !hasSingleNextStep) &&
      !isStepTypeNps &&
      !isStepTypeCustomSurvey &&
      !(hasSingleNextStep && !firstConnection.buttonEnabled);

    const isButtonWrapTopMarginRemoved =
      (selectorType === STEP_NEXT_SELECTOR_TYPE.button && visibleConnections.length > 1) ||
      (selectorType === STEP_NEXT_SELECTOR_TYPE.tiles && hasNextStep);

    const shouldShowSingleNextButton =
      hasNextStep &&
      !isNextStepHiddenDueToChecklistNotCompleted &&
      (([STEP_NEXT_SELECTOR_TYPE.radio, STEP_NEXT_SELECTOR_TYPE.select].includes(selectorType) &&
        !shouldGoNextOnSelection) ||
        hasSingleNextStep) &&
      selectorType !== STEP_NEXT_SELECTOR_TYPE.tiles;

    const defaultNextButtonLabel = t('StepButtons.NextButton');

    const singleNextButtonLabel = useMemo(() => {
      const labelBase = [STEP_NEXT_SELECTOR_TYPE.radio, STEP_NEXT_SELECTOR_TYPE.select].includes(selectorType)
        ? buttonSettingsContent.nextStepButtonText || defaultNextButtonLabel
        : getNextStepLabel(firstConnection);
      const label = convertTemplateStringToDisplayString({
        content: labelBase || '',
        userData,
        localData,
        serverCallVariables,
        isPreview,
        shouldWrapVarName: isPreview,
      });

      const shouldShowConditionalNextStepIconForSingleNextButton =
        isPreview &&
        hasSingleNextStep &&
        [STEP_NEXT_SELECTOR_TYPE.button, STEP_NEXT_SELECTOR_TYPE.tiles].includes(selectorType) &&
        [STEP_NEXT_VISIBILITY.externalCondition, STEP_NEXT_VISIBILITY.fallback].includes(firstConnection.visibility);

      return shouldShowConditionalNextStepIconForSingleNextButton ? (
        <>
          {label}
          <StyledConditionalNextStepIcon data-cy="StyledConditionalNextStepIcon" />
        </>
      ) : (
        label
      );
    }, [
      isPreview,
      userData,
      localData,
      serverCallVariables,
      selectorType,
      buttonSettingsContent.nextStepButtonText,
      firstConnection,
      hasSingleNextStep,
      defaultNextButtonLabel,
    ]);

    const shouldShowButtonsWrap = !['finalStep', STEP_TYPE.contactForm, STEP_TYPE.nps, STEP_TYPE.customSurvey].includes(
      stepType
    );

    const shouldShowBackButton = showBackButton && !isFirstStep && stepType !== 'introduction' && !!previousUrlToLoad;

    useEffect(() => {
      buttonClickedRef.current = false;
    }, [stepId]);

    useEffect(() => {
      onShouldShowNextButtonSelectorChange(shouldShowNextButtonSelector);
    }, [shouldShowNextButtonSelector]);

    // Prevent clicking on the button multiple times within short period of time
    // Consequent clicks are causing the guided tour to stop working due to multiple steps being skipped at the same time
    const onStepNextClickProxy = useCallback(
      option => {
        if (buttonClickedRef.current) {
          return;
        }
        if (ref.current) {
          ref.current.handlePreSendActions();
        }
        if (!isInputValuesValid) {
          return;
        }
        buttonClickedRef.current = true;
        setTimeout(() => {
          buttonClickedRef.current = false;
        }, 1000);
        onStepNextClick(option);
      },
      [onStepNextClick, ref, isInputValuesValid]
    );

    // Prevent clicking on the button multiple times within short period of time
    // Consequent clicks are causing the guided tour to stop working due to multiple steps being skipped at the same time
    const onSingleNextStepClickProxy = useCallback(
      fallbackOptionId => {
        if (buttonClickedRef.current) {
          return;
        }
        if (ref.current) {
          ref.current.handlePreSendActions();
        }
        if (!isInputValuesValid) {
          return;
        }
        buttonClickedRef.current = true;
        setTimeout(() => {
          buttonClickedRef.current = false;
        }, 1000);
        const optionId = selectedOptionId || fallbackOptionId;
        const selectedConnection = optionId
          ? visibleConnections.find(connection => connection.id === optionId)
          : firstConnection;
        onSingleNextStepClick(selectedConnection);
      },
      [selectedOptionId, firstConnection, visibleConnections, onSingleNextStepClick, isInputValuesValid, ref]
    );

    if (isInlineGuideLoading) {
      return <StyledLoader type="circle" />;
    }

    if (isLoading && !isEveryConnectionHasTypeInteraction) {
      return <StyledLoader type="circle" />;
    }

    return (
      <>
        {shouldShowNextButtonSelector && (
          <NextStepSelector
            options={visibleConnections}
            setSelectedOptionId={setSelectedOptionId}
            onStepNextClick={onStepNextClickProxy}
            selectedOptionId={selectedOptionId}
            selectorType={selectorType}
            disabled={isPreview || isAttachmentUploading}
            shouldGoNextOnSelection={shouldGoNextOnSelection}
            tileLayout={tileLayout}
            tileImagePosition={tileImagePosition}
            tileImageSize={tileImageSize}
            goNextOnSelection={onSingleNextStepClickProxy}
            placeholderText={buttonSettingsContent.placeholderText}
          />
        )}
        {shouldShowButtonsWrap && (
          <ButtonsWrap
            noTopMargin={!isNextStepHiddenDueToChecklistNotCompleted && isButtonWrapTopMarginRemoved}
            currentlyActive={currentlyActive}
            className="button-wrap"
          >
            {shouldShowBackButton && (
              <BackButton
                link={previousUrlToLoad}
                onClick={onBackLinkClick}
                className="back-button"
                dataCy="backButton"
                disabled={isPreview}
              />
            )}
            {shouldShowSkipChecklistButton && (
              <SkipChecklistButton
                isFirstStep={isFirstStep}
                isPreview={isPreview}
                checklistId={stepId}
                onSkipChecklistClick={onSkipChecklistClick}
              />
            )}
            {shouldShowSingleNextButton && (
              <SingleNextButton
                className={`single-button-wrap ${singleNextButtonLabel ? 'single-button-wrap-custom-label' : ''}`}
                background="outlineAmaranth"
                mouseover="highlight"
                disabled={
                  (!selectedOptionId && visibleConnections.length > 1) ||
                  (selectedOptionId &&
                    visibleConnections.some(({ id, disabled }) => id === selectedOptionId && disabled)) ||
                  isPreview ||
                  (selectorType === STEP_NEXT_SELECTOR_TYPE.button && firstConnection.disabled) ||
                  isAttachmentUploading
                }
                removed={firstConnection && firstConnection.removed}
                added={firstConnection && firstConnection.added}
                content={singleNextButtonLabel}
                withArrow={!isMobileDevice()}
                onClick={() => onSingleNextStepClickProxy()}
                dataCy="nextStepButton"
                dataStaticValue={
                  selectorType === STEP_NEXT_SELECTOR_TYPE.button ? firstConnection.staticValue : undefined
                }
                positionTooltip="upLeft"
                tooltip={isPreview ? t('Preview.ButtonsDisabled') : undefined}
                forceTooltip={isPreview}
              />
            )}
            {!!pathToChecklist && !hasNextStep && (
              <SingleNextButton
                className="single-button-wrap"
                background="outlineAmaranth"
                mouseover="highlight"
                content={t('Global.Next')}
                withArrow={!isMobileDevice()}
                link={pathToChecklist}
              />
            )}
          </ButtonsWrap>
        )}
      </>
    );
  }
);

StepNavigation.propTypes = {
  options: PropTypes.array,
  onStepNextClick: PropTypes.func,
  selectorType: PropTypes.string,
  showBackButton: PropTypes.bool,
  shouldGoNextOnSelection: PropTypes.bool,
  tileLayout: PropTypes.string,
  tileImagePosition: PropTypes.string,
  tileImageSize: PropTypes.string,
  buttonSettingsContent: PropTypes.object,
  stepType: PropTypes.string,
  shouldShowSkipChecklistButton: PropTypes.bool,
  isChecklistCompleted: PropTypes.bool,
  currentlyActive: PropTypes.bool,
  isFirstStep: PropTypes.bool,
  stepId: PropTypes.number,
  previousUrlToLoad: PropTypes.string,
  onBackLinkClick: PropTypes.func,
  onSkipChecklistClick: PropTypes.func,
  onSingleNextStepClick: PropTypes.func,
  pathToChecklist: PropTypes.string,
  isPreview: PropTypes.bool,
  stepConnectionList: PropTypes.array,
  shouldEveryConnectionBeEnabled: PropTypes.bool,
  onShouldShowNextButtonSelectorChange: PropTypes.func,
  isInlineGuideLoading: PropTypes.bool,
};

export default StepNavigation;
