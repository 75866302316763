import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import i18n, { getLanguageShorthand } from 'helpers/i18n';
import { playerUrl } from 'global/env';
import { RIGHT, guideTypeMap } from 'global';
import TextLink from '@playerCommon/StandardElements/TextLink';
import displayDate from 'helpers/dateManagement.js';
import GuideThumbnail from '@playerCommon/CustomElements/GuideThumbnail/GuideThumbnail';

import MoreIconSVG from 'icons/more.svg';

import ExplanationRowDropdown from './ExplanationRowDropdown';

const OptionsIcon = styled(MoreIconSVG)`
  margin-right: 20px;
  cursor: pointer;
  path {
    fill: ${props => props.theme.slateGrey};
  }
`;

const Canvas = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ context }) => (context === 'view' ? '0 8px;' : '0 24px;')};
  margin: ${({ context }) => (context === 'view' ? '0 -8px;' : '0 -24px;')};
  ${({ context }) =>
    context === 'smallView' &&
    css`
      margin: 0;
      padding: 0;
    `};

  &:hover {
    background: ${props => props.theme.paleGrey};
  }
`;

const TitleCol = styled.div`
  display: flex;
  align-items: center;
  ${({ context }) => context === 'view' && 'width: 80%;'}
  ${({ context }) => context === 'smallView' && 'width: 100%;'}
  padding-right: 8px;
`;

const ExplanationMainInfo = styled.div``;

const Title = styled(TextLink)`
  font-size: ${({ context }) => (context === 'view' ? '14px' : '16px')};
  display: inline-block;
`;

const Status = styled.div`
  width: 15%;
  font-size: 16px;
`;

const SubInfo = styled.div`
  color: ${props => props.theme.lightGrey};
  font-size: 12px;
  margin-top: 7px;
  display: flex;
  align-items: center;
`;

const UpdateDate = styled.div`
  width: 20%;
  ${({ context }) => context === 'smallView' && 'display:none;'}
  font-size: ${({ context }) => (context === 'view' ? '12px' : '16px')};
`;

const Options = styled.div`
  width: 5%;
  display: flex;
  justify-content: flex-end;
  position: relative;
`;

const ExplanationThumbnail = styled(GuideThumbnail)`
  margin: 24px 24px 24px 0;
`;

const ExplanationRow = ({
  explanation,
  openMovePanel,
  deleteExplanation,
  duplicateExplanation,
  // changeGuideRights,
  status,
  context,
  explanationURL,
  loadedFolders,
  currentFolderId,
}) => {
  const parentFolderAccess = loadedFolders
    ? (loadedFolders.find(folder => folder.folderId === currentFolderId) || {}).accessRights
    : RIGHT.FULL_RIGHTS;

  const history = useHistory();

  const getStepNumberLabel = stepNumber => {
    let label;
    if (stepNumber === 1) {
      label = i18n('Console.stepNumberLabelOne');
    } else if (stepNumber < 1) {
      label = i18n('Console.stepNumberLabelNone');
    } else {
      label = `${stepNumber} ${i18n('Console.stepNumberLabelMultiple')}`;
    }
    return label;
  };

  const currentLanguage = getLanguageShorthand();

  const onOpenSettings = useCallback(guideId => {
    history.push(`/app/${guideTypeMap[explanation.type]}/${guideId}/editor/?issettingon=true`);
  }, []);

  const onEditRightsProxy = useCallback(guideId => {
    // until edit guide rights opens the panel it has to be overriden
    // changeGuideRights(guideId);
    history.push(`/app/${guideTypeMap[explanation.type]}/${guideId}/editor/?editAccessRights=true`);
  }, []);

  const onView = useCallback(
    guideId => {
      if (explanation.status === 'draft') {
        window.open(`${playerUrl}/guide/${currentLanguage}/${guideId}/PreviewSteps/`);
      } else {
        window.open(`${playerUrl}/guide/${currentLanguage}/${guideId}/Steps/`, '_blank');
      }
    },
    [currentLanguage]
  );

  const onEdit = useCallback(guideId => {
    history.push(`/app/${guideTypeMap[explanation.type]}/${guideId}/editor/`);
  }, []);

  const stepNumber = getStepNumberLabel(explanation.stepNumber);

  const thumbSize = context === 'view' ? 'extraSmall' : 'small';
  const link = ['view', 'smallView'].includes(context)
    ? explanationURL
    : `/app/${guideTypeMap[explanation.type]}/${explanation.id}/editor/`;
  const image = {
    thumbnail: explanation.image,
    thumbnailSecureHash: explanation.imageSecureHash,
  };

  return (
    <Canvas data-cy="explanationRows" context={context}>
      <TitleCol context={context}>
        <ExplanationThumbnail src={image} size={thumbSize} guideId={explanation.id} context={context} />
        <ExplanationMainInfo>
          <Title context={context} type="black" href={link}>
            {explanation.title}
          </Title>
          <SubInfo>{stepNumber}</SubInfo>
        </ExplanationMainInfo>
      </TitleCol>
      {status === 'all' && <Status>{explanation.status}</Status>}
      <UpdateDate context={context}>{displayDate(explanation.updateDate)}</UpdateDate>
      {!['view', 'smallView'].includes(context) && (
        <Options>
          <ExplanationRowDropdown
            guideId={explanation.id}
            guide={explanation}
            accessRights={Math.min(explanation.access, parentFolderAccess)}
            onView={onView}
            onEdit={onEdit}
            onEditRights={onEditRightsProxy}
            onDuplicate={duplicateExplanation}
            onOpenSettings={onOpenSettings}
            onMoveTo={openMovePanel}
            onDelete={deleteExplanation}
            trigger={<OptionsIcon data-cy="optionsButton" />}
          />
        </Options>
      )}
    </Canvas>
  );
};

ExplanationRow.propTypes = {
  explanation: PropTypes.object,
  deleteExplanation: PropTypes.func,
  duplicateExplanation: PropTypes.func,
  elementToDisplay: PropTypes.object,
  toggleElementToDisplay: PropTypes.func,
  openMovePanel: PropTypes.func,
  status: PropTypes.string,
  context: PropTypes.string,
  explanationURL: PropTypes.string,
  loadedFolders: PropTypes.array,
  currentFolderId: PropTypes.number,
};

export default ExplanationRow;
