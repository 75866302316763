import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from '@playerCommon/StandardElements/Button/Button';
import CloseButtonSVG from 'icons/cross.svg';
import { AnimatePresence, motion } from 'framer-motion';
import UserInfo from '../components/UserInfo/UserInfo.js';
import Actions from '../components/Actions/Actions.js';

const Canvas = styled(motion.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  margin-bottom: -24px;
  background: ${props => props.theme.white};
  border-top: 1px solid ${props => props.theme.silver};

  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 24px;
  right: 16px;
`;

const MobileUserOptions = ({ className, show, user, toggleElementToDisplay }) => (
  <AnimatePresence>
    {show && (
      <Canvas
        key={show}
        className={className}
        initial={{ opacity: 0, y: 24, pointerEvents: 'none' }}
        animate={{ opacity: 1, y: 0, pointerEvents: 'all' }}
        exit={{ opacity: 0, y: 24, pointerEvents: 'none' }}
        transition={{ duration: 0.25, ease: 'easeInOut' }}
      >
        <CloseButton
          background="white"
          type="icon"
          content={CloseButtonSVG}
          mouseover="normal"
          onClick={() => toggleElementToDisplay(false)}
        />
        <UserInfo user={user} />
        <Actions user={user} />
      </Canvas>
    )}
  </AnimatePresence>
);

MobileUserOptions.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool,
  user: PropTypes.object,
  toggleElementToDisplay: PropTypes.func,
};

export default MobileUserOptions;
