const contentCanvas = {
  contentCanvasPaddingBottom: '68px',
  contentCanvasPaddingBottomMobile: '60px',
};

const compactIllustration = {
  compactIllustrationTopMargin: '-12px',
  // icon
  compactIllustrationIconHeight: '216px',
  compactIllustrationIconVerticalMargin: '-12px',
  // video and code
  compactIllustrationVideoCodeVerticalMargin: '-12px',
  compactIllustrationBorderRadius: '4px',
};

const nextStepSelector = {
  nextStepSelectorMarginTop: '32px',
  nextStepSelectorButtonsTypeMarginTop: '40px',
  nextStepSelectorButtonMarginBottom: '16px',
};

const contentWrap = {
  contentWrapBigDesktopPadding: '40px 80px',
  contentWrapDesktopPadding: '40px',
  contentWrapSubDesktopPadding: '24px',
  contentWrapSubDesktopScrollableVerticalPadding: 64,
  contentWrapSubDesktopNoIllustrationPaddingTop: '40px',
  contentWrapMobileNoIllustrationPaddingTop: '32px',
};

const progressBar = {
  progressBarHeight: '3px',
};

const header = {
  widgetHeaderHeight: '64px',
  hcHeaderBigDesktopHeight: '96px',
  hcHeaderSubDesktopHeight: '60px',
  hcWidgetHeaderHeight: '65px',
  normalHeaderBigDesktopHeight: '88px',
  normalHeaderMobileDesktopHeight: '60px',
};

const footer = {
  footerBigDesktopHeight: '84px',
  footerSubDesktopHeight: '68px',
  footerMobileHeight: '60px',
};

const embedWrap = {
  embedWrapPadding: '8px',
};

const aiStep = {
  aiAnswerInputBottomTop: '16px',
  aiAnswerInputBottomPadding: '24px',
  playerMobileAiAnswerInputBottomTop: '16px',
  playerMobileAiAnswerInputBottomPadding: '16px',
  aiAnswerInputFontSize: '16px',
  aiAnswerInputLineHeight: '24px',
  playerMobileAiAnswerInputFontSize: '16px',
  playerMobileAiAnswerInputLineHeight: '24px',
  // ai answer styles
  aiAnswerAnswerFontSize: '16px',
  aiAnswerAnswerLineHeight: '28px',
  aiAnswerButtonFontSize: '16px',
  aiAnswerButtonLineHeight: '24px',
  aiAnswerButtonPadding: '8px 16px',
  // ai answer styles mobile
  playerMobileAiAnswerAnswerFontSize: '16px',
  playerMobileAiAnswerAnswerLineHeight: '24px',
  playerMobileAiAnswerButtonPadding: '8px 16px',
};

const specialStepBackButton = {
  marginTop: '-12px',
  marginBottom: '16px',
  subDesktop: {
    marginTop: '-12px',
    marginBottom: '24px',
    noIllustration: {
      marginTop: '-28px',
      marginBottom: '20px',
    },
  },
  mobile: {
    marginTop: '-12px',
    marginBottom: '24px',
    noIllustration: {
      marginTop: '-20px',
      marginBottom: '20px',
    },
  },
};

/**
 * Step content paddings for guide modes for 'Sub Desktop' screens (max-width 899px)
 */
export const stepsContentPaddings = {
  /** guide in Agent Application */
  agentApp: {
    /** Icon, Video and Code padding */
    mediaPadding: 0,
    /** Content and buttons padding */
    contentPadding: 8,
  },
  /** guide mode === 'widget' */
  widget: {
    /** widget format === 'Light' */
    light: {
      /** widget placement === 'Modal' */
      modal: {
        /** Icon, Video and Code padding */
        mediaPadding: 0,
        /** Content and buttons padding */
        contentPadding: 24,
      },
      /** widget placement === 'Corner Window' */
      cornerWindow: {
        /** Icon, Video and Code padding */
        mediaPadding: 8,
        /** Content and buttons padding */
        contentPadding: 16,
      },
    },
  },
  /** [start] For other modes and widget types */
  /** Icon, Video and Code padding */
  mediaPadding: 12,
  /** Content and buttons padding */
  contentPadding: 24,
  /** [end] For other modes and widget types */
};

export default function getStepTheme({ mode } = {}) {
  const root = mode === 'embedModal' ? stepsContentPaddings.agentApp : stepsContentPaddings;

  return {
    ...contentCanvas,
    ...compactIllustration,
    ...nextStepSelector,
    ...contentWrap,
    ...progressBar,
    ...header,
    ...footer,
    ...embedWrap,
    ...aiStep,
    specialStepBackButton,
    stepsContentPaddings: {
      mediaPadding: root.mediaPadding,
      contentPadding: root.contentPadding,
    },
  };
}
