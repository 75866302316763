import { uuidv4 } from '@stonlyCommons/helpers/randomValues';

const asyncPosthogGetFeatureFlag = flag =>
  new Promise((resolve, reject) => {
    if (window.posthog && flag) {
      const posthogTimeout = setTimeout(() => {
        resolve(null);
      }, 5000);
      window.posthog.onFeatureFlags(() => {
        if (window.posthog.getFeatureFlag) {
          resolve(window.posthog.getFeatureFlag(flag));
        } else {
          resolve(null);
        }
        clearTimeout(posthogTimeout);
      });
    } else {
      resolve(null);
    }
  });

export const getFeatureFlag = async flag => {
  return asyncPosthogGetFeatureFlag(flag);
};

export const getNewFunnelId = () => {
  return uuidv4();
};
