import memoize from 'fast-memoize';
import ManyKeysWeakMap from './ManyKeysWeakMap';

function ObjectKeyedCache() {
  this.cache = new ManyKeysWeakMap();
}
ObjectKeyedCache.prototype.has = function has(key) {
  return this.cache.has(key);
};
ObjectKeyedCache.prototype.get = function get(key) {
  return this.cache.get(key);
};
ObjectKeyedCache.prototype.set = function set(key, value) {
  this.cache.set(key, value);
};

export function clientOnlyMemoize(func) {
  const cache = {
    create: function create() {
      return new ObjectKeyedCache();
    },
  };

  function serializer() {
    return [...arguments];
  }

  if (typeof window === 'undefined') {
    return func;
  }
  return memoize(func, { cache, serializer });
}

const DEFAULT_OPTIONS = { invalidationTimeout: 1000 };
/**
 * Memoize (cache) function by params-key.
 * Invalidate after timeout.
 * Created for "caching" promises, to avoid same requests executed simultaneously
 * But can be used for any data (consider "fast-memoize" if you don't need timeout)
 *
 * @Mateusz - test memory leak, maybe should delete invalidate cache imperatively
 * @Mateusz - do not cache errors(?)
 */
export function memoizeWithTimeout(func, options = DEFAULT_OPTIONS) {
  const { invalidationTimeout, name = 'unnamed' } = { ...DEFAULT_OPTIONS, ...options };

  const cacheByParams = Object.create(null); /* [params]: { timestamp, reference } */

  return (...params) => {
    const cacheKey = `cache_${JSON.stringify(params)}`;
    const timestamp = Date.now();

    if (!cacheByParams[cacheKey] || cacheByParams[cacheKey].timestamp + invalidationTimeout < timestamp) {
      cacheByParams[cacheKey] = { timestamp, reference: func(...params) };
    } else {
      // Please let it go for now. @Mateusz will remove it at ~ mid2022
      console.log(`:: Use ${name} memoized with params: ${JSON.stringify(params)}`);
    }
    return cacheByParams[cacheKey].reference;
  };
}
