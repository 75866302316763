const addGenesysChatListeners = (onOpen, onClose) => {
  if (window.Genesys) {
    Genesys('subscribe', 'Messenger.opened', onOpen);
    Genesys('subscribe', 'Messenger.closed', onClose);
  }
};

export const initializeGenesysChat = (isWidget, onContactOpen, onContactClose) => {
  if (window.Genesys && !isWidget) {
    addGenesysChatListeners(onContactOpen, onContactClose);
  }
};
