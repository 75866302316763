import React, { useCallback, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Dropdown from '@playerCommon/CustomElements/Dropdown';

const DropdownWrap = styled.div`
  padding: 8px;
`;

const Separator = styled.div`
  border-top: 1px solid ${props => props.theme.silver};
  height: 1px;
  margin: 8px -8px 8px -8px;
`;

const CategoryName = styled.div`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 8px;
  padding: 0 12px;
  color: ${props => props.theme.steel};
`;

const OptionIcon = styled.div`
  display: flex;
  margin-right: 12px;
  flex-shrink: 0;

  svg {
    transition: fill 0.2s;
  }
`;

const OptionName = styled.div`
  flex-grow: 1;
`;

const OptionRow = styled.div`
  display: flex;
  width: 100%;
  padding: 6px 12px;
  align-items: center;
  justify-content: flex-start;
  border-radius: 4px;
  background-color: transparent;

  cursor: pointer;
  font-size: 14px;
  line-height: 24px;
  color: ${props => props.theme.slateGrey};
  font-weight: 500;
  transition: color 0.2s, background-color 0.2s;

  ${({ animateIconHover, theme }) =>
    animateIconHover &&
    css`
      ${OptionIcon} {
        svg path,
        svg circle {
          transition: fill 0.2s;
          fill: ${theme.lightGrey};
        }
      }
    `}

  &:hover {
    color: ${props => props.textHoverColor || props.theme.darkGrey};
    background-color: ${props => props.backgroundHoverColor || props.theme.paleGrey};

    ${({ animateIconHover, iconHoverColor, theme }) =>
      animateIconHover &&
      css`
        ${OptionIcon} {
          svg path,
          svg circle {
            fill: ${iconHoverColor || theme.slateGrey};
          }
        }
      `}
  }
`;

const ActionsDropdown = ({
  options,
  trigger,
  width = 216,
  placement = 'bottom-start',
  offsetValue = 10,
  animateIconHover,
  className,
}) => {
  const dropdownRef = useRef();
  const closeDropdown = useCallback(() => {
    dropdownRef.current.closeDropdown();
  }, []);

  const optionsMemoized = useMemo(
    () =>
      options.map(opt => {
        const onClick = e => {
          e.preventDefault();
          e.stopPropagation();
          closeDropdown();
          if (opt.onClick) opt.onClick(opt.value);
        };
        return (
          <React.Fragment key={opt.id}>
            {opt.separation && <Separator />}
            {opt.isCategory ? <CategoryName>{opt.label}</CategoryName> : null}
            {opt.isCategory ? null : (
              <OptionRow
                onClick={onClick}
                animateIconHover={animateIconHover}
                iconHoverColor={opt.iconHoverColor}
                textHoverColor={opt.textHoverColor}
                backgroundHoverColor={opt.backgroundHoverColor}
                data-cy={opt.dataCy}
              >
                {typeof opt.icon === 'function' && (
                  <OptionIcon>
                    <opt.icon />
                  </OptionIcon>
                )}
                {typeof opt.icon === 'object' && <OptionIcon>{opt.icon}</OptionIcon>}
                <OptionName data-cy="optionName">{opt.label}</OptionName>
              </OptionRow>
            )}
          </React.Fragment>
        );
      }),
    [options]
  );

  return (
    <Dropdown
      width={width}
      ref={dropdownRef}
      trigger={trigger}
      placement={placement}
      className={className}
      offsetValue={offsetValue}
    >
      <DropdownWrap data-cy="dropdownWrap">{optionsMemoized}</DropdownWrap>
    </Dropdown>
  );
};

ActionsDropdown.propTypes = {
  options: PropTypes.array,
  className: PropTypes.string,
  trigger: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
  width: PropTypes.number,
  placement: PropTypes.string,
  offsetValue: PropTypes.number,
  animateIconHover: PropTypes.bool,
  dataCy: PropTypes.string,
};

export default ActionsDropdown;
