const SEPARATOR = '|';

export const createPath = (...val) => {
  return val.filter(Boolean).join(SEPARATOR);
};

export const calculatePathLevel = path => {
  if (!path) {
    // workaround for case when e.g. adding element to the tree (virtuoso doesnt's see correct path for a miliseconds)
    return 0;
  }
  return path.split(SEPARATOR).length - 1;
};

export const isPathStartsWith = (path, startsWithThis) => {
  if (path && startsWithThis) {
    return (path + SEPARATOR).startsWith(startsWithThis + SEPARATOR);
  }
  return false;
};

export const splitPath = path => {
  return path.split(SEPARATOR);
};

/**
 * for path "a|b|c" returns ["a", "a|b", "a|b|c"]
 */
export const generatePathChainList = path => {
  const pathPartList = splitPath(path);

  return pathPartList.reduce((acc, currPathPart, idx) => {
    return [...acc, idx === 0 ? currPathPart : createPath(acc[idx - 1], currPathPart)];
  }, []);
};
