/* eslint-disable react/jsx-one-expression-per-line */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

const AiAnswerPendingMessages = styled.button`
  position: absolute;
  left: 50%;
  bottom: 100%;
  width: fit-content;
  padding: 4px 16px;
  color: white;
  border-radius: 40px;
  font-size: 14px;
  line-height: 24px;
  background: #1c1a24;
  appearance: none;
  border: 0;
  cursor: pointer;

  transform: translate3d(-50%, 56px, 0);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0.2s, transform 0.2s;

  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      visibility: visible;
      transform: translate3d(-50%, 0px, 0);
      transition-delay: 1s;
    `}
`;

export const AiAnswerUnreadMessagesButton = ({ isVisible }) => {
  const { t } = useTranslation();
  const scrollToFirstUnreadMessage = useCallback(() => {
    const firstUnreadMessage = document.querySelector('.ai-step-result-text-not-in-viewport');
    if (firstUnreadMessage) {
      firstUnreadMessage.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  const [arePendingMessagesAvailable, setArePendingMessagesAvailable] = useState(false);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

  useEffect(() => {
    const updateUnreadMessages = () => {
      setTimeout(() => {
        const unreadMessages = document.querySelectorAll('.ai-step-result-text-not-in-viewport');
        setArePendingMessagesAvailable(unreadMessages.length > 0);
        setUnreadMessagesCount(unreadMessages.length);
      }, 0);
    };
    window.addEventListener('ai-answer-reset', updateUnreadMessages);
    window.addEventListener('message-seen', updateUnreadMessages);
    return () => {
      window.removeEventListener('ai-answer-reset', updateUnreadMessages);
      window.removeEventListener('message-seen', updateUnreadMessages);
    };
  }, []);

  return (
    <AiAnswerPendingMessages
      isVisible={arePendingMessagesAvailable && isVisible}
      onClick={arePendingMessagesAvailable ? scrollToFirstUnreadMessage : undefined}
      className="ai-step-unread-messages-button"
    >
      {t('AiStep.UnreadMessages', { count: unreadMessagesCount })}
    </AiAnswerPendingMessages>
  );
};
