import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { STEP_NEXT_TYPE, STEP_NEXT_VISIBILITY, STEP_NEXT_SELECTOR_TYPE } from 'global';
import { getIsMobileIntegration } from 'helpers/userAgentHelpers';
import { integrations } from 'helpers/widgetIntegrations';
import RadioButtons from '@playerCommon/StandardElements/RadioButtons/RadioButtons';
import Button from '@playerCommon/StandardElements/Button/Button.js';
import Tile, { TileGrid } from '@playerCommon/StandardElements/Tile';
import DropdownInput from '@playerCommon/StandardElements/DropdownInput';
import { playerContentTextStyles, mobilePlayerContentTextStyle } from '@playerCommon/CommonStyledComponents/Typography';
import { useSentData } from '@playerCommon/Contexts/sentDataContext';
import { useUserData } from '@playerCommon/Contexts/userDataContext';
import { useServerCallData } from '@playerCommon/Contexts/serverCallDataContext';
import { isNonStonlyLink } from 'helpers/validationHelpers';
import {
  replaceVarNamesWithValues,
  convertTemplateStringToDisplayString,
} from '@stonlyCommons/helpers/guideVariableHelpers.js';
import useGuideData from '@playerCommon/hooks/playerHooks/useGuideData';
import { useTranslation } from 'react-i18next';
import { StyledConditionalNextStepIcon } from '../Steps.styles';
import { isSnoozeTriggerOption } from '../Steps.helpers';

const Canvas = styled.div`
  margin-top: ${({ theme }) => theme.nextStepSelectorMarginTop};

  ${({ isButtonSelectorType }) =>
    isButtonSelectorType &&
    css`
      margin-top: ${({ theme }) => theme.nextStepSelectorButtonsTypeMarginTop};
    `}

  @media screen and (max-width: 899px) {
    padding: 0 ${({ theme }) => theme.stepsContentPaddings.contentPadding}px;
  }

  &:has(+ .button-wrap:not(:empty)) {
    margin-bottom: 16px;
  }
`;

const NextStepButton = styled(Button)`
  margin-bottom: ${({ theme }) => theme.nextStepSelectorButtonMarginBottom};
  &:last-of-type {
    margin-bottom: 0;
  }
  display: block;

  [data-cy='tooltip'] {
    left: 20px;
  }
`;

const ChoiceWrapper = styled.div``;

const StyledRadioButtons = styled(RadioButtons)`
  ${playerContentTextStyles};
  display: block;
  padding-left: 12px;
  padding-right: 12px;

  @media screen and (max-width: 480px) {
    ${mobilePlayerContentTextStyle};
  }
`;

const StyledDropdownInput = styled(DropdownInput)`
  font-size: 16px;

  ${({ added, removed }) => {
    if (removed) {
      return css`
        .dropdownInputWrap {
          background: ${props => props.theme.changesRemovedColor};
        }
      `;
    }
    if (added) {
      return css`
        .dropdownInputWrap {
          background: ${props => props.theme.changesAddedColor};
        }
      `;
    }
  }}
`;

const ChoiceLabel = styled.span`
  align-items: center;
  display: flex;
`;

const NextStepSelector = ({
  options,
  setSelectedOptionId,
  selectedOptionId,
  selectorType,
  disabled,
  onStepNextClick,
  shouldGoNextOnSelection,
  tileLayout,
  tileImagePosition,
  tileImageSize,
  goNextOnSelection,
  placeholderText,
}) => {
  const { t } = useTranslation();
  const { guideLinksDisabled, mode } = useGuideData();
  const isPreview = mode === 'preview';
  const hasExactlyOneOption = options.length === 1;

  const isRemoved = options.every(option => option.removed);
  const isAdded = options.every(option => option.added);

  const { localData } = useSentData();
  const userData = useUserData();
  const { serverCallVariables } = useServerCallData();

  const isButtonSelectorType = selectorType === STEP_NEXT_SELECTOR_TYPE.button;

  function onSelectChange(optionId) {
    if (shouldGoNextOnSelection && !hasExactlyOneOption && !isPreview) {
      goNextOnSelection(optionId);
    } else {
      setSelectedOptionId(optionId);
    }
  }

  function renderChoiceLabel(option) {
    const choiceLabelBase = option.label || option.title || t('StepButtons.NextButton');
    const choiceLabel = convertTemplateStringToDisplayString({
      content: choiceLabelBase,
      localData,
      userData,
      serverCallVariables,
      isPreview,
      shouldWrapVarName: isPreview,
    });

    if (isPreview) {
      return (
        <ChoiceLabel>
          {choiceLabel}
          {[STEP_NEXT_VISIBILITY.externalCondition, STEP_NEXT_VISIBILITY.fallback].includes(option.visibility) && (
            <StyledConditionalNextStepIcon
              data-cy="styledConditionalNextStepIcon"
              className={isButtonSelectorType ? '' : 'slate-grey'}
            />
          )}
        </ChoiceLabel>
      );
    }
    return choiceLabel;
  }

  return (
    <Canvas data-cy="selectorTypes" isButtonSelectorType={isButtonSelectorType}>
      {selectorType === STEP_NEXT_SELECTOR_TYPE.radio && (
        <ChoiceWrapper className="radio-wrap" added={isAdded} removed={isRemoved}>
          <StyledRadioButtons
            options={options.map(option => ({
              id: option.id,
              value: option.id,
              label: renderChoiceLabel(option),
              staticValue: option.staticValue,
              added: option.added,
              removed: option.removed,
              modified: option.modified,
              className: `radioOption-${option.id}`,
            }))}
            selectOption={onSelectChange}
            selectedId={selectedOptionId}
            added={isAdded}
            removed={isRemoved}
          />
        </ChoiceWrapper>
      )}
      {selectorType === STEP_NEXT_SELECTOR_TYPE.select && (
        <ChoiceWrapper className="dropdown-wrap" added={isAdded} removed={isRemoved}>
          <StyledDropdownInput
            placeholder={placeholderText || t('ExplanationPlayer.ChoicePlaceholder')}
            options={options.map(option => ({
              id: option.id,
              value: option.id,
              label: renderChoiceLabel(option),
              staticValue: option.staticValue,
              added: option.added,
              removed: option.removed,
              modified: option.modified,
            }))}
            added={isAdded}
            removed={isRemoved}
            onChange={e => onSelectChange(e.target.value)}
            value={options.length === 1 ? options[0].id : selectedOptionId}
            floatingPlacementStrategy="absolute"
            maxHeight="Min(256px, 45vh)"
            filteringEnabled
          />
        </ChoiceWrapper>
      )}
      {selectorType === STEP_NEXT_SELECTOR_TYPE.button &&
        options.map(option => (
          <NextStepButton
            className={`button-wrap stepNextButton-${option.id} ${
              isSnoozeTriggerOption(option) ? 'snooze-button' : ''
            }`}
            key={option.id}
            background="outlineAmaranth"
            mouseover="highlight"
            content={renderChoiceLabel(option)}
            added={option.added}
            removed={option.removed}
            modified={option.modified}
            dataStaticValue={option.staticValue}
            onClick={() => onStepNextClick(option)}
            withArrow={!getIsMobileIntegration()}
            disabled={disabled || option.disabled}
            positionTooltip="upLeft"
            tooltip={isPreview ? t('Preview.ButtonsDisabled') : undefined}
            forceTooltip={isPreview}
            dataCy="nextStepButton"
            ariaLabel={option.label || option.title}
          />
        ))}
      {selectorType === STEP_NEXT_SELECTOR_TYPE.tiles && (
        <TileGrid layout={tileLayout}>
          {options.map(option => {
            const isIntegration = integrations[option.linkUrl];
            const isExternalLink = option.toStepId === STEP_NEXT_TYPE.EXTERNAL_LINK && !isIntegration;
            const urlToOpen =
              guideLinksDisabled && isNonStonlyLink(option.linkUrl)
                ? undefined
                : replaceVarNamesWithValues({
                    content: option.linkUrl,
                    userData,
                    localData,
                    serverCallVariables,
                  });
            return (
              <Tile
                className={`tile stepNextTile-${option.id} ${isSnoozeTriggerOption(option) ? 'snooze-button' : ''}`}
                key={option.id}
                content={renderChoiceLabel(option)}
                added={option.added}
                removed={option.removed}
                modified={option.modified}
                dataStaticValue={option.staticValue}
                isExternalLink={isExternalLink}
                shouldOpenInNewTab={option.newTabEnabled}
                urlToOpen={urlToOpen}
                onClick={e => {
                  e.preventDefault();
                  onStepNextClick(option);
                }}
                disabled={disabled || option.disabled}
                imagePosition={tileImagePosition}
                imageSize={tileImageSize}
                media={option.media}
                isTextHidden={option.isTextHidden}
              />
            );
          })}
        </TileGrid>
      )}
    </Canvas>
  );
};

NextStepSelector.propTypes = {
  options: PropTypes.array,
  setSelectedOptionId: PropTypes.func,
  selectedOptionId: PropTypes.number,
  selectorType: PropTypes.string,
  disabled: PropTypes.bool,
  onStepNextClick: PropTypes.func,
  shouldGoNextOnSelection: PropTypes.bool,
  tileLayout: PropTypes.string,
  tileImagePosition: PropTypes.string,
  tileImageSize: PropTypes.string,
  goNextOnSelection: PropTypes.func,
  placeholderText: PropTypes.string,
};

export default NextStepSelector;
