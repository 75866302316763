import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import i18n from 'helpers/i18n';
import styled from 'styled-components';
import SuccessSVG from 'icons/successLarge.svg';

import BackButton from '@playerCommon/CustomElements/BackButton';
import { useSentData } from '@playerCommon/Contexts/sentDataContext';
import { useUserData } from '@playerCommon/Contexts/userDataContext';
import { useServerCallData } from '@playerCommon/Contexts/serverCallDataContext';
import { replaceVarNamesWithValues } from '@stonlyCommons/helpers/guideVariableHelpers.js';
import { Title, MainContent } from '../ContactForm.styles';

const Canvas = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SuccessIcon = styled(SuccessSVG)`
  margin-bottom: 24px;
`;

const StyledBackButton = styled(BackButton)`
  margin-top: 24px;
  margin-right: 0;
`;

function SuccessMessage({ previousUrlToLoad, contactFormContent, shouldShowBackButton, onBackClick }) {
  const { successMessage } = contactFormContent;
  const { localData } = useSentData();
  const userData = useUserData();
  const { serverCallVariables } = useServerCallData();

  const successMessageToDisplay = useMemo(
    () =>
      replaceVarNamesWithValues({
        content: successMessage,
        userData,
        localData,
        serverCallVariables,
      }),
    [successMessage, localData, userData, serverCallVariables]
  );
  return (
    <Canvas>
      <SuccessIcon />
      {successMessageToDisplay ? (
        <MainContent className="content-text" dataCy="contentText" text={successMessageToDisplay} />
      ) : (
        <Title data-cy="thankYou">{i18n('HelpcenterContact.ThankYou')}</Title>
      )}
      {shouldShowBackButton && <StyledBackButton link={previousUrlToLoad} onClick={onBackClick} />}
    </Canvas>
  );
}

SuccessMessage.propTypes = {
  previousUrlToLoad: PropTypes.string,
  contactFormContent: PropTypes.object,
  shouldShowBackButton: PropTypes.bool,
  onBackClick: PropTypes.func,
};

export default SuccessMessage;
