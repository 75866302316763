import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import i18n, { getLanguageShorthand } from 'helpers/i18n';
import { Link } from 'react-router-dom';
import { createPathForURL, getChoicesPrettyPrint } from 'helpers/explanationManagement.js';
import { STEP_TYPE } from 'global';
import { removeTags } from 'helpers/textManagement.js';
import MultipleChoicePictoSVG from 'icons/multipleChoicePicto.svg';
import MultipleChoiceSVG from 'icons/multipleChoice.svg';
import CodeSVG from 'icons/code.svg';
import ImageSVG from 'icons/image.svg';
import CairnSVG from 'icons/cairnPicto.svg';
import { getIntroductionContent, getStepContent, getStepMedia, getStepTitle } from 'helpers/guidePlayerHelpers';
import useGuideData from '@playerCommon/hooks/playerHooks/useGuideData';
import { navigationWithKeyboardStyle } from '@playerCommon/CommonStyledComponents/NavigationWithKeyboard';
import PanelCloseButton from '../PanelCloseButton';

const Canvas = styled.div`
  padding: 48px 16px 0 16px;

  @media screen and (max-width: 621px) {
    padding: 16px 16px 0 16px;
  }

  ${({ mode }) =>
    ['embed', 'borderlessembed'].includes(mode) &&
    css`
      padding: 18px 24px 0 24px;
    `}
`;

const SubCanvas = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h2`
  margin: 0 16px 40px;
  line-height: 1.4;
  font-size: 24px;
  font-weight: 400;
`;

const StepPictoCanvas = styled.div`
  margin: 0 24px 0 16px;
  width: 40px;
  height: 40px;
  background: ${props => props.theme.grey};
  ${({ type }) => type === 'image' && 'background: #1293cf;'}
  ${({ type }) => type === 'questionMark' && 'background: #000000'}
	pointer-events: none;
`;

const RowsContainer = styled.div``;

const Row = styled(Link)`
  height: 72px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: box-shadow 0.2s;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: inherit;
  }

  ${navigationWithKeyboardStyle}

  &:hover {
    background-color: ${props => props.theme.paleGrey};

    ${StepPictoCanvas} {
      background: ${props => props.theme.turquoise};
    }
  }
`;

const StepTitle = styled.div`
  font-weight: 600;
`;

const StepContent = styled.div`
  color: ${({ multipleChoices, theme }) => (multipleChoices ? `${theme.tertiaryColor}` : 'inherit')};
  color: ${({ empty, theme }) => (empty ? theme.steel : 'color: inherit')};
  font-style: ${({ empty }) => (empty ? 'italic' : 'normal')};
  display: flex;
  align-items: center;
`;

const PlainText = styled.div`
  text-overflow: ellipsis;
  width: ${({ multipleChoices }) => (multipleChoices ? '85%' : '90%')};
  white-space: nowrap;
  overflow: hidden;
`;

const Info = styled.div`
  line-height: 1.4;
  font-size: 14px;
  width: 85%;
`;

const MultipleRoutesIcon = styled(MultipleChoiceSVG)`
  margin-right: 8px;
  padding-top: 5px;
`;

function getIllustrationType(type, stepMedia) {
  if (type === 'multipleChoicePicto') {
    return { type, picto: MultipleChoicePictoSVG };
  }
  if (stepMedia.type === 'image' && stepMedia.media.length > 0) {
    return { type: 'image', picto: ImageSVG };
  }
  if (type === 'cairnPicto') {
    return { type, picto: CairnSVG };
  }
  return { type, picto: CairnSVG };
}

export const PREVIOUS_STEPS_TITLE_ID = 'ston-previous-steps-title';

function PreviousSteps({ stepsPath, onStepClick, toggleComponent, mode }) {
  const {
    steps,
    stepConnectionList,
    firstStepId,
    guideInfo: {
      options: { defaultLanguage },
    },
  } = useGuideData();

  const previousStepsRows = useMemo(() => {
    const language = getLanguageShorthand();
    if (!stepsPath) return '';
    const reverseStepPath = stepsPath.slice().reverse();
    let arrayKey = 0;

    return reverseStepPath.map((stepId, index) => {
      arrayKey += 1;

      const stepRow = steps.find(step => step.stepId === stepId && step.stepType === STEP_TYPE.regular);
      const nextStepsRow = stepConnectionList.filter(nextStep => nextStep.fromStepId === stepId);
      let stepRowContent;
      let contentPicto;
      let stepRowIllustration;
      let link;
      let stepLabel;

      if (index === 0 || (!stepRow && stepId !== -2)) {
        return [];
      }

      if (stepId === -2) {
        stepLabel = 'Introduction';
        const firstStep = steps.find(s => s.stepId === firstStepId);
        const introductionContent = getIntroductionContent(firstStep, language, defaultLanguage);
        stepRowContent = removeTags(introductionContent);
        link = 'introduction';
        stepRowIllustration = { type: 'introduction', picto: CodeSVG };
      } else {
        if (nextStepsRow.length > 1) {
          stepRowContent = getChoicesPrettyPrint(nextStepsRow, reverseStepPath[index - 1]);

          contentPicto = <MultipleRoutesIcon />;
          stepRowIllustration = getIllustrationType('multipleChoicePicto', {});
        } else {
          const currentIllustrations = getStepMedia(stepRow, language, defaultLanguage);
          stepRowContent = getStepContent(stepRow, language, defaultLanguage);

          stepRowContent = stepRowContent ? removeTags(stepRowContent) : 'No content for this step';
          stepRowIllustration = getIllustrationType('cairnPicto', currentIllustrations);
        }

        stepLabel = getStepTitle(stepRow, language, defaultLanguage);

        const pathForLink = stepsPath.slice();
        pathForLink.length = stepsPath.indexOf(stepId) + 1;
        link = createPathForURL(pathForLink);
      }

      return (
        <Row
          key={arrayKey}
          to={link}
          onClick={() => {
            onStepClick(stepId);
            toggleComponent('');
          }}
        >
          <StepPictoCanvas type={stepRowIllustration.type}>
            <stepRowIllustration.picto />
          </StepPictoCanvas>
          <Info>
            <StepTitle>{stepLabel}</StepTitle>
            <StepContent
              multipleChoices={contentPicto !== undefined}
              empty={stepRowContent === 'No content for this step'}
            >
              {contentPicto}
              <PlainText multipleChoices={contentPicto !== undefined}>{stepRowContent}</PlainText>
            </StepContent>
          </Info>
        </Row>
      );
    });
  }, [stepsPath]);

  return (
    <Canvas mode={mode}>
      <SubCanvas>
        <PanelCloseButton mode={mode} onClick={toggleComponent} />
        <Title id={PREVIOUS_STEPS_TITLE_ID}>{i18n('PreviousSteps.CompletedSteps')}</Title>
        <RowsContainer>{previousStepsRows}</RowsContainer>
      </SubCanvas>
    </Canvas>
  );
}

PreviousSteps.propTypes = {
  toggleComponent: PropTypes.func,
  steps: PropTypes.array,
  illustrations: PropTypes.array,
  stepsPath: PropTypes.array,
  stepConnectionList: PropTypes.array,
  contents: PropTypes.array,
  introductionContent: PropTypes.string,
  mode: PropTypes.string,
  onStepClick: PropTypes.func,
};

export default PreviousSteps;
