import styled, { css } from 'styled-components';

import SearchSVG from 'icons/search.svg';
import CrossSVG from 'icons/crossSmall.svg';

export const SearchInputIcon = styled(SearchSVG)`
  margin-right: 12px;
  flex-shrink: 0;

  path {
    fill: ${props => props.theme.darkGrey};
    transition: fill 0.2s;
  }
`;

export const SearchInput = styled.input`
  min-width: 0;
`;

export const SearchCrossIcon = styled(CrossSVG)`
  flex-shrink: 0;
  margin-left: 8px;
  cursor: pointer;
  display: none;

  path {
    fill: ${props => props.theme.slateGrey};
  }

  ${({ show }) =>
    show &&
    css`
      display: block;
    `}
`;

export const Search = styled.div`
  display: flex;
  align-items: center;
  width: 224px;
  height: 40px;
  border: 1px solid ${props => props.theme.grey};
  border-radius: 4px;
  padding: 8px 12px;
  background: ${props => props.theme.white};

  input {
    font-size: 14px;
    line-height: 20px;
    height: 20px;
    width: 100%;
    border: none;
    outline: none;
    padding: 0;
    color: ${props => props.theme.darkGrey};

    &:focus {
      & + svg path {
        fill: ${props => props.theme.darkGrey};
      }
    }

    &::placeholder {
      color: ${props => props.theme.lightGrey};
      opacity: 1;
    }
  }
`;
