import React, { memo } from 'react';
import T from 'prop-types';
import { buildLeftGapStyleForLevel } from '../helpers';
import { RowPlaceholderContainer, ContentWrap, Content, RowCanvas } from './styles';

export const RowSpacing = memo(({ level, isUnlinked }) => {
  return (
    <RowCanvas isUnlinked={isUnlinked}>
      <RowPlaceholderContainer style={buildLeftGapStyleForLevel(level)} disabled>
        <ContentWrap>
          <Content data-cy="rowContent" />
        </ContentWrap>
      </RowPlaceholderContainer>
    </RowCanvas>
  );
});

RowSpacing.propTypes = {
  level: T.number,
  isUnlinked: T.bool,
};
