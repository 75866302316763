import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import i18n from 'helpers/i18n';
import { filePrompt } from 'helpers/fileHelpers';
import { onKeyPressToOnClick } from 'helpers/a11y';
import { navigationWithKeyboardStyle } from '@playerCommon/CommonStyledComponents/NavigationWithKeyboard';

import AttachmentIconSVG from 'icons/attachmentIcon.svg';

const FileInputWrap = styled.div`
  margin-top: 24px;
`;

const ContactFormFileInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border: 1px dashed ${props => props.theme.grey};
  border-radius: 4px;
  font-size: 14px;
  line-height: 1;
  color: ${props => props.theme.lightGrey};
  background: transparent;
  cursor: pointer;
  transition: opacity 0.2s, box-shadow 0.2s;
  box-shadow: none;

  ${({ fileOver }) =>
    fileOver &&
    css`
      border-style: solid;
      border-color: ${props => props.theme.seaweed};
    `}

  ${navigationWithKeyboardStyle}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}
`;

const AttachmentIcon = styled(AttachmentIconSVG)`
  height: 16px;
  width: 16px;
  margin-right: 8px;
`;

const FileInputLabel = styled.label`
  display: flex;
  font-size: 12px;
  color: ${props => props.theme.steel};
  font-weight: 500;
  padding-left: 0;
  margin-bottom: 8px;
`;

function FormFileInput({ onFile, disabled }) {
  const [fileOver, setFileOver] = useState(false);

  const handleOnClick = () => {
    if (disabled) return;
    filePrompt().then(onFile);
    // inputElement.accept =
    //   'image/*,video/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  };

  const handleFileDrop = e => {
    e.preventDefault();
    if (disabled) return;
    setFileOver(false);
    const { files } = e.dataTransfer;
    let filesValid = true;
    if (files.length > 1) filesValid = false; // TODO: remove when we support more than one file on the backend
    // for (let i = 0; i < files.length; i++) {
    //   if (
    //     !files[i].type.match(
    //       /image\/*|video\/*|application\/pdf|application\/msword,application\/vnd\.openxmlformats-officedocument\.wordprocessingml\.document/
    //     )
    //   )
    //     filesValid = false;
    // }
    if (filesValid) {
      onFile(files);
    }
  };

  const handleFileDragLeave = e => {
    e.preventDefault();
    setFileOver(false);
  };

  const handleFileDragOver = e => {
    e.preventDefault();
    setFileOver(true);
  };

  return (
    <FileInputWrap>
      <FileInputLabel>{i18n('HelpcenterContact.Attachments')}</FileInputLabel>
      <ContactFormFileInput
        fileOver={fileOver}
        onDrop={handleFileDrop}
        onDragOver={handleFileDragOver}
        onDragLeave={handleFileDragLeave}
        onClick={handleOnClick}
        onKeyPress={onKeyPressToOnClick(handleOnClick)}
        tabIndex="0"
        disabled={disabled}
      >
        <AttachmentIcon />
        {i18n('HelpcenterContact.FileInput')}
      </ContactFormFileInput>
    </FileInputWrap>
  );
}

FormFileInput.propTypes = {
  onFile: PropTypes.func,
  disabled: PropTypes.bool,
};

export default FormFileInput;
