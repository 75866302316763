import styled, { css } from 'styled-components';
import Button from '@playerCommon/StandardElements/Button/Button.js';
import ErrorMessage from '@playerCommon/CustomElements/ErrorMessage/ErrorMessage';
import Alert from '@playerCommon/StandardElements/Alert';

export const Canvas = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 40px 64px;

  @media screen and (max-width: 480px) {
    padding: 24px;
  }
`;

export const RegistrationCanvas = styled(Canvas)`
  ${({ shouldHaveOnlyVerticalPadding }) =>
    shouldHaveOnlyVerticalPadding &&
    css`
      padding: 32px 0 32px 0;

      @media screen and (max-width: 480px) {
        padding: 24px 0 24px 0;
      }
    `}
`;

export const Top = styled.div`
  display: flex;
  justify-content: center;
  flex-shrink: 0;
`;

export const Title = styled.h1`
  font-size: 32px;
  margin-bottom: 4px;
`;

export const SubTitle = styled.div`
  font-size: 12px;
  line-height: 24px;
  margin-top: 16px;
  margin-bottom: 32px;
  color: ${({ theme }) => theme.slateGrey};

  p {
    margin: 0;
    a {
      color: ${({ theme }) => theme.linkColor};
    }
  }
`;

export const Switch = styled.div`
  display: flex;
  color: ${props => props.theme.steel};
  font-size: 14px;
  justify-content: center;
  margin-top: 24px;
  align-items: center;
  flex-shrink: 0;
`;

export const TextLink = styled.button`
  color: ${props => props.theme.slateGrey};
  width: fit-content;
  cursor: pointer;
  font-weight: 500;
  background: none;
  border: none;
  font-family: inherit;

  &:hover {
    color: ${props => props.theme.darkGrey};
    text-decoration: underline;
  }
`;

export const ForgotPassword = styled(TextLink)`
  margin-bottom: 24px;
  font-size: 12px;
  display: block;
`;

export const AuthenticationInputWrap = styled.div`
  display: flex;

  & > * {
    width: 50%;

    &:first-child {
      margin-right: 24px;
    }
  }
`;

export const AuthenticationButton = styled(Button)`
  margin-top: 16px;
  width: 100%;
`;

export const AuthenticationButtonSSO = styled(AuthenticationButton)`
  margin-top: 40px;
`;

export const ErrorText = styled.div`
  font-size: 16px;
  margin-top: 32px;
`;

export const StyledAlert = styled(Alert)`
  margin-top: 16px;

  ${({ severity }) =>
    severity === 'info' &&
    css`
      background: rgba(77, 210, 239, 0.1);
    `}
`;

export const SuccessMessage = styled(ErrorMessage)`
  color: ${({ theme }) => theme.seaweed} !important;
`;

export const InputTitleAction = styled(TextLink)`
  color: ${props => props.theme.lightGrey};

  &:hover {
    color: ${props => props.theme.steel};
  }
`;

export const Separator = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.steel};

  @media screen and (max-width: 458px) {
    padding: 16px 0;
  }

  &::after,
  &::before {
    content: '';
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.silver};
  }
`;

export const SeparatorText = styled.span`
  padding: 0 16px;
  font-size: 14px;
`;

export const SSOButton = styled(Button)`
  margin-top: 16px;
  width: 100%;

  button {
    font-size: 14px;
    height: 38px;
    text-transform: none;
  }
`;

export const SSOProblemText = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.steel};
  transition: color 0.2s;
  font-size: 12px;
  line-height: 20px;

  &:hover {
    color: ${({ theme }) => theme.slateGrey};
  }
`;

export const TextWrap = styled.div`
  display: inline-block;
`;

export const Terms = styled.div`
  font-size: 13px;
  line-height: 22px;
  color: ${props => props.theme.steel};
  text-align: center;
  margin-top: 16px;

  a {
    color: ${({ theme }) => theme.slateGrey};
  }
`;
