import { useEffect, useCallback } from 'react';
import { simpleDebounce } from '@stonlyCommons/helpers/debounce';

export default function useTargetingChangedMessage({ onTargetingChange }) {
  const onTargetingChangeDebounced = useCallback(
    simpleDebounce(
      ({ properties, event }) => {
        if (typeof onTargetingChange === 'function') {
          onTargetingChange({ properties, event });
        }
      },
      { timeout: 1000, leading: false }
    ),
    [onTargetingChange]
  );

  useEffect(() => {
    const onTargetingChangeMessage = message => {
      if (message.data.type === 'targetingChanged') {
        const { properties, event } = message.data;
        onTargetingChangeDebounced({ properties, event });
      }
    };

    window.addEventListener('message', onTargetingChangeMessage);
    return () => {
      window.removeEventListener('message', onTargetingChangeMessage);
    };
  }, [onTargetingChangeDebounced]);
}
