import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { validatePasswordHints } from '@playerCommon/Authentication/PasswordHints/passwordHints.helpers';
import PasswordHintsTooltip from '@playerCommon/Authentication/PasswordHints/PasswordHintsTooltip';
import { defaultPasswordHintIsValid } from '@playerCommon/Authentication/PasswordHints/passwordHints.consts';
import { InputText } from '@playerCommon/ui/components/inputs/InputText/InputText';
import { InputPassword } from '@playerCommon/ui/components/inputs/InputPassword/InputPassword';
import { ColumnFlex } from '@playerCommon/ui/components/Flex/Flex';
import { AuthenticationInputWrap, AuthenticationButton } from '../../Authentication.styles.js';

const Canvas = styled.div``;

const RegistrationForm = ({
  formValues,
  handleFormChange,
  handleFormFocus,
  handleFormBlur,
  accountCreationFormSubmit,
  isNamePrefilled,
  emailFromToken,
  formStatus,
  inputRefs,
}) => {
  const { t } = useTranslation();
  const [passwordHints, setPasswordHints] = useState(defaultPasswordHintIsValid);

  async function accountCreationFormSubmitProxy(e) {
    e.preventDefault();
    accountCreationFormSubmit(e);
  }

  return (
    <Canvas>
      <form id="accountCreationForm" onSubmit={accountCreationFormSubmitProxy}>
        <ColumnFlex marginTop={2} gap={2} marginBottom={2}>
          <InputText
            label={t('Registration.EmailFieldTitle')}
            name="emailForAccountCreation"
            status={formStatus.emailForAccountCreation.type}
            message={formStatus.emailForAccountCreation.message}
            value={emailFromToken || formValues.emailForAccountCreation}
            onChange={handleFormChange}
            onBlur={handleFormBlur}
            onFocus={handleFormFocus}
            placeholder={t('Registration.EmailFieldPlaceholder')}
            data-cy="emailField"
            disabled={!!emailFromToken}
            readOnly={!!emailFromToken}
            autoComplete="email"
            ref={inputRefs.emailForAccountCreation}
          />
          {!isNamePrefilled && (
            <AuthenticationInputWrap>
              <InputText
                label={t('Registration.FirstnameFieldTitle')}
                name="firstName"
                status={formStatus.firstName.type}
                message={formStatus.firstName.message}
                value={formValues.firstName}
                onChange={handleFormChange}
                onBlur={handleFormBlur}
                onFocus={handleFormFocus}
                placeholder="Bill"
                data-cy="firstNameField"
                autoComplete="given-name"
                ref={inputRefs.firstName}
              />
              <InputText
                label={t('Registration.LastnameFieldTitle')}
                name="lastName"
                status={formStatus.lastName.type}
                message={formStatus.lastName.message}
                value={formValues.lastName}
                onChange={handleFormChange}
                onBlur={handleFormBlur}
                onFocus={handleFormFocus}
                placeholder="Crisper"
                data-cy="lastNameField"
                autoComplete="family-name"
                ref={inputRefs.lastName}
              />
            </AuthenticationInputWrap>
          )}
          <InputPassword
            label={t('Registration.PasswordFieldTitle')}
            name="passwordForAccountCreation"
            status={formStatus.passwordForAccountCreation.type}
            message={formStatus.passwordForAccountCreation.message}
            value={formValues.passwordForAccountCreation}
            onChange={event => {
              handleFormChange(event);
              setPasswordHints(validatePasswordHints(event.target.value));
            }}
            placeholder={t('Registration.PasswordFieldPlaceholder')}
            data-cy="passwordField"
            onFocus={handleFormFocus}
            onBlur={handleFormBlur}
            tooltip={<PasswordHintsTooltip passwordHints={passwordHints} />}
            autoComplete="new-password"
            ref={inputRefs.passwordForAccountCreation}
          />
        </ColumnFlex>
      </form>
      <AuthenticationButton
        form="accountCreationForm"
        fullWidth
        usage="submit"
        type="bigText"
        content={t('Registration.SignUp')}
        value="Submit"
        dataCy="join"
        onClick={accountCreationFormSubmitProxy}
      />
    </Canvas>
  );
};

RegistrationForm.propTypes = {
  focusedFormFields: PropTypes.object,
  formValues: PropTypes.object,
  handleFormChange: PropTypes.func,
  handleFormBlur: PropTypes.func,
  handleFormFocus: PropTypes.func,
  accountCreationFormSubmit: PropTypes.func,
  formPasswordHints: PropTypes.object,
  isNamePrefilled: PropTypes.bool,
  emailFromToken: PropTypes.string,
  formStatus: PropTypes.object,
};

export default RegistrationForm;
