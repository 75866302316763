import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '@playerCommon/StandardElements/Button/Button.js';
import RestrictedAccessIcon from 'icons/restrictedAccess.svg';
import TextLink from '@playerCommon/StandardElements/TextLink';
import StonlyLogo from 'resources/logo/logoDark.svg';
import withUser from '@playerCommon/HOC/withUser/withUser';
import useAuthenticationFlow from '@playerCommon/Authentication/useAuthenticationFlow';
import { postMessage } from 'helpers/widgetHelpers';

const Canvas = styled.div`
  display: flex;
  height: 100vh;
  padding: 48px;
  padding-top: 80px;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 480px) {
    padding: 24px;
    padding-top: 68px;
  }
`;

const Header = styled.div`
  position: absolute;
  display: flex;
  height: 80px;
  width: 100%;
  justify-content: center;
  top: 0;
  left: 0;
  font-size: 14px;
  background-color: transparent;
  transition: box-shadow 0.2s, background-color 0.2s;
  color: rgba(255, 255, 255, 0.7);
  z-index: 2;

  @media screen and (max-width: 480px) {
    height: 68px;
  }
`;

const HeaderCanvas = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 80px;
  max-width: 1504px;
  width: 100%;
  padding-left: 32px;
  padding-right: 32px;
  font-weight: 500;
  transition: background-color 0.2s;

  @media screen and (max-width: 480px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media screen and (max-width: 480px) {
    padding-left: 24px;
    padding-right: 24px;
    height: 68px;
  }

  @media screen and (max-width: 768px) {
    ${({ isMenuOpen }) =>
      isMenuOpen &&
      css`
        background: ${props => props.theme.mainColor};
      `}
  }
`;

const Logo = styled(StonlyLogo)`
  width: 95px;
  height: 28px;

  & g path:first-child {
    fill: ${({ theme }) => theme.mainColor};
  }
`;

const HeaderLink = styled(TextLink)`
  margin-right: 0;
  font-size: 1em;
  color: ${props => props.theme.lightGrey};

  @media screen and (max-width: 768px) {
    font-size: 0.8em;
  }
`;

const Left = styled.div`
  line-height: 0;
  padding-top: 2px;
`;

const Right = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

const SubCanvas = styled.div`
  text-align: center;
`;

const GoToAuthenticationButton = styled(Button)`
  margin-top: 24px;
`;

const Title = styled.h1`
  font-size: 20px;
  margin-top: 24px;
  font-weight: 400;
`;

const SubTitle = styled.div`
  font-size: 14px;
  line-height: 24px;
  margin-top: 8px;
`;

function NoViewAccess({ userManagement, mode = 'explanation', showLink = true, showBranding = true }) {
  const { t } = useTranslation();
  const { onOpenSignIn } = useAuthenticationFlow();

  useEffect(() => {
    // When zero height is passed, max height of the widget will be applied.
    postMessage({ type: 'contentHeight', height: 0 });
  }, []);

  return (
    <Canvas>
      <Header>
        <HeaderCanvas>
          <Left>
            {showBranding && (
              <a href="/">
                <Logo />
              </a>
            )}
          </Left>
          <Right>
            {showLink && (
              <HeaderLink type="slateGrey" href="/">
                {t('ErrorPages.404GoToHomepage')}
              </HeaderLink>
            )}
          </Right>
        </HeaderCanvas>
      </Header>
      <SubCanvas>
        {(mode === 'explanation' || mode === 'guide') && (
          <>
            {!userManagement?.user?.id && (
              <>
                <RestrictedAccessIcon />
                <Title data-cy="noAccessViewTitle">{t('RestrictedAccess.RestrictedAccess')}</Title>
                <SubTitle data-cy="noAccessViewSubTitle">{t('RestrictedAccess.PleaseLogin')}</SubTitle>
                <GoToAuthenticationButton
                  onClick={() => onOpenSignIn({ returnUrl: window.location.href })}
                  content={t('RestrictedAccess.SignInButton')}
                  value="Submit"
                  dataCy="noAccessButton"
                />
              </>
            )}
            {userManagement?.user?.id && (
              <>
                <RestrictedAccessIcon />
                <Title data-cy="noAccessViewTitle">{t('RestrictedAccess.RestrictedAccess')}</Title>
                <SubTitle data-cy="noAccessViewSubTitle">{t('RestrictedAccess.NoAccess')}</SubTitle>
              </>
            )}
          </>
        )}
        {mode === 'editor' && (
          <>
            <RestrictedAccessIcon />
            <Title data-cy="noAccessViewTitle">{t('RestrictedAccess.EditorRestrictedAccess')}</Title>
            <SubTitle data-cy="noAccessViewSubTitle">{t('RestrictedAccess.NoEditRights')}</SubTitle>
            <GoToAuthenticationButton
              link="/app/general/guides/"
              content={t('RestrictedAccess.GoBackToManager')}
              value="Submit"
              dataCy="noAccessButton"
            />
          </>
        )}
        {mode === 'general' && (
          <>
            <RestrictedAccessIcon />
            <Title data-cy="noAccessViewTitle">{t('RestrictedAccess.RestrictedAccess')}</Title>
            <SubTitle data-cy="noAccessViewSubTitle">{t('RestrictedAccess.NoAccess')}</SubTitle>
          </>
        )}
      </SubCanvas>
    </Canvas>
  );
}

NoViewAccess.propTypes = {
  userManagement: PropTypes.object,
  mode: PropTypes.string,
  showLink: PropTypes.bool,
  showBranding: PropTypes.bool,
};

export default withUser(NoViewAccess);
