import React, { forwardRef, useEffect, useRef } from 'react';

import styled from 'styled-components';

const TextareaAutosizeWrap = styled.div`
  display: inline-grid;
  vertical-align: top;
  align-items: flex-start;
  position: relative;
  font-family: inherit;
  padding: 0;
  border: 0;

  &::after,
  textarea {
    width: 100%;
    height: 100%;
    min-width: 1em;
    grid-area: 1 / 1;
    font: inherit;
    margin: 0;
    resize: none;
    background: none;
    appearance: none;
    border: none;
    padding: none;
  }

  &::after {
    content: attr(data-value) ' ';
    visibility: hidden;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }
`;

const TextareaInput = styled.textarea`
  &:focus {
    outline: none;
  }
`;

interface TextareaAutosizeProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  value: string;
  className?: string;
  onResize: (height: number) => void;
}

/**
 * TextareaAutosize is a custom component that automatically resizes a textarea to fit its content.
 * It uses the ResizeObserver API to detect changes in the textarea's size and notify the user via `onResize` callback.
 * @param {string} [props.className] - The class name for the component. When providing custom styles you must remember
 * to style both children components, `textarea` and `&::after`, the same way, otherwise autoresize will work weirdly.
 * @param {(height: number) => void} props.onResize - A function that is called when the textarea's size changes.
 * Height is passed as a param.
 */
const TextareaAutosize = forwardRef<HTMLTextAreaElement, TextareaAutosizeProps>((props, ref) => {
  const { value, className, onResize, ...rest } = props;
  const wrapRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      onResize(wrapRef.current?.clientHeight ?? 0);
    });
    if (wrapRef.current) {
      resizeObserver.observe(wrapRef.current);
    }

    return () => resizeObserver.disconnect();
  }, [onResize]);

  return (
    <TextareaAutosizeWrap data-value={value} ref={wrapRef} className={className}>
      <TextareaInput value={value} ref={ref} {...rest} />
    </TextareaAutosizeWrap>
  );
});

export default TextareaAutosize;
