import React, { memo, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import i18n from 'helpers/i18n';
import callApi from 'helpers/apiHelpers.js';
import NewComment from './NewComment/NewComment.js';
import CurrentComments from './CurrentComments/CurrentComments.js';
import PanelCloseButton from '../PanelCloseButton';

const Canvas = styled.div`
  margin-bottom: 100px;
`;

const Wrap = styled.div`
  padding: 48px 32px 0 32px;

  @media screen and (max-width: 768px) {
    padding: 18px 24px 0 24px;
  }

  ${({ mode }) =>
    ['widget', 'embed', 'borderlessembed'].includes(mode) &&
    css`
      padding: 18px 24px 0 24px;
    `}
`;

const StepTitle = styled.h4`
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 40px;
  margin-top: 0;
`;

export const COMMENTS_TITLE_ID = 'ston-comments-title';

function StepComments({ stepId, guideId, currentGuideId, access, toggleComponent, userManagement, mode }) {
  const [stepComments, setStepComments] = useState([]);

  function updateStepComments(comment, action = 'add') {
    let updatedStepComments = [];

    updatedStepComments =
      action === 'add'
        ? stepComments.concat(comment)
        : stepComments.filter(c => c.stepCommentId !== comment.stepCommentId);
    setStepComments(updatedStepComments);
  }

  useEffect(() => {
    const fetchComments = async () => {
      const res = await callApi(`v1/stepReaction?reactionType=comment&guideId=${guideId}&stepId=${stepId}`, 'get');
      setStepComments(res.data.items);
    };
    fetchComments();
  }, [stepId]);

  const currentStepComments = stepComments.filter(comment => Number(comment.stepId) === Number(stepId));

  return (
    <Wrap mode={mode}>
      <StepTitle data-cy="commentsTitle" id={COMMENTS_TITLE_ID}>
        <PanelCloseButton mode={mode} dataCy="commentsCloseButton" onClick={toggleComponent} />
        {i18n('StepComments.Subtitle')}
      </StepTitle>
      <Canvas>
        <NewComment
          userManagement={userManagement}
          stepId={stepId}
          guideId={guideId}
          currentGuideId={currentGuideId}
          updateStepComments={updateStepComments}
        />

        {stepComments.length > 0 && (
          <CurrentComments
            stepComments={currentStepComments}
            userManagement={userManagement}
            updateStepComments={updateStepComments}
            access={access}
          />
        )}
      </Canvas>
    </Wrap>
  );
}

StepComments.propTypes = {
  userManagement: PropTypes.object,
  stepId: PropTypes.number,
  guideId: PropTypes.string,
  currentGuideId: PropTypes.string,
  access: PropTypes.number,
  toggleComponent: PropTypes.func,
  mode: PropTypes.string,
};

export default memo(StepComments);
