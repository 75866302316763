import { useEffect, useState } from 'react';

// Hook
function useSessionStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      // Get from session storage by key
      const item = window.sessionStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to sessionStorage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // if value is undefined we remove it from sessionStorage
      if (valueToStore === undefined) {
        window.sessionStorage.removeItem(key);
      } else {
        // Save to session storage
        window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  // save an initial value to sessionStorage to retrieve it later if it's needed
  // this also has the same API as the regular hook
  useEffect(() => {
    let currentValue;
    try {
      currentValue = window.sessionStorage.getItem(key);
    } catch (e) {
      console.log('error', e);
    }
    if (!currentValue) setValue(initialValue);
  }, []);

  return [storedValue, setValue];
}

export default useSessionStorage;
