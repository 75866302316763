import React, { useEffect, useRef, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getNewFunnelId } from '@playerCommon/helpers/topOfFunnelManagement';
import useRefWidth from '@playerCommon/hooks/useRefWidth';
import { posthogCapture } from 'components/Analytics/recorder';
import { POSTHOG_EVENT_NAME, FUNNEL_STEP } from '@stonlyCommons/constants/Authentication.consts';
import { FUNNEL_FLOW_TYPE } from '../Authentication.consts';
import GoogleSignUp from '../GoogleAuth/GoogleSignUp.jsx';

import { RegistrationCanvas, Top, Title, Separator, SeparatorText } from '../Authentication.styles.js';
import Registration from './Registration.js';

const RegistrationWithGoogle = ({
  setAuthenticationView,
  email,
  firstName,
  lastName,
  handlePostAuthentication,
  funnelFlow,
  funnelId: providedFunnelId,
}) => {
  const { t } = useTranslation();
  const canvasRef = useRef(null);
  const [funnelId, setFunnelId] = useState(providedFunnelId);
  const [canvasWidth] = useRefWidth(canvasRef);
  const isAuthenticationPage = funnelFlow === FUNNEL_FLOW_TYPE.AUTHENTICATION_REGISTRATION;

  const canvasContentWidth = useMemo(() => {
    let canvasPadding = 0;
    if (canvasRef.current) {
      const canvasStyle = window.getComputedStyle(canvasRef.current);
      canvasPadding = Number.parseFloat(canvasStyle.paddingLeft) + Number.parseFloat(canvasStyle.paddingRight);
    }
    return canvasWidth - canvasPadding;
  }, [canvasRef, canvasWidth]);

  useEffect(() => {
    if (!funnelId) {
      setFunnelId(getNewFunnelId());
    }
  }, []);

  useEffect(() => {
    posthogCapture(POSTHOG_EVENT_NAME.DEMO_SIGNUP_FUNNEL, {
      step: FUNNEL_STEP.ACCOUNT_CREATION_FORM_SHOWN,
      funnelId,
      flow: funnelFlow,
    });
  }, [funnelId]);

  return (
    <>
      <RegistrationCanvas ref={canvasRef} shouldHaveOnlyVerticalPadding={!isAuthenticationPage}>
        <Top>
          <Title>{t('Registration.Title')}</Title>
        </Top>
        <GoogleSignUp
          containerWidth={canvasContentWidth}
          funnelId={funnelId}
          handlePostAuthentication={handlePostAuthentication}
        />
      </RegistrationCanvas>
      <Separator>
        <SeparatorText>{t('SignInSSO.Or')}</SeparatorText>
      </Separator>
      <Registration
        shouldHideTitle
        setAuthenticationView={setAuthenticationView}
        handlePostAuthentication={handlePostAuthentication}
        email={email}
        firstName={firstName}
        lastName={lastName}
        funnelId={funnelId}
        funnelFlow={funnelFlow}
      />
    </>
  );
};

RegistrationWithGoogle.propTypes = {
  className: PropTypes.string,
  setAuthenticationView: PropTypes.func,
  setLoginEmail: PropTypes.func,
  handlePostAuthentication: PropTypes.func,
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};

export default RegistrationWithGoogle;
