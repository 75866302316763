import { useEffect } from 'react';

export function isNodeFound(current, componentNode) {
  if (current === componentNode) {
    return true;
  }

  return false;
}

/**
 * Try to find our node in a hierarchy of nodes, returning the document
 * node as highest node if our node is not found in the path up.
 */
export function findHighest(current, componentNode) {
  if (current === componentNode) {
    return true;
  }

  while (current.parentNode || current.host) {
    // Only check normal node without shadowRoot
    if (current.parentNode && isNodeFound(current, componentNode)) {
      return true;
    }
    // eslint-disable-next-line no-param-reassign
    current = current.parentNode || current.host;
  }
  return current;
}

export default function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = event => {
        // Do nothing if clicking ref's element or descendent elements
        const current = (event.composed && event.composedPath && event.composedPath().shift()) || event.target;
        if (!ref) return;
        if (findHighest(current, ref.current) !== document) return;
        if (!ref.current || ref.current.contains(event.target)) return;

        const portalElement = document.querySelector('#react-cool-portal');
        if (portalElement && portalElement.contains(event.target)) return;

        handler(event);
      };

      document.addEventListener('click', listener);

      return () => {
        document.removeEventListener('click', listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}
