import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { assetsUrl } from 'global/env';
import { GUIDE_VARIABLE_TYPES } from 'global';
import i18n from 'helpers/i18n';

import withFullscreen from '@playerCommon/HOC/withFullscreen';
import UnsafeHtml from '@playerCommon/StandardElements/UnsafeHtml/UnsafeHtml';
import {
  playerContentTextStyles,
  mobilePlayerContentTextStyle,
  tableStyles,
  variableStyles,
} from '@playerCommon/CommonStyledComponents/Typography';
import useTooltip from '@playerCommon/hooks/useTooltip';
import FullscreenTable from '../components/FullscreenTable/FullscreenTable.js';

const EXPAND_TABLE_BUTTON_SIZE = 32;
const EXPAND_TABLE_BUTTON_OFFSET = 4;

const expandTableButtonStyles = css`
  &:before {
    content: url('${assetsUrl}resources/icons/expandDiagonally.svg');
    position: absolute;
    top: ${EXPAND_TABLE_BUTTON_OFFSET}px;
    right: ${EXPAND_TABLE_BUTTON_OFFSET}px;
    padding: 8px;
    width: ${EXPAND_TABLE_BUTTON_SIZE}px;
    height: ${EXPAND_TABLE_BUTTON_SIZE}px;
    line-height: 0;
    background-color: ${props => props.theme.highlightColor};
    box-shadow: 0px 4px 12px ${props => props.theme.canvasBox};
    border-radius: 4px;
    z-index: 1;
    cursor: pointer;

    svg {
      display: block;

      path {
        fill: ${props => props.theme.white};
      }
    }
  }
`;

// eslint-disable-next-line react/prop-types
const UnsafeHtmlFiltered = ({ isPreview, ...rest }) => <UnsafeHtml {...rest} />;

const contentStyle = css`
  color: ${props => props.theme.darkGrey};
  margin-bottom: ${props => props.theme.playerMainContentMarginBottom};
  ${playerContentTextStyles};
  ${tableStyles};
  ${variableStyles};

  ${({ isPreview }) =>
    !isPreview &&
    css`
      .table-container .table-wrapper {
        ${expandTableButtonStyles};
      }
    `}

  @media screen and (max-width: 899px) {
    ${mobilePlayerContentTextStyle};
  }
`;

const StyledUnsafeHtml = styled(UnsafeHtmlFiltered)`
  ${contentStyle}
`;

const StyledContent = styled.div`
  ${contentStyle}
`;

const ContentText = ({
  className,
  text,
  mode,
  parentIsFullscreen,
  openParentFullscreen,
  closeParentFullscreen,
  stepId,
}) => {
  const [parentFullscreenWasOpen, setParentFullscreenWasOpen] = useState(false);
  const [isFullscreenTableOpen, setIsFullscreenTableOpen] = useState(false);
  const [tableHTML, setTableHTML] = useState('');
  const isPreview = mode === 'preview';

  function openFullscreen() {
    if (['embed', 'widget', 'hc', 'hc-widget'].includes(mode)) {
      if (parentIsFullscreen) {
        setParentFullscreenWasOpen(true);
      } else {
        openParentFullscreen();
      }
    }
    setTimeout(() => {
      setIsFullscreenTableOpen(true);
    }, 100);
  }

  function closeFullscreen() {
    if (parentIsFullscreen && !parentFullscreenWasOpen) {
      closeParentFullscreen();
    }
    setTimeout(() => {
      setIsFullscreenTableOpen(false);
      setParentFullscreenWasOpen(false);
    }, 100);
  }

  const openFullscreenTable = e => {
    const { width } = e.target.getBoundingClientRect();
    const { offsetX, offsetY } = e;

    if (
      e.target.classList.contains('table-wrapper') &&
      offsetY > EXPAND_TABLE_BUTTON_OFFSET &&
      offsetY < EXPAND_TABLE_BUTTON_SIZE + EXPAND_TABLE_BUTTON_OFFSET &&
      offsetX > width - (EXPAND_TABLE_BUTTON_SIZE + EXPAND_TABLE_BUTTON_OFFSET) &&
      offsetX < width - EXPAND_TABLE_BUTTON_OFFSET
    ) {
      setTableHTML(e.target.outerHTML);
      openFullscreen();
    }
  };

  useEffect(() => {
    const tableContainerList = document.querySelectorAll(`.step-${stepId} .table-container`);
    tableContainerList.forEach(tableContainer => {
      tableContainer.addEventListener('click', openFullscreenTable);
    });
    return () => {
      tableContainerList.forEach(tableContainer => {
        tableContainer.removeEventListener('click', openFullscreenTable);
      });
    };
  }, [text]);

  const [tooltipContent, setTooltipContent] = useState('');
  const tooltipContentByTypeMap = {
    [GUIDE_VARIABLE_TYPES.local]: i18n('Preview.VariableLocalTooltip'),
    [GUIDE_VARIABLE_TYPES.userData]: i18n('Preview.VariableUserDataTooltip'),
    [GUIDE_VARIABLE_TYPES.server]: i18n('Preview.VariableServerCallTooltip'),
  };
  const { tooltipElement, setElementToStickToRef, setTooltipVisible } = useTooltip({
    content: tooltipContent,
  });

  useEffect(() => {
    if (!isPreview) {
      return;
    }
    const varEls = document.querySelectorAll('var[data-type]');
    const onMouseOver = e => {
      setTooltipContent(tooltipContentByTypeMap[e.target.dataset.type]);
      setElementToStickToRef(e.target);
      setTooltipVisible(true);
    };
    const onMouseOut = () => {
      setTooltipVisible(false);
    };
    varEls.forEach(varEl => {
      varEl.addEventListener('mouseover', onMouseOver);
      varEl.addEventListener('mouseout', onMouseOut);
    });
    // eslint-disable-next-line consistent-return
    return () => {
      varEls.forEach(varEl => {
        varEl.removeEventListener('mouseover', onMouseOver);
        varEl.removeEventListener('mouseout', onMouseOut);
      });
    };
  }, [text]);

  return (
    <>
      {typeof text === 'string' ? ( // if content is a string to put as UnsafeHTML
        <StyledUnsafeHtml className={className} text={text} isPreview={isPreview} dataCy="contentText" />
      ) : (
        // if content is a raw html converted by i18n to a component
        <StyledContent className={className} isPreview={isPreview} data-cy="contentText">
          {text}
        </StyledContent>
      )}
      <FullscreenTable
        table={`<div class="table-container">${tableHTML}</div>`}
        show={isFullscreenTableOpen}
        closeFullscreen={closeFullscreen}
      />
      {isPreview && tooltipElement}
    </>
  );
};

ContentText.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  mode: PropTypes.string,
  parentIsFullscreen: PropTypes.bool,
  openParentFullscreen: PropTypes.func,
  closeParentFullscreen: PropTypes.func,
  stepId: PropTypes.number,
};

export default withFullscreen(ContentText);
