import { STEP_NEXT_VISIBILITY } from 'global';
import callApi, { callAutomationApi } from 'helpers/apiHelpers';
import { extractBaseData } from 'components/Analytics/recorder';

export function prepareStepNextList(optionsToResolve, localData, serverCallStatuses, serverCallVariables) {
  const stepNextList = optionsToResolve.map(
    ({ id: stepNextId, conditionLocalProperties, conditionServerCallProperties }) => ({
      stepNextId,
      properties: [
        ...conditionLocalProperties.map(name => ({
          name,
          value: localData[name],
          type: 'local',
        })),
        ...conditionServerCallProperties.map(name => {
          let value;
          let type;
          const [prefix, id] = name.split('|');

          if (prefix === 'server-call-variable') {
            value = serverCallVariables[id];
            type = 'server';
          } else if (prefix === 'server-call-status') {
            value = serverCallStatuses[id];
            type = 'server-call-request-status';
          }

          return {
            name,
            value,
            type,
          };
        }),
      ].filter(({ value }) => value !== undefined),
    })
  );
  return stepNextList.length ? stepNextList : undefined;
}

function prepareServerDataList(serverCalls, localData, serverCallVariables) {
  const serverDataList = serverCalls.map(({ id, localPropertyNames, serverPropertyNames }) => ({
    serverCallId: id,
    properties: [
      ...localPropertyNames.map(name => ({
        name,
        value: localData[name],
        type: 'local',
      })),
      ...serverPropertyNames.map(name => ({
        name,
        value: serverCallVariables[name],
        type: 'server',
      })),
    ].filter(({ value }) => value !== undefined),
  }));
  return serverDataList.length ? serverDataList : undefined;
}

function getResolvedOption(results = [], options = []) {
  const resultsMap = Object.fromEntries(results.map(({ stepNextId, result: isResolved }) => [stepNextId, isResolved]));
  return (
    options
      .sort((a, b) => a.ordering - b.ordering) // option with lower ordering has higher priority
      .find(o => resultsMap[o.id]) || null
  );
}

function getVariables(serverCallVariables = {}) {
  return Object.fromEntries(
    Object.entries(serverCallVariables).map(([serverCallVariableId, response]) => {
      let value;
      if (response !== null) {
        value = typeof response === 'object' ? JSON.stringify(response, null, 2) : response;
      }
      return [serverCallVariableId, value];
    })
  );
}

function getStatuses(statuses = {}) {
  return Object.fromEntries(
    Object.entries(statuses).map(([serverCallId, statusObj]) => [serverCallId, statusObj.serverCallStatus])
  );
}

export const runServerCallsAndGetResolvedOption = async ({
  optionList = [],
  serverCalls = [],
  localData = {},
  serverCallStatuses = {},
  serverCallVariables = {},
}) => {
  const optionsToResolve = optionList.filter(o => o.visibility === STEP_NEXT_VISIBILITY.externalCondition);

  if (!optionsToResolve.length && !serverCalls.length) {
    return false;
  }

  const stepNextList = prepareStepNextList(optionsToResolve, localData, serverCallStatuses, serverCallVariables);
  const serverDataList = prepareServerDataList(serverCalls, localData, serverCallVariables);

  try {
    const baseData = await extractBaseData();
    const payload = {
      ...baseData,
      stepNextList,
      serverDataList,
    };
    let data;
    if (serverDataList) {
      const result = await callAutomationApi('v1/automation', 'post', payload, { withCredentials: true });
      data = result.data;
    } else {
      const result = await callApi('v1/widget/condition/resolve', 'post', payload);
      data = result.data;
    }

    const resolvedOption = getResolvedOption(data.conditionResults, optionsToResolve);
    const variables = getVariables(data.responses?.serverCallVariables);
    const statuses = getStatuses(data.responses?.statuses);

    return {
      resolvedOption,
      serverCallVariables: variables,
      serverCallStatuses: statuses,
    };
  } catch {
    return false;
  }
};
