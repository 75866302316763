import { useState, useEffect, useCallback } from 'react';

export default function useRefWidth(ref) {
  const [refWidth, setRefWidth] = useState();

  const calcRefWidth = useCallback(() => {
    if (ref.current) {
      setRefWidth(ref.current.clientWidth);
    }
  }, [ref]);

  useEffect(() => {
    calcRefWidth();
    if (ref.current) {
      window.addEventListener('resize', calcRefWidth);
    }
    return () => {
      window.removeEventListener('resize', calcRefWidth);
    };
  }, [ref, calcRefWidth]);

  return [refWidth];
}
