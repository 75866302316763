import { playerUrl } from 'global/env';
import callApi from 'helpers/apiHelpers';
import { generateSessionId, logout as sendLogoutPostMessage } from 'helpers/statIdsManagement';

export const logout = async () => {
  // cleanup of critical stuff
  localStorage.removeItem('user');
  generateSessionId();
  sendLogoutPostMessage();
  // cleanup of other stuff
  localStorage.removeItem('lastUsedExtensionURL');

  await callApi('v1/auth/logout', 'get');
  window.location.href = playerUrl;
};
