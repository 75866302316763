import React, { useState, useEffect, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import isEqual from 'react-fast-compare';
import { useLocation, withRouter } from 'react-router-dom';
import { imagesUrl } from 'global/env';
import LoginOrOutPlayer from '@playerCommon/Commons/LoginOrOutPlayer/LoginOrOutPlayer';
import { getTitleFontSize } from 'helpers/textManagement.js';
import { getCompletionRate } from '@stonlyCommons/helpers/guidePathHelpers';
import StonlyIconSVG from 'icons/stonlyAmaranthIcon.svg';
import ProgressBar from '@playerCommon/CustomElements/ProgressBar/ProgressBar';
import { navigationWithKeyboardStyle } from '@playerCommon/CommonStyledComponents/NavigationWithKeyboard';
import useUserManagement from '@playerCommon/hooks/useUserManagement';
import { useAdditionalLoadedGuidesData } from '@playerCommon/Contexts/additionalGuideLoaderContext';
import { mergeGuideStates } from 'helpers/guidePlayerHelpers';

const CanvasPlaceholder = styled.div`
  position: relative;
  display: flex;
  flex-shrink: 0;
  margin: 0;
  width: 100%;
  height: 88px;

  @media screen and (max-width: 621px) {
    height: 60px;
  }
`;

const Canvas = styled.div`
  position: relative;
  display: flex;
  margin: 0 auto 0 auto;
  align-items: center;
  width: 100%;
  background: white;
  padding: 24px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.12);
  z-index: 100;
  height: 88px;
  color: ${props => props.theme.slateGrey};
  font-size: 14px;

  @media screen and (max-width: 621px) {
    height: 60px;
  }

  @media screen and (max-width: 899px) {
    position: fixed;
    top: 0;
  }
`;

const Title = styled.h1`
  font-size: ${({ fontSize }) => fontSize}px;
  display: inline-flex;
  max-width: calc(100% - 350px);
  align-items: center;
  color: ${props => props.theme.darkGrey};
  line-height: 1;

  @media screen and (max-width: 621px) {
    font-size: 16px;
    max-width: calc(100% - 80px);
  }
`;

const StonlyIcon = styled(StonlyIconSVG)``;

const TextOverflower = styled.a`
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  line-height: normal;
  outline: none;
  border-radius: 4px;
  transition: box-shadow 0.2s;
  ${navigationWithKeyboardStyle}
`;

const Actions = styled.div`
  margin-left: auto;
  display: flex;
`;

const CompletionCanvas = styled.div`
  position: absolute;
  top: 84px;
  z-index: 1;
  left: 0;
  width: 100%;
  display: none;

  @media screen and (min-width: 900px) {
    ${({ currentStepId, displayProgressBar }) =>
      currentStepId !== -2 &&
      displayProgressBar &&
      css`
        display: flex;
      `}
  }
`;

const CustomIcon = styled.div`
  height: 32px;
  width: 32px;
  background-image: url(${({ url, teamIconSecureHash }) =>
    `${imagesUrl.url}/${url}?w=32&h=32&dpr=1&s=${teamIconSecureHash}`});
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background-image: url(${({ url, teamIconSecureHash }) =>
      `${imagesUrl.url}/${url}?w=32&h=32&dpr=2&s=${teamIconSecureHash}`});
  }
  background-size: cover;
  background-position: center center;
`;

const LogoLink = styled.a`
  margin-right: 20px;
`;

const Logo = styled.div`
  margin-right: 20px;
`;

const MemoizedStaticItems = memo(({ fontSize, teamIcon, teamIconSecureHash, title, isLoggedIn, titleLink }) => {
  const { search } = useLocation();

  return (
    <>
      <Title data-cy="explanationTitle" fontSize={fontSize}>
        {teamIcon ? (
          <Logo>
            <CustomIcon url={teamIcon} teamIconSecureHash={teamIconSecureHash} />
          </Logo>
        ) : (
          <LogoLink href={`/${search}`}>
            <StonlyIcon />
          </LogoLink>
        )}
        <TextOverflower href={titleLink} className="guide-title">
          {title}
        </TextOverflower>
      </Title>
      <Actions>{isLoggedIn && <LoginOrOutPlayer />}</Actions>
    </>
  );
}, isEqual);

MemoizedStaticItems.propTypes = {
  fontSize: PropTypes.number,
  teamIcon: PropTypes.string,
  teamIconSecureHash: PropTypes.string,
  title: PropTypes.string,
  titleLink: PropTypes.string,
  isLoggedIn: PropTypes.bool,
};

const ExplanationTop = ({
  title,
  currentStepId,
  stepsPath,
  displayProgressBar,
  teamIcon,
  teamIconSecureHash,
  guide: guideData,
}) => {
  const { additionalLoadedGuides } = useAdditionalLoadedGuidesData();
  const guide = useMemo(() => mergeGuideStates(guideData, additionalLoadedGuides), [guideData, additionalLoadedGuides]);

  const userManagement = useUserManagement();
  const [currentCompletion, setCompletion] = useState(0);
  const { guideInfo } = guide;

  useEffect(() => {
    if (displayProgressBar) {
      if (currentStepId === -2 || currentStepId === -4) {
        setCompletion(0);
        return;
      }
      setCompletion(getCompletionRate(stepsPath, guide));
    }
  }, [stepsPath]);

  const fontSize = getTitleFontSize(title);

  const isLoggedIn = Object.keys(userManagement.user).length !== 0;

  const titleLink = guideInfo.hasIntroduction ? 'introduction' : String(guideInfo.firstStepId);

  return (
    <CanvasPlaceholder>
      <Canvas>
        <CompletionCanvas currentStepId={currentStepId} displayProgressBar={displayProgressBar}>
          <ProgressBar completion={currentCompletion} />
        </CompletionCanvas>
        <MemoizedStaticItems
          fontSize={fontSize}
          teamIcon={teamIcon}
          teamIconSecureHash={teamIconSecureHash}
          title={title}
          isLoggedIn={isLoggedIn}
          titleLink={titleLink}
        />
      </Canvas>
    </CanvasPlaceholder>
  );
};

ExplanationTop.propTypes = {
  title: PropTypes.string,
  currentStepId: PropTypes.number,
  stepsPath: PropTypes.array,
  displayProgressBar: PropTypes.bool,
  teamIcon: PropTypes.string,
  teamIconSecureHash: PropTypes.string,
  guide: PropTypes.object,
};

export default withRouter(ExplanationTop);
