import { findLastIndex } from 'helpers/arrayManagement';
import { generatePathChainList, isPathStartsWith } from '../helpers';
import {
  resolveFamilyUuidPartition,
  resolveFullPathForFolderUuid,
  resolveRowByUuid,
  resolveActiveUuidList,
  resolvePathForUuid,
  resolveIsPathTemporaryOpened,
  resolveSelectedUuidList,
} from '../resolvers';

export const ACTION = {
  openFolder: 'openFolder',
  closeFolder: 'closeFolder',
  openToRowTemporary: 'openToRowTemporary',
  closeTemporaryOpened: 'closeTemporaryOpened',
  move: 'move',
  setDragging: 'setDragging',
  initialize: 'initialize',
  setActive: 'setActive',
  setHighlightColor: 'setHighlightColor',
  setSelected: 'setSelected',
  expandAll: 'expandAll',
  collapseAll: 'collapseAll',
  insertRowList: 'insertRowList',
};

const DEFAULT_OPEN_PATH_TMP = {};

export const initializeFlatTreeState = ({ rowList, openPath, mainPathName }) => {
  const state = {
    // state part provided directly
    staticOpenedPath: openPath,
    mainPathName,
    // computed state part
    initializationKey: `init:${Math.random()}`, // #docForTreeInitializationKey
    temporaryOpenedPath: DEFAULT_OPEN_PATH_TMP, // one-time open path (no saving in storage)
    highlightColorByUuid: {},
    activeUuidList: [], // a data-level parameter (in FlatTreeProvider state). E.g. active step on a step list
    activePathList: [],
    selectedPathList: [], // its gonna be a tree-instance-level parameter (@TODO - move it to FlatTree state level). E.g. select steps to be deleted
    selectedUuidList: [],
    draggingRowUuid: undefined,
    draggingFolderFullPath: undefined,
    rowUuidList: rowList.map(row => row.uuid),
    rowByUuid: rowList.reduce((acc, curr) => {
      acc[curr.uuid] = curr; // #perf mutation
      return acc;
    }, {}),
  };

  return state;
};

export const reducer = (state, action) => {
  if (action.type === ACTION.initialize) {
    const {
      rowUuidList,
      rowByUuid,
      activeUuidList,
      activePathList,
      mainPathName,
      initializationKey, // #docForTreeInitializationKey
    } = initializeFlatTreeState(action.payload);

    return {
      ...state,
      initializationKey,
      mainPathName,
      rowUuidList,
      rowByUuid,
      activeUuidList,
      activePathList,
    };
  }

  if (action.type === ACTION.setActive) {
    const { uuidList } = action.payload;
    const activeUuidList = Array.isArray(uuidList) ? uuidList : [];

    return {
      ...state,
      activeUuidList,
      activePathList: [...new Set(activeUuidList.map(activeUuid => resolveRowByUuid(state, activeUuid).path))],
    };
  }

  if (action.type === ACTION.setSelected) {
    const { uuidList } = action.payload;
    const selectedUuidList = Array.isArray(uuidList) ? uuidList : [];

    return {
      ...state,
      selectedUuidList,
      selectedPathList: [...new Set(selectedUuidList.map(selectedUuid => resolveRowByUuid(state, selectedUuid).path))],
    };
  }

  if (action.type === ACTION.setHighlightColor) {
    const { colorByUuid = {} } = action.payload;

    return {
      ...state,
      highlightColorByUuid: colorByUuid,
    };
  }

  if (action.type === ACTION.openFolder) {
    const { uuid } = action.payload;
    const fullPath = resolveFullPathForFolderUuid(state, uuid);
    const pathChainList = generatePathChainList(fullPath);

    if (!fullPath) {
      return state; // proably not a folder (should never happen)
    }

    return {
      ...state,
      staticOpenedPath: {
        ...state.staticOpenedPath,
        ...pathChainList.reduce((acc, partialPath) => ({ ...acc, [partialPath]: true }), {}),
      },
    };
  }

  if (action.type === ACTION.closeFolder) {
    const { uuid } = action.payload;
    const fullPath = resolveFullPathForFolderUuid(state, uuid);

    if (!fullPath) {
      return state; // proably not a folder (should never happen)
    }

    return {
      ...state,
      staticOpenedPath: Object.entries(state.staticOpenedPath).reduce((acc, [key, val]) => {
        acc[key] = isPathStartsWith(key, fullPath) ? false : val;
        return acc;
      }, {}),
      temporaryOpenedPath: Object.entries(state.temporaryOpenedPath).reduce((acc, [key, val]) => {
        acc[key] = isPathStartsWith(key, fullPath) ? false : val;
        return acc;
      }, {}),
    };
  }

  if (action.type === ACTION.openToRowTemporary) {
    const { uuid } = action.payload;
    const path = resolvePathForUuid(state, uuid);
    if (!path) {
      return state;
    }

    if (!resolveIsPathTemporaryOpened(state, path)) {
      return {
        ...state,
        temporaryOpenedPath: generatePathChainList(path).reduce((acc, partialPath) => {
          return { ...acc, [partialPath]: true };
        }, {}),
      };
    }
  }

  if (action.type === ACTION.closeTemporaryOpened) {
    return {
      ...state,
      temporaryOpenedPath: DEFAULT_OPEN_PATH_TMP,
    };
  }

  if (action.type === ACTION.replaceRow) {
    const { uuid, insertRowList = [] } = action.payload;

    if (!uuid || state.rowUuidList.indexOf(uuid) === -1) {
      return state;
    }

    const { [uuid]: rowToRemove, ...newRowByUuid } = state.rowByUuid;

    return {
      ...state,
      rowByUuid: {
        ...newRowByUuid,
        ...insertRowList.reduce((acc, rowDef) => {
          return { ...acc, [rowDef.uuid]: rowDef };
        }, {}),
      },
      rowUuidList: state.rowUuidList.reduce((acc, rowUuid) => {
        return rowUuid === uuid ? [...acc, ...insertRowList.map(row => row.uuid)] : [...acc, rowUuid];
      }, []),
      activePathList: [
        ...new Set(resolveActiveUuidList(state).map(activeUuid => resolveRowByUuid(state, activeUuid).path)),
      ],
      selectedPathList: [
        ...new Set(resolveSelectedUuidList(state).map(selectedUuid => resolveRowByUuid(state, selectedUuid).path)),
      ],
    };
  }

  /* DND */

  if (action.type === ACTION.setDragging) {
    const { uuid } = action.payload;

    return {
      ...state,
      draggingUuid: uuid,
      draggingFolderFullPath: resolveFullPathForFolderUuid(state, uuid),
    };
  }
  if (action.type === ACTION.expandAll) {
    return {
      ...state,
      staticOpenedPath: Object.values(state.rowByUuid).reduce((acc, curr) => {
        acc[curr.path] = true; // #perf mutation
        return acc;
      }, {}),
      temporaryOpenedPath: {},
    };
  }
  if (action.type === ACTION.collapseAll) {
    return {
      ...state,
      staticOpenedPath: {},
      temporaryOpenedPath: {},
    };
  }

  if (action.type === ACTION.move) {
    // TODO @Mateusz - probably would be better to have a separate reducers for ACTION.moveBelow and ACTION.moveInto
    const { sourceUuid, targetUuid, placement = 'below' } = action.payload;

    // validation - don't allow for operation on itself
    if (sourceUuid === targetUuid) {
      return state;
    }

    const { isFolder: isSourceFolder, path: sourcePath } = resolveRowByUuid(state, sourceUuid);
    const { isFolder: isTargetFolder, path: targetPath } = resolveRowByUuid(state, targetUuid);

    // validation - don't allow folder to be put inside it's own
    if (isSourceFolder && isPathStartsWith(targetPath, resolveFullPathForFolderUuid(state, sourceUuid))) {
      return state;
    }

    const isMoveBelowAction = placement === 'below'; // otherwise "inside"

    // phase 1 - find path of destination place
    const destinationPath = isMoveBelowAction
      ? /* move below */ targetPath
      : /* move into, so we have a folder */ resolveFullPathForFolderUuid(state, targetUuid);

    // phase 2 - collect ids to be moved (item and it's children)
    const [uuidToMoveList, restUuidList] = resolveFamilyUuidPartition(state, sourceUuid);

    // phase 3 - find REAL item to put row below.
    let anchorIndex;
    if (isMoveBelowAction && isTargetFolder) {
      // If we want to put after a folder, then:
      // it would be after the last descendant of this folder if not empty
      // it would be this folder if empty
      const targetFolderPath = resolveFullPathForFolderUuid(state, targetUuid);
      const restRowPathList = restUuidList.map(uuid => resolveRowByUuid(state, uuid).path);
      const latestDescendantIdx = findLastIndex(restRowPathList, path => isPathStartsWith(path, targetFolderPath));
      anchorIndex = latestDescendantIdx !== -1 ? latestDescendantIdx : restUuidList.indexOf(targetUuid);
    } else {
      anchorIndex = restUuidList.indexOf(targetUuid);
    }

    // phase 4 - update paths in moved items
    const newRowByUuid = {
      ...state.rowByUuid,
      ...uuidToMoveList
        .map(uuid => resolveRowByUuid(state, uuid))
        .reduce((acc, currRow) => {
          acc[currRow.uuid] = {
            ...currRow,
            path: currRow.path.replace(sourcePath, destinationPath),
          };
          return acc;
        }, {}),
    };

    // phase 5 - update array of ids
    const newRowUuidList = restUuidList.reduce((acc, currRow, currIdx) => {
      return currIdx === anchorIndex ? [...acc, currRow, ...uuidToMoveList] : [...acc, currRow];
    }, []);

    // phase 6 - update active paths
    const newActivePathList = [
      ...new Set(resolveActiveUuidList(state).map(activeUuid => resolveRowByUuid(state, activeUuid).path)),
    ];

    // phase 7 - update selected paths
    const newSelectedPathList = [
      ...new Set(resolveSelectedUuidList(state).map(selectedUuid => resolveRowByUuid(state, selectedUuid).path)),
    ];

    return {
      ...state,
      rowByUuid: newRowByUuid,
      rowUuidList: newRowUuidList,
      activePathList: newActivePathList,
      selectedPathList: newSelectedPathList,
    };
  }

  return state;
};
