import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { isIE } from 'helpers/userAgentHelpers';

import { imagesUrl } from 'global/env';
import { convertVideoMediaUrl, isMediaFileVideo } from 'helpers/fileHelpers';

const backgroundCss = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  outline: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ stretch }) =>
    stretch === 'FIT' &&
    css`
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        border-radius: 4px;
      }

      @media screen and (min-width: 900px) {
        ${({ guideOrientation }) =>
          guideOrientation !== 'vertical' &&
          css`
            &:before {
              border-radius: 0;
            }
          `}
      }
    `}
`;

const ImageWrap = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 100%;
  margin-right: 12px;

  ${({ guideOrientation }) =>
    guideOrientation === 'vertical' &&
    css`
      aspect-ratio: ${({ aspectRatioX, aspectRatioY }) => `${aspectRatioX} / ${aspectRatioY}`};
    `}
  }

  ${({ galleryLength }) =>
    galleryLength === 1 &&
    css`
      @media screen and (max-width: 899px) {
        width: calc(100% - 24px);
        aspect-ratio: ${({ aspectRatioX, aspectRatioY }) => `${aspectRatioX} / ${aspectRatioY}`};
        margin-left: 12px;
      }

      @media screen and (min-width: 900px) {
        width: 100%;
      }
    `}

  ${({ galleryLength }) =>
    galleryLength > 1 &&
    css`
      @media screen and (max-width: 899px) {
        width: calc(100% - 64px);
        aspect-ratio: ${({ aspectRatioX, aspectRatioY }) => `${aspectRatioX} / ${aspectRatioY}`};

        &:first-child {
          margin-left: 12px;
        }
      }

      ${({ guideOrientation }) =>
        guideOrientation === 'vertical' &&
        css`
          @media screen and (min-width: 900px) {
            width: calc(100% - 52px);
            aspect-ratio: ${({ aspectRatioX, aspectRatioY }) => `${aspectRatioX} / ${aspectRatioY}`};

            &:last-child {
              margin-right: 0;
            }
          }
        `}

      @media screen and (min-width: 900px) {
        ${({ guideOrientation }) =>
          guideOrientation !== 'vertical' &&
          css`
            width: 100%;
            margin-right: 0;
          `}
      }
    `}
`;

const Picture = styled.picture`
  ${backgroundCss};

  &:before {
    background-color: ${({ backgroundColor }) => backgroundColor};
  }
`;

const ImageIE = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${({ background }) => `url(${background})`};
  background-position: ${({ focalPointX, focalPointY }) => `${focalPointX}% ${focalPointY}%`};
  background-size: cover;
  z-index: 10;
  border-radius: 4px;

  @media screen and (min-width: 900px) {
    ${({ guideOrientation }) =>
      guideOrientation !== 'vertical' &&
      css`
        border-radius: 0;
      `}
  }

  ${({ positionDiff }) =>
    positionDiff !== 0 &&
    css`
      opacity: 0.7;
    `}
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  align-self: center;
  z-index: 10;
  cursor: pointer;
  cursor: zoom-in;
  border-radius: 4px;
  flex-shrink: 0;
  transition: opacity 0.4s;

  ${({ stretch, ext, focalPointX, focalPointY }) =>
    stretch === 'FILL' &&
    ext !== 'svg' &&
    css`
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: ${focalPointX}% ${focalPointY}%;
    `}

  ${({ stretch }) =>
    stretch === 'FIT' &&
    css`
      max-width: calc(100% - 32px);
      max-height: calc(100% - 32px);

      @media screen and (min-width: 900px) {
        max-width: calc(100% - 80px);
        max-height: calc(100% - 80px);
      }
    `}

  @media screen and (min-width: 900px) {
    ${({ guideOrientation }) =>
      guideOrientation !== 'vertical' &&
      css`
        border-radius: 0;
      `}
  }

  ${({ positionDiff }) =>
    positionDiff !== 0 &&
    css`
      opacity: 0.7;
    `}
`;

const VideoBackground = styled.div`
  ${backgroundCss};

  &:before {
    background-color: ${props => props.theme.paleGrey};
  }
`;

const Video = styled.video`
  max-width: calc(100% - 32px);
  max-height: calc(100% - 32px);
  align-self: center;
  cursor: pointer;
  cursor: zoom-in;
  z-index: 10;
  flex-shrink: 0;
  border-radius: 4px;
  transition: opacity 0.4s;

  @media screen and (min-width: 900px) {
    max-width: calc(100% - 80px);
    max-height: calc(100% - 80px);
  }

  ${({ positionDiff }) =>
    positionDiff !== 0 &&
    css`
      opacity: 0.7;
    `}

  &::-internal-media-controls-overlay-cast-button {
    display: none;
  }
`;

const Caption = styled.div`
  position: absolute;
  bottom: 12px;
  left: 12px;
  z-index: 10;
  background-color: ${props => props.theme.white};
  border-radius: 2px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  padding: 2px 4px;
  font-size: 10px;
  line-height: 16px;
  font-weight: 600;
  color: ${props => props.theme.slateGrey};
  max-width: calc(100% - 24px);
`;

function SingleImage({
  url,
  onClick,
  positionDiff,
  isFirst,
  stretch,
  backgroundColor,
  focalPointX,
  focalPointY,
  aspectRatioX = 16,
  aspectRatioY = 9,
  altText,
  caption,
  galleryLength,
  guideOrientation,
  ext,
  loadImagesFromRoot,
}) {
  const isVideo = isMediaFileVideo(url);

  const baseUrl = loadImagesFromRoot ? '' : imagesUrl.url;

  return (
    <ImageWrap
      aspectRatioX={aspectRatioX}
      aspectRatioY={aspectRatioY}
      galleryLength={galleryLength}
      guideOrientation={guideOrientation}
    >
      {isVideo ? (
        <VideoBackground guideOrientation={guideOrientation} stretch={stretch}>
          <Video
            key={url}
            src={convertVideoMediaUrl(`${baseUrl}/${url}`)}
            tabIndex={0}
            onKeyDown={onClick}
            onClick={onClick}
            controls={false}
            playsInline
            autoPlay
            muted
            loop
            guideOrientation={guideOrientation}
            positionDiff={positionDiff}
          />
        </VideoBackground>
      ) : (
        <Picture
          key={url}
          backgroundColor={backgroundColor}
          isFirst={isFirst}
          guideOrientation={guideOrientation}
          stretch={stretch}
        >
          <source media="(max-width: 480px)" srcSet={`${baseUrl}/${url}&w=480&h=960&auto=format&dpr=2`} />
          <source media="(max-width: 768px)" srcSet={`${baseUrl}/${url}&w=768&h=1536&auto=format&dpr=2`} />
          <source media="(max-width: 899px)" srcSet={`${baseUrl}/${url}&w=900&h=1800&auto=format&dpr=1`} />
          <source media="(min-width: 900px)" srcSet={`${baseUrl}/${url}&w=1280&h=1440&auto=format&dpr=1`} />
          {isIE && stretch === 'FILL' ? (
            <ImageIE
              role="img"
              aria-label={altText}
              background={`${baseUrl}/${url}&w=1280&h=1440&auto=format`}
              focalPointX={focalPointX}
              focalPointY={focalPointY}
              guideOrientation={guideOrientation}
              positionDiff={positionDiff}
            />
          ) : (
            <Image
              onKeyDown={onClick}
              tabIndex={0}
              onClick={onClick}
              src={`${baseUrl}/${url}&w=1280&h=1440&auto=format`}
              stretch={stretch}
              focalPointX={focalPointX}
              focalPointY={focalPointY}
              alt={altText}
              guideOrientation={guideOrientation}
              positionDiff={positionDiff}
              ext={ext}
            />
          )}
          {caption && <Caption>{caption}</Caption>}
        </Picture>
      )}
    </ImageWrap>
  );
}

SingleImage.propTypes = {
  url: PropTypes.string,
  onClick: PropTypes.func,
  positionDiff: PropTypes.number,
  isFirst: PropTypes.bool,
  stretch: PropTypes.string,
  backgroundColor: PropTypes.string,
  focalPointX: PropTypes.number,
  focalPointY: PropTypes.number,
  aspectRatioX: PropTypes.string,
  aspectRatioY: PropTypes.string,
  altText: PropTypes.string,
  caption: PropTypes.string,
  galleryLength: PropTypes.number,
  guideOrientation: PropTypes.string,
  ext: PropTypes.string,
  loadImagesFromRoot: PropTypes.bool,
};

export default memo(SingleImage);
