import React, { memo } from 'react';
import styled from 'styled-components';
import StepPreviewImageSVG from 'resources/illustrations/stepPreviewImage.svg';

const NoneWrap = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: transparent;
`;

function None() {
  return (
    <NoneWrap>
      <StepPreviewImageSVG />
    </NoneWrap>
  );
}

export default memo(None);
