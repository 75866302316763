import React, { Component } from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { navigationWithKeyboardStyle } from '@playerCommon/CommonStyledComponents/NavigationWithKeyboard';

const Canvas = styled.div`
  width: 100%;
`;

const Title = styled.label`
  display: block;
  font-size: 12px;
  color: ${props => props.theme.steel};
  font-weight: 500;

  ${({ skin }) =>
    skin === 'bigUnderline' &&
    css`
      font-size: 14px;
      margin-bottom: 8px;
    `};

  ${({ skin }) =>
    skin === 'bordered' &&
    css`
      margin-bottom: 8px;
    `};
`;

const FilteredTextAreaAutosize = ({ invalid, ...props }) => <TextareaAutosize {...props} />;
const StyledTextArea = styled(FilteredTextAreaAutosize)`
  font-family: inherit;
  width: 100%;
  font-size: 16px;
  color: #212121;

  outline: 0;
  border: 0;

  resize: none;
  transition: border-color 0.2s, box-shadow 0.2s;
  padding-left: 0;
  background: transparent;
  appearance: none;
  -webkit-appearance: none;

  ${navigationWithKeyboardStyle}

  ${({ skin }) =>
    skin === 'normal' &&
    css`
      padding: 10px 0;
      font-size: 14px;
      border-bottom: 1px solid #d2d2d2;

      &::placeholder {
        color: ${props => props.theme.lightGrey};
        font-size: 14px;
      }
    `};

  ${({ skin }) =>
    skin === 'big' &&
    css`
      padding: 16px 0;
      font-size: 32px;
      color: ${props => props.theme.darkGrey};

      &::placeholder {
        color: ${props => props.theme.lightGrey};
        font-size: 32px;
      }
    `}

  ${({ skin }) =>
    skin === 'mediumUnderline' &&
    css`
      padding: 12px 0;
      font-size: 20px;
      color: ${props => props.theme.darkGrey};
      border-bottom: 1px solid ${props => props.theme.grey};

      ${({ invalid }) =>
        invalid &&
        css`
          border-bottom-color: ${props => props.theme.errorColor};
        `}

      &:focus {
        border-bottom-color: ${props => props.theme.darkGrey};
      }

      &::placeholder {
        color: ${props => props.theme.lightGrey};
        font-size: 20px;
      }
    `}

  ${({ skin }) =>
    skin === 'bigUnderline' &&
    css`
      padding: 16px 0;
      font-size: 32px;
      color: ${props => props.theme.darkGrey};
      border-bottom: 1px solid ${props => props.theme.grey};

      &:focus {
        border-bottom-color: ${props => props.theme.darkGrey};
      }

      &::placeholder {
        color: ${props => props.theme.silver};
        font-size: 32px;
      }
    `}

  ${({ skin }) =>
    skin === 'grey' &&
    css`
      padding: 10px 12px;
      border-bottom: none;
      background: #f6f7f8;
      border-radius: 4px;

      &::placeholder {
        color: ${props => props.theme.lightGrey};
        font-size: 14px;
      }
    `};

  ${({ skin }) =>
    skin === 'bordered' &&
    css`
      padding: 10px 12px;
      background: white;
      border: 1px solid ${props => props.theme.grey};
      border-radius: 4px;
      transition: border-color 0.2s, color 0.2s, background-color 0.2s;
      font-size: 14px;

      ${({ invalid }) =>
        invalid &&
        css`
          border-color: ${props => props.theme.errorColor};
        `}

      ${({ disabled, theme }) =>
        disabled &&
        css`
          opacity: 1;
          color: ${theme.lightGrey};
          background: ${theme.paleGrey};
        `}

      &::placeholder {
        color: ${props => props.theme.lightGrey};
        font-size: 14px;
      }
    `};
`;

const StyledNormaltextarea = StyledTextArea.withComponent('textarea');

class Textarea extends Component {
  textarea = React.createRef();

  componentDidMount() {
    const { autofocus, select } = this.props;
    if (autofocus || select) {
      setImmediate(() => {
        if (autofocus) {
          try {
            this.textarea.current.focus();
          } catch {
            this.textarea.current.textarea.focus();
          }
        }
        if (select) {
          try {
            this.textarea.current.select();
          } catch {
            this.textarea.current.textarea.select();
          }
        }
      });
    }
  }

  render() {
    const {
      value,
      className,
      onChange = null,
      onBlur,
      onClick = null,
      placeholder = '',
      id = '',
      name = '',
      title,
      skin,
      dataCy,
      row = 1,
      autosize = true,
      maxLength = null,
      wrap = 'soft',
      defaultValue,
      invalid,
      disabled,
      ariaLabel,
    } = this.props;

    return (
      <Canvas className={className}>
        {title && (
          <Title skin={skin} htmlFor={id}>
            {title}
          </Title>
        )}
        {autosize && (
          <StyledTextArea
            innerRef={ref => {
              this.textarea.current = ref;
            }}
            name={name}
            id={id}
            type="text"
            skin={skin}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            onBlur={onBlur}
            onClick={onClick}
            placeholder={placeholder}
            rows={row}
            maxLength={maxLength}
            wrap={wrap}
            invalid={invalid}
            disabled={disabled}
            data-cy={dataCy ? `${dataCy}-styledTextArea` : undefined}
            aria-label={ariaLabel}
          />
        )}
        {!autosize && (
          <StyledNormaltextarea
            ref={this.textarea}
            name={name}
            id={id}
            type="text"
            skin={skin}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            onBlur={onBlur}
            onClick={onClick}
            placeholder={placeholder}
            rows={row}
            maxLength={maxLength}
            wrap={wrap}
            invalid={invalid}
            disabled={disabled}
            data-cy={dataCy ? `${dataCy}-normalTextArea` : undefined}
            aria-label={ariaLabel}
          />
        )}
      </Canvas>
    );
  }
}

Textarea.propTypes = {
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node]),
  skin: PropTypes.string,
  row: PropTypes.number,
  autosize: PropTypes.bool,
  maxLength: PropTypes.number,
  wrap: PropTypes.string,
  autofocus: PropTypes.bool,
  select: PropTypes.bool,
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,
  dataCy: PropTypes.string,
};

export default Textarea;
