import { STEP_NEXT_TYPE, STEP_WIDGET_ACTION_TYPES } from 'global';

import i18n from 'helpers/i18n';
import {
  convertUrlStringToDisplayString,
  convertTemplateStringToDisplayString,
} from '@stonlyCommons/helpers/guideVariableHelpers';
import { parseStepIdNumber } from 'helpers/guideTreeStructure';

import DotSVG from 'icons/dot.svg';
import ZendeskIconSVG from 'icons/smallZendeskIcon.svg';
import IntercomIconSVG from 'icons/smallIntercomIcon.svg';
import FrontIconSVG from 'icons/smallFrontappIcon.svg';
import FreshchatIconSVG from 'icons/smallFreshchatIcon.svg';
import HelpshiftIconSVG from 'icons/smallHelpshiftIcon.svg';
import LivechatIconSVG from 'icons/smallLivechatIcon.svg';
import CrispIconSVG from 'icons/smallCrispIcon.svg';
import HubspotIconSVG from 'icons/smallHubspotIcon.svg';
import SalesforceIconSVG from 'icons/smallSalesforceIcon.svg';
import GorgiasIconSVG from 'icons/smallGorgiasIcon.svg';
import ContactFormIconSVG from 'icons/contactFormIcon.svg';
import CloseWidgetIconSVG from 'icons/smallCloseWidgetIcon.svg';
import KbIconSVG from 'icons/smallKbIcon.svg';
import GuidedTourIconSVG from 'icons/guidedTourSmall.svg';
import StarSVG from 'icons/starIcon.svg';
import ExplanationIconSVG from 'icons/guideHollowIcon.svg';
import TinyCodeIconSVG from 'icons/codeIconTiny.svg';
import ChecklistIconSVG from 'icons/success.svg';
import AutomationIconSVG from 'icons/automation.svg';
import AiAnswerIconSVG from 'icons/aiAnswer.svg';
import LinkSVG from 'icons/link.svg';
import MultipleChoicesMenuSVG from 'icons/multipleChoicesMenu.svg';
import GenesysIconSVG from 'icons/smallGenesysIcon.svg';

const integrationsLogos = {
  'Open Zendesk Chat': ZendeskIconSVG,
  'Open Intercom': IntercomIconSVG,
  'Open Front Chat': FrontIconSVG,
  'Open Freshchat': FreshchatIconSVG,
  'Open Helpshift': HelpshiftIconSVG,
  'Open Livechat': LivechatIconSVG,
  'Open Crisp Chat': CrispIconSVG,
  'Open Hubspot Chat': HubspotIconSVG,
  'Open Salesforce Chat': SalesforceIconSVG,
  'Open Salesforce Messaging for Web Chat': SalesforceIconSVG,
  'Open Gorgias Chat': GorgiasIconSVG,
  'Open Genesys Chat': GenesysIconSVG,
};

const STEP_TYPE_ICON = {
  regular: DotSVG,
  embeddedGuide: ExplanationIconSVG,
  contactForm: ContactFormIconSVG,
  nps: StarSVG,
  crs: StarSVG,
  iframe: TinyCodeIconSVG,
  checklist: ChecklistIconSVG,
  automation: AutomationIconSVG,
  aiAnswer: AiAnswerIconSVG,
};

const SPECIAL_STEP_ACTION_ICON = {
  [STEP_WIDGET_ACTION_TYPES.CLOSE_WIDGET]: CloseWidgetIconSVG,
  [STEP_WIDGET_ACTION_TYPES.KNOWLEDGE_BASE]: KbIconSVG,
  [STEP_WIDGET_ACTION_TYPES.GUIDED_TOUR]: GuidedTourIconSVG,
};

const specialStepTypeNames = {
  [STEP_WIDGET_ACTION_TYPES.CLOSE_WIDGET]: i18n('ExplanationEditNextSteps.WidgetActionsCloseWidget'),
  [STEP_WIDGET_ACTION_TYPES.KNOWLEDGE_BASE]: i18n('ExplanationEditNextSteps.WidgetActionsOpenKnowledgeBase'),
  [STEP_WIDGET_ACTION_TYPES.GUIDED_TOUR]: i18n('ExplanationEditNextSteps.WidgetActionsOpenGuidedTour'),
};

const STATIC_ROW_LABEL = {
  unNamed: i18n('Menu.UnNamed'),
  unknownSpecialStep: i18n('ExplanationEditNextSteps.UnknownSpecialStep'),
  endOfGuide: i18n('ExplanationEditNextSteps.EndExplanation'),
  introduction: i18n('Introduction.Title'),
  interactionChoice: i18n('ExplanationEditNextSteps.UIInteraction'),
  untitledChoice: i18n('Menu.UntitledChoice'),
  untitledQuestion: i18n('ExplanationEditNextSteps.AiAnswerStep.UntitledQuestion'),
};

const DEFAULT_OPTIONS = {
  enableEmbeddedClick: true,
};

export const computeStepsTreeRowDisplayParameters = (rowPayload = {}, options = {}) => {
  const optionsToUse = {
    ...DEFAULT_OPTIONS,
    ...options,
  };
  const {
    title,
    stepId: stepIdString,
    stepType,
    linkUrl,
    actionType,
    hasMultipleChildren,
    isNavigationTypeInteraction,
    isNavigationTypeButton,
  } = rowPayload;

  const stepId = parseStepIdNumber(stepIdString);
  const isEmbeddedGuideNotClickable = stepType === 'embeddedGuide' && !optionsToUse.enableEmbeddedClick;

  // Icon
  let icon = DotSVG;
  if (hasMultipleChildren && stepType === 'regular') {
    icon = MultipleChoicesMenuSVG;
  } else if (STEP_TYPE_ICON[stepType]) {
    icon = STEP_TYPE_ICON[stepType];
  } else if (stepId === STEP_NEXT_TYPE.SPECIAL) {
    icon = SPECIAL_STEP_ACTION_ICON[actionType] || LinkSVG;
  } else if (stepId === STEP_NEXT_TYPE.EXTERNAL_LINK) {
    icon = integrationsLogos[linkUrl] || LinkSVG;
  } else if (stepId === STEP_NEXT_TYPE.AI_QUESTION) {
    icon = STEP_TYPE_ICON.aiAnswer;
  } else if (stepId === STEP_NEXT_TYPE.INTRODUCTION) {
    icon = undefined;
  }

  // label
  let label = title;

  if (stepId === STEP_NEXT_TYPE.INTRODUCTION) {
    label = STATIC_ROW_LABEL.introduction;
  } else if (stepId === STEP_NEXT_TYPE.SPECIAL) {
    label = specialStepTypeNames[actionType] || STATIC_ROW_LABEL.unknownSpecialStep;
  } else if (stepId === STEP_NEXT_TYPE.END_OF_GUIDE) {
    label = STATIC_ROW_LABEL.endOfGuide;
  } else if (stepId === STEP_NEXT_TYPE.EXTERNAL_LINK) {
    label = convertUrlStringToDisplayString(linkUrl);
  } else if (isNavigationTypeInteraction && !isNavigationTypeButton) {
    label = STATIC_ROW_LABEL.interactionChoice;
  } else if (stepId === STEP_NEXT_TYPE.AI_QUESTION) {
    label = title || STATIC_ROW_LABEL.untitledQuestion;
  } else if (!title || title === 'unNamed') {
    label = stepId ? STATIC_ROW_LABEL.unNamed : STATIC_ROW_LABEL.untitledChoice;
  } else {
    label = convertTemplateStringToDisplayString({ content: title, isPreview: true, shouldWrapVarName: true });
  }

  // if not - e.g. "fake" step (like close widget) that are not clickable etc.
  // @Mateusz - please change that name if want to use this function in the player
  let isClickable = false;
  if (isEmbeddedGuideNotClickable) {
    isClickable = false;
  } else if (STEP_TYPE_ICON[stepType]) {
    isClickable = true;
  } else if (stepId === STEP_NEXT_TYPE.INTRODUCTION) {
    isClickable = true;
  }

  let tooltip = '';
  if (isEmbeddedGuideNotClickable) {
    tooltip = i18n('ChecklistEdit.EmbeddedGuideOptionDisabledTooltip');
  }

  return {
    ...rowPayload,
    icon,
    isClickable,
    label,
    tooltip,
  };
};
