import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { getGlobal } from 'global/windowUtils';
import { imagesUrl, resourcesUrl } from 'global/env';
import RightChevronSVG from 'icons/chevronLightRight.svg';
import StonlyLogoSVG from 'resources/logo/helpcenterLogo.svg';
import BackSVG from 'icons/leftThinChevron.svg';
import Link from '@playerCommon/StandardElements/LinkWithQuery/LinkWithQuery';
import ProgressBar from '@playerCommon/CustomElements/ProgressBar';
import { getCompletionRate } from '@stonlyCommons/helpers/guidePathHelpers';
import { navigationWithKeyboardStyle } from '@playerCommon/CommonStyledComponents/NavigationWithKeyboard';
import { getFolderCaption } from 'helpers/helpcenterHelpers';
import { useAdditionalLoadedGuidesData } from '@playerCommon/Contexts/additionalGuideLoaderContext';
import { createSlug, mergeGuideStates } from 'helpers/guidePlayerHelpers';
import {
  useContactFormOptionsChecking,
  openCustomGuideWidget,
  openExternalChat,
  openExternalLink,
  getExternalChatCSS,
} from '../helpers';
import { WIDGET_PLACEMENT_TYPES } from '../const';
import HeaderMenu from '../../components/HeaderMenu';
import ContactForm from '../../components/ContactForm';
import ExternalChat from '../../components/ExternalChat';
import { SearchProvider } from '../../components/SearchBar/searchContext';

const CanvasPlaceholder = styled.div`
  position: relative;
  display: flex;
  margin: 0;
  width: 100%;
  height: 96px;
  flex-shrink: 0;

  @media screen and (max-width: 1024px) {
    height: 60px;
  }
`;

const Canvas = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto 0 auto;
  width: 100%;
  background: white;
  padding: 0 32px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.12);
  z-index: 100;
  height: 96px;
  color: ${props => props.theme.slateGrey};
  font-size: 14px;
  transition: background-color 0.2s, color 0.2s;

  @media screen and (max-width: 1024px) {
    position: fixed;
    top: 0;
    height: 60px;
    padding: 0 24px;

    ${({ menuOpen, headerTextColor, mainColor, theme }) =>
      menuOpen &&
      css`
        background: ${mainColor || 'white'};
        color: ${headerTextColor || theme.slateGrey};
      `}
  }

  @media screen and (max-width: 480px) {
    padding: 0 16px;
  }
`;

const Title = styled.h1`
  display: inline-flex;
  position: absolute;
  font-size: 20px;
  line-height: 40px;
  height: 40px;
  max-width: calc(100% - 48px);
  align-items: center;
  color: ${props => props.theme.darkGrey};
  top: 16px;
  left: 24px;
  transition: opacity 0.2s, visibility 0.2s, transform 0.2s;

  @media screen and (max-width: 1024px) {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    height: 24px;
    top: 18px;
    left: 20px;
    max-width: calc(100% - 12px);
  }
`;

const MobileTitle = styled.div`
  font-size: 28px;
  line-height: 40px;
  font-weight: 600;
  display: block;
  color: ${props => props.theme.darkGrey};
  margin-bottom: 24px;
`;

const MobileBreadcrumbsWrap = styled.div`
  margin-bottom: 24px;
`;

const MobileMenuContentWrap = styled.div`
  border-bottom: 1px solid ${props => props.theme.silver};
  margin-bottom: 24px;

  &:last-child {
    border-bottom: 0;
  }
`;

const TextOverflower = styled.a`
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  padding-right: 4px;
  height: 100%;
  line-height: inherit;
  outline: none;
  border-radius: 4px;
  transition: box-shadow 0.2s;
  ${navigationWithKeyboardStyle}
`;

const Breadcrumbs = styled.div`
  position: relative;
  display: inline-block;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-right: 16px;
`;

const DesktopBreadcrumbs = styled.div`
  position: absolute;
  display: flex;
  top: 56px;
  width: calc(100% - 48px);
  height: 20px;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  height: 26px;
  top: 53px;
  padding-left: 4px;
  margin-left: -4px;
  text-overflow: ellipsis;

  ${Breadcrumbs} {
    white-space: pre;
    margin-right: auto;
    height: 26px;
    padding-top: 3px;
    padding-left: 4px;
    margin-left: -4px;
  }

  &,
  & > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const Crumb = styled.a`
  color: ${props => props.theme.slateGrey};
  margin-left: 8px;
  margin-right: 8px;
  box-shadow: none;
  transition: color 0.2s, box-shadow 0.2s;
  border-radius: 4px;

  &:first-child {
    margin-left: 0;
    /* @noflip */
    [dir='rtl'] & {
      margin-left: 8px;
      margin-right: 0;
    }
  }

  &:last-child {
    margin-right: 0;
    /* @noflip */
    [dir='rtl'] & {
      margin-right: 8px;
      margin-left: 0;
    }
  }

  &:hover {
    color: ${props => props.theme.darkGrey};
  }

  ${navigationWithKeyboardStyle}
`;

const CrumbArrow = styled(RightChevronSVG)`
  width: 8px;
  height: 8px;
  transform: translateY(-1px);

  path {
    fill: ${props => props.theme.steel};
  }

  /* @noflip */
  [dir='rtl'] & {
    transform: translateY(-1px) scaleX(-1);
  }
`;

const LogoLink = styled.a`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  padding-top: 2px;
  font-size: 0;
  line-height: 0;
  transition: opacity 0.2s, visibility 0.2s, transform 0.2s;
  left: 20px;

  @media screen and (min-width: 1025px) {
    display: none;
    left: 24px;
  }
`;

export const StonlyLogo = styled(StonlyLogoSVG)`
  height: 28px;
  width: 155px;
`;

export const Logo = styled.img`
  margin-top: 2px;
  max-height: 36px;
`;

const BackButtonIcon = styled(BackSVG)`
  path {
    fill: ${props => props.theme.highlightColor};
  }

  /* @noflip */
  [dir='rtl'] & {
    transform: scaleX(-1);
  }
`;

const BackButtonLink = styled.a`
  ${navigationWithKeyboardStyle}
  position: absolute;
  left: -12px;
  top: 24px;
  padding: 4px;
  line-height: 0;
  transition: opacity 0.2s, visibility 0.2s, box-shadow 0.2s;

  @media screen and (max-width: 1024px) {
    left: -8px;
    top: 18px;
  }
`;

const Left = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding-left: 24px;
  padding-right: 24px;

  @media screen and (max-width: 1024px) {
    max-width: calc(100% - 80px);
    padding-left: 20px;

    ${({ menuOpen }) =>
      menuOpen
        ? css`
            ${LogoLink} {
              opacity: 1;
              visibility: visible;
              transform: translate(-20px, -50%);
            }
            ${Title} {
              opacity: 0;
              visibility: hidden;
              transform: translate(-20px, 0);
            }
            ${BackButtonLink} {
              opacity: 0;
              visibility: hidden;
            }
          `
        : css`
            ${LogoLink} {
              opacity: 0;
              visibility: hidden;
            }
            ${Title} {
              opacity: 1;
              visibility: visible;
            }
          `}
  }
`;

const CompletionCanvas = styled.div`
  position: absolute;
  top: 92px;
  z-index: 1;
  left: 0;
  width: 100%;
  display: none;

  @media screen and (min-width: 1025px) {
    ${({ currentStepId, displayProgressBar }) =>
      currentStepId !== -2 &&
      displayProgressBar &&
      css`
        display: flex;
      `}
  }
`;

const ExplanationTop = ({
  title,
  currentStepId,
  stepsPath,
  displayProgressBar,
  guide: guideData,
  breadcrumbs = [],
  helpcenterData,
  folderId,
  language,
}) => {
  const { additionalLoadedGuides } = useAdditionalLoadedGuidesData();
  const guide = useMemo(() => mergeGuideStates(guideData, additionalLoadedGuides), [guideData, additionalLoadedGuides]);

  const { guideInfo } = guide;

  const [menuOpen, setMenuOpen] = useState(false);
  const [isContactUsOpen, setIsContactUsOpen] = useState(false);
  const [currentCompletion, setCompletion] = useState(0);

  const onHeaderMenuOpen = useCallback(() => setMenuOpen(true), []);
  const onHeaderMenuClose = useCallback(() => setMenuOpen(false), []);

  const [referrerURL] = useState(getGlobal('referrer'));
  const host = getGlobal('host');

  const referrerIsValid = referrerURL.includes(host) && !referrerURL.includes('/guide/');

  const { knowledgeBase } = helpcenterData;

  const titleLink = guideInfo.hasIntroduction ? 'introduction' : guideInfo.firstStepId;

  const {
    isContactFormEnabled,
    isContactSuggestionsEnabled,
    isContactFormDefault,
    isContactFormCustomGuide,
    isContactFormExternalChat,
    isContactFormExternalLink,
  } = useContactFormOptionsChecking(knowledgeBase);

  const crumbs = (
    <Breadcrumbs>
      {breadcrumbs.map((folder, i) => {
        const folderName = getFolderCaption(folder, language).name;
        const slug = folderName && folder.folderId ? `${createSlug(folderName)}-${folder.folderId}` : '';
        return (
          <React.Fragment key={folder.folderId}>
            <Link component={Crumb} to={`/kb/${language}/${slug}`} rel={folderName ? null : 'noindex'}>
              {folderName || folder.folderId}
            </Link>
            {i !== breadcrumbs.length - 1 && <CrumbArrow />}
          </React.Fragment>
        );
      })}
    </Breadcrumbs>
  );

  const backButtonUrl = useMemo(() => {
    if (folderId === knowledgeBase.rootFolderId) {
      return `/kb/${language}/`;
    }

    const foundBackFolder = breadcrumbs.find(f => f.folderId === folderId);
    if (foundBackFolder) {
      const folderName = getFolderCaption(foundBackFolder, language).name;
      const slug = folderName ? `${createSlug(folderName)}-${foundBackFolder.folderId}` : '';
      return `/kb/${language}/${slug}`;
    }
    return `/kb/${language}/${breadcrumbs.length > 1 ? folderId : ''}`;
  }, [breadcrumbs, folderId, language]);

  useEffect(() => {
    if (displayProgressBar) {
      if (currentStepId === -2 || currentStepId === -4) {
        setCompletion(0);
        return;
      }
      setCompletion(getCompletionRate(stepsPath, guide));
    }
  }, [stepsPath]);

  const handleContactForm = () => {
    if (isContactFormCustomGuide) {
      const { widgetFormat, widgetPlacement, widgetSizeType, adaptWidgetHeight } =
        knowledgeBase.contactFormDisplaySettings;
      openCustomGuideWidget({
        guideId: knowledgeBase.guideId,
        stepId: knowledgeBase.customContactFormFirstStepId,
        widgetFormat,
        widgetPlacement,
        widgetSizeType,
        adaptWidgetHeight,
        language,
      });
    }
    if (isContactFormExternalChat) {
      openExternalChat(knowledgeBase.contactFormDisplaySettings.externalChatType);
    }
    if (isContactFormExternalLink) {
      openExternalLink(knowledgeBase.contactFormDisplaySettings.externalUrl);
    }
  };

  const onContactClose = useCallback(() => setIsContactUsOpen(false), []);
  const onContactOpen = () => (isContactFormDefault ? setIsContactUsOpen(true) : handleContactForm());
  const [isExternalChatOpen, setIsExternalChatOpen] = useState(false);

  return (
    <CanvasPlaceholder>
      {isContactFormExternalChat && !isExternalChatOpen && (
        <style>{getExternalChatCSS(knowledgeBase.contactFormDisplaySettings.externalChatType)}</style>
      )}
      <Canvas menuOpen={menuOpen} headerTextColor={knowledgeBase.headerTextColor} mainColor={knowledgeBase.mainColor}>
        <CompletionCanvas currentStepId={currentStepId} displayProgressBar={displayProgressBar}>
          <ProgressBar completion={currentCompletion} />
        </CompletionCanvas>
        <Left menuOpen={menuOpen}>
          {referrerIsValid ? (
            <BackButtonLink href={referrerURL}>
              <BackButtonIcon />
            </BackButtonLink>
          ) : (
            <Link component={BackButtonLink} to={backButtonUrl}>
              <BackButtonIcon />
            </Link>
          )}
          <Link to="/kb/" component={LogoLink}>
            <Logo
              src={
                knowledgeBase.logo
                  ? `${imagesUrl.url}/${knowledgeBase.logo}?s=${knowledgeBase.logoSecureHash}`
                  : `${resourcesUrl.url}resources/logo/helpcenterLogo.svg`
              }
            />
          </Link>
          <Title data-cy="explanationTitle">
            <TextOverflower href={titleLink} className="guide-title">
              {title}
            </TextOverflower>
          </Title>
          <DesktopBreadcrumbs>{crumbs}</DesktopBreadcrumbs>
        </Left>
        <SearchProvider initialValue="" helpcenterData={helpcenterData} language={language}>
          <HeaderMenu
            onOpen={onHeaderMenuOpen}
            onClose={onHeaderMenuClose}
            onContactClick={onContactOpen}
            helpcenterData={helpcenterData}
            mobileTopContent={
              <MobileMenuContentWrap>
                <MobileTitle>{title}</MobileTitle>
                <MobileBreadcrumbsWrap>{crumbs}</MobileBreadcrumbsWrap>
              </MobileMenuContentWrap>
            }
            mode="explanation"
          />
        </SearchProvider>
      </Canvas>
      {isContactFormDefault && (
        <ContactForm
          suggestionsEnabled={isContactSuggestionsEnabled}
          open={isContactUsOpen}
          onCloseClick={onContactClose}
          isModal={knowledgeBase.contactFormDisplaySettings.widgetPlacement === WIDGET_PLACEMENT_TYPES.MODAL}
        />
      )}
      {isContactFormExternalChat && (
        <ExternalChat
          externalChatType={knowledgeBase.contactFormDisplaySettings.externalChatType}
          onContactOpen={() => setIsExternalChatOpen(true)}
          onContactClose={() => setIsExternalChatOpen(false)}
        />
      )}
    </CanvasPlaceholder>
  );
};

ExplanationTop.propTypes = {
  title: PropTypes.string,
  breadcrumbs: PropTypes.array,
  helpcenterData: PropTypes.object,
  displayProgressBar: PropTypes.bool,
  folderId: PropTypes.number,
  currentStepId: PropTypes.number,
  stepsPath: PropTypes.array,
  stepConnectionList: PropTypes.array,
  language: PropTypes.string,
  guide: PropTypes.object,
};

export default ExplanationTop;
