import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { appUrl, playerUrl } from 'global/env';
import { setCookie, getParsedCookie } from 'global/windowUtils.js';
import { getIds, setPublicUserId, setUserId } from 'helpers/statIdsManagement';
import { setLanguage } from 'helpers/i18n.helpers';
import { sanitizeURL } from 'helpers/sanitizeHelpers';
import usePopup from '@playerCommon/hooks/usePopup';
import { track as recorderTrack, posthogCapture } from 'components/Analytics/recorder';
import withUser from '@playerCommon/HOC/withUser/withUser.js';
import CloseButton from '@playerCommon/CustomElements/CloseButton/CloseButton';
import PlayerPrivateRoute from '@playerCommon/HOC/PlayerPrivateRoute';
import { POSTHOG_EVENT_NAME } from '@stonlyCommons/constants/Authentication.consts';
import { AUTHENTICATION_VIEWS } from './Authentication.consts';
import Registration from './Registration/Registration.js';
import RegistrationWithGoogle from './Registration/RegistrationWithGoogle.jsx';
import SignIn from './SignIn/SignIn.jsx';
import SignInWithSSO from './SignIn/SignInWithSSO.jsx';
import PasswordRecovery from './PasswordRecovery/PasswordRecovery.js';
import ResetPassword from './ResetPassword/ResetPassword';
import ConfirmAccount from './ConfirmAccount/ConfirmAccount';
import AcceptInvitation from './AcceptInvitation/AcceptInvitation';
import Unsubscribe from './Unsubscribe/Unsubscribe';
import ConfirmPassword from './ConfirmPassword/ConfirmPassword';

const Canvas = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`;
const StyledCloseButton = styled(CloseButton)`
  div[role='button'] {
    @media screen and (max-width: 768px) {
      margin-top: 20px;
    }
    @media screen and (max-width: 480px) {
      margin-top: 12px;
    }
  }
`;

const Authentication = ({
  authenticationView: authenticationViewProp,
  className,
  token,
  userManagement,
  history,
  onCloseClick,
  hideClose,
  email,
  firstName,
  lastName,
  funnelId,
  funnelFlow,
}) => {
  const [authenticationView, setAuthenticationView] = useState(authenticationViewProp || AUTHENTICATION_VIEWS.SIGN_IN);
  const [loginEmail, setLoginEmail] = useState(email || '');
  const [isRedirecting, setIsRedirecting] = useState(false);

  const { closePopup } = usePopup();

  const redirectToApp = type => {
    setIsRedirecting(true);
    const parsedCookie = getParsedCookie();
    let url = `${appUrl}/app/general`;
    if (parsedCookie.returnUrl) {
      url = sanitizeURL(parsedCookie.returnUrl);
      setCookie('returnUrl', '', -1);
    }
    if (type === AUTHENTICATION_VIEWS.REGISTRATION) {
      url = `${url}${url.includes('?') ? '&' : '?'}newUser=true`;
    }
    window.location.href = url;
  };

  const handleRegistrationAuthentication = () => {
    if (!token) {
      // all cookies below handle onboarding behavior
      const parsedCookie = getParsedCookie();
      if (typeof parsedCookie.postAuthenticationAction !== 'string') {
        setCookie('freshUser_onboarding_introGuide', 'true');
      }
      setCookie('freshUser_onboarding_guideEdit', 'true');
    }
  };

  const handlePostAuthentication = async ({ res, isRegistration }) => {
    const shouldRedirectToTeamSpace = isRegistration && token && res.data.user.teams?.[0]?.teamId;
    const { userId } = await getIds();
    setUserId(res.data.user.logUserId);
    setPublicUserId(res.data.user.id);
    setLanguage(res.data.user.defaultLanguage, true);
    sessionStorage.removeItem('currentIntent');
    closePopup();

    if (!isRegistration) {
      recorderTrack({
        actionType: 'login',
        actionDetail: {
          previousId: userId,
          currentId: res.data.user.logUserId,
        },
      });
    }

    if (isRegistration) {
      posthogCapture(POSTHOG_EVENT_NAME.USER_CREATED);
      handleRegistrationAuthentication();
    }

    if (shouldRedirectToTeamSpace) {
      window.location.href = `${appUrl}/app/general/${res.data.user.teams[0].teamId}/guides/`;
    }

    if (!isRedirecting) {
      redirectToApp(authenticationView);
    }
    userManagement.setUser(res.data.user);
  };

  useEffect(() => {
    if (userManagement.user && Object.keys(userManagement.user).length !== 0) {
      if (
        token &&
        [AUTHENTICATION_VIEWS.REGISTRATION, AUTHENTICATION_VIEWS.REGISTRATION_WITH_GOOGLE].includes(authenticationView)
      ) {
        window.location.href = `${playerUrl}/authentication/acceptInvitation/${token}?signedIn`;
        // prevent any further action to let handlePostAuthentication handle it correctly
        return;
      }
      if (!isRedirecting) {
        redirectToApp(authenticationView);
      }
    }
  }, [isRedirecting, userManagement, history, authenticationView, token]);

  useEffect(() => {
    if (authenticationViewProp) {
      setAuthenticationView(authenticationViewProp);
    }
  }, [authenticationViewProp]);

  return (
    <Canvas className={className}>
      {!hideClose && onCloseClick && <StyledCloseButton dataCy="closeButton" onClick={onCloseClick} />}
      {authenticationView === AUTHENTICATION_VIEWS.SIGN_IN_WITH_SSO && (
        <SignInWithSSO
          setAuthenticationView={setAuthenticationView}
          setLoginEmail={setLoginEmail}
          loginEmail={loginEmail}
        />
      )}
      {authenticationView === AUTHENTICATION_VIEWS.SIGN_IN && (
        <SignIn
          handlePostAuthentication={handlePostAuthentication}
          setAuthenticationView={setAuthenticationView}
          loginEmail={loginEmail}
        />
      )}
      {authenticationView === AUTHENTICATION_VIEWS.REGISTRATION && (
        <Registration
          handlePostAuthentication={handlePostAuthentication}
          setAuthenticationView={setAuthenticationView}
          token={token}
          email={loginEmail}
          firstName={firstName}
          lastName={lastName}
          funnelFlow={funnelFlow}
          funnelId={funnelId}
        />
      )}
      {authenticationView === AUTHENTICATION_VIEWS.REGISTRATION_WITH_GOOGLE && (
        <RegistrationWithGoogle
          setAuthenticationView={setAuthenticationView}
          handlePostAuthentication={handlePostAuthentication}
          email={loginEmail}
          firstName={firstName}
          lastName={lastName}
          funnelFlow={funnelFlow}
          funnelId={funnelId}
        />
      )}
      {authenticationView === AUTHENTICATION_VIEWS.PASSWORD_RECOVERY && (
        <PasswordRecovery setAuthenticationView={setAuthenticationView} />
      )}
      {authenticationView === AUTHENTICATION_VIEWS.RESET_PASSWORD && (
        <ResetPassword setAuthenticationView={setAuthenticationView} token={token} />
      )}
      {authenticationView === AUTHENTICATION_VIEWS.VERIFY_EMAIL && (
        <ConfirmAccount handlePostAuthentication={handlePostAuthentication} token={token} />
      )}
      {authenticationView === AUTHENTICATION_VIEWS.CONFIRM_PASSWORD && (
        <ConfirmPassword
          setAuthenticationView={setAuthenticationView}
          token={token}
          email={loginEmail}
          funnelId={funnelId}
        />
      )}
      {authenticationView === AUTHENTICATION_VIEWS.UNSUBSCRIBE && <Unsubscribe token={token} />}
      {authenticationView === AUTHENTICATION_VIEWS.ACCEPT_INVITATION && (
        <PlayerPrivateRoute
          path="/authentication/acceptInvitation/:token"
          component={() => <AcceptInvitation token={token} />}
        />
      )}
    </Canvas>
  );
};
Authentication.propTypes = {
  authenticationView: PropTypes.string,
  userManagement: PropTypes.object,
  className: PropTypes.string,
  token: PropTypes.string,
  history: PropTypes.object,
  onCloseClick: PropTypes.func,
  hideClose: PropTypes.bool,
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  funnelId: PropTypes.string,
  funnelFlow: PropTypes.string,
};
export default withUser(Authentication);
