import React, { Fragment, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Markup } from 'interweave';
import Link from '@playerCommon/StandardElements/LinkWithQuery/LinkWithQuery';
import SearchTags from './SearchTags';

import {
  FolderIcon,
  ExplanationIcon,
  ExplanationIconSmall,
  GuidedTourIcon,
  GuidedTourIconSmall,
  PadlockIcon,
  StepIcon,
  ResultsRow,
  ResultsIcon,
  ResultsBreadcrumbIcon,
  ResultsContent,
  ResultsTitle,
  ResultsBreadcrumbs,
  ResultsTitleWrap,
  ResultsHighlight,
  Crumb,
  ContainerRow,
  AIPromptIcon,
} from './SearchResults.styles';

const iconMap = {
  folder: <FolderIcon />,
  step: <StepIcon />,
  ai: <AIPromptIcon />,
};

const guideIconMap = {
  GUIDE: <ExplanationIcon />,
  GUIDED_TOUR: <GuidedTourIcon />,
};

const smallGuideIconMap = {
  guide: <ExplanationIconSmall />,
  guidedTour: <GuidedTourIconSmall />,
};

const contentTextHighlighted = highlight => {
  if (!highlight) return '';
  const foundBs = /<b>.*<\/b>/gi.exec(highlight);
  let searchPosition = 0;
  let endPosition = 0;
  if (foundBs) {
    searchPosition = foundBs.index;
    endPosition = foundBs.index + foundBs[0].length;
  } else {
    return highlight;
  }

  let returnPhrase = '';
  if (searchPosition - 20 > 0) {
    returnPhrase = `...${returnPhrase}`;
  }
  returnPhrase += highlight.slice(Math.max(searchPosition - 20, 0), endPosition + 20).trim();
  if (endPosition + 20 < highlight.length) {
    returnPhrase += '...';
  }

  return returnPhrase;
};

const ALLOWED_TAG_LIST = ['b'];

const SearchResultRow = ({
  result,
  isHighlighted,
  shouldOpenInNewTab,
  shouldShowHighlight,
  disableBreadcrumbs,
  itemUrlFormatter,
  base,
  onResultClickProxy,
  roundedTags,
}) => {
  const rowRef = useRef();
  const linkRef = useRef();

  useEffect(() => {
    if (isHighlighted) {
      rowRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });

      const onKeyDown = e => {
        if (e.code === 'Enter') {
          e.preventDefault();
          e.stopPropagation();
          linkRef.current.click();
        }
      };

      window.addEventListener('keydown', onKeyDown);
      return () => window.removeEventListener('keydown', onKeyDown);
    }
  }, [isHighlighted]);

  const shouldShowTag = shouldShowHighlight && result.highlight?.tags && result.highlight?.tags.length > 0;
  return (
    <ContainerRow role="listitem" ref={rowRef}>
      <Link
        to={itemUrlFormatter(result, base)}
        component={ResultsRow}
        isHighlighted={isHighlighted}
        data-cy="resultsRow"
        onClick={() => {
          onResultClickProxy(result);
        }}
        target={shouldOpenInNewTab ? '_blank' : '_self'}
        rel="noopener noreferrer"
        innerRef={linkRef}
      >
        <ResultsIcon>{iconMap[result.type] || guideIconMap[result.guideType]}</ResultsIcon>
        <ResultsContent>
          <ResultsTitleWrap>
            <ResultsTitle data-cy="resultsTitle">
              <Markup
                allowList={ALLOWED_TAG_LIST}
                content={shouldShowHighlight ? result.highlight?.name || result.name : result.name}
              />
              {result.isRestricted && <PadlockIcon />}
            </ResultsTitle>
            {shouldShowTag && <SearchTags tags={result.highlight?.tags} roundedTags={roundedTags} />}
          </ResultsTitleWrap>

          {shouldShowHighlight && (
            <ResultsHighlight>
              <Markup allowList={ALLOWED_TAG_LIST} content={contentTextHighlighted(result.highlight?.content)} />
            </ResultsHighlight>
          )}
          {!disableBreadcrumbs && (
            <ResultsBreadcrumbs data-cy="resultsBreadcrumbs">
              {result.breadcrumbs?.map((crumb, index) => {
                if (crumb.type === 'folder') {
                  return (
                    <Fragment key={crumb.folderId}>
                      <Crumb>{crumb.name}</Crumb>
                      {index === result.breadcrumbs.length - 1 ? '' : <ResultsBreadcrumbIcon />}
                    </Fragment>
                  );
                }
                return (
                  <Fragment key={crumb.name + crumb.type}>
                    {smallGuideIconMap[crumb.type]}
                    <Crumb>{crumb.name}</Crumb>
                    {index === result.breadcrumbs.length - 1 ? '' : <ResultsBreadcrumbIcon />}
                  </Fragment>
                );
              })}
            </ResultsBreadcrumbs>
          )}
        </ResultsContent>
      </Link>
    </ContainerRow>
  );
};

SearchResultRow.propTypes = {
  result: PropTypes.object,
  isHighlighted: PropTypes.bool,
  shouldOpenInNewTab: PropTypes.bool,
  shouldShowHighlight: PropTypes.bool,
  disableBreadcrumbs: PropTypes.bool,
  roundedTags: PropTypes.bool,
  itemUrlFormatter: PropTypes.func,
  base: PropTypes.object,
  onResultClickProxy: PropTypes.func,
};

export default SearchResultRow;
