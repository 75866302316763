import React, { useState, useCallback, useMemo } from 'react';
import callApi from 'helpers/apiHelpers';
import { getIds } from 'helpers/statIdsManagement';
import { uuidv4 } from '@stonlyCommons/helpers/randomValues';

export const FILE_UPLOAD_STATUS = {
  PENDING: 'pending',
  UPLOADED: 'uploaded',
  ERROR: 'error',
};

const StepAttachmentsContext = React.createContext();

// Temporary id that is sent to the server in order to validate if the file upload size is reached
const stepTemporaryUploadIdByStepId = {};

function StepAttachmentsProvider({ children }) {
  const [attachmentDataByInputId, setAttachmentDataByInputId] = useState({});

  const uploadAttachment = useCallback(async ({ inputId, fileToUpload, stepId } = {}) => {
    if (!stepTemporaryUploadIdByStepId[stepId]) {
      stepTemporaryUploadIdByStepId[stepId] = uuidv4();
    }

    setAttachmentDataByInputId(prev => ({
      ...prev,
      [inputId]: { fileToUpload, status: FILE_UPLOAD_STATUS.PENDING },
    }));

    const { sessionId } = await getIds();
    const formData = new FormData();
    formData.append('selectedFile', fileToUpload, fileToUpload.name);
    formData.append('sessionId', sessionId);
    formData.append('stepTemporaryUploadId', stepTemporaryUploadIdByStepId[stepId]);
    formData.append('stepModuleId', inputId);

    return callApi(`v1/step/automationServerCall/file`, 'post', formData)
      .then(result => {
        setAttachmentDataByInputId(prev => ({
          ...prev,
          [inputId]: {
            ...prev[inputId],
            status: FILE_UPLOAD_STATUS.UPLOADED,
            fileRemoteName: result.data.filename,
          },
        }));

        return result.data.filename;
      })
      .catch(() => {
        setAttachmentDataByInputId(prev => ({
          ...prev,
          [inputId]: { ...prev[inputId], status: FILE_UPLOAD_STATUS.ERROR },
        }));
        return false;
      });
  }, []);

  const cancelAttachment = useCallback(
    inputId => {
      if (attachmentDataByInputId[inputId]) {
        setAttachmentDataByInputId(prev => Object.fromEntries(Object.entries(prev).filter(([key]) => key !== inputId)));
      }
    },
    [attachmentDataByInputId]
  );

  const value = useMemo(
    () => ({
      attachmentDataByInputId,
      uploadAttachment,
      cancelAttachment,
      isAttachmentUploading: Object.values(attachmentDataByInputId).some(
        data => data?.status === FILE_UPLOAD_STATUS.PENDING
      ),
    }),
    [attachmentDataByInputId, uploadAttachment, cancelAttachment]
  );

  return <StepAttachmentsContext.Provider value={value}>{children}</StepAttachmentsContext.Provider>;
}

function useStepAttachments() {
  const context = React.useContext(StepAttachmentsContext);
  if (context === undefined) {
    throw new Error('useStepAttachments must be used within a StepAttachmentsContext');
  }
  return context;
}

export { StepAttachmentsProvider, useStepAttachments };
