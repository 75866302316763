const addLiveChatListeners = () => {
  LiveChatWidget.on('visibility_changed', data => {
    if (data.visibility === 'minimized') {
      LiveChatWidget.call('hide_greeting');
      LiveChatWidget.call('hide');
    }
  });
};

export const initializeLiveChat = isWidget => {
  LiveChatWidget.call('hide_greeting');
  LiveChatWidget.call('hide');
  if (!isWidget) addLiveChatListeners();
};
