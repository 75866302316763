import React from 'react';
import styled, { useTheme } from 'styled-components';
import PropTypes from 'prop-types';
import TextLink from '@playerCommon/StandardElements/TextLink';
import { secondsToMinutesAndSeconds } from 'helpers/dateManagement.js';
import Icon from '@playerCommon/StandardElements/Icon';
import { assetsUrl } from 'global/env.js';
import { useTranslation } from 'react-i18next';

const Canvas = styled.div``;

const Title = styled.div`
  text-transform: capitalize;
  font-size: 20px;
`;

const Info = styled.div`
  margin-top: 8px;
  display: flex;
  gap: 16px;
  color: ${props => props.theme.lightGrey};
`;

const SubInfo = styled.div`
  display: flex;
`;

const Text = styled.div`
  font-size: 12px;
  font-weight: 500;
  margin-left: 4px;
`;

const AiConversationLink = styled(TextLink)`
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${props => props.theme.slateGrey};
  margin-top: 32px;
`;

const KbMainView = ({ sessionData, setView }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const getTotalTime = () => {
    const startDate = sessionData.aiConversation[0].creationDate;
    const endDate = sessionData.aiConversation[sessionData.aiConversation.length - 1].creationDate;
    const totalTime = endDate - startDate;
    return secondsToMinutesAndSeconds(totalTime);
  };

  const getTotalNumberOfQuestions = () => {
    const questionLength = sessionData.aiConversation.filter(
      interaction => interaction.actionType === 'semanticSearchCompleted'
    ).length;

    return `${questionLength} ${
      questionLength === 1 ? t('SessionDetail.SingleQuestion') : t('SessionDetail.MultipleQuestions')
    }`;
  };

  return (
    <Canvas>
      <Title data-cy="mainViewTitle">{sessionData.title}</Title>
      <Info>
        <SubInfo>
          <Icon src={`${assetsUrl}resources/icons/timerSmall.svg`} size={16} color={theme.defaultGreen} />
          <Text>{getTotalTime()}</Text>
        </SubInfo>
        <SubInfo>
          <Icon src={`${assetsUrl}resources/icons/chat-bubble-16.svg`} size={16} color={theme.defaultGreen} />
          <Text>{getTotalNumberOfQuestions()}</Text>
        </SubInfo>
      </Info>
      <AiConversationLink onClick={() => setView(`conversationView-${sessionData.sourceId}`)}>
        {t('SessionDetail.ShowConversation')}
      </AiConversationLink>
    </Canvas>
  );
};

KbMainView.propTypes = {
  sessionData: PropTypes.object,
  setView: PropTypes.func,
};

export default KbMainView;
