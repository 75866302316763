import React, { Suspense, lazy } from 'react';
import Loader, { FullscreenLoaderWrap } from '@playerCommon/CustomElements/Loader/Loader';

const TableOfContentsFlowchart = lazy(() =>
  import(/* webpackChunkName: "TableOfContentsFlowchart" */ './TableOfContentsFlowchart')
);

const renderLoadingIndicator = () => (
  <FullscreenLoaderWrap>
    <Loader type="circle" />
  </FullscreenLoaderWrap>
);

export function TableOfContentsFlowchartAsyncWrapper(props) {
  const isSSR = typeof window === 'undefined';

  return isSSR ? null : (
    <Suspense fallback={renderLoadingIndicator()}>
      <TableOfContentsFlowchart {...props} />
    </Suspense>
  );
}
