import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { dynamicSort } from 'helpers/arrayManagement.js';
import Comment from './Comment/Comment.js';

const Canvas = styled.div`
  margin-top: 56px;

  & > div > div {
    margin: 0 0 32px -48px;
    border: none;
    padding-left: 0px;
  }
`;

const CommentCanvas = styled.div`
  margin: 0 0 32px 48px;
`;

const CurrentComments = ({ stepComments, userManagement, updateStepComments, access }) => {
  const commentsPath = () => {
    const pathForComments = [];

    const stepCommentsTop = stepComments.filter(comment => comment.parentId === null);

    const commentsSubPath = (levelComments, subPath) => {
      levelComments.forEach(parsedComment => {
        const subLevelComments = stepComments.filter(subComment => subComment.parentId === parsedComment.stepCommentId);

        if (subLevelComments.length > 0) {
          // eslint-disable-next-line no-param-reassign
          parsedComment.children = [];
          commentsSubPath(subLevelComments, parsedComment.children);
        } else {
          // eslint-disable-next-line no-param-reassign
          parsedComment.children = subLevelComments;
        }
        subPath.push(parsedComment);
      });
    };

    commentsSubPath(stepCommentsTop, pathForComments);

    return pathForComments;
  };

  const commentsStructure = path => {
    const structureForComments = [];

    // key for every array elements
    let keyforComments = 0;

    const commentsSubStructure = (subPath, structure) => {
      const subStructure = [];
      subPath.sort(dynamicSort('commentDate'));

      subPath.forEach(comment => {
        subStructure.push(
          <Comment
            key={keyforComments}
            item={comment}
            userManagement={userManagement}
            updateStepComments={updateStepComments}
            access={access}
          />
        );
        keyforComments += 1;

        if (comment.children.length > 0) {
          commentsSubStructure(comment.children, subStructure);
        }
      });

      structure.push(<CommentCanvas key={keyforComments}>{subStructure}</CommentCanvas>);
      keyforComments += 1;
    };

    commentsSubStructure(path, structureForComments);

    return structureForComments;
  };

  const pathForComments = commentsPath();
  const structureForComments = commentsStructure(pathForComments);

  return <Canvas>{structureForComments}</Canvas>;
};

CurrentComments.propTypes = {
  updateStepComments: PropTypes.func,
  userManagement: PropTypes.object,
  stepComments: PropTypes.array,
  access: PropTypes.number,
};

export default CurrentComments;
