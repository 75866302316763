/* eslint-disable unicorn/no-useless-undefined */

/** Just for storing simple STRINGs for a while */
const PERSIST_PREFIX = `--persist${Math.random()}`; // by default different prefix for every load/refresh

const awhilePersistor = {
  save: (key, val, prefix = PERSIST_PREFIX /* define prefix to keep between refreshes */) => {
    sessionStorage.setItem(prefix + key, val);
  },
  readNumber: (key, prefix = PERSIST_PREFIX) => {
    return Number(sessionStorage.getItem(prefix + key));
  },
  readString: (key, prefix = PERSIST_PREFIX) => {
    const result = sessionStorage.getItem(prefix + key);
    if (typeof result === 'string') {
      return result;
    }
    return undefined; // don't want Null to be returned
  },
  readBoolean: (key, prefix = PERSIST_PREFIX) => {
    if (sessionStorage.getItem(prefix + key) === 'true') {
      return true;
    }
    if (sessionStorage.getItem(prefix + key) === 'false') {
      return false;
    }
    // else
    return undefined;
  },
  readObject: (key, prefix = PERSIST_PREFIX) => {
    const dataStr = sessionStorage.getItem(prefix + key);
    if (!dataStr) {
      return undefined;
    }
    let data;
    try {
      data = JSON.parse(dataStr);
    } catch {
      /* nottin */
    }
    return data;
  },
  remove: (key, prefix = PERSIST_PREFIX) => {
    sessionStorage.removeItem(prefix + key);
  },
};

export default awhilePersistor;
