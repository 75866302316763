import React, { useContext, useMemo } from 'react';
import { FlatTreeContext } from '../FlatTreeProvider';
import { resolveInitializationKey } from '../resolvers';

/**
    Returns an initialization key for the tree.
    A parameter that if changed, means that the tree state was reinitialized
    (changed from the outside - e.g. guide step name has changed).

    Can be used to trigger some additional behavior.
    E.g. it is used to remount react-virtuoso
    def#docForTreeInitializationKey
*/
export const useTreeInitializationKey = () => {
  const { state } = useContext(FlatTreeContext);

  const initializationKey = resolveInitializationKey(state);

  return useMemo(() => initializationKey, [initializationKey]);
};
