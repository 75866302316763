/* eslint-disable no-undef */
import React, { useEffect, useRef, useCallback } from 'react';
import scriptLoader from 'react-async-script-loader';
import PropTypes from 'prop-types';
import { clientId } from 'global/env';
import { posthogCapture } from 'components/Analytics/recorder';
import { POSTHOG_EVENT_NAME, FUNNEL_STEP } from '@stonlyCommons/constants/Authentication.consts';
import { FUNNEL_FLOW_TYPE } from '../Authentication.consts';
import { GoogleButtonSignIn } from './GoogleAuth.styles';
import useGoogleAuth from './useGoogleAuth';

const GoogleSignIn = ({ isScriptLoaded, isScriptLoadSucceed, containerWidth, funnelId, handlePostAuthentication }) => {
  const googleButtonRef = useRef(null);
  const { handleCredentialResponse } = useGoogleAuth({ funnelId });

  const handleLoad = useCallback(() => {
    google.accounts.id.initialize({
      client_id: clientId,
      callback: response => {
        posthogCapture(POSTHOG_EVENT_NAME.SIGN_IN_FUNNEL, {
          funnelId,
          flow: FUNNEL_FLOW_TYPE.GOOGLE,
          step: FUNNEL_STEP.CLICK_LOGIN,
        });
        handleCredentialResponse(response, handlePostAuthentication);
      },
    });

    google.accounts.id.renderButton(googleButtonRef.current, {
      size: 'large',
      width: containerWidth || 352,
      text: 'signin_with',
      shape: 'circle',
      logo_alignment: 'center',
    });
  }, [containerWidth]);

  useEffect(() => {
    if (isScriptLoaded && isScriptLoadSucceed) {
      handleLoad();
    }
  }, [isScriptLoaded, isScriptLoadSucceed, handleLoad]);

  return <GoogleButtonSignIn ref={googleButtonRef} data-cy="googleButtonSignIn" />;
};

GoogleSignIn.propTypes = {
  isScriptLoaded: PropTypes.bool,
  isScriptLoadSucceed: PropTypes.bool,
  containerWidth: PropTypes.number,
  handlePostAuthentication: PropTypes.func,
  funnelId: PropTypes.string,
};

export default scriptLoader('https://accounts.google.com/gsi/client')(GoogleSignIn);
