import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { getTempStorage, setTempStorage } from 'helpers/storage';
import chevronLeftSVG from 'resources/icons/chevronLeftFilled12px.svg';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { onKeyPressToOnClick } from 'helpers/a11y';

const BackIcon = styled(chevronLeftSVG)`
  margin-right: 4px;
  transition: transform 0.2s;
  path {
    fill: ${({ theme }) => theme.slateGrey};
  }
`;

const BackToSpecialStepWrapper = styled(motion.div)`
  align-items: center;
  color: ${({ theme }) => theme.slateGrey};
  cursor: pointer;
  display: flex;

  margin-top: ${props => props.theme.specialStepBackButton.marginTop};
  margin-bottom: ${props => props.theme.specialStepBackButton.marginBottom};

  @media screen and (max-width: 899px) {
    margin-top: ${props => props.theme.specialStepBackButton.subDesktop.marginTop};
    margin-bottom: ${props => props.theme.specialStepBackButton.subDesktop.marginBottom};

    ${({ hasNoIllustration, theme }) =>
      hasNoIllustration &&
      css`
        margin-top: ${theme.specialStepBackButton.subDesktop.noIllustration.marginTop};
        margin-bottom: ${theme.specialStepBackButton.subDesktop.noIllustration.marginBottom};
      `}
  }

  @media screen and (max-width: 480px) {
    margin-top: ${props => props.theme.specialStepBackButton.mobile.marginTop};
    margin-bottom: ${props => props.theme.specialStepBackButton.mobile.marginBottom};

    ${({ hasNoIllustration, theme }) =>
      hasNoIllustration &&
      css`
        margin-top: ${theme.specialStepBackButton.mobile.noIllustration.marginTop};
        margin-bottom: ${theme.specialStepBackButton.mobile.noIllustration.marginBottom};
      `}
  }

  width: fit-content;
  outline: none;

  body.navigating-with-keyboard &:focus {
    box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 4px ${props => props.theme.turquoise};
    border-radius: 4px;
  }

  @media screen and (max-width: 899px) {
    padding: 0 ${({ theme }) => theme.stepsContentPaddings.contentPadding}px;
  }

  &:hover ${BackIcon} {
    transform: translateX(-4px);
  }
`;

const BackToSpecialStepContent = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
`;

const BackToSpecialStep = ({ onClick, dataCy, text, hasNoIllustration }) => {
  const { t } = useTranslation();
  const shouldAnimate = typeof window !== 'undefined' && !getTempStorage('isBackToSpecialStepAnimated');
  const animate = { opacity: 1, y: 0, height: 'auto' };
  const initial = shouldAnimate ? { opacity: 0, y: 8, height: 0 } : animate;

  return (
    <BackToSpecialStepWrapper
      initial={initial}
      animate={animate}
      onAnimationComplete={() => setTempStorage('isBackToSpecialStepAnimated', true)}
      onClick={onClick}
      onKeyPress={onKeyPressToOnClick(onClick)}
      dataCy={dataCy}
      tabIndex="0"
      hasNoIllustration={hasNoIllustration}
      role="button"
      aria-label={text}
    >
      <BackIcon />
      <BackToSpecialStepContent>{text || t('Global.Back')}</BackToSpecialStepContent>
    </BackToSpecialStepWrapper>
  );
};

BackToSpecialStep.propTypes = {
  onClick: PropTypes.func,
  dataCy: PropTypes.string,
  text: PropTypes.string,
  hasNoIllustration: PropTypes.bool,
};

export default BackToSpecialStep;
