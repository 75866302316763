import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CONTACT_FORM_STEPS } from 'global';
import i18n, { getLanguageShorthand } from 'helpers/i18n';
import callApi from 'helpers/apiHelpers';

import Button from '@playerCommon/StandardElements/Button';
import Loader from '@playerCommon/CustomElements/Loader';
import { navigationWithKeyboardStyle } from '@playerCommon/CommonStyledComponents/NavigationWithKeyboard';
import ExplanationIconSVG from 'icons/explanationBigIcon.svg';
import { useHistory } from 'react-router-dom';
import { createSlug } from 'helpers/guidePlayerHelpers';
import { Title, ContactFormFooter, ContactFormBody } from '../ContactForm.styles';

const FoundArticles = styled.div`
  display: block;
`;

const FoundArticle = styled.a`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  margin-left: -12px;
  padding-right: 24px;
  width: calc(100% + 24px);
  transition: background-color 0.2s, box-shadow 0.2s;
  box-shadow: none;
  border-radius: 4px;

  &:hover {
    background-color: ${props => props.theme.paleGrey};
  }

  ${navigationWithKeyboardStyle}
`;

const ExplanationIcon = styled(ExplanationIconSVG)`
  height: 48px;
  width: 48px;
  flex-shrink: 0;

  path {
    fill: ${props => props.theme.highlightColor};
  }
`;

const ExplanationName = styled.div`
  font-size: 16px;
  line-height: 24px;
  padding-left: 8px;
`;

const LoaderWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const SolutionsTitle = styled(Title)`
  font-size: 20px;
  line-height: 28px;
`;

function SuggestedSolutions({ setStep, searchQuery }) {
  const history = useHistory();
  const [foundResults, setFoundResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const currentLanguage = getLanguageShorthand();

  useEffect(() => {
    async function getSearchResults() {
      if (searchQuery.length < 3) {
        setStep(CONTACT_FORM_STEPS.CONTACT_FORM);
        return;
      }
      const {
        data: { results, total },
      } = await callApi(`v1/knowledgebase/contact/search?search=${searchQuery}&language=${currentLanguage}`, 'GET');
      if (total > 0) {
        setLoading(false);
        setFoundResults(results.slice(0, 5));
      } else {
        setStep(CONTACT_FORM_STEPS.CONTACT_FORM);
      }
    }
    getSearchResults();
  }, []);

  const onContactSupport = () => {
    setStep(CONTACT_FORM_STEPS.CONTACT_FORM);
  };

  return loading ? (
    <ContactFormBody>
      <LoaderWrap>
        <Loader />
      </LoaderWrap>
    </ContactFormBody>
  ) : (
    <>
      <ContactFormBody>
        <SolutionsTitle>{i18n('HelpcenterContact.WeFoundHelpfulArticles')}</SolutionsTitle>
        <FoundArticles>
          {foundResults.map(result => (
            <FoundArticle
              key={result.guideId}
              href={history.createHref({
                pathname: `/kb/guide/${currentLanguage}/${createSlug(result.name)}-${result.guideId}/Steps/${
                  result.firstStepId
                }`,
              })}
              target="_blank"
            >
              <ExplanationIcon />
              <ExplanationName>{result.name}</ExplanationName>
            </FoundArticle>
          ))}
        </FoundArticles>
      </ContactFormBody>
      <ContactFormFooter>
        <Button
          background="outlineAmaranth"
          mouseover="highlight"
          onClick={onContactSupport}
          dataCy="nextStepButton"
          content={i18n('HelpcenterContact.ContactSupport')}
        />
      </ContactFormFooter>
    </>
  );
}

SuggestedSolutions.propTypes = {
  setStep: PropTypes.func,
  searchQuery: PropTypes.string,
};

export default SuggestedSolutions;
