import React from 'react';
import T from 'prop-types';

import {
  RowConnectionContainer,
  ContentWrap,
  ConnectionIconWrap,
  Content,
  FolderIcon,
  RowCanvas,
  ConditionalNextStepIcon,
} from './styles';
import { buildLeftGapStyleForLevel } from '../helpers';

export const RowConnection = React.memo(
  ({ isOpen, toggleFolderOpen, level, label, hasActiveInside, hasSelectedInside, isUnlinked, isConditional }) => {
    return (
      <RowCanvas isUnlinked={isUnlinked}>
        <RowConnectionContainer
          isSelected={hasSelectedInside}
          isActive={hasActiveInside}
          style={buildLeftGapStyleForLevel(level)}
          onClick={toggleFolderOpen}
        >
          <ConnectionIconWrap isOpen={isOpen}>
            <FolderIcon />
          </ConnectionIconWrap>
          <ContentWrap>
            <Content data-cy="connectionTitle">
              <span>{label}</span>
              {isConditional ? <ConditionalNextStepIcon /> : null}
            </Content>
          </ContentWrap>
        </RowConnectionContainer>
      </RowCanvas>
    );
  }
);

RowConnection.propTypes = {
  isOpen: T.bool,
  toggleFolderOpen: T.func.isRequired,
  level: T.number,
  label: T.string,
  hasActiveInside: T.bool,
  hasSelectedInside: T.bool,
  isUnlinked: T.bool,
  isConditional: T.bool,
};
