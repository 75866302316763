import React from 'react';
import PropTypes from 'prop-types';
import { ItemTextWrapper, ItemText, ItemTextDiv, ChevronIcon } from './Checklist.styles';

const ChecklistItemLabel = ({ title, showChevronIcon, onClick, onKeyDown, tabIndex, checkboxId }) => (
  <ItemTextWrapper
    className="item-text-wrapper"
    onClick={onClick}
    onKeyDown={onKeyDown}
    tabIndex={tabIndex}
    htmlFor={checkboxId}
  >
    <ItemTextDiv>
      <ItemText className="item-text">{title}</ItemText>
    </ItemTextDiv>
    {showChevronIcon && <ChevronIcon className="chevron-icon" />}
  </ItemTextWrapper>
);

ChecklistItemLabel.propTypes = {
  title: PropTypes.string,
  showChevronIcon: PropTypes.bool,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checkboxId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ChecklistItemLabel;
