import styled, { css } from 'styled-components';
import StepSVG from 'icons/stepIcon.svg';
import FolderSVG from 'icons/folder-outline-16.svg';
import GuideSVG from 'icons/explanationIcon.svg';
import SearchSVG from 'icons/search.svg';
import ChatIconSVG from 'icons/chat-bubble-16.svg';

const icon = css`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  margin-right: 8px;
`;

export const StepIcon = styled(StepSVG)`
  ${icon};
  path {
    fill: ${props => props.theme.amaranth};
  }
`;

export const GuideIcon = styled(GuideSVG)`
  ${icon};
`;

export const FolderIcon = styled(FolderSVG)`
  ${icon};

  path {
    fill: ${props => props.theme.skyBlue};
  }
`;

export const ChatIcon = styled(ChatIconSVG)`
  ${icon};
  path {
    fill: ${props => props.theme.periwinkle};
  }
`;

export const SearchIcon = styled(SearchSVG)`
  ${icon};

  path {
    fill: ${props => props.theme.seaweed};
  }
`;
