import styled, { css } from 'styled-components';
import BaseButton from './BaseButton';
import ButtonWithTooltip from './ButtonWithTooltip';

const secondaryButtonStyle = css`
  text-transform: uppercase;
  transition: color 0.2s, background-color 0.2s;

  ${({ theme }) => css`
    & svg path {
      fill: ${theme.slateGrey};
    }

    border: 1px solid ${theme.grey};
    background: ${theme.white};
    color: ${theme.slateGrey};

    &:hover,
    &:active {
      background: ${theme.paleGrey};
    }
  `}
`;

export const SecondaryButton = styled(BaseButton)`
  ${secondaryButtonStyle};
`;

export const SecondaryButtonSmall = styled(BaseButton)`
  ${secondaryButtonStyle};
  padding: 6px 12px;
  height: 24px;
  font-size: 12px;
  font-weight: 600;
`;

export const LargeSecondaryButton = styled(BaseButton)`
  ${secondaryButtonStyle};
  height: 44px;
`;

export const LargeSecondaryIconButton = styled(BaseButton)`
  ${secondaryButtonStyle};
  padding: 0;
  height: 44px;
  width: 44px;

  & svg {
    width: 16px;
    height: 16px;
  }

  & svg path {
    transition: fill 0.2s;
    fill: ${props => props.theme.slateGrey};
  }
`;

export const SecondaryIconButton = styled(ButtonWithTooltip)`
  ${secondaryButtonStyle};
  padding: 0;
  height: 36px;
  width: 36px;

  & svg {
    width: 16px;
    height: 16px;
  }

  & svg path {
    transition: fill 0.2s;
    fill: ${props => props.theme.slateGrey};
  }
`;
