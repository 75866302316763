/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { guideTypeMap } from 'global';
import i18n from 'helpers/i18n';
import Loader from '@playerCommon/CustomElements/Loader/Loader';
import SearchResultRow from './SearchResultRow';

import {
  Canvas,
  SearchEmptyWrap,
  SearchEmptyIcon,
  SearchEmptyText,
  ResultsWrap,
  ResultsTitleSep,
  ResultsMoreContainer,
  ResultsMore,
  SearchIcon,
  ContactButton,
} from './SearchResults.styles';

const getItemUrl = (item, base = {}) => {
  switch (item.type) {
    case 'folder': {
      if (!base.folder) {
        return `/app/general/-1/guides/${item.folderId}`;
      }
      return `${base.folder}/${item.folderId}/`;
    }
    case 'guide': {
      if (!base.guide) {
        return `/app/${guideTypeMap[item.guideType]}/${item.guideId}/editor/`;
      }
      return `${base.guide}/${item.guideId}/`;
    }
    case 'explanation': {
      if (!base.guide) {
        return `/app/${guideTypeMap[item.guideType]}/${item.guideId}/editor/`;
      }
      return `${base.guide}/${item.guideId}/`;
    }
    case 'step': {
      if (!base.step) {
        return `/app/${guideTypeMap[item.guideType]}/${item.guideId}/editor/${item.stepId}`;
      }
      return `${base.step}/${item.guideId}/${item.stepId}`;
    }
    default: {
      return '';
    }
  }
};

const typeMap = {
  guide: i18n('Search.Guide'),
  folder: i18n('Search.Folder'),
  step: i18n('Search.Step'),
  ai: i18n('Search.AIGeneratedAnswer'),
};

function SearchResults({
  searchPhrase,
  results,
  loading,
  base = {},
  showSearchEmpty = true,
  showLengthTooShort = true,
  itemUrlFormatter = getItemUrl,
  mode,
  onShowAllResultsClick,
  shouldOpenInNewTab,
  allResultsLength,
  shouldShowHighlight = false,
  enableKeyboardNavigation = false,
  disableBreadcrumbs = false,
  roundedTags = true,
  onResultClick,
  resultTypeToNameMap = typeMap,
  onContactFormClick,
  kbContactButtonText,
  kbResultsNotFoundDropdownText,
}) {
  const [highlightedResult, setHighlightedResult] = useState(null);
  const isEmpty = !searchPhrase && results.length === 0;
  const tooShort = searchPhrase.length < 3 && !isEmpty;
  const noResults = !!searchPhrase && results.length === 0 && !loading && !tooShort;
  const hasMoreResults = allResultsLength > results.length;
  const hasNoResultsButHasHiddenResults = hasMoreResults && results.length === 0;

  let noSearchResultLabel = i18n('Search.SearchEmpty');
  if (noResults) {
    noSearchResultLabel = kbResultsNotFoundDropdownText || i18n('Search.SearchNoResults');
  } else if (tooShort) {
    noSearchResultLabel = i18n('Search.SearchTooShort');
  }

  const onResultClickProxy = result => {
    if (typeof onResultClick === 'function') {
      onResultClick(result);
    }
  };

  const handleKeyDown = e => {
    if (e.key === ' ' || e.key === 'Enter') {
      onShowAllResultsClick();
    }
  };

  useEffect(() => {
    if (results.length === 0) {
      setHighlightedResult(null);
    }
  }, [results]);

  useEffect(() => {
    const onKeyDown = e => {
      if (e.code === 'ArrowDown') {
        e.preventDefault();
        if (highlightedResult === null) {
          setHighlightedResult(0);
        } else {
          setHighlightedResult(highlightedResult < results.length - 1 ? highlightedResult + 1 : 0);
        }
      }

      if (e.code === 'ArrowUp') {
        e.preventDefault();
        if (highlightedResult === null) {
          setHighlightedResult(0);
        } else {
          setHighlightedResult(highlightedResult === 0 ? results.length - 1 : highlightedResult - 1);
        }
      }
    };

    if (enableKeyboardNavigation && results.length > 0) {
      document.addEventListener('keydown', onKeyDown, false);
    } else {
      setHighlightedResult(null);
    }
    return () => {
      document.removeEventListener('keydown', onKeyDown, false);
    };
  }, [highlightedResult, results.length, enableKeyboardNavigation]);

  return (
    <Canvas>
      {((showSearchEmpty && isEmpty) || noResults || (showLengthTooShort && tooShort)) &&
        !hasNoResultsButHasHiddenResults && (
          <SearchEmptyWrap mode={mode} showAllResults={onShowAllResultsClick}>
            {!onContactFormClick && <SearchEmptyIcon />}
            <SearchEmptyText data-cy="noSearchLabel" isFixedWidth={!kbResultsNotFoundDropdownText}>
              {noSearchResultLabel}
            </SearchEmptyText>
            {onContactFormClick && (
              <ContactButton
                background="outlineAmaranth"
                mouseover="highlight"
                type="text"
                onClick={onContactFormClick}
                dataCy="nextStepButton"
                content={kbContactButtonText || i18n('HelpcenterHome.ContactUs')}
                className="contact-us"
              />
            )}
          </SearchEmptyWrap>
        )}
      {loading && <Loader monochrome />}
      {results.length > 0 && !loading && (
        <ResultsWrap role="list">
          {results.map((result, indexRes) => (
            <React.Fragment key={`${result.folderId + result.name + result.guideId + result.type + result.stepId}`}>
              {(indexRes === 0 || results[indexRes - 1].type !== result.type) && (
                <ResultsTitleSep className={`results-title ${result.type}`}>
                  {resultTypeToNameMap[result.type]}
                </ResultsTitleSep>
              )}
              <SearchResultRow
                result={result}
                isHighlighted={highlightedResult === indexRes}
                shouldOpenInNewTab={shouldOpenInNewTab}
                shouldShowHighlight={shouldShowHighlight}
                disableBreadcrumbs={disableBreadcrumbs}
                roundedTags={roundedTags}
                itemUrlFormatter={itemUrlFormatter}
                base={base}
                onResultClickProxy={onResultClickProxy}
              />
            </React.Fragment>
          ))}
        </ResultsWrap>
      )}
      {hasMoreResults && !loading && onShowAllResultsClick && (
        <ResultsMoreContainer
          tabIndex={0}
          onKeyDown={handleKeyDown}
          onClick={onShowAllResultsClick}
          noTopBorder={noResults}
        >
          <ResultsMore>
            <SearchIcon />
            {i18n('Search.SearchAllResults', { results: allResultsLength })}
          </ResultsMore>
        </ResultsMoreContainer>
      )}
    </Canvas>
  );
}

SearchResults.propTypes = {
  searchPhrase: PropTypes.string,
  results: PropTypes.array,
  loading: PropTypes.bool,
  showSearchEmpty: PropTypes.bool,
  showLengthTooShort: PropTypes.bool,
  base: PropTypes.object,
  itemUrlFormatter: PropTypes.func,
  mode: PropTypes.string,
  allResultsLength: PropTypes.number,
  onShowAllResultsClick: PropTypes.func,
  shouldOpenInNewTab: PropTypes.bool,
  shouldShowHighlight: PropTypes.bool,
  roundedTags: PropTypes.bool,
  enableKeyboardNavigation: PropTypes.bool,
  disableBreadcrumbs: PropTypes.bool,
  onResultClick: PropTypes.func,
  resultTypeToNameMap: PropTypes.object,
  onContactFormClick: PropTypes.func,
  kbContactButtonText: PropTypes.string,
  kbResultsNotFoundDropdownText: PropTypes.string,
};

export default memo(SearchResults);
