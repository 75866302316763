import React, { useState, useEffect, memo, useRef } from 'react';
import PropTypes from 'prop-types';
import i18n from 'helpers/i18n';
import { CONTACT_FORM_STEPS } from 'global';
import { onKeyPressToOnClick } from 'helpers/a11y';

import Intro from './Intro';
import SuggestedSolutions from './SuggestedSolutions';
import Form from './Form';
import ThankYou from './ThankYou';

import {
  Canvas,
  ContactFormHeader,
  ContactFormHeaderLeft,
  BackButton,
  ContactFormHeaderText,
  CloseButton,
  ModalWrap,
  CornerWrap,
  Backdrop,
} from './ContactForm.styles';

function ContactForm({
  open,
  suggestionsEnabled = true,
  onCloseClick,
  monochromeHeader = false,
  closeAsArrow = false,
  isModal = false,
}) {
  const { INTRO, SUGGESTED_SOLUTIONS, CONTACT_FORM, THANK_YOU } = CONTACT_FORM_STEPS;
  const [step, setStep] = useState(INTRO);
  const [backVisible, setBackVisible] = useState(closeAsArrow);
  const [question, setQuestion] = useState('');
  const [hideNode, setHideNode] = useState(!open);
  const timeoutRef = useRef();

  const handleCloseClick = () => {
    onCloseClick();
    if (step === THANK_YOU) {
      setTimeout(() => {
        setQuestion('');
        setStep(INTRO);
      }, 200);
    }
  };

  useEffect(() => {
    let visible = [SUGGESTED_SOLUTIONS, CONTACT_FORM].includes(step);
    if (closeAsArrow && [INTRO, THANK_YOU].includes(step)) visible = true;
    setBackVisible(visible);
  }, [step]);

  useEffect(() => {
    clearTimeout(timeoutRef.current);
    if (open) {
      setHideNode(!open);
    } else {
      timeoutRef.current = setTimeout(() => setHideNode(!open), 210);
    }
  }, [open]);

  const handleBackClick = () => {
    if ([INTRO, THANK_YOU].includes(step) && closeAsArrow) handleCloseClick();
    if (step === SUGGESTED_SOLUTIONS) setStep(INTRO);
    if (step === CONTACT_FORM) setStep(INTRO);
  };

  const Wrap = isModal ? ModalWrap : CornerWrap;

  return (
    (open || !hideNode) && (
      <>
        {isModal && <Backdrop open={!hideNode && open} />}
        <Wrap open={!hideNode && open}>
          <Canvas open={!hideNode && open} isModal={isModal}>
            <ContactFormHeader monochromeHeader={monochromeHeader}>
              <ContactFormHeaderLeft>
                <BackButton
                  tabIndex={backVisible ? '0' : '-1'}
                  onClick={handleBackClick}
                  visible={backVisible}
                  onKeyPress={onKeyPressToOnClick(handleCloseClick)}
                />
                <ContactFormHeaderText backVisible={backVisible}>
                  {i18n('HelpcenterContact.ContactUs')}
                </ContactFormHeaderText>
              </ContactFormHeaderLeft>
              {!closeAsArrow && (
                <CloseButton
                  tabIndex="0"
                  role="button"
                  aria-label={i18n('Global.Close')}
                  onClick={handleCloseClick}
                  onKeyPress={onKeyPressToOnClick(handleCloseClick)}
                />
              )}
            </ContactFormHeader>
            {step === INTRO && (
              <Intro
                suggestionsEnabled={Boolean(suggestionsEnabled)}
                setStep={setStep}
                question={question}
                setSearchQuery={setQuestion}
              />
            )}
            {step === SUGGESTED_SOLUTIONS && <SuggestedSolutions setStep={setStep} searchQuery={question} />}
            {step === CONTACT_FORM && <Form setStep={setStep} question={question} setBackVisible={setBackVisible} />}
            {step === THANK_YOU && <ThankYou />}
          </Canvas>
        </Wrap>
      </>
    )
  );
}

ContactForm.propTypes = {
  open: PropTypes.bool,
  onCloseClick: PropTypes.func,
  suggestionsEnabled: PropTypes.bool,
  monochromeHeader: PropTypes.bool,
  closeAsArrow: PropTypes.bool,
  isModal: PropTypes.bool,
};

export default memo(ContactForm);
