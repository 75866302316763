import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import WarningSVG from 'icons/warning.svg';
import InfoSVG from 'icons/tip.svg';
import TickSVG from 'icons/tickMedium.svg';
import CloseSVG from 'icons/close.svg';

export const WARNING_TYPES = {
  WARNING: 'warning',
  INFO: 'info',
  SUCCESS: 'success',
  ERROR: 'error',
};

export const IconWrapper = styled.div`
  width: 16px;
  height: 16px;
  flex-shrink: 0;

  svg {
    height: inherit;
    width: inherit;
  }
`;

export const Content = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.darkGrey};

  ul {
    padding-left: 24px;
    margin-bottom: 0;
    margin-top: 12px;

    li {
      margin-bottom: 4px;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  padding: 16px 12px;
  border-radius: 4px;

  ${({ severity }) =>
    severity === WARNING_TYPES.WARNING &&
    css`
      background: rgba(254, 190, 49, 0.05);
    `}

  ${({ severity }) =>
    severity === WARNING_TYPES.SUCCESS &&
    css`
      background: rgba(24, 201, 142, 0.1);
    `}

  ${({ severity }) =>
    severity === WARNING_TYPES.INFO &&
    css`
      background: ${props => props.theme.washedBlue};
    `}

  ${({ severity }) =>
    severity === WARNING_TYPES.ERROR &&
    css`
      background: rgba(251, 92, 69, 0.1);
      ${Content} {
        font-weight: 500;
      }
    `}

  ${IconWrapper} + ${Content} {
    margin-left: 16px;
  }

  & a {
    text-decoration: underline;
    -webkit-text-decoration-color: ${props => props.theme.darkGrey};
    text-decoration-color: ${props => props.theme.darkGrey};
  }
`;

const StyledWarningSVG = styled(WarningSVG)`
  path {
    fill: ${({ theme }) => theme.mango};
  }
`;

const StyledInfoSVG = styled(InfoSVG)`
  path {
    fill: ${({ theme }) => theme.lightblue};
  }
`;

const StyledErrorSVG = styled(WarningSVG)`
  path {
    fill: ${({ theme }) => theme.deleteColor};
  }
`;

const StyledTickSVG = styled(TickSVG)`
  path {
    fill: ${({ theme }) => theme.seaweed};
  }
`;

const StyledCloseSVG = styled(CloseSVG)`
  margin-left: auto;
  cursor: pointer;
  flex-shrink: 0;
  path {
    fill: ${({ theme }) => theme.lightGrey};
    transition: fill 0.2s;
  }
  &:hover {
    path {
      fill: ${props => props.theme.steel};
    }
  }
`;

const getIcon = severity => {
  let icon;
  if (severity === WARNING_TYPES.WARNING) {
    icon = <StyledWarningSVG />;
  }
  if (severity === WARNING_TYPES.INFO) {
    icon = <StyledInfoSVG />;
  }
  if (severity === WARNING_TYPES.ERROR) {
    icon = <StyledErrorSVG />;
  }
  if (severity === WARNING_TYPES.SUCCESS) {
    icon = <StyledTickSVG />;
  }
  return icon ? <IconWrapper>{icon}</IconWrapper> : null;
};

const Alert = ({
  className,
  children,
  severity = WARNING_TYPES.WARNING,
  dataCy,
  onCloseClick,
  shouldShowIcon = true,
}) => {
  return (
    <Wrapper className={className} severity={severity}>
      {shouldShowIcon && getIcon(severity)}
      <Content className="alert-content" data-cy={dataCy}>
        {children}
      </Content>
      {typeof onCloseClick === 'function' && <StyledCloseSVG onClick={onCloseClick} />}
    </Wrapper>
  );
};

Alert.propTypes = {
  severity: PropTypes.oneOf(Object.values(WARNING_TYPES)),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
  shouldShowIcon: PropTypes.bool,
  className: PropTypes.string,
  dataCy: PropTypes.string,
  onCloseClick: PropTypes.func,
};

export default Alert;
