import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import i18n from 'helpers/i18n';
import { STEP_SEPARATOR } from 'global';

import DropdownBack from '@playerCommon/CustomElements/DropdownBack/DropdownBack.js';
import Step from '../components/Step/Step.js';
import ConversationView from '../ConversationView/ConversationView.js';

const Canvas = styled.div``;

const StepsContainer = styled.div`
  margin-top: 24px;
  padding-top: 4px;
  overflow: scroll;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  & {
    -ms-overflow-style: none;
  }
  & {
    overflow: -moz-scrollbars-none;
  }
`;

const StepView = ({ steps, stepPath, setView, guideId, goToPlayer, view }) => {
  let previousStepPath = '';

  const [selectedStep, setSelectedStep] = useState();

  const closeConversationView = () => {
    setSelectedStep(null);
  };

  return (
    <Canvas>
      {selectedStep ? (
        <ConversationView
          aiSearchInteractionList={selectedStep.aiSearchInteractionList}
          allAiSearchInteractionList={selectedStep.allAiSearchInteractionList}
          setView={closeConversationView}
        />
      ) : (
        <Canvas>
          <DropdownBack backFunction={setView} location="mainView" />
          <StepsContainer>
            {steps
              .slice()
              .reverse()
              .map((step, index) => {
                const circleType =
                  step.link.length < previousStepPath.length || index === stepPath.length - 1 ? 'forward' : 'backward';
                previousStepPath = step.link;

                let type = '';
                let contentToShow = '';
                let inputToShow = '';

                if (step.input) {
                  inputToShow = `${step.input} `;
                }

                if (step.choiceLabel) {
                  type = 'choice';
                  contentToShow += step.choiceLabel;
                } else {
                  type = 'next';
                  contentToShow = circleType === 'forward' ? i18n('Global.Next') : i18n('Global.Back');
                }

                let order = '';
                if (index === 0) {
                  order = 'first';
                  contentToShow = i18n('SessionDetail.LastVisitedStep');
                } else if (index === steps.length - 1) {
                  order = 'last';
                }

                // finding the right path to load.
                const instanceStepPath = stepPath.slice();
                instanceStepPath.splice(-index, index);
                const instanceStepPathForUrl = instanceStepPath.join(STEP_SEPARATOR);

                return (
                  <Step
                    key={`${step.timing}-${step.stepId}`}
                    guideId={guideId}
                    stepPathForUrl={instanceStepPathForUrl}
                    stepId={step.stepId}
                    inputToShow={inputToShow}
                    title={step.title}
                    content={contentToShow}
                    timing={step.timing}
                    type={type}
                    order={order}
                    goToPlayer={goToPlayer}
                    circleType={circleType}
                    view={view}
                    selectStep={() =>
                      setSelectedStep({
                        aiSearchInteractionList: step.aiSearchInteractionList,
                        allAiSearchInteractionList: step.allAiSearchInteractionList,
                      })
                    }
                    hasAiSearchInteraction={!!step.aiSearchInteractionList}
                    aiQuestionLength={
                      step.aiSearchInteractionList?.filter(
                        interaction => interaction.actionType === 'aiAnswerSearchCompleted'
                      ).length
                    }
                  />
                );
              })}
          </StepsContainer>
        </Canvas>
      )}
    </Canvas>
  );
};

StepView.propTypes = {
  steps: PropTypes.array,
  setView: PropTypes.func,
  stepPath: PropTypes.array,
  guideId: PropTypes.string,
  goToPlayer: PropTypes.func,
  view: PropTypes.string,
};

export default StepView;
