import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import useMediaQuery from '@playerCommon/hooks/useMediaQuery';
import useGuideData from '@playerCommon/hooks/playerHooks/useGuideData';
import Illustration from './Illustration/Illustration';

const Canvas = styled.div`
  display: none;
  overflow: hidden;
  position: relative;
  width: 100%;
  background: transparent;

  @media screen and (min-width: 900px) {
    display: block;
    width: 50%;
    height: auto;
    @supports (height: initial) {
      height: initial;
    }
    border-right: 1px solid ${props => props.theme.silver};
  }
`;

const Backgrounds = styled.div`
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.paleGrey};
`;

const BgLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: ${({ background }) => background};
  opacity: 0;
  transition: opacity 0.2s;
  ${({ active }) =>
    active &&
    css`
      opacity: 1;
    `};
`;

const colorsMap = {
  code: '#26282e',
  image: 'transparent',
  none: '#f8f8f9',
  video: '#f8f8f9',
  icon: '#f8f8f9',
};

function getColor(currentStepInfo) {
  const currentIllustrationType = currentStepInfo.illustrationType;
  let currentImageBg = colorsMap[currentIllustrationType];
  if (currentIllustrationType === 'icon') {
    currentImageBg = currentStepInfo.stepIllustrations[0]?.backgroundColor;
  }

  return currentImageBg;
}

function Illustrations({ stepsToShowInfo, scrollableSteps, currentStepId, mode, encrypted, scrollable }) {
  const { loadImagesFromRoot } = useGuideData();

  const matchesIllustrationMediaQuery = useMediaQuery('(min-width: 900px)', true);

  if (!scrollable && stepsToShowInfo.length === 1 && stepsToShowInfo[0].illustrationType === 'none') {
    return null;
  }

  return (
    <Canvas data-cy="illustrations" mode={mode}>
      {matchesIllustrationMediaQuery && (
        <>
          <Backgrounds>
            {stepsToShowInfo.map(stepInfo => (
              <BgLayer
                background={getColor(stepInfo)}
                active={stepInfo.stepId === currentStepId}
                key={stepInfo.stepId}
              />
            ))}
          </Backgrounds>
          {stepsToShowInfo.map(stepInfo => (
            <Illustration
              key={stepInfo.stepId}
              stepId={stepInfo.stepId}
              stepIllustrations={stepInfo.stepIllustrations}
              illustrationType={stepInfo.illustrationType}
              mode={mode}
              encrypted={encrypted}
              scrollable={scrollable}
              currentStepId={currentStepId}
              scrollableSteps={scrollableSteps}
              loadImagesFromRoot={loadImagesFromRoot}
            />
          ))}
        </>
      )}
    </Canvas>
  );
}

Illustrations.propTypes = {
  stepsToShowInfo: PropTypes.array,
  scrollableSteps: PropTypes.array,
  currentStepId: PropTypes.number,
  mode: PropTypes.string,
  encrypted: PropTypes.bool,
  scrollable: PropTypes.bool,
};

export default Illustrations;
