import React, { useEffect, useCallback, useState, memo } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import { useSwipeable } from 'react-swipeable';
import withFullscreen from '@playerCommon/HOC/withFullscreen';
import { navigationWithKeyboardStyle } from '@playerCommon/CommonStyledComponents/NavigationWithKeyboard';
import { getImageUrl } from 'helpers/fileHelpers';
import ChevronSVG from 'icons/chevronThinRight.svg';
import { checkColorLuma, blendColors } from 'helpers/colorHelpers';
import i18n from 'helpers/i18n';
import {
  fullscreenEnabled,
  isFullscreen,
  addOnFullscreenChangeListener,
  removeOnFullscreenChangeListener,
} from '@playerCommon/helpers/windowManagement';
import { isMobileSafari, getIsMobileIntegration } from 'helpers/userAgentHelpers.js';
import { imagesUrl } from 'global/env';
import FullscreenImage from '../FullscreenImage';

import SingleImage from './SingleImage';

const Canvas = styled.div`
  height: 100%;
`;

const Gallery = styled.div`
  display: flex;
  height: 100%;
  transition: transform 0.4s;

  ${({ currentImage, galleryLength }) =>
    css`
      ${galleryLength === 2 &&
      currentImage === 1 &&
      css`
        transform: translateX(calc(-100% + 92px));
        /* @noflip */
        [dir='rtl'] & {
          transform: translateX(calc(100% - 92px));
        }
      `}

      ${galleryLength === 3 &&
      currentImage === 1 &&
      css`
        transform: translateX(calc(-100% + 72px));
        /* @noflip */
        [dir='rtl'] & {
          transform: translateX(calc(100% - 72px));
        }
      `}

    ${galleryLength === 3 &&
      currentImage === 2 &&
      css`
        transform: translateX(calc(-200% + 144px));
        /* @noflip */
        [dir='rtl'] & {
          transform: translateX(calc(200% - 144px));
        }
      `}
    `}

  @media screen and (min-width: 900px) {
    ${({ guideOrientation, currentImage, galleryLength }) =>
      guideOrientation === 'vertical' &&
      css`
        ${galleryLength === 2 &&
        currentImage === 1 &&
        css`
          transform: translateX(calc(-100% + 91px));
          /* @noflip */
          [dir='rtl'] & {
            transform: translateX(calc(100% - 91px));
          }
        `}

        ${galleryLength === 3 &&
        currentImage === 1 &&
        css`
          transform: translateX(calc(-100% + 65px));
          /* @noflip */
          [dir='rtl'] & {
            transform: translateX(calc(100% - 65px));
          }
        `}

        ${galleryLength === 3 &&
        currentImage === 2 &&
        css`
          transform: translateX(calc(-200% + 131px));
          /* @noflip */
          [dir='rtl'] & {
            transform: translateX(calc(200% - 131px));
          }
        `}
      `}
  }

  @media screen and (min-width: 900px) {
    ${({ guideOrientation, currentImage, galleryLength }) =>
      guideOrientation !== 'vertical' &&
      css`
        ${galleryLength === 2 &&
        currentImage === 1 &&
        css`
          transform: translateX(-100%);
          /* @noflip */
          [dir='rtl'] & {
            transform: translateX(-100%);
          }
        `}

        ${galleryLength === 3 &&
        currentImage === 1 &&
        css`
          transform: translateX(-100%);
          /* @noflip */
          [dir='rtl'] & {
            transform: translateX(-100%);
          }
        `}

        ${galleryLength === 3 &&
        currentImage === 2 &&
        css`
          transform: translateX(-200%);
          /* @noflip */
          [dir='rtl'] & {
            transform: translateX(-200%);
          }
        `}
      `}
  }
`;

const SubCanvas = styled.div`
  display: flex;
  height: 100%;
  transition: transform 0.4s;
  transform: translate3d(1, 1, 1);

  ${({ added, removed, modified }) =>
    (added || removed || modified) &&
    css`
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
        z-index: 10;
        border: 4px solid ${props => props.theme.changesAddedColorSolid};
      }

      &:before {
        content: '';
        display: flex;
        position: absolute;
        left: 0px;
        top: 0px;
        height: 24px;
        padding: 0 8px;
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        align-items: center;
        color: ${props => props.theme.white};
        background-color: ${props => props.theme.changesAddedColorSolid};
        border-bottom-right-radius: 4px;
        z-index: 11;
      }
    `}

  ${({ added, removed, modified }) => {
    if (added) {
      return css`
        &:after {
          border-color: ${props => props.theme.changesAddedColorSolid};
        }
        &:before {
          content: '${i18n('Versions.ImageAdded')}';
          background-color: ${props => props.theme.changesAddedColorSolid};
        }
      `;
    }
    if (removed) {
      return css`
        &:after {
          border-color: ${props => props.theme.changesRemovedColorSolid};
        }
        &:before {
          content: '${i18n('Versions.ImageRemoved')}';
          background-color: ${props => props.theme.changesRemovedColorSolid};
        }
      `;
    }
    if (modified) {
      return css`
        &:after {
          border-color: ${props => props.theme.changesModifiedColorSolid};
        }
        &:before {
          content: '${i18n('Versions.ImageModified')}';
          background-color: ${props => props.theme.changesModifiedColorSolid};
        }
      `;
    }
  }}
`;

const arrowStyle = css`
  path {
    fill: ${props => props.theme.slateGrey};
  }
`;

const ArrowRight = styled(ChevronSVG)`
  ${arrowStyle};
`;
const ArrowLeft = styled(ChevronSVG)`
  ${arrowStyle};
  transform: rotate(180deg);
`;

const galleryButtonStyle = css`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  width: 36px;
  height: 36px;
  background-color: ${({ theme }) => theme.highlightColor};
  border: 0;
  box-shadow: 0 1px 4px 0 ${props => props.theme.canvasBox};
  z-index: 10;
  cursor: pointer;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.2s, visibility 0.2s, background-color 0.2s, box-shadow 0.2s;
  outline: none;

  &:hover {
    background-color: ${({ theme }) => blendColors(theme.highlightColor, theme.white, 0.15)};
  }

  ${navigationWithKeyboardStyle}

  svg path {
    fill: ${({ theme }) => (checkColorLuma(theme.highlightColor) > 200 ? '#000000' : theme.white)};
  }

  ${({ hidden }) =>
    hidden &&
    css`
      opacity: 0 !important;
      visibility: hidden;
    `}

  /* @noflip */
  [dir='rtl'] & {
    transform: translateY(-50%) rotate(180deg);
  }
`;

const StyledPrevGalleryButton = styled.button`
  ${galleryButtonStyle};
  left: 24px;
`;

const StyledNextGalleryButton = styled.button`
  ${galleryButtonStyle};
  right: 24px;
`;

function Illustration({
  stepIllustrations,
  encrypted,
  guideOrientation,
  added,
  removed,
  modified,
  loadImagesFromRoot,
  mode,
  // fullscreen handlers
  parentCanvasRef,
  parentIsFullscreen,
  openParentFullscreen,
  closeParentFullscreen,
}) {
  const [currentImage, setCurrentImage] = useState(0);
  const galleryLength = stepIllustrations.length;
  const isGallery = () => stepIllustrations.length > 1;

  const [fullscreenOpen, setFullscreenOpen] = useState(false);
  const [parentFullscreenWasOpen, setParentFullscreenWasOpen] = useState(false);

  function showPreviousImage() {
    setCurrentImage(curImage => (curImage === 0 ? curImage : curImage - 1));
  }

  function showNextImage() {
    setCurrentImage(curImage => (curImage === galleryLength - 1 ? curImage : curImage + 1));
  }

  const swipeHandles = useSwipeable({ onSwipedLeft: showNextImage, onSwipedRight: showPreviousImage });

  const openFullscreen = useCallback(
    (illustrations, index) => {
      if (!fullscreenEnabled() && !isMobileSafari() && !getIsMobileIntegration()) {
        const baseUrl = loadImagesFromRoot ? '' : imagesUrl.url;
        window.open(`${baseUrl}/${getImageUrl(illustrations[index])}`, '_blank');
        return;
      }
      if (['embed', 'widget', 'hc', 'hc-widget', 'preview'].includes(mode)) {
        if (parentIsFullscreen) {
          setParentFullscreenWasOpen(true);
        } else {
          openParentFullscreen();
        }
      }
      setTimeout(() => {
        setFullscreenOpen(true);
      }, 100);
    },
    [parentIsFullscreen, loadImagesFromRoot, mode, openParentFullscreen]
  );

  const closeFullscreen = useCallback(() => {
    if (parentIsFullscreen && !parentFullscreenWasOpen) {
      closeParentFullscreen();
    }
    setTimeout(() => {
      setFullscreenOpen(false);
      setParentFullscreenWasOpen(false);
    }, 100);
  }, [parentIsFullscreen, parentFullscreenWasOpen, closeParentFullscreen]);

  useEffect(() => {
    function fullscreenChangeHandler() {
      if (fullscreenOpen && !isFullscreen()) {
        closeFullscreen();
      }
    }
    addOnFullscreenChangeListener(fullscreenChangeHandler);
    return () => removeOnFullscreenChangeListener(fullscreenChangeHandler);
  }, [fullscreenOpen, closeFullscreen]);

  function enterFullScreen(e, index) {
    if (e.keyCode) {
      if ([13, 32].includes(e.keyCode)) {
        e.preventDefault();
        openFullscreen(stepIllustrations, index);
      }
    } else {
      openFullscreen(stepIllustrations, index);
    }
  }

  return (
    <Canvas {...swipeHandles}>
      {isGallery() ? (
        <Gallery galleryLength={galleryLength} currentImage={currentImage} guideOrientation={guideOrientation}>
          {stepIllustrations.map((si, index) => (
            <SingleImage
              key={getImageUrl(si)}
              url={getImageUrl(si)}
              onClick={e => enterFullScreen(e, index)}
              encrypted={encrypted}
              positionDiff={index - currentImage}
              isFirst={index === 0}
              stretch={si.stretch}
              backgroundColor={si.backgroundColor}
              focalPointX={si.focalPointX}
              focalPointY={si.focalPointY}
              aspectRatioX={(si.aspectRatio || '16:9').split(':')[0]}
              aspectRatioY={(si.aspectRatio || '16:9').split(':')[1]}
              altText={si.altText}
              caption={si.caption}
              galleryLength={galleryLength}
              guideOrientation={guideOrientation}
              ext={si.ext}
              loadImagesFromRoot={loadImagesFromRoot}
              name={si.altText}
            />
          ))}
        </Gallery>
      ) : (
        <SubCanvas added={added} removed={removed} modified={modified}>
          <SingleImage
            url={getImageUrl(stepIllustrations[0])}
            onClick={e => enterFullScreen(e, 0)}
            name={stepIllustrations[0].altText}
            encrypted={encrypted}
            positionDiff={0}
            isFirst
            stretch={stepIllustrations[0].stretch}
            backgroundColor={stepIllustrations[0].backgroundColor}
            focalPointX={stepIllustrations[0].focalPointX}
            focalPointY={stepIllustrations[0].focalPointY}
            aspectRatioX={(stepIllustrations[0].aspectRatio || '16:9').split(':')[0]}
            aspectRatioY={(stepIllustrations[0].aspectRatio || '16:9').split(':')[1]}
            altText={stepIllustrations[0].altText}
            caption={stepIllustrations[0].caption}
            galleryLength={galleryLength}
            guideOrientation={guideOrientation}
            ext={stepIllustrations[0].ext}
            loadImagesFromRoot={loadImagesFromRoot}
          />
        </SubCanvas>
      )}
      <StyledPrevGalleryButton
        aria-label="previous image"
        onClick={showPreviousImage}
        hidden={currentImage === 0}
        tabIndex={0}
      >
        <ArrowLeft />
      </StyledPrevGalleryButton>
      <StyledNextGalleryButton
        aria-label="next image"
        onClick={showNextImage}
        hidden={currentImage === galleryLength - 1}
        tabIndex={0}
      >
        <ArrowRight />
      </StyledNextGalleryButton>
      {fullscreenOpen && (
        <FullscreenImage
          stepIllustrations={stepIllustrations}
          currentImage={currentImage}
          show={fullscreenOpen}
          closeFullscreen={closeFullscreen}
          encrypted={encrypted}
          loadImagesFromRoot={loadImagesFromRoot}
          showPreviousImage={showPreviousImage}
          showNextImage={showNextImage}
          setCurrentImage={setCurrentImage}
          portalContainerEl={parentCanvasRef?.current}
        />
      )}
    </Canvas>
  );
}

Illustration.propTypes = {
  encrypted: PropTypes.bool,
  stepIllustrations: PropTypes.array,
  guideOrientation: PropTypes.string,
  added: PropTypes.bool,
  removed: PropTypes.bool,
  modified: PropTypes.bool,
  loadImagesFromRoot: PropTypes.bool,
  mode: PropTypes.string,
  // fullscreen handlers
  parentCanvasRef: PropTypes.object,
  parentIsFullscreen: PropTypes.bool,
  openParentFullscreen: PropTypes.func,
  closeParentFullscreen: PropTypes.func,
};

export default withFullscreen(memo(Illustration, isEqual));
