import React, { memo, useState, useMemo } from 'react';
import i18n from 'helpers/i18n';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import useInput from '@playerCommon/hooks/useInput';
import { useSentData } from '@playerCommon/Contexts/sentDataContext';
import { useUserData } from '@playerCommon/Contexts/userDataContext';
import { useServerCallData } from '@playerCommon/Contexts/serverCallDataContext';
import { replaceVarNamesWithValues } from '@stonlyCommons/helpers/guideVariableHelpers.js';
import BackButton from '@playerCommon/CustomElements/BackButton';
import { extractBaseData, trackCustomSurveyStat, track } from 'components/Analytics/recorder';
import InternalNote from '@playerCommon/StandardElements/ContentEditable/InternalNote/InternalNote.player';
import { getAdjustedOptionIndex, RATING_STYLES } from '@stonlyCommons/constants/CustomSurvey.consts';
import { CUSTOM_SURVEY_STEPS, scaleRatingToPercentage } from './CustomSurvey.consts';
import RatingList from './RatingList';
import {
  ButtonsWrap,
  Canvas,
  Content,
  Label,
  LabelsWrap,
  SkipButton,
  StyledBackButton,
  StyledTextarea,
  SubmitButton,
  SuccessIcon,
  ThankYou,
  Title,
  TitleContent,
} from './CustomSurvey.styles';

const { RATING, QUESTION, THANK_YOU, THANK_YOU_SKIPPED } = CUSTOM_SURVEY_STEPS;

const CustomSurvey = ({
  customSurveyStepInfo,
  isFirstStep,
  previousUrlToLoad,
  isPreview,
  view,
  guide = {},
  stepId,
  onBackLinkClick,
  onStepNextClick,
  internalNotes,
}) => {
  const [step, setStep] = useState(RATING);
  const [selectedRating, setSelectedRating] = useState();
  const [answer, setAnswer] = useInput('');

  const ratingNextSteps = (guide.stepConnectionList || [])
    .filter(el => el.conditionList && el.toStepId && el.fromStepId === stepId)
    .map(el => ({ ...el, stepConnectionId: el.id }));
  const hasSkipStep = ratingNextSteps.some(el => el.conditionList[0] === -1);

  const {
    content: { ratingText, followupQuestion, lowestValueLabel, highestValueLabel },
    properties: { ratingStyle, iconStyle, ratingScale, followUpQuestionEnabled, allowSkip },
    customSurveyModuleId,
    customSurveyModuleGuideId: originalGuideId,
  } = customSurveyStepInfo;

  const sentData = useSentData();
  const userData = useUserData();
  const { serverCallVariables } = useServerCallData();

  const { ratingTextToDisplay, followupQuestionToDisplay } = useMemo(() => {
    return {
      ratingTextToDisplay: replaceVarNamesWithValues({
        content: ratingText,
        userData,
        localData: sentData.guideData,
        serverCallVariables,
      }),
      followupQuestionToDisplay: replaceVarNamesWithValues({
        content: followupQuestion,
        userData,
        localData: sentData.guideData,
        serverCallVariables,
      }),
    };
  }, [ratingText, followupQuestion, sentData.guideData, userData, isPreview, serverCallVariables]);

  async function onRatingClick(rating) {
    if (!isPreview) {
      setSelectedRating(rating);

      if (view !== 'PreviewSteps') {
        const baseData = await extractBaseData();
        trackCustomSurveyStat({
          ...baseData,
          actionType: 'crs',
          originalGuideId,
          stepModuleId: customSurveyModuleId,
          score: scaleRatingToPercentage({ rating, ratingScale, ratingStyle }),
          originalRating: getAdjustedOptionIndex(ratingStyle, ratingScale, rating),
          ratingStyle,
          ratingScale,
          response: '',
        });
      }

      const option = ratingNextSteps.find(el => el.conditionList.includes(rating)) || {};

      if (followUpQuestionEnabled) {
        setStep(QUESTION);
      } else if (option.toStepId) {
        onStepNextClick(option);
      } else {
        setStep(THANK_YOU);
      }
    }
  }

  async function onSubmitClick() {
    if (view !== 'PreviewSteps') {
      const baseData = await extractBaseData();
      trackCustomSurveyStat({
        ...baseData,
        actionType: 'crs',
        originalGuideId,
        stepModuleId: customSurveyModuleId,
        score: scaleRatingToPercentage({ rating: selectedRating, ratingScale, ratingStyle }),
        originalRating: getAdjustedOptionIndex(ratingStyle, ratingScale, selectedRating),
        ratingStyle,
        ratingScale,
        response: answer,
      });
    }

    const option = ratingNextSteps.find(el => el.conditionList.includes(selectedRating)) || {};

    if (option.toStepId) {
      onStepNextClick(option);
    } else {
      setStep(THANK_YOU);
    }
  }

  async function onSkipClick() {
    track({
      actionType: 'crsSkipped',
      originalGuideId,
      actionDetail: {
        stepModuleStaticId: customSurveyModuleId,
      },
    });

    const option = ratingNextSteps.find(el => el.conditionList.includes(-1)) || {};

    if (option.toStepId) {
      onStepNextClick(option);
    } else {
      setStep(THANK_YOU_SKIPPED);
    }
  }

  const shouldShowLabels = !!lowestValueLabel || !!highestValueLabel;
  const shouldShowBackButton = !isFirstStep;
  const shouldShowSkipButton = !!allowSkip && !!hasSkipStep;
  const shouldReverseOptions = ratingStyle === RATING_STYLES.THUMBS;

  return (
    <Canvas>
      {step === RATING && (
        <>
          <TitleContent className="custom-survey-description" data-cy="titleContent" text={ratingTextToDisplay} />
          <Content className="custom-survey-rating-scale">
            <RatingList
              ratingStyle={ratingStyle}
              iconStyle={iconStyle}
              ratingScale={ratingScale}
              onRatingClick={onRatingClick}
              isPreview={isPreview}
              shouldReverseOptions={shouldReverseOptions}
            />
            {shouldShowLabels && (
              <LabelsWrap>
                {shouldReverseOptions ? (
                  <>
                    <Label className="custom-survey-label">{highestValueLabel}</Label>
                    <Label className="custom-survey-label">{lowestValueLabel}</Label>
                  </>
                ) : (
                  <>
                    <Label className="custom-survey-label">{lowestValueLabel}</Label>
                    <Label className="custom-survey-label">{highestValueLabel}</Label>
                  </>
                )}
              </LabelsWrap>
            )}
          </Content>
          {internalNotes.map(internalNote => (
            <InternalNote
              key={internalNote.id}
              added={internalNote.added}
              removed={internalNote.removed}
              content={internalNote.content}
            />
          ))}
          {(shouldShowBackButton || shouldShowSkipButton) && (
            <ButtonsWrap>
              {shouldShowBackButton && (
                <BackButton
                  className="custom-survey-back-button"
                  link={previousUrlToLoad}
                  onClick={onBackLinkClick}
                  disabled={isPreview}
                />
              )}
              {shouldShowSkipButton && (
                <SkipButton
                  className="custom-survey-skip-button"
                  dataCy="skipButton"
                  isFirstStep={isFirstStep}
                  background="filledGreyBorder"
                  onClick={onSkipClick}
                  content={i18n('CustomSurvey.Skip')}
                  disabled={isPreview}
                />
              )}
            </ButtonsWrap>
          )}
        </>
      )}
      {step === QUESTION && (
        <>
          <TitleContent
            className="custom-survey-followup-question"
            data-cy="followUpTitleContent"
            text={followupQuestionToDisplay}
          />
          <StyledTextarea
            className="custom-survey-followup-answer"
            dataCy="followUpAnswer"
            value={answer}
            onChange={setAnswer}
            placeholder={i18n('CustomSurvey.AnswerPlaceholder')}
            maxLength={1000}
          />

          <ButtonsWrap>
            {shouldShowBackButton && (
              <BackButton className="custom-survey-back-button" link={previousUrlToLoad} onClick={onBackLinkClick} />
            )}
            <SubmitButton
              className="custom-survey-submit-button"
              dataCy="customSurveySubmitButton"
              data-ston="customSurveySubmitButton"
              background="highlight"
              onClick={onSubmitClick}
              content={i18n('CustomSurvey.Submit')}
            />
          </ButtonsWrap>
        </>
      )}
      {step === THANK_YOU && (
        <ThankYou>
          <SuccessIcon />
          <Title className="custom-survey-thank-you" data-cy="customSurveyThankYouTitle">
            {i18n('CustomSurvey.ThankYouForFeedback')}
          </Title>
          {shouldShowBackButton && (
            <StyledBackButton
              className="custom-survey-back-button"
              link={previousUrlToLoad}
              onClick={onBackLinkClick}
            />
          )}
        </ThankYou>
      )}
      {step === THANK_YOU_SKIPPED && (
        <ThankYou>
          <SuccessIcon />
          <Title className="custom-survey-thank-you-skip">{i18n('CustomSurvey.ThankYou')}</Title>
          {shouldShowBackButton && (
            <StyledBackButton
              className="custom-survey-back-button"
              link={previousUrlToLoad}
              onClick={onBackLinkClick}
            />
          )}
        </ThankYou>
      )}
    </Canvas>
  );
};

CustomSurvey.propTypes = {
  customSurveyStepInfo: PropTypes.object,
  isFirstStep: PropTypes.bool,
  previousUrlToLoad: PropTypes.string,
  isPreview: PropTypes.bool,
  view: PropTypes.string,
  guide: PropTypes.object,
  stepId: PropTypes.number,
  onStepNextClick: PropTypes.func,
  internalNotes: PropTypes.array,
};

export default memo(withRouter(CustomSurvey));
