import { motion } from 'framer-motion';
import styled from 'styled-components';

const Container = styled(motion.div).attrs<{ placement: 'todo placement handling'; isReducedMotion?: boolean }>(
  ({ placement = 'bottom', isReducedMotion = false /* TODO read it in this file */ }) => ({
    initial: {
      opacity: 0,
      pointerEvents: 'none',
      transform: isReducedMotion ? 'none' : `translate3d(0, ${placement.includes('bottom') ? '-' : ''}10px, 0)`,
    },
    animate: {
      opacity: 1,
      pointerEvents: 'auto',
      transform: isReducedMotion ? 'none' : 'translate3d(0, 0px, 0)',
    },
    exit: {
      opacity: 0,
      transform: isReducedMotion ? 'none' : `translate3d(0, ${placement.includes('bottom') ? '-' : ''}10px, 0)`,
      pointerEvents: 'none',
    },
    transition: { duration: 0.25 },
  })
)`
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
  background-color: ${({ theme }) => theme.white};
  border-radius: 4px;
  position: relative;
  display: block;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-track {
    opacity: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.lightGrey};
    border-radius: 6px;
    border: 2px solid white;
    background-size: cover;
    background-position: center;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${props => props.theme.grey};
  }
`;

export { Container };
