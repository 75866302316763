import styled, { css } from 'styled-components';

export const focusInputOutlineStyle = css`
  outline: 2px solid ${({ theme }) => theme.darkBlue};
  outline-offset: 0px;
`;

const IconWrap = styled.span<{ $color?: string }>`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  display: flex;
  align-items: center;
  height: 16px;
  width: fit-content; // eventually remove it
  margin: 12px;
  color: ${({ theme }) => theme.lightGrey};
  ${({ $color }) =>
    $color &&
    css`
      & svg path {
        fill: ${$color};
      }
    `}
`;

const IconLeftWrap = styled(IconWrap).attrs({
  tabIndex: -1, // fix if input's focus is blinking on mousedown (2024-01 not blinking)
})`
  margin-right: 0;
`;

const IconRightWrap = styled(IconWrap).attrs({
  tabIndex: -1, // fix if input's focus is blinking on mousedown (2024-01 not blinking)
})`
  margin-left: 0;
  outline: none;
`;

const IconUnitsWrap = styled(IconWrap).attrs({
  tabIndex: -1, // fix if input's focus is blinking on mousedown (2024-01 not blinking)
})`
  margin-left: 0;
  outline: none;
  & svg path {
    fill: ${props => props.theme.lightGrey};
  }
`;

const InputElementWrap = styled.div<{
  $disabled?: boolean;
}>`
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 3px;

  ${({ theme, $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
      background: ${theme.paleGrey};

      & input {
        color: ${theme.lightGrey};
      }
      & ${IconWrap} svg path {
        fill: ${theme.grey};
      }
    `}
`;

const FieldWrap = styled.div<{ $borderColor?: string }>`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  border: 1px solid ${({ theme, $borderColor }) => $borderColor || theme.grey};
  border-radius: 4px;
  background: ${({ theme }) => theme.white};

  &:focus-within /* if has(:focus-visible) was used then it will blink on icon click */ {
    z-index: 1; /* to make outline cover additional action button */
    ${focusInputOutlineStyle}
  }

  ${({ theme, $borderColor }) => css`
    border-color: ${$borderColor || theme.grey};
  `}
`;

const InputElement = styled.input`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-family: inherit;
  color: ${({ theme }) => theme.darkGrey};
  width: 100%;
  min-width: 48px;
  border: 0;
  outline: 0;
  padding: 0;
  margin: 0;
  background: transparent;
  appearance: none;
  padding: 10px 12px;

  &::placeholder {
    color: ${({ theme }) => theme.lightGrey};
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  ${IconLeftWrap}+& {
    padding-left: 8px;
  }
`;

export const FieldStyles = {
  FieldWrap,
  InputElementWrap,
  IconWrap,
  IconLeftWrap,
  InputElement,
  IconRightWrap,
  IconUnitsWrap,
};
