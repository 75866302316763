/* eslint-disable react/prop-types */
/* eslint-disable react/static-property-placement */

import React, { Component } from 'react';
import queryString from 'query-string';

const LOOM_URL_REGEX =
  /^(?:https?:\/\/)?((?:loom.com|www.loom.com)\/share\/[a-z-]*?[\da-f]+)(\?(?:sid=([\da-f-]+)|hide_owner=true|hide_title=true|hideembedtopbar=true|hide_share=true|muted=(?:1|true)|autoplay=(?:1|true)|t=[\dms]*|&)*)?$/im;
const LOOM_HOSTNAME_CAPTURE = /(www\.loom\.com|loom\.com|stage\.loom\.com|loomlocal\.com:4444)/i;

export const isLoomUrl = url => LOOM_URL_REGEX.test(url);

export const isValidEmbedUrl = url => isLoomUrl(url);

const SRC_URL_REGEX = /src=["']+(https?:\/\/[\d&./:?A-z]+)/;

const getResponsiveEmbedCode = (embedURL, options = {}) => {
  const iframeStyles = 'position: absolute; top: 0; left: 0; width: 100%; height: 100%;';

  return (
    `<iframe src="${embedURL}${embedURL.includes('?') ? '&' : '?'}${queryString.stringify({
      autoplay: options.autoplay,
      t: options.startTime,
      hide_owner: options.hide_owner,
      hide_title: options.hide_title,
      hideEmbedTopBar: options.hideEmbedTopBar,
      hide_share: options.hide_share,
      muted: options.muted,
    })}" ` +
    'frameborder="0" webkitallowfullscreen mozallowfullscreen ' +
    `allowfullscreen style="${iframeStyles}"></iframe>`
  );
};

const enhanceEmbedCode = (videoData, options) => {
  const { width, height } = options;

  if (!width && !height) {
    const [, embedUrl] = videoData.html.match(SRC_URL_REGEX);

    return {
      ...videoData,
      html: getResponsiveEmbedCode(embedUrl, options),
    };
  }
  return videoData;
};

const buildLoomOembedUrl = (url, options) => {
  const { width, height } = options;
  const maxWidth = width ? `&maxwidth=${width}` : '';
  const maxHeight = height ? `&maxheight=${height}` : '';

  const [loomDomain] = url.match(LOOM_HOSTNAME_CAPTURE);
  let loomBaseDomain = loomDomain;

  if (loomDomain === 'loom.com') {
    loomBaseDomain = `www.${loomDomain}`;
  }

  return `https://${loomBaseDomain}/v1/oembed?url=${url}${maxWidth}${maxHeight}`;
};

const buildOembedUrl = (url, options) => {
  if (isLoomUrl(url)) {
    return buildLoomOembedUrl(url, options);
  }
  throw new Error('URL is not from a supported video provider');
};

const oembed = (linkUrl, options = {}) => {
  const isSupportedUrl = isValidEmbedUrl(linkUrl);

  if (isSupportedUrl) {
    const { query } = queryString.parseUrl(linkUrl);
    return fetch(buildOembedUrl(linkUrl, options))
      .then(resp => resp.json())
      .then(videoData => enhanceEmbedCode(videoData, { ...options, ...query }))
      .catch(() => {
        throw new Error('Unable to fetch oembed data');
      });
  }
  throw new Error('URL is not from a supported video provider');
};

export default class Loom extends Component {
  static displayName = 'Loom';

  static canPlay = url => LOOM_URL_REGEX.test(url);

  duration = undefined;

  componentDidMount() {
    const { onMount } = this.props;
    if (onMount) onMount(this);
  }

  state = {
    embedHtml: '',
  };

  load = async url => {
    const outputText = await oembed(url, this.props.config.loom);
    this.duration = outputText.duration;

    this.setState({ embedHtml: outputText.html });

    // eslint-disable-next-line react/destructuring-assignment
    this.props.onReady();
  };

  play = () => {};

  pause = () => {};

  stop = () => {};

  seekTo = () => {};

  setVolume = () => {};

  mute = () => null;

  unmute = () => null;

  getDuration() {
    return this.duration;
  }

  getCurrentTime = () => null;

  getLoaded = () => null;

  getSecondsLoaded = () => null;

  render() {
    const { embedHtml } = this.state;

    return <div dangerouslySetInnerHTML={{ __html: embedHtml }} />;
  }
}
