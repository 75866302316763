import { findEmbeddedFirstStep } from '@stonlyCommons/helpers/guidePathHelpers';
import { STEP_NEXT_TYPE, STEP_SEPARATOR, STEP_WIDGET_ACTION_TYPES } from 'global';
import { SPECIAL_STEPS_BUTTON_NAME } from 'global/translated-names';
import i18n from 'helpers/i18n';
import { postMessage } from 'helpers/widgetHelpers';

export function getNextStepLabel(stepNextObj) {
  if (!stepNextObj) return false;
  if (!stepNextObj.label && !stepNextObj.action) {
    return i18n('StepButtons.NextButton');
  }
  return stepNextObj.label || SPECIAL_STEPS_BUTTON_NAME[stepNextObj.action.type];
}

export function getNextStepToLoad(nextSteps, nextStepSelected) {
  if (nextSteps.length > 1) {
    return nextStepSelected;
  }
  if (nextSteps[0]) {
    return nextSteps[0];
  }
  return {};
}

export function getTitleToDisplay(title, stepType, displayStepTitle) {
  if ((!['finalStep', 'introduction', 'stonlyAd'].includes(stepType) && !displayStepTitle) || title === 'unNamed') {
    return '';
  }
  return title;
}

function generateGoToNextStepProps({ option, urlToGo, nextStepType }) {
  return {
    newPath: urlToGo,
    toStepId: option.toStepId || '',
    stepConnectionId: option.id,
    nextStepLabel: option.label || 'next',
    nextStepStaticValue: option.staticValue,
    typeOfNextStep: nextStepType,
    openInNewTab: option.newTabEnabled,
    action: option.action,
  };
}

export function getGoToNextStepProps({ option, path, guide }) {
  if (option.toStepId === STEP_NEXT_TYPE.EXTERNAL_LINK) {
    return generateGoToNextStepProps({ option, urlToGo: option.linkUrl, nextStepType: 'external' });
  }
  if (option.toStepId === STEP_NEXT_TYPE.SPECIAL) {
    return generateGoToNextStepProps({ option, urlToGo: option.linkUrl, nextStepType: 'special' });
  }

  return generateGoToNextStepProps({
    option,
    urlToGo: `${path}${STEP_SEPARATOR}${findEmbeddedFirstStep(option.toStepId, guide)}`,
    nextStepType: 'internal',
  });
}

export const postStepIdMessage = path => {
  let stepsPath = path;
  if (typeof path === 'string') {
    stepsPath = path.split(STEP_SEPARATOR);
  }
  const stepIdToPost = stepsPath[stepsPath.length - 1];
  if (stepIdToPost) {
    postMessage({ type: 'currentStep', stepId: +stepIdToPost, stepsPath: stepsPath.join(STEP_SEPARATOR) });
  }
};

export const getContentWithLinkAttributes = content => {
  return (
    content
      // here we are abusing the fact that browsers ignore duplicated attributes,
      // so target="_blank" and rel="noopener" will only be added if the link doesn't have these attributes yet
      .replaceAll(/<a href=(.*?)>(.*?)<\/a>/gim, '<a href=$1 target="_blank" rel="noopener">$2</a>')
  );
};

export const isSnoozeTriggerOption = option => {
  return option?.toStepId === STEP_NEXT_TYPE.SPECIAL && option?.action.type === STEP_WIDGET_ACTION_TYPES.TRIGGER_SNOOZE;
};
