/* eslint-disable max-classes-per-file */

// "polyfill" for SSR
const CustomEvent =
  // eslint-disable-next-line no-undef
  globalThis?.CustomEvent ||
  class CustomEvent extends Event {
    constructor(message, data) {
      super(message, data);
      this.detail = data.detail;
    }
  };

/**
 * Imperativelly make tree show/expand/scroll first row that meets the "isActive" requirement
 * Usefull for forcing highlighting on the element from outside of the tree context
 */
export class GoToFirstActiveRowInTreeCustomEvent extends CustomEvent {
  static toString = () => 'flat-tree-first-go-to-first-active-row';

  constructor({ treeName, behavior, blink } = {}) {
    if (!treeName) {
      throw new Error(
        'STON:ERROR GoToFirstActionRowInTreeName constructor accepts named arguments: { treeName, behavior = undefined } '
      );
    }
    super(GoToFirstActiveRowInTreeCustomEvent.toString(), { detail: { treeName, behavior, blink } });
  }
}

export class GoToRowUuidInTreeCustomEvent extends CustomEvent {
  static toString = () => 'flat-tree-first-go-to-row-uuid';

  constructor({ treeName, uuid, behavior } = {}) {
    if (!treeName || !uuid) {
      throw new Error(
        'STON:ERROR GoToRowUuidInTreeCustomEvent constructor accepts named arguments: ({ treeName, uuid, behavior = undefined}) '
      );
    }
    super(GoToRowUuidInTreeCustomEvent.toString(), { detail: { treeName, uuid, behavior } });
  }
}
