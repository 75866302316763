const addZendeskChatListeners = () => {
  try {
    zE('webWidget:on', 'close', () => {
      zE('webWidget', 'hide');
    });
    zE('messenger:on', 'close', () => {
      zE('messenger', 'hide');
    });
  } catch {
    // we don't really care
  }
};

export const initializeZendeskChat = isWidget => {
  try {
    zE('webWidget', 'hide');
    zE('messenger', 'hide');
  } catch {
    // we don't really care
  }
  if (!isWidget) addZendeskChatListeners();
};
