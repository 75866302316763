import React from 'react';
import userContext from '@playerCommon/Contexts/userContext';

const withUser = Component => props =>
  (
    <userContext.Consumer>
      {userManagement => <Component {...props} userManagement={userManagement} />}
    </userContext.Consumer>
  );

export default withUser;
