import styled, { css } from 'styled-components';
import { navigationWithKeyboardStyle } from '@playerCommon/CommonStyledComponents/NavigationWithKeyboard';
import Icon from '@playerCommon/StandardElements/Icon';

export const FileExtIcon = styled(Icon)``;

export const FileFrontWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 2;
`;

export const FileRearWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const FileNameWrapper = styled.div`
  flex-grow: 2;
  margin: 0 12px;
`;

export const FileSize = styled.span`
  font-size: 10px;
  font-weight: 700;
  color: ${({ isError, theme }) => (isError ? theme.deleteColor : theme.lightGrey)};
  transition: color 0.2s;
`;

export const FileTitle = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.charcoal};
  word-break: break-all;
  transition: color 0.2s;
`;

export const FileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 44px;
  padding: 6px 12px 8px 12px;
  border-radius: 4px;
  margin-bottom: 8px;
  outline: none;
  transition: background-color 0.2s, box-shadow 0.2s;
  ${({ isHoverable }) =>
    isHoverable &&
    css`
      cursor: pointer;
    `}
  background-color: ${({ theme, isError }) => (isError && 'rgba(231, 55, 55, 0.1)') || theme.paleGrey};

  ${({ theme, isAdded, isRemoved }) =>
    (isAdded &&
      css`
        border: 3px solid ${theme.secondaryColor};
      `) ||
    (isRemoved &&
      css`
        border: 3px solid ${theme.deleteColor};
      `)}

  ${navigationWithKeyboardStyle}

  &:hover,
  &:focus,
  &:focus-within,
  &:active {
    ${({ isHoverable }) =>
      isHoverable &&
      css`
        background-color: rgba(227, 229, 233, 0.5);
        ${FileTitle} {
          color: ${({ theme }) => theme.darkGrey};
        }
        ${FileSize} {
          color: ${({ theme }) => theme.steel};
        }
        ${FileRearWrapper} {
          & svg path {
            fill: ${({ theme }) => theme.darkGrey};
          }
        }
      `}
  }
`;
