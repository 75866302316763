import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Dropdown from '@playerCommon/CustomElements/Dropdown';
import ActionList from '@playerCommon/CustomElements/ActionList/ActionList';

const StepButtonsDropdown = ({ buttonsConfig, trigger }) => {
  const dropdownRef = useRef();
  const closeDropdown = () => {
    dropdownRef.current.closeDropdown();
  };

  const preparedOptions = buttonsConfig.map(el => ({
    id: el.label,
    bulletMedia: [el.contentMore || el.content],
    bulletMediaSize: 16,
    label: el.label,
    action: el.onClick,
    separation: el.separation,
  }));

  if (preparedOptions.length === 0) return null;

  return (
    <Dropdown
      width={200}
      offsetValue={28}
      ref={dropdownRef}
      trigger={trigger}
      dataCy="stepInputPositionedDropdown"
      placement="top"
    >
      <ActionList options={preparedOptions} onPostSelect={closeDropdown} />
    </Dropdown>
  );
};

StepButtonsDropdown.propTypes = {
  buttonsConfig: PropTypes.array,
  trigger: PropTypes.object,
};

export default StepButtonsDropdown;
