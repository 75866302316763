import React from 'react';
import Tick16 from 'resources/icons/checkSmall.svg';
import Tip16 from 'resources/icons/tip.svg';
import Warning16 from 'resources/icons/warning.svg';
import Error16 from 'resources/icons/error.svg';
import Tick24 from 'resources/icons/tickMedium.svg';
import Tip24 from 'resources/icons/tipLarge.svg';
import Warning24 from 'resources/icons/warningLarge.svg';
import Error24 from 'resources/icons/error-24.svg';
import type { Status } from '@playerCommon/ui/models';
import { STATIC_CLASS_NAME } from '@playerCommon/ui/constants';

export const renderStatusIconForStatus = (status?: Status | false, iconSizeName?: '16' | '24') => {
  if (status === 'error') {
    const Error = iconSizeName === '24' ? Error24 : Error16;
    return <Error data-status={status} className={STATIC_CLASS_NAME.statusIcon} />;
  }
  if (status === 'warning') {
    const Warning = iconSizeName === '24' ? Warning24 : Warning16;
    return <Warning data-status={status} className={STATIC_CLASS_NAME.statusIcon} />;
  }
  if (status === 'success') {
    const Tick = iconSizeName === '24' ? Tick24 : Tick16;
    return <Tick data-status={status} className={STATIC_CLASS_NAME.statusIcon} />;
  }
  if (status === 'info') {
    const Tip = iconSizeName === '24' ? Tip24 : Tip16;
    return <Tip data-status={status} className={STATIC_CLASS_NAME.statusIcon} />;
  }
  return null;
};
