import React from 'react';

import { useTreeRowProps } from '@playerCommon/ComplexElements/FlatTree/hooks/useTreeRowProps';
import { useTreeRowRef } from '@playerCommon/ComplexElements/FlatTree/hooks/useTreeRowRef';
import { getIsIntegrationSpecialUrl } from 'helpers/widgetIntegrations';
import { RowConnection } from '../../shared/components/RowConnection';
import { RowPlayerStep } from './RowPlayerStep';
import { RowSpacing } from '../../shared/components/RowSpacing';

export const Row = ({ uuid, onStepClick }) => {
  const rowProps = useTreeRowProps({ uuid });
  const rowRef = useTreeRowRef({ uuid });

  if (!rowProps) {
    console.log('STON:row item not found', uuid);
    return null;
  }

  if (rowProps.isSpecial && rowProps.payload.isSpacing) {
    return <RowSpacing level={rowProps.level} isUnlinked={rowProps.payload.isUnlinked} />;
  }

  if (rowProps.isFolder) {
    return (
      <RowConnection
        isOpen={rowProps.isOpen}
        toggleFolderOpen={rowProps.toggleFolderOpen}
        level={rowProps.level}
        // flatten payload data for React.memo
        label={rowProps.payload.label}
        hasActiveInside={rowProps.hasActiveInside}
        hasSelectedInside={rowProps.hasSelectedInside}
        isUnlinked={rowProps.payload.isUnlinked}
      />
    );
  }

  const { linkUrl } = rowProps.payload;

  // we want to allow for openning URL, but don't want to ex. open chat (that info is stored in payload linkUrl)
  const isClickableRowOnPlayer = linkUrl ? !getIsIntegrationSpecialUrl(linkUrl) : rowProps.payload.isClickable;

  return (
    <RowPlayerStep // ! this is different than the common one for Editor, VersionCompare etc.
      ref={rowRef}
      uuid={uuid}
      linkUrl={linkUrl}
      isNewTabEnabled={rowProps.payload.isNewTabEnabled}
      level={rowProps.level}
      isActive={rowProps.isActive}
      isSelected={rowProps.isSelected}
      isReference={rowProps.payload.isReference}
      referenceUuid={rowProps.payload.referenceUuid}
      goToRow={rowProps.goToRow}
      isHighlighted={rowProps.isHighlighted}
      highlightColor={rowProps.highlightColor}
      // flatten payload data for React.memo
      icon={rowProps.payload.icon}
      isClickable={isClickableRowOnPlayer}
      label={rowProps.payload.label}
      stepId={rowProps.payload.stepId}
      onStepClick={onStepClick}
      isUnlinked={rowProps.payload.isUnlinked}
    />
  );
};
