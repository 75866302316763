import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import RightChevronSVG from 'icons/chevronLightRight.svg';
import StonlyLogoSVG from 'resources/logo/helpcenterLogo.svg';
import { getFolderCaption } from 'helpers/helpcenterHelpers';
import { openIntegration } from 'helpers/widgetIntegrations';
import Link from '@playerCommon/StandardElements/LinkWithQuery/LinkWithQuery';
import { navigationWithKeyboardStyle } from '@playerCommon/CommonStyledComponents/NavigationWithKeyboard';
import { createSlug } from 'helpers/guidePlayerHelpers';
import { useContactFormOptionsChecking, openExternalLink, getExternalChatCSS } from '../helpers';
import { INTEGRATION_NAMES } from '../const';
import HeaderMenu from '../Widget/components/HeaderMenu';
import ContactForm from '../../components/ContactForm';
import ExternalChat from '../../components/ExternalChat';
import { SearchProvider } from '../../components/SearchBar/searchContext';

const CanvasPlaceholder = styled.div`
  position: relative;
  display: flex;
  flex-shrink: 0;
  margin: 0;
  width: 100%;
  height: 65px;
`;

const Canvas = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto 0 auto;
  width: 100%;
  background: white;
  padding: 0 32px;
  border-bottom: 1px solid ${props => props.theme.silver};
  z-index: 100;
  height: 96px;
  color: ${props => props.theme.slateGrey};
  font-size: 14px;
  transition: background-color 0.2s, color 0.2s;
  position: fixed;
  top: 0;
  height: 65px;
  padding: 0 24px;

  @media screen and (max-width: 480px) {
    padding: 0 16px;
  }
`;

const MobileTitle = styled.h1`
  font-size: 28px;
  line-height: 40px;
  font-weight: 600;
  display: block;
  color: ${props => props.theme.darkGrey};
  margin-bottom: 24px;
`;

const MobileBreacrumbsWrap = styled.div`
  margin-bottom: 24px;
`;

const MobileMenuContentWrap = styled.div`
  border-bottom: 1px solid ${props => props.theme.silver};
  margin-bottom: 24px;

  &:last-child {
    border-bottom: 0;
  }
`;

const Breadcrumbs = styled.div`
  position: relative;
  display: inline-block;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-right: 16px;
`;

const Crumb = styled.a`
  color: ${props => props.theme.slateGrey};
  margin-left: 8px;
  margin-right: 8px;
  transition: color 0.2s, box-shadow 0.2s;
  box-shadow: none;
  border-radius: 4px;

  &:first-child {
    margin-left: 0;
    /* @noflip */
    [dir='rtl'] & {
      margin-left: 8px;
      margin-right: 0;
    }
  }

  &:last-child {
    margin-right: 0;
    /* @noflip */
    [dir='rtl'] & {
      margin-right: 8px;
      margin-left: 0;
    }
  }

  &:hover {
    color: ${props => props.theme.darkGrey};
  }

  ${navigationWithKeyboardStyle}
`;

const CrumbArrow = styled(RightChevronSVG)`
  width: 8px;
  height: 8px;
  transform: translateY(-1px);

  path {
    fill: ${props => props.theme.steel};
  }

  /* @noflip */
  [dir='rtl'] & {
    transform: translateY(-1px) scaleX(-1);
  }
`;

export const StonlyLogo = styled(StonlyLogoSVG)`
  height: 28px;
  width: 155px;
`;

export const Logo = styled.img`
  margin-top: 2px;
`;

const ExplanationTop = ({ title, breadcrumbs = [], helpcenterData, folderId, guideId, language }) => {
  const [isContactUsOpen, setIsContactUsOpen] = useState(false);
  const [isExternalChatOpen, setIsExternalChatOpen] = useState(false);

  const { knowledgeBase } = helpcenterData;

  const isKbCustomContactFormGuide = knowledgeBase.guideId === guideId;
  const {
    isContactFormEnabled,
    isContactSuggestionsEnabled,
    isContactFormDefault,
    isContactFormCustomGuide,
    isContactFormExternalChat,
    isContactFormExternalLink,
  } = useContactFormOptionsChecking(knowledgeBase);
  const forceParentHref = !(isContactFormCustomGuide && isKbCustomContactFormGuide);

  const customFormUrl = isContactFormCustomGuide ? `/kb-widget/guide/${language}/${knowledgeBase.guideId}/Steps/` : '';

  const handleContactForm = () => {
    if (isContactFormCustomGuide) {
      window.location.href = customFormUrl;
    }
    if (isContactFormExternalLink) {
      openExternalLink(knowledgeBase.contactFormDisplaySettings.externalUrl);
    }

    if (isContactFormExternalChat) {
      openIntegration(INTEGRATION_NAMES[knowledgeBase.contactFormDisplaySettings.externalChatType]);
    }
  };

  const onContactClose = useCallback(() => setIsContactUsOpen(false), []);
  const onContactOpen = useCallback(
    () => (isContactFormDefault ? setIsContactUsOpen(true) : handleContactForm()),
    [knowledgeBase.contactFormType]
  );

  const crumbs = (
    <Breadcrumbs>
      {breadcrumbs.map((folder, i) => {
        const folderName = getFolderCaption(folder, language).name;
        const slug = folderName && folder.folderId ? `${createSlug(folderName)}-${folder.folderId}` : '';
        return (
          <React.Fragment key={folder.folderId}>
            <Link component={Crumb} to={`/kb-widget/${language}/${slug}`}>
              {folderName || folder.folderId}
            </Link>
            {i !== breadcrumbs.length - 1 && <CrumbArrow />}
          </React.Fragment>
        );
      })}
    </Breadcrumbs>
  );

  const backButtonUrl = useMemo(() => {
    if (folderId === knowledgeBase.rootFolderId || !forceParentHref) {
      return `/kb-widget/${language}/`;
    }

    const foundBackFolder = breadcrumbs.find(f => f.folderId === folderId);
    if (foundBackFolder) {
      const folderName = getFolderCaption(foundBackFolder, language).name;
      const slug = folderName ? `${createSlug(folderName)}-${foundBackFolder.folderId}` : '';
      return `/kb-widget/${language}/${slug}`;
    }
    return `/kb-widget/${language}/${breadcrumbs.length > 1 ? folderId : ''}`;
  }, [breadcrumbs, folderId, language, forceParentHref]);

  return (
    <CanvasPlaceholder className="kbWidget-guideHeader">
      {isContactFormExternalChat && !isExternalChatOpen && (
        <style>{getExternalChatCSS(knowledgeBase.contactFormDisplaySettings.externalChatType)}</style>
      )}
      <Canvas className="kbWidget-guideTop">
        <SearchProvider initialValue="" helpcenterData={helpcenterData} language={language}>
          <HeaderMenu
            onContactClick={isContactFormEnabled ? onContactOpen : undefined}
            helpcenterData={helpcenterData}
            mobileTopContent={
              <MobileMenuContentWrap>
                <MobileTitle>{title}</MobileTitle>
                <MobileBreacrumbsWrap>{crumbs}</MobileBreacrumbsWrap>
              </MobileMenuContentWrap>
            }
            parentHref={backButtonUrl}
            forceParentHref={forceParentHref}
            mode="explanation"
            currentLanguage={language}
          />
        </SearchProvider>
      </Canvas>
      {isContactFormDefault && (
        <ContactForm
          suggestionsEnabled={isContactSuggestionsEnabled}
          open={isContactUsOpen}
          onCloseClick={onContactClose}
          closeAsArrow
          monochromeHeader
        />
      )}
      {isContactFormExternalChat && (
        <ExternalChat
          externalChatType={knowledgeBase.contactFormDisplaySettings.externalChatType}
          onContactOpen={() => setIsExternalChatOpen(true)}
          onContactClose={() => setIsExternalChatOpen(false)}
          isWidget
        />
      )}
    </CanvasPlaceholder>
  );
};

ExplanationTop.propTypes = {
  title: PropTypes.string,
  breadcrumbs: PropTypes.array,
  helpcenterData: PropTypes.object,
  folderId: PropTypes.number,
  guideId: PropTypes.string,
  language: PropTypes.string,
};

export default ExplanationTop;
