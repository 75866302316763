import { useCallback, useEffect, useRef } from 'react';

export default function useScrolledSteps({
  isScrollable = false,
  containerRef,
  setCurrentStep,
  currentStepId,
  currentFirstScrollableStepId,
}) {
  const currentStepIdRef = useRef(currentStepId);
  const stepElementsRef = useRef([]);
  const onResizeRequestAnimationFrameRef = useRef();

  const scrollToStep = useCallback(
    (stepId = currentStepIdRef.current) => {
      if (isScrollable && containerRef.current && stepId) {
        const stepContentEl = containerRef.current.querySelector(`[data-step-id="${stepId}"]`);
        if (stepContentEl) {
          const scrollBlock = stepContentEl.offsetHeight > containerRef.current.offsetHeight ? 'start' : 'center';
          stepContentEl.scrollIntoView({ block: scrollBlock });
        }
      }
    },
    [isScrollable]
  );

  useEffect(() => {
    if (!isScrollable) return;

    stepElementsRef.current = [...containerRef.current.querySelectorAll('[data-step-id]')];

    const onIntersection = entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const stepId = +entry.target.dataset.stepId;
          if (stepId && stepId !== currentStepIdRef.current) {
            currentStepIdRef.current = stepId;
            setCurrentStep(stepId);
          }
        }
      });
    };

    const intersectionObserver = new IntersectionObserver(onIntersection, {
      root: containerRef.current,
      rootMargin: '-50% 0% -50% 0%', // this places the "middle" of the viewport as the intersection point
      threshold: 0,
    });

    stepElementsRef.current.forEach(el => {
      intersectionObserver.observe(el);
    });

    return () => {
      intersectionObserver.disconnect();
    };
  }, [isScrollable, currentFirstScrollableStepId]);

  useEffect(() => {
    if (!isScrollable) return;

    function onResize() {
      cancelAnimationFrame(onResizeRequestAnimationFrameRef.current);
      onResizeRequestAnimationFrameRef.current = requestAnimationFrame(() => {
        scrollToStep();
      });
    }

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [isScrollable, currentFirstScrollableStepId]);

  return {
    scrollToStep,
  };
}
