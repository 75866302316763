import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import i18n from 'helpers/i18n';
import TextLink from '@playerCommon/StandardElements/TextLink';
import ListNavBackSVG from 'icons/listNavBack.svg';

const Canvas = styled.div`
  font-size: 20px;
  color: ${props => props.theme.darkGrey};
  display: flex;
`;

const BackLink = styled(TextLink)`
  color: ${props => props.theme.darkGrey};

  & a {
    color: ${props => props.theme.darkGrey};
  }
`;

const DropdownBack = ({ backFunction, location = '', className = '', href = '' }) => (
  <Canvas className={className}>
    {href ? (
      <BackLink href={href} bullet={ListNavBackSVG} bulletHover="none" type="blackBullet" cacheGetRequests>
        {i18n('Global.Back')}
      </BackLink>
    ) : (
      <BackLink
        onClick={() => backFunction(location)}
        bullet={ListNavBackSVG}
        bulletHover="none"
        type="blackBullet"
        cacheGetRequests
      >
        {i18n('Global.Back')}
      </BackLink>
    )}
  </Canvas>
);

DropdownBack.propTypes = {
  backFunction: PropTypes.func,
  location: PropTypes.string,
  className: PropTypes.string,
  href: PropTypes.string,
};

export default DropdownBack;
