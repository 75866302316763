import { copyHTMLToClipboard, copyTextToClipboard } from 'helpers/textManagement';

const removeTags = content => {
  const contentWithNewLines = content.replaceAll('<br/>', '\u000A');
  const divToRemoveTags = document.createElement('div');
  divToRemoveTags.innerHTML = contentWithNewLines;

  const aTags = divToRemoveTags.querySelectorAll('a');
  aTags.forEach(aTag => {
    const href = aTag.getAttribute('href') || '';
    aTag.textContent = `${aTag.textContent || ''} ${href}`;
  });

  return divToRemoveTags.textContent || divToRemoveTags.innerText || '';
};

export const copyAiAnswerToClipboard = content => {
  if (!window.navigator.clipboard || !navigator.userAgent.includes('Chrome')) {
    const contentWithoutTags = removeTags(content);
    copyTextToClipboard(contentWithoutTags);
  } else {
    copyHTMLToClipboard(content);
  }
};
