import React, { Component } from 'react';
import styled, { withTheme, ThemeProvider, createGlobalStyle, css } from 'styled-components';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import callApi from 'helpers/apiHelpers';
import i18n, { getLanguageShorthand } from 'helpers/i18n';
import { setLanguage } from 'helpers/i18n.helpers';
import { parseFoldersPath } from 'helpers/helpcenterHelpers';
import { createPathFromParam, loadExplanation, getNewLanguageUrl } from 'helpers/explanationManagement.js';
import { validateHexaDecimal } from 'helpers/validationHelpers.js';
import withUser from '@playerCommon/HOC/withUser/withUser';
import NoViewAccess from '@playerCommon/Commons/NoViewAccess';
import Loader, { FullscreenLoaderWrap } from '@playerCommon/CustomElements/Loader/Loader.js';
import ExplanationPlayer from '@playerCommon/ComplexElements/ExplanationPlayer';
import withContextProviders from 'HOC/withContextProviders';
import withHcExplanationData from 'HOC/withHcExplanationData';
import fullscreenContext from '@playerCommon/Contexts/fullscreenContext';
import { RIGHT, STEP_NEXT_VISIBILITY } from 'global';
import { postMessage, getWidgetFormat, getIsWidgetKbHeaderHidden } from 'helpers/widgetHelpers';
import { getIds } from 'helpers/statIdsManagement';
import { getStepsGlobalScrollbarStyles } from '@playerCommon/CommonStyledComponents/CustomScrollbar';
import { getIsiOSMobileIntegration } from 'helpers/userAgentHelpers';
import { parseGuideData } from 'helpers/guidePlayerHelpers';
import { setCookie } from 'global/windowUtils';
import { WIDGET_FORMATS } from '@stonlyCommons/components/consts';
import { parseBoolean } from '@playerCommon/helpers/booleanHelpers';
import ExplanationTop from './ExplanationTop';

const Canvas = styled.div``;

const ExplanationWrap = styled.div`
  width: 100%;
  height: calc(100vh - 65px);
  border-radius: 4px;

  ${props =>
    props.isWidgetKbHeaderHidden &&
    css`
      height: 100vh;
    `}

  @media screen and (max-width: 901px) {
    width: 100%;
    margin: 0;
    border-radius: 0;
    border: 0;
    height: 100%;
  }
`;

const ExplanationContent = styled.div`
  width: 100%;
  height: calc(100vh - 65px);
  display: flex;
`;

const GlobalScrollStyles = createGlobalStyle`
  ${getStepsGlobalScrollbarStyles({ marginTop: '96px', marginBottom: '68px' })}

  @media screen and (max-width: 899px) {
    ${getStepsGlobalScrollbarStyles({ marginTop: '65px', marginBottom: '68px' })}
  }

  @media screen and (max-width: 480px) {
    ${getStepsGlobalScrollbarStyles({ marginTop: '65px', marginBottom: '60px' })}
  }

  ${props =>
    props.isWidgetKbHeaderHidden &&
    css`
      .kbWidget-guideHeader {
        display: none;
      }

      ${getStepsGlobalScrollbarStyles({ marginTop: '0px', marginBottom: '68px' })}

      @media screen and (max-width: 899px) {
        ${getStepsGlobalScrollbarStyles({ marginTop: '0px', marginBottom: '68px' })}
      }

      @media screen and (max-width: 480px) {
        ${getStepsGlobalScrollbarStyles({ marginTop: '0px', marginBottom: '60px' })}
      }
    `}
`;

const ALLOWED_RIGHTS = new Set([RIGHT.FULL_RIGHTS, RIGHT.VIEW, RIGHT.VIEW_EDIT, RIGHT.VIEW_EDIT_PUBLISH]);

const getProcessedState = (match, location, loadedGuide, statusCode) => {
  if (statusCode === 403) {
    return { explanationLoaded: true, accessToExplanation: false, guide: {} };
  }
  if (!loadedGuide) return null;

  const accessToExplanation = ALLOWED_RIGHTS.has(loadedGuide.guideInfo.access);
  if (!accessToExplanation) {
    return { explanationLoaded: true, accessToExplanation: false, guide: {} };
  }

  const format = getWidgetFormat(location);

  // this is needed for embedding the guide as an iframe inside other KB in Smart Answer.
  const isWidgetKbHeaderHidden = getIsWidgetKbHeaderHidden(location);

  /*
   * Once the explanation (including the sub explanations) is loaded
   * The path is taken from the URL to load the right step. Depending on
   * whether the current step belongs to the main explanation or a sub one,
   * or if this is the last step or the introduction, different parameters
   * are loaded
   */

  return {
    guide: loadedGuide,
    explanationLoaded: true,
    accessToExplanation,
    isFullScreen: false,
    isWidgetKbHeaderHidden,
    format,
  };
};

class WidgetExplanation extends Component {
  constructor(props) {
    super(props);

    const ssrInitialState = getProcessedState(props.match, props.location, props.loadedGuide, props.statusCode);
    const stepsPath = createPathFromParam(props.match.params.pathToLoad);

    this.state = { ...ssrInitialState, breadcrumbs: props.breadcrumbs, hcData: props.helpcenterData, stepsPath } || {
      guide: [],
      explanationLoaded: false,
      accessToExplanation: true,
      breadcrumbs: [],
      hcData: {},
      isFullScreen: false,
      isWidgetKbHeaderHidden: false,
      stepsPath,
      format: {
        widgetFormat: '',
        placement: '',
        widgetSizeType: '',
      },
    };
  }

  componentDidMount() {
    const { explanationLoaded } = this.state;
    if (!explanationLoaded) this.initiateExplanation();

    getIds().then(({ sessionId }) => {
      postMessage({
        type: 'params',
        name: 'stonlySessionId',
        value: sessionId,
      });
    });
    postMessage({ type: 'initPlugin' });
    postMessage({ type: 'guideLanguage', language: getLanguageShorthand() });

    window.addEventListener('beforeunload', this.onBeforeUnload);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onBeforeUnload);
  }

  componentDidUpdate(prevProps) {
    const { userManagement, match } = this.props;
    const { match: oldMatch } = prevProps;
    if (userManagement.user.id && !prevProps.userManagement.user.id) {
      this.initiateExplanation();
    }
    if (match.params.pathToLoad !== oldMatch.params.pathToLoad) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ stepsPath: createPathFromParam(match.params.pathToLoad) });
    }
  }

  onBeforeUnload = () => {
    // save widget format to cookie to be able to use it's value inside Widget.jsx
    setCookie('stonlyWidget_format', JSON.stringify(this.state.format), 15);
  };

  setLanguage = async language => {
    const { match, history } = this.props;
    setLanguage(language);
    postMessage({ type: 'guideLanguageChanged', language });
    await this.initiateExplanation();
    const { guide } = this.state;
    history.replace(getNewLanguageUrl(language, match, guide.guideInfo.title));
  };

  initiateExplanation = async () => {
    const { match, location } = this.props;

    try {
      const language = getLanguageShorthand();
      const { id, view } = match.params;
      const { widgetId, segmentAnonymousId, segmentUserId, stonlyAnonymousId, customerUserId } = queryString.parse(
        location.search
      );
      const loadedGuide = await loadExplanation(id, {
        isPreview: view === 'PreviewSteps',
        isShortlink: location.pathname.slice(1, 3) === 'sl',
        widgetId,
        segmentAnonymousId,
        segmentUserId,
        stonlyAnonymousId,
        customerUserId,
      });
      const parsedGuide = parseGuideData(loadedGuide, language);
      const newState = getProcessedState(match, location, parsedGuide);

      const res = await callApi(`v1/knowledgebase/integration?language=${language}`, 'get');
      const helpcenterData = res.data;
      const breadcrumbs = parseFoldersPath(parsedGuide.guideOptions.folderId, helpcenterData.folders, false);
      breadcrumbs.unshift({
        folderId: null,
        caption: { [language]: { name: helpcenterData.knowledgeBase.knowledgeBaseName } },
      });

      if (!newState) return;

      this.setState({ ...newState, breadcrumbs });
    } catch {
      this.setState({ explanationLoaded: true, accessToExplanation: false });
    }
  };

  openFullscreen = () => {
    postMessage({ type: 'stonlyWidgetFullscreenOpen' });
    this.setState({ isFullScreen: true });
  };

  closeFullscreen = () => {
    const { isFullScreen } = this.state;
    if (isFullScreen) {
      postMessage({ type: 'stonlyWidgetFullscreenClose' });
    }
    this.setState({ isFullScreen: false });
  };

  contentBasedOnLinkAndAccess(guide, title) {
    /*
     * this function creates an object with the content to display
     * depending on whether there URL is wrong or the access to the explanation is restricted.
     */

    let titleToDisplay;
    let errorToDisplay;
    if (guide.guideInfo.guideId) {
      titleToDisplay = guide.guideInfo.access === RIGHT.NONE ? 'Restricted access.' : title;
      errorToDisplay =
        guide.guideInfo.access === RIGHT.NONE ? <div>Sorry you do not have access to this guide.</div> : '';
    } else {
      titleToDisplay = 'Ooops, no guide found.';
      errorToDisplay = 'Are you sure you have the right link?';
    }
    return { titleToDisplay, errorToDisplay };
  }

  render() {
    const { match, theme, location } = this.props;
    const {
      guide,
      explanationLoaded,
      accessToExplanation,
      breadcrumbs,
      hcData,
      isFullScreen,
      isWidgetKbHeaderHidden,
      format,
      stepsPath,
    } = this.state;

    if (!explanationLoaded) {
      return (
        <FullscreenLoaderWrap>
          <Loader text={i18n('Global.Loading')} />
        </FullscreenLoaderWrap>
      );
    }

    if (!accessToExplanation) {
      return <NoViewAccess />;
    }

    const { title } = guide.guideInfo;
    const hasConditionalConnection = guide.allGuides
      .flatMap(({ stepNext }) => stepNext)
      .some(({ visibility }) => visibility === STEP_NEXT_VISIBILITY.externalCondition);

    let displayProgressBar = accessToExplanation ? parseBoolean(guide.guideOptions.displayProgressBar, true) : true;
    displayProgressBar = displayProgressBar && !hasConditionalConnection;

    const content = this.contentBasedOnLinkAndAccess(guide, title);

    const { view, language, id } = match.params;
    // mainExplanationInfo.view = view;

    let firstStep = null;
    const firstStepObj = guide.allGuides.find(info => info.guideId === id);
    if (firstStepObj) firstStep = firstStepObj.startId;

    const { knowledgeBase } = hcData;
    const urlParams = queryString.parse(location.search);

    const themeModified = { ...theme };

    if (urlParams.accent && validateHexaDecimal(urlParams.accent)) {
      themeModified.highlightColor = urlParams.accent;
    }
    if (knowledgeBase.highlightColor) {
      themeModified.highlightColor = knowledgeBase.highlightColor;
    }

    const { widgetFormat, placement, widgetSizeType } = format;
    const compact = widgetFormat === WIDGET_FORMATS.LIGHT;

    let loadImagesFromRoot = false;
    if (knowledgeBase?.isPrivate && knowledgeBase?.knowledgeBaseOrigin) {
      loadImagesFromRoot = true;
    }

    const isiOSMobileIntegration = getIsiOSMobileIntegration();

    return (
      <ThemeProvider theme={themeModified}>
        {isiOSMobileIntegration && (
          <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
          </Helmet>
        )}
        <GlobalScrollStyles isWidgetKbHeaderHidden={isWidgetKbHeaderHidden} />
        {knowledgeBase.customHtml ? <span dangerouslySetInnerHTML={{ __html: knowledgeBase.customHtml }} /> : null}
        <Canvas
          className="kbWidget-guide"
          onClickCapture={() => {
            postMessage({
              type: 'widgetClick',
            });
          }}
        >
          <fullscreenContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
              parentIsFullscreen: isFullScreen,
              openParentFullscreen: this.openFullscreen,
              closeParentFullscreen: this.closeFullscreen,
            }}
          >
            <ExplanationTop
              title={content.titleToDisplay}
              breadcrumbs={breadcrumbs}
              helpcenterData={hcData}
              folderId={guide.guideOptions.folderId}
              guideId={guide.guideInfo.guideId}
              language={language}
            />
            <ExplanationContent>
              <ExplanationWrap isWidgetKbHeaderHidden={isWidgetKbHeaderHidden}>
                {view.includes('Steps') && (
                  <ExplanationPlayer
                    mode="hc-widget"
                    guide={guide}
                    firstStep={firstStep}
                    stepsPath={stepsPath}
                    displayProgressBar={displayProgressBar}
                    setLanguage={this.setLanguage}
                    isFullScreen={isFullScreen}
                    compact={compact}
                    widgetPlacement={placement}
                    widgetSizeType={widgetSizeType}
                    loadImagesFromRoot={loadImagesFromRoot}
                  />
                )}
              </ExplanationWrap>
            </ExplanationContent>
          </fullscreenContext.Provider>
        </Canvas>
      </ThemeProvider>
    );
  }
}

WidgetExplanation.propTypes = {
  match: PropTypes.object,
  userManagement: PropTypes.object,
  helpcenterData: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  statusCode: PropTypes.number,
  theme: PropTypes.object,
  breadcrumbs: PropTypes.array,
  loadedGuide: PropTypes.object,
};

export default withHcExplanationData(withContextProviders(withUser(withTheme(WidgetExplanation))));
