import React from 'react';

/**
 * @typedef {Object} PlayerPopupConfig
 * @prop {JSX.Element} customContent - Component to show before title or instead of all other
 * @prop {Object} [style] - Custom popup body style
 * @prop {string} [title] - Text to show as title
 * @prop {JSX.Element} [titleIcon] - Component to display on the left side of the title
 * @prop {string | JSX.Element} [text] - Text or component to show as content
 * @prop {boolean} [hideDismiss] - Hide dissmiss button
 * @prop {boolean} [hideClose] - Hide close button
 * @prop {() => void} [confirmFunction] - Function triggered when confirm button is pressed
 * @prop {string | null} confirmButtonText - Text on confirm button
 * @prop {string} [confirmButtonColor] - Color of confirm button
 * @prop {() => void} [dismissFunction] - Function triggered when dismiss button is pressed
 * @prop {string | null} dismissButtonText - Text on dismiss button
 * @prop {() => void} [closeButtonFunction] - Function triggered when close button is pressed
 * @prop {boolean} [dismissable] - Deprecated: Should allow closing via X
 */

const popupContext = React.createContext({
  /**
   * @type {PlayerPopupConfig} popupConfig
   */
  popup: {
    text: '',
    title: '',
    customContent: undefined,
    style: {},
    titleIcon: undefined,
    confirmFunction: () => {},
    dismissFunction: () => {},
    closeButtonFunction: undefined,
    hideDismiss: false,
    hideClose: false,
    confirmButtonText: '',
    dismissButtonText: '',
    confirmButtonColor: undefined,
  },
  /**
   * Opens popup
   * @param {PlayerPopupConfig} popupConfig
   * @returns {void}
   */
  openPopup: popupConfig => {},
  closePopup: () => {},
  popupOpen: false,
});

export default popupContext;
