import { useContext, useMemo } from 'react';
import { FlatTreeContext } from '../FlatTreeProvider';

/** All the functions to manipulate tree: setSelected, openFolder, etc. */
export const useTreeModifiers = () => {
  const { state, ...rest } = useContext(FlatTreeContext);

  return useMemo(
    () => ({
      openFolder: rest.openFolder,
      closeFolder: rest.closeFolder,
      replaceRow: rest.replaceRow,
      expandAll: rest.expandAll,
      collapseAll: rest.collapseAll,
      moveItem: rest.moveItem,
      setDragging: rest.setDragging,
      setActive: rest.setActive,
      initialize: rest.initialize,
      scrollToRow: rest.scrollToRow,
      setHighlightColor: rest.setHighlightColor,
      setHighlightColorByRule: rest.setHighlightColorByRule,
      setSelected: rest.setSelected,
      setSelectedByRule: rest.setSelectedByRule,
      openToRowTemporary: rest.openToRowTemporary,
      closeTemporaryOpened: rest.closeTemporaryOpened,
      memoizedGetCanDropBelow: rest.memoizedGetCanDropBelow,
      memoizedGetCanDropInside: rest.memoizedGetCanDropInside,
      goToRow: rest.goToRow,
      treeName: rest.treeName,
    }),
    [state]
  );
};
